import React, { useContext, useEffect, useState } from "react";

import { SeedContext } from ".";
import AppContext from "../../../../contexts/app";
import useApi from "../../../../hooks/useApi";
import destinationSeed from "./data/destination";
import mafiaSeed from "./data/mafia";
import zoSeed from "./data/zo";

const SeedProvider = ({ children }) => {
  const { category, slug } = useContext(AppContext);
  const api = useApi("webapp", false);

  const [layout, setLayout] = useState({});
  const [style, setStyle] = useState({});
  const [loadingSeed, setLoadingSeed] = useState(true);

  const [seedData, setSeedData] = useState({});

  useEffect(() => {
    if (api) {
      if (category === "destination") {
        destinationSeed.layout.header.title =
          slug.substr(0, 1).toUpperCase() + slug.substr(1);
        setLayout(destinationSeed.layout);
        setStyle(destinationSeed.style);
        api
          .get(`/api/v1/stay/destinations/${slug}/`)
          .then((res) => {
            setSeedData(res.data.destination);
            console.log(res.data.destination);
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (category === "cult" && slug === "playmafia") {
        setLayout(mafiaSeed.layout);
        setStyle(mafiaSeed.style);
      } else {
        setLayout(zoSeed.layout);
        setStyle(zoSeed.style);
      }
      setLoadingSeed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, slug, api]);

  return (
    <SeedContext.Provider value={{ layout, style, seedData }}>
      {loadingSeed ? <></> : children}
    </SeedContext.Provider>
  );
};

export default SeedProvider;
