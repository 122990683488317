import React, { useContext, useEffect, useState } from "react";
import withMemo from "../../../../../../../utils/withMemo";
import InGameContext from "../../../contexts/inGame";
import ModalContext from "../../../contexts/modal";
import backSide from "./../../../assets/role-back.svg";
import detectiveCard from "./../../../assets/role-detective.svg";
import healerCard from "./../../../assets/role-healer.svg";
import mafiaCard from "./../../../assets/role-mafia.svg";
import villageBoy1Card from "./../../../assets/role-villager-boy-1.svg";
import villageBoy2Card from "./../../../assets/role-villager-boy-2.svg";
import villageGirl1Card from "./../../../assets/role-villager-girl-1.svg";
import villageGirl2Card from "./../../../assets/role-villager-girl-2.svg";

const CARD = {
  mafia: mafiaCard,
  healer: healerCard,
  detective: detectiveCard,
  villager: villageBoy1Card,
  "villager-boy-1": villageBoy1Card,
  "villager-boy-2": villageBoy2Card,
  "villager-girl-1": villageGirl1Card,
  "villager-girl-2": villageGirl2Card,
};
const Card = withMemo(
  ({ distributed, hide, status, clickable, roleCard, revealed }) => {
    const { setModal } = useContext(ModalContext);

    const onClick = clickable
      ? setModal.bind(null, ["identity", CARD[roleCard]])
      : null;
    const classes = `role-card absolute w-8 h-12 ${
      distributed ? "given" : ""
    } ${hide && status === "alive" && !revealed ? "hide" : ""} ${
      revealed ? "revealed" : ""
    }`;

    return (
      <div className={classes} onClick={onClick}>
        <div className="role-card-inner">
          <img className="role-card-front rounded-md" src={backSide} alt="" />
          {revealed && (
            <img
              className="role-card-back rounded-md"
              src={CARD[roleCard]}
              alt=""
            />
          )}
        </div>
      </div>
    );
  },
  [
    "distributed",
    "hide",
    "status",
    "role",
    "gameOver",
    "clickable",
    "roleCard",
    "revealed",
  ]
);

const Cards = () => {
  const [distributed, setDistributed] = useState(false);
  const [inGame, setInGame] = useState(false);
  const [hide, setHide] = useState(false);
  const { roomRound, playersStates, player, roomStatus } =
    useContext(InGameContext);

  useEffect(() => {
    if (roomRound === "identity") {
      setTimeout(() => {
        setDistributed(true);
      }, 1000);
    } else if (roomRound === "game_starting") {
    } else if (roomRound) {
      setInGame(true);
      setHide(true);
    }
  }, [roomRound]);

  return (
    <div className="absolute z-10 w-full h-full inset-0">
      {Object.keys(playersStates).map((id) => {
        const _player = playersStates[id];
        return (
          <Card
            key={id}
            role={_player.role}
            roleCard={_player.role_card}
            status={_player.status}
            distributed={distributed || inGame}
            revealed={_player.revealed}
            hide={hide}
            clickable={parseInt(id) === player.id}
            gameOver={
              roomStatus === "ended_mafia" || roomStatus === "ended_village"
            }
          />
        );
      })}
    </div>
  );
};

export default Cards;
