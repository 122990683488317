import die_bg_1 from "../assets/Badges/Die_Hard_zo/001_Die_Hard_zo.svg";
import die_bg_2 from "../assets/Badges/Die_Hard_zo/002_Die_Hard_zo.svg";
import die_bg_3 from "../assets/Badges/Die_Hard_zo/003_Die_Hard_zo.svg";
import die_bg_4 from "../assets/Badges/Die_Hard_zo/004_Die_Hard_zo.svg";
import die_bg_5 from "../assets/Badges/Die_Hard_zo/005_Die_Hard_zo.svg";
import dis_bg_1 from "../assets/Badges/Discoverer/001_Discoverer.svg";
import dis_bg_2 from "../assets/Badges/Discoverer/002_Discoverer.svg";
import dis_bg_3 from "../assets/Badges/Discoverer/003_Discoverer.svg";
import dis_bg_4 from "../assets/Badges/Discoverer/004_Discoverer.svg";
import dis_bg_5 from "../assets/Badges/Discoverer/005_Discoverer.svg";
import expert_bg_1 from "../assets/Badges/Expert/001_Expert.svg";
import expert_bg_2 from "../assets/Badges/Expert/002_Expert.svg";
import expert_bg_3 from "../assets/Badges/Expert/003_Expert.svg";
import expert_bg_4 from "../assets/Badges/Expert/004_Expert.svg";
import expert_bg_5 from "../assets/Badges/Expert/005_Expert.svg";
import exp_bg_1 from "../assets/Badges/Explorer/001_Explorer.svg";
import exp_bg_2 from "../assets/Badges/Explorer/002_Explorer.svg";
import exp_bg_3 from "../assets/Badges/Explorer/003_Explorer.svg";
import exp_bg_4 from "../assets/Badges/Explorer/004_Explorer.svg";
import exp_bg_5 from "../assets/Badges/Explorer/005_Explorer.svg";
import goat_bg_1 from "../assets/Badges/Goat/001_Goat.svg";
import goat_bg_2 from "../assets/Badges/Goat/002_Goat.svg";
import goat_bg_3 from "../assets/Badges/Goat/003_Goat.svg";
import goat_bg_4 from "../assets/Badges/Goat/004_Goat.svg";
import goat_bg_5 from "../assets/Badges/Goat/005_Goat.svg";
import legend_bg_1 from "../assets/Badges/Legends/001_Legend.svg";
import legend_bg_2 from "../assets/Badges/Legends/002_Legend.svg";
import legend_bg_3 from "../assets/Badges/Legends/003_Legend.svg";
import legend_bg_4 from "../assets/Badges/Legends/004_Legend.svg";
import legend_bg_5 from "../assets/Badges/Legends/005_Legend.svg";
import master_bg_1 from "../assets/Badges/Master/001_Master.svg";
import master_bg_2 from "../assets/Badges/Master/002_Master.svg";
import master_bg_3 from "../assets/Badges/Master/003_Master.svg";
import master_bg_4 from "../assets/Badges/Master/004_Master.svg";
import master_bg_5 from "../assets/Badges/Master/005_Master.svg";
import veteran_bg_1 from "../assets/Badges/Veteran/001_Veteran.svg";
import veteran_bg_2 from "../assets/Badges/Veteran/002_Veteran.svg";
import veteran_bg_3 from "../assets/Badges/Veteran/003_Veteran.svg";
import veteran_bg_4 from "../assets/Badges/Veteran/004_Veteran.svg";
import veteran_bg_5 from "../assets/Badges/Veteran/005_Veteran.svg";
import wan_bg_1 from "../assets/Badges/Wanderer/001_Wanderer.svg";
import wan_bg_2 from "../assets/Badges/Wanderer/002_Wanderer.svg";
import wan_bg_3 from "../assets/Badges/Wanderer/003_Wanderer.svg";
import wan_bg_4 from "../assets/Badges/Wanderer/004_Wanderer.svg";
import wan_bg_5 from "../assets/Badges/Wanderer/005_Wanderer.svg";
import bn_9 from "../assets/Banner/DieHard.svg";
import bn_3 from "../assets/Banner/Discoverer.svg";
import bn_4 from "../assets/Banner/Expert.svg";
import bn_2 from "../assets/Banner/Explorer.svg";
import bn_8 from "../assets/Banner/Goat.svg";
import bn_7 from "../assets/Banner/Legend.svg";
import bn_6 from "../assets/Banner/Master.svg";
import bn_5 from "../assets/Banner/Veteran.svg";
import bn_1 from "../assets/Banner/Wanderer.svg";
export const bg = [
  wan_bg_1,
  wan_bg_2,
  wan_bg_3,
  wan_bg_4,
  wan_bg_5,
  exp_bg_1,
  exp_bg_2,
  exp_bg_3,
  exp_bg_4,
  exp_bg_5,
  dis_bg_1,
  dis_bg_2,
  dis_bg_3,
  dis_bg_4,
  dis_bg_5,
  expert_bg_1,
  expert_bg_2,
  expert_bg_3,
  expert_bg_4,
  expert_bg_5,
  veteran_bg_1,
  veteran_bg_2,
  veteran_bg_3,
  veteran_bg_4,
  veteran_bg_5,
  master_bg_1,
  master_bg_2,
  master_bg_3,
  master_bg_4,
  master_bg_5,
  legend_bg_1,
  legend_bg_2,
  legend_bg_3,
  legend_bg_4,
  legend_bg_5,
  goat_bg_1,
  goat_bg_2,
  goat_bg_3,
  goat_bg_4,
  goat_bg_5,
  die_bg_1,
  die_bg_2,
  die_bg_3,
  die_bg_4,
  die_bg_5,
];
export const bn = [bn_1, bn_2, bn_3, bn_4, bn_5, bn_6, bn_7, bn_8, bn_9];
export const levels = [
  "Wanderer I",
  "Wanderer II",
  "Wanderer III",
  "Wanderer IV",
  "Wanderer V",
  "Explorer I",
  "Explorer II",
  "Explorer III",
  "Explorer IV",
  "Explorer V",
  "Discoverer I",
  "Discoverer II",
  "Discoverer III",
  "Discoverer IV",
  "Discoverer V",
  "Expert I",
  "Expert II",
  "Expert III",
  "Expert IV",
  "Expert V",
  "Veteran I",
  "Veteran II",
  "Veteran III",
  "Veteran IV",
  "Veteran V",
  "Master I",
  "Master II",
  "Master III",
  "Master IV",
  "Master V",
  "Legend I",
  "Legend II",
  "Legend III",
  "Legend IV",
  "Legend V",
  "GOAT I",
  "GOAT II",
  "GOAT III",
  "GOAT IV",
  "GOAT V",
  "Die Hard Zo I",
  "Die Hard Zo II",
  "Die Hard Zo III",
  "Die Hard Zo IV",
  "Die Hard Zo V",
];

// eslint-disable-next-line import/no-anonymous-default-export
export default { bn, bg, levels };
