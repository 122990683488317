import React, { useState } from "react";
import { MoreVertical } from "../../../../../../assets/icons";
import RTCSettingsSheet from "../../../../../../components/bottomsheets/RTCSettingsSheet/RTCSettingsSheet";

interface RTCSettingsProps {}

const RTCSettings: React.FC<RTCSettingsProps> = () => {
  const [isSheetVisible, setSheetVisibility] = useState<boolean>(false);

  const visible = localStorage.getItem("zo-audio-settings");

  return visible === "true" ? (
    <>
      <button
        className="absolute left-full focus:outline-none w-8 h-8 p-1 rounded-full hover:bg-white-translucent-75"
        onClick={setSheetVisibility.bind(null, true)}
      >
        <MoreVertical className="w-6 h-6 text-black fill-current" />
      </button>
      <RTCSettingsSheet
        open={isSheetVisible}
        onDismiss={setSheetVisibility.bind(null, false)}
      />
    </>
  ) : null;
};

export default RTCSettings;
