import React, { useContext, useEffect, useState } from "react";
import useApi from "../../../../../../../../../components/hooks/useApi";
import RoomContext from "../../../../../contexts/room";
import RTCSettings from "../../../../Footer/components/RTCSettings";

const Footer = () => {
  const { room } = useContext(RoomContext);
  const api = useApi("");

  const [nextTournamentCode, setNextTournamentCode] = useState(null);
  const [autoJoin, setAutoJoin] = useState(true);

  useEffect(() => {
    if (
      api &&
      room &&
      room.tournament &&
      room.status !== "ended_mafia" &&
      room.status !== "ended_village"
    ) {
      api
        .get("/tournament/active/")
        .then((res) => {
          if (res.data.room) {
            setNextTournamentCode(res.data.room.code);
          }
        })
        .catch((e) => {});
    }
  }, [api, room]);

  // const goBack = () => {
  //   window.history.go(-1);
  // };

  // const isFirstPlayer = useMemo(
  //   () =>
  //     player &&
  //     player.id &&
  //     (player.id === +Object.keys(playersStates)[0] ||
  //       player.id === Object.keys(playersStates)[0]),
  //   [player, playersStates]
  // );

  // const restartGame = async () => {
  //   const data = {
  //     data: {
  //       players_count: room.players_count,
  //       name: room.name,
  //       mafia_count: room.mafia_count,
  //       mafia_semi_count: room.mafia_semi_count,
  //       mafia_revealer_count: room.mafia_revealer_count,
  //       mafia_flagbearer_count: room.mafia_flagbearer_count,
  //       detective_count: room.detective_count,
  //       healer_count: room.healer_count,
  //       villager_count: room.villager_count,
  //       villager_save_count: room.villager_save_count,
  //       villager_revenge_count: room.villager_revenge_count,
  //       game_flow: room.game_flow,
  //     },
  //     type: room.type,
  //   };
  //   try {
  //     const response = await api.post(
  //       "/games/api/v1/mafia/room/",
  //       JSON.stringify(data)
  //     );
  //     console.log(response);
  //     if (response.status === 200) {
  //       const roomData = response.data.room;
  //       startTravel(
  //         "mafia",
  //         `https://mafia.${process.env.REACT_APP_ROOT_DOMAIN}/r/${roomData.code}/`
  //       );
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const backToRoom = () => {
    if (autoJoin) {
      window.open(
        `https://${process.env.REACT_APP_ROOT_DOMAIN}/@mafia/?autojoin=true`,
        "_parent"
      );
    } else {
      window.open(
        `https://${process.env.REACT_APP_ROOT_DOMAIN}/@mafia`,
        "_parent"
      );
    }
  };

  const nextTournamentMatch = () => {
    window.open(
      `https://${process.env.REACT_APP_ROOT_DOMAIN}/@mafia/r/${nextTournamentCode}/`,
      "_parent"
    );
  };

  return room.status !== "ended_mafia" && room.status !== "ended_village" ? (
    <div className="w-full flex items-center justify-between mt-4">
      <div className="w-1/3 flex">
        {room.tournament && nextTournamentCode ? (
          <div
            className="bg-mafia-secondary font-semibold text-mafia-background px-4 py-3 text-xl rounded-lg shadow-md cursor-pointer"
            onClick={nextTournamentMatch}
          >
            Start next match
          </div>
        ) : (
          <div className="flex items-center">
            <div
              className="bg-mafia-secondary font-semibold text-mafia-background px-4 py-3 text-xl rounded-lg shadow-md cursor-pointer mr-4"
              onClick={backToRoom}
            >
              Back to Zo Mafia
            </div>
            <input
              type="checkbox"
              className="form-checkbox mr-2 text-mafia-secondary focus:outline-none"
              id="auto-join"
              checked={autoJoin}
              onChange={(e) => {
                setAutoJoin(e.target.checked);
              }}
            />
            <label
              htmlFor="auto-join"
              className="text-mafia-secondary font-bold"
            >
              Auto join
            </label>
          </div>
        )}
      </div>
      <div className="w-1/3 flex justify-center">
        {room.status !== "ended_village" && room.status !== "ended_mafia" && (
          <RTCSettings />
        )}
      </div>
      <div className="w-1/3"></div>
    </div>
  ) : null;
};

export default Footer;
