import Axios from "axios";

const publicApi = new Axios.create({
  baseURL: process.env.REACT_APP_API_ZO,
  headers: {
    "Content-Type": "application/json",
    "Client-App-Id": "Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU",
  },
});

export default publicApi;
