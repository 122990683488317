import React, { useEffect, useState } from "react";

// import Avatar from "../../../../../ui/Avatar";
import NameRow from "./ProfileUpdates/Name";
import DOBRow from "./ProfileUpdates/DOB";
import GenderRow from "./ProfileUpdates/Gender";
import CountryRow from "./ProfileUpdates/Country";
import useResponseFlash from "../../../../hooks/useResponseFlash";
import useApi from "../../../../hooks/useApi";

const MemberUpdateModal = ({ code, close, sendSocketMessage, message }) => {
  const api = useApi("profile", true);
  const { response: quoteSent, setResponse: setQuoteSent } = useResponseFlash();
  const {
    response: activateSent,
    setResponse: setActivateSent,
  } = useResponseFlash();

  const [onboarded, setOnboarded] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  const [countriesList, setCountriesList] = useState([]);
  const [profile, setProfile] = useState({});

  const handleOnboarded = async () => {
    try {
      const response = await api.post(
        `/geeta/api/v1/profile/${code}/tag/`,
        JSON.stringify({ tag_id: 1 })
      );
      if (response.status === 200) {
        sendSocketMessage({ action: "member_onboarded", payload: { code } });
        setOnboarded(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaymentSend = () => {
    sendSocketMessage({ action: "payment_quote", payload: { code } });
    setQuoteSent(true);
  };

  const handleActivateDomain = () => {
    sendSocketMessage({ action: "activate_domain", payload: { code } });
    setActivateSent(true);
  };

  useEffect(() => {
    if (code && api) {
      setProfile({});
      api
        .get("/profile/api/v1/locations/countries/")
        .then((res) => {
          setCountriesList(res.data.countries);
        })
        .catch((e) => {
          console.log(e);
        });

      api.get(`/geeta/api/v1/profile/${code}/`).then((res) => {
        console.log(res.data.profile);
        const { profile: _profile } = res.data;
        if (_profile.tags.find((t) => t.name === "onboarding_complete")) {
          setOnboarded(true);
        } else {
          setOnboarded(false);
        }
        if (_profile.status === "premium") {
          setSubscribed(true);
        } else {
          setSubscribed(false);
        }
        setProfile(_profile);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, code]);

  useEffect(() => {
    if (
      message &&
      message.action === "new_premium" &&
      message.payload.code === code
    ) {
      setSubscribed(true);
    }
  }, [code, message]);

  const updateProfile = async (data) => {
    try {
      const response = await api.post(
        `/geeta/api/v1/profile/${code}/`,
        JSON.stringify(data)
      );
      if (response.status === 200) {
        setProfile((p) => Object.assign(p, data));
        Promise.resolve();
      } else {
        Promise.reject();
      }
    } catch (error) {
      console.log(error);
      Promise.reject();
    }
  };

  return (
    <section className="fixed z-50 top-0 right-0 mt-24 mr-4 flex-col flex items-center bg-white border-t-4 border-orange portrait:w-full w-content h-content p-4 rounded-lg">
      <button className="w-8 h-8 absolute top-0 right-0 mt-2" onClick={close}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
        </svg>
      </button>
      <div className="w-full tracking-wider uppercase text-orange font-bold text-center pb-2 leading-none px-4">
        Update {profile.nickname}'s Profile
      </div>
      <div className="flex w-full">
        {/* <div className="w-content flex items-center flex-col mr-6">
          <Avatar svg={profile.avatar_url} className="w-32" alt="" full />
        </div> */}
        <div className="w-84">
          {Object.keys(profile).length > 0 && (
            <div className="flex flex-col items-start bg-gray-100 rounded-lg p-2 mt-2 w-full">
              <NameRow profile={profile} updateProfile={updateProfile} />
              <DOBRow profile={profile} updateProfile={updateProfile} />
              <GenderRow profile={profile} updateProfile={updateProfile} />
              <CountryRow
                profile={profile}
                updateProfile={updateProfile}
                countries={countriesList}
              />
            </div>
          )}
          <div className="w-full mt-2">
            <div className="bg-gray-100 rounded-lg p-2 flex items-center justify-between">
              <span className="text-sm">Payment</span>
              {!subscribed ? (
                quoteSent ? (
                  <div
                    className="text-sm p-2 leading-none bg-gray-200 text-orange font-medium rounded-lg"
                    onClick={handlePaymentSend}
                  >
                    Sent Quote
                  </div>
                ) : (
                  <button
                    className="text-sm p-2 leading-none bg-orange text-white font-medium rounded-lg"
                    onClick={handlePaymentSend}
                  >
                    Send Payment Details
                  </button>
                )
              ) : (
                <div className="text-sm p-2 leading-none bg-gray-200 text-green-500 font-medium rounded-lg">
                  Subscribed
                </div>
              )}
            </div>
          </div>
          {subscribed && (
            <div className="w-full mt-2">
              <div className="bg-gray-100 rounded-lg p-2 flex items-center justify-between">
                <span className="text-sm">Onboarding Status</span>
                {!onboarded ? (
                  <button
                    className="text-sm p-2 leading-none bg-orange text-white font-medium rounded-lg"
                    onClick={handleOnboarded}
                  >
                    Mark Completed
                  </button>
                ) : (
                  <div className="text-sm p-2 leading-none bg-gray-200 text-green-500 font-medium rounded-lg">
                    Complete
                  </div>
                )}
              </div>
            </div>
          )}
          {subscribed && onboarded && (
            <div className="w-full mt-2">
              <div className="bg-gray-100 rounded-lg p-2 flex items-center justify-between">
                <span className="text-sm">Domain</span>
                {!profile.subdomain ? (
                  activateSent ? (
                    <div className="text-sm p-2 leading-none bg-gray-200 text-orange font-medium rounded-lg">
                      Sent Confimation
                    </div>
                  ) : (
                    <button
                      className="text-sm p-2 leading-none bg-orange text-white font-medium rounded-lg"
                      onClick={handleActivateDomain}
                    >
                      Send Domain Confirmation
                    </button>
                  )
                ) : (
                  <div className="text-sm p-2 leading-none bg-gray-200 text-green-500 font-medium rounded-lg">
                    Active ({profile.subdomain})
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default MemberUpdateModal;
