import getRandomString from "./../utils/getRandomString";

function JanusStore() {
  const transactionsMap = {};
  window.transactionsMap = transactionsMap;

  const getCallback = (transactionId) => {
    return transactionsMap[transactionId];
  };

  const storeCallback = (callback) => {
    const uniqueTransactionId = getRandomString(12);
    transactionsMap[uniqueTransactionId] = callback;
    return uniqueTransactionId;
  };

  return {
    getCallback,
    storeCallback,
  };
}

export default JanusStore;
