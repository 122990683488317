import React, { useEffect, useState } from "react";

import StarRating from "../../../../../ui/StarRating";
import ZoHeaderStat from "../../../../../ui/ZoHeaderStat";
import useApi from "../../../../../hooks/useApi";
import { useSeed } from "../../../contexts/seed";

const Ratings = () => {
  const api = useApi("feedback", false);
  const {
    style: { primaryColor, textSecondaryColor },
  } = useSeed();

  const [rating, setRating] = useState(null);

  useEffect(() => {
    if (api) {
      api
        .get(`/api/v1/rating/global/`)
        .then((res) => {
          setRating(res.data.rating);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [api]);

  return (
    <ZoHeaderStat
      className={`zo-head_rating whitespace-no-wrap w-auto bg-${
        primaryColor ? primaryColor : "white"
      }`}
      titleClassName={textSecondaryColor ? `text-${textSecondaryColor}` : ""}
      value={rating != null ? <StarRating outOf={5} rating={rating} /> : "..."}
      title={"Rating " + rating}
    />
  );
};

export default Ratings;
