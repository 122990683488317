import React, { useEffect, useState } from "react";

interface AutoExpandingTextAreaProps {
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  value: string;
  className?: string;
  placeholder?: string;
}

const AutoExpandingTextArea = React.forwardRef<
  HTMLTextAreaElement,
  AutoExpandingTextAreaProps
>(({ onChange, value, className, placeholder }, ref) => {
  const [height, setHeight] = useState<string>("inherit");

  useEffect(() => {
    const _ref: any = ref;
    if (_ref) {
      setHeight(`${_ref.current.scrollHeight}px`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setHeight(`${e.target.scrollHeight}px`);
    onChange(e);
  };
  return (
    <textarea
      ref={ref}
      onChange={handleChange}
      placeholder={placeholder}
      className={className}
      value={value}
      style={{ height }}
    />
  );
});

export default AutoExpandingTextArea;
