import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import ProfileContext from "../../../../contexts/profile";
import useApi from "../../../../hooks/useApi";
import RoomContext from "../../contexts/room";
import Member from "./components/Member";
import Timer from "./components/Timer";

const MIN_PLAYER = 3;

const MafiaQueue = () => {
  const { profile } = useContext(ProfileContext);
  const {
    members,
    sendSocketMessage,
    message,
    connecting,
    roomDetails,
  } = useContext(RoomContext);
  const history = useHistory();
  const location = useLocation();
  const api = useApi("comms", true);

  const [eta, setEta] = useState(null);

  const [queueMembers, setQueueMembers] = useState([]);

  useEffect(() => {
    if (roomDetails) {
      setQueueMembers(roomDetails.queue_members || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (api) {
      api
        .get("/lobby/api/v1/room/mafia/info/queue/")
        .then((res) => {
          setEta(res.data.eta.nextGameAt);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [api]);

  const joinQueue = () => {
    sendSocketMessage({
      action: "joined_queue",
      payload: { code: profile.code },
    });
  };

  const queueMembersFull = useMemo(
    () =>
      (members.length > 0 &&
        queueMembers.length > 0 &&
        queueMembers
          .map((qm) => members.find((m) => m.code === qm))
          .filter((qm) => qm != null)) ||
      [],
    [members, queueMembers]
  );

  const leaveQueue = () => {
    sendSocketMessage({
      action: "left_queue",
      payload: { code: profile.code },
    });
  };

  const addToQueue = (code) => {
    if (queueMembers.indexOf(code) === -1) {
      setQueueMembers((qMs) => [...qMs, code]);
    }
  };

  const removeFromQueue = (code) => {
    setQueueMembers((qMs) => qMs.filter((qM) => qM !== code));
  };

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    if (search.get("autojoin") && connecting === false) {
      setTimeout(joinQueue, 0);
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, connecting]);

  useEffect(() => {
    if (message) {
      if (message.action === "joined_queue") {
        addToQueue(message.payload.code);
      } else if (
        message.action === "left_queue" ||
        message.action === "left" ||
        message.action === "kick"
      ) {
        removeFromQueue(message.payload.code);
      } else if (message.action === "queue_eta") {
        setEta(message.payload.nextGameAt);
      } else if (message.action === "new_match") {
        if (message.payload.players.indexOf(profile.code) !== -1) {
          window.open(
            `https://mafia.${process.env.REACT_APP_ROOT_DOMAIN}/room/${message.payload.gameCode}`,
            "_parent"
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <section className="inline-flex flex-col items-center w-full sm:w-96 h-96 sm:h-108 mb-4 sm:mx-2  bg-white-translucent-90 rounded-lg shadow-md overflow-hidden flex-shrink-0">
      <header className="w-full p-3 flex items-center justify-between">
        <span className="flex flex-col">
          <span className="font-bold">Mafia Game Queue</span>
          {queueMembers.length < MIN_PLAYER ? (
            <span className="text-sm">
              Waiting for atleast {MIN_PLAYER - queueMembers.length} player
              {queueMembers.length === MIN_PLAYER - 1 ? "" : "s"} to join
            </span>
          ) : (
            <span className="text-sm">
              Next game starting in <Timer eta={eta} />
            </span>
          )}
        </span>
        {queueMembers.find((m) => m === profile.code) ? (
          <button
            className="bg-mafia-secondary p-2 leading-none rounded-lg font-semibold uppercase tracking-wide cursor-pointer focus:outline-none text-mafia-primary"
            onClick={leaveQueue}
          >
            Leave
          </button>
        ) : (
          <button
            className="bg-mafia-secondary p-2 leading-none rounded-lg font-semibold uppercase tracking-wide cursor-pointer focus:outline-none text-mafia-primary"
            onClick={joinQueue}
          >
            Join
          </button>
        )}
      </header>
      <div className="relative flex-grow w-full h-auto overflow-y-auto scroll-visible rounded-lg bg-gray-400">
        {queueMembersFull && queueMembersFull.length > 0 ? (
          queueMembersFull.map((m) => (
            <Member key={m.code} avatar={m.avatar_url} nickname={m.nickname} />
          ))
        ) : (
          <div className="w-full h-full flex items-center justify-center text-lg font-semibold text-gray-800 bg-gray-400">
            No active players.
          </div>
        )}
      </div>
    </section>
  );
};

export default MafiaQueue;
