import React, { useMemo } from "react";

import WidgetComponent from "../../widgets";

const Widgets = ({ widgets }) => {
  const getWidgets = () => {
    const widgetComponents = [];
    widgets.forEach((widget) => {
      widgetComponents.push(
        <WidgetComponent type={widget.type} key={Math.random()} {...widget} />
      );
    });
    return widgetComponents;
  };

  return useMemo(
    () => (
      <section
        className="block max-w-screen overflow-x-hidden landscape:grid flex-grow grid-flow-row-dense grid-cols-8 gap-6 px-4 landscape:px-16 pb-6"
        style={{
          height: "calc(100vh - 24rem - 4px)",
          gridTemplateRows: "max-content",
        }}
      >
        {getWidgets()}
      </section>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [widgets]
  );
};

export default Widgets;
