import React, { useContext } from "react";

import SubSection from "../../components/SubSection";
import Section from "../../components/Section";
import ProfileContext from "../../../../../../contexts/profile";
import NameField from "./components/NameField";
import SecurityField from "./components/SecurityField";
import LinkField from "./components/LinkField";

const CommonRoomSettings = () => {
  const { profile } = useContext(ProfileContext);

  return (
    <Section title="Common Room">
      <SubSection title="General">
        <NameField />
        <LinkField />
      </SubSection>
      <SubSection title="Security">
        {profile.status === "premium" ? (
          <SecurityField />
        ) : (
          <div className="font-semibold text-gray-600">
            Buy a Visa Membership to unlock this feature and many more ...
          </div>
        )}
      </SubSection>
    </Section>
  );
};

export default CommonRoomSettings;
