import React from "react";
import Widget from "../../../../ui/Widget";

const MemeOTD = ({ props, content }) => {
  return (
    <Widget className="zo-photo bg-white" {...props}>
      <div
        className="flex flex-col justify-end w-full h-full bg-center bg-cover rounded-md"
        style={{ backgroundImage: content && `url(${content.image}?w=100&h=100&c=80)` }}
      >
        {content.caption && <div className="px-2 pt-2 pb-2 font-bold text-white zo-gradient-black-bottom rounded-md">
          <p>{content.category} of the Day</p>
          {content.caption}
        </div>}

      </div>
    </Widget>
  );
};

export default MemeOTD;
