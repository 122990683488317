import React from "react";

import bg from "../assets/oh-bg.svg";
import Portal from "../../../ui/Portal";

const ErrorBoundaryModal = () => {
  return (
    <Portal>
      <div className="w-screen h-screen fixed top-0 left-0 z-100 bg-red-100 flex flex-col items-center justify-center">
        <div className="w-3/4 portrait:w-full h-screen flex flex-col items-center portrait:justify-center px-4 pt-8 portrait:pt-0">
          <img src={bg} className="w-full" alt="" />
          <div className="text-center mt-8">
            <h1 className="text-orange font-bold text-3xl">Uh Oh!</h1>
            <h2 className="text-gray-800 font-medium text-2xl">
              Looks like you've found a broken wire.
            </h2>
            <h3>
              No worries, our team of engineers now know about this
              <br />
              and will fix this in their next Zo World shift.
            </h3>
            <h3>Try refreshing or try again later.</h3>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default ErrorBoundaryModal;
