import React, { useContext, useState } from "react";
import AppContext from "../../../../contexts/app";
import ProfileContext from "../../../../contexts/profile";
import useApi from "../../../../hooks/useApi";
import Modal from "../../../../ui/Modal";
import RoomContext from "../../contexts/room";

const SpeakabilityModal = ({ close, speakability }) => {
  const api = useApi("profile", true);
  const commsApi = useApi("comms", true);
  const { sendSocketMessage, roomDetails } = useContext(RoomContext);
  const { updateProfile } = useContext(ProfileContext);
  const { category, slug } = useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const onSuccess = (level) => {
    sendSocketMessage({
      action: "update_speakability",
      payload: { speakability: level },
    });
    setLoading(false);
  };

  const handleUpdate = async (level) => {
    if (level > 0 && level < 4) {
      if (category === "profile") {
        if (!loading) {
          setLoading(true);
          try {
            const data = {
              speakability: level,
            };
            const response = await api.post(
              `/profile/api/v1/me/`,
              JSON.stringify(data)
            );
            if (response.status === 200) {
              console.log(response);
              updateProfile(data);
              onSuccess(level);
            }
          } catch (error) {
            console.log(error.response);
          }
        }
      } else {
        if (!loading) {
          setLoading(true);
          try {
            const data = {
              speakability: level,
            };
            const response = await commsApi.post(
              `/lobby/api/v1/room/${slug || "zo"}/`,
              JSON.stringify(data)
            );
            if (response.status === 200) {
              console.log(response);
              onSuccess(level);
            }
          } catch (error) {
            console.log(error.response);
          }
        }
      }
    }
  };

  return (
    <Modal close={close} backgroundTheme="light" visible>
      <div className="w-108 portrait:w-full rounded-lg bg-white border-t-4 border-orange flex flex-col items-center">
        <span className="font-bold text-xl p-4">Speakability Settings</span>
        <div className="bg-gray-100 w-full flex flex-col items-center justify-center p-4">
          <div className="flex flex-col w-full items-center">
            <div className="flex items-center">
              <button
                className={`shadow-inner text-sm disabled:cursor-not-allowed focus:outline-none p-2 leading-none rounded-l-lg ${
                  speakability === 3
                    ? "bg-orange shadow-none text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                disabled={speakability === 3}
                onClick={handleUpdate.bind(null, 3)}
              >
                Allowed only
              </button>
              <button
                className={`shadow-inner text-sm disabled:cursor-not-allowed focus:outline-none p-2 leading-none rounded-r-lg ${
                  speakability === 1
                    ? "bg-orange shadow-none text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                disabled={speakability === 1}
                onClick={handleUpdate.bind(null, 1)}
              >
                Sweet Chaos
              </button>
            </div>
            <div className="bg-gray-200 p-2 inline-flex items-center rounded-lg my-2 text-gray-600 text-left text-xs">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                className="w-5 h-5 fill-current mr-4 flex-shrink-0"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
              </svg>
              {speakability === 3
                ? roomDetails.type === "commonroom"
                  ? "Only hosts and requested users can talk in this room."
                  : "Only you and requested users can talk in your room."
                : speakability === 1
                ? roomDetails.type === "commonroom"
                  ? "All users have the permission to talk."
                  : "All users can talk in your room."
                : null}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SpeakabilityModal;
