import React, { useEffect } from "react";
import { fireworks, schoolPride, snow } from "./../utils/confetti";
import AppProvider from "./contexts/app/provider";

const App = () => {
  console.log("[INFO] API Domain", process.env.REACT_APP_API_DOMAIN);
  console.log("[INFO] Root Domain", process.env.REACT_APP_ROOT_DOMAIN);
  console.log("[INFO] RTC Socket URL", process.env.REACT_APP_RTC_SOCKET_URL);
  console.log("[INFO] Environment", process.env.REACT_APP_ENV);

  useEffect(() => {
    window._confetti_schoolPride = schoolPride;
    window._confetti_snow = snow;
    window._confetti_fireworks = fireworks;
  }, []);

  return <AppProvider />;
};

export default App;
