import React, { useMemo, useContext, useEffect } from "react";

import ProfileContext from "../../../../contexts/profile";
import RTCContext from "../../context/rtcV2";
import RTCButton from "./components/RTCButton";
import PartyMembers from "./components/PartyMembers";

const Party = ({
  members,
  isPartyHost,
  leaveParty,
  partyCode,
  handleKick,
  partyMessage,
  sendPartyMessage,
  partyTalking,
  setPartyTalking,
}) => {
  const { setUserCode, setRoomCode } = useContext(RTCContext);
  const { profile } = useContext(ProfileContext);

  useEffect(() => {
    if (partyCode && profile.code && profile.nickname) {
      if (members.length > 1) {
        setUserCode(profile.code);
        setRoomCode(`1-${partyCode}`);
      } else {
        setUserCode(null);
        setRoomCode(null);
      }
    }
  }, [members, partyCode, profile, setRoomCode, setUserCode]);

  return useMemo(
    () =>
      members != null && members.length > 1 ? (
        <section className="flex items-center mr-4 portrait:mr-0 portrait:mb-4 bg-white rounded-lg">
          <div className="relative z-10 flex flex-col items-center">
            <span className="block font-semibold mb-2 mx-6 leading-none">
              Party
            </span>
            <div className="flex w-full justify-around items-center">
              {!isPartyHost && (
                <button
                  className="bg-orange text-white p-2 leading-none rounded-lg font-medium text-xs ml-2"
                  onClick={leaveParty}
                >
                  Leave Party
                </button>
              )}
              <RTCButton
                partyMessage={partyMessage}
                sendPartyMessage={sendPartyMessage}
                pressed={partyTalking}
                setPressed={setPartyTalking}
              />
            </div>
          </div>
          <PartyMembers
            members={members}
            partyCode={partyCode}
            handleKick={handleKick}
            isPartyHost={isPartyHost}
          />
        </section>
      ) : (
        <></>
      ),
    [
      handleKick,
      isPartyHost,
      leaveParty,
      members,
      partyCode,
      partyMessage,
      partyTalking,
      sendPartyMessage,
      setPartyTalking,
    ]
  );
};

export default Party;
