import React, { useEffect, useContext } from "react";

import RTCControlButton from "./../components/RTCControlButton";
import { useResponseFlash } from "../../../apps/Zo/components/hooks";
import Portal from "../../../ui/Portal";
import RTCContext from "../../../contexts/rtcV2";
import unmuteAudio from "./../../../../assets/unmute.mp3";
import useAudio from "../../../hooks/useAudio";

const UnmuteModal = ({ close }) => {
  const { response, setResponse } = useResponseFlash();
  const { isSelfMute } = useContext(RTCContext);

  const unmuteSound = useAudio(unmuteAudio, false, 0.3);

  useEffect(() => {
    setResponse("asdasd");
    if (unmuteSound) {
      unmuteSound.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSelfMute) {
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelfMute]);

  return (
    <Portal>
      <section
        className={`flex items-center justify-center fixed top-0 left-0 z-70 pointer-events-none w-screen h-screen ${
          response ? "bg-blur" : ""
        }`}
      >
        <div className="border-orange border-t-4 bg-white rounded-lg shadow-lg p-6 w-content h-content flex flex-col items-center pointer-events-auto">
          <div className="fadeInUp animation-delay-100 font-semibold text-lg">
            The host wants you to speak
          </div>
          <div className="fadeInUp animation-delay-300 text-gray-700 font-medium">
            Unmute and start speaking!
          </div>
          <RTCControlButton
            className="fadeInUp animation-delay-500 mt-6 mb-2"
            showSpeakingAnimation
            large
          />
          <button
            className="text-orange font-semibold hover:underline text-xs mb-2 fadeInUp animation-delay-1000"
            onClick={close}
          >
            No, thanks!
          </button>
        </div>
      </section>
    </Portal>
  );
};

export default UnmuteModal;
