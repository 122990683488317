import { parsePhoneNumberFromString } from "libphonenumber-js";
import React, { useState } from "react";
import useApi from "../../../../../../../hooks/useApi";
import SubSectionRow from "../../../components/SubSectionRow";

const WhatsappField = ({ loadingContact, mobileFields, setMobileFields }) => {
  const api = useApi("AUTH_CONTACT");

  const [expanded, setExpanded] = useState(false);

  async function handleMobileWhatsapp(i, whatsapp) {
    const values = [...mobileFields];
    console.log(values[i].whatsapp, whatsapp);
    values[i].setting = true;
    setMobileFields(values);
    try {
      const data = {
        mobile: {
          id: values[i].id,
          whatsapp: whatsapp,
        },
      };
      const response = await api.put("", JSON.stringify(data));
      console.log(response);
      if (response.status === 200) {
        const values = [...mobileFields];
        values[i].whatsapp = whatsapp;
        values[i].setting = false;
        setMobileFields([...values]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <SubSectionRow
      title="Whatsapp Updates"
      visibleArea={
        loadingContact
          ? "Fetching Whatsapp information..."
          : Array.isArray(mobileFields) &&
            mobileFields.length > 0 &&
            mobileFields[0].mobile &&
            mobileFields.filter((e) => e.whatsapp).length > 0 && (
              <ul>
                {mobileFields
                  .filter((e) => e.whatsapp)
                  .map((e) => {
                    const formattedNumber = parsePhoneNumberFromString(
                      `+${e.mobile_country_code}${e.mobile}`
                    );
                    return (
                      <li key={e.id}>
                        {formattedNumber.formatInternational()}
                      </li>
                    );
                  })}
              </ul>
            )
      }
      placeholder="You are currently not receiving any Whatsapp updates on your mobile number(s)."
      isExpanded={expanded}
      setExpanded={setExpanded}
    >
      <div className="flex w-full flex-col items-start">
        {mobileFields.map((field, idx) => {
          const formattedNumber = parsePhoneNumberFromString(
            `+${field.mobile_country_code}${field.mobile}`
          );
          return (
            <div
              key={`${field}-${idx}`}
              className="flex items-center w-full py-4"
            >
              <span className="font-medium w-84">
                {formattedNumber.formatInternational()}
              </span>
              <label className="whitespace-no-wrap">
                <input
                  type="checkbox"
                  className="form-checkbox text-orange mr-2"
                  checked={field.whatsapp}
                  onChange={(e) => handleMobileWhatsapp(idx, e.target.checked)}
                  disabled={field.setting}
                />
                {field.setting ? "Updating" : "Count me in!"}
              </label>
            </div>
          );
        })}
        <button
          className="block bg-white text-orange cursor-pointer mt-2 mb-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
          onClick={setExpanded.bind(null, false)}
        >
          OK
        </button>
      </div>
    </SubSectionRow>
  );
};

export default WhatsappField;
