import React, { useContext, useEffect, useState } from "react";

import UnmuteModal from "./UnmuteModal";
import MemberUpdateModal from "./MemberUpdateModal";
import ActivateDomainModal from "./ActivateDomainModal";
import OnboardedModal from "./OnboardedModal";
import PaymentCompleteModal from "./PaymentCompleteModal";
import PaymentQuoteModal from "./PaymentQuoteModal";
import RoomContext from "../contexts/room";
import ProfileContext from "../../../contexts/profile";

const CommonRoomModals = () => {
  const { sendSocketMessage, message } = useContext(RoomContext);
  const { profile } = useContext(ProfileContext);

  const [unmuteModalVisible, setUnmuteModalVisible] = useState(false);
  const [memberEditModal, setMemberEditModal] = useState(false);
  const [paymentQuoteVisible, setPaymentQuoteVisible] = useState(false);
  const [paymentCompleteVisible, setPaymentCompleteVisible] = useState(false);
  const [activateDomainVisible, setActivateDomainVisible] = useState(false);
  const [onboardedVisible, setOnboardedVisible] = useState(false);

  useEffect(() => {
    if (message) {
      if (
        message.action === "unmute_request" &&
        message.payload.to.nickname === profile.nickname
      ) {
        setUnmuteModalVisible(true);
      }
      if (message.action === "member_onboarded") {
        if (message.payload.code) {
          if (message.payload.code === profile.code) {
            setOnboardedVisible(true);
          }
        }
      }
      if (message.action === "payment_quote") {
        if (message.payload.code) {
          if (message.payload.code === profile.code) {
            setPaymentQuoteVisible(true);
          }
        }
      }
      if (message.action === "activate_domain") {
        if (message.payload.code) {
          if (message.payload.code === profile.code) {
            setActivateDomainVisible(true);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <>
      {unmuteModalVisible && (
        <UnmuteModal close={setUnmuteModalVisible.bind(null, false)} />
      )}
      {memberEditModal && (
        <MemberUpdateModal
          sendSocketMessage={sendSocketMessage}
          code={memberEditModal}
          close={setMemberEditModal.bind(null, false)}
          message={message}
        />
      )}
      {onboardedVisible && (
        <OnboardedModal close={setOnboardedVisible.bind(null, false)} />
      )}
      {paymentQuoteVisible && (
        <PaymentQuoteModal
          visible
          close={setPaymentQuoteVisible.bind(null, false)}
          showPaymentForm={() => {
            setPaymentQuoteVisible(false);
            setPaymentCompleteVisible(true);
          }}
        />
      )}
      {paymentCompleteVisible && (
        <PaymentCompleteModal
          close={setPaymentCompleteVisible.bind(null, false)}
        />
      )}
      {activateDomainVisible && (
        <ActivateDomainModal
          close={setActivateDomainVisible.bind(null, false)}
        />
      )}
    </>
  );
};

export default CommonRoomModals;
