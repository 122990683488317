import React from "react";
import "react-phone-input-2/lib/material.css";

import "./index.css";
import PhoneInput from "react-phone-input-2";

const MobileInput = ({ className, onChange, value, placeholder, disabled }) => {
  return (
    <div className={`mobile-input ${className ? className : ""}`}>
      <PhoneInput
        country={"in"}
        inputClass={"form-input"}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        searchPlaceholder="Search for your country"
        enableSearch={true}
        tabIndex={-1}
        disabled={disabled}
      />
    </div>
  );
};

export default MobileInput;
