import React, { useState, useEffect } from "react";
import useApi from "../../../../hooks/useApi";

import GameCard from "./components/GameCard";

const MafiaPublicGames = () => {
  const api = useApi("games", true);

  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    if (api) {
      api
        .get("/games/api/v1/mafia/rooms/public/")
        .then((res) => {
          console.log("Rooms List", res);
          setRooms(res.data.rooms);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <section className="inline-flex flex-col items-center w-full sm:w-96 h-96 sm:h-108 mb-4 sm:mx-2  bg-white-translucent-90 rounded-lg shadow-md overflow-hidden flex-shrink-0">
      <header className="w-full p-3 flex items-center justify-between">
        <span className="font-bold">Active Games</span>
      </header>
      <div className="relative flex-grow w-full h-auto overflow-y-auto scroll-visible rounded-lg">
        {rooms.length > 0 ? (
          <ul className="h-auto p-2 bg-gray-400">
            {rooms.map((room) => (
              <GameCard cardData={room} key={room.code} />
            ))}
          </ul>
        ) : (
          <div className="w-full h-full flex items-center justify-center text-lg font-semibold text-gray-800 bg-gray-400">
            There are no active rooms right now.
          </div>
        )}
      </div>
    </section>
  );
};

export default MafiaPublicGames;
