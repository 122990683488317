import React from "react";

import OurChallenges from "./OurChallenges";
import HotDeal from "./HotDeal";
import DestinationUnlocked from "./DestinationUnlocked";
import BlogArticles from "./BlogArticles";
import PhotoOTD from "./PhotoOTD";
import ContenTOTD from "./ContentOTD";
import ZobuOTD from "./ZobuOTD";
import AssetOTD from "./AssetOTD";
import Happiness from "./Happiness";
import LeaderBoard from "./Leaderboard";
import Timeline from "./Timeline";
import Population from "./Population";
import Cults from "./Cults";
import PageTitle from "./PageTitle";
import PopulationMetrics from "./PopulationMetrics";
import Portal from "./Portal";
import MembersList from "./MembersList";
import Weather from "./Weather";
import Services from "./Services";
import TalkToHost from "./TalkToHost";
import LifeAt from "./LifeAt";
import WidgetErrorBoundary from "../ui/WidgetErrorBoundary";
import GameCharacters from "./GameCharacters";
import GamesList from "./GamesList";
import MafiaActions from "./MafiaActions";
import MafiaQuickJoin from "./MafiaQuickJoin";

const WidgetComponent = (props) => {
  const getWidget = () => {
    switch (props.type) {
      case "challenges":
        return <OurChallenges {...props} />;
      case "hot-deal":
        return <HotDeal {...props} />;
      case "photo-otd-potrait":
        return <PhotoOTD {...props} />;
      case "destinations-unlocked":
        return <DestinationUnlocked {...props} />;
      case "zobu-otd":
        return <ZobuOTD {...props} />;
      case "asset-otd":
        return <AssetOTD {...props} />;
      case "content-otd":
        return <ContenTOTD {...props} />;
      case " BlogArticles":
        return <BlogArticles {...props} />;
      case "happiness":
        return <Happiness {...props} />;
      case "leaderboard":
        return <LeaderBoard {...props} />;
      case "timeline":
        return <Timeline {...props} />;
      case "population":
        return <Population {...props} />;
      case "cults":
        return <Cults {...props} />;
      case "population-metrics":
        return <PopulationMetrics {...props} />;
      case "page-title":
        return <PageTitle {...props} />;
      case "portal":
        return <Portal {...props} />;
      case "members-list":
        return <MembersList {...props} />;
      case "weather":
        return <Weather {...props} />;
      case "services":
        return <Services {...props} />;
      case "talk-to-host":
        return <TalkToHost {...props} />;
      case "life-at":
        return <LifeAt {...props} />;
      case "blog":
        return <BlogArticles />;
      case "game-characters":
        return <GameCharacters {...props} />;
      case "games-list":
        return <GamesList {...props} />;
      case "mafia-actions":
        return <MafiaActions {...props} />;
      case "mafia-quick-join":
        return <MafiaQuickJoin {...props} />;
      default:
        return null;
    }
  };

  return <WidgetErrorBoundary {...props}>{getWidget()}</WidgetErrorBoundary>;
};

export default WidgetComponent;
