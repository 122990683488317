import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import ZoHeaderStat from "./../../../../../ui/ZoHeaderStat";
import happinessIcon from "././../../../../../../assets/happiness.svg";
import useLocationActive from "../../hooks/useLocationActive";
import AuthContext from "../../../../../contexts/auth";
import Modal from "../../../../../ui/Modal";

const Happiness = () => {
  const { getCookie, setCookie, api, isLoggedIn, publicApi } = useContext(
    AuthContext
  );
  const history = useHistory();
  const isActive = useLocationActive("/happiness");

  const [happinessCount, setHappinessCount] = useState(null);
  const [happinessModal, setHappinessModal] = useState(false);

  useEffect(() => {
    publicApi
      .get(`/zoworld/api/v1/happiness/`)
      .then((res) => {
        console.log(res.data);
        setHappinessCount(res.data.happiness.percent);
      })
      .catch((e) => {
        console.log(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHappiness = () => {
    const dailyScored = getCookie("happinessDaily");
    if (!dailyScored.length && isLoggedIn) {
      setHappinessModal(true);
      history.push("/happiness");
    } else {
      history.push("/happiness");
    }
  };

  const submitHappiness = async (score) => {
    try {
      const data = {
        score,
      };
      await api
        .post(`/zoworld/api/v1/happiness/submit/`, JSON.stringify(data))
        .then((res) => {
          if (res.data) {
            var expire;
            expire = new Date();
            expire.setDate(expire.getDate() + 1);
            expire.setUTCHours(0, 0, 0, 0);
            setCookie(
              "happinessDaily",
              JSON.stringify(res.data.happiness_status),
              expire
            );
            setHappinessModal(false);
            window.open("https://happiness.zo.xyz");
          }
        });
    } catch (e) {
      console.log("e", e);
    }
  };

  return (
    <>
      <ZoHeaderStat
        className="zo-head_happiness"
        value={happinessCount ? `${happinessCount}%` : "..."}
        icon={happinessIcon}
        active={isActive}
        title={"Happiness"}
        onClick={handleHappiness}
      />

      <Modal visible={happinessModal} close={() => setHappinessModal(false)}>
        <div className="relative z-40 w-108 p-4 rounded-lg overflow-hidden shadow-lg mb-10 bg-white">
          <div
            className="font-bold absolute right-0 mr-4"
            onClick={() => setHappinessModal(false)}
          >
            <span className="cursor-pointer text-lg">x</span>
          </div>
          <div className="text-center text-xl">How Was Today ?</div>
          <div className="grid grid-cols-2 mt-12 gap-4 flex justify-evenly">
            <div
              className="hover:bg-white hover:shadow-md p-2 rounded-lg cursor-pointer"
              onClick={() => submitHappiness(0)}
            >
              <img src={happinessIcon} alt="bad" />
              <div className="text-center">Sad</div>
            </div>
            <div
              className="hover:bg-white hover:shadow-md p-2 rounded-lg cursor-pointer"
              onClick={() => submitHappiness(1)}
            >
              <img src={happinessIcon} alt="bad" />
              <div className="text-center">Happy</div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Happiness;
