import { createContext } from "react";

const SeedContext = createContext({
  avatarTools: { categories: [], bases: [] },
  seedData: {},
  backgrounds: {},
  mobileBackgrounds: {},
  musicFiles: null,
  abuseTypes: [],
});

export default SeedContext;
