import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import AuthContext from "../../contexts/auth";
import AppContext from "../../contexts/app";
import Home from "./pages/Home";
import useApi from "../../hooks/useApi";
import { normaliseRoomData } from "./utils";
import useSocket from "../../hooks/useSocket";
import RoomContext from "./contexts/room";
import RTCProvider from "../../contexts/rtcV2/provider";
import ProfileContext from "../../contexts/profile";
import WelcomeModal from "./modals/WelcomeModal";
import Mafia from "../../games/Mafia";
import MafiaRedirect from "../../commons/MafiaRedirect";
import bg from "./../../../assets/pr-bg.svg";
import NicknameModal from "./modals/NicknameModal";
import Settings from "../Settings";
import AvatarSection from "../../ui/AvatarSection";

const CommonRoom = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const { profile } = useContext(ProfileContext);
  const { slug, category } = useContext(AppContext);
  const commsApi = useApi("comms", true);
  const commsPublicApi = useApi("comms", false);
  const {
    connecting,
    members,
    message,
    sendSocketMessage,
    setMembers,
    setSocketUrl,
    setToken,
  } = useSocket("ROOM");

  const [loading, setLoading] = useState(true);
  const [roomDetails, setRoomDetails] = useState(null);

  useEffect(() => {
    let mounted = true;
    if (isLoggedIn && profile) {
      if (category === "commonroom" && commsApi) {
        commsApi
          .get(`/lobby/api/v1/room/${slug || "zo"}/info/`)
          .then((res) => {
            console.log("Room Details:", res);
            if (mounted) {
              setRoomDetails(normaliseRoomData(category, res.data));
              setLoading(false);
            }
          })
          .catch((e) => {
            console.log(e);
            if (e.response.status === 401) {
              // setPremiumVisible(true);
            }
          });
      } else if (category === "profile" && commsPublicApi) {
        if (profile.subdomain === slug || slug === "me") {
          commsApi
            .get("/profile/api/v1/me/lobby/")
            .then((res) => {
              console.log("My Lobby", res);
              if (mounted) {
                setRoomDetails(normaliseRoomData(category, res.data));
                setLoading(false);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          commsPublicApi
            .get(`/profile/api/v1/profile/lobby/${slug}/`)
            .then((res) => {
              console.log("Room Details:", res);
              if (mounted) {
                setRoomDetails(normaliseRoomData(category, res.data));
                setLoading(false);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    }

    return () => {
      mounted = false;
    };
  }, [isLoggedIn, category, commsApi, slug, commsPublicApi, profile]);

  return isLoggedIn ? (
    loading ? (
      <></>
    ) : profile && !profile.nickname ? (
      <main
        className="w-screen h-screen flex sm:p-2 bg-cover bg-bottom border-orange border-t-2"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <NicknameModal next={() => {}} />
      </main>
    ) : (
      <BrowserRouter>
        {slug === "mafia" ? (
          <Switch>
            <Route path="/settings">
              <Settings forceVisible />
            </Route>
            <Route path="/zobu/editor">
              <AvatarSection forceVisible />
            </Route>
            <Route path="/r/:id" component={Mafia} />
            <Route path="/room/:id" component={Mafia} />
            <Route path="/season/finals" exact component={MafiaRedirect} />
            <Route path="/">
              <RTCProvider
                record={
                  process.env.NODE_ENV === "production"
                    ? roomDetails.code
                    : null
                }
                type="room"
              >
                <RoomContext.Provider
                  value={{
                    roomDetails,
                    setToken,
                    setSocketUrl,
                    setMembers,
                    message,
                    members,
                    connecting,
                    sendSocketMessage,
                  }}
                >
                  <Home />
                </RoomContext.Provider>
              </RTCProvider>
            </Route>
          </Switch>
        ) : (
          <Switch>
            <Route path="/settings">
              <Settings forceVisible />
            </Route>
            <Route path="/zobu/editor">
              <AvatarSection forceVisible />
            </Route>
            <Route path="/">
              <RTCProvider
                record={
                  process.env.NODE_ENV === "production"
                    ? roomDetails.code
                    : null
                }
                type="room"
              >
                <RoomContext.Provider
                  value={{
                    roomDetails,
                    setToken,
                    setSocketUrl,
                    setMembers,
                    message,
                    members,
                    connecting,
                    sendSocketMessage,
                  }}
                >
                  <Home />
                </RoomContext.Provider>
              </RTCProvider>
            </Route>
          </Switch>
        )}
      </BrowserRouter>
    )
  ) : (
    <main
      className="w-screen h-screen flex sm:p-2 bg-cover bg-bottom border-orange border-t-2"
      style={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <WelcomeModal />
    </main>
  );
};

export default CommonRoom;
