import React, { useCallback, useContext, useEffect, useState } from "react";
import { useAuth, useProfile } from "../../../contexts";
import GameContext from "../contexts/game";
import ModalProvider from "../contexts/modal/provider";
import RoomContext from "../contexts/room";
import SocketContext from "../contexts/socket";
import TimeProvider from "../contexts/time/provider";
import GameScene from "./Game";
import IntroScene from "./Intro";
import JoiningScene from "./Joining";

const Scenes = () => {
  const { room } = useContext(RoomContext);
  const { isLoggedIn } = useAuth();
  const { profile } = useProfile();
  const { update } = useContext(GameContext);
  const { startSocketConnection, setToken, socket, connecting } =
    useContext(SocketContext);

  const [scene, setScene] = useState("intro");

  const handleSpectate = useCallback(() => {
    const anon_user_token = localStorage.getItem("anon_user_token");
    const anon_user = localStorage.getItem("anon_user");
    const anon_token_expiry = localStorage.getItem("anon_token_expiry");
    const anon_nickname = localStorage.getItem("anon_nickname");
    if (isLoggedIn) {
      if (profile) {
        update([
          "PLAYER",
          {
            auth_user_id: profile.code,
            status: "spectate",
            nickname: profile.nickname,
            passport_id: profile.code,
            role: "",
            roleCard: "",
            vote: null,
          },
        ]);
        if (
          room.status === "started" ||
          room.status === "ended_mafia" ||
          room.status === "ended_village"
        ) {
          setScene("game");
        } else {
          setScene("joining");
        }
      }
    } else if (
      anon_nickname &&
      anon_token_expiry &&
      anon_user &&
      anon_user_token &&
      JSON.parse(anon_user) &&
      JSON.parse(anon_user).app_id &&
      JSON.parse(anon_user).user_id
    ) {
      update([
        "PLAYER",
        {
          auth_user_id: JSON.parse(anon_user).id,
          status: "spectate",
          nickname: anon_nickname,
          role: "",
          roleCard: "",
          vote: null,
        },
      ]);
      if (
        room.status === "started" ||
        room.status === "ended_mafia" ||
        room.status === "ended_village"
      ) {
        setScene("game");
      } else {
        setScene("joining");
      }
    }
  }, [isLoggedIn, profile, room.status, update]);

  useEffect(() => {
    if (profile) {
      const _player = room.players.find((p) => p.passport_id === profile.code);
      if (_player) {
        update(["PLAYER", _player]);
        if (
          room.status === "started" ||
          room.status === "ended_mafia" ||
          room.status === "ended_village"
        ) {
          setScene("game");
        } else {
          setScene("joining");
        }
      } else {
        handleSpectate();
      }
    } else if (localStorage.getItem("anon_user")) {
      const anonUser = localStorage.getItem("anon_user")
        ? JSON.parse(localStorage.getItem("anon_user"))
        : null;
      if (anonUser) {
        const _player = room.players.find(
          (p) => p.auth_user_id === anonUser.id
        );
        if (_player) {
          update(["PLAYER", _player]);
          if (
            room.status === "started" ||
            room.status === "ended_mafia" ||
            room.status === "ended_village"
          ) {
            setScene("game");
          } else {
            setScene("joining");
          }
        } else {
          handleSpectate();
        }
      }
    } else {
      setScene("intro");
    }
  }, [handleSpectate, room.players, room.status, update, profile]);

  useEffect(() => {
    if (isLoggedIn) {
      localStorage.removeItem("anon_user_token");
      localStorage.removeItem("anon_user");
      localStorage.removeItem("anon_token_expiry");
      localStorage.removeItem("anon_nickname");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    startSocketConnection(room.connection_path);
    if (localStorage.getItem("anon_user_token")) {
      setToken(localStorage.getItem("anon_user_token"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room.connection_path]);

  return scene === "joining" ? (
    <JoiningScene showGame={setScene.bind(null, "game")} />
  ) : scene === "game" ? (
    socket && !connecting ? (
      <ModalProvider>
        <TimeProvider>
          <GameScene />
        </TimeProvider>
      </ModalProvider>
    ) : (
      <div className="fixed top-0 w-full h-full flex items-center justify-center bg-mafia-background">
        <div className="bg-mafia-primary flex items-center border-t-4 border-mafia-secondary justify-center w-84 h-56 text-white text-xl font-bold rounded-lg shadow-lg">
          <p>Connecting to room</p>
        </div>
      </div>
    )
  ) : (
    <IntroScene
      join={setScene.bind(null, "joining")}
      spectate={setScene.bind(null, "game")}
    />
  );
};

export default Scenes;
