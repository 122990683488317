import React, { useEffect, useState } from "react";
import moment from "moment";
import populationIcon from "./../../../../../../../../assets/populations/Population.svg";
import timeIcon from "./../../../../../../../../assets/populations/Time.svg";
import timebg from "./../../../../../../../../assets/populations/Timebg.svg";

// const _timelines = [
//   'Jan-Mar 2015',
//   'Apr-Jun 2015',
//   'Jul-Sept 2015',
//   'Oct-Dec 2015',
//   'Jan-Mar 2016',
//   'Apr-Jun 2016',
//   'Jul-Sept 2016',
//   'Oct-Dec 2016',
//   'Jan-Mar 2017',
//   'Apr-Jun 2017',
//   'Jul-Sept 2017',
//   'Oct-Dec 2017',
//   'Jan-Mar 2018',
//   'Apr-Jun 2018',
//   'Jul-Sept 2018',
//   'Oct-Dec 2018',
//   'Jan-Mar 2019',
//   'Apr-Jun 2019',
//   'Jul-Sept 2019',
//   'Oct-Dec 2019',
//   'Jan-Mar 2020',
//   'Apr-Jun 2020',
//   'Jul-Sept 2020',
//   'Oct-Dec 2020',
// ]

const TimeLine = ({ populationMetrics, humanize }) => {
  const [zoTimelines, setZoTimeLines] = useState(null);

  useEffect(() => {
    if (populationMetrics) {
      populationMetrics.date_joined_month.map((item) => {
        item.month = moment(item.month).format("MMM YYYY");
        return item;
      });

      setZoTimeLines(populationMetrics.date_joined_month.reverse());
    }
  }, [populationMetrics]);

  return (
    <>
      <div className="table-fixed portrait:mt-4">
        <div className="text-center font-bold  py-2 relative flex items-center justify-center">
          <img className="h-6" src={timeIcon} alt="" />
          <span className="ml-4 text-xl">By Time</span>
        </div>

        <div
          className="overflow-y-scroll overflow-x-hidden"
          style={{ height: "62vh" }}
        >
          {zoTimelines &&
            zoTimelines.map((item) => {
              return (
                // <tr>
                // <td className="px-4 py-2 ">
                <div
                  key={item.month}
                  className=" px-4 py-2  my-2 mx-2 flex items-center bg-white rounded-lg shadow-md p-2 justify-between"
                  style={{
                    backgroundImage: `url(${timebg})`,
                    backgroundSize: "cover",
                  }}
                >
                  <div className="text-sm my-5">
                    <p className="text-gray-900 text-md leading-none">
                      {item.month}
                    </p>
                  </div>
                  <div>
                    <div className="relative flex justify-center">
                      <img className="h-6" src={populationIcon} alt="" />
                      <span className="inline-block ml-2">
                        {humanize(item.count)}
                      </span>
                    </div>
                  </div>
                </div>
                //   </td>
                // </tr>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default TimeLine;
