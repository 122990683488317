import React, { useEffect, useState } from "react";
// import indiaFlag from "./../../../../../assets/india.svg";
import populationIcon from "./../../../../../assets/populations/Population.svg";
// import ZoHeaderStat from "../../../../ui/ZoHeaderStat";

// const _destinations = [
//   'Bir',
//   'Jaipur',
//   'Delhi'
// ]

const Destinations = ({ seedData, humanize, populationMetrics }) => {
  const [destinations, setDestinations] = useState([]);

  useEffect(() => {
    if (seedData && populationMetrics) {
      seedData?.destinations?.map((destination) => {
        const data = {
          ...destination,
          count: populationMetrics.destination.find(
            (item) => item.id === destination.id
          )
            ? populationMetrics.destination.find(
                (item) => item.id === destination.id
              ).count
            : 0,
        };
        setDestinations((d) => [...d, data].sort((a, b) => b.count - a.count));
        return true;
      });
    }
  }, [seedData, populationMetrics]);

  return (
    <>
      <table className="table-fixed">
        <thead>
          <tr>
            <th className="w-1/2 px-4 py-2 text-xl">By Destinations</th>
          </tr>
        </thead>
        <div className="overflow-scroll pb-48" style={{ height: "80vh" }}>
          {destinations && destinations.length
            ? destinations.map((destination) => {
                return (
                  // <tr className="p-2" >
                  //   <td className="px-4 py-2 ">
                  <div className="  px-4 py-2  my-2 mx-2 flex items-center bg-white rounded-lg shadow-md p-2 justify-between">
                    {/* <img className="w-10 h-10 rounded-full mr-4" src={indiaFlag} alt="flag" /> */}
                    {/* <div className='text-5xl -my-2 mr-4'> { country.emoji }</div> */}
                    <div className="text-sm my-5">
                      <p className="text-gray-900 text-md leading-none">
                        {destination.name}
                      </p>
                    </div>
                    <div>
                      <div className="relative flex justify-center">
                        <img className="h-6" src={populationIcon} alt="" />
                        <span className="inline-block ml-2">
                          {destination.count
                            ? humanize(destination.count)
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                  //   </td>
                  // </tr>
                );
              })
            : ""}
        </div>
      </table>
    </>
  );
};

export default Destinations;
