import React, { useEffect, useState } from "react";

import GeneralSettings from "./panes/GeneralSettings";
import CommonRoomSettings from "./panes/CommonRoomSettings";
import NotificationSettings from "./panes/NotificationSettings";
import VisaSettings from "./panes/VisaSettings";
import SocialSettings from "./panes/SocialSettings";
import useApi from "../../../../hooks/useApi";

const ContentPane = ({ activePane }) => {
  const authApi = useApi("auth", true);
  const profileApi = useApi("profile", true);

  const [countriesList, setCountriesList] = useState([]);
  const [emailFields, setEmailFields] = useState([]);
  const [mobileFields, setMobileFields] = useState([]);
  const [loadingContact, setLoadingContact] = useState(true);

  useEffect(() => {
    setLoadingContact(true);
    if (authApi) {
      authApi
        .get("/auth/api/v1/contact/")
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            if (response.data.mobiles.length) {
              setMobileFields(response.data.mobiles);
            } else {
              setMobileFields([
                {
                  mobile: "",
                  mobile_country_code: "",
                  verified: false,
                  primary: false,
                },
              ]);
            }

            if (response.data.emails.length) {
              setEmailFields(response.data.emails);
            } else {
              setEmailFields([{ email: "", verified: false, primary: false }]);
            }
            setLoadingContact(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authApi]);

  useEffect(() => {
    if (profileApi) {
      profileApi
        .get("/profile/api/v1/locations/countries/")
        .then((res) => {
          setCountriesList(res.data.countries);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [profileApi]);

  switch (activePane) {
    case "general":
      return (
        <GeneralSettings
          countriesList={countriesList}
          emailFields={emailFields}
          setEmailFields={setEmailFields}
          mobileFields={mobileFields}
          setMobileFields={setMobileFields}
          loadingContact={loadingContact}
        />
      );
    case "social":
      return <SocialSettings />;
    case "common-room":
      return <CommonRoomSettings />;
    case "notification":
      return (
        <NotificationSettings
          emailFields={emailFields}
          setEmailFields={setEmailFields}
          mobileFields={mobileFields}
          setMobileFields={setMobileFields}
          loadingContact={loadingContact}
        />
      );
    case "visa":
      return <VisaSettings />;
    default:
      return null;
  }
};

export default ContentPane;
