import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useLocationActive = (path) => {
  const location = useLocation();

  const [isActive, setActive] = useState(false);

  useEffect(() => {
    if (location.pathname === path) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location.pathname, path]);

  return isActive;
};

export default useLocationActive;
