import React, { useContext, useState } from "react";

import useApi from "../../../hooks/useApi";
import useInput from "../../../hooks/useInput";
import useResponseFlash from "../../../hooks/useResponseFlash";
import footer1 from "./../../../../assets/footer-1.svg";
import ProfileContext from "./../../../contexts/profile";
import Modal from "./../../../ui/Modal";

const ActivateDomainModal = ({ close }) => {
  const { profile, updateProfile } = useContext(ProfileContext);
  const api = useApi("profile", true);
  const { response, setResponse } = useResponseFlash();
  const { value: nickname, bind: bindNickname } = useInput("");

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleConfirm = async () => {
    if (!loading) {
      setLoading(true);
      try {
        const res = await api.post(`/profile/api/v1/me/domain/`);
        console.log("domain response", res);
        if (res.status === 200) {
          updateProfile({ subdomain: profile.nickname });
          setResponse("Success!");
          setTimeout(close, 1000);
        } else {
          setResponse("Error in activating domain.");
        }
      } catch (error) {
        console.log(error);
        setResponse("Error in activating domain.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (editMode) {
      if (api) {
        if (!/^[a-zA-Z0-9-]{6,32}$/.test(nickname)) {
          setResponse(
            "Only [A-Z, a-z, 0-9 or -] and 6 to 32 characters in length allowed"
          );
          return;
        }
        try {
          const data = {
            nickname: nickname,
          };
          console.log(data);

          const response = await api.post(
            `/profile/api/v1/me/`,
            JSON.stringify(data)
          );
          if (response.status === 200) {
            updateProfile(data);
            setEditMode(false);
          }
        } catch (e) {}
      }
    } else {
      setEditMode(true);
    }
  };

  return (
    <Modal
      close={close}
      visible
      backgroundTheme="light"
      hideCloseButton
      disableCloseOutside
    >
      <div className="relative border-orange border-t-4 bg-white p-6 pb-0 rounded-lg shadow-2xl h-120 portrait:h-auto w-144 portrait:w-full flex flex-col items-center">
        <header className="font-black text-3xl fadeInUp animation-delay-100 text-center mb-4">
          Set your <span className="text-orange">Digital Address</span>
        </header>
        <section className="mb-4 flex-col flex items-center text-center">
          <div className="font-medium">
            As a Zo World user, your digital address is the URL that you’ll
            share with people to find you. Set yours here.
          </div>

          <form
            className="bg-gray-200 rounded-lg flex items-center justify-between p-4 w-full mt-4"
            onSubmit={handleUpdate}
          >
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                className="mr-4 w-6 h-6"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M17 7h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c1.65 0 3 1.35 3 3s-1.35 3-3 3h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c2.76 0 5-2.24 5-5s-2.24-5-5-5zm-9 5c0 .55.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1H9c-.55 0-1 .45-1 1zm2 3H7c-1.65 0-3-1.35-3-3s1.35-3 3-3h3c.55 0 1-.45 1-1s-.45-1-1-1H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h3c.55 0 1-.45 1-1s-.45-1-1-1z" />
              </svg>
              <span>
                https://
                {editMode ? (
                  <input
                    className="w-40 p-1 form-input text-sm"
                    type="text"
                    placeholder="New Nickname"
                    {...bindNickname}
                  />
                ) : (
                  <strong>{profile.nickname}</strong>
                )}
                .{process.env.REACT_APP_ROOT_DOMAIN}
              </span>
            </div>
            <button
              className="text-orange font-medium text-sm hover:underline focus:outline-none"
              type="submit"
            >
              {editMode ? "Update" : "Change"}
            </button>
          </form>

          <div className="font-medium mt-4">
            Confirm that your nickname is as cool as you are!
          </div>
          <div className="w-full h-6 flex items-center text-center justify-center font-semibold text-xs text-orange">
            {response}
          </div>
          <button
            className="bg-orange text-white font-medium px-4 py-3 rounded-lg shadow-md focus:outline-none"
            onClick={handleConfirm}
          >
            {loading ? "Confirming" : "Confirm"}
          </button>
        </section>

        <footer
          className="bg-contain bg-bottom bg-no-repeat h-48 flex-shrink-0 w-full fadeInUp flex-grow"
          style={{ backgroundImage: `url(${footer1})` }}
        ></footer>
      </div>
    </Modal>
  );
};

export default ActivateDomainModal;
