import { createContext } from "react";

const InGameContext = createContext({
  player: null,
  roomRound: "",
  roomStatus: "",
  playersStates: {},
  update: ([type, payload]) => {},
});

export default InGameContext;
