import React from "react";

interface InputProps {
  placeholder?: string;
  type: string;
  value: any;
  setValue: (value: any) => void;
}

const Input: React.FC<InputProps> = ({
  placeholder,
  type,
  value,
  setValue,
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
  };

  return (
    <input
      type={type}
      className="p-3 border rounded-lg focus:outline-none focus:shadow-outline text-sm w-full"
      placeholder={placeholder}
      onChange={handleChange}
      value={value}
    />
  );
};

export default Input;
