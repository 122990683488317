import React, { useMemo } from "react";
import {
  Facebook,
  Gift,
  Instagram,
  Link,
  Twitter,
  Youtube,
} from "../../../assets/icons";
import { useRoom } from "../../../contexts";
import useResponseFlash from "../../../hooks/useResponseFlash";
import Avatar from "../../ui/Avatar";
import Widget from "../../ui/Widget";

interface AboutProps {}

const getSocialLink = (link: number, value: string) => {
  switch (link) {
    case 1:
      return value;
    case 2:
      return `https://www.twitter.com/${value}`;
    case 3:
      return `https://www.instagram.com/${value}`;
    case 4:
    case 5:
      return value;
    case 6:
      return `upi://pay?pa=${value}&tn=Gift from Zo`;
    default:
      return "";
  }
};

const copyReferralLink = (link: string) => {
  const textArea = document.createElement("textarea");
  textArea.value = link;
  textArea.style.position = "fixed";
  textArea.style.top = "-400vh";
  textArea.style.left = "0";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  textArea.setSelectionRange(0, 99999);
  document.execCommand("copy");
  textArea.remove();
};

const getSocialIcon = (link: number) => {
  switch (link) {
    case 1:
      return <Facebook className="w-5 h-5" />;
    case 2:
      return <Twitter className="w-5 h-5" />;
    case 3:
      return <Instagram className="w-5 h-5" />;
    case 4:
      return <Link className="w-5 h-5" />;
    case 5:
      return <Youtube className="w-5 h-5" />;
    case 6:
      return <Gift className="w-5 h-5" />;
    default:
      return "";
  }
};

const About: React.FC<AboutProps> = () => {
  const { room } = useRoom();

  const { response: copyResponse, setResponse: setCopyResponse } =
    useResponseFlash(1000);

  const copy = (link: string) => {
    try {
      copyReferralLink(link);
      setCopyResponse("UPI Id copied!");
    } catch (error) {
      console.log(error);
    }
  };

  const nickname = useMemo(() => room?.nickname, [room]);
  const avatarUrl = useMemo(() => room?.avatar_url, [room]);
  const bio = useMemo(() => room?.bio, [room]);
  const socialLinks = useMemo(() => room?.socials || [], [room]);

  return (
    <Widget title="About me" id="about-me" hasToggle>
      {() => (
        <section
          className="w-full rounded-lg relative overflow-hidden"
          style={{ minHeight: "15rem" }}
        >
          <Avatar
            className="h-72 -mr-4 -mb-8 absolute bottom-0 right-0"
            svg={avatarUrl || null}
            full
          />
          <div
            className="relative w-full p-2 rounded-lg flex flex-col"
            style={{ maxWidth: "75%" }}
          >
            <div className="font-bold text-gray-900 mt-4">{nickname}</div>
            {bio && (
              <div className="mt-3 text-sm italic font-medium text-gray-700">
                {bio}
              </div>
            )}
            {socialLinks.length > 0 && (
              <>
                <div className="flex items-center mt-4">
                  {socialLinks
                    .filter((s) => s.value !== "" && s.link !== 6)
                    .map((s) => (
                      <a
                        key={s.link}
                        className="mr-4 sm:mr-2"
                        href={getSocialLink(s.link, s.value)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getSocialIcon(s.link)}
                      </a>
                    ))}
                </div>
                {socialLinks.find((f) => f.link === 6) != null &&
                socialLinks.find((f) => f.link === 6)?.value !== "" ? (
                  <button
                    className="flex items-center mt-4"
                    onClick={copy.bind(
                      null,
                      socialLinks.find((f) => f.link === 6)?.value || ""
                    )}
                  >
                    {getSocialIcon(6)}{" "}
                    <span className="ml-1 truncate text-sm">
                      {copyResponse && copyResponse !== ""
                        ? copyResponse
                        : socialLinks.find((f) => f.link === 6)?.value}
                    </span>
                  </button>
                ) : null}
              </>
            )}
          </div>
        </section>
      )}
    </Widget>
  );
};

export default About;
