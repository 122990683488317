import React, { useContext, useMemo } from "react";
import { useRTC } from "../../../../../../../contexts";
import InGameContext from "../../../../../contexts/inGame";
import detectiveCard from "./../../../../../assets/role-detective.svg";
import healerCard from "./../../../../../assets/role-healer.svg";
import mafiaCard from "./../../../../../assets/role-mafia.svg";
import villageBoy1Card from "./../../../../../assets/role-villager-boy-1.svg";
import villageBoy2Card from "./../../../../../assets/role-villager-boy-2.svg";
import villageGirl1Card from "./../../../../../assets/role-villager-girl-1.svg";
import villageGirl2Card from "./../../../../../assets/role-villager-girl-2.svg";
import Player from "./Player";

const CARD = {
  mafia: mafiaCard,
  healer: healerCard,
  detective: detectiveCard,
  villager: villageBoy1Card,
  "villager-boy-1": villageBoy1Card,
  "villager-boy-2": villageBoy2Card,
  "villager-girl-1": villageGirl1Card,
  "villager-girl-2": villageGirl2Card,
};

const Players = () => {
  const { playersStates, player } = useContext(InGameContext);
  const { peers, isSelfMute, isActive } = useRTC();

  return useMemo(
    () => (
      <div className="flex justify-between">
        {Object.values(playersStates).map((_player) => {
          const connected =
            _player.nickname === player.nickname
              ? peers.find((p) => p.display === _player.nickname) || isActive
              : peers.find((p) => p.display === _player.nickname);

          const speaking = connected
            ? connected.status === "talking"
              ? true
              : false
            : false;

          const muted =
            _player.nickname === player.nickname
              ? isSelfMute
              : connected
              ? connected.muted
              : false;

          return (
            <Player
              key={_player.id}
              avatar={_player.avatar}
              role={_player.role}
              nickname={_player.nickname}
              isUser={_player.id === player.id}
              roleCard={CARD[_player.role_card]}
              status={_player.status}
              connected={connected}
              speaking={speaking}
              muted={muted}
            />
          );
        })}
      </div>
    ),
    [playersStates, player.nickname, player.id, isActive, peers, isSelfMute]
  );
};

export default Players;
