import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import Flex from "../../../../components/structure/Flex";
import Input from "../../../../components/ui/FormElement/components/Input";
import { useProfile } from "../../../../contexts";
import useApi from "../../../../hooks/useApi";
import useResponseFlash from "../../../../hooks/useResponseFlash";
import zobuWelcome from "./../../../../assets/lottie/zobu-welcome.json";
import Button from "./../../../../components/ui/Button";

interface ProfileFillerProps {
  field: string;
}

const ProfileFiller: React.FC<ProfileFillerProps> = ({ field }) => {
  const api = useApi("PROFILE_ME");

  const { updateProfile, updateProfileKey, profile } = useProfile();

  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const { response, setResponse } = useResponseFlash();

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();
    if (/^[a-zA-Z0-9]{6,25}$/.test(value)) {
      try {
        if (updateProfileKey) {
          const response = await updateProfileKey("nickname", value);
          if (response) {
            setResponse("Creating your room...");
            if (api) {
              const _res = await api.post("/domain/");
              updateProfile(_res.data.profile);
            }
          } else {
            setResponse("Nickname already exists.");
          }
        }
      } catch (error) {
        setResponse("Try again later.");
      }
    } else {
      setResponse(
        "Only [A-Z, a-z, 0-9] and 6 to 25 characters in length allowed"
      );
    }
  };

  useEffect(() => {
    if (profile && profile.nickname) {
      if (api) {
        api
          .post("/domain/")
          .then((res) => {
            updateProfile(res.data.profile);
          })
          .catch(console.log);
      }
    } else {
      setFormVisible(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, api]);

  return (
    <section className="h-full w-full bg-zo-off-white relative flex items-center justify-center max-w-sm mx-auto">
      <Flex col className="mb-24">
        <header className="font-black text-xl fadeInUp text-center w-full portrait:px-6 text-orange portrait:block text-3xl mb-4">
          Zo World
        </header>
        {formVisible && (
          <section className="w-full px-4 flex-col flex items-center">
            <div className="font-bold text-lg mb-8 fadeInUp animation-delay-100 text-center">
              {/* {ONBOARDING_DATA[field]?.subtitle} */}
              You get a room!
            </div>
            <div className="font-semibold text-gray-700 mb-8 fadeInUp animation-delay-300 text-center">
              {/* {ONBOARDING_DATA[field]?.text} */}
              Zo World is all about creating your own identity and it all starts
              with your room and nickname.
              <br />
              Choose a nickname as cool as you are and choose wisely.
            </div>
            <form
              className="w-full flex items-center flex-col fadeInUp animation-delay-500"
              onSubmit={handleSubmit}
            >
              {/* {ONBOARDING_DATA[field]?.formElement === "input" ? (
              <Input
                value={value}
                setValue={setValue}
                placeholder={ONBOARDING_DATA[field]?.placeholder}
                type="text"
              />
            ) : ONBOARDING_DATA[field]?.formElement === "date" ? (
              <DateTimePicker
                value={value}
                setValue={setValue}
                hideTime
                placeholder={ONBOARDING_DATA[field]?.placeholder}
              />
            ) : ONBOARDING_DATA[field]?.formElement === "textarea" ? (
              <Textarea
                value={value}
                setValue={setValue}
                placeholder={ONBOARDING_DATA[field]?.placeholder}
                type=""
              />
            ) : null} */}
              <Input
                value={value}
                setValue={setValue}
                placeholder="Choose a cool nickname"
                type="text"
              />
              <span className="text-center w-full h-8 flex items-center justify-center text-orange font-bold text-sm">
                {response}
              </span>
              <Button type="primary" className="">
                {/* {ONBOARDING_DATA[field]?.buttonText} */}
                Let's Go!
              </Button>
            </form>
          </section>
        )}
      </Flex>
      <Lottie
        animationData={zobuWelcome}
        autoPlay
        loop
        className="absolute fadeInUp animation-delay-300 left-0 right-0 bottom-0 object-contain"
      />
    </section>
  );
};

export default ProfileFiller;
