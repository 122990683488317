import { createContext } from "react";

type SeedContextInterface = {
  avatarTools: { categories: any[]; bases: any[] };
  seedData: GeneralObject;
  backgrounds: GeneralObject;
  musicFiles: GeneralObject;
  abuseTypes: any[];
};

const SeedContext = createContext<SeedContextInterface>({
  avatarTools: { categories: [], bases: [] },
  seedData: {},
  backgrounds: {},
  musicFiles: {},
  abuseTypes: [],
});

export default SeedContext;
