import React, { useState, useEffect, useContext, useRef, useMemo } from "react";

import ProfileContext from "../../../../contexts/profile";
import useApi from "../../../../hooks/useApi";
import Avatar from "../../../../ui/Avatar";
// import firstBadge from "./../../../../../assets/1.svg";
// import secondBadge from "./../../../../../assets/2.svg";
// import thirdBadge from "./../../../../../assets/3.svg";

const MafiaLeaderboard = () => {
  const api = useApi("games", true);
  const { profile } = useContext(ProfileContext);

  const standingListRef = useRef();
  const lastScrollPostion = useRef();

  const [standingList, setStandingList] = useState([]);
  const [gainerList, setGainerList] = useState([]);
  const [currentTab, setCurrentTab] = useState("standing");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (api) {
      api
        .get("/games/api/v1/mafia/leaderboard/?page=" + currentPage)
        .then((res) => {
          console.log("Leaderboard", res);
          setStandingList((l) => [...l, ...res.data.standings]);
          setGainerList((l) => [...l, ...res.data.gainers]);
          setTotal(res.data.total);
          if (
            standingListRef &&
            standingListRef.current &&
            lastScrollPostion &&
            lastScrollPostion.current
          ) {
            standingListRef.current.scrollTop = lastScrollPostion.current;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, currentPage]);

  const list = useMemo(
    () => (currentTab === "standing" ? standingList : gainerList),
    [currentTab, gainerList, standingList]
  );

  const handleLoadMore = () => {
    if (
      standingListRef &&
      standingListRef.current &&
      lastScrollPostion &&
      lastScrollPostion.current
    ) {
      lastScrollPostion.current = standingListRef.current.scrollTop;
    }
    setCurrentPage((p) => ++p);
  };

  return (
    <section className="inline-flex flex-col items-center w-full sm:w-96 h-96 sm:h-108 mb-4 sm:mx-2 bg-white-translucent-90 rounded-lg shadow-md overflow-hidden flex-shrink-0">
      <header className="w-full p-3 flex items-center justify-between">
        <span className="font-bold">Leaderboard</span>
        <ul className="flex">
          <li
            className={`text-sm py-1 px-2 font-medium leading-none cursor-pointer ${
              currentTab === "gainer"
                ? "text-white bg-orange rounded-lg"
                : "text-gray-700 hover:text-orange"
            }`}
            onClick={setCurrentTab.bind(null, "gainer")}
          >
            This Season
          </li>
          <li
            className={`text-sm py-1 px-2 font-medium leading-none cursor-pointer ${
              currentTab === "standing"
                ? "text-white bg-orange rounded-lg"
                : "text-gray-700 hover:text-orange"
            }`}
            onClick={setCurrentTab.bind(null, "standing")}
          >
            All time
          </li>
        </ul>
      </header>
      <div
        className="flex items-center w-full py-2 px-4 bg-white-translucent-90 rounded-t-lg"
        style={{ boxShadow: "0 0 5px rgba(0,0,0,0.1)" }}
      >
        <span className="w-6 h-6 flex justify-cente font-medium rounded-full items-center flex-shrink-0 text-xs mr-2">
          Rank
        </span>
        <span className="flex-grow font-medium ml-4 mr-2 flex items-center">
          Nickname
        </span>
        <span className="flex-shrink-0 w-24 text-cente font-medium">Score</span>
      </div>
      <div
        className="relative flex-grow w-full h-auto overflow-y-auto p-2 bg-gray-300 scroll-visible"
        ref={standingListRef}
      >
        {list.length > 0 ? (
          <ul className="w-full flex flex-col items-center">
            {list.map((l, i) => (
              <li
                key={l.profile.code}
                className={`w-full flex items-center w-full p-2 rounded-lg mb-2 ${
                  (currentTab === "standing" && i < 12) ||
                  (currentTab === "gainer" && i < 5)
                    ? "bg-gray-600"
                    : ""
                } ${
                  l.profile.code === profile.code
                    ? "bg-gray-800 text-white"
                    : "bg-white"
                }`}
              >
                <span
                  className={`w-6 h-6 flex justify-center font-medium rounded-full items-center flex-shrink-0 text-xs mr-2 ${
                    l.profile.code === profile.code
                      ? "text-gray-800 bg-white"
                      : "text-white bg-gray-800"
                  }`}
                >
                  {i + 1}
                </span>
                <Avatar
                  svg={l.profile.avatar_url}
                  className="w-10 h-10 border-white rounded-full border-2 flex-shrink-0 bg-white"
                />
                <span
                  className={`flex-grow font-medium mx-2 flex items-center ${
                    l.profile.code === profile.code
                      ? "text-white"
                      : "text-gray-800"
                  }`}
                >
                  {l.profile.nickname}
                  {/* {i === 0 ? (
                    <img src={firstBadge} alt="" className="h-6 ml-2" />
                  ) : i === 1 ? (
                    <img src={secondBadge} alt="" className="h-6 ml-2" />
                  ) : i === 2 ? (
                    <img src={thirdBadge} alt="" className="h-6 ml-2" />
                  ) : (
                    <></>
                  )} */}
                </span>
                <span
                  className={`flex-shrink-0 w-24 text-center font-medium ${
                    l.profile.code === profile.code
                      ? "text-white"
                      : "text-gray-800"
                  }`}
                >
                  {currentTab === "standing" ? l.score : l.season_score}
                </span>
              </li>
            ))}
            {total > list.length ? (
              <button
                className="bg-gray-800 text-white rounded-lg font-semibold uppercase tracking-wide p-2 shadow-md my-2 focus:outline-none"
                onClick={handleLoadMore}
              >
                Load More Ranks
              </button>
            ) : (
              <></>
            )}
          </ul>
        ) : (
          <div className="w-full h-full flex items-center justify-center text-lg font-semibold zo-text-shadow text-gray-800 bg-gray-400">
            No games played in this season.
          </div>
        )}
      </div>
    </section>
  );
};

export default MafiaLeaderboard;
