import Lottie from "lottie-react";
import React, { useState } from "react";
import Flex from "../../../../components/structure/Flex";
import Auth from "../../../Auth";
import zobuWelcome from "./../../../../assets/lottie/zobu-welcome.json";

interface WelcomeProps {}

const Welcome: React.FC<WelcomeProps> = () => {
  const [login, setLogin] = useState<boolean>(false);

  return login ? (
    <Auth />
  ) : (
    <section className="h-full w-full bg-zo-off-white relative flex items-center justify-center max-w-sm mx-auto">
      <Flex col>
        <header className="font-black text-xl fadeInUp text-center w-full portrait:px-6 leading-8">
          Welcome to{" "}
          <span className="text-orange portrait:block text-3xl portrait:mb-4 block">
            Zo World Community
          </span>
        </header>
        <section className="w-full mb-8 landscape:mt-4 px-4 flex-col flex items-center">
          <div className="font-semibold mb-8 fadeInUp animation-delay-300 text-center">
            A Digital-First Nation built for socialising and more!
          </div>
          <div className="w-full flex items-center flex-col">
            <button
              className="bg-orange px-6 py-3 rounded-lg shadow-md hover:shadow-lg focus:outline-none font-semibold text-white fadeInUp animation-delay-500"
              onClick={setLogin.bind(null, true)}
            >
              Check in
            </button>
          </div>
        </section>
      </Flex>
      <Lottie
        animationData={zobuWelcome}
        autoPlay
        loop
        className="absolute fadeInUp animation-delay-300 left-0 right-0 bottom-0 object-contain"
      />
    </section>
  );
};

export default Welcome;
