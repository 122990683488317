import React, { useState, useEffect, useContext } from "react";

import useApi from "../../../../../hooks/useApi";
import MembersStack from "../../MembersStack";
import ProfileContext from "../../../../../contexts/profile";

const PartyRequest = ({
  from,
  partyCode,
  sendSocketMessage,
  pop,
  setPartyMembers,
  setPartyCode,
}) => {
  const { profile } = useContext(ProfileContext);
  const commApi = useApi("comms", true);

  const [members, setMembers] = useState([]);
  const [connecting, setConnecting] = useState(false);

  useEffect(() => {
    if (partyCode && commApi) {
      commApi
        .get(`/lobby/api/v1/party/${partyCode}/info/`)
        .then((response) => {
          if (response.status === 200) {
            setMembers(response.data.members);
          } else {
            setMembers([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setMembers([]);
        });
    }
  }, [commApi, partyCode]);

  const handleAccept = async () => {
    if (commApi) {
      setConnecting("Joining the party ...");
      const response = await commApi.get(
        `/lobby/api/v1/party/${partyCode}/info/`
      );
      if (response.status === 200) {
        if (response.data.members) {
          if (response.data.members.find((f) => f.nickname === from)) {
            setConnecting("Joined.");
            sendSocketMessage({
              action: "party_request_response",
              payload: {
                response: "accept",
                to: { nickname: from },
                from: { nickname: profile ? profile.nickname : null },
              },
            });
            setPartyMembers([]);
            setPartyCode(partyCode);
            setTimeout(() => {
              pop(from);
            }, 1000);
          } else {
            setConnecting("Party has ended.");
            setTimeout(() => {
              pop(from);
            }, 1000);
          }
        }
      } else {
        setConnecting("Party has ended.");
        setTimeout(() => {
          pop(from);
        }, 1000);
      }
    } else {
      setConnecting("Error in joining.");
      setTimeout(() => {
        pop(from);
      }, 1000);
    }
  };

  const handleDecline = () => {
    sendSocketMessage({
      action: "party_request_response",
      payload: {
        response: "decline",
        to: { nickname: from },
        from: { nickname: profile ? profile.nickname : null },
      },
    });
    pop(from);
  };

  return (
    <div className="fadeInUp bg-white border-t-4 border-green-500 rounded-lg shadow-lg">
      <div className="bg-gray-300 p-2">
        {members && <MembersStack members={members} hostPassport={partyCode} />}
      </div>
      <div className="p-2">
        <strong>{from}</strong> wants you to join their party
      </div>
      <div className="flex items-center w-full justify-around pb-2">
        {connecting ? (
          <div className="text-orange font-semibold text-xs">{connecting}</div>
        ) : (
          <>
            <button
              className="bg-green-500 font-semibold px-3 py-2 leading-none text-sm rounded-lg shadow-md text-white hover:shadow-lg"
              onClick={handleAccept}
            >
              Accept
            </button>
            <button
              className="bg-red-500 font-semibold px-3 py-2 leading-none text-sm rounded-lg shadow-md text-white hover:shadow-lg"
              onClick={handleDecline}
            >
              Decline
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PartyRequest;
