import React from "react";

import RTCAudioIcon from "../../../../../scenes/Intro/components/PlayerCard/RTCAudioIcon";
import Avatar from "../../../../../../../ui/Avatar";
import withMemo from "../../../../../../../../utils/withMemo";

const Player = withMemo(
  ({
    avatar,
    nickname,
    status,
    speaking,
    muted,
    isUser,
    roleCard,
    connected,
  }) => {
    return (
      <div className="flex flex-col items-center">
        <div
          className={`relative flex flex-col items-center shadow-lg rounded-full border-4 ${
            status === "killed"
              ? "bg-red-700 border-gray-900"
              : status === "lynched" || status === "revenged"
              ? "bg-gray-700 border-gray-900"
              : "bg-white border-mafia-primary"
          } ${speaking ? "mafia-player-speaking" : ""}`}
        >
          <Avatar className="w-16 h-16" svg={avatar} />
          <RTCAudioIcon
            speaking={speaking}
            muted={muted}
            connected={connected}
          />
        </div>
        <div
          className={`flex w-auto h-6 rounded-md shadow-sm border-t-2 ${
            isUser
              ? "bg-mafia-secondary border-mafia-primary text-mafia-primary"
              : "bg-mafia-primary border-mafia-secondary text-white"
          }`}
        >
          <span className="px-2 whitespace-no-wrap w-20 text-center truncate">
            {nickname}
          </span>
        </div>
        <img src={roleCard} className="mt-2 w-16 h-auto" alt="" />
      </div>
    );
  },
  [
    "avatar",
    "nickname",
    "status",
    "speaking",
    "muted",
    "isUser",
    "roleCard",
    "connected",
  ]
);

export default Player;
