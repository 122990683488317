import React, { useContext } from "react";

import Widget from "../../ui/Widget";
import SquareIcon from "../../ui/SquareIcon";
import commonroomIcon from "./../../../../../../assets/Common_Room_02.svg";
import avatarIcon from "./../../../../../../assets/Avatars.svg";
import settingsIcon from "./../../../../../../assets/settings.svg";
import ProfileContext from "../../../../../contexts/profile";
import { useHistory } from "react-router-dom";
// import plusIcon from "./../../../../../../assets/plus.svg";
// import mafiaIcon from "./../../../assets/cult-mafia.svg";
// import cameraIcon from "./../../../assets/cult-camera.svg";
// import musicIcon from "./../../../assets/cult-music.svg";

const portals = [
  {
    icon: commonroomIcon,
    hoverText: "My Common Room",
    link: "/",
  },
  {
    icon: avatarIcon,
    hoverText: "Edit my Zobu Avatar",
    link: "/zobu",
  },
  {
    icon: settingsIcon,
    hoverText: "My Settings",
    link: "#settings",
  },
];

const Portal = (props) => {
  const { profile } = useContext(ProfileContext);
  const history = useHistory();

  return (
    <Widget
      className="zo-portal flex flex-col items-end portrait:hidden shadow-none overflow-visible"
      {...props}
    >
      {profile ? (
        <div className="flex items-center">
          <span className="flex-shrink-0 text-2xl font-bold zo-text-shadow">
            Portal
          </span>
          <ul className="flex items-center ml-10">
            {portals.map((cult) => (
              <SquareIcon
                key={Math.random()}
                {...cult}
                onClick={
                  cult.link
                    ? () => {
                        if (cult.link === "#settings") {
                          history.push("#settings");
                        } else
                          window.open(
                            `https://me.${process.env.REACT_APP_ROOT_DOMAIN}${cult.link}`,
                            "_parent"
                          );
                      }
                    : null
                }
              />
            ))}
          </ul>
        </div>
      ) : (
        <></>
      )}
    </Widget>
  );
};

export default Portal;
