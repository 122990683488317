import React, { useState, useEffect } from "react";
import TimeContext from ".";

const TimeProvider = ({ children }) => {
  const [gameTime, setGameTime] = useState(0);
  const [gameStartTime, setGameStartTime] = useState(null);
  const [roundTime, setRoundTime] = useState(10);
  const [roundStartTime, setRoundStartTime] = useState(null);
  const [roundTimeLeft, setRoundTimeLeft] = useState(null);
  const [roundTimeSpeed, setRoundTimeSpeed] = useState(1);
  const [gameOver, setGameOver] = useState(false);

  useEffect(() => {
    let timer;
    if (gameStartTime) {
      timer = setInterval(() => {
        const timeElapsed = Math.round((+new Date() - gameStartTime) / 1000);
        setGameTime(timeElapsed);
      }, 1000);
    }
    if (gameOver) {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStartTime]);

  useEffect(() => {
    let timer;
    if (roundStartTime && roundTimeLeft && roundTimeSpeed) {
      timer = setInterval(() => {
        const timeleft =
          roundTimeLeft -
          Math.round(((+new Date() - roundStartTime) * roundTimeSpeed) / 1000);
        setRoundTime(timeleft);
      }, 1000 / roundTimeSpeed);
    }
    if (gameOver) {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundStartTime, roundTimeSpeed, roundTimeLeft]);

  return (
    <TimeContext.Provider
      value={{
        gameTime,
        setGameTime,
        roundTime,
        setRoundTime,
        gameOver,
        setGameOver,
        roundStartTime,
        setRoundStartTime,
        roundTimeLeft,
        setRoundTimeLeft,
        roundTimeSpeed,
        setRoundTimeSpeed,
        setGameStartTime,
      }}
    >
      {children}
    </TimeContext.Provider>
  );
};

export default TimeProvider;
