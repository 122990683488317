import React, { useCallback, useContext, useMemo } from "react";
import withMemo from "../../../../../../utils/withMemo";
import ProfileContext from "../../../../../contexts/profile";

import useInput from "../../../../../hooks/useInput";
import RoomContext from "../../../contexts/room";

const ChatInput = withMemo(() => {
  const { sendSocketMessage } = useContext(RoomContext);
  const { profile } = useContext(ProfileContext);
  const {
    value: chatMessage,
    bind: bindChatMessage,
    reset: resetChatMessage,
  } = useInput("");

  const handleMessageSend = useCallback(
    (e) => {
      e.preventDefault();
      if (chatMessage && chatMessage.trim().length) {
        sendSocketMessage({
          action: "member_chat",
          payload: {
            from: profile.nickname,
            message: chatMessage.trim(),
            timestamp: +new Date(),
            avatar: profile.avatar_url,
          },
        });
      }
      resetChatMessage();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatMessage, profile.nickname]
  );

  return useMemo(
    () => (
      <form
        className="w-full flex-shrink-0 flex-grow-0"
        onSubmit={handleMessageSend}
      >
        <input
          type="text"
          id="chat-input"
          className="form-input w-full rounded-lg shadow-md p-4 text-sm"
          placeholder="Enter your message here ..."
          autoComplete="off"
          {...bindChatMessage}
        />
      </form>
    ),
    [bindChatMessage, handleMessageSend]
  );
}, []);

export default ChatInput;
