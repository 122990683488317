import React from "react";

import ZoPages from "./pages";
import SeedProvider from "./contexts/seed/provider";
import ModalProvider from "./contexts/modals/provider";

const Zo = () => {
  return (
    <SeedProvider>
      <ModalProvider>
        <ZoPages />
      </ModalProvider>
    </SeedProvider>
  );
};

export default Zo;
