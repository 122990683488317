import React from "react";
import { FrontHand, StarCircle } from "../../../assets/icons";
import Flex from "../../structure/Flex";
import Avatar from "../Avatar";
import RTCAudioIcon from "./components/RTCAudioIcon";

interface CitizenProps {
  data: DetailedCitizen;
  onClick: () => void;
}

const Citizen: React.FC<CitizenProps> = ({ data, onClick }) => {
  return (
    <Flex
      inline
      col
      items="center"
      className="w-1/5 sm:w-1/6 my-2 relative group"
      onClick={onClick}
    >
      <Flex
        col
        items="center"
        className="relative group w-12 mb-4 cursor-pointer"
      >
        <Avatar
          svg={data.avatar_url}
          className={`bg-white rounded-full w-full h-full border-2  ${
            data.muted
              ? "bg-gray-300 border-gray-300"
              : data.speaking
              ? "border-orange bg-red-100 avatar-speaking"
              : "bg-white"
          }`}
        />
        <div className="w-20 leading-none text-center group-hover:w-auto group-hover:bg-white group-hover:z-10 group-hover:border rounded-lg p-1 absolute bottom-0 truncate text-xxs font-semibold text-gray-800 mt-2 -mb-5">
          {data.nickname}
        </div>
        {data.isHost && (
          <div className="w-4 h-4 bg-white rounded-full absolute top-0 left-0">
            <StarCircle className="w-4 h-4 fill-current text-green-500" />
          </div>
        )}
        {data.handRaised && (
          <FrontHand className="w-4 h-4 text-2xl absolute bottom-0 left-0 fill-current text-yellow-600" />
        )}
        <RTCAudioIcon
          speaking={data.speaking}
          muted={data.muted}
          connected={data.connected}
        />
      </Flex>
    </Flex>
  );
};

export default Citizen;
