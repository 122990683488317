import React from "react";

import pageData from "./page.js";
import Page from "../../components/ui/Page";

const Population = () => {
  const { widgets } = pageData;

  return <Page widgets={widgets} />;
};

export default Population;
