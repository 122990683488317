import React, { useEffect, useState } from "react";
import populationIcon from "./../../../../../../../../assets/populations/Population.svg";
import roleIcon from "./../../../../../../../../assets/populations/Role.svg";
import rolebg from "./../../../../../../../../assets/populations/Rolebg.svg";
import AnimatorIcon from "./../../../../../../../../assets/roles/Animator.svg";
import BusinessIcon from "./../../../../../../../../assets/roles/Business.svg";
import Content_writerIcon from "./../../../../../../../../assets/roles/Content_writer.svg";
import DesignerIcon from "./../../../../../../../../assets/roles/Designer.svg";
import Digital_wandererIcon from "./../../../../../../../../assets/roles/Digital_wanderer.svg";
import EngineerIcon from "./../../../../../../../../assets/roles/Engineer_02.svg";
import ExplorerIcon from "./../../../../../../../../assets/roles/Explorer.svg";
import ManagerIcon from "./../../../../../../../../assets/roles/Manager.svg";
import Cult_manager from "./../../../../../../../../assets/roles/Cult__manager.svg";
import Cult_president from "./../../../../../../../../assets/roles/Cult_president.svg";
import Destination_host from "./../../../../../../../../assets/roles/Destination_host.svg";
import Destination_Manager from "./../../../../../../../../assets/roles/Destination_Manager.svg";

const rolesIcon = {
  1: EngineerIcon,
  2: DesignerIcon,
  3: Content_writerIcon,
  4: AnimatorIcon,
  6: BusinessIcon,
  7: ManagerIcon,
  8: Destination_host,
  9: Destination_Manager,
  10: Cult_president,
  11: Cult_manager,
  12: Digital_wandererIcon,
  13: ExplorerIcon,
};

const Roles = ({ populationMetrics, seedData, humanize }) => {
  const [zoRoles, setZoRoles] = useState(null);

  useEffect(() => {
    if (populationMetrics && seedData) {
      const roles = [];
      seedData.work_roles &&
        seedData.work_roles.map(([id, role]) => {
          roles.push({
            id,
            name: role,
            citizen: populationMetrics.work_role.find(
              (item) => item.work_role === id
            )
              ? populationMetrics.work_role.find(
                  (item) => item.work_role === id
                ).count
              : "-",
          });
          //  populationMetrics.work_role.map(item => {
          //    if(item.work_role === role || item.work_role === id ){
          //       roles.push({
          //         name: role,
          //         citizen: item.count || 0
          //       })
          //    }
          //    return role
          //  })
          return role;
        });

      setZoRoles(roles.sort((a, b) => b.citizen - a.citizen));
    }
  }, [populationMetrics, seedData]);

  return (
    <>
      <div className="table-fixed portrait:mt-4">
        <div className="text-center font-bold  py-2 relative flex justify-center items-center">
          <img className="h-6" src={roleIcon} alt="" />
          <span className="ml-4 text-xl">By Roles</span>
        </div>

        <div
          className="overflow-y-scroll overflow-x-hidden"
          style={{ height: "62vh" }}
        >
          {zoRoles &&
            zoRoles.map((role) => {
              return (
                // <tr>
                // <td className="px-4 py-2 ">
                <div
                  key={role.id}
                  className=" px-4 py-2  my-2 mx-2 flex items-center bg-white rounded-lg shadow-md p-2 justify-between"
                  style={{
                    backgroundImage: `url(${rolebg})`,
                    backgroundSize: "cover",
                  }}
                >
                  <div>
                    <img
                      className="h-12"
                      src={rolesIcon[role.id] || rolesIcon[7]}
                      alt="roles"
                    />
                  </div>
                  <div className="text-sm my-5">
                    <p className="text-gray-900 text-md leading-none">
                      {role.name}
                    </p>
                  </div>
                  <div>
                    <div className="relative flex justify-center">
                      <img className="h-6" src={populationIcon} alt="" />
                      <span className="inline-block ml-2">
                        {humanize(role.citizen)}
                      </span>
                    </div>
                  </div>
                </div>
                //   </td>
                // </tr>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Roles;
