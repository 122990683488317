import React, { useMemo } from "react";
import Avatar from "../../../../ui/Avatar";

interface QueueMemberProps {
  avatar: string;
  nickname: string;
}

const Member: React.FC<QueueMemberProps> = ({ avatar, nickname }) => {
  return useMemo(
    () => (
      <li className="inline-flex flex-col items-center w-1/5 h-18 my-3">
        <div className="flex flex-col items-center w-full h-full relative group w-16 mb-5 cursor-pointer">
          <div className="relative w-12 h-12 rounded-full bg-mafia-primary">
            <Avatar svg={avatar} className="w-full h-full" />
          </div>
          <div className="w-16 leading-none text-center text-gray-800 group-hover:w-auto group-hover:bg-white group-hover:z-10 group-hover:border rounded-lg p-1 absolute bottom-0 truncate text-xs font-semibold mt-2 -mb-6">
            {nickname}
          </div>
        </div>
      </li>
    ),
    [avatar, nickname]
  );
};

export default Member;
