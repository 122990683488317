import React, { useContext } from "react";
import TravelPortal from "../../../../commons/TravelPortal";
import useSocket from "../../../../hooks/useSocket";
import ProfileContext from "../../../../contexts/profile";

const TravelPortalOptions = ({ visible }) => {
  const {
    message: partyMessage,
    setMembers: setPartyMembers,
    setSocketUrl: setPartySocketURL,
    setToken: setPartyToken,
    socket: partySocket,
    sendSocketMessage: sendPartyMessage,
  } = useSocket("PARTY_SOCKET");
  const { profile } = useContext(ProfileContext);

  return visible && profile ? (
    <TravelPortal
      isHost={true}
      partyMembers={[]}
      partyMessage={partyMessage}
      partySocket={partySocket}
      setPartyMembers={setPartyMembers}
      setPartySocketURL={setPartySocketURL}
      setPartyToken={setPartyToken}
      sendPartyMessage={sendPartyMessage}
      partyCode={profile ? profile.code : null}
      setPartyCode={() => {}}
      sendSocketMessage={() => {}}
      setSentMemberRequests={() => {}}
      setSentPartyRequests={() => {}}
      addNotification={() => {}}
      setPartyTalking={() => {}}
    />
  ) : null;
};

export default TravelPortalOptions;
