const convertToNumber = (text) => {
  const alphabet = "1234567890abcdefghijklmnopqrstuvwxyz-";
  const _text = text.toLowerCase();
  return parseInt(
    _text
      .split("")
      .map((c) => {
        return alphabet.indexOf(c);
      })
      .join("")
  );
};

export default convertToNumber;
