import bg from "./../../../assets/bg.png";

const page = {
  layout: {
    header: {
      title: "Zo World",
      stats: [
        [
          {
            type: "population",
          },
          {
            type: "countries",
          },
        ],
        {
          type: "calendar",
        },
        [
          {
            type: "rating",
          },
          {
            type: "economy",
            locked: true,
          },
          {
            type: "live",
          },
          {
            type: "leaderboard",
          },
        ],
      ],
    },
  },
  style: {
    backgroundPattern: bg,
    accentColor: "orange",
  },
};

export default page;
