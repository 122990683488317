import React, { useEffect, useState } from "react";
import humanize from "humanize-number";

import ZoHeaderStat from "./../../../../../ui/ZoHeaderStat";
import { useSeed } from "../../../contexts/seed";
import useApi from "../../../../../hooks/useApi";

const CountStat = (props) => {
  const {
    style: { primaryColor, textSecondaryColor },
  } = useSeed();
  const api = useApi("games", false);

  const [value, setValue] = useState(null);

  useEffect(() => {
    if (api && props.dataKey) {
      api
        .get("/games/api/v1/mafia/metrics/")
        .then((res) => {
          const response = res.data;
          setValue(response[props.dataKey]);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [api, props.dataKey]);

  return (
    <ZoHeaderStat
      className={`whitespace-no-wrap w-auto bg-${
        primaryColor ? primaryColor : "white"
      }`}
      titleClassName={textSecondaryColor ? `text-${textSecondaryColor}` : ""}
      value={value != null ? humanize(value) : "..."}
      icon={props.icon}
      title={props.title}
      onClick={null}
    />
  );
};

export default CountStat;
