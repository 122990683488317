import React, { useContext, useState } from "react";

import SubSectionRow from "../../../components/SubSectionRow";
import ProfileContext from "../../../../../../../contexts/profile";
import { useResponseFlash } from "../../../../../../Zo/components/hooks";
import LocationDisplay from "./LocationDisplay";
import LocationSelector from "./LocationSelector";
import useApi from "../../../../../../../hooks/useApi";

const HomeTownField = ({ countriesList }) => {
  const api = useApi("profile", true);
  const { profile, updateProfile } = useContext(ProfileContext);
  const { response, setResponse } = useResponseFlash();

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!location.country || !location.city) {
      setResponse("Incomplete input");
      return;
    }
    if (!loading) {
      setLoading(true);
      try {
        const data = {
          country_citizen: location.country,
          hometown: location.city,
        };
        console.log(data);

        const response = await api.post(
          `/profile/api/v1/me/`,
          JSON.stringify(data)
        );
        if (response.status === 200) {
          updateProfile(data);
          setExpanded(false);
        } else {
          setResponse("Error in fields");
        }
      } catch (error) {
        console.log(error.response);
        setResponse("Error in setting the location.");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <SubSectionRow
      title="Hometown"
      visibleArea={
        profile.country_citizen &&
        profile.hometown && (
          <LocationDisplay
            countriesData={countriesList}
            initialData={{
              country: profile.country_citizen,
              city: profile.hometown,
            }}
          />
        )
      }
      placeholder="Where were you born?"
      isExpanded={expanded}
      setExpanded={setExpanded}
    >
      <form className="flex flex-col w-full" onSubmit={handleSubmit}>
        <div className="flex w-full">
          <LocationSelector
            countriesData={countriesList}
            setLocation={setLocation}
            initialData={{
              country: profile.country_citizen,
              city: profile.hometown,
            }}
          />
        </div>
        <div className="w-full h-6 flex items-center justify-start font-semibold text-xs text-orange">
          {response}
        </div>
        <div className="flex mb-4 w-full">
          <button
            className="bg-orange text-white cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            type="submit"
          >
            {loading ? "Saving" : "Save"}
          </button>
          <button
            className="bg-white text-orange cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            onClick={setExpanded.bind(null, false)}
          >
            Cancel
          </button>
        </div>
      </form>
    </SubSectionRow>
  );
};

export default HomeTownField;
