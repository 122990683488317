import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";

import Chat from "./../../components/Chat";
import Header from "./../../components/Header";
import Members from "./../../components/Members";
import bgCommonRoom from "./../../../../../assets/bg-cr.svg";
import ZoHeaderUser from "../../../../ui/ZoHeaderUser";
import withMemo from "../../../../../utils/withMemo";
import ProfileContext from "../../../../contexts/profile";
import RoomContext from "../../contexts/room";
import SeedContext from "../../../../contexts/seed";
import RTCContext from "../../../../contexts/rtcV2";
import AvatarDisplay from "../../components/AvatarsDisplay";
import TravelPortal from "../../../../commons/TravelPortal";
import useSocket from "../../../../hooks/useSocket";
import Notifications from "../../../../commons/Notifications";
import { checkHost } from "../../utils";
import useAudio from "../../../../hooks/useAudio";
import newComerAudio from "./../../../../../assets/new-comer.mp3";
import { getCookie } from "../../../../../utils";
import CommonRoomModals from "../../modals";
import MobileTabs from "../../components/MobileTabs";
import PasswordModal from "./../../modals/PasswordModal";
import ErrorBoundaryModal from "../../../../commons/ErrorBoundary/modals";
import Widgets from "./../../components/Widgets";
import HostSettings from "../../components/HostSettings";
import AmbientAudio from "../../components/AmbientAudio";
import AvatarSection from "../../../../ui/AvatarSection";
import Settings from "./../../../Settings";
import EventsDisplay from "../../components/EventsDisplay";

// const SNOW_ENABLED_BACKGROUDS = [
//   "eaa79d76-473d-4cf6-a0cc-d9d8a23e1ae0",
//   "8581ca9c-5b7d-4bc4-a059-6c49d26c2f1a",
// ];

const Home = withMemo(() => {
  const { profile } = useContext(ProfileContext);
  const location = useLocation();
  const history = useHistory();
  const {
    roomDetails,
    setMembers,
    setSocketUrl,
    setToken,
    sendSocketMessage,
    message,
  } = useContext(RoomContext);
  const { setRoomCode, setUserCode, setIsSelfMute } = useContext(RTCContext);
  const { backgrounds, mobileBackgrounds } = useContext(SeedContext);
  const {
    members: partyMembers,
    message: partyMessage,
    setMembers: setPartyMembers,
    setSocketUrl: setPartySocketURL,
    setToken: setPartyToken,
    socket: partySocket,
    sendSocketMessage: sendPartyMessage,
  } = useSocket("PARTY_SOCKET");
  const newComerSound = useAudio(newComerAudio, false, 0.3);

  const [passwordModalVisible, setPasswordModalVisibility] = useState(false);
  const [eventsVisible, setEventsVisible] = useState(false);
  const [loadingEntry, setLoadingEntry] = useState(true);
  const [partyCode, setPartyCode] = useState(null);
  const [sentMemberRequests, setSentMemberRequests] = useState([]);
  const [sentPartyRequests, setSentPartyRequests] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [partyTalking, setPartyTalking] = useState([]);

  const [background, setBackground] = useState(null);
  const [speakability, setSpeakability] = useState(1);
  const [security, setSecurity] = useState(2);
  const [roomName, setRoomName] = useState("");
  const [whiteboardText, setWhiteboardText] = useState("");
  const [sound, setSound] = useState(null);

  const [kicked, setKicked] = useState(false);

  const isHost = useMemo(
    () => (profile ? checkHost(roomDetails, profile.code) : false),
    [profile, roomDetails]
  );

  useEffect(() => {
    if (window.innerWidth < 640) {
      document.getElementsByTagName("html")[0].style.fontSize = "14px";
    } else {
      document.getElementsByTagName("html")[0].style.fontSize = "16px";
    }
  }, []);

  useEffect(() => {
    const values = location.hash.split("/");
    if (values[0] === "#events") {
      setEventsVisible(true);
    } else {
      setEventsVisible(false);
    }
  }, [location.hash]);

  useEffect(() => {
    const kickedLocal = localStorage.getItem("kicked");
    if (kickedLocal && +kickedLocal > +new Date()) {
      setKicked(true);
    } else {
      if (roomDetails && profile) {
        setBackground(roomDetails.background_key);
        // setSpeakability(roomDetails.speakability || 1);
        setSecurity(roomDetails.security || 2);
        setRoomName(roomDetails.name);
        setWhiteboardText(roomDetails.description || "");
        setSound(roomDetails.music_key);
        if (profile.nickname) {
          if (roomDetails.type === "commonroom") {
            setMembers(roomDetails.members);
            setToken(roomDetails.socket.token);
            setSocketUrl(roomDetails.socket.path);
            setRoomCode(
              `cr${process.env.REACT_APP_ENV === "production" ? "" : "s"}-${
                roomDetails.id
              }`
            );
            setUserCode(profile.code);
          } else if (roomDetails.type === "profile") {
            if (profile.code !== roomDetails.code) {
              setPasswordModalVisibility(true);
            } else {
              if (profile.status === "premium") {
                setMembers(roomDetails.members);
                setToken(roomDetails.socket.token);
                setSocketUrl(roomDetails.socket.path);
                setRoomCode(
                  `${process.env.REACT_APP_ENV === "production" ? "" : "s-"}${
                    profile.code
                  }`
                );
                setUserCode(profile.code);
              }
            }
          }
          setLoadingEntry(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomDetails, profile]);

  useEffect(() => {
    if (message) {
      if (message.action === "update_speakability") {
        setSpeakability(message.payload.speakability);
      }
      if (message.action === "update_security") {
        setSecurity(message.payload.security);
      }
      if (message.action === "update_room_name") {
        setRoomName(message.payload.name);
      }
      if (message.action === "update_whiteboard_text") {
        setWhiteboardText(message.payload.text);
      }
      if (message.action === "new_whiteboard") {
        if (message.payload.from === profile.code) {
          setWhiteboardText("   ");
        }
      }
      if (message.action === "update_background") {
        setBackground(message.payload.background);
      }
      if (message.action === "update_music") {
        setSound(message.payload.music);
      }
      // if (message.action === "update_ambient_mute") {
      //   setAmbientMute(message.payload.mute);
      // }
      // if (message.action === "update_media") {
      //   setRoomMedia(message.payload);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    if (message && message.action) {
      if (message.action === "left" || message.action === "member_kick") {
        setSentMemberRequests((p) =>
          p.filter((_p) => _p !== message.payload.nickname)
        );
        setSentPartyRequests((p) =>
          p.filter((_p) => _p !== message.payload.nickname)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    if (!isHost) {
      setIsSelfMute(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speakability]);

  useEffect(() => {
    if (
      message &&
      message.action &&
      message.action === "joined" &&
      isHost &&
      newComerSound &&
      localStorage.getItem("disableChatSound") == null
    ) {
      newComerSound.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, isHost]);

  useEffect(() => {
    if (profile) {
      if (getCookie("partyCode")) {
        setPartyCode(getCookie("partyCode"));
      } else {
        setPartyCode(profile.code);
      }
    }
  }, [profile]);

  const handleKick = () => {
    localStorage.setItem("kicked", +new Date() + 60 * 60 * 1000);
    window.location.reload();
  };

  useEffect(() => {
    if (message && message.action && profile) {
      if (message.action === "member_mute") {
        if (message.payload.nickname === profile.nickname) {
          setIsSelfMute(true);
        }
      }
      if (message.action === "member_kick") {
        if (message.payload.code === profile.code) {
          handleKick();
        } else {
          setMembers((m) => m.filter((_m) => _m.code !== message.payload.code));
        }
      }
      if (message.action === "profile_update_avatar") {
        setMembers((m) => {
          const _member = m.find((m) => m.code === message.payload.code);
          if (_member) {
            _member.avatar_url = message.payload.avatar_url;
          }
          return [...m];
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, profile]);

  useEffect(() => {
    sendSocketMessage({
      action: "profile_update_avatar",
      payload: { code: profile.code, avatar_url: profile.avatar_url },
    });
    sendPartyMessage({
      action: "profile_update_avatar",
      payload: { code: profile.code, avatar_url: profile.avatar_url },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.avatar_url]);

  useEffect(() => {
    if (partyMessage && partyMessage.action && profile) {
      if (partyMessage.action === "profile_update_avatar") {
        setPartyMembers((m) => {
          const _member = m.find((m) => m.code === partyMessage.payload.code);
          if (_member) {
            _member.avatar_url = partyMessage.payload.avatar_url;
          }
          return [...m];
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyMessage]);

  const addNotification = useCallback((title, description) => {
    setNotificationList((n) => [
      ...n,
      { id: Math.random(), title, description },
    ]);
  }, []);

  const hasPremium = useMemo(() => {
    if (roomDetails.type === "commonroom") {
      return true;
    } else {
      if (roomDetails.status === "premium") {
        return true;
      } else {
        return false;
      }
    }
  }, [roomDetails.type, roomDetails.status]);

  return kicked ? (
    <ErrorBoundaryModal />
  ) : loadingEntry ? (
    <></>
  ) : (
    <main
      className="w-screen h-full flex sm:p-2 bg-cover bg-bottom border-orange border-t-2"
      style={{
        backgroundImage: `url(${
          background != null
            ? window.innerWidth < 640
              ? mobileBackgrounds[background] || bgCommonRoom
              : backgrounds[background] || bgCommonRoom
            : bgCommonRoom
        })`,
      }}
    >
      {/* {background != null &&
        SNOW_ENABLED_BACKGROUDS.indexOf(background) !== -1 && <SnowFlakes />} */}
      <AvatarDisplay />
      <aside className="relative w-full sm:w-108 flex flex-col items-center flex-grow-0 flex-shrink-0 scroll-hidden">
        <Header
          roomName={roomName}
          isHost={isHost}
          speakability={speakability}
          hasPremium={hasPremium}
        />
        {hasPremium && (
          <>
            <Members
              sentMemberRequests={sentMemberRequests}
              sentPartyRequests={sentPartyRequests}
              partyMembers={partyMembers}
              partyCode={partyCode}
            />
            {window.innerWidth > 640 ? (
              <div className="flex-grow w-full overflow-auto scroll-hidden">
                <Chat active setUnreadChatCount={() => {}} />
              </div>
            ) : (
              <MobileTabs
                isHost={isHost}
                hasPremium={hasPremium}
                whiteboardText={whiteboardText}
                roomDetails={roomDetails}
                background={background}
                sound={sound}
                security={security}
                speakability={speakability}
              />
            )}
          </>
        )}
      </aside>
      <section className="flex-grow hidden sm:flex flex-col">
        <header className="flex items-center justify-between p-2 w-full flex-shrink-0 flex-grow-0">
          <div></div>
          <div className="flex items-center">
            {isHost && (
              <HostSettings
                hasPremium={hasPremium}
                whiteboardText={whiteboardText}
                background={background}
                sound={sound}
                security={security}
                speakability={speakability}
              />
            )}
            <button
              className="bg-white p-3 font-medium rounded-lg shadow-sm ml-2 mr-8 flex items-center"
              onClick={() => {
                history.push("#events");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                className="fill-current text-orange mr-2"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M19 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H8V2c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 16H6c-.55 0-1-.45-1-1V8h14v10c0 .55-.45 1-1 1zM8 10h3c.55 0 1 .45 1 1v3c0 .55-.45 1-1 1H8c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1z" />
              </svg>
              View Upcoming Events
            </button>
            <ZoHeaderUser />
          </div>
        </header>
        {hasPremium && (
          <section className="w-full flex-grow px-4 sm:pl-2 sm:pr-0 overflow-y-auto py-2 relative scroll-hidden">
            <Widgets whiteboardText={whiteboardText} slug={roomDetails.slug} />
          </section>
        )}
      </section>
      {hasPremium && sound && <AmbientAudio sound={sound} />}
      <TravelPortal
        message={message}
        partyMembers={partyMembers}
        partyMessage={partyMessage}
        partySocket={partySocket}
        setPartyMembers={setPartyMembers}
        setPartySocketURL={setPartySocketURL}
        setPartyToken={setPartyToken}
        sendPartyMessage={sendPartyMessage}
        partyCode={partyCode}
        setPartyCode={setPartyCode}
        sendSocketMessage={sendSocketMessage}
        setSentMemberRequests={setSentMemberRequests}
        setSentPartyRequests={setSentPartyRequests}
        addNotification={addNotification}
        partyTalking={partyTalking}
        setPartyTalking={setPartyTalking}
      />
      {window.innerWidth > 640 && (
        <Notifications
          notificationList={notificationList}
          dismissTime={3000}
          autoDelete
        />
      )}
      <CommonRoomModals />
      {passwordModalVisible && (
        <PasswordModal close={setPasswordModalVisibility.bind(null, false)} />
      )}

      <Settings isOverlay />
      <AvatarSection />
      {eventsVisible && (
        <EventsDisplay
          close={() => {
            history.push("/");
          }}
          sendMessage={sendPartyMessage}
        />
      )}
    </main>
  );
}, []);

export default Home;
