import React from "react";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "../../components/commons/ErrorBoundary";
import { AuthProvider } from "./contexts/auth";
import { ProfileProvider } from "./contexts/profile";
import { RTCProvider } from "./contexts/rtc";
import { SeedProvider } from "./contexts/seed";
import Routes from "./Routes";

interface ZoWorldProps {}

const ZoWorld: React.FC<ZoWorldProps> = () => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <AuthProvider>
          <SeedProvider>
            <ProfileProvider>
              <RTCProvider>
                <main className="bg-zo-off-white overflow-hidden w-full h-full">
                  <Routes />
                </main>
              </RTCProvider>
            </ProfileProvider>
          </SeedProvider>
        </AuthProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default ZoWorld;
