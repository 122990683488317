import React, { useState, useEffect } from "react";

import RTCContext from ".";
import Janus from "../../../../commons/Janus";

const RTCProvider = ({ children }) => {
  const [peers, setPeers] = useState([]);
  const [isActive, setActive] = useState(false);
  const [selfMuted, setSelfMuted] = useState(true);
  // const [streamMuted, setStreamMuted] = useState(false);
  const [roomCode, setRoomCode] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [userCode, setUserCode] = useState(null);
  // const [controlsVisible, setControlsVisible] = useState(true);
  const [rtcSupported, setRtcSupported] = useState(true);

  useEffect(() => {
    const isWebRTCSupported =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia ||
      window.RTCPeerConnection;

    setRtcSupported(
      isWebRTCSupported && window.navigator.userAgent.indexOf("Edge") === -1
    );
  }, []);

  return (
    <RTCContext.Provider
      value={{
        peers,
        setPeers,
        isActive,
        setActive,
        isSelfMute: selfMuted,
        setIsSelfMute: setSelfMuted,
        setRoomCode,
        setUserCode,
        rtcSupported,
        roomCode,
        setDisplayName,
      }}
    >
      {children}
      {roomCode && userCode && (
        <Janus
          instanceName="RTC_PARTY"
          peers={peers}
          roomCode={roomCode}
          selfMuted={selfMuted}
          displayName={displayName}
          setPeers={setPeers}
          setSelfMuted={setSelfMuted}
          userPassport={userCode}
          isActive={isActive}
          setActive={setActive}
          volume={1}
        />
      )}
    </RTCContext.Provider>
  );
};

export default RTCProvider;
