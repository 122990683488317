const formattedSeconds = (t) => {
  return t > 0 ? (t < 10 ? `0${t}` : `${t}`) : "00";
};
const getMinutes = (s) => {
  return parseInt(s / 60);
};
const getSeconds = (s) => {
  return parseInt(s % 60);
};
const shuffleArray = (array) => {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
};
const getVoteResult = (votesArray) => {
  const votesMap = {};
  votesArray.forEach((id) => {
    if (votesMap[id] === undefined) {
      votesMap[id] = 1;
    } else {
      votesMap[id]++;
    }
  });
  const votesEntries = Object.entries(votesMap);
  const sortedVotesEntries = votesEntries.sort((b, a) => a[1] - b[1]);
  const maxVotee = [];
  sortedVotesEntries.forEach((v, i) => {
    if (v[1] === sortedVotesEntries[0][1]) {
      maxVotee.push(v[0]);
    }
  });
  shuffleArray(maxVotee);
  return maxVotee;
};

export {
  formattedSeconds,
  getMinutes,
  getSeconds,
  shuffleArray,
  getVoteResult,
};
