import React, { useState } from "react";
import ModalContext from ".";
import CultSuggestModal from "../../components/modals/CultSuggest";
import BecomeMemberModal from "../../components/modals/BecomeMember";

const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState("intro");

  const getModal = () => {
    if (modal === "cult-suggest") {
      return <CultSuggestModal close={setModal.bind(null, "")} />;
    } else if (modal === "become-member") {
      return <BecomeMemberModal close={setModal.bind(null, "")} />;
    } else {
      return <></>;
    }
  };

  return (
    <ModalContext.Provider value={{ modal, setModal }}>
      {children}
      {getModal()}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
