import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import SeedContext from "./SeedContext";

interface SeedProviderProps {}

const SeedProvider: React.FC<SeedProviderProps> = ({ children }) => {
  const fetchSeed = useApi("PROFILE_SEED");

  const [avatarTools, setAvatarTools] = useState({ categories: [], bases: [] });
  const [seedData, setSeedData] = useState({});
  const [backgrounds, setBackgrounds] = useState({});
  const [musicFiles, setMusicFiles] = useState({});
  const [abuseTypes, setAbuseTypes] = useState([]);

  useEffect(() => {
    if (fetchSeed) {
      fetchSeed
        .get("")
        .then((res) => {
          console.log("Profile Seed", res.data);
          if (res.status === 200) {
            const data: GeneralObject = res.data;
            setAvatarTools(data.avatar);
            setSeedData(data);
            if (data.background) {
              const _bgs: GeneralObject = {};
              const _mbgs: GeneralObject = {};
              data.background.forEach((b: GeneralObject) => {
                _bgs[b.key] = b.file;
                _mbgs[b.key] = b.file_vertical || b.file;
              });
              if (window.innerWidth < window.innerHeight) {
                setBackgrounds(_mbgs);
              } else {
                setBackgrounds(_bgs);
              }
            }
            if (data.music) {
              const _mscs: GeneralObject = {};
              data.music.forEach((m: any) => {
                _mscs[m.key] = m;
              });
              setMusicFiles(_mscs);
            }
            if (data.report_user) {
              setAbuseTypes(data.report_user.types);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [fetchSeed]);

  return (
    <SeedContext.Provider
      value={{
        avatarTools,
        seedData,
        backgrounds,
        musicFiles,
        abuseTypes,
      }}
    >
      {children}
    </SeedContext.Provider>
  );
};

export default SeedProvider;
