import React, { useEffect, useState } from "react";
import { Save } from "../../../assets/icons";
import { useSeed } from "../../../contexts";
import BottomSheet from "../../ui/BottomSheet";
import IconButton from "../../ui/IconButton";

interface BackgroundSheetProps {
  open: boolean;
  onDismiss: () => void;
  selected: string | null;
  onChange: (key: string) => void;
}

const BackgroundSheet: React.FC<BackgroundSheetProps> = ({
  open,
  onDismiss,
  selected,
  onChange,
}) => {
  const { backgrounds } = useSeed();
  const [bgSelected, setBgSelected] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (open && selected) {
      setBgSelected(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const saveBG = () => {
    if (selected !== bgSelected) {
      onChange(bgSelected);
    }
    onDismiss();
  };

  return (
    <BottomSheet
      open={open}
      snapPoints={({ maxHeight }) => [maxHeight * 0.95]}
      onDismiss={onDismiss}
      title="Change background"
      onSpringEnd={setVisible.bind(null, true)}
      rightOptions={
        <IconButton onClick={saveBG}>
          <Save className="w-5 h-5" />
        </IconButton>
      }
    >
      <div className="flex flex-wrap w-full overflow-y-auto p-2 justify-center">
        {visible &&
          Object.keys(backgrounds).map((bg) => {
            const _bg = backgrounds[bg];
            return (
              <img
                className={`w-20 h-20 m-1 rounded-lg border-2 object-cover cursor-pointer ${
                  bgSelected === bg ? "border-orange" : ""
                }`}
                key={_bg}
                src={_bg}
                onClick={setBgSelected.bind(null, bg)}
                alt=""
              />
            );
          })}
      </div>
    </BottomSheet>
  );
};

export default BackgroundSheet;
