import React from "react";
import Flex from "../../structure/Flex";
import DateTimePicker from "./components/DateTimePicker";
import Input from "./components/Input";
import Textarea from "./components/Textarea";

interface FormElementProps {
  label: string;
  placeholder?: string;
  type: "input" | "textarea" | "datetime" | "custom";
  inputType?: string;
  value?: any;
  setValue?: (value: any) => void;
}

const FormElement: React.FC<FormElementProps> = ({
  label,
  placeholder,
  type,
  inputType,
  value,
  setValue,
  children,
}) => {
  const getFormElement = () => {
    if (type === "input") {
      if (setValue != null) {
        return (
          <Input
            type={inputType || "text"}
            value={value}
            setValue={setValue}
            placeholder={placeholder}
          />
        );
      }
    } else if (type === "textarea") {
      if (setValue != null) {
        return (
          <Textarea
            type={inputType || "text"}
            value={value}
            setValue={setValue}
            placeholder={placeholder}
          />
        );
      }
    } else if (type === "datetime") {
      if (setValue != null) {
        return (
          <DateTimePicker
            value={value}
            setValue={setValue}
            placeholder={placeholder}
          />
        );
      }
    } else if (type === "custom") {
      return children;
    }
  };

  return (
    <Flex col className="mt-4">
      <label className="font-semibold text-sm mb-1">{label}</label>
      {getFormElement()}
    </Flex>
  );
};

export default FormElement;
