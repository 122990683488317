import React from "react";
import Portal from "../../../../ui/Portal";

const PoorNetModal = ({ close }) => {
  return (
    <Portal>
      <section
        className="fixed bottom-0 left-0 right-0 z-100 w-screen pointer-events-none flex flex-col items-center"
        onMouseOver={close}
      >
        <span className="text-xs md:text-base font-bold text-white bg-orange p-1 md:p-2 w-content rounded-t-lg">
          Your connection is unstable
        </span>
      </section>
    </Portal>
  );
};

export default PoorNetModal;
