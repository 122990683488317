import React from "react";
import Avatar from "../../../../../ui/Avatar";
import { useSeed } from "../../../contexts/seed";

const CircleIcon = ({ avatar, icon, hoverText, onClick, backgroundColor }) => {
  const { style } = useSeed();
  return (
    <li
      className={`relative flex flex-col items-center ml-2 group w-16 h-16 rounded-full ${
        onClick ? "cursor-pointer shadow-md hover:shadow-xl" : "border"
      } bg-${style.primaryColor ? style.primaryColor : "white"}`}
      onClick={onClick}
      style={{ backgroundColor: backgroundColor }}
    >
      {icon && <img className="w-full h-full p-4" src={icon} alt="" />}
      {avatar && <Avatar className="w-full h-full p-1" svg={avatar} />}
      <span className="absolute bottom-0 inline-block p-1 -mb-6 text-xs whitespace-no-wrap opacity-0 pointer-events-none group-hover:opacity-100">
        {hoverText}
      </span>
    </li>
  );
};

export default CircleIcon;
