import React, { useState, useEffect, useCallback } from "react";
import Joyride from "react-joyride";

import TutorialContext from ".";
import JoyrideTooltip from "./components/JoyrideTooltip";

const TutorialProvider = ({ children }) => {
  const [hasTutorial, setHasTutorial] = useState(false);
  const [steps, setSteps] = useState(null);
  const [isTutorialRunning, setTutorialRunning] = useState(false);

  useEffect(() => {
    if (steps) {
      setHasTutorial(true);
    } else {
      setHasTutorial(false);
    }
  }, [steps]);

  const startTutorial = useCallback(() => {
    setTutorialRunning(true);
  }, []);

  const handleCallback = useCallback((data) => {
    if (data.action === "skip" && data.lifecycle === "complete") {
      setTutorialRunning(false);
    }
  }, []);

  return (
    <TutorialContext.Provider
      value={{
        hasTutorial,
        steps,
        setSteps,
        startTutorial,
      }}
    >
      {children}
      {hasTutorial && (
        <Joyride
          callback={handleCallback}
          steps={steps}
          showProgress
          tooltipComponent={JoyrideTooltip}
          continuous
          autoStart={true}
          run={isTutorialRunning}
          showSkipButton
        />
      )}
    </TutorialContext.Provider>
  );
};

export default TutorialProvider;
