import React from "react";
import { useProfile } from "../../../../contexts";

const TravelPortalOptions = ({ visible }) => {
  // const {
  //   message: partyMessage,
  //   setMembers: setPartyMembers,
  //   setSocketUrl: setPartySocketURL,
  //   setToken: setPartyToken,
  //   socket: partySocket,
  //   sendSocketMessage: sendPartyMessage,
  // } = useSocket("PARTY_SOCKET");
  const { profile } = useProfile();

  return visible && profile ? (
    // <TravelPortal
    //   isHost={true}
    //   partyMembers={[]}
    //   partyMessage={partyMessage}
    //   partySocket={partySocket}
    //   setPartyMembers={setPartyMembers}
    //   setPartySocketURL={setPartySocketURL}
    //   setPartyToken={setPartyToken}
    //   sendPartyMessage={sendPartyMessage}
    //   partyCode={profile ? profile.code : null}
    //   setPartyCode={() => {}}
    //   sendSocketMessage={() => {}}
    //   setSentMemberRequests={() => {}}
    //   setSentPartyRequests={() => {}}
    //   addNotification={() => {}}
    //   setPartyTalking={() => {}}
    // />
    <></>
  ) : null;
};

export default TravelPortalOptions;
