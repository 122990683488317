import React from "react";

import Avatar from "../../../../../ui/Avatar";
import withMemo from "../../../../../../utils/withMemo";
import detectiveGlass from "./../../../assets/detective-glass.png";
import pistol from "./../../../assets/pistol.png";
import knife from "./../../../assets/knife.png";
import pray from "./../../../assets/pray.png";
import RTCAudioIcon from "../../../scenes/Intro/components/PlayerCard/RTCAudioIcon";

const Player = withMemo(
  ({
    avatar,
    nickname,
    vote,
    status,
    speaking,
    muted,
    isNight,
    role,
    isUser,
    userRole,
    connected,
    isInGame,
  }) => {
    return (
      <div
        className={`player absolute flex flex-col items-center shadow-lg rounded-full ${
          status === "killed"
            ? "bg-red-700 border-gray-900"
            : status === "lynched" || status === "revenged"
            ? "bg-gray-700 border-gray-900"
            : "bg-white border-mafia-primary"
        } ${speaking && !muted ? "mafia-player-speaking" : ""}`}
      >
        <Avatar svg={avatar} />
        <div
          className={`info absolute flex w-auto h-6 rounded-md shadow-sm border-t-2 ${
            isUser
              ? "bg-mafia-secondary border-mafia-primary text-mafia-primary"
              : userRole &&
                userRole.includes("mafia") &&
                isInGame &&
                role &&
                role.includes("mafia")
              ? "bg-red-700 border-red-900"
              : isInGame &&
                userRole === "mafia-semi" &&
                role === "villager-save"
              ? "bg-blue-700 border-blue-900"
              : "bg-mafia-primary border-mafia-secondary"
          }`}
        >
          <span className="px-2 whitespace-no-wrap flex items-center">
            {userRole &&
              userRole.includes("mafia") &&
              isInGame &&
              role === "mafia-flagbearer" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  className={`w-6 h-6 pr-2 fill-current ${
                    userRole === "mafia-flagbearer"
                      ? "text-red-700"
                      : "text-mafia-secondary"
                  }`}
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M14.4 6l-.24-1.2c-.09-.46-.5-.8-.98-.8H6c-.55 0-1 .45-1 1v15c0 .55.45 1 1 1s1-.45 1-1v-6h5.6l.24 1.2c.09.47.5.8.98.8H19c.55 0 1-.45 1-1V7c0-.55-.45-1-1-1h-4.6z" />
                </svg>
              )}
            {nickname}
          </span>
        </div>
        {vote && (
          <div className="vote-info absolute flex items-center bg-white px-1 rounded-full shadow-md">
            {isNight ? (
              role && role.includes("mafia") ? (
                <img className="w-4 h-4 mr-2" src={pistol} alt="" />
              ) : role === "detective" ? (
                <img className="w-4 h-4 mr-2" src={detectiveGlass} alt="" />
              ) : role === "healer" ? (
                <img className="w-4 h-4 mr-2" src={pray} alt="" />
              ) : (
                <img className="w-4 h-4 mr-2" src={knife} alt="" />
              )
            ) : (
              <img className="w-4 h-4 mr-2" src={knife} alt="" />
            )}
            <span className="inline-block w-24 ml-2 text-left truncate text-gray-900">
              {vote}
            </span>
          </div>
        )}
        <RTCAudioIcon speaking={speaking} muted={muted} connected={connected} />
      </div>
    );
  },
  [
    "avatar",
    "nickname",
    "vote",
    "status",
    "speaking",
    "muted",
    "isNight",
    "userRole",
    "role",
    "isUser",
    "connected",
    "isInGame",
  ]
);

export default Player;
