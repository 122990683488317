import confetti from "canvas-confetti";

const DURATION = 15 * 1000;

const isMobile = window.innerWidth < 1000;

const snow = (duration = DURATION) => {
  if (!window._confetti_snowRunning) {
    window._confetti_snowRunning = true;
    var animationEnd = Date.now() + duration;
    var skew = 1;

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    (function frame() {
      var timeLeft = animationEnd - Date.now();
      var ticks = Math.max(200, (isMobile ? 100 : 500) * (timeLeft / duration));
      skew = Math.max(0.8, skew - 0.001);

      confetti({
        particleCount: 1,
        startVelocity: 0,
        ticks: ticks,
        gravity: 0.5,
        origin: {
          x: Math.random(),
          // since particles fall down, skew start toward the top
          y: Math.random() * skew - 0.2,
        },
        colors: ["#f15824", "#ffffff"],
        shapes: ["circle"],
        scalar: randomInRange(0.4, 1),
      });

      if (timeLeft > 0) {
        requestAnimationFrame(frame);
      } else {
        window._confetti_snowRunning = false;
      }
    })();
  }
};

const schoolPride = (duration = DURATION) => {
  if (!window._confetti_schoolPrideRunning) {
    window._confetti_schoolPrideRunning = true;
    var end = Date.now() + duration;

    (function frame() {
      if (isMobile) {
        confetti({
          particleCount: 1,
          angle: 90,
          spread: 55,
          origin: { x: 0.5, y: 1 },
        });
      } else {
        confetti({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: { x: 0 },
        });
        confetti({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { x: 1 },
        });
      }

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      } else {
        window._confetti_schoolPrideRunning = false;
      }
    })();
  }
};

const fireworks = (duration = DURATION) => {
  if (!window._confetti_fireworksRunning) {
    window._confetti_fireworksRunning = true;
    var animationEnd = Date.now() + duration;
    var defaults = { startVelocity: 10, spread: 360, ticks: 100, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    var interval = setInterval(function () {
      var timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        window._confetti_fireworksRunning = false;
        return clearInterval(interval);
      }

      var particleCount = (isMobile ? 20 : 100) * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.49), y: Math.random() - 0.2 },
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.51, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);
  }
};

export { schoolPride, fireworks, snow };
