import React, { useState, useContext, useEffect } from "react";

import Modal from "../../../../ui/Modal";
import { useInput, useResponseFlash } from "../hooks";
import { useSeed } from "../../contexts/seed";
import ProfileContext from "../../../../contexts/profile";
import useApi from "../../../../hooks/useApi";

const BecomeMemberModal = ({ close }) => {
  const { value: why, bind: bindWhy } = useInput("");
  const { value: how, bind: bindHow } = useInput("");
  const { response, setResponse } = useResponseFlash();
  const { seedData, style } = useSeed();
  const { myDestination } = useContext(ProfileContext);
  const profileApi = useApi("profile", true);
  const zoworldApi = useApi("zo world", true);

  const [loadingSet, setLoadingSet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (myDestination && myDestination.current.code === seedData.code) {
      setStep(1);
    } else {
      setStep(0);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myDestination]);

  const handleSetDestination = async () => {
    try {
      setLoadingSet(true);
      const response = await profileApi.post(
        "/profile/api/v1/me/destination/",
        JSON.stringify({ code: seedData.code })
      );
      if (response.status === 200) {
        setStep(1);
        setLoadingSet(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingSet(false);
    }
  };

  const handleSubmit = async () => {
    if (!why.trim().length || !how.trim().length) {
      setResponse("Fill the above fields.");
      return;
    } else {
      const data = {
        destination: seedData.code,
        data: {
          questions: [
            {
              question: "Why should you be considered?",
              answer: why,
            },
            {
              question: "What would you do?",
              answer: how,
            },
          ],
        },
      };
      try {
        await zoworldApi.post(
          "/zoworld/api/v1/request/destination/host/",
          JSON.stringify(data)
        );
        setResponse("Thanks for applying!");
        setTimeout(close, 1000);
      } catch (error) {
        console.log(error);
        setResponse("Unable to submit. Try again later.");
      }
    }
  };

  const next = () => {
    setStep((s) => ++s);
  };

  return (
    <Modal visible close={close} disableCloseOutside>
      {loading ? (
        <></>
      ) : (
        step === 0 && (
          <section
            className={`flex fade-in flex-col items-center bg-white p-8 portrait:p-4 portrait:w-full border-t-4 border-${
              style.accentColor || "orange"
            } rounded-lg overflow-hidden`}
          >
            <div className="font-bold text-2xl text-center">
              Become {seedData.name}'s member
            </div>
            <div className="my-6 w-84 text-center text-lg font-medium">
              You need to become a member of {seedData.name}, in order to become
              its destination host.
            </div>
            <button
              className={`bg-${
                style.accentColor || "orange"
              } text-white cursor-pointer mb-2 font-semibold px-6 py-4 rounded-lg shadow-lg leading-none hover:shadow-xl`}
              onClick={handleSetDestination}
            >
              {loadingSet
                ? "Setting ..."
                : `Set ${seedData.name} as my destination`}
            </button>
            <button
              className="text-gray-700 text-sm hover:underline font-medium"
              onClick={close}
            >
              No Thanks.
            </button>
          </section>
        )
      )}
      {step === 1 && (
        <section
          className={`flex fade-in flex-col items-center bg-white p-8 border-t-4 border-${
            style.accentColor || "orange"
          } rounded-lg overflow-hidden`}
        >
          <div className="font-bold text-2xl text-center">
            Destination Host's Pledge
          </div>
          <div className="my-6 w-84 text-center text-lg font-medium">
            Do you pledge to take responsibility of {seedData.name} and help
            unlock it for the Zo World?
          </div>
          <button
            className={`bg-${
              style.accentColor || "orange"
            } text-white cursor-pointer mb-2 font-semibold px-6 py-4 rounded-lg shadow-lg leading-none hover:shadow-xl`}
            onClick={next}
          >
            Yes I Do
          </button>
          <button
            className="text-gray-700 text-sm hover:underline font-medium"
            onClick={close}
          >
            I don't think I can
          </button>
        </section>
      )}
      {step === 2 && (
        <section
          className={`relative flex fade-in flex-col items-center bg-white p-4 border-t-4 border-${
            style.accentColor || "orange"
          } rounded-lg overflow-hidden`}
        >
          <div className="font-bold text-2xl text-center">
            Become {seedData.name}'s host
          </div>
          <div className="mt-4 w-108 portrait:w-full">
            <div className="font-medium text-sm mb-2">
              Why should you be considered for being the best destination host
              for {seedData.name}?
            </div>
            <textarea
              className="p-4 border outline-none w-full rounded-lg resize-none"
              placeholder="Write your answer here..."
              rows="5"
              {...bindWhy}
            />
          </div>
          <div className="mt-4 w-108 portrait:w-full">
            <div className="font-medium text-sm mb-2">
              What would you do to make {seedData.name} the best destination to
              explore and live in India?
            </div>
            <textarea
              className="p-4 border outline-none w-full rounded-lg resize-none"
              placeholder="Write your answer here..."
              rows="5"
              {...bindHow}
            />
          </div>
          <div
            className={`h-6 flex items-center justify-center font-semibold text-xs text-${
              style.accentColor || "orange"
            }`}
          >
            {response}
          </div>
          <button
            className={`bg-${
              style.accentColor || "orange"
            } text-white cursor-pointer mb-2 font-semibold px-6 py-4 rounded-lg shadow-lg leading-none hover:shadow-xl`}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </section>
      )}
    </Modal>
  );
};

export default BecomeMemberModal;
