const page = {
  widgets: [
    {
      type: "page-title",
      value: "Population",
      rowSpan: 1,
      colSpan: 1,
    },
    { type: "population-metrics", rowSpan: 1, colSpan: 5 },
    {
      type: "portal",
      rowSpan: 1,
      colSpan: 2,
    },
    {
      type: "population",
      rowSpan: 4,
      colSpan: 8,
    },
  ],
};

export default page;
