import React, { useState, useEffect, useMemo, useContext } from "react";
import { useLocation } from "react-router-dom";
import ProfileContext from "../../../contexts/profile";

import RTCContext from "../../../contexts/rtcV2";
import useApi from "../../../hooks/useApi";
import RoomContext from "../contexts/room";

const ErrorMessage = ({ title, text }) => {
  return (
    <div className="flex flex-col items-center w-full">
      <h1 className="font-bold text-lg mt-4 mb-2">{title}</h1>
      <div className="text-center w-full">{text}</div>
    </div>
  );
};

const PasswordModal = ({ close }) => {
  const { roomDetails, setMembers, setSocketUrl, setToken } = useContext(
    RoomContext
  );
  const { setUserCode, setRoomCode } = useContext(RTCContext);
  const { profile } = useContext(ProfileContext);
  const api = useApi("comms", true);
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const passwordToken = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("t");
  }, [location]);

  useEffect(() => {
    let mounted = true;

    if (api) {
      setLoading(true);
      let password = passwordToken;
      if (
        roomDetails.security === 3 ||
        (roomDetails.security === 2 && profile.status !== "premium")
      ) {
        if (!password && localStorage.getItem("entryToken")) {
          password = localStorage.getItem("entryToken");
        }
      }
      const data = JSON.stringify({ password });
      api
        .post(
          `/profile/api/v1/profile/lobby/${roomDetails.subdomain}/login/`,
          data
        )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (mounted) {
              localStorage.setItem("entryToken", passwordToken);
              setMembers(res.data.members);
              setSocketUrl(res.data.socket.path);
              setToken(res.data.socket.token);
              if (roomDetails.status === "premium") {
                setRoomCode(
                  `${process.env.REACT_APP_ENV === "production" ? "" : "s-"}${
                    res.data.profile.code
                  }`
                );
                setUserCode(profile.code);
              }

              close();
            }
          }
        })
        .catch((error) => {
          console.log(error.errorResponse);
          localStorage.removeItem("entryToken");
        })
        .then(() => {
          if (mounted) {
            setLoading(false);
          }
        });
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, passwordToken, roomDetails]);

  return (
    <section className="fade-in w-screen h-screen bg-blur fixed inset-0 z-50 flex items-center justify-center">
      <div className="relative pointer-events-auto z-40 rounded-lg bg-white border-t-4 border-orange overflow-hidden shadow-lg flex flex-col items-center w-108 portrait:w-full h-auto">
        <div className="text-2xl font-bold text-orange flex items-center justify-center w-full py-4 bg-gray-100 border-b">
          <svg
            className="w-6 h-6 fill-current mr-4"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M11.19 1.36l-7 3.11C3.47 4.79 3 5.51 3 6.3V11c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V6.3c0-.79-.47-1.51-1.19-1.83l-7-3.11c-.51-.23-1.11-.23-1.62 0zM12 11.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z" />
          </svg>
          <span>
            {roomDetails.lobby_name || `${roomDetails.nickname}'s Room`}
          </span>
        </div>
        {loading ? (
          <div className="">Logging you in the room</div>
        ) : (
          <div className="bg-white pt-4 px-8 pb-8">
            {roomDetails.security === 3 ? (
              passwordToken ? (
                <ErrorMessage
                  title="Invalid Link"
                  text={`The link has expired or is invalid. Ask ${roomDetails.nickname} for a new link.`}
                />
              ) : (
                <ErrorMessage
                  title="This room is private"
                  text={`You need to have an invite link to enter this room.`}
                />
              )
            ) : roomDetails.security === 2 ? (
              passwordToken ? (
                <ErrorMessage
                  title={`Cannot enter the room`}
                  text={
                    <div>
                      Either the link has expired/ is invalid or the host is not
                      present.
                      <br />
                      Buy premium to enter this room without any link (Go to
                      Front Desk).
                    </div>
                  }
                />
              ) : (
                <ErrorMessage
                  title="Cannot enter the room"
                  text={`Either you should be a premium member(Go to Front Desk) or have an invite link (Ask ${roomDetails.nickname}) to enter this room.`}
                />
              )
            ) : roomDetails.security === 1 && profile.status !== "premium" ? (
              <ErrorMessage
                title={`Waiting for ${roomDetails.nickname} to join`}
                text={`You can't access this room if the host is not present.`}
              />
            ) : null}
          </div>
        )}
      </div>
    </section>
  );
};

export default PasswordModal;
