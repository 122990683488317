import React, { useContext } from "react";

import footer1 from "./../../../../assets/footer-1.svg";
import ProfileContext from "./../../../contexts/profile";
import Modal from "./../../../ui/Modal";

const OnboardedModal = ({ close }) => {
  const { profile } = useContext(ProfileContext);
  return (
    <Modal close={close} visible backgroundTheme="light" hideCloseButton>
      <div className="relative border-orange border-t-4 bg-white p-6 pb-0 rounded-lg shadow-2xl h-120 w-144 flex flex-col items-center">
        <header className="font-black text-3xl fadeInUp animation-delay-100">
          Welcome aboard,{" "}
          <span className="text-orange">{profile && profile.first_name}</span>!
        </header>
        <section className="mb-4 flex-col flex items-center">
          <div className="text text-center w-5/6 mt-4 mb-6 fadeInUp animation-delay-300">
            Excited to be here? <strong>Zo World</strong> is a nation without
            boundaries, where passionate people can showcase their talents and
            follow their hearts fearlessly. We hope you have a great time with
            us.
          </div>
          <button
            className="bg-orange px-6 py-3 rounded-lg shadow-md hover:shadow-lg focus:outline-none font-medium text-white fadeInUp animation-delay-500"
            onClick={close}
          >
            Close
          </button>
        </section>

        <footer
          className="bg-contain bg-bottom bg-no-repeat h-48 flex-shrink-0 w-full fadeInUp flex-grow"
          style={{ backgroundImage: `url(${footer1})` }}
        ></footer>
      </div>
    </Modal>
  );
};

export default OnboardedModal;
