import React, { useContext, useEffect } from "react";

import Widgets from "../Widgets";
// import IntroModals from "./components/IntroModals";
import TutorialContext from "./../../../../../contexts/tutorial";

const Page = ({ widgets, steps }) => {
  const { setSteps } = useContext(TutorialContext);

  useEffect(() => {
    if (steps && steps.length) {
      setSteps(steps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps]);

  return (
    <>
      <Widgets widgets={widgets} />
      {/* <IntroModals /> */}
    </>
  );
};

export default Page;
