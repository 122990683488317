import React from "react";
import DateTime from "react-datetime";

interface DateTimePickerProps {
  value: any;
  setValue: React.Dispatch<any>;
  hideTime?: boolean;
  hideDate?: boolean;
  placeholder?: string;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  value,
  setValue,
  hideTime,
  hideDate,
  placeholder,
}) => {
  return (
    <DateTime
      value={value}
      onChange={setValue}
      className="flex-grow"
      inputProps={{
        className:
          "p-3 border rounded-lg focus:outline-none focus:shadow-outline text-sm w-full",
        placeholder,
      }}
      timeFormat={!hideTime}
      dateFormat={!hideDate && "D MMM, YYYY"}
      renderInput={(props) => {
        return <input {...props} value={value ? props.value : ""} />;
      }}
    />
  );
};

export default DateTimePicker;
