import React, { useContext, useEffect, useState } from "react";

import Destinations from "./components/Destinations";
import Header from "./components/Header";
import SubHeader from "./components/SubHeader";
// import States from './components/States';
// import Districts from './components/Districts';
import humanize from "humanize-number";
import wiggle from "./../../../assets/wiggle.svg";
import Roles from "./components/Roles";
import Timeline from "./components/Timeline";
// import logo from "./../../../assets/logo.svg";
import publicApi from "../../../utils/api";
import { SeedContext } from "../../contexts/contexts";

const Country = () => {
  const { seedData } = useContext(SeedContext);

  const [populationMetrics, setPopulationMetrics] = useState(null);

  useEffect(() => {
    if (publicApi && !populationMetrics) {
      try {
        publicApi
          .get(`/zoworld/api/v1/population/metrics/?country_id=${"101"}`)
          .then((res) => {
            console.log("metric", res.data);
            setPopulationMetrics(res.data); //set metrics
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, [populationMetrics]);

  if (populationMetrics) {
    return (
      <main
        className="flex flex-col w-full h-full max-h-screen text-gray-900 bg-white"
        style={{ backgroundImage: `url(${wiggle})` }}
      >
        <Header populationMetrics={populationMetrics} humanize={humanize} />

        <SubHeader
          populationMetrics={populationMetrics}
          seedData={seedData}
          humanize={humanize}
        />

        <div
          className="flex flex-col mx-20 my-5 bg-gray-200 br-5 rounded-sm shadow-lg"
          style={{ height: "70vh" }}
        >
          <div className="grid grid-cols-3 p-4 gap-4 bg-gray-200 rounded overflow-hidden shadow-lg">
            <Destinations
              populationMetrics={populationMetrics}
              seedData={seedData}
              humanize={humanize}
            />
            {/* <States />
              <Districts /> */}
            <Roles
              populationMetrics={populationMetrics}
              seedData={seedData}
              humanize={humanize}
            />
            <Timeline
              populationMetrics={populationMetrics}
              humanize={humanize}
            />
          </div>
        </div>
      </main>
    );
  }

  return null;
};

export default Country;
