import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Portal from "../../../../components/ui/Portal";
import { useAuth, useProfile } from "../../contexts";
import backIcon from "./../../../../assets/back.svg";
import logoutIcon from "./../../../../assets/logout.svg";
import ContentPane from "./components/ContentPane";
import LogoutModal from "./components/LogoutModal";
import MenuPane from "./components/MenuPane";

const PANES = ["general", "notification", "social"];

const Settings = ({ forceVisible }) => {
  const { logout } = useAuth();
  const { profile } = useProfile();
  const history = useHistory();
  const location = useLocation();

  const [visible, setVisible] = useState(false);
  const [activePane, setActivePane] = useState("");
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const [activity, setActivity] = useState("menu");

  useEffect(() => {
    if (forceVisible) {
      const values = location.pathname
        .split("/")
        .filter((f) => f !== "/" && f !== "");
      console.log(values);
      if (values[0] === "settings") {
        setVisible(true);
        if (values[1] && PANES.indexOf(values[1]) !== -1) {
          setActivePane(values[1]);
          setActivity("content");
        } else {
          // if (window.innerWidth < 640) {
          //   setActivePane("general");
          // } else {
          setActivePane("");
          setActivity("menu");
          // }
        }
      }
    } else {
      const values = location.hash.split("/");
      if (values[0] === "#settings") {
        setVisible(true);
        if (values[1] && PANES.indexOf(values[1]) !== -1) {
          setActivePane(values[1]);
          setActivity("content");
        } else {
          // if (window.innerWidth < 640) {
          //   setActivePane("general");
          // } else {
          setActivePane("");
          setActivity("menu");
          // }
        }
      } else {
        setVisible(false);
      }
    }
  }, [location, forceVisible]);

  const handleMenuClick = (pane) => {
    if (forceVisible) {
      history.push(`/settings/${pane}`);
    } else {
      history.push(`#settings/${pane}`);
    }
  };

  const handleBack = () => {
    history.push(history.location.pathname);
  };

  const handleLogout = () => {
    setLogoutModalVisible(true);
    setTimeout(() => {
      logout();
      window.open(`https://${process.env.REACT_APP_ROOT_DOMAIN}`, "_parent");
    }, 3000);
  };

  const handleClose = () => {
    logout();
    window.open(`https://${process.env.REACT_APP_ROOT_DOMAIN}`, "_parent");
  };

  return profile && visible ? (
    <Portal>
      <section className="fixed top-0 left-0 w-full h-full p-4 flex z-80">
        <div className="flex rounded-lg bg-white overflow-hidden w-full h-full shadow-md">
          <div
            className={`h-full w-1/4 border-r-2 bg-gray-100 flex flex-col portrait:w-full ${
              activity === "menu" ? "portrait:flex" : "portrait:hidden"
            }`}
          >
            <div className="flex-shrink-0 font-bold text-4xl px-4 py-3 flex items-center">
              {!forceVisible && (
                <img
                  className="cursor-pointer mr-2"
                  src={backIcon}
                  onClick={handleBack}
                  alt=""
                />
              )}
              Settings
            </div>
            <MenuPane
              handleMenuClick={handleMenuClick}
              activePane={activePane}
            />
            <button
              className="w-full flex-shrink-0 p-4 text-xs font-semibold flex items-center justify-center bg-gray-200 hover:bg-gray-300"
              onClick={handleLogout}
              id="logout"
            >
              <img src={logoutIcon} alt="" className="w-4 h-4 mr-2" />
              Log Out
            </button>
          </div>
          <div
            className={`w-full h-full flex flex-col ${
              activity === "content" ? "portrait:block" : "portrait:hidden"
            }`}
          >
            <ContentPane activePane={activePane} />
          </div>
        </div>
      </section>
      {logoutModalVisible && <LogoutModal close={handleClose} />}
    </Portal>
  ) : (
    <></>
  );
};

export default Settings;
