import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Header from "../components/Header";
import Home from "./Home";
import { useSeed } from "../contexts/seed";
import LeaderBoard from "./Leaderboard";
import Timeline from "./Timeline";
import Population from "./Population";
import AppContext from "../../../contexts/app";
import Mafia from "./../../../games/Mafia";
import MafiaRedirect from "../../../commons/MafiaRedirect";

const ProtectedRoute = (props) => {
  const { slug } = useContext(AppContext);

  if (props.allowedSlug && slug === props.allowedSlug) {
    return <CustomRoute {...props} />;
  } else if (
    Array.isArray(props.allowedSlugs) &&
    props.allowedSlugs.indexOf(slug) !== -1
  ) {
    return <CustomRoute {...props} />;
  } else {
    return null;
  }
};

const CustomRoute = (props) => {
  useEffect(() => {
    props.toggleHeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Route {...props} />;
};

const ZoPages = () => {
  const {
    style: { backgroundPattern, backgroundColor, textPrimaryColor },
  } = useSeed();

  const [headerVisible, setHeaderVisibility] = useState(true);

  const showHeader = () => {
    setHeaderVisibility(true);
  };
  const hideHeader = () => {
    setHeaderVisibility(false);
  };

  return (
    <main
      className={`bg-repeat flex flex-col w-screen portrait:h-auto landscape:h-screen min-h-screen text-${
        textPrimaryColor ? textPrimaryColor : "gray-900"
      } bg-${
        backgroundColor ? backgroundColor : "white"
      } landscape:overflow-y-hidden`}
      style={{ backgroundImage: `url("${backgroundPattern}")` }}
    >
      <BrowserRouter>
        {headerVisible && <Header />}
        <Switch>
          <CustomRoute
            path="/leaderboard"
            exact
            component={LeaderBoard}
            toggleHeader={showHeader}
          />
          <CustomRoute
            path="/timeline"
            exact
            component={Timeline}
            toggleHeader={showHeader}
          />
          <CustomRoute
            path="/population"
            exact
            component={Population}
            toggleHeader={showHeader}
          />
          <ProtectedRoute
            path="/r/:id"
            component={Mafia}
            allowedSlug="playmafia"
            toggleHeader={hideHeader}
          />
          <ProtectedRoute
            path="/room/:id"
            component={Mafia}
            allowedSlug="playmafia"
            toggleHeader={hideHeader}
          />
          <ProtectedRoute
            path="/season/finals"
            exact
            component={MafiaRedirect}
            allowedSlug="playmafia"
            toggleHeader={hideHeader}
          />
          <CustomRoute path="/" component={Home} toggleHeader={showHeader} />
        </Switch>
      </BrowserRouter>
    </main>
  );
};

export default ZoPages;
