import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useApi from "../../hooks/useApi";
import Onboarding from "../../screens/Onboarding";
import ProfileContext from "./ProfileContext";

interface ProfileProviderProps {}

const ProfileProvider: React.FC<ProfileProviderProps> = ({ children }) => {
  const profileApi = useApi("PROFILE_ME");
  const activityHoursApi = useApi("PROFILE_ME_ACTIVITY_HOURS");
  const history = useHistory();
  const location = useLocation();

  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [activityHours, setActivityHours] = useState<number>(0);

  const requiredFields: string[] = useMemo(() => {
    if (profile) {
      if (profile.subdomain) {
        if (
          location.pathname === "/" ||
          location.pathname === "/@" ||
          location.pathname === "/@portal"
        ) {
          history.push("/@" + profile.subdomain);
        }
        return [];
      } else {
        return ["subdomain"];
      }
      // if (seedData?.profile_validations) {
      //   const _profile: GeneralObject = profile;
      //   return seedData.profile_validations
      //     .filter((f: any) => f.activity_hours <= activityHours)
      //     .map((m: any) => m.key)
      //     .filter((f: string) => _profile[f] != null && _profile[f] !== "");
      // } else {
      // return [];
      // }
    } else {
      return ["welcome"];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (profileApi && activityHoursApi) {
      profileApi
        .get("")
        .then((res) => {
          console.log("Profile", res.data.profile);
          setProfile(res.data.profile);
        })
        .catch((e) => {
          console.log(e);
        });
      activityHoursApi
        .get("")
        .then((res) => {
          setActivityHours(res.data.hours);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [activityHoursApi, profileApi]);

  const updateProfile = (data: GeneralObject) => {
    setProfile((p) => {
      return { ...Object.assign(p, { ...data }) };
    });
  };

  const updateProfileKey = async (key: string, value: any) => {
    if (profileApi) {
      try {
        const data: GeneralObject = {};
        data[key] = value;
        const response = await profileApi.post(``, JSON.stringify(data));
        if (response.status === 200) {
          console.log(response);
          updateProfile(response.data.profile);
          return true;
        }
        return false;
      } catch (error) {
        console.log(error);
        return false;
      }
    }
    return false;
  };

  return (
    <ProfileContext.Provider
      value={{
        activityHours,
        profile,
        updateProfile,
        setProfile,
        updateProfileKey,
      }}
    >
      {requiredFields.length === 0 ? (
        children
      ) : (
        <Onboarding fields={requiredFields} />
      )}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
