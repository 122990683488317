import React from "react";

interface TextareaProps {
  placeholder?: string;
  type: string;
  value: any;
  setValue: (value: any) => void;
}

const Textarea: React.FC<TextareaProps> = ({
  placeholder,
  type,
  value,
  setValue,
}) => {
  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setValue(e.target.value);
  };

  return (
    <textarea
      className="p-3 border rounded-lg focus:outline-none focus:shadow-outline text-sm w-full resize-none"
      placeholder={placeholder}
      rows={3}
      value={value}
      onChange={handleChange}
    />
  );
};

export default Textarea;
