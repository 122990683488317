import React, { useEffect, useState } from "react";
import { RTCContext } from ".";
import useJanusSocket from "../../../../components/commons/Janus/hooks/useJanusSocket";
import Janus from "./../../../../components/commons/Janus";

interface RTCProviderProps {}

const RTCProvider: React.FC<RTCProviderProps> = ({ children }) => {
  const { janusSocket, sendSocketMessage, janusMessage } =
    useJanusSocket("RTC_ROOM");
  const [peers, setPeers] = useState<any[]>([]);
  const [isActive, setActive] = useState<boolean>(false);
  const [selfMuted, setSelfMuted] = useState<boolean>(true);
  const [roomCode, setRoomCode] = useState<string | null>(null);
  const [displayName, setDisplayName] = useState<string | null>(null);
  const [userCode, setUserCode] = useState<string | null>(null);
  const [rtcSupported, setRtcSupported] = useState<boolean>(true);
  const [volume, setVolume] = useState<number>(1);
  const [recordName, setRecordName] = useState<string | false | null>(null);
  const [inputDeviceId, setInputDeviceId] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const _navigator: any = window.navigator;

    const isWebRTCSupported =
      _navigator.getUserMedia ||
      _navigator.webkitGetUserMedia ||
      _navigator.mozGetUserMedia ||
      _navigator.msGetUserMedia ||
      window.RTCPeerConnection;

    setRtcSupported(
      isWebRTCSupported != null &&
        window.navigator.userAgent.indexOf("Edge") === -1
    );
  }, []);

  return (
    <RTCContext.Provider
      value={{
        peers,
        setPeers,
        isActive,
        setActive,
        isSelfMute: selfMuted,
        setIsSelfMute: setSelfMuted,
        setRoomCode,
        setUserCode,
        rtcSupported,
        roomCode,
        setDisplayName,
        volume,
        setVolume,
        setRecordName,
        inputDeviceId,
        setInputDeviceId,
      }}
    >
      {children}
      {roomCode && userCode && recordName != null && (
        <Janus
          peers={peers}
          roomCode={roomCode}
          selfMuted={selfMuted}
          inputDeviceId={inputDeviceId}
          displayName={displayName}
          setPeers={setPeers}
          setSelfMuted={setSelfMuted}
          userPassport={userCode}
          isActive={isActive}
          setActive={setActive}
          volume={volume}
          record={recordName === false ? null : recordName}
          socket={janusSocket}
          message={janusMessage}
          sendMessage={sendSocketMessage}
        />
      )}
    </RTCContext.Provider>
  );
};

export default RTCProvider;
