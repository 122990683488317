import React, { useState } from "react";
import female from "./../../../../../assets/female.svg";
import male from "./../../../../../assets/male.svg";

const ToggleBase = ({ value, onChange }) => {
  const [base, setBase] = useState(value);

  const handleChange = (_base) => {
    setBase(_base);
    onChange(_base);
  };

  return (
    <div className="flex items-center justify-around rounded-full absolute bottom-0 bg-gray-100 w-16 h-8 mb-1">
      <img
        className={`w-6 h-6 p-1 rounded-full cursor-pointer ${
          base === 1 ? "bg-gray-300" : ""
        }`}
        src={male}
        alt="Male"
        onClick={handleChange.bind(null, 1)}
      />
      <img
        className={`w-6 h-6 p-1 rounded-full cursor-pointer ${
          base === 2 ? "bg-gray-300" : ""
        }`}
        src={female}
        alt="Female"
        onClick={handleChange.bind(null, 2)}
      />
    </div>
  );
};

export default ToggleBase;
