import React from "react";
import Portal from "../../../ui/Portal";

const LogoutModal = ({ close }) => {
  return (
    <Portal>
      <section className="w-screen h-screen fixed inset-0 z-90 bg-blur flex items-center justify-center opacity-100 pointer-events-auto">
        <div className="w-108 portrait:w-full portrait:mx-4 rounded-lg bg-white border-t-4 border-orange p-4 flex flex-col items-center shadow-lg">
          <div className="font-bold text-3xl text-center">See you soon!</div>

          <div className="text-lg text-center font-medium mt-4">
            You have been successfully logged out.
          </div>
          <div
            className="bg-orange text-white cursor-pointer mt-6 mb-2 font-semibold px-6 py-4 rounded-lg shadow-lg leading-none hover:shadow-xl"
            onClick={close}
          >
            Continue
          </div>
        </div>
      </section>
    </Portal>
  );
};

export default LogoutModal;
