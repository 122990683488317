import moment from "moment";
import React, { useEffect, useState } from "react";
import useResponseFlash from "../../../../../../../../components/apps/Zo/components/hooks/useResponseFlash";
import PaymentCompleteModal from "../../../../../../../../components/commons/ZoComm/components/modals/PaymentCompleteModal";
import PaymentQuoteModal from "../../../../../../../../components/commons/ZoComm/components/modals/PaymentQuoteModal";
import useApi from "../../../../../../hooks/useApi";
import Section from "../../components/Section";
import SubSection from "../../components/SubSection";
import SubSectionRow from "../../components/SubSectionRow";

const VisaSettings = () => {
  const myPlanApi = useApi("SUBSCRIPTION_MY_PLAN");
  const plansApi = useApi("SUBSCRIPTION_PLANS");
  const { response } = useResponseFlash();

  const [selected, setSelected] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [myplan, setMyplan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cancelling, setCancelling] = useState(false);

  const [paymentQuoteVisible, setPaymentQuoteVisible] = useState(false);
  const [paymentCompleteVisible, setPaymentCompleteVisible] = useState(false);

  useEffect(() => {
    if (myPlanApi && plansApi) {
      myPlanApi
        .get("")
        .then((res) => {
          if (res.status === 200) {
            setMyplan(res.data.subscription);
            console.log(res.data.subscription);
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
      plansApi
        .get("")
        .then((res) => {
          setPaymentData(res.data);
          console.log(res.data);
          setSelected(res.data.plans[0].id);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [myPlanApi, plansApi]);

  const handleBuy = async () => {
    setPaymentQuoteVisible(true);
  };

  const showPaymentForm = () => {
    setPaymentQuoteVisible(false);
    setPaymentCompleteVisible(true);
  };

  const handleCancel = async () => {
    try {
      const response = await myPlanApi.post("/cancel/", JSON.stringify({}));
      console.log(response);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkCancelling = () => {
    if (cancelling) {
      handleCancel();
    } else {
      setCancelling(true);
    }
  };

  return (
    <Section title="My Membership">
      {loading ? (
        <span className="font-medium italic">Fetching info</span>
      ) : myplan && myplan.plan && myplan.status === "active" ? (
        <SubSection title="Plan Details">
          <SubSectionRow
            title="Plan type"
            visibleArea={<strong>{myplan.plan.name} Plan</strong>}
            editText={cancelling ? "Click again to confirm" : "Cancel Plan"}
            editOnClick={checkCancelling}
          ></SubSectionRow>
          <SubSectionRow
            title="Next Renewal Date"
            visibleArea={moment(myplan.next_charge).format("Do MMMM, YYYY")}
            editable={false}
          ></SubSectionRow>
          <SubSectionRow
            title="Status"
            visibleArea={
              <span className="bg-gray-200 text-green-500 text-sm font-medium p-2 leading-none rounded-lg">
                Active
              </span>
            }
            editable={false}
          ></SubSectionRow>
        </SubSection>
      ) : (
        <div className="flex flex-col px-4">
          <div className="mb-4 w-full">
            <div className="font-bold text-xl">
              Becoming a Member of Zo World comes with extra perks and prestige!
            </div>
            <ul className="list-disc w-full pl-6 pr-4 mt-2">
              {paymentData &&
                paymentData.benefits.map((benefit) => (
                  <li key={benefit}>{benefit}</li>
                ))}
            </ul>
          </div>
          <div className="font-bold text-xl mb-4">
            Select your plan and become a member now!
          </div>
          <div className="flex w-full">
            {paymentData &&
              paymentData.plans.map((plan) => (
                <div
                  key={plan.id}
                  className={`px-2 pt-4 pb-10 relative overflow-hidden flex flex-col items-center justify-center w-1/3 text-center mr-2 border rounded-md cursor-pointer ${
                    selected === plan.id ? "shadow-lg bg-orange" : "bg-white"
                  }`}
                  onClick={setSelected.bind(null, plan.id)}
                >
                  <div
                    className={`text-sm font-semibold mb-2 ${
                      selected === plan.id ? "text-white" : "text-orange"
                    }`}
                  >
                    {plan.name}
                  </div>
                  <div className={selected === plan.id ? "text-white" : ""}>
                    <span className="text-lg font-bold">₹{plan.price}</span>/{" "}
                    {plan.short_text}
                  </div>
                  <div
                    className={`w-full p-1 text-xs font-medium bottom-0 left-0 text-center absolute ${
                      selected === plan.id
                        ? " bg-white text-orange"
                        : " bg-orange text-white"
                    }`}
                  >
                    {plan.discount_text}
                  </div>
                </div>
              ))}
          </div>
          <div className="flex flex-col items-center">
            <div className="w-full h-6 flex items-center justify-start font-semibold text-xs text-orange">
              {response}
            </div>
            <button
              className="w-auto inline-block bg-orange text-white font-semibold px-6 py-4 rounded-lg shadow-lg leading-none hover:shadow-xl"
              onClick={handleBuy}
            >
              Become a Member
            </button>
          </div>
        </div>
      )}
      <PaymentQuoteModal
        visible={paymentQuoteVisible}
        close={setPaymentQuoteVisible.bind(null, false)}
        initialPriceId={selected}
        showPaymentForm={showPaymentForm}
      />
      {paymentCompleteVisible && (
        <PaymentCompleteModal
          close={() => {
            window.location.reload();
          }}
        />
      )}
    </Section>
  );
};

export default VisaSettings;
