/* eslint-disable eqeqeq */
import Axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { deleteCookie, getCookie, setCookie } from "../../../utils/index.js";
import AuthModal from "./components/AuthModal.js";
import AuthContext from "./index.js";

const LogButton = ({ onClick }) => {
  return process.env.NODE_ENV !== "production" ? (
    <button
      style={{ position: "fixed", top: "60px", left: "0", zIndex: "999999" }}
      onClick={onClick}
    >
      Login
    </button>
  ) : null;
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [tokenExpiry, setTokenExpiry] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [authModalVisible, setAuthModalVisibility] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(null);

  const publicApi = useMemo(() => {
    return Axios.create({
      baseURL: process.env.REACT_APP_API_ZO,
      headers: {
        "Content-Type": "application/json",
        "Client-App-Id": "Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU",
      },
    });
  }, []);

  const api = useMemo(
    (baseURL) => {
      return token && sessionId
        ? Axios.create({
            baseURL: baseURL || process.env.REACT_APP_API_ZO,
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
              "Client-App-Id": "Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU",
              "Client-User-Id": sessionId,
            },
          })
        : null;
    },
    [token, sessionId]
  );

  const usePublicApi = useCallback((baseURL) => {
    return Axios.create({
      baseURL: baseURL || process.env.REACT_APP_API_ZO,
      headers: {
        "Content-Type": "application/json",
        "Client-App-Id": "Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU",
      },
    });
  }, []);

  const useAuthenticatedApi = useCallback(
    (baseURL) => {
      return token && sessionId
        ? Axios.create({
            baseURL: baseURL || process.env.REACT_APP_API_ZO,
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
              "Client-App-Id": "Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU",
              "Client-User-Id": sessionId,
            },
          })
        : null;
    },
    [token, sessionId]
  );

  const profileApi = useMemo(
    (baseURL) => {
      return token && sessionId
        ? Axios.create({
            baseURL: process.env.REACT_APP_API_ZO,
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
              "Client-App-Id": "Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU",
              "Client-User-Id": sessionId,
            },
          })
        : null;
    },
    [token, sessionId]
  );

  const feedbackApi = useMemo((baseURL) => {
    return Axios.create({
      baseURL: process.env.REACT_APP_API_ZO,
      headers: {
        "Content-Type": "application/json",
        "Client-App-Id": "Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU",
      },
    });
  }, []);

  const formApi = useMemo(() => {
    return token && sessionId
      ? Axios.create({
          baseURL: process.env.REACT_APP_API_ZO,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/x-www-form-urlencoded",
            "Client-App-Id": "Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU",
            "Client-User-Id": sessionId,
          },
        })
      : null;
  }, [token, sessionId]);

  const webAppApi = useMemo(() => {
    return Axios.create({
      baseURL: process.env.REACT_APP_API_ZO,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }, []);

  const paymentApi = useMemo(
    (baseURL) => {
      return token && sessionId
        ? Axios.create({
            baseURL: process.env.REACT_APP_API_ZO,
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
              "Client-App-Id": "Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU",
              "Client-User-Id": sessionId,
            },
          })
        : null;
    },
    [token, sessionId]
  );

  const zoworldApi = useMemo(
    (baseURL) => {
      return token && sessionId
        ? Axios.create({
            baseURL: process.env.REACT_APP_API_ZO,
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
              "Client-App-Id": "Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU",
              "Client-User-Id": sessionId,
            },
          })
        : null;
    },
    [token, sessionId]
  );

  useEffect(() => {
    const localToken = localStorage.getItem("zo_token");
    if (token) {
      localStorage.setItem("zo_token", token);
    } else {
      if (localToken) {
        setToken(localToken);
      } else {
        setLoggedIn(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const localTokenExpiry = localStorage.getItem("zo_token_expiry");
    if (tokenExpiry) {
      localStorage.setItem("zo_token_expiry", tokenExpiry);
    } else {
      if (localTokenExpiry) {
        setTokenExpiry(localTokenExpiry);
      } else {
        setLoggedIn(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenExpiry]);

  useEffect(() => {
    const localSessionId = localStorage.getItem("sessionId");
    if (sessionId) {
      localStorage.setItem("sessionId", sessionId);
    } else {
      if (localSessionId) {
        setSessionId(localSessionId);
      } else {
        setLoggedIn(false);
      }
    }
  }, [sessionId]);

  useEffect(() => {
    const localUser = localStorage.getItem("zo_user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    if (user) {
      localStorage.setItem("zo_user", JSON.stringify(user));
    } else {
      if (localUser) {
        setUser(localUser);
      } else {
        setLoggedIn(false);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user && token && sessionId && tokenExpiry) {
      try {
        if (new Date(tokenExpiry).getTime() < new Date().getTime()) {
          logout();
        }
      } catch (error) {
        console.log(error);
      }
      setLoggedIn(true);
    }
  }, [user, token, sessionId, tokenExpiry]);

  const logout = () => {
    setUser(null);
    setToken(null);
    setTokenExpiry(null);
    setSessionId(null);
    localStorage.clear();
  };

  const showAuthModal = () => {
    setAuthModalVisibility(true);
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      loginTest();
    }
  }, []);

  function loginTest() {
    console.log("setting local values");
    setUser({
      id: "UA-17006",
      pid: "WQ72RWXJ",
      first_name: "Ishaan",
      last_name: "Rawat",
      mobile: "91-9602262261",
      app_id: "Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU",
      user_id: "a93c480ae1",
      roles: [
        "community_manager",
        "maps_central",
        "geeta_central",
        "crs_admin",
        "zobu_chat_admin",
        "community_manager",
        "manager_partner",
        "maps_central",
        "geeta_central",
        "crs_admin",
        "zobu_chat_admin",
      ],
    });
    setSessionId("a93c480ae1");
    setToken(
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IlVBLTE3MDA2IiwicGlkIjoiV1E3MlJXWEoiLCJmaXJzdF9uYW1lIjoiSXNoYWFuIiwibGFzdF9uYW1lIjoiUmF3YXQiLCJtb2JpbGUiOiI5MS05NjAyMjYyMjYxIiwiYXBwX2lkIjoiTmUwSHNTZ1dyb01Ka1Y5SlFCcFdkN1pkR0lxQVJSbktlU1loUmRWVSIsInVzZXJfaWQiOiJhOTNjNDgwYWUxIiwiaWF0IjoxNjIyNzA4NzYyfQ.WpiJeORNrPJKBbvA_xjOz7XbJs-XCLCdAC9DNtrBfJ8"
    );
    setTokenExpiry("2021-07-03T08:26:02.166237");
  }

  return (
    <AuthContext.Provider
      value={{
        api,
        logout,
        user,
        isLoggedIn,
        token,
        sessionId,
        showAuthModal,
        publicApi,
        feedbackApi,
        zoworldApi,
        profileApi,
        formApi,
        paymentApi,
        webAppApi,
        setCookie,
        getCookie,
        useAuthenticatedApi,
        usePublicApi,
      }}
    >
      {!isLoggedIn && <LogButton onClick={loginTest} />}
      {children}
      <AuthModal
        visible={authModalVisible}
        close={setAuthModalVisibility.bind(null, false)}
      />
    </AuthContext.Provider>
  );
};

export default AuthProvider;
