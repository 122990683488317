import React, { useState, useEffect } from "react";

import ZoHeaderStat from "../../../../ui/ZoHeaderStat";
// import populationIcon from "./../../../../../assets/people.svg";
import genzIcon from '../../../../../assets/populations/genZ.svg';
import babyIcon from '../../../../../assets/populations/Baby_boomers.svg';
import femaleIcon from '../../../../../assets/populations/Female.svg';
import maleIcon from '../../../../../assets/populations/Male.svg';
import millenialsIcon from '../../../../../assets/populations/MIllennials.svg';
import transIcon from '../../../../../assets/populations/Trans.svg';

const SubHeader = ({  populationMetrics, seedData, humanize }) => {

  const [ zoGenders, setZoGenders ] = useState(null);
  const [ zoGenZ, setZoGenZ ] = useState(null);
  const [ zoMillenials, setZoMillenials ] = useState(null);
  const [ zoBoomers, setZoBoomers ] = useState(null);

  useEffect(() => {
    if(populationMetrics && seedData){

       const genders = [];
       seedData.gender && seedData.gender.map(([id, sex]) => {
         populationMetrics.gender.map(item => {
           if(item.gender === id || item.gender === id){
              genders.push({
                sex: sex,
                citizen: item.count || 0
              })
           }
           return genders
         })
         return genders
       });

       setZoGenders(genders.reduce(((obj,item) => { 
            obj[item.sex] = item; 
            return obj;
          }), {})
        )

        // calculate generations wise count

        let genz = [];
        let genz_Count = 0;
        let millennials = [];
        let millennials_count = 0;
        let boomers = [];
        let boomers_count = 0;

        populationMetrics.age_year.map(item => {
          if(item.year){
            const [year] = item.year.split('-');
            if(1996 < Number(year)){ // gen z
              genz.push(item);
              genz_Count = item.count + genz_Count;

            } else if(1981 < Number(year) < 1996){ //millenials
              millennials.push(item);
              millennials_count = item.count + millennials_count;

            } else if(1944 < Number(year) < 1964){ //boomers
              boomers.push(item);
              boomers_count = item.count + boomers_count
            }
          }
          return item
        })

        setZoGenZ({
          items: genz,
          citizens: genz_Count
        });

        setZoMillenials({
          items: millennials,
          citizens: millennials_count
        });

        setZoBoomers({
          items: boomers,
          citizens: boomers_count
        });
    }

  }, [populationMetrics, seedData]);

  return (
    <div className="flex items-center justify-center">
      <div className="flex">
        <ZoHeaderStat
          icon={maleIcon}
          title="Male"
          value={<span className='ml-2'> { (zoGenders && zoGenders['Male']) ? humanize(zoGenders['Male'].citizen) : '' } </span>}
        />
        <ZoHeaderStat
          icon={femaleIcon}
          title="Female"
          value={<span className='ml-2'> {(zoGenders && zoGenders['Female']) ? humanize(zoGenders['Female'].citizen) : '' } </span>}
        />
        <ZoHeaderStat
          title="Other"
          icon={transIcon}
          value={<span className='ml-2'> { (zoGenders && zoGenders['Other']) ? humanize(zoGenders['Other'].citizen) : '' } </span>}
        />
        <ZoHeaderStat
          title="Gen Z"
          icon={genzIcon}
          value={ zoGenZ && humanize(zoGenZ.citizens) }
        />
        <ZoHeaderStat
          title="Millennials"
          icon={millenialsIcon}
          value={zoMillenials && humanize(zoMillenials.citizens)}
        />
        <ZoHeaderStat
          title="Baby Boomers"
          icon={babyIcon}
          value={zoBoomers && humanize(zoBoomers.citizens)}
        />
      </div>
      
      <div className="flex items-center ml-3">
        {/* <div className="  px-4 py-2   my-2 mx-2 flex items-center bg-white rounded-lg shadow-md p-2 justify-between">
            <div className="text-sm">
              <p className="text-gray-900 text-md font-bold">Gen Z</p>            
            </div>
            <div>
            <div className="relative flex justify-end">
              <span className="inline-block ml-2">{ zoGenZ && humanize(zoGenZ.citizens) }</span>
            </div>
            </div>
            
        </div>

        <div className="  px-4 py-2   my-2 mx-2 flex items-center bg-white rounded-lg shadow-md p-2 justify-between">
            <div className="text-sm">
              <p className="text-gray-900 text-md font-bold">Millennials</p>            
            </div>
            <div>
            <div className="relative flex justify-end">
              <span className="inline-block ml-2">{zoMillenials && humanize(zoMillenials.citizens)}</span>
            </div>
            </div>
            
        </div>

        <div className="  px-4 py-2   my-2 mx-2 flex items-center bg-white rounded-lg shadow-md p-2 justify-between">
            <div className="text-sm">
              <p className="text-gray-900 text-md font-bold">Baby Boomers</p>            
            </div>
            <div>
            <div className="relative flex justify-end">
              <span className="inline-block ml-2">{zoBoomers && humanize(zoBoomers.citizens)}</span>
            </div>
            </div>
            
        </div> */}

      </div>

    </div>
  );
};

export default SubHeader;
