import React from "react";
import Flex from "../../structure/Flex";

interface TextToggleProps {
  value?: any;
  options: Array<{ id: any; name: string }>;
  onChange?: (key: any) => void;
}

const TextToggle: React.FC<TextToggleProps> = ({
  options,
  onChange,
  value,
}) => {
  return (
    <Flex items="center">
      {options.map((o, i) => (
        <button
          className={`focus:outline-none py-1 px-2 whitespace-no-wrap font-semibold text-xs border-r border-t border-b border-gray-300 ${
            i === 0 && "rounded-l-lg border-l"
          } ${i === options.length - 1 && "rounded-r-lg"} ${
            value === o.id
              ? "bg-orange text-white"
              : "bg-zo-off-white text-gray-800"
          }`}
          key={o.id}
          onClick={onChange?.bind(null, o.id)}
        >
          {o.name}
        </button>
      ))}
    </Flex>
  );
};

export default TextToggle;
