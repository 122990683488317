import * as Sentry from "@sentry/browser";
import React from "react";
import "react-datetime/css/react-datetime.css";
import ReactDOM from "react-dom";
import "react-spring-bottom-sheet/dist/style.css";
import "./assets/stylesheets/main.css";
import App from "./components/App";
import * as serviceWorker from "./utils/serviceWorker";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://1f228f0a992b4a6a96f0eb3c49fca5a5@o402184.ingest.sentry.io/5262843",
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.NODE_ENV,
  });
}
// if (process.env.NODE_ENV === "development") {
//   const whyDidYouRender = require("@welldone-software/why-did-you-render");
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }
if (
  process.env.NODE_ENV === "production" &&
  !localStorage.getItem("zozoConsole")
) {
  window.console.log = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
