import React, { useState, useEffect } from "react";
import humanize from "humanize-number";

import Widget from "../../ui/Widget";
import useApi from "../../../../../hooks/useApi";
const OurChallenges = (props) => {
  const api = useApi("zo world", false);

  const [populationMetrics, setPopulationMetrics] = useState(null);

  useEffect(() => {
    if (api) {
      api
        .get(`/zoworld/api/v1/population/metrics/`)
        .then((res) => {
          setPopulationMetrics(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <Widget
      className="zo-milestone p-2 bg-white border-t-4 border-orange"
      {...props}
    >
      <div className="font-bold">Our Next Milestone</div>
      <div className="flex flex-col justify-around">
        <div className="mt-6 text-5xl">
          <span className="font-bold text-orange">1M</span>{" "}
          <span className="text-gray-800">users</span>
        </div>
        <div className="mt-2">
          <div
            className="relative w-full h-2 my-4 rounded"
            style={{
              backgroundColor: "#f157241a",
            }}
          >
            <div
              className="absolute inset-0 rounded bg-orange"
              style={{
                width: populationMetrics
                  ? `${(populationMetrics.total_count / 1000000) * 100}%`
                  : "0%",
              }}
            />
          </div>
          <div className="flex items-baseline justify-between">
            <div>
              <span className="font-bold text-orange">
                {" "}
                {populationMetrics &&
                  Math.round(populationMetrics.total_count / 10000)}
                %{" "}
              </span>{" "}
              complete
            </div>
            <div>
              <span className="font-bold text-orange">
                {populationMetrics && humanize(populationMetrics.total_count)}
              </span>{" "}
              on board
            </div>
          </div>
        </div>
      </div>
    </Widget>
  );
};

export default OurChallenges;
