import React, {useState } from "react";
import SquareIcon from "../../../../ui/SquareIcon";
import logo from "../../../../../../../../assets/logo-white.svg";
import closeIcon from "../../../../../../../../assets/close.svg";
import mafiaIcon from "../../../../../assets/cult-mafia.svg";
import cameraIcon from "../../../../../assets/cult-camera.svg";
import musicIcon from "../../../../../assets/cult-music.svg";

const FilterByCult = ({onFilterClicked}) => {

  const cults = [
    {
      icon: logo,
      hoverText: "Travel",
      key: "travel",
      backgroundColor: "#f15824",
  
    },
    {
      icon: mafiaIcon,
      hoverText: "Mafia",
      key: "mafia",
      backgroundColor: "#f8b41c",
    },
    {
      icon: cameraIcon,
      hoverText: "Camera",
      key: "camera"
      // backgroundColor: "#2ab3b5",
    },
    {
      icon: musicIcon,
      hoverText: "Music",
      key: "music"
      // backgroundColor: "#4ba68e",
    },
    {
      icon: closeIcon,
      hoverText: "none",
    }
  ];

  const [active,setActive] = useState(0)
  return (
      <ul className="flex items-center">
        {cults.map((cult,i) => (  
          <SquareIcon textInset={true}
            additionalClasses={ active === i ? ' transform scale-90': '' }
            key={Math.random()}
            {...cult}
            onClick={()=>{
              setActive(i);
              onFilterClicked(cult.key)}
            }
          />
        ))}
      </ul>
  );
};

export default FilterByCult;
