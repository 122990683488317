import React, { useEffect } from "react";
import useAudio from "../../../../components/hooks/useAudio";
import Portal from "../../../../components/ui/Portal";
import { useRTC } from "../../contexts";
import useResponseFlash from "../../hooks/useResponseFlash";
import { Mic, MicOff } from "./../../assets/icons";
import unmuteAudio from "./../../assets/audio/unmute.mp3";

interface UnmuteRequestModalProps {
  close: () => void;
}

const UnmuteRequestModal: React.FC<UnmuteRequestModalProps> = ({ close }) => {
  const { response, setResponse } = useResponseFlash();

  const { isSelfMute, setIsSelfMute } = useRTC();

  const unmuteSound = useAudio(unmuteAudio, false, 0.3);

  const toggleMute = () => {
    setIsSelfMute((m) => !m);
  };

  useEffect(() => {
    setResponse("asdasd");
    if (unmuteSound) {
      unmuteSound.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSelfMute) {
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelfMute]);

  return (
    <Portal>
      <section
        className={`flex items-center justify-center fixed top-0 left-0 z-70 pointer-events-none w-screen h-screen ${
          response ? "bg-blur" : ""
        }`}
      >
        <div className="border-orange border-t-4 bg-white rounded-lg shadow-lg p-6 w-content h-content flex flex-col items-center pointer-events-auto">
          <div className="fadeInUp animation-delay-100 font-semibold text-lg">
            The host wants you to speak
          </div>
          <div className="fadeInUp animation-delay-300 text-gray-700 font-medium">
            Unmute and start speaking!
          </div>
          <button
            className="relative fadeInUp animation-delay-500 mt-6 mb-2 flex p-3 focus:outline-none focus:shadow-outline items-center justify-center bg-orange text-white rounded-full shadow-2xl"
            onClick={toggleMute}
          >
            {isSelfMute ? (
              <MicOff className="w-6 h-6 fill-current" />
            ) : (
              <Mic className="w-6 h-6 fill-current" />
            )}
          </button>
          <button
            className="text-orange font-semibold hover:underline text-xs mb-2 fadeInUp animation-delay-1000"
            onClick={close}
          >
            No, thanks!
          </button>
        </div>
      </section>
    </Portal>
  );
};

export default UnmuteRequestModal;
