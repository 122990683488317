import React, { useContext, useMemo, useState, useRef, useEffect } from "react";

import RTCContext from "../../../contexts/rtcV2";

const RTCVolumeButton = () => {
  const { volume, setVolume, isActive } = useContext(RTCContext);
  const [volumeBarVisible, setVolumeBarVisibility] = useState(false);

  const rangeRef = useRef();

  const handleVolumeChange = (e) => {
    setVolume(+e.target.value);
  };

  useEffect(() => {
    if (volumeBarVisible && rangeRef && rangeRef.current) {
      rangeRef.current.focus();
    }
  }, [volumeBarVisible]);

  return useMemo(
    () =>
      isActive ? (
        <button
          className="relative focus:outline-none flex-col items-center group rounded-full p-2 w-content h-content mr-2 flex"
          onClick={setVolumeBarVisibility.bind(null, (v) => !v)}
        >
          {volume === 0 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              className="fill-current w-5 h-5 text-gray-700"
            >
              <path d="M3.63 3.63c-.39.39-.39 1.02 0 1.41L7.29 8.7 7 9H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h3l3.29 3.29c.63.63 1.71.18 1.71-.71v-4.17l4.18 4.18c-.49.37-1.02.68-1.6.91-.36.15-.58.53-.58.92 0 .72.73 1.18 1.39.91.8-.33 1.55-.77 2.22-1.31l1.34 1.34c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L5.05 3.63c-.39-.39-1.02-.39-1.42 0zM19 12c0 .82-.15 1.61-.41 2.34l1.53 1.53c.56-1.17.88-2.48.88-3.87 0-3.83-2.4-7.11-5.78-8.4-.59-.23-1.22.23-1.22.86v.19c0 .38.25.71.61.85C17.18 6.54 19 9.06 19 12zm-8.71-6.29l-.17.17L12 7.76V6.41c0-.89-1.08-1.33-1.71-.7zM16.5 12c0-1.77-1.02-3.29-2.5-4.03v1.79l2.48 2.48c.01-.08.02-.16.02-.24z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              className="fill-current w-5 h-5 text-white"
            >
              <path d="M3 10v4c0 .55.45 1 1 1h3l3.29 3.29c.63.63 1.71.18 1.71-.71V6.41c0-.89-1.08-1.34-1.71-.71L7 9H4c-.55 0-1 .45-1 1zm13.5 2c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 4.45v.2c0 .38.25.71.6.85C17.18 6.53 19 9.06 19 12s-1.82 5.47-4.4 6.5c-.36.14-.6.47-.6.85v.2c0 .63.63 1.07 1.21.85C18.6 19.11 21 15.84 21 12s-2.4-7.11-5.79-8.4c-.58-.23-1.21.22-1.21.85z" />
            </svg>
          )}
          <span className="absolute top-full z-60 p-1 mt-1 rounded-lg shadow-md leading-none bg-gray-700 text-white text-xs whitespace-no-wrap opacity-0 pointer-events-none group-hover:opacity-100">
            Change Room audio volume
          </span>
          {volumeBarVisible && (
            <div
              className="absolute top-full z-60 w-56 -mt-1 h-content p-2 pb-1 rounded-full bg-gray-100 shadow-md"
              onBlur={setVolumeBarVisibility.bind(null, false)}
            >
              <input
                type="range"
                ref={rangeRef}
                min={0}
                max={1}
                step={0.1}
                className="w-full bg-orange"
                value={volume}
                onChange={handleVolumeChange}
              />
            </div>
          )}
        </button>
      ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isActive, volume, volumeBarVisible]
  );
};

export default RTCVolumeButton;
