import React from "react";
import PropTypes from "prop-types";

import "./index.css";
import { useSeed } from "../../../contexts/seed";

const Widget = ({
  className,
  rowSpan,
  colSpan,
  children,
  onClick,
  locked,
  style,
  shadow,
  scroll,
}) => {
  const { style: seedStyle } = useSeed();

  return (
    <section
      className={`zo-widget relative overflow-hidden portrait:mb-6 w-full col-span-${colSpan} row-span-${rowSpan} rounded-lg ${
        shadow === false ? "" : "shadow-md"
      } ${className ? className : ""} ${
        onClick ? "cursor-pointer hover:shadow-xl" : ""
      }`}
      onClick={onClick}
      style={style}
    >
      {locked && (
        <div className="absolute top-0 left-0 w-full h-full z-10 bg-blur flex items-center justify-center font-semibold">
          <span
            className={`bg-${
              seedStyle.accentColor || "orange"
            } text-white p-2 rounded-md shadow-md`}
          >
            {locked}
          </span>
        </div>
      )}
      {children}
    </section>
  );
};

Widget.propTypes = {
  className: PropTypes.string,
  rowSpan: PropTypes.number.isRequired,
  colSpan: PropTypes.number.isRequired,
  children: PropTypes.any,
  onClick: PropTypes.func,
  locked: PropTypes.any,
};

export default Widget;
