import React, { ReactNode } from "react";
import { BottomSheet as BS, BottomSheetRef } from "react-spring-bottom-sheet";
import { snapPoints, SpringEvent } from "react-spring-bottom-sheet/dist/types";
import { X } from "../../../assets/icons";
import IconButton from "../IconButton";
import Flex from "./../../structure/Flex";

interface BottomSheetProps {
  open: boolean;
  children: any;
  title?: string | ReactNode;
  onDismiss: () => void;
  snapPoints?: snapPoints;
  rightOptions?: ReactNode;
  onSpringStart?: (event: SpringEvent) => void;
  onSpringEnd?: (event: SpringEvent) => void;
}

const BottomSheet = React.forwardRef<BottomSheetRef, BottomSheetProps>(
  (
    {
      children,
      open,
      snapPoints,
      onDismiss,
      title,
      rightOptions,
      onSpringStart,
      onSpringEnd,
    },
    ref
  ) => {
    return (
      <BS
        ref={ref}
        onDismiss={onDismiss}
        open={open}
        snapPoints={snapPoints}
        blocking={window.innerWidth < 768}
        onSpringStart={onSpringStart}
        onSpringEnd={onSpringEnd}
        header={
          <Flex items="center" className="w-full" justify="between">
            <IconButton onClick={onDismiss}>
              <X className="w-5 h-5" />
            </IconButton>
            {title != null ? (
              typeof title === "string" ? (
                <span className="font-bold text-sm">{title}</span>
              ) : (
                title
              )
            ) : null}
            {rightOptions != null ? rightOptions : <div className="w-7 h-7" />}
          </Flex>
        }
      >
        {children}
      </BS>
    );
  }
);

export default BottomSheet;
