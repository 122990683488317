import React from "react";
import SubSection from "../../components/SubSection";
import Section from "../../components/Section";
import SocialField from "./components/SocialField";

const SocialSettings = () => {
  return (
    <Section title="Social Links">
      <SubSection title="Connected Accounts">
        <SocialField
          title="Facebook"
          placeholder="Link your Facebook for a better experience."
          inputPlaceholder="Your FB Profile link"
          hint="Enter your complete Facebook profile link eg. https://www.facebook.com/some-username"
          validator={
            /(?:https?:\/\/)?(?:www\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w-]*\/)*([\w\-.]*)/
          }
          link={1}
        />
        <SocialField
          title="Twitter"
          placeholder="Tweeting obsessively? Let the people here know."
          hint="Enter only your twitter handle, no complete link. For eg. zoworld"
          inputPlaceholder="Enter twitter handle"
          validator={/^(?!@)(\w){1,15}$/}
          link={2}
        />
        <SocialField
          title="Instagram"
          placeholder="Flaunt your ‘gram to the citizens!"
          hint="Enter only your instagram handle, no complete link. For eg. thezoworld"
          inputPlaceholder="Instagram handle"
          validator={/^(?!@)(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/}
          link={3}
        />
        <SocialField
          title="Youtube"
          placeholder="Vlog much? Show everyone."
          inputPlaceholder="Your youtube channel link"
          hint="Enter your complete youtube channel link eg. https://www.youtube.com/channel/123123"
          validator={
            /(https?:\/\/)?(www\.)?youtube\.com\/(channel|user)\/[\w-]+/
          }
          link={5}
        />
        <SocialField
          title="Web Link"
          placeholder="Have any blog, website or web link to showcase? Here you go!"
          inputPlaceholder="Blog/Website/Web link"
          validator={
            /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
          }
          link={4}
        />
      </SubSection>
      <SubSection title="Payment Information">
        <SocialField
          title="UPI Id"
          placeholder="Add your UPI Id to let people support your art."
          inputPlaceholder="UPI Id"
          link={6}
          validator={/^[\w.-]+@[\w.-]+$/}
        />
      </SubSection>
    </Section>
  );
};

export default SocialSettings;
