import React from "react";
import Portal from "../../../ui/Portal";

const DeviceNotSupported = () => {
  return (
    <Portal>
      <section className="fixed top-0 left-0 z-100 bg-blur zo-bg-black-transparent w-screen h-screen flex flex-col items-center justify-center">
        <div className="w-120 portrait:w-full text-center h-content relative border-orange border-t-4 px-8 py-16 bg-white rounded-lg flex-col flex items-center shadow-xl">
          <div className="text-xl text-orange font-semibold">Oh no!</div>
          <span className="text-lg mt-2 text-gray-800 font-medium block text-center">
            Sorry but as of now your device/browser doesn’t support all features
            to access Zo World.
            <br />
            Chrome on laptop seems to work best!
          </span>
        </div>
      </section>
    </Portal>
  );
};

export default DeviceNotSupported;
