import React, { useEffect } from "react";

import detectiveTrue from "./../../../../assets/detective_true.svg";
import detectiveFalse from "./../../../../assets/detective_false.svg";
import Modal from "../../../../../../ui/Modal";

const NighResultModal = ({ visible, close, player }) => {
  useEffect(() => {
    let mounted = true;
    if (visible) {
      setTimeout(() => {
        if (mounted) {
          close();
        }
      }, 5000);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal visible={visible} close={close} disableCloseOutside>
      {player && player.role && player.role.includes("mafia") ? (
        <div
          className="bg-gray-200 relative z-40 rounded-lg overflow-hidden shadow-lg mb-10 bg-white bg-no-repeat bg-cover bg-center flex flex-col items-center justify-between"
          style={{
            width: "38.8rem",
            height: "35.2rem",
            backgroundImage: `url(${detectiveTrue})`,
          }}
        >
          <div className="bg-mafia-primary mt-4 w-3/4 border-t-2 border-mafia-secondary p-4 rounded-lg shadow-lg flex flex-col items-center">
            <div className="font-bold uppercase tracking-normal text-mafia-secondary text-2xl">
              Detective Result
            </div>
          </div>
          <div className="bg-mafia-primary mb-4 w-3/4 border-t-2 border-mafia-secondary p-4 rounded-lg shadow-lg flex flex-col items-center">
            <div className="text-white text-lg text-center">
              <span className="text-mafia-secondary font-bold text-3xl">
                {player.nickname} is Mafia!
              </span>
              <br />
              Suspicion is in the Eye of a detective and you’ve used yours
              pretty well.
            </div>
          </div>
        </div>
      ) : (
        <div
          className="bg-gray-200 relative z-40 rounded-lg overflow-hidden shadow-lg mb-10 bg-white bg-no-repeat bg-cover bg-center flex flex-col items-center justify-between"
          style={{
            width: "38.8rem",
            height: "35.2rem",
            backgroundImage: `url(${detectiveFalse})`,
          }}
        >
          <div className="bg-mafia-primary mt-4 w-3/4 border-t-2 border-mafia-secondary p-4 rounded-lg shadow-lg flex flex-col items-center">
            <div className="font-bold uppercase tracking-normal text-mafia-secondary text-2xl">
              Detective Result
            </div>
          </div>
          <div className="bg-mafia-primary mb-4 w-3/4 border-t-2 border-mafia-secondary p-4 rounded-lg shadow-lg flex flex-col items-center">
            <div className="text-white text-lg text-center">
              <span className="text-mafia-secondary font-bold text-3xl">
                {player.nickname} is not a Mafia!
              </span>
              <br />
              One reckless decision and you go down the rabbit hole.
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default NighResultModal;
