import React, { useState, useContext, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";

import Modal from "./../../../../ui/Modal";
import passport from "./../../assets/passport.jpg";
import ProfileContext from "../../../../contexts/profile";
import useApi from "../../../../hooks/useApi";

const VisaModal = ({ close, email }) => {
  const api = useApi("payments", true);
  const { profile } = useContext(ProfileContext);

  const [selected, setSelected] = useState(null);
  const [paymentData, setPaymentData] = useState(null);

  useEffect(() => {
    if (api) {
      api
        .get("/subscription/api/v1/plans/")
        .then((res) => {
          setPaymentData(res.data);
          console.log(res.data);
          setSelected(res.data.plans[0].id);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [api]);

  const handleBuy = async () => {
    if (!profile.email) {
      email();
    }
    if (selected != null) {
      const data = {
        name: `${profile.first_name}${
          profile.middle_name && " " + profile.middle_name
        }${profile.last_name && " " + profile.last_name}`,
        email: profile.email || "",
        mobile: profile.mobile,
        plan_id: selected,
      };
      console.log(data);
      try {
        const response = await api.post(
          "/subscription/api/v1/process/",
          JSON.stringify(data)
        );
        if (response.status === 200) {
          if (response.data && response.data.order) {
            try {
              const stripe = await loadStripe(paymentData.stripe_key);
              const stripeResponse = await stripe.redirectToCheckout({
                sessionId: response.data.order.ref_id,
              });
              if (stripeResponse.error) {
                console.log(stripeResponse.error);
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Modal visible close={close}>
      <div className="flex rounded-lg overflow-hidden">
        <div className="w-108 min-h-108 bg-white portrait:hidden">
          <img src={passport} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="w-108 portrait:w-full portrait:border-t-4 portrait:border-orange portrait:p-4 bg-white px-2 pt-4 pb-6 flex flex-col items-center">
          <div className="font-bold text-3xl text-orange">Visa Benefits</div>
          <ul className="list-disc w-full pl-6 pr-4 mt-2">
            {paymentData &&
              paymentData.benefits.map((benefit) => (
                <li key={benefit}>{benefit}</li>
              ))}
          </ul>
          <div className="font-semibold text-xl my-4 text-center">
            Select your plan
          </div>
          <div className="flex w-full">
            {paymentData &&
              paymentData.plans.map((plan) => (
                <div
                  key={plan.id}
                  className={`px-2 pt-4 pb-10 relative overflow-hidden flex flex-col items-center justify-center w-1/3 text-center mr-2 border rounded-md cursor-pointer ${
                    selected === plan.id ? "shadow-lg bg-orange" : "bg-white"
                  }`}
                  onClick={setSelected.bind(null, plan.id)}
                >
                  <div
                    className={`text-sm font-semibold mb-2 ${
                      selected === plan.id ? "text-white" : "text-orange"
                    }`}
                  >
                    {plan.name}
                  </div>
                  <div className={selected === plan.id ? "text-white" : ""}>
                    <span className="text-lg font-bold">₹{plan.price}</span>/{" "}
                    {plan.short_text}
                  </div>
                  <div
                    className={`w-full p-1 text-xs font-medium bottom-0 left-0 text-center absolute ${
                      selected === plan.id
                        ? " bg-white text-orange"
                        : " bg-orange text-white"
                    }`}
                  >
                    {plan.discount_text}
                  </div>
                </div>
              ))}
          </div>
          <button
            className="bg-orange text-white mt-6 font-semibold px-6 py-4 portrait:text-sm portrait:px-2 rounded-lg shadow-lg leading-none hover:shadow-xl"
            onClick={handleBuy}
          >
            Buy{" "}
            {paymentData &&
              selected != null &&
              paymentData.plans.find((p) => p.id === selected).name}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default VisaModal;
