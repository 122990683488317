import { any } from "prop-types";
import React, { useEffect, useMemo } from "react";
import AudioBridge from "./components/AudioBridge";
import convertToNumber from "./utils/covertToNumber";

const Janus = React.memo(
  ({
    userPassport,
    roomCode,
    displayName,
    peers,
    setPeers,
    selfMuted,
    record,
    inputDeviceId,
    setActive,
    volume,
    setSelfMuted,
    socket,
    message,
    sendMessage,
  }) => {
    useEffect(() => {
      console.log(`[RTC_ROOM] User ${userPassport} for Room ${roomCode}`);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomCode, userPassport]);

    useEffect(() => {
      console.log(`[RTC_ROOM] Mute ${selfMuted}`);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selfMuted]);

    useEffect(() => {
      if (!isNaN(volume)) {
        const audio = document.querySelector("#RTC_ROOM");
        if (audio) {
          audio.volume = volume;
        }
      }
    }, [volume]);

    return useMemo(
      () => (
        <AudioBridge
          userId={convertToNumber(userPassport)}
          roomId={convertToNumber(roomCode)}
          fileName={roomCode}
          displayId={displayName || userPassport}
          peers={peers}
          setSelfMuted={setSelfMuted}
          inputDeviceId={inputDeviceId}
          setPeers={setPeers}
          selfMuted={selfMuted}
          setActive={setActive}
          record={record}
          socket={socket}
          message={message}
          sendMessage={sendMessage}
        />
      ),
      [
        userPassport,
        roomCode,
        displayName,
        peers,
        setSelfMuted,
        inputDeviceId,
        setPeers,
        selfMuted,
        setActive,
        record,
        socket,
        message,
        sendMessage,
      ]
    );
  }
);

Janus.propTypes = {
  instanceName: any,
  userPassport: any,
  roomCode: any,
  displayName: any,
  peers: any,
  setPeers: any,
  selfMuted: any,
  record: any,
  setActive: any,
  volume: any,
};

export default Janus;
