import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={24}
      viewBox="0 0 24 24"
      width={24}
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M18.5 14.5c0 .28-.22.5-.5.5-1.51 0-2.77 1.12-2.97 2.58-.03.24-.25.42-.49.42-.3 0-.54-.27-.5-.57a4.03 4.03 0 013.46-3.4V3.25a1.25 1.25 0 00-2.5 0v7.25c0 .28-.22.5-.5.5s-.5-.22-.5-.5V1.25a1.25 1.25 0 00-2.5 0v9.25c0 .28-.22.5-.5.5s-.5-.22-.5-.5V2.75a1.25 1.25 0 00-2.5 0v8.75c0 .28-.22.5-.5.5s-.5-.22-.5-.5V5.75a1.25 1.25 0 00-2.5 0v10c0 4.56 3.69 8.25 8.25 8.25S21 20.31 21 15.75v-6.5a1.25 1.25 0 00-2.5 0v5.25z" />
    </svg>
  );
}

export default SvgComponent;
