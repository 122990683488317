import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useProfile } from "../../../contexts";
import Flex from "../../structure/Flex";
import Avatar from "../Avatar";

interface UserNavProps {}

const UserNav: React.FC<UserNavProps> = () => {
  const { profile } = useProfile();
  const history = useHistory();

  const [expanded, setExpanded] = useState<boolean>(false);

  const openQuick = (link: string) => {
    history.push(link);
    setExpanded(false);
  };

  return (
    <>
      <div className="ml-2 relative cursor-pointer">
        <Avatar
          className="w-10 h-10 rounded-full bg-white"
          svg={profile?.avatar_url || null}
          onClick={setExpanded.bind(null, true)}
        />
        <span className="absolute top-0 left-0 bg-red-100 rounded-lg -mt-3 -ml-3 text-orange font-bold uppercase tracking-wide p-1 text-xs">
          BETA
        </span>
      </div>
      {expanded && (
        <>
          <div
            className="fixed inset-0 z-30 bg-white-translucent-50"
            onClick={setExpanded.bind(null, false)}
          />
          <Flex
            col
            className="absolute right-0 top-0 m-2 z-30 rounded-lg bg-orange shadow-zo-sm text-gray-800"
          >
            <Flex items="center" className="p-4 text-white">
              <span className="font-bold">{profile?.nickname}</span>
              <Avatar
                className="w-10 h-10 ml-12 rounded-full bg-white"
                svg={profile?.avatar_url || null}
                onClick={setExpanded.bind(null, true)}
              />
            </Flex>
            <Flex col items="start" className="p-2 bg-white">
              <button
                className="focus:outline-none hover:bg-gray-300 w-full text-left font-semibold text-sm p-2 rounded-lg"
                onClick={openQuick.bind(null, "#zobu")}
              >
                Edit my Avatar
              </button>

              <button
                className="focus:outline-none hover:bg-gray-300 w-full text-left font-semibold text-sm p-2 rounded-lg"
                onClick={openQuick.bind(null, "#settings")}
              >
                Settings
              </button>
            </Flex>
            <Flex items="center" className="p-4 bg-zo-off-white rounded-b-lg">
              <button className="focus:outline-none text-xs font-semibold">
                Report User
              </button>
              <span className="mx-1">◦</span>
              <button className="focus:outline-none text-xs font-semibold">
                Privacy
              </button>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

export default UserNav;
