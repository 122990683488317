import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height={40}
      width={40}
      viewBox="0 0 40 40"
      className="prefix__loader"
      {...props}
    >
      <circle className="loader-dot" cx={10} cy={20} r={3} />
      <circle className="loader-dot" cx={20} cy={20} r={3} />
      <circle className="loader-dot" cx={30} cy={20} r={3} />
    </svg>
  );
}

export default SvgComponent;
