import React, { useCallback, useEffect, useRef, useState } from "react";
import { useProfile } from "../contexts";

export type SocketMessage = {
  action: string;
  payload: GeneralObject;
  action_user?: any;
};

const useSocket: <T extends {}>(
  name: string
) => {
  setSocketUrl: React.Dispatch<string | null>;
  socket: WebSocket | null;
  connecting: boolean | null;
  sendSocketMessage: React.Dispatch<SocketMessage>;
  message: SocketMessage | null;
  members: T[];
  setMembers: React.Dispatch<React.SetStateAction<T[]>>;
} = (name) => {
  const { profile } = useProfile();
  const [socketUrl, setSocketUrl] = useState<string | null>(null);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [timeoutSeconds, setTimeoutSeconds] = useState<number>(0);
  const [connecting, setConnecting] = useState<boolean | null>(null);
  const [message, setMessage] = useState<SocketMessage | null>(null);
  const [members, setMembers] = useState<any[]>([]);

  const messageBuffer = useRef<SocketMessage[]>([]);

  const handleMessage = (_message: SocketMessage) => {
    if (_message) {
      switch (_message.action) {
        case "joined":
          setMembers((_members) => {
            if (
              _members.findIndex(
                (_member) => _member.code === _message.payload.code
              ) === -1
            ) {
              const member = _message.payload;
              return [..._members, member];
            } else {
              return _members;
            }
          });
          break;
        case "left":
          setMembers((_members) =>
            _members.filter((_member) => _member.code !== _message.payload.code)
          );
          break;

        default:
          break;
      }
    }
    setMessage(_message);
  };

  useEffect(() => {
    let mounted = true;
    if (socketUrl && socket === null) {
      console.log(`[${name}] Attempting Connection in`, timeoutSeconds);
      setTimeout(() => {
        console.log(`[${name}] Creating Connection...`);
        setConnecting(true);
        const _socket = new WebSocket(
          process.env.REACT_APP_SOCKET_URL + socketUrl
        );
        _socket.onmessage = function (e) {
          const data = JSON.parse(e.data);
          if (Array.isArray(data)) {
            data.forEach((d, i) => {
              setTimeout(() => {
                handleMessage(d);
              }, i * 500);
              console.log(`[${name}] Message received.`, d);
            });
          } else {
            handleMessage(data);
            console.log(`[${name}] Message received.`, data);
          }
        };
        _socket.onclose = (e) => {
          console.log(`[${name}] Socket closed`);
          mounted && setSocket(null);
        };
        _socket.onerror = function (e) {
          console.log(`[${name}] Socket error`, e);
          setSocket(null);
        };
        _socket.onopen = (e) => {
          setConnecting(false);
          setTimeoutSeconds(0);
          console.log(`[${name}] Connection established.`);
          if (messageBuffer.current.length) {
            messageBuffer.current.forEach((m) => {
              _socket.send(JSON.stringify(m));
            });
            messageBuffer.current = [];
          }
        };
        setSocket(_socket);
      }, timeoutSeconds);
      setTimeoutSeconds((t) => Math.min(10000, t ? t * 2 : 2000));
    }
    return () => {
      mounted = false;
      if (socket) {
        console.log("socketclose");
        socket.close();
        setMessage(null);
        setSocket(null);
        setConnecting(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketUrl, socket]);

  const sendSocketMessage = useCallback(
    (message: SocketMessage) => {
      const _message = { ...message, sender: profile?.code };
      if (socket && profile) {
        socket.send(JSON.stringify(_message));
      } else {
        console.log(`[${name}] Message not sent`, message, socket);
        messageBuffer.current.push(_message);
      }
    },
    [name, profile, socket]
  );

  return {
    setSocketUrl,
    socket,
    socketUrl,
    connecting,
    sendSocketMessage,
    message,
    members,
    setMembers,
  };
};

export default useSocket;
