import React, { useMemo } from "react";
import Flex from "../../../../components/structure/Flex";
import UserNav from "../../../../components/ui/UserNav";
import { useRoom } from "../../../../contexts";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { room, citizens } = useRoom();

  const name = useMemo(() => room?.lobby_name, [room]);
  const security = useMemo(
    () => (room?.security === 3 ? "Private" : "Public"),
    [room]
  );
  const nickname = useMemo(() => room?.nickname, [room]);
  const citizensCount = useMemo(() => citizens.length, [citizens]);

  return useMemo(
    () => (
      <header className="w-full md:w-108 flex items-center justify-between flex-shrink-0 mb-4">
        <Flex
          col
          className="p-2 pl-3 rounded-lg shadow-zo-sm bg-white flex-1 mr-2"
        >
          <h1 className="font-bold truncate">{name}</h1>
          <span className="font-bold text-zo-mid-grey text-xs">
            {nickname} · {citizensCount} Zobu
            {citizensCount > 1 && "s"} Live · {security}
          </span>
        </Flex>
        <UserNav />
      </header>
    ),
    [citizensCount, name, nickname, security]
  );
};

export default Header;
