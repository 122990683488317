import React, { useState } from "react";

import useInput from "../../../../../hooks/useInput";
import useResponseFlash from "../../../../../hooks/useResponseFlash";

const NameRow = ({ profile, updateProfile }) => {
  const [editMode, setEditMode] = useState(false);
  const { response, setResponse } = useResponseFlash();

  const { value: firstName, bind: bindFirstName } = useInput(
    profile.first_name
  );
  const { value: middleName, bind: bindMiddleName } = useInput(
    profile.middle_name
  );
  const { value: lastName, bind: bindLastName } = useInput(profile.last_name);

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateProfile({
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
    })
      .then((e) => {
        setEditMode(false);
      })
      .catch((e) => {
        setResponse("Error in updating");
      });
  };

  return (
    <div className="flex items-start w-full">
      <div className="w-1/4 mr-4 pt-3 text-sm">Name</div>
      <div className="w-3/4">
        {editMode ? (
          <form
            className="flex items-center flex-col bg-gray-300 p-2 rounded-lg"
            onSubmit={handleSubmit}
          >
            <div className="flex items-center justify-between w-full">
              <input
                className="form-input w-1/3 mr-1 p-1"
                type="text"
                placeholder="First Name"
                {...bindFirstName}
              />
              <input
                className="form-input w-1/3 mr-1 p-1"
                type="text"
                placeholder="Middle Name"
                {...bindMiddleName}
              />
              <input
                className="form-input w-1/3 p-1"
                type="text"
                placeholder="Last Name"
                {...bindLastName}
              />
            </div>
            <div className="h-4 flex items-center justify-center font-semibold text-xs text-orange">
              {response}
            </div>
            <div className="flex items-center justify-around w-full">
              <button
                type="submit"
                className="bg-orange font-medium py-2 text-sm leading-none px-4 rounded-lg shadow-md text-white"
              >
                Update
              </button>
              <button
                type="button"
                onClick={setEditMode.bind(null, false)}
                className="bg-gray-100 font-medium py-2 text-sm leading-none px-4 rounded-lg shadow-md text-gray-800"
              >
                Cancel
              </button>
            </div>
          </form>
        ) : (
          <div className="w-full flex items-center justify-between pt-2">
            <span className="font-medium">
              {firstName}
              {middleName ? ` ${middleName}` : ""}
              {lastName ? ` ${lastName}` : ""}
            </span>
            <button
              className="text-orange text-sm font-semibold"
              onClick={setEditMode.bind(null, true)}
            >
              Edit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NameRow;
