import React, { useEffect, useState } from "react";

import "./index.css";
import Avatar from "../Avatar";
import useApi from "../../hooks/useApi";

const Article = ({
  className,
  coverImage,
  title,
  children,
  author,
  authorPassport,
  date,
  graphic_left,
  graphic_right,
  background,
}) => {
  const api = useApi("profile", false);

  const [authorData, setAuthorData] = useState(null);

  useEffect(() => {
    if (authorPassport && api) {
      api
        .get("/profile/api/v1/profile/lobby/" + authorPassport + "/")
        .then((response) => {
          console.log(response);
          setAuthorData(response.data.profile);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [authorPassport, api]);

  return (
    <article
      className={`w-full p-4 portrait:p-0 ${className ? className : ""}`}
    >
      <div
        className="relative w-full rounded-lg overflow-hidden flex flex-col text-white items-center justify-center h-108 bg-cover"
        style={{
          backgroundImage: `url(${coverImage})`,
          backgroundColor: "rgba(0,0,0,.2)",
        }}
      >
        <div className="absolute inset-0 z-0 zo-bg-black-transparent w-full h-full" />
        <div className="relative z-10 w-full flex flex-col text-white items-center justify-center">
          <div className="">{date}</div>
          <div className="my-8 text-5xl font-bold px-4 text-center portrait:text-xl">
            {title}
          </div>
          <div
            className="flex items-center cursor-pointer"
            onClick={
              authorData &&
              authorData.subdomain &&
              window.open.bind(
                null,
                `https://${authorData.subdomain}.${process.env.REACT_APP_ROOT_DOMAIN}`,
                "_parent"
              )
            }
          >
            {authorData && (
              <Avatar
                svg={authorData.avatar_url}
                className="bg-white w-8 h-8 mr-2"
              />
            )}
            <span className="font-medium">{author}</span>
          </div>
        </div>
      </div>
      <div
        className={`bg-white flex flex-col items-start relative bg-repeat`}
        style={{
          backgroundImage: `url(${background})`,
        }}
      >
        <div
          className="w-20 absolute float-left bg-repeat-y h-full stretch bg-repeat"
          style={{ backgroundImage: `url(${graphic_left})` }}
        />
        <div className="-mt-12 relative z-10 article-content leading-relaxed rounded-lg py-6 px-4 portrait:p-4 portrait:mt-0 portrait:text-base portrait:w-full text-lg text-justify w-3/5 mx-auto bg-white">
          {children}
        </div>
        <div
          className="w-20 absolute float-right top-0 right-0 bg-repeat-y h-full stretch bg-repeat"
          alt="left"
          style={{ backgroundImage: `url(${graphic_right})` }}
        />
      </div>
    </article>
  );
};

export default Article;
