import React, { useMemo } from "react";
import { Route, Switch } from "react-router";
import MafiaRedirect from "../../components/commons/MafiaRedirect";
import { RoomProvider } from "./contexts/room";
import Mafia from "./games/Mafia";
import Room from "./screens/Room";
import Settings from "./screens/Settings";
import TravelPortal from "./screens/TravelPortal";
import ZobuEditor from "./screens/ZobuEditor";

interface RoutesProps {}

const Routes: React.FC<RoutesProps> = () => {
  return useMemo(
    () => (
      <>
        <Switch>
          <Route path="/settings">
            <Settings forceVisible={true} />
          </Route>
          <Route path="/zobu/editor">
            <ZobuEditor forceVisible={true} />
          </Route>
          <Route path="/@mafia/r/:id" component={Mafia} />
          <Route path="/@mafia/room/:id" component={Mafia} />
          <Route path="/@mafia/season/finals" exact component={MafiaRedirect} />
          <Route path="/@:subdomain">
            <RoomProvider>
              <Room />
            </RoomProvider>
          </Route>
          <Route path="/@portal" component={TravelPortal} />
        </Switch>
        <Settings forceVisible={false} />
        <ZobuEditor forceVisible={false} />
      </>
    ),
    []
  );
};

export default Routes;
