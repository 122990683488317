import React, { useContext, useEffect, useRef, useState } from "react";
import Datetime from "react-datetime";
import moment from "moment";

import ProfileContext from "../../../../contexts/profile";
import useApi from "../../../../hooks/useApi";
import { useInput, useResponseFlash } from "../../../Zo/components/hooks";

const HostEventModal = ({ close, data }) => {
  const { profile } = useContext(ProfileContext);
  const api = useApi("zo world", true);
  const [loading, setLoading] = useState(false);
  const { value: name, bind: bindName, setValue: setName } = useInput("");
  const [startTime, setStartTime] = useState();
  const {
    value: description,
    bind: bindDescription,
    setValue: setDescription,
  } = useInput("");
  const { response, setResponse } = useResponseFlash();

  const yesterday = useRef(moment().subtract(1, "day"));

  useEffect(() => {
    if (data) {
      setName(data.name);
      setDescription(data.description);
      setStartTime(moment(data.start_time));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSave = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (!name.trim().length || !description.trim().length || !startTime) {
        setResponse("Kindly fill all the fields");
        return;
      }
      setLoading(true);
      try {
        if (data != null && data.id != null) {
          const updatedData = {
            name: name.trim(),
            start_time: startTime.toISOString(),
            description: description.trim(),
            url: data.url,
          };
          const response = await api.patch(
            `/zoworld/api/v1/room/event/${data.id}/`,
            JSON.stringify(updatedData)
          );
          console.log(response);
          if (response.status === 201) {
            setLoading(false);
            setResponse("Event updated. ");
            close();
          } else {
            setResponse("Error in submitting event. Try again later.");
            setLoading(false);
          }
        } else {
          const data = {
            name: name.trim(),
            start_time: startTime.toISOString(),
            description: description.trim(),
            url:
              profile.status === "premium"
                ? `https://${profile.subdomain}.${process.env.REACT_APP_ROOT_DOMAIN}/`
                : `https://${process.env.REACT_APP_ROOT_DOMAIN}/`,
            host: profile.code,
          };
          const response = await api.post(
            `/zoworld/api/v1/room/event/`,
            JSON.stringify(data)
          );
          console.log(response);
          if (response.status === 201) {
            setLoading(false);
            setResponse("Event submitted for approval. ");
            close();
          } else {
            setResponse("Error in submitting event. Try again later.");
            setLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
        console.log(error.response);
        setResponse("Error in submitting event. Try again later.");
        setLoading(false);
      }
    }
  };

  return (
    <section className="w-full h-full fixed top-0 left-0 z-70 flex items-center justify-center">
      <div className="border-orange border-t-4 bg-white fadeInUp rounded-lg shadow-2xl h-content w-content portrait:w-full">
        <form
          className="w-144 flex p-6 pb-0 flex-col items-center portrait:w-full portrait:h-content relative"
          onSubmit={handleSave}
        >
          <button className="absolute top-0 right-0 m-4" onClick={close}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
            </svg>
          </button>
          <header className="font-black text-3xl fadeInUp animation-delay-100 text-center">
            Host a <span className="text-orange">Zo World</span> Event!
          </header>
          <section className="mb-4 flex-col flex items-center">
            <div className="font-medium text text-center w-5/6 mt-4 mb-6 fadeInUp animation-delay-300">
              So, tell us{" "}
              <span className="text-orange">
                {profile && profile.first_name}
              </span>
              ! What event would you like to host on <strong>Zo World</strong>?
            </div>
            <input
              type="text"
              placeholder="Name of the event"
              {...bindName}
              className="form-input w-96 fadeInUp animation-delay-500"
            />
            {/* <input
              type="text"
              placeholder="Event start time"
              {...bindStartTime}
              className="form-input w-96 fadeInUp animation-delay-500 mt-2"
            /> */}
            <Datetime
              value={startTime}
              onChange={(e) => {
                setStartTime(e);
              }}
              inputProps={{
                className: "form-input w-96 fadeInUp animation-delay-500 mt-2",
                placeholder: "Event start time",
              }}
              timeConstraints={{
                minutes: {
                  step: 30,
                },
              }}
              isValidDate={(currentDate) =>
                moment(currentDate).isAfter(yesterday.current, "day")
              }
            />
            <textarea
              type="text"
              placeholder="Brief description of your event"
              {...bindDescription}
              className="form-textarea w-96 fadeInUp animation-delay-500 mt-2 resize-none"
            />
            <div className="h-6 flex items-center justify-center font-semibold text-xs text-orange">
              {response}
            </div>
            <button className="bg-orange px-6 py-3 rounded-lg shadow-md hover:shadow-lg focus:outline-none font-medium text-white fadeInUp animation-delay-750">
              {data != null
                ? loading
                  ? "Updating"
                  : "Update"
                : loading
                ? "Submitting"
                : "Submit"}
            </button>
          </section>
          <footer></footer>
        </form>
      </div>
    </section>
  );
};

export default HostEventModal;
