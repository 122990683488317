import Lottie from "lottie-react";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import zobuWelcome from "../../assets/lottie/zobu-welcome.json";
import Flex from "../../components/structure/Flex";
import Input from "../../components/ui/FormElement/components/Input";
import { useAuth } from "../../contexts";
import useResponseFlash from "../../hooks/useResponseFlash";
import useUnauthenticatedApi from "../../hooks/useUnauthenticatedApi";
interface AuthProps {}

const Auth: React.FC<AuthProps> = () => {
  const requestOTPApi = useUnauthenticatedApi("AUTH_REQUEST_OTP");
  const activateApi = useUnauthenticatedApi("AUTH_ACTIVATE");
  const { response, setResponse } = useResponseFlash();
  const { login } = useAuth();
  // const [mobile, bindNumber] = useInput<string>(null);
  const [mobile, setMobile] = useState<GeneralObject>({ value: "" });
  const [loading, setLoading] = useState<boolean>(true);
  const [otp, setOtp] = useState<string>("");
  const [otpRequired, setOtpRequired] = useState<boolean>(false);

  const handleNext = async () => {
    const _mobile = mobile.value.slice(mobile.data.dialCode.length);
    const _countryCode = mobile.data.dialCode;
    const data = {
      mobile_country_code: _countryCode,
      mobile: _mobile,
    };
    try {
      const response = await requestOTPApi.post("", JSON.stringify(data));
      console.log(response);
      if (response.status === 200) {
        setOtpRequired(true);
      } else {
        setResponse("Error while sending OTP.");
      }
    } catch (error: any) {
      setResponse("Error while sending the OTP.");
      console.log(error);
      console.log(error.response);
    }
  };

  const handleChange = (
    value: any,
    data: any,
    event: any,
    formattedValue: any
  ) => {
    setMobile({ value, data });
  };

  const handleEnter = async () => {
    const _mobile = mobile.value.slice(mobile.data.dialCode.length);
    const _countryCode = mobile.data.dialCode;
    setLoading(true);
    const data = {
      mobile_country_code: _countryCode,
      mobile: _mobile,
      otp,
    };
    try {
      const res = await activateApi.post("", JSON.stringify(data));
      console.log(res);
      if (res.status === 200) {
        const { token_expiry, user, user_token } = res.data;
        login(user, user_token, token_expiry);
      } else {
        setResponse("Invalid OTP");
      }
    } catch (error: any) {
      setResponse("Error in validating. Try again later.");
      console.log(error);
      console.log(error.response);
    } finally {
      setLoading(false);
    }
    // verifyOTP(
  };

  return (
    <section className="h-full w-full bg-zo-off-white relative flex items-center justify-center max-w-sm mx-auto">
      <Lottie
        animationData={zobuWelcome}
        autoPlay
        loop
        className="absolute fadeInUp animation-delay-300 left-0 right-0 bottom-0 object-contain"
      />
      <Flex col className="relative">
        <header className="font-black text-xl fadeInUp text-center w-full portrait:px-6 leading-8">
          Check in{" "}
          <span className="text-orange portrait:block text-3xl portrait:mb-4 block">
            Zo World Community
          </span>
        </header>
        <section className="w-full mb-8 landscape:mt-4 px-4 flex-col flex items-center">
          <div className="font-semibold mb-8 fadeInUp animation-delay-300 text-center">
            A Digital-First Nation built for socialising and more!
          </div>
          <PhoneInput
            country={"in"}
            value={mobile.value}
            onChange={handleChange}
            isValid={(value, country: GeneralObject) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name;
              } else if (value.match(/1234/)) {
                return false;
              } else {
                return true;
              }
            }}
          />
          {otpRequired && (
            <div className="mt-4">
              <Input
                type="number"
                placeholder="Enter the OTP here"
                value={otp}
                setValue={setOtp}
              />
            </div>
          )}
          <span className="text-center w-full h-8 flex items-center justify-center text-orange font-bold text-sm">
            {response}
          </span>
          <div className="w-full flex items-center flex-col">
            <button
              className="bg-orange px-6 py-3 rounded-lg shadow-md hover:shadow-lg focus:outline-none font-semibold text-white fadeInUp animation-delay-500"
              onClick={otpRequired ? handleEnter : handleNext}
            >
              Check in
            </button>
          </div>
        </section>
      </Flex>
    </section>
  );
};

export default Auth;
