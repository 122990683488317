import React from "react";
import moment from "moment";

import mafiaIcon from "./../../../../assets/mafia/icon-mafia.svg";
import detectiveIcon from "./../../../../assets/mafia/icon-detective.svg";
import healerIcon from "./../../../../assets/mafia/icon-healer.svg";
import villagerIcon from "./../../../../assets/mafia/icon-villager-boy-2.svg";
import { useHistory } from "react-router-dom";

const getStatus = (status, timestamp) => {
  if (status === "not_started") {
    return (
      <span className="cursor-default font-semibold text-gray-400 whitespace-no-wrap">
        Awaiting
      </span>
    );
  } else {
    const diff = moment().diff(moment(timestamp));
    const diffDuration = moment.duration(diff);
    return (
      <span className="cursor-default font-semibold text-secondary whitespace-no-wrap">
        {diffDuration.hours()} : {diffDuration.minutes()}
      </span>
    );
  }
};

const GameCard = ({ cardData }) => {
  const history = useHistory();

  const handleSpectate = async () => {
    history.push("/r/" + cardData.code);
  };

  return (
    <li
      className={`hover:shadow-lg p-4 mb-2 last:mb-0 rounded-lg flex portrait:flex-col group overflow-hidden items-center bg-mafia-background border-t-2 ${
        cardData.status === "not_started"
          ? "border-mafia-background"
          : "border-mafia-secondary"
      }`}
    >
      <div className="w-2/5 portrait:w-full portrait:flex-row flex-col flex items-start truncate font-medium text-gray-400 group-hover:text-gray-100">
        <span className="flex-grow portrait:pr-1 truncate">
          {cardData.data.name}
        </span>
        <div className="mt-1 flex flex-shrink-0 items-center cursor-default">
          <div className="flex item-baseline text-xs mr-3">
            <img
              className="fill-current w-4 h-4 text-gray-600 mr-2 flex-shrink-0"
              src={mafiaIcon}
              alt="M"
            />{" "}
            {cardData.data.mafia_count}
          </div>
          <div className="flex item-baseline text-xs mr-3">
            <img
              className="fill-current w-4 h-4 text-gray-600 mr-2 flex-shrink-0"
              src={detectiveIcon}
              alt="D"
            />{" "}
            {cardData.data.detective_count}
          </div>
          <div className="flex item-baseline text-xs mr-3">
            <img
              className="fill-current w-4 h-4 text-gray-600 mr-2 flex-shrink-0"
              src={healerIcon}
              alt="H"
            />{" "}
            {cardData.data.healer_count}
          </div>
          <div className="flex item-baseline text-xs">
            <img
              className="fill-current w-4 h-4 text-gray-600 mr-2 flex-shrink-0"
              src={villagerIcon}
              alt="V"
            />{" "}
            {cardData.data.villager_count}
          </div>
        </div>
      </div>
      <div className="w-3/5 flex portrait:w-full portrait:mt-2 justify-between items-center">
        <div className="w-1/6">
          {cardData.status === "not_started" ? (
            <span className="mr-4 flex items-center">
              <svg
                className="fill-current text-gray-600 mr-2 flex-shrink-0 w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V18c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05.02.01.03.03.04.04 1.14.83 1.93 1.94 1.93 3.41V18c0 .35-.07.69-.18 1H22c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5z" />
              </svg>
              {cardData.players_joined}/{cardData.data.players_count}
            </span>
          ) : (
            <span className="mr-4 flex items-center whitespace-no-wrap">
              <svg
                className="fill-current text-gray-600 mr-2 flex-shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V18c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05.02.01.03.03.04.04 1.14.83 1.93 1.94 1.93 3.41V18c0 .35-.07.69-.18 1H22c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5z" />
              </svg>
              {cardData.data.mafia_count} vs{" "}
              {cardData.data.players_count - cardData.data.mafia_count}
            </span>
          )}
        </div>
        <div className="w-2/6 flex justify-center">
          {getStatus(cardData.status, cardData.time_create)}
        </div>
        <div className="w-3/6 ml-4 whitespace-no-wrap flex justify-end overflow-hidden transition-all duration-200 ease-in-out">
          <button
            className="text-white bg-mafia-primary border-t border-mafia-secondary p-2 leading-none rounded-lg font-semibold hover:shadow-lg"
            onClick={handleSpectate}
          >
            View Lobby
          </button>
          {/* {cardData.status === "not_started" && (
            <button
              className="px-2 text-mafia-primary bg-mafia-secondary p-2 leading-none rounded-lg hover:shadow-lg font-semibold ml-4"
              onClick={handleJoin}
            >
              Join
            </button>
          )} */}
        </div>
      </div>
    </li>
  );
};

export default GameCard;
