import React from "react";
import {
  setDisplayName,
  shallowEqual,
  shouldUpdate,
  wrapDisplayName,
} from "./utils";

const pure: <T extends {}>(BaseComponent: React.FC<T>) => React.FC<T> = (
  BaseComponent
) => {
  const hoc = shouldUpdate(
    (props: any, nextProps: any) => !shallowEqual(props, nextProps)
  );

  if (process.env.NODE_ENV !== "production") {
    return setDisplayName(wrapDisplayName(BaseComponent, "pure"))(
      hoc(BaseComponent)
    );
  }

  return hoc(BaseComponent);
};

export default pure;
