import { createContext } from "react";

const ProfileContext = createContext({
  profile: null,
  myDestination: null,
  activityHours: null,
  updateProfile: (profile) => {},
  setProfile: (profile) => {},
  setMyDestination: (myDestination) => {},
});

export default ProfileContext;
