import React, { useRef, useState } from "react";
import { renderLinks } from "../../../../../utils";
import { Edit, Save } from "../../../assets/icons";
import Flex from "../../structure/Flex";
import AutoExpandingTextArea from "../../ui/AutoExpandingTextArea";
import Widget from "../../ui/Widget";

interface WhiteboardProps {}

const Whiteboard: React.FC<WhiteboardProps> = () => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isEditing, setEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setValue(e.target.value);
  };

  const toggleView = (
    updateWidget: (key: string, value: any) => void,
    pretext: string,
    e: any
  ) => {
    e.stopPropagation();
    if (isEditing) {
      updateWidget("text", value);
    } else {
      setValue(pretext || "");

      setTimeout(() => {
        if (
          textareaRef &&
          textareaRef.current &&
          typeof textareaRef.current.focus === "function"
        ) {
          textareaRef.current.focus();
        }
      }, 100);
    }
    setEditing((v) => !v);
  };

  return (
    <Widget title="Whiteboard" id="whiteboard" hasToggle>
      {({ widget, updateWidget, isHost }) => (
        <section
          className="w-full relative"
          style={{ boxShadow: "0 0 5px rgba(0,0,0,0.1)" }}
          data-host-options={
            <button
              className="focus:outline-none mr-4 flex items-center"
              onClick={toggleView.bind(null, updateWidget, widget.data.text)}
            >
              {isEditing ? (
                <Save className="w-4 h-4 mr-2" />
              ) : (
                <Edit className="w-4 h-4 mr-2" />
              )}
              <span className="text-xs font-bold">
                {isEditing ? "Save" : "Edit"}
              </span>
            </button>
          }
        >
          {isEditing ? (
            <AutoExpandingTextArea
              className="whiteboard-text leading-5 font-medium w-full p-2 form-textarea"
              placeholder="Add a whiteboard text"
              ref={textareaRef}
              value={value}
              onChange={handleChange}
            />
          ) : widget.data.text !== "" ? (
            <p
              className="whiteboard-text whitespace-pre-line break-words leading-5 p-2 w-full font-medium"
              dangerouslySetInnerHTML={{
                __html: renderLinks(widget.data.text),
              }}
            />
          ) : isHost ? (
            <Flex
              items="center"
              justify="center"
              className="text-sm text-zo-mid-grey font-bold py-2"
            >
              Press edit icon to start.
            </Flex>
          ) : (
            <Flex
              items="center"
              justify="center"
              className="text-sm text-zo-mid-grey font-bold py-2"
            ></Flex>
          )}
        </section>
      )}
    </Widget>
  );
};

export default Whiteboard;
