import React, { useContext, useState, useEffect } from "react";

import InGameContext from "../../../../../contexts/inGame";

const GameScore = () => {
  const { playersStates, roomStatus } = useContext(InGameContext);

  const [mafiaCount, setMafiaCount] = useState(2);
  const [villagerCount, setVillagerCount] = useState(2);

  useEffect(() => {
    let mafias = 0,
      villagers = 0;
    Object.values(playersStates).forEach((player) => {
      if (player.status === "alive") {
        if (player.role && player.role.includes("mafia")) {
          mafias++;
        } else {
          villagers++;
        }
      }
    });
    setMafiaCount(mafias);
    setVillagerCount(villagers);
  }, [playersStates]);

  return (
    <div className="w-1/3 flex flex-col items-center">
      {roomStatus === "ended_mafia" && (
        <div className="text-red-800 font-bold text-3xl uppercase tracking-wide whitespace-no-wrap">
          Mafia wins
        </div>
      )}
      {roomStatus === "ended_village" && (
        <div className="text-mafia-secondary font-bold text-3xl uppercase tracking-wide whitespace-no-wrap">
          Village wins
        </div>
      )}
      <div className="w-content text-white flex items-center">
        <div className="flex w-full items-center bg-mafia-primary shadow-lg mt-2 rounded-lg p-1 justify-between">
          Mafia{" "}
          <span className="text-lg font-bold inline-block ml-4 mr-2 text-red-800">
            {mafiaCount}
          </span>{" "}
          <span className="text-xs lowercase align-middle font-bold">vs</span>{" "}
          <span className="text-lg font-bold inline-block mr-4 ml-2 text-mafia-secondary">
            {villagerCount}
          </span>{" "}
          Village
        </div>
      </div>
    </div>
  );
};

export default GameScore;
