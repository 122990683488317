import JanusStore from "./JanusStore";
import getRandomString from "../utils/getRandomString";
import { KEEPALIVE_INTERVAL } from "../utils/constants";

function Janus(token) {
  const janusStore = JanusStore();
  let keepAliveTimer;

  const routeMessage = (message) => {
    const { transaction } = message;
    const callback = janusStore.getCallback(transaction);
    if (callback) {
      callback(message);
    }
  };

  const createUserSession = (sendSocketMessage, callback) => {
    const request = {
      janus: "create",
      transaction: janusStore.storeCallback(callback),
      token: token,
    };
    sendSocketMessage(request);
  };

  const attachAudioBridgePlugin = (sessionId, sendSocketMessage, callback) => {
    const request = {
      janus: "attach",
      plugin: "janus.plugin.audiobridge",
      transaction: janusStore.storeCallback(callback),
      opaque_id: getRandomString(10),
      session_id: sessionId,
      token,
    };
    sendSocketMessage(request);
  };

  const startKeepAlive = (token, sessionId, sendSocketMessage) => {
    keepAliveTimer = setInterval(() => {
      let request = {
        janus: "keepalive",
        session_id: sessionId,
        transaction: getRandomString(12),
        token: token,
      };
      console.log("[WEBRTC] Sending keepAlive.");
      sendSocketMessage(request);
    }, KEEPALIVE_INTERVAL);
  };

  const stopKeepAlive = () => {
    clearInterval(keepAliveTimer);
  };

  return {
    routeMessage,
    token,
    createUserSession,
    attachAudioBridgePlugin,
    startKeepAlive,
    stopKeepAlive,
  };
}

export default Janus;
