import React, { useEffect } from "react";
import useApi from "../../hooks/useApi";
import { useHistory } from "react-router-dom";

const MafiaRedirect = () => {
  const history = useHistory();

  const api = useApi("games", true);

  useEffect(() => {
    if (api) {
      api
        .get("/games/api/v1/mafia/room/tournament/active/")
        .then((res) => {
          if (res.data.room) {
            history.push("/room/" + res.data.room.code + "/");
          } else {
            history.push("/");
          }
        })
        .catch((e) => {
          history.push("/");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return <></>;
};

export default MafiaRedirect;
