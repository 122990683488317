import React from "react";
import SubSection from "../../components/SubSection";
import Section from "../../components/Section";
import EmailField from "./components/EmailField";
import SMSField from "./components/SMSField";
import WhatsappField from "./components/WhatsappField";

const NotificationSettings = ({
  emailFields,
  setEmailFields,
  mobileFields,
  setMobileFields,
  loadingContact,
}) => {
  return (
    <Section title="Notification">
      <SubSection title="Email">
        <EmailField
          loadingContact={loadingContact}
          emailFields={emailFields}
          setEmailFields={setEmailFields}
        />
      </SubSection>
      <SubSection title="Mobile Number">
        <SMSField
          loadingContact={loadingContact}
          mobileFields={mobileFields}
          setMobileFields={setMobileFields}
        />
        <WhatsappField
          loadingContact={loadingContact}
          mobileFields={mobileFields}
          setMobileFields={setMobileFields}
        />
      </SubSection>
    </Section>
  );
};

export default NotificationSettings;
