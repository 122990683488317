import React, { useContext, useRef, useState } from "react";
import AppContext from "../../../../contexts/app";
import ProfileContext from "../../../../contexts/profile";
import SeedContext from "../../../../contexts/seed";
import useApi from "../../../../hooks/useApi";
import useResponseFlash from "../../../../hooks/useResponseFlash";
import Modal from "../../../../ui/Modal";
import RoomContext from "../../contexts/room";

const AmbientSoundModal = ({ close, sound }) => {
  const api = useApi("profile", true);
  const commsApi = useApi("comms", true);
  const { updateProfile } = useContext(ProfileContext);
  const { sendSocketMessage } = useContext(RoomContext);
  const { category, slug } = useContext(AppContext);
  const { musicFiles } = useContext(SeedContext);
  const { response, setResponse } = useResponseFlash();

  const [selected, setSelected] = useState(sound);
  const [loading, setLoading] = useState(false);
  const audioRef = useRef();

  const onSuccess = (music) => {
    sendSocketMessage({
      action: "update_music",
      payload: { music },
    });
    setResponse("Ambient Sound updated.");
    setLoading(false);
    close();
  };

  const handleSubmit = async (value) => {
    if (category === "profile") {
      if (!loading) {
        setLoading(true);
        try {
          const data = {
            music_key: value,
          };
          const response = await api.post(
            `/profile/api/v1/me/`,
            JSON.stringify(data)
          );
          if (response.status === 200) {
            console.log(response);
            updateProfile(data);
            onSuccess(data.music_key);
          }
        } catch (error) {
          console.log(error.response);
        }
      }
    } else {
      if (!loading) {
        setLoading(true);
        try {
          const data = {
            music_key: value,
          };
          const response = await commsApi.post(
            `/lobby/api/v1/room/${slug || "zo"}/`,
            JSON.stringify(data)
          );
          if (response.status === 200) {
            console.log(response);
            onSuccess(data.music_key);
          }
        } catch (error) {
          console.log(error.response);
        }
      }
    }
  };

  const removeSound = () => {
    setSelected(null);
    handleSubmit(null);
  };

  return (
    <Modal close={close} backgroundTheme="light" visible>
      <div className="w-108 portrait:w-full rounded-lg bg-white border-t-4 border-orange flex flex-col items-center">
        <span className="font-bold text-xl p-4">Ambient Sound Settings</span>
        <div className="bg-gray-100 w-full flex items-center justify-center p-4">
          <div className="flex flex-col w-full">
            {selected && musicFiles[selected].file && (
              <audio
                controls
                src={musicFiles[selected].file}
                ref={audioRef}
                className="focus:outline-none w-full"
                controlsList="nodownload novolume"
              />
            )}
            <div
              className="flex flex-wrap w-full overflow-y-auto mt-2"
              style={{ maxHeight: "24rem" }}
            >
              {Object.keys(musicFiles).map((msc) => {
                const _msc = musicFiles[msc];
                return (
                  <div
                    className="inline-block w-24 cursor-pointer h-content flex flex-col items-center"
                    key={_msc}
                    onClick={setSelected.bind(null, msc)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      className={`w-20 h-20 p-2 rounded-lg border-2 bg-white fill-current ${
                        selected === msc
                          ? "border-orange text-orange"
                          : "text-gray-800"
                      }`}
                    >
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path d="M8 18c.55 0 1-.45 1-1V7c0-.55-.45-1-1-1s-1 .45-1 1v10c0 .55.45 1 1 1zm4 4c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1s-1 .45-1 1v18c0 .55.45 1 1 1zm-8-8c.55 0 1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1v2c0 .55.45 1 1 1zm12 4c.55 0 1-.45 1-1V7c0-.55-.45-1-1-1s-1 .45-1 1v10c0 .55.45 1 1 1zm3-7v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1z" />
                    </svg>
                    <span className="block truncate w-full text-xs text-center">
                      {_msc.name || "Untitled"}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="w-full h-6 flex items-center justify-start font-semibold text-xs text-orange">
              {response}
            </div>
            <div className="flex mb-4 w-full">
              <button
                className="bg-orange text-white cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
                onClick={handleSubmit.bind(null, selected)}
              >
                Save
              </button>
              {sound && (
                <button
                  className="text-red-500 cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
                  onClick={removeSound}
                >
                  Remove
                </button>
              )}
              <button
                className="bg-white text-orange cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
                onClick={close}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AmbientSoundModal;
