import React, { useEffect, useRef } from "react";

import moment from "moment";
import Chart from "chart.js";

const Charts = ({ happinessAggregate }) => {
  const chartRef = useRef();

  useEffect(() => {
    if (happinessAggregate) {
      const myChartRef = chartRef.current.getContext("2d");

      new Chart(myChartRef, {
        type: "line",
        data: {
          labels: happinessAggregate.map((x) =>
            moment(x.date).format("MMM DD")
          ),
          datasets: [
            {
              label: "happiness",
              fill: false,
              backgroundColor: "green",
              borderColor: "green",
              data: happinessAggregate.map((x) => x.percent),
            },
          ],
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: "Zo world happines",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          scales: {
            x: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "day",
              },
            },
            yAxes: [
              {
                ticks: {
                  min: 0,
                  max: 100,
                  callback: function (value) {
                    return value + "%";
                  },
                },
                scaleLabel: {
                  display: true,
                  labelString: "Percentage",
                },
              },
            ],
            // y: {
            //   display: true,
            //   scaleLabel: {
            //     display: true,
            //     labelString: 'Value'
            //   }
            // }
          },
        },
      });
    }
  }, [happinessAggregate]);

  return (
    <div>
      <div className="flex  items-center justify-center">
        <div className="mb-2">
          <p className="mb-2 "> Destinations</p>
          <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
            <option>All Zo World</option>
            <option>Delhi</option>
            <option>Agra</option>
            <option>Allepey</option>
            <option>Banglore</option>
            <option>Bir</option>
          </select>

          <div className="pointer-events-none relative w-full items-center px-2  -mt-6 text-gray-700">
            <svg
              className="fill-current h-4 w-4 absolute right-0 mr-2 "
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>

        <div className="mb-2  ml-4 text-center text-xl">
          {/* <p className='mb-2'> Destination</p>
          <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
            <option>All</option>
            <option>Rest of the world</option>
            <option>Country</option>
          </select> */}
          {/* <div className="pointer-events-none absolute right-0 flex items-center px-2 mr-6 -mt-6 text-gray-700">
            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div> */}
        </div>
      </div>

      <div className="mt-10">
        <canvas id="myChart" ref={chartRef} />
      </div>
    </div>
  );
};

export default Charts;
