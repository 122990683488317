import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRTC } from "../../../../../contexts";
import InGameContext from "../../../contexts/inGame";
import RoomContext from "../../../contexts/room";
import CharacterCounts from "./CharacterCounts";

const Population = () => {
  const { room } = useContext(RoomContext);
  const { playersStates, roomStatus, player } = useContext(InGameContext);
  const { peers } = useRTC();

  const [mafiaCount, setMafiaCount] = useState(2);
  const [villagerCount, setVillagerCount] = useState(2);

  useEffect(() => {
    let mafias = 0,
      villagers = 0;
    Object.values(playersStates).forEach((player) => {
      if (player.status === "alive") {
        if (player.role && player.role.includes("mafia")) {
          mafias++;
        } else {
          villagers++;
        }
      }
    });
    setMafiaCount(mafias);
    setVillagerCount(villagers);
  }, [playersStates]);

  const filterSpectators = useCallback(
    (_peer) => {
      if (roomStatus !== "ended_mafia" && roomStatus !== "ended_village") {
        return (
          Object.values(playersStates)
            .filter((_player) => _player.status === "alive")
            .map((_player) => _player.nickname)
            .indexOf(_peer.display) === -1
        );
      } else {
        return true;
      }
    },
    [playersStates, roomStatus]
  );

  const spectators = useMemo(() => {
    const s = peers.filter(filterSpectators);
    const uniqueS = [];
    if (player.id == null) {
      s.unshift({ display: player.nickname, id: "spec123" });
    }
    s.forEach((ss) => {
      if (uniqueS.indexOf(ss.display) === -1) {
        uniqueS.push(ss.display);
      }
    });
    return uniqueS;
  }, [filterSpectators, peers, player]);

  return (
    <section className="flex flex-col items-end">
      <div className="flex flex-col items-start">
        <div className="bg-mafia-primary flex flex-col items-center text-sm font-semibold tracking-normal uppercase inline-block px-2 py-1 rounded-lg shadow-lg">
          {room.tournament ? (
            <span className="border-mafia-secondary text-mafia-secondary border-2 m-1 font-bold uppercase tracking-wide p-1 text-xs leading-none rounded-lg whitespace-no-wrap">
              Season Finals
            </span>
          ) : (
            room.ranked_game && (
              <span className="border-mafia-secondary text-mafia-secondary border-2 m-1 font-bold uppercase tracking-wide p-1 text-xs leading-none rounded-lg whitespace-no-wrap">
                Ranked Game
              </span>
            )
          )}
          <CharacterCounts />
          <div className="flex w-full items-center border-t border-mafia-secondary mt-1 pt-1 justify-between">
            Mafia{" "}
            <span className="text-lg font-bold text-red-800">{mafiaCount}</span>{" "}
            <span className="text-xs lowercase align-middle font-bold">vs</span>{" "}
            <span className="text-lg font-bold text-mafia-secondary">
              {villagerCount}
            </span>{" "}
            Village
          </div>
        </div>
        <div>
          Total Population: <strong>{room.players_count}</strong>
        </div>
        <div>
          Dead:{" "}
          <strong>{room.players_count - mafiaCount - villagerCount}</strong>
        </div>
        {peers && (
          <div className="relative group">
            <div>
              Spectators: <strong>{spectators.length}</strong>
            </div>
            <ul className="opacity-0 absolute top-full right-0 w-content group-hover:opacity-75 text-xs bg-mafia-primary rounded-lg shadow-xl z-20">
              {spectators.map((p) => (
                <li key={p} className="mx-2 mt-2 last:mb-2">
                  {p}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export default Population;
