import React, { useEffect, useState } from "react";
import { useResponseFlash } from "../../../../../../../../../components/apps/Zo/components/hooks";
import { useProfile, useSeed } from "../../../../../../../contexts";
import useApi from "../../../../../../../hooks/useApi";

const EssentialIds = ({ goBack }) => {
  const api = useApi("PROFILE_ME");

  const { profile, setProfile } = useProfile();
  const { seedData } = useSeed();
  const { response, setResponse } = useResponseFlash();

  const [ids, setIds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (profile && seedData) {
      setIds(
        seedData.id_proof.map((v) => {
          return {
            id: v[0],
            name: v[1],
            file: profile.assets.find((a) => a.type === v[0])
              ? profile.assets.find((a) => a.type === v[0]).file
              : "",
            loading: false,
          };
        })
      );
    }
  }, [profile, seedData]);

  const handleFile = (id, e) => {
    const eids = [...ids];
    if (eids.find((e) => e.id === id)) {
      eids.find((e) => e.id === id).file = e.target.files[0];
    }
    setIds(eids);
  };

  const handleSave = async (id) => {
    const eids = [...ids];
    if (!eids.find((e) => e.id === id)) {
      return;
    }
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("file", eids.find((e) => e.id === id).file);
      const response = await api.post(`/assets/${id}/upload/`, formData, {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      });
      console.log(response);
      if (response.status === 200) {
        profile.assets.push(response.data);
        setProfile((p) => {
          const asset = p.assets.find((a) => a.type === id);
          if (asset) {
            asset.file = response.data.file;
          }
          setProfile({ ...p });
          setResponse([id, "File Uploaded successfully."]);
        });
      } else {
        setResponse([id, "Error in uploading file"]);
      }
    } catch (error) {
      console.log(error);
      setResponse([id, "Error in uploading file."]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="flex flex-col h-full">
      <div
        className="flex flex-wrap overflow-y-auto"
        style={{ height: "calc(100% - 3rem)" }}
      >
        {ids.map((eid) => (
          <div
            key={eid.id}
            className="inline-flex flex-col border w-72 portrait:w-full p-4 rounded-lg m-4 portrait:m-0 portrait:mb-2"
            style={{ height: "max-content" }}
          >
            <div className="font-medium">{eid.name}</div>
            <img
              src={eid.file}
              className="w-full h-64 border rounded-lg bg-gray-200 my-6"
              alt=""
            />
            <input
              type="file"
              id="id-file"
              className="cursor-pointer"
              onChange={(e) => handleFile(eid.id, e)}
            />
            <div className="h-6 flex items-center justify-center font-semibold text-xs text-orange">
              {response && response[0] === eid.id && response[1]}
            </div>
            <button
              className="p-2 w-40 portrait:w-full mx-auto bg-orange text-white font-medium rounded-lg hover:shadow-lg"
              loading={loading.toString()}
              onClick={() => {
                handleSave(eid.id);
              }}
            >
              Save
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default EssentialIds;
