import React, { useEffect, useState } from "react";
import { useRoom } from "../../../contexts";
import Widget from "../../ui/Widget";
import useApi from "./../../../hooks/useApi";
import GameCard from "./components/MatchCard/MatchCard";

const MafiaPublicGames = () => {
  const api = useApi("GAMES_MAFIA_ROOMS_PUBLIC");
  const { message } = useRoom();

  const [rooms, setRooms] = useState<any[]>([]);

  useEffect(() => {
    let mounted = true;
    if (message && message.action === "new_match") {
      if (mounted) {
        setRooms((r) => [message.payload, ...r]);
      }
    }

    return () => {
      mounted = false;
    };
  }, [message]);

  useEffect(() => {
    let mounted = true;
    if (api) {
      api
        .get("")
        .then((res) => {
          if (mounted) {
            console.log("Rooms List", res);
            setRooms(res.data.rooms);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <Widget title="Active Mafia Matches" id="mafia-active-matches" hasToggle>
      {() => (
        <div className="relative flex-grow w-full h-96 overflow-y-auto scroll-visible rounded-lg">
          {rooms.length > 0 ? (
            <ul className="h-auto p-2">
              {rooms.map((room) => (
                <GameCard cardData={room} key={room.code} />
              ))}
            </ul>
          ) : (
            <div className="w-full h-full flex items-center justify-center text-lg font-semibold text-gray-800">
              There are no active rooms right now.
            </div>
          )}
        </div>
      )}
    </Widget>
  );
};

export default MafiaPublicGames;
