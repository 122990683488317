import React, { useEffect, useMemo, useState } from "react";
import { useResponseFlash } from "../../../apps/Zo/components/hooks";
import AudioBridgePlugin from "../lib/AudioBridgePlugin";
import Janus from "../lib/Janus";
import RTC from "../lib/RTC";
import { JANUS_TOKEN_URL } from "../utils/constants";
import PoorNetModal from "./modals/PoorNetModal";

const AudioBridge = ({
  userId,
  roomId,
  displayId,
  peers,
  record,
  setPeers,
  selfMuted,
  setSelfMuted,
  inputDeviceId,
  fileName,
  setActive,
  socket,
  message,
  sendMessage,
}) => {
  const [token, setToken] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [publisherHandle, setPublisherHandle] = useState(null);
  const [publisherId, setPublisherId] = useState(null);
  const [roomExists, setRoomExistence] = useState(null);
  const [peerConnections, setPeerConnections] = useState({});
  const [sdpOffer, setSdpOffer] = useState(null);

  const { response: isPoorConnection, setResponse: setPoorConnection } =
    useResponseFlash(5000);

  useEffect(() => {
    let mounted = true;

    fetch(JANUS_TOKEN_URL)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const { token } = data.data;
        if (mounted) {
          setToken(token);
        }
      })
      .catch(console.log);

    return () => {
      mounted = false;
    };
  }, [userId]);

  const janusInstance = useMemo(() => {
    if (token) {
      console.log(`[RTC_ROOM] Initializing Janus Instance.`);
      return Janus(token);
    }
  }, [token]);

  useEffect(() => {
    console.log(`[RTC_ROOM] Peers:`, peers);
  }, [peers]);

  const audioBridgeInstance = useMemo(() => {
    if (publisherHandle != null && token != null && sessionId != null) {
      console.log(`[RTC_ROOM] Initializing Audio Bridge Instance.`);
      return AudioBridgePlugin(
        sessionId,
        displayId,
        userId,
        token,
        publisherHandle,
        sendMessage,
        record
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisherHandle, sessionId, token]);

  useEffect(() => {
    if (audioBridgeInstance && janusInstance) {
      window.addEventListener("beforeunload", leaveRoom, false);
    }
    return () => {
      if (audioBridgeInstance && janusInstance) {
        console.log(`[RTC_ROOM] Unmounted.`);
        leaveRoom();
        window.removeEventListener("beforeunload", leaveRoom, false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioBridgeInstance, janusInstance]);

  const rtcInstance = useMemo(() => {
    if (publisherHandle != null && token != null && sessionId != null) {
      console.log(`[RTC_ROOM] Initializing RTC Instance.`);
      return RTC(sessionId, token, publisherHandle, sendMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisherHandle, sessionId, token]);

  function onUserSession(message) {
    if (message.janus === "success") {
      console.log(`[RTC_ROOM] User Session Created.`);
      setSessionId(message.data.id);
    }
  }

  function onAttachingAudioBridgePlugin(message) {
    if (message.janus === "success") {
      console.log(`[RTC_ROOM] AudioBridge Attached.`);
      setPublisherHandle(message.data.id);
    }
  }

  function onRoomCheck(message) {
    if (
      message.janus === "success" &&
      message.plugindata.data.audiobridge === "success"
    ) {
      console.log(`[RTC_ROOM] Room Check Successful.`);
      setRoomExistence(message.plugindata.data.exists);
    }
  }

  function onRoomLeave(message) {
    console.log(`[RTC_ROOM] Room Left Successfully.`, message);
  }

  function leaveRoom() {
    if (audioBridgeInstance && janusInstance) {
      console.log(`[RTC_ROOM] Leaving room.`, roomId);
      audioBridgeInstance.leaveRoom(roomId, onRoomLeave);
    }
  }

  function onRoomCreation(message) {
    if (
      message.janus === "success" &&
      message.plugindata.data.audiobridge === "created"
    ) {
      console.log(`[RTC_ROOM] Room Created Successfully.`);
      setRoomExistence(true);
    }
  }

  function onRoomJoin(message) {
    if (
      message.janus === "event" &&
      message.plugindata.data.audiobridge === "joined"
    ) {
      console.log(`[RTC_ROOM] Room Joined Successfully`);
      setPeers(message.plugindata.data.participants);
      setPublisherId(message.plugindata.data.id);
      getRTCPeerConnection(message.plugindata.data.id);
      setupPublisherStream(message.plugindata.data.id);
    }
  }

  function getRTCPeerConnection(id) {
    if (id in peerConnections) {
      return peerConnections[id].pc;
    }

    const _peerConnection = new RTCPeerConnection({
      iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
    });
    _peerConnection.onicecandidate = (event) => {
      if (rtcInstance) {
        if (event.candidate) {
          rtcInstance.trickleCandidate(event.candidate);
        } else {
          rtcInstance.trickleCandidate({ completed: true });
        }
      }
    };
    _peerConnection.ontrack = (event) => {
      const remoteStream = new MediaStream();
      remoteStream.addTrack(event.track);
      setPeerConnections((pcs) => {
        return {
          ...pcs,
          [id]: { ...pcs[id], stream: remoteStream },
        };
      });
    };
    _peerConnection.onnegotiationneeded = (data) => {
      _peerConnection
        .createOffer()
        .then((offer) => {
          return _peerConnection.setLocalDescription(offer);
        })
        .then(() => {
          const sdp = _peerConnection.localDescription;
          if (!sdpOffer) {
            setSdpOffer(sdp);
          }
          setPeerConnections((pcs) => {
            return {
              ...pcs,
              [id]: { ...pcs[id], sdpOffer: sdp },
            };
          });
        });
    };
    _peerConnection.onremovetrack = (data) => {};
    _peerConnection.oniceconnectionstatechange = (data) => {};
    _peerConnection.onicegatheringstatechange = (data) => {};
    _peerConnection.onSignalingStateChange = (data) => {};

    setPeerConnections((pcs) => {
      return { ...pcs, [id]: { pc: _peerConnection } };
    });
    return _peerConnection;
  }

  function setupPublisherStream(id) {
    const pc = getRTCPeerConnection(id);

    try {
      navigator.mediaDevices
        .getUserMedia({
          audio: inputDeviceId ? { deviceId: { exact: inputDeviceId } } : true,
        })
        .then((localStream) => {
          console.log(
            "[RTC_ROOM] Setting up publisher stream",
            inputDeviceId,
            pc
          );
          localStream
            .getTracks()
            .forEach((track) => pc.addTrack(track, localStream));
          // setPcs({...pcs.current, [id]: {pc: pc, stream: localStream, publisher: true}});
          setPeerConnections((pcs) => {
            return { ...pcs, [id]: { pc: pc, publisher: true } };
          });
        })
        .catch((e) => {
          console.log(`Error: [${e.name}]: ${e.message}`);
        });
    } catch (error) {
      console.log(error);
    }
  }

  function onStreamPublish(message) {
    if (message.janus === "event" && message.plugindata.data.result === "ok") {
      const pc = peerConnections[publisherId].pc;
      pc.setRemoteDescription(new RTCSessionDescription(message.jsep))
        .then(() => {
          console.log(`[RTC_ROOM] Remote Description set`);
        })
        .catch((e) => console.log(e));

      setPeerConnections((pcs) => {
        return {
          ...pcs,
          [publisherId]: { ...pcs[publisherId], pc: pc },
        };
      });
    }
  }

  function onRoomChange(message) {
    if (
      message.janus === "event" &&
      message.plugindata.data.audiobridge === "roomchanged"
    ) {
      console.log(`[RTC_ROOM] Room Changed Successfully.`);
      setPeers(message.plugindata.data.participants);
    }
  }

  useEffect(() => {
    if (janusInstance != null && socket != null && socket.readyState === 1) {
      console.log(`[RTC_ROOM] Creating User Session.`);
      janusInstance.createUserSession(sendMessage, onUserSession);
    }
    if (
      janusInstance != null &&
      (socket == null || (socket != null && socket.readyState !== 1))
    ) {
      setTimeout(() => {
        console.log(`[RTC_ROOM] Rechecking Session.`);
        console.log(`[RTC_ROOM] Creating User Session.`);
        janusInstance.createUserSession(sendMessage, onUserSession);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [janusInstance, socket]);

  useEffect(() => {
    if (message) {
      if (message.janus === "webrtcup") {
        console.log(`[RTC_ROOM] Room Started Successfully.`, message);
        setActive(true);
      } else if (message.janus === "hangup") {
        console.log(`[RTC_ROOM] Room Hanged up.`, message);
        setActive(false);
      } else if (message.janus === "slowlink") {
        console.log(`[RTC_ROOM] Slow Internet detected.`);
        setPoorConnection(true);
      } else if (message.janus === "media") {
        console.log(`[RTC_ROOM] Media received.`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    if (message != null && janusInstance != null) {
      janusInstance.routeMessage(message);
    }
  }, [janusInstance, message]);

  useEffect(() => {
    if (message != null && audioBridgeInstance != null) {
      // console.log("RTC_ROOM] DEBUG", message);
      audioBridgeInstance.routeMessage(message);
    }
  }, [audioBridgeInstance, message]);

  useEffect(() => {
    if (sessionId != null && janusInstance != null) {
      janusInstance.attachAudioBridgePlugin(
        sessionId,
        sendMessage,
        onAttachingAudioBridgePlugin
      );
      janusInstance.startKeepAlive(token, sessionId, sendMessage);
    }
    return () => {
      if (janusInstance) {
        janusInstance.stopKeepAlive();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [janusInstance, sessionId]);

  useEffect(() => {
    if (publisherHandle) {
      if (roomExists === null) {
        audioBridgeInstance.checkForRoom(roomId, onRoomCheck);
      } else if (roomExists === true) {
        audioBridgeInstance.joinRoom(roomId, selfMuted, onRoomJoin);
      } else if (roomExists === false) {
        audioBridgeInstance.createRoom(roomId, onRoomCreation);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomExists, publisherHandle]);

  useEffect(() => {
    if (sdpOffer != null && audioBridgeInstance != null) {
      audioBridgeInstance.publishStream(sdpOffer, selfMuted, onStreamPublish);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioBridgeInstance, sdpOffer, publisherId]);

  function onMute(message) {
    if (message.janus === "event" && message.plugindata.data.result === "ok") {
      // setSelfMuted(selfMuted);
    }
  }

  function configureMute(muted) {
    if (sdpOffer && audioBridgeInstance) {
      audioBridgeInstance.configureMute(muted, onMute);
    }
  }

  useEffect(() => {
    configureMute(selfMuted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfMuted]);

  useEffect(() => {
    if (inputDeviceId && audioBridgeInstance) {
      console.log("[RTC_ROOM] Changing input device", inputDeviceId);
      audioBridgeInstance.leaveRoom(roomId, onRoomLeave);
      setPeerConnections({});
      setPeers([]);
      setSdpOffer(null);
      setPublisherId(null);
      setTimeout(() => {
        audioBridgeInstance.joinRoom(roomId, selfMuted, onRoomJoin);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDeviceId]);

  useEffect(() => {
    if (sessionId != null && audioBridgeInstance != null) {
      setSelfMuted(true);
      audioBridgeInstance.checkForRoom(roomId, function (message) {
        if (
          message.janus === "success" &&
          message.plugindata.data.audiobridge === "success"
        ) {
          console.log(`[RTC_ROOM] Room Check Successful.`);
          if (message.plugindata.data.exists) {
            audioBridgeInstance.changeRoom(roomId, selfMuted, onRoomChange);
          } else {
            audioBridgeInstance.createRoom(roomId, () => {
              audioBridgeInstance.changeRoom(roomId, selfMuted, onRoomChange);
            });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  useEffect(() => {
    if (message && message.janus === "event") {
      const { audiobridge, id, participants, leaving, display } =
        message.plugindata.data;
      if (audiobridge === "talking" || audiobridge === "stopped-talking") {
        const peer = peers.find((item) => item.id === id);
        if (peer != null) {
          peer["status"] = audiobridge;
          setPeers((ps) => [...ps.filter((p) => p.id !== id), peer]);
        } else {
          if (id === userId) {
            setPeers((p) => [
              ...p,
              { id, display: displayId, status: audiobridge },
            ]);
          }
        }
      } else if (participants) {
        const newPeer = participants[0];
        if (newPeer) {
          const existingPeer = peers.find((item) => item.id === newPeer.id);
          // New participant added
          if (existingPeer) {
            setPeers((ps) => [
              ...ps.filter((p) => p.id !== newPeer.id),
              newPeer,
            ]);
          } else {
            console.log(
              `[RTC_ROOM] New Member Joined with display ${newPeer}.`
            );
            setPeers((ps) => [...ps, newPeer]);
          }
        }
      } else if (leaving) {
        const peer = peers.find((item) => item.id === leaving);
        if (peer) {
          console.log(`[RTC_ROOM] Member left with display ${peer}.`);
          setPeers((ps) => ps.filter((p) => p.id !== peer.id));
        }
      } else if (id && display) {
        // console.log("New Publisher: ", display, id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return useMemo(
    () => (
      <>
        <section className="hidden fixed top-0 left-0 w-content h-content">
          {Object.keys(peerConnections) &&
            Object.keys(peerConnections).map((key, index) => {
              const peer = peerConnections[key];
              // console.log("Peer", peer, !(peer.publisher));
              if (peer.stream) {
                return (
                  <div key={index} style={{ display: "inline" }}>
                    <audio
                      id="RTC_ROOM"
                      autoPlay
                      ref={(audio) =>
                        audio ? (audio.srcObject = peer.stream) : null
                      }
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}
        </section>
        {isPoorConnection && (
          <PoorNetModal close={setPoorConnection.bind(null, false)} />
        )}
      </>
    ),
    [isPoorConnection, peerConnections, setPoorConnection]
  );
};

export default AudioBridge;
