import React, { useEffect, useState } from "react";

import Widget from "../../ui/Widget";
import { useSeed } from "../../../contexts/seed";
import AssetModal from "./AssetModal";
import useApi from "../../../../../hooks/useApi";

const AssetOTD = (props) => {
  const { style } = useSeed();
  const api = useApi("zo world", false);
  const [isVisible, setVisible] = useState(false);
  const [assetOTD, setAssetOTD] = useState(null);

  useEffect(() => {
    if (api) {
      api
        .get(`/zoworld/api/v1/avatar/asset/today/`)
        .then((res) => {
          setAssetOTD(res.data.avatar_asset);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <>
      <Widget
        className={`zo-asset p-2 bg-white border-t-4 border-${
          style.accentColor || "orange"
        }`}
        {...props}
        {...props}
        onClick={setVisible.bind(null, true)}
      >
        <div className="font-bold">{props.data.title}</div>
        <div className="flex flex-col items-center w-full mt-8">
          <img
            className=" w-full h-24"
            src={assetOTD && assetOTD.cropped_file}
            alt=""
          />
          <div className="text-sm mt-8">Claim Now!</div>
        </div>
      </Widget>
      {isVisible && (
        <AssetModal close={setVisible.bind(null, "")} assetOTD={assetOTD} />
      )}
    </>
  );
};

export default AssetOTD;
