import React, {
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  ListRowRenderer,
  ScrollParams,
} from "react-virtualized";
import useAudio from "../../../../../../../../components/hooks/useAudio";
import Flex from "../../../../../../components/structure/Flex";
import { useProfile, useRoom } from "../../../../../../contexts";
import newComerAudio from "./../../../../../../assets/audio/new-comer.mp3";
import newMessageAudio from "./../../../../../../assets/audio/new-message.mp3";
import ChatMessageItem from "./components/ChatMessageItem";

interface ChatMessagesProps {
  active: boolean;
  setUnreadChatCount: React.Dispatch<SetStateAction<number>>;
}

const getColorCode = () => {
  const COLORS = [
    "text-red-500",
    "text-yellow-800",
    "text-green-500",
    "text-teal-500",
    "text-blue-500",
    "text-indigo-500",
    "text-purple-500",
    "text-pink-500",
    "text-orange",
    "text-teal-800",
  ];
  const index = Math.floor(Math.random() * COLORS.length);

  return COLORS[index];
};
const getUptoMinutes = (timestamp: number) => Math.floor(timestamp / 60000);

const ChatMessages: React.FC<ChatMessagesProps> = ({
  active,
  setUnreadChatCount,
}) => {
  const { profile } = useProfile();
  const { message, citizens, widgets, room } = useRoom();

  const newMessageSound = useAudio(newMessageAudio, false, 0.3);
  const newComerSound = useAudio(newComerAudio, false, 0.3);

  const [keyboardFocused, setKeyboardFocused] = useState<boolean>(false);
  const citizensColorRef = useRef<GeneralObject>({});
  const listRef = useRef<List>(null);
  const lastLength = useRef(0);
  const title = useRef(document.title);
  const pageHidden = useRef(false);
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const nickname = useMemo(() => profile?.nickname, [profile]);
  const roomCode = useMemo(() => room?.nickname, [room]);
  const isHost = useMemo(
    () => room?.nickname === profile?.nickname,
    [room, profile]
  );
  const hasScrollTampered = useRef<boolean>(false);
  const [hasNewMessages, setNewMessageVisibility] = useState<boolean>(true);

  const chatMessagesReceived = useMemo(
    () =>
      chatMessages.filter((c) => c.type !== "info" && c.from !== nickname)
        .length,
    [chatMessages, nickname]
  );
  const chatWidget = useMemo(
    () => widgets.find((f) => f.widget === "chat"),
    [widgets]
  );
  const infoMessagesVisible = useMemo(
    () => chatWidget?.data.infoMessagesVisible,
    [chatWidget]
  );
  const notificationsSounds = useMemo(
    () => chatWidget?.data.notificationsSounds,
    [chatWidget]
  );

  const scrollToBottom = () => {
    if (listRef.current) {
      listRef.current.scrollToRow(chatMessages.length);
      setNewMessageVisibility(false);
    }
  };

  const toggleKeyboardFocused = () => {
    setKeyboardFocused((v) => !v);
    setTimeout(scrollToBottom, 100);
  };

  useEffect(() => {
    if (window.innerWidth < 640) {
      document
        .querySelector("#chat-input")
        ?.addEventListener("focus", toggleKeyboardFocused);
      document
        .querySelector("#chat-input")
        ?.addEventListener("blur", toggleKeyboardFocused);
    }

    return () => {
      document
        .querySelector("#chat-input")
        ?.removeEventListener("focus", toggleKeyboardFocused);
      document
        .querySelector("#chat-input")
        ?.removeEventListener("blur", toggleKeyboardFocused);
    };
  });

  useEffect(() => {
    setChatMessages([]);
  }, [roomCode]);

  useEffect(() => {
    if (
      message &&
      message.action &&
      message.action === "joined" &&
      isHost &&
      newComerSound &&
      notificationsSounds
    ) {
      newComerSound.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, isHost]);

  useEffect(() => {
    if (!active) {
      if (chatMessagesReceived !== lastLength.current) {
        setUnreadChatCount((c) => ++c);
        if (chatMessages) {
          const filteredMessages = chatMessages.filter((cm) => !!cm.from);
          const lastChatter =
            filteredMessages[filteredMessages.length - 1] &&
            filteredMessages[filteredMessages.length - 1].from;
          if (lastChatter) {
            document.title = `New message from ${lastChatter}`;
          }
        }
        if (newMessageSound && notificationsSounds === true) {
          newMessageSound.play();
        }
      }
    } else {
      setUnreadChatCount(0);
      lastLength.current = chatMessagesReceived;
      document.title = title.current;
      if (pageHidden.current) {
        if (chatMessages) {
          const filteredMessages = chatMessages.filter((cm) => !!cm.from);
          const lastChatter =
            filteredMessages[filteredMessages.length - 1] &&
            filteredMessages[filteredMessages.length - 1].from;
          if (lastChatter) {
            document.title = `New message from ${lastChatter}`;
          }
        }
        if (newMessageSound && notificationsSounds === true) {
          newMessageSound.play();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessagesReceived, active]);

  useEffect(() => {
    function makeSound() {
      if (document.visibilityState === "hidden") {
        pageHidden.current = true;
      } else {
        pageHidden.current = false;
        document.title = title.current;
      }
    }
    document.addEventListener("visibilitychange", makeSound);

    return () => {
      document.removeEventListener("visibilitychange", makeSound);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log("DDDD", message);
    if (message && message.action) {
      if (message.action === "member_chat") {
        setChatMessages((c) => [
          ...c,
          {
            type:
              message.payload.from === profile?.nickname ? "sent" : "received",
            message: message.payload.message,
            from: message.payload.from,
            timestamp: message.payload.timestamp,
            avatar: message.payload.avatar,
          },
        ]);
      } else if (message.action === "joined" && infoMessagesVisible === true) {
        setChatMessages((c) => [
          ...c,
          {
            type: "info",
            message: `${message.payload.nickname} has joined.`,
            timestamp: +new Date(),
            from: "",
          },
        ]);
      } else if (message.action === "left" && infoMessagesVisible === true) {
        setChatMessages((c) => [
          ...c,
          {
            type: "info",
            message: `${message.payload.nickname} has left.`,
            timestamp: +new Date(),
            from: "",
          },
        ]);
      } else if (
        message.action === "member_kick" &&
        infoMessagesVisible === true
      ) {
        setChatMessages((c) => [
          ...c,
          {
            type: "info",
            message: `${message.payload.nickname} has left ...`,
            timestamp: +new Date(),
            from: "",
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    citizens.forEach((m) => {
      if (!citizensColorRef.current[m.nickname]) {
        citizensColorRef.current[m.nickname] = getColorCode();
      }
    });
  }, [citizens]);

  useEffect(() => {
    if (!hasScrollTampered.current) {
      setTimeout(() => {
        if (!hasScrollTampered.current) {
          scrollToBottom();
        }
      }, 100);
    } else {
      setNewMessageVisibility(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessages]);

  const handleScroll = (params: ScrollParams) => {
    if (params.scrollHeight - params.clientHeight - params.scrollTop > 60) {
      hasScrollTampered.current = true;
    } else {
      hasScrollTampered.current = false;
      if (params.scrollHeight - params.clientHeight - params.scrollTop === 0) {
        setNewMessageVisibility(false);
      }
    }
  };

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
    })
  ).current;

  const chatRowRenderer: ListRowRenderer = ({ key, index, style, parent }) => {
    const chat = chatMessages[index];

    const collapsed =
      chatMessages[index - 1] &&
      chat.from === chatMessages[index - 1].from &&
      getUptoMinutes(chat.timestamp) ===
        getUptoMinutes(chatMessages[index - 1].timestamp);

    return (
      <CellMeasurer
        cache={cache}
        key={index}
        columnIndex={0}
        parent={parent}
        index={index}
      >
        {({ registerChild }) => (
          <ChatMessageItem
            type={chat.type}
            key={key}
            ref={(el) => {
              const _e: any = el;
              if (registerChild) {
                registerChild(_e);
              }
            }}
            style={style}
            timestamp={chat.timestamp}
            message={chat.message}
            nickname={chat.from}
            collapsed={collapsed}
            avatar={chat.avatar}
            accentColor={citizensColorRef.current[chat.from]}
          />
        )}
      </CellMeasurer>
    );
  };

  return (
    <div
      className={`w-full pb-3 ${
        !keyboardFocused && "pt-3"
      } scroll-hidden relative h-full`}
    >
      {hasNewMessages && (
        <Flex
          className="absolute left-0 right-0 pointer-events-none z-10 bottom-0 h-content"
          justify="center"
        >
          <button
            className="bg-orange text-white font-medium tracking-wide pointer-events-auto mb-4 p-2 text-sm leading-none rounded-lg shadow-md"
            onClick={scrollToBottom}
          >
            New Messages &darr;
          </button>
        </Flex>
      )}
      <AutoSizer>
        {({ width, height }) => (
          <List
            ref={listRef}
            onScroll={handleScroll}
            height={height}
            rowHeight={cache.rowHeight}
            width={width}
            rowCount={chatMessages.length}
            rowRenderer={chatRowRenderer}
            overscanRowCount={10}
            deferredMeasurementCache={cache}
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default ChatMessages;
