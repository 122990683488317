import moment from "moment";
import React, { useEffect, useState } from "react";
import { Check, Info } from "../../../assets/icons";
import { useProfile } from "../../../contexts";
import useApi from "../../../hooks/useApi";
import useResponseFlash from "../../../hooks/useResponseFlash";
import { getRoomURL } from "../../../utils/room";
import Flex from "../../structure/Flex";
import BottomSheet from "../../ui/BottomSheet";
import FormElement from "../../ui/FormElement";
import IconButton from "../../ui/IconButton";

interface EventEditSheetProps {
  open: boolean;
  onDismiss: (updated: boolean) => void;
  data: ZoEvent | "new" | null;
}

const EventEditSheet: React.FC<EventEditSheetProps> = ({
  open,
  data,
  onDismiss,
}) => {
  const { profile } = useProfile();
  const api = useApi("ZOWORLD_ROOM_EVENT");

  const [name, setName] = useState<string>("");
  const [startTime, setStartTime] = useState<moment.Moment>();
  const [endTime, setEndTime] = useState<moment.Moment>();
  const [description, setDescription] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { response, setResponse } = useResponseFlash();

  useEffect(() => {
    if (data != null && data !== "new") {
      setName(data.name);
      setStartTime(moment(data.start_time));
      setEndTime(moment(data.end_time));
      setDescription(data.description);
    }
  }, [data]);

  const close = (update: boolean) => {
    setName("");
    setDescription("");
    setStartTime(undefined);
    setEndTime(undefined);
    onDismiss(update);
  };

  const addOrUpdateEvent = async () => {
    setLoading(true);
    if (
      name.trim().length > 0 &&
      startTime != null &&
      endTime != null &&
      description.trim().length > 0
    ) {
      if (data === "new") {
        const _data = {
          name: name.trim(),
          start_time: moment(startTime).toISOString(),
          end_time: moment(endTime).toISOString(),
          description: description.trim(),
          url: getRoomURL(profile?.subdomain || ""),
          host: profile?.code,
        };
        if (api) {
          const response = await api.post("", JSON.stringify(_data));
          if (response.status === 201) {
            close(true);
          }
        }
      } else if (data) {
        const _data = {
          name: name.trim(),
          start_time: moment(startTime).toISOString(),
          end_time: moment(endTime).toISOString(),
          description: description.trim(),
          url: getRoomURL(profile?.subdomain || ""),
        };
        if (api) {
          const response = await api.patch(
            `/${data.id}/`,
            JSON.stringify(_data)
          );
          if (response.status === 201) {
            close(true);
          }
        }
      }
    } else {
      setResponse("Enter all the details.");
    }
  };

  return (
    <BottomSheet
      open={open}
      snapPoints={({ maxHeight }) => [maxHeight * 0.92]}
      onDismiss={close.bind(null, false)}
      title="Zo World Event"
      rightOptions={
        <IconButton onClick={addOrUpdateEvent} loading={loading}>
          <Check className="w-5 h-5" />
        </IconButton>
      }
    >
      <Flex col className="p-4 flex-1 h-full relative">
        {response && (
          <Flex
            items="center"
            justify="center"
            className="absolute top-0 left-0 right-0 mt-2 fadeInUp"
          >
            <Flex
              items="center"
              className="bg-red-500 text-white font-bold text-sm p-2 rounded-lg"
            >
              <Info className="w-4 h-4 mr-2" />
              {response}
            </Flex>
          </Flex>
        )}
        <span className="font-bold text-lg text-center">
          So, tell us <span className="text-orange">{profile?.nickname}</span>,
          What event would you like to host on <strong>Zo World</strong>?
        </span>
        <div className="mt-4 mb-32">
          <FormElement
            type="input"
            value={name}
            setValue={setName}
            placeholder="Event name"
            label="What would you like to call it?"
          />
          <FormElement
            type="datetime"
            value={startTime}
            setValue={setStartTime}
            placeholder="Event start time"
            label="When are you planning to start the event?"
          />
          <FormElement
            type="datetime"
            value={endTime}
            setValue={setEndTime}
            placeholder="Event end time"
            label="By what time do you think it would end?"
          />
          <FormElement
            type="textarea"
            value={description}
            setValue={setDescription}
            placeholder="Event description"
            label="Brief us about the event"
          />
        </div>
      </Flex>
    </BottomSheet>
  );
};

export default EventEditSheet;
