import React, { useState, useEffect, useContext } from "react";

import SocketContext from ".";
import AuthContext from "../../../../contexts/auth";
import ProfileContext from "../../../../contexts/profile";

const SOCKET_URL = "wss://socket." + process.env.REACT_APP_API_DOMAIN + "/";

const SocketProvider = ({ children }) => {
  const { profile } = useContext(ProfileContext);
  const [url, setUrl] = useState(null);
  const [message, setMessage] = useState(null);
  const [socket, setSocket] = useState(null);
  const [timeoutSeconds, setTimeoutSeconds] = useState(0);
  const [connecting, setConnecting] = useState(false);
  const [usertoken, setToken] = useState(null);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    if (url && (token || usertoken)) {
      const _token = token || usertoken;
      if (socket === null) {
        console.log("[SOCKET] Attempting Connection in", timeoutSeconds);
        setTimeout(() => {
          console.log("[SOCKET] Creating Connection...");
          setConnecting(true);
          const _socket = new WebSocket(SOCKET_URL + url + "?" + _token);
          _socket.onmessage = function (e) {
            const data = JSON.parse(e.data);
            setMessage(data);
            console.log("[SOCKET] Message received.", data);
          };
          _socket.onclose = (e) => {
            setSocket(null);
          };
          _socket.onerror = function (e) {
            setSocket(null);
          };
          _socket.onopen = (e) => {
            setConnecting(false);
            setTimeoutSeconds(0);
            console.log("[SOCKET] Connection established.");
          };
          setSocket(_socket);
        }, timeoutSeconds);
        setTimeoutSeconds((t) => Math.min(10000, t ? t * 2 : 2000));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, socket, token, usertoken]);

  const closeSocketConnection = () => {
    socket.close();
  };
  const sendSocketMessage = (action, payload) => {
    const data = {
      action,
      payload,
      time_sent: new Date().getTime(),
    };
    if (profile) {
      data.sender = profile.code;
    }
    socket.send(JSON.stringify(data));
  };
  const startSocketConnection = (_url) => {
    setUrl(_url);
  };

  return (
    <SocketContext.Provider
      value={{
        message,
        closeSocketConnection,
        sendSocketMessage,
        startSocketConnection,
        connecting,
        setToken,
        socket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
