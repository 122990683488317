import React, { useContext } from "react";

import TimeContext from "../../../contexts/time";
import { formattedSeconds, getMinutes, getSeconds } from "../../../utils";

const RoomTimer = () => {
  const { gameTime } = useContext(TimeContext);
  return (
    <section className="bg-mafia-primary font-bold text-sm text-mafia-secondary tracking-normal uppercase text-lg inline-block px-2 py-1 mt-2 rounded-lg shadow-lg">
      <span className="w-8 text-right pr-1">
        {formattedSeconds(getMinutes(gameTime))}
      </span>
      :
      <span className="w-8 text-left pl-1">
        {formattedSeconds(getSeconds(gameTime))}
      </span>
    </section>
  );
};

export default RoomTimer;
