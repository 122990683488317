import moment from "moment";
import React from "react";
import { Info } from "../../../../../../../../assets/icons";
import Avatar from "../../../../../../../../components/ui/Avatar";
import { renderLinks } from "../../../../../../../../utils/format";

interface ChatMessageItemProps {
  type: any;
  message: string;
  nickname: string;
  timestamp: number;
  collapsed: boolean;
  avatar: string | undefined;
  accentColor: any;
  onLoad?: () => void;
  style?: React.CSSProperties;
}

const ChatMessageItem = React.forwardRef<HTMLDivElement, ChatMessageItemProps>(
  (
    {
      type,
      message,
      nickname,
      style,
      collapsed,
      timestamp,
      avatar,
      onLoad,
      accentColor,
    },
    ref
  ) => {
    return type === "received" ? (
      <div
        className="flex items-start w-full transition-none transform-none"
        style={style}
        onLoad={onLoad}
        ref={ref}
      >
        {collapsed ? (
          <div className="w-8 h-8 mr-2 flex-shrink-0" />
        ) : (
          <Avatar
            className="w-8 h-8 bg-white rounded-full mr-2 flex-shrink-0"
            svg={avatar || null}
          />
        )}
        <div className="flex-grow overflow-hidden">
          <article
            className="w-content overflow-hidden bg-white rounded-lg p-2 mb-1 shadow-md hover:bg-white"
            style={{ maxWidth: "80%" }}
          >
            {!collapsed && (
              <header className="text-sm">
                <span
                  className={`font-bold pr-4 ${accentColor || "text-gray-800"}`}
                >
                  {nickname}
                </span>
                <span className="font-semibold text-gray-600 text-xxs">
                  {moment(timestamp).format("LT")}
                </span>
              </header>
            )}
            <section className="max-w-full break-words text-sm text-gray-800">
              <p
                dangerouslySetInnerHTML={{
                  __html: renderLinks(message, "text-orange hover:underline"),
                }}
              ></p>
            </section>
          </article>
        </div>
      </div>
    ) : type === "sent" ? (
      <div
        className="flex items-start w-full justify-end transition-none transform-none"
        style={style}
        onLoad={onLoad}
        ref={ref}
      >
        <div className="flex-grow flex justify-end overflow-hidden mb-1">
          <article
            className="w-content overflow-hidden bg-white rounded-lg p-2 shadow-md hover:bg-white"
            style={{ maxWidth: "80%" }}
          >
            {!collapsed && (
              <header className="text-sm text-right">
                <span className="font-semibold text-gray-600 text-xxs">
                  {moment(timestamp).format("LT")}
                </span>
                <span
                  className={`font-bold pl-4 ${accentColor || "text-gray-800"}`}
                >
                  Me
                </span>
              </header>
            )}
            <section className="max-w-full break-words text-sm text-gray-800">
              <p
                dangerouslySetInnerHTML={{
                  __html: renderLinks(message, "text-orange hover:underline"),
                }}
              ></p>
            </section>
          </article>
        </div>
        {collapsed ? (
          <div className="w-8 h-8 ml-2 flex-shrink-0" />
        ) : (
          <Avatar
            className="w-8 h-8 bg-white rounded-full ml-2 flex-shrink-0"
            svg={avatar || null}
          />
        )}
      </div>
    ) : type === "info" ? (
      <div
        className="flex items-start w-full justify-center transition-none transform-none"
        style={style}
        ref={ref}
        onLoad={onLoad}
      >
        <article className="w-content max-w-full overflow-hidden zo-bg-black-transparent rounded-full p-1 mb-1 shadow-md">
          <section className="max-w-full break-words text-sm text-white font-semibold flex items-center">
            <Info className="w-4 h-4 mr-2" />
            {message}
            <span className="font-semibold text-xxs pl-2">
              {moment(timestamp).format("LT")}
            </span>
          </section>
        </article>
      </div>
    ) : null;
  }
);

export default ChatMessageItem;
