import React from "react";
import ProfileFiller from "./components/ProfileFiller";
import Welcome from "./components/Welcome";

interface OnboardingProps {
  fields: string[];
}

const Onboarding: React.FC<OnboardingProps> = ({ fields }) => {
  if (fields.includes("welcome")) {
    return <Welcome />;
  } else {
    return <ProfileFiller field={fields[0]} />;
  }
};

export default Onboarding;
