import React, { useContext, useMemo, useCallback } from "react";

import withMemo from "../../../../../utils/withMemo";
import Chat from "./components/Chat";
import RTCSettings from "./components/RTCSettings";
import TimeExtension from "./components/TimeExtension";
import VotingArea from "./components/VotingArea";
import RoomContext from "../../contexts/room";
import InGameContext from "../../contexts/inGame";

const Footer = withMemo(() => {
  const { room } = useContext(RoomContext);
  const { roomStatus, player } = useContext(InGameContext);

  // const checkIfForceSilent = useCallback(() => {
  //   if (roomRound === "night") {
  //     if (player.role === "mafia") {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   } else {
  //     return false;
  //   }
  // }, [player, roomRound]);

  const isForceMute = useCallback(() => {
    if (player.status !== "spectate") {
      if (roomStatus !== "ended_village" && roomStatus !== "ended_mafia") {
        if (player.status !== "alive") {
          console.log("MUTE not alive");
          return true;
        } else {
          console.log("MUTE alive");
          return false;
        }
      } else {
        console.log("MUTE game ended");
        return false;
      }
    } else {
      console.log("MUTE spectator");
      return true;
    }
  }, [player.status, roomStatus]);

  return useMemo(
    () => (
      <footer className="w-screen z-50 h-auto flex items-end justify-between px-2 pb-2 fixed bottom-0 left-0 pointer-events-none">
        <div className="flex items-end">
          <Chat />
        </div>
        <div className="flex items-end pointer-events-auto">
          {room.status !== "ended_village" && room.status !== "ended_mafia" && (
            <RTCSettings forceMute={isForceMute()} />
          )}
          <TimeExtension />
          <VotingArea />
        </div>
      </footer>
    ),
    [room.status, isForceMute]
  );
}, []);

export default Footer;
