import React, { useContext } from "react";
import PropTypes from "prop-types";
import TutorialContext from "../../contexts/tutorial";
import ZoHeaderUser from "../ZoHeaderUser";

const ZoHeader = ({
  title,
  children,
  titleClassName,
  className,
  titleOnClick,
}) => {
  const { hasTutorial, startTutorial } = useContext(TutorialContext);

  const titleClasses = `relative group flex items-center flex-shrink-0 text-4xl font-bold zo-text-shadow ${
    titleClassName ? titleClassName : ""
  } ${titleOnClick ? "cursor-pointer" : ""}`;
  const classes = `flex items-center justify-between flex-shrink-0 w-full px-4 py-4 landscape:h-24 landscape:px-16 landscape:py-0 border-t-4 ${
    className ? className : ""
  }`;
  return (
    <>
      <header className={classes}>
        {title && (
          <div className={titleClasses} onClick={titleOnClick}>
            <div className="relative">
              {title}
              <div
                className="absolute right-0 bottom-0 zo-text-no-shadow uppercase font-extrablack tracking-wide text-orange"
                style={{ fontSize: "0.6rem" }}
              >
                BETA
              </div>
            </div>
          </div>
        )}
        {hasTutorial && (
          <button
            className="hidden landscape:block bg-gray-900 flex-shrink-0 ml-2 text-xs cursor-pointer text-white font-bold w-6 h-6 flex items-center justify-center rounded-full shadow-landscape hover:shadow-xl"
            onClick={startTutorial}
          >
            ?
          </button>
        )}
        <div className="portrait:hidden landscape:flex items-center justify-between w-full flex-grow px-4">
          {children}
        </div>
        <ZoHeaderUser className="zo-head_user flex-shrink-0" />
      </header>
      <div className="scroll-hidden">
        <div className="portrait:flex landscape:hidden items-center justify-between w-full overflow-y-hidden h-auto overflow-x-auto pb-6 px-4 flex-no-wrap">
          {children}
        </div>
      </div>
    </>
  );
};

ZoHeader.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any,
};

export default ZoHeader;
