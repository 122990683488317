import React, { useState, useEffect, useRef } from "react";

import ArticleModal from "./articleModal";
import Widget from "../../ui/Widget";
import leftArrow from "../../../../../../assets/arrow-left.svg";
import rightArrow from "../../../../../../assets/arrow-right.svg";
import { useSeed } from "../../../contexts/seed";
import "./styles.css";
import useApi from "../../../../../hooks/useApi";

const BlogArticles = (props) => {
  const api = useApi("webapp", false);

  const [isVisible, setVisible] = useState(false);
  const [articleIndex, setArticleIndex] = useState(0);
  const [articles, setArticles] = useState([]);
  const [timer, setTimer] = useState(null);
  const { seedData } = useSeed();
  const articleRef = useRef();
  useEffect(() => {
    if (api) {
      seedData.code
        ? api
            .get(`/api/v1/blogs/destination/${seedData.code}/recent/`)
            .then((res) => {
              setArticles(res.data.blogs);
            })
            .catch((e) => {
              console.log(e);
            })
        : api
            .get("/api/v1/blogs/recent/?count=3")
            .then((res) => {
              setArticles(res.data.blogs);
            })
            .catch((e) => {
              console.log(e);
            });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seedData.code, api]);

  useEffect(() => {
    let mounted = true;
    const children = articleRef.current.children;
    let id = setTimeout(() => {
      const _index = (articleIndex + 1) % (children.length - 1);
      if (mounted) {
        setArticleIndex(_index);
      }
    }, 5000);

    setTimer(id);

    return () => {
      mounted = false;
    };
  }, [articleIndex]);
  return (
    <>
      <Widget
        className="portrait:h-84 outerBox zo-save-bp bg-white cursor-pointer"
        {...props}
      >
        <div
          className="arrow-container flex flex-row w-full absolute mt-20 p-2 h-0 "
          style={{ justifyContent: "space-between", zIndex: 1 }}
        >
          <span
            className="prev rounded-full h-10 w-10 flex items-center justify-center bg-white bg-opacity-50 text-white cursor-pointer"
            onClick={() => {
              setArticleIndex(
                articleIndex >= 1 ? articleIndex - 1 : articles.length - 1
              );
            }}
          >
            <img className="w-12 h-12 text-white" src={leftArrow} alt="" />
          </span>
          <span
            className="next rounded-full h-10 w-10 flex items-center justify-center bg-white bg-opacity-50 text-white cursor-pointer"
            onClick={() => {
              setArticleIndex(
                articleIndex < articles.length - 1 ? articleIndex + 1 : 0
              );
            }}
          >
            <img className="w-12 h-12 text-white" src={rightArrow} alt="" />
          </span>
        </div>
        <div ref={articleRef}>
          {articles.map((art, i) => (
            <div
              className={` absolute flex flex-col justify-end w-full h-full bg-center bg-cover rounded-md rounded-md-b ${
                articleIndex === i
                  ? "opacity-100 pointer-events-auto"
                  : "opacity-0 pointer-events-none"
              }`}
              style={{
                backgroundImage: `url(${
                  art.custom_cover ? art.custom_cover : art.coverImage
                }?w=500&h=500&c=80)`,
              }}
              onClick={() => {
                setVisible(true);
                clearTimeout(timer);
              }}
            >
              <div className="p-2 zo-gradient-black-bottom rounded-md">
                <div className="font-bold text-white">{art.title}</div>
                <span className="text-xs text-gray-100"></span>
              </div>
            </div>
          ))}
        </div>
      </Widget>
      {isVisible && (
        <ArticleModal
          coverImage={
            articles[articleIndex].custom_cover
              ? articles[articleIndex].custom_cover
              : articles[articleIndex].cover_image
          }
          body={articles[articleIndex].content}
          link={articles[articleIndex].link}
          title={articles[articleIndex].title}
          author={articles[articleIndex].author}
          date={articles[articleIndex].time_create}
          graphic_left={seedData.graphic_left}
          graphic_right={seedData.graphic_right}
          background={seedData.background}
          slug ={seedData.slug}
          close={() => {
            setArticleIndex(
              articleIndex < articles.length - 1 ? articleIndex + 1 : 0
            );
            setVisible(false);
          }}
        />
      )}
    </>
  );
};

export default BlogArticles;
