import React, { useContext } from "react";

import NightResultModal from "./modals/NightResultModal";
import PublicHangingModal from "./modals/PublicHangingModal";
import IdentityModal from "./modals/IdentityModal";
import NightModal from "./modals/NightModal";
import DetectiveModal from "./modals/DetectiveModal";
import ModalContext from "../../contexts/modal";
import EndedModal from "./modals/EndedModal";
import EndScreenModal from "./modals/EndScreenModal";
import InGameContext from "../../contexts/inGame";
import VillageRevengeModal from "./modals/VillageRevengeModal";
import MafiaRevealModal from "./modals/MafiaRevealModal";
import VengefulKillModal from "./modals/VengefulKillModal";
import VillagerRevealModal from "./modals/VillagerRevealModal";
import DayModal from "./modals/DayModal";

const GameModals = () => {
  const { roomStatus } = useContext(InGameContext);
  const { modal, setModal } = useContext(ModalContext);

  return roomStatus === "ended_mafia" || roomStatus === "ended_village" ? (
    modal[0] === "ended" ? (
      <EndedModal
        visible
        close={setModal.bind(null, ["end-screen"])}
        type={modal[1]}
      />
    ) : modal[0] === "end-screen" || modal[0] === "detective" ? (
      <EndScreenModal visible close={setModal.bind(null, [])} type={modal[1]} />
    ) : null
  ) : modal[0] === "identity" ? (
    <IdentityModal visible close={setModal.bind(null, [])} card={modal[1]} />
  ) : modal[0] === "night_result" ? (
    <NightResultModal
      visible
      close={setModal.bind(null, [])}
      player={modal[1]}
    />
  ) : modal[0] === "public_hang" ? (
    <PublicHangingModal
      visible
      close={setModal.bind(null, [])}
      player={modal[1]}
    />
  ) : modal[0] === "vengeful_kill" ? (
    <VengefulKillModal
      visible
      close={setModal.bind(null, [])}
      player={modal[1]}
    />
  ) : modal[0] === "villager_reveal" ? (
    <VillagerRevealModal
      visible
      close={setModal.bind(null, [])}
      player={modal[1]}
    />
  ) : modal[0] === "detective" ? (
    <DetectiveModal visible close={setModal.bind(null, [])} player={modal[1]} />
  ) : modal[0] === "night" ? (
    <NightModal visible close={setModal.bind(null, [])} role={modal[1]} />
  ) : modal[0] === "day" ? (
    <DayModal
      visible
      close={setModal.bind(null, [])}
      role={modal[1]}
      extras={modal[2]}
    />
  ) : modal[0] === "village-revenge" ? (
    <VillageRevengeModal visible close={setModal.bind(null, [])} />
  ) : modal[0] === "mafia-reveal" ? (
    <MafiaRevealModal visible close={setModal.bind(null, [])} />
  ) : (
    <></>
  );
};

export default GameModals;
