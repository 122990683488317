import { parsePhoneNumberFromString } from "libphonenumber-js";
import React, { useState } from "react";
import MobileInput from "../../../../../../../../../components/ui/MobileInput";
import useApi from "../../../../../../../hooks/useApi";
import SubSectionRow from "../../../components/SubSectionRow";

const MobileField = ({ loadingContact, mobileFields, setMobileFields }) => {
  const api = useApi("AUTH_CONTACT");
  const verifyOTPApi = useApi("AUTH_VERIFY_OTP");
  const requestOTPApi = useApi("AUTH_REQUEST_OTP");

  const [expanded, setExpanded] = useState(false);

  function handleMobileChange(i, value, country, e, formattedValue) {
    // console.log(value, country, e, formattedValue);
    const formattedNumber = parsePhoneNumberFromString(`+${value}`);
    console.log(formattedNumber);
    const values = [...mobileFields];
    if (formattedNumber) {
      values[i].mobile_country_code = formattedNumber.countryCallingCode;
      values[i].mobile = formattedNumber.nationalNumber;
      setMobileFields(values);
    }
  }

  function handleMobileAdd() {
    const values = [...mobileFields];
    values.push({
      id: Math.random(),
      mobile: "",
      mobile_country_code: "",
      verified: false,
      primary: false,
    });
    setMobileFields(values);
  }

  async function handleMobileRemove(i) {
    const values = [...mobileFields];
    if (values[i].id != null) {
      try {
        await api.delete(`/mobile/${values[i].id}/`);
      } catch (error) {
        console.log(error);
      }
    }
    values.splice(i, 1);
    if (!values.length) {
      values.push({
        mobile: "",
        mobile_country_code: "",
        verified: false,
        primary: false,
      });
    }
    setMobileFields(values);
  }

  async function handleMobileVerify(i) {
    const values = [...mobileFields];
    if (values[i].mobile.length) {
      values[i].sendingOTP = true;
      setMobileFields(values);
      try {
        const data = {
          mobile_country_code: values[i].mobile_country_code,
          mobile: values[i].mobile,
        };
        const response = await requestOTPApi.post(
          "/mobile/",
          JSON.stringify(data)
        );
        if (response.status === 200) {
          values[i].sendingOTP = false;
          values[i].verifying = true;
          setMobileFields([...values]);
        }
      } catch (error) {
        console.log(error);
        values[i].verifying = false;
        setMobileFields([...values]);
      }

      values[i].verifying = true;
      setMobileFields(values);
    }
  }

  function handleMobileOTPChange(i, event) {
    const values = [...mobileFields];
    values[i].otp = event.target.value;
    setMobileFields(values);
  }

  async function handleMobileOTPSubmit(i, e) {
    e.preventDefault();
    const values = [...mobileFields];
    if (values[i].otp.length === 6) {
      values[i].checkingOTP = true;
      setMobileFields(values);
      try {
        const data = {
          mobile_country_code: values[i].mobile_country_code,
          mobile: values[i].mobile,
          otp: values[i].otp,
        };
        const response = await verifyOTPApi.post(
          "/mobile/",
          JSON.stringify(data)
        );
        console.log(response);
        if (response.status === 200) {
          const values = [...mobileFields];
          values[i] = response.data;
          setMobileFields([...values]);
        }
      } catch (error) {
        console.log(error);
      }
      values[i].checkingOTP = false;
      values[i].verifying = false;
      setMobileFields([...values]);
    }
  }

  async function handleMobilePrimary(i) {
    const values = [...mobileFields];
    if (!values[i].primary) {
      values[i].makingPrimary = true;
      setMobileFields(values);
      try {
        const data = {
          mobile: {
            id: values[i].id,
            primary: true,
          },
        };
        console.log(data);
        const response = await api.put("", JSON.stringify(data));
        if (response.status === 200) {
          const values = [...mobileFields];
          values.forEach((v, _i) => {
            if (i === _i) {
              values[i].primary = true;
            } else {
              values[i].primary = false;
            }
          });
          values[i].makingPrimary = false;
          setMobileFields([...values]);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <SubSectionRow
      title="Mobile"
      visibleArea={
        loadingContact
          ? "Fetching mobile information..."
          : Array.isArray(mobileFields) &&
            mobileFields.length > 0 &&
            mobileFields[0].mobile && (
              <ul>
                {mobileFields.map((e) => {
                  if (e.mobile && e.mobile_country_code) {
                    const formattedNumber = parsePhoneNumberFromString(
                      `+${e.mobile_country_code}${e.mobile}`
                    );
                    return (
                      <li key={e.id}>
                        {formattedNumber.formatInternational()}
                        {e.primary && <strong> (primary)</strong>}
                      </li>
                    );
                  } else {
                    return <li></li>;
                  }
                })}
              </ul>
            )
      }
      placeholder="Add an mobile address"
      isExpanded={expanded}
      setExpanded={setExpanded}
    >
      <div className="w-full">
        <div className="flex flex-col w-full flex-col items-start">
          {mobileFields.map((field, idx) => {
            return (
              <div
                key={`${field}-${idx}`}
                className="flex w-full flex-wrap items-center mb-2 portrait:my-2 group"
              >
                <MobileInput
                  className="zo-phone portrait:mb-2 portrait:w-full"
                  value={`${field.mobile_country_code}${field.mobile}`}
                  placeholder="+91 XXXXX-XXXXX"
                  onChange={(value) => handleMobileChange(idx, value)}
                  disabled={field.verified || field.verifying}
                />
                <div className="relative flex items-center">
                  {field.verifying ? (
                    <form
                      className="flex items-center absolute portrait:relative z-10"
                      onSubmit={(e) => {
                        if (!field.checkingOTP) {
                          handleMobileOTPSubmit(idx, e);
                        } else {
                          e.preventDefault();
                        }
                      }}
                    >
                      <input
                        type="number"
                        className="form-input mx-2 portrait:mx-0 portrait:mr-2 portrait:w-3/5"
                        placeholder="Enter OTP"
                        value={field.otp}
                        onChange={(e) => handleMobileOTPChange(idx, e)}
                      />
                      <button
                        type="submit"
                        className="bg-orange text-white px-4 py-3 portrait:px-0 portrait:w-2/5 portrait:flex portrait:justify-center text-sm ml-2 rounded-lg leading-none hover:shadow-sm font-medium"
                      >
                        {field.checkingOTP ? "Verifying" : "Submit"}
                      </button>
                    </form>
                  ) : (
                    <>
                      {field.verified ? (
                        <>
                          <div className="text-sm text-orange ml-2 text-gray-700 font-medium border-orange border p-2 leading-none rounded-lg">
                            Verified
                          </div>
                          {field.primary ? (
                            <div className="text-sm text-gray-800 ml-2 text-gray-700 font-medium border-gray-800 border p-2 leading-none rounded-lg">
                              Primary
                            </div>
                          ) : (
                            <button
                              className="text-sm font-medium ml-4 leading-none opacity-100 hover:underline"
                              onClick={() => {
                                if (!field.makingPrimary) {
                                  handleMobilePrimary(idx);
                                }
                              }}
                            >
                              {field.makingPrimary
                                ? "Making Primary"
                                : "Make primary"}
                            </button>
                          )}
                        </>
                      ) : (
                        <button
                          className="bg-orange text-white px-4 py-3 text-sm ml-2 rounded-lg leading-none hover:shadow-sm font-medium"
                          onClick={() => {
                            if (!field.sendingOTP) {
                              handleMobileVerify(idx);
                            }
                          }}
                        >
                          {field.sendingOTP ? "Sending OTP" : "Verify"}
                        </button>
                      )}
                      {!field.primary && (
                        <button
                          className="ml-4 opacity-100"
                          onClick={() => handleMobileRemove(idx)}
                        >
                          <svg
                            className="fill-current text-red-500"
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                          </svg>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <button
          className="text-sm text-gray-700 font-medium hover:underline my-2"
          onClick={handleMobileAdd}
        >
          + Add another mobile address
        </button>
        <button
          className="block bg-white text-orange cursor-pointer mt-2 mb-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
          onClick={setExpanded.bind(null, false)}
        >
          OK
        </button>
      </div>
    </SubSectionRow>
  );
};

export default MobileField;
