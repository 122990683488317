import React from "react";

const Toggle = ({ value, onChange, options }) => {
  return (
    <div className="flex items-center rounded-lg overflow-hidden">
      {options.map((op) => (
        <button
          key={op.id}
          className={`shadow-inner text-sm disabled:cursor-not-allowed focus:outline-none p-2 leading-none ${
            value === op.id
              ? "bg-orange shadow-none text-white"
              : "bg-gray-200 text-gray-700"
          }`}
          disabled={value === op.id}
          onClick={onChange.bind(null, op.id)}
        >
          {op.value}
        </button>
      ))}
    </div>
  );
};

export default Toggle;
