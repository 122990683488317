import React, { useState, useEffect } from "react";

// import errorIcon from "./../../../assets/error.svg";

const DOBInput = React.forwardRef((props, ref) => {
  const [d, setD] = useState("");
  const [m, setM] = useState("");
  const [y, setY] = useState("");
  const [initial, setInitial] = useState(true);

  const handleDateChange = (e) => {
    if (e.target.value) {
      const _day = parseInt(e.target.value);
      if (_day > 0 && _day < 32) {
        setD(_day);
      }
    } else {
      setD(e.target.value);
    }
  };

  const handleMonthChange = (e) => {
    if (e.target.value) {
      const _month = parseInt(e.target.value);
      if (_month > 0 && _month < 13) {
        setM(_month);
      }
    } else {
      setM(e.target.value);
    }
  };
  const handleYearChange = (e) => {
    if (e.target.value) {
      const _year = parseInt(e.target.value);
      if (_year <= new Date().getFullYear()) {
        setY(_year);
      }
    } else {
      setY(e.target.value);
    }
  };

  const verifyDOB = () => {
    if (y && m && d) {
      const _y = parseInt(y);
      const _d = parseInt(d);
      const _m = parseInt(m);
      if (_y >= 1900 && _y <= new Date().getFullYear()) {
        //check month
        if (_m >= 1 && _m <= 12) {
          //check da_ys
          if (
            _d >= 1 &&
            _d <= 31 &&
            (_m === 1 ||
              _m === 3 ||
              _m === 5 ||
              _m === 7 ||
              _m === 8 ||
              _m === 10 ||
              _m === 12)
          ) {
            console.log(`${_y}-${_m}-${_d}`);
            props.onChange({ target: { value: `${_y}-${_m}-${_d}` } });
          } else if (
            _d >= 1 &&
            _d <= 30 &&
            (_m === 4 || _m === 6 || _m === 9 || _m === 11)
          ) {
            console.log(`${_y}-${_m}-${_d}`);
            props.onChange({ target: { value: `${_y}-${_m}-${_d}` } });
          } else if (_d >= 1 && _d <= 28 && _m === 2) {
            console.log(`${_y}-${_m}-${_d}`);
            props.onChange({ target: { value: `${_y}-${_m}-${_d}` } });
          } else if (
            _d === 29 &&
            _m === 2 &&
            (_y % 400 === 0 || (_y % 4 === 0 && _y % 100 !== 0))
          ) {
            console.log(`${_y}-${_m}-${_d}`);
            props.onChange({ target: { value: `${_y}-${_m}-${_d}` } });
          } else setD("");
        } else {
          setM("");
        }
      } else {
        setY("");
      }
    }
  };

  useEffect(() => {
    let mounted = true;
    if (initial && props.initialValue) {
      if (props.initialValue.split("-").length === 3) {
        if (mounted) {
          setD(props.initialValue.split("-")[2]);
          setM(props.initialValue.split("-")[1]);
          setY(props.initialValue.split("-")[0]);
          setInitial(false);
        }
      }
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValue]);

  return (
    <div className="flex justify-between">
      <div className="mr-1">
        {/* <div className='text-xs'>{'Day'}</div> */}
        <input
          type="number"
          className="form-input w-16"
          placeholder="Date"
          value={d}
          onChange={handleDateChange}
          onBlur={verifyDOB}
        />
      </div>

      <div className="mr-1">
        {/* <label>{'Month'}</label> */}
        <input
          type="number"
          className="form-input w-16"
          placeholder="Month"
          value={m}
          onChange={handleMonthChange}
          onBlur={verifyDOB}
        />
      </div>

      <div className="">
        {/* <label>{'Year'}</label> */}
        <input
          type="number"
          className="form-input w-20"
          placeholder="Year"
          value={y}
          onChange={handleYearChange}
          onBlur={verifyDOB}
        />
      </div>
    </div>
  );
});

export default DOBInput;
