import { createContext } from "react";

const TimeContext = createContext({
  gameTime: 0,
  roundTime: 10,
  gameOver: false,
  roundStartTime: null,
  roundTimeLeft: null,
  roundTimeSpeed: 1,
  setGameTime: (gameTime) => {},
  setRoundTime: (roundTime) => {},
  setGameOver: () => {},
  setRoundStartTime: (roundStartTime) => {},
  setRoundTimeLeft: (roundTimeLeft) => {},
  setRoundTimeSpeed: (roundTimeSpeed) => {},
  setGameStartTime: (gameStartTime) => {},
});

export default TimeContext;
