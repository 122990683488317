import React, { useState } from "react";
import SorryModal from "../../../Zo/components/modals/Sorry";
import VisaModal from "../../../Zo/components/modals/Visa";

const ReferralBlocker = ({ goBackOnClose, customClose }) => {
  const [modal, setModal] = useState("sorry");

  const close = () => {
    if (goBackOnClose) {
      window.history.back();
    } else {
      setModal("");
      customClose();
    }
  };

  return modal === "sorry" ? (
    <SorryModal
      close={close}
      visa={setModal.bind(null, "visa")}
      goBackOnClose
    />
  ) : modal === "visa" ? (
    <VisaModal
      close={close}
      email={setModal.bind(null, "email")}
      goBackOnClose
    />
  ) : null;
};

export default ReferralBlocker;
