import Axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReferralBlocker from "../../../../../../components/apps/CommonRoom/components/ReferralBlocker";
import { useAuth, useProfile, useRTC } from "../../../../contexts";
import useApi from "../../../../hooks/useApi";
import useResponseFlash from "../../../../hooks/useResponseFlash";
import zobu from "../../assets/zobu.svg";
import RTCSettings from "../../components/Footer/components/RTCSettings";
import TravelPortalOptions from "../../components/TravelPortalOptions";
import GameContext from "../../contexts/game";
import RoomContext from "../../contexts/room";
import SocketContext from "../../contexts/socket";
import PlayerCard from "../Intro/components/PlayerCard";
import detectiveIcon from "./../../assets/icon-detective.svg";
import healerIcon from "./../../assets/icon-healer.svg";
import mafiaIcon from "./../../assets/icon-mafia.svg";
import villagerIcon from "./../../assets/icon-villager-boy-2.svg";
import HostRoom from "./components/HostRoom";

// import useAudio from "../hooks/useAudio";
// import joiningBgAudio from "./../../assets/sounds/joining-modal-background.mp3";
// import joiningPlayerAudio from "./../../assets/sounds/joining-modal-new-join.wav";

const copyReferralLink = (link) => {
  const textArea = document.createElement("textarea");
  textArea.value = link;
  textArea.style.position = "fixed";
  textArea.style.top = "-400vh";
  textArea.style.left = "0";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  textArea.setSelectionRange(0, 99999);
  document.execCommand("copy");
  textArea.remove();
};

const JoiningModal = ({ showGame }) => {
  // const { start: playBackgroundAudio } = useAudio(joiningBgAudio, true, 0.1);
  // const { start: playPlayerJoin } = useAudio(joiningPlayerAudio, false);
  const gamesApi = useApi("GAMES_MAFIA");
  const { playersStates, player, update } = useContext(GameContext);
  const { room, setRoom } = useContext(RoomContext);
  const { message, sendSocketMessage } = useContext(SocketContext);
  const {
    peers,
    isSelfMute,
    isActive,
    setIsSelfMute,
    setDisplayName,
    setRoomCode,
    setRecordName,
    setUserCode,
  } = useRTC();
  const { isLoggedIn } = useAuth();
  const { profile } = useProfile();
  const { response, setResponse } = useResponseFlash();

  const [hostRoomVisibility, setHostRoomVisibility] = useState(false);
  const [referralBlockerVisibility, setReferralBlockerVisibility] =
    useState(false);
  const [loadingJoining, setLoadingJoining] = useState(false);

  useEffect(() => {
    // playBackgroundAudio();
    const _ps = {};
    room.players.forEach((_p) => {
      _ps[_p.id] = {
        avatar: zobu,
        ..._p,
        role: "",
        status: "alive",
        connected: true,
        vote: null,
      };
    });
    update(["PLAYERS_STATES", _ps]);
    const _player = room.players.find((p) => player && p.id === player.id);
    if (!_player && player.status !== "spectate") {
      sendSocketMessage("player_joined", { ...player });
    }
    room.players.forEach((_p) => {
      if (_p.passport_id) {
        Axios.get(
          `https://profile.${process.env.REACT_APP_API_DOMAIN}/profile/api/v1/profile/lobby/${_p.passport_id}/`
        )
          .then((res) => {
            if (res.status === 200) {
              if (res.data.profile && res.data.profile.avatar_url) {
                update([
                  "PLAYER_AVATAR",
                  {
                    id: _p.id,
                    avatar: res.data.profile.avatar_url,
                  },
                ]);
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (message && message.action) {
      if (message.action === "player_joined") {
        handlePlayer(message.payload);
      } else if (message.action === "player_left") {
        handlePlayerLeave(message.payload);
      } else if (message.action === "room_status") {
        if (message.payload.status === "started") {
          showGame();
        }
      } else if (message.action === "room_update") {
        setRoom(message.payload);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  function handlePlayer(_player) {
    if (!Object.values(playersStates).find((p) => p.id === _player.id)) {
      // playPlayerJoin();
      update([
        "PLAYER_ADD",
        {
          avatar: zobu,
          ..._player,
          role: "",
          status: "alive",
          connected: true,
          vote: null,
        },
      ]);
      if (_player.passport_id) {
        Axios.get(
          `https://profile.${process.env.REACT_APP_API_DOMAIN}/profile/api/v1/profile/lobby/${_player.passport_id}/`
        )
          .then((res) => {
            if (res.status === 200) {
              if (res.data.profile && res.data.profile.avatar_url) {
                update([
                  "PLAYER_AVATAR",
                  {
                    id: _player.id,
                    avatar: res.data.profile.avatar_url,
                  },
                ]);
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }

  function handlePlayerLeave(_player) {
    if (Object.values(playersStates).find((p) => p.id === _player.id)) {
      // playPlayerJoin();
      update([
        "PLAYER_REMOVE",
        {
          ..._player,
        },
      ]);
    }
  }

  const handleLeave = async () => {
    if (player && player.id != null) {
      sendSocketMessage("player_left", { ...player });
      const anon_user_token = localStorage.getItem("anon_user_token");
      const anon_user = localStorage.getItem("anon_user");
      const anon_token_expiry = localStorage.getItem("anon_token_expiry");
      const anon_nickname = localStorage.getItem("anon_nickname");
      if (isLoggedIn) {
        try {
          const response = await gamesApi.post(
            `/leave/${room.code}/`,
            JSON.stringify({
              id: player.id,
            })
          );
          console.log(response);
          if (response.status === 200) {
            window.open(
              `https://mafia.${process.env.REACT_APP_ROOT_DOMAIN}`,
              "_parent",
              ""
            );
          }
        } catch (error) {
          console.log(error.response);
        }
      } else if (
        anon_nickname &&
        anon_token_expiry &&
        anon_user &&
        anon_user_token &&
        JSON.parse(anon_user) &&
        JSON.parse(anon_user).app_id &&
        JSON.parse(anon_user).user_id
      ) {
        try {
          const response = await Axios.post(
            `https://socket.${process.env.REACT_APP_API_DOMAIN}/games/api/v1/mafia/leave/${room.code}/`,
            JSON.stringify({
              id: player.id,
            }),
            {
              headers: {
                Authorization: "Bearer " + anon_user_token,
                "Content-Type": "application/json",
                "Client-App-Id": JSON.parse(anon_user).app_id,
                "Client-User-Id": JSON.parse(anon_user).user_id,
              },
            }
          );
          console.log(response);
          if (response.status === 200) {
            window.open(
              `https://mafia.${process.env.REACT_APP_ROOT_DOMAIN}`,
              "_parent",
              ""
            );
          }
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      window.open(
        `https://mafia.${process.env.REACT_APP_ROOT_DOMAIN}`,
        "_parent",
        ""
      );
    }
  };

  const copyCode = () => {
    copyReferralLink(
      `https://playmafia.${process.env.REACT_APP_ROOT_DOMAIN}/r/${room.code}`
    );
    setResponse("Link copied!");
  };

  const handleJoin = async () => {
    const anon_user_token = localStorage.getItem("anon_user_token");
    const anon_user = localStorage.getItem("anon_user");
    const anon_token_expiry = localStorage.getItem("anon_token_expiry");
    const anon_nickname = localStorage.getItem("anon_nickname");
    if (isLoggedIn) {
      if (profile && profile.status !== "unfriendly") {
        setLoadingJoining(true);
        try {
          const response = await gamesApi.post(
            `/join/${room.code}/`,
            JSON.stringify({
              nickname: profile.nickname,
            })
          );
          console.log(response);
          if (response.status === 200) {
            const _player = { ...response.data.player };
            if (_player.id) {
              update(["PLAYER", _player]);
              const _playerFound = Object.values(playersStates).find(
                (p) => player && p.passport_id === player.passport_id
              );
              if (!_playerFound) {
                sendSocketMessage("player_joined", { ..._player });
              }
              setLoadingJoining(false);
            } else {
              update(["PLAYER", null]);
              setLoadingJoining(false);
            }
          }
        } catch (error) {
          console.log(error.response);
        }
      } else {
        setReferralBlockerVisibility(true);
      }
    } else if (
      anon_nickname &&
      anon_token_expiry &&
      anon_user &&
      anon_user_token &&
      JSON.parse(anon_user) &&
      JSON.parse(anon_user).app_id &&
      JSON.parse(anon_user).user_id
    ) {
      try {
        const response = await Axios.post(
          `https://socket.${process.env.REACT_APP_API_DOMAIN}/games/api/v1/mafia/join/${room.code}/`,
          JSON.stringify({ nickname: anon_nickname }),
          {
            headers: {
              Authorization: "Bearer " + anon_user_token,
              "Content-Type": "application/json",
              "Client-App-Id": JSON.parse(anon_user).app_id,
              "Client-User-Id": JSON.parse(anon_user).user_id,
            },
          }
        );
        console.log(response);
        if (response.status === 200) {
          const _player = { ...response.data.player };
          if (_player.id) {
            update(["PLAYER", _player]);
            const _playerFound = Object.values(playersStates).find(
              (p) => player && p.passport_id === player.passport_id
            );
            if (!_playerFound) {
              sendSocketMessage("player_joined", { ..._player });
            }
          } else {
            update(["PLAYER", null]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (player && player.nickname && room && room.code) {
      console.log("DEG", player, room);
      setRoomCode(room.code);
      setUserCode(player.passport_id || player.auth_user_id);
      setDisplayName(player.nickname);
      setRecordName(
        process.env.NODE_ENV === "production" ? `mafia/${room.code}` : false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player, room]);

  useEffect(() => {
    if (player) {
      if (player.status === "spectate") {
        setIsSelfMute(true);
      }
      if (player.status === "alive") {
        setIsSelfMute(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  // function handleReady(id) {
  // update(["PLAYERS_STATES", ((_ps) => {
  //   _ps[id].connected = true;
  //   return { ..._ps };
  // });
  // }

  // const sendReady = () => {
  // sendSocketMessage("READY", "ALL", { id: player.id });
  // };

  const handleStart = () => {
    sendSocketMessage("room_status", {
      game_code: room.code,
      status: "started",
    });
  };

  const filterSpectators = useCallback(
    (_peer) =>
      Object.values(playersStates)
        .map((_player) => _player.nickname)
        .indexOf(_peer.display) === -1,
    [playersStates]
  );

  const spectators = useMemo(() => {
    const s = peers.filter(filterSpectators);
    const uniqueS = [];
    if (player.status === "spectate") {
      s.unshift({ display: player.nickname, id: "spec123" });
    }
    s.forEach((ss) => {
      if (uniqueS.indexOf(ss.display) === -1) {
        uniqueS.push(ss.display);
      }
    });
    return uniqueS;
  }, [filterSpectators, peers, player]);

  const isJoiningAllowed = useMemo(() => {
    if (!room.tournament) {
      return true;
    } else {
      if (room.qualifiers && room.qualifiers.length) {
        return room.qualifiers.indexOf(player.passport_id) !== -1;
      } else {
        return false;
      }
    }
  }, [player, room]);

  const isHost = useMemo(() => {
    return +Object.keys(playersStates)[0] === player.id;
  }, [playersStates, player]);

  return (
    <section className="w-screen h-screen fixed inset-0 z-40 flex items-center justify-center">
      <div
        className="border-t-4 border-mafia-secondary rounded-lg shadow-lg bg-mafia-primary overflow-hidden flex flex-col items-center"
        style={{ width: "60rem" }}
      >
        <div className="w-full flex-shrink-0 p-4 flex items-center justify-between border-b-2 border-mafia-background">
          <div className="w-1/4 flex flex-col justify-center items-start">
            <span className="text-xs">Invite your friends</span>
            <div className="flex items-center mt-2">
              <span
                className="border-mafia-secondary cursor-pointer p-2 mr-2 leading-none border border-dashed rounded-lg font-bold text-xl tracking-wider text-mafia-secondary"
                title={response || "Click to copy link"}
                onClick={copyCode}
              >
                {room.code}
              </span>
              <a
                href={`https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  `https://playmafia.${process.env.REACT_APP_ROOT_DOMAIN}/r/${room.code}`
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on Facebook"
              >
                <svg
                  enableBackground="new 0 0 24 24"
                  className="fill-current text-white mr-2 w-5 h-5 cursor-pointer"
                  height="512"
                  viewBox="0 0 24 24"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z" />
                </svg>
              </a>
              <a
                href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                  `Join *${
                    room.name
                  }* on Zo World. ${`https://playmafia.${process.env.REACT_APP_ROOT_DOMAIN}/r/${room.code}`}`
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on WhatsApp"
              >
                <svg
                  className="fill-current text-white w-5 h-5 cursor-pointer"
                  enableBackground="new 0 0 24 24"
                  height="512"
                  viewBox="0 0 24 24"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m17.507 14.307-.009.075c-2.199-1.096-2.429-1.242-2.713-.816-.197.295-.771.964-.944 1.162-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.293-.506.32-.578.878-1.634.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.576-.05-.997-.042-1.368.344-1.614 1.774-1.207 3.604.174 5.55 2.714 3.552 4.16 4.206 6.804 5.114.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345z" />
                  <path d="m20.52 3.449c-7.689-7.433-20.414-2.042-20.419 8.444 0 2.096.549 4.14 1.595 5.945l-1.696 6.162 6.335-1.652c7.905 4.27 17.661-1.4 17.665-10.449 0-3.176-1.24-6.165-3.495-8.411zm1.482 8.417c-.006 7.633-8.385 12.4-15.012 8.504l-.36-.214-3.75.975 1.005-3.645-.239-.375c-4.124-6.565.614-15.145 8.426-15.145 2.654 0 5.145 1.035 7.021 2.91 1.875 1.859 2.909 4.35 2.909 6.99z" />
                </svg>
              </a>
            </div>
          </div>
          <div className="w-2/4 text-xl font-bold tracking-wide uppercase justify-center flex flex-col items-center">
            <span className="text-sm text-mafia-secondary flex items-center">
              {room.name}
              {room.tournament ? (
                <span className="border-mafia-secondary text-mafia-secondary border-2 ml-2 font-bold uppercase tracking-wide p-1 text-sm leading-none rounded-lg whitespace-no-wrap">
                  Season Finals
                </span>
              ) : (
                room.ranked_game && (
                  <span className="border-mafia-secondary text-mafia-secondary border-2 ml-2 font-bold uppercase tracking-wide p-1 text-sm leading-none rounded-lg whitespace-no-wrap">
                    Ranked Game
                  </span>
                )
              )}
              {isHost && (
                <svg
                  className="fill-current text-white ml-2 cursor-pointer w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  onClick={setHostRoomVisibility.bind(null, true)}
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z" />
                </svg>
              )}
            </span>
            <span className="mt-2 leading-none font-bold text-xl tracking-wider">
              The Village is Populating
            </span>
          </div>
          <div className="w-1/4 flex items-center justify-end">
            <button
              className="rounded-lg p-3 leading-none bg-mafia-background font-semibold hover:shadow-lg"
              onClick={handleLeave}
            >
              Leave Room
            </button>
          </div>
        </div>
        <div
          className="w-full overflow-y-auto overflow-x-hidden flex-grow px-1"
          style={{ maxHeight: "27.8rem" }}
        >
          {Object.values(playersStates).length > 0 ? (
            Object.values(playersStates).map((_player, i) => {
              const connected =
                _player.nickname === player.nickname
                  ? peers.find((p) => p.display === _player.nickname) ||
                    isActive
                  : peers.find((p) => p.display === _player.nickname);

              const speaking = connected
                ? connected.status === "talking"
                  ? true
                  : false
                : false;

              const muted =
                _player.nickname === player.nickname
                  ? isSelfMute
                  : connected
                  ? connected.muted
                  : false;

              return (
                <PlayerCard
                  className="w-1/2"
                  isUser={_player.id === player.id}
                  nickname={_player.nickname}
                  key={_player.id}
                  avatar={_player.avatar}
                  ready={_player.connected}
                  isHost={i === 0}
                  speaking={speaking}
                  connected={connected}
                  muted={muted}
                />
              );
            })
          ) : (
            <div className=" w-full h-32 shadow-inner font-semibold flex items-center justify-center text-lg text-white">
              The room is empty
            </div>
          )}
        </div>
        <div className="w-full flex justify-between items-center h-auto py-2 px-2 mt-2 mx-2 border-t-2 border-mafia-background flex-shrink-0">
          <div className="flex items-center text-white px-1 cursor-default">
            <div>
              Total Population: <strong>{room.players_count}</strong>
            </div>
            <span title="Mafia(s)" className="ml-8 flex items-center">
              <img className="w-5 h-5 mr-2" src={mafiaIcon} alt="M" />{" "}
              <strong>{room.mafia_count + room.mafia_semi_count}</strong>
            </span>
            <span title="Detective(s)" className="ml-8 flex items-center">
              <img className="w-5 h-5 mr-2" src={detectiveIcon} alt="D" />{" "}
              <strong>{room.detective_count}</strong>
            </span>
            <span title="Healer(s)" className="ml-8 flex items-center">
              <img className="w-5 h-5 mr-2" src={healerIcon} alt="H" />{" "}
              <strong>{room.healer_count}</strong>
            </span>
            <span title="Villager(s)" className="ml-8 flex items-center">
              <img className="w-5 h-5 mr-2" src={villagerIcon} alt="V" />{" "}
              <strong>{room.villager_count + room.villager_save_count}</strong>
            </span>
          </div>
          {peers && (
            <div className="relative group mx-4 flex items-center flex-col cursor-default">
              <div className="flex items-center">
                <svg
                  className="fill-current mr-2 w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M12 4C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                </svg>
                <strong>{spectators.length}</strong>
              </div>
              <ul className="opacity-0 absolute bottom-full right-0 w-content group-hover:opacity-75 text-xs bg-mafia-background rounded-lg shadow-xl z-20">
                {spectators.map((p) => (
                  <li key={p} className="mx-2 mt-2 last:mb-2">
                    {p}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <RTCSettings />
          {Object.values(playersStates).length === room.players_count ? (
            isHost ? (
              <div
                className="bg-mafia-secondary font-semibold text-mafia-background px-4 py-3 text-xl rounded-lg shadow-md cursor-pointer"
                onClick={handleStart}
              >
                Start the game
              </div>
            ) : (
              <div className="font-semibold text-white px-4 py-2 text-xl">
                Waiting for host to start ...
              </div>
            )
          ) : player.status === "spectate" ? (
            <div className="flex items-center">
              <div className="font-semibold text-white px-4 py-2 text-xl mr-2">
                {room.players_count - Object.values(playersStates).length}{" "}
                players left
              </div>
              {isJoiningAllowed && (
                <button
                  className="focus:outline-none bg-mafia-secondary font-semibold text-mafia-background px-4 py-3 text-xl rounded-lg shadow-md cursor-pointer disabled:cursor-wait"
                  onClick={handleJoin}
                  disabled={loadingJoining}
                >
                  {loadingJoining ? "Joining ..." : "Join the game"}
                </button>
              )}
            </div>
          ) : (
            <div className="font-semibold text-white px-4 py-2 text-xl">
              Waiting for{" "}
              {room.players_count - Object.values(playersStates).length} more to
              join ...
            </div>
          )}
        </div>
      </div>
      {hostRoomVisibility && (
        <HostRoom
          sendSocketMessage={sendSocketMessage}
          close={setHostRoomVisibility.bind(null, false)}
        />
      )}
      {referralBlockerVisibility && (
        <ReferralBlocker
          customClose={setReferralBlockerVisibility.bind(null, false)}
        />
      )}
      <TravelPortalOptions visible />
    </section>
  );
};

export default JoiningModal;
