import React, { useContext, useState } from "react";

import SubSectionRow from "../../../components/SubSectionRow";
import ProfileContext from "../../../../../../../contexts/profile";
import {
  useInput,
  useResponseFlash,
} from "../../../../../../Zo/components/hooks";
import SeedContext from "../../../../../../../contexts/seed";
import useApi from "../../../../../../../hooks/useApi";

const GenderField = () => {
  const api = useApi("profile", true);
  const { seedData } = useContext(SeedContext);
  const { profile, updateProfile } = useContext(ProfileContext);
  const { value: gender, bind: bindGender } = useInput(profile.gender || 0);
  const { response, setResponse } = useResponseFlash();

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      try {
        const data = {
          gender: +gender,
        };
        console.log(data);

        const response = await api.post(
          `/profile/api/v1/me/`,
          JSON.stringify(data)
        );
        if (response.status === 200) {
          updateProfile(data);
          setExpanded(false);
        } else {
          setResponse("Error in fields");
        }
      } catch (error) {
        console.log(error.response);
        if (error.response.status === 422) {
          setResponse(error.response.data.errors.nickname[0]);
          setLoading(false);
        } else {
          setResponse("Error in setting the nickname.");
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <SubSectionRow
      title="Gender"
      visibleArea={
        profile.gender != null &&
        seedData.gender &&
        seedData.gender.find((r) => r[0] === profile.gender) &&
        seedData.gender.find((r) => r[0] === profile.gender)[1]
      }
      isExpanded={expanded}
      setExpanded={setExpanded}
    >
      <form className="flex flex-col w-full" onSubmit={handleSubmit}>
        <div className="flex w-full">
          <select
            className="w-1/3 form-select portrait:w-full"
            placeholder="Select your Gender"
            {...bindGender}
          >
            {seedData.gender &&
              seedData.gender.map(([id, sex]) => (
                <option key={id} value={id}>
                  {sex}
                </option>
              ))}
          </select>
        </div>
        <div className="w-full h-6 flex items-center justify-start font-semibold text-xs text-orange">
          {response}
        </div>
        <div className="flex mb-4 w-full">
          <button
            className="bg-orange text-white cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            type="submit"
          >
            {loading ? "Saving" : "Save"}
          </button>
          <button
            className="bg-white text-orange cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            onClick={setExpanded.bind(null, false)}
          >
            Cancel
          </button>
        </div>
      </form>
    </SubSectionRow>
  );
};

export default GenderField;
