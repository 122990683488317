import Lottie from "lottie-react";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useProfile } from "../../..";
import Portal from "../../../../../../components/ui/Portal";
import { Info, SmartToy } from "../../../../assets/icons";
import Flex from "../../../../components/structure/Flex";
import useApi from "../../../../hooks/useApi";
import loadingRoom from "./../../../../assets/lottie/loading-room.json";

interface LoadingEntryProps {
  phase: "entry" | "check" | "kicked" | "connecting" | "error";
  room: Room | null;
  close: (data: GeneralObject) => void;
}

const LoadingEntry: React.FC<LoadingEntryProps> = ({ phase, room, close }) => {
  const { profile } = useProfile();
  const api = useApi("PROFILE_LOBBY");
  const location = useLocation();

  const [blocker, setBlocker] = useState<boolean>(false);

  const passwordToken = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("t");
  }, [location]);

  const security = useMemo(() => room?.security, [room]);
  const subdomain = useMemo(() => room?.subdomain, [room]);
  const nickname = useMemo(() => room?.nickname, [room]);
  const code = useMemo(() => room?.code, [room]);
  const isPremium = useMemo(() => profile?.status !== "premium", [profile]);

  useEffect(() => {
    let mounted = true;

    if (
      phase === "check" &&
      api &&
      security != null &&
      code != null &&
      subdomain != null
    ) {
      let password = passwordToken;
      if (security === 3 || (security === 2 && !isPremium)) {
        if (!password && localStorage.getItem(`entryToken_${code}`)) {
          password = localStorage.getItem(`entryToken_${code}`);
        }
      }
      const data = JSON.stringify({ password });
      api
        .post(`/${subdomain}/login/`, data)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (mounted) {
              localStorage.setItem(`entryToken_${code}`, password || "");
              close(res.data);
            }
          }
        })
        .catch((error) => {
          console.log(error.errorResponse);
          localStorage.removeItem(`entryToken_${code}`);
        })
        .finally(() => {
          if (mounted) {
            setBlocker(true);
          }
        });
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, code, isPremium, passwordToken, phase, security, subdomain]);

  return (
    <Portal>
      <Flex
        items="center"
        justify="center"
        col
        className="fixed inset-0 bg-zo-off-white fade-in"
      >
        {phase === "error" ? (
          <ErrorMessage
            title="404"
            text="Umm... We don't think a room with this link exists."
          />
        ) : phase === "kicked" ? (
          <>
            <SmartToy className="w-12 h-12 text-zo-mid-grey" />
            <span className="px-4 pt-4 font-bold text-lg text-center">
              Umm...
            </span>
            <span className="px-4 pt-2 font-semibold text-center">
              We're not really sure why you're not allowed in this room for the
              next hour but that is what the host wants.
            </span>
            <Link
              to="/@portal"
              className="font-bold text-orange mt-4 text-center"
            >
              Open Travel Portal
            </Link>
          </>
        ) : !blocker ? (
          <>
            <Lottie animationData={loadingRoom} loop className="w-32 h-32" />
            <span className="mt-8 font-bold text-gray-700">
              {phase === "entry"
                ? "Travelling to room"
                : phase === "connecting"
                ? "Connecting to room"
                : phase === "check"
                ? "Entering the room"
                : ""}
            </span>
          </>
        ) : (
          <>
            {security === 3 ? (
              passwordToken ? (
                <ErrorMessage
                  title="Invalid Link"
                  text={`The link has expired or is invalid. Ask ${nickname} for a new link.`}
                />
              ) : (
                <ErrorMessage
                  title="This room is private"
                  text={`You need to have an invite link to enter this room.`}
                />
              )
            ) : security === 2 ? (
              passwordToken ? (
                <ErrorMessage
                  title={`Cannot enter the room`}
                  text={
                    <span className="text-center">
                      Either the link has expired/ is invalid or the host is not
                      present.
                      <br />
                      Buy premium to enter this room without any link.
                    </span>
                  }
                />
              ) : (
                <ErrorMessage
                  title="Cannot enter the room"
                  text={`Either you should be a premium member or have an invite link (Ask ${nickname}) to enter this room.`}
                />
              )
            ) : security === 1 && !isPremium ? (
              <ErrorMessage
                title={`Waiting for ${nickname} to join`}
                text={`You can't access this room if the host is not present.`}
              />
            ) : null}
          </>
        )}
      </Flex>
    </Portal>
  );
};

const ErrorMessage: React.FC<{ title: string; text: any }> = ({
  title,
  text,
}) => {
  return (
    <>
      <Info className="w-12 h-12 text-zo-mid-grey" />
      <span className="px-4 pt-4 font-bold text-lg text-center">{title}</span>
      <span className="px-4 pt-2 font-semibold text-center">{text}</span>
      <Link to="/@portal" className="font-bold text-orange mt-4 text-center">
        Open Travel Portal
      </Link>
    </>
  );
};

export default LoadingEntry;
