import React, { useContext, useState } from "react";
import {
  useInput,
  useResponseFlash,
} from "../../../../../../../../../components/apps/Zo/components/hooks";
import ProfileContext from "../../../../../../../contexts/profile/ProfileContext";
import useApi from "../../../../../../../hooks/useApi";
import SubSectionRow from "../../../components/SubSectionRow";

const getLinkValue = (profile, link) => {
  if (profile) {
    if (profile.socials.find((l) => l.link === link)) {
      return profile.socials.find((l) => l.link === link).value;
    }
  }
  return "";
};

const SocialField = ({
  link,
  title,
  placeholder,
  inputPlaceholder,
  hint,
  validator,
}) => {
  const api = useApi("PROFILE_ME");
  const { profile, updateProfile } = useContext(ProfileContext);
  const { value: linkValue, bind: bindLinkValue } = useInput(
    getLinkValue(profile, link) || ""
  );
  const { response, setResponse } = useResponseFlash();

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (linkValue === "" || validator.test(linkValue)) {
      if (!loading && api) {
        setLoading(true);
        try {
          const data = {
            socials: [{ link, value: linkValue }],
          };
          console.log(data);

          const response = await api.post(``, JSON.stringify(data));
          if (response.status === 200) {
            updateProfile(response.data.profile);
            setExpanded(false);
          } else {
            setResponse("Error in fields");
          }
        } catch (error) {
          console.log(error.response);
          setResponse("Error in setting the link.");
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }
    } else {
      setResponse("Invalid value.");
    }
  };

  return (
    <SubSectionRow
      title={title}
      visibleArea={getLinkValue(profile, link)}
      placeholder={placeholder}
      isExpanded={expanded}
      setExpanded={setExpanded}
    >
      <form className="flex flex-col w-full" onSubmit={handleSubmit}>
        <span className="text-sm text-gray-700 mb-2 block">{hint}</span>
        <div className="flex items-center w-full">
          <input
            className="w-full mr-2 form-input portrait:w-full portrait:mr-0"
            type="text"
            placeholder={inputPlaceholder}
            {...bindLinkValue}
          />
        </div>
        <div className="w-full h-6 flex items-center justify-start font-semibold text-xs text-orange">
          {response}
        </div>
        <div className="flex mb-4 w-full">
          <button
            className="bg-orange text-white cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            type="submit"
          >
            {loading ? "Saving" : "Save"}
          </button>
          <button
            className="bg-white text-orange cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            onClick={setExpanded.bind(null, false)}
          >
            Cancel
          </button>
        </div>
      </form>
    </SubSectionRow>
  );
};

export default SocialField;
