import React, { useMemo, useEffect, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";

import ZoHeader from "./../../../../ui/ZoHeader";
import Population from "./components/Population";
import Countries from "./components/Countries";
import Calendar from "./components/Calendar";
import Happiness from "./components/Happiness";
import Live from "./components/Live";
import { useSeed } from "./../../contexts/seed";
import Economy from "./components/Economy";
import Ratings from "./components/Ratings";
import Leaderboard from "./components/Leaderboard";
import About from "./components/About";
import CountStat from "./components/CountStat";

const getStatComponent = (props) => {
  switch (props.type) {
    case "population":
      return <Population {...props} />;
    case "countries":
      return <Countries {...props} />;
    case "calendar":
      return <Calendar {...props} />;
    case "happiness":
      return <Happiness {...props} />;
    case "rating":
      return <Ratings {...props} />;
    case "live":
      return <Live {...props} />;
    case "economy":
      return <Economy {...props} />;
    case "leaderboard":
      return <Leaderboard {...props} />;
    case "about":
      return <About {...props} />;
    case "count-stat":
      return <CountStat {...props} />;
    default:
      return null;
  }
};

const Header = () => {
  const {
    layout: { header },
    style,
  } = useSeed();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (
      process.env.REACT_APP_ROOT_DOMAIN === "zo.xyz" &&
      window.rudderanalytics
    ) {
      window.rudderanalytics.page();
    }
  }, [location]);

  const getHeaderStats = useCallback(() => {
    const headerStats = [];

    header.stats.forEach((stat) => {
      if (Array.isArray(stat)) {
        const group = [];
        stat.forEach((_s) => {
          const component = getStatComponent(_s);
          if (component) {
            group.push(
              React.cloneElement(component, { key: Math.random(), ..._s })
            );
          }
        });
        headerStats.push(
          <div key={Math.random()} className="flex items-center portrait:mr-4">
            {group}
          </div>
        );
      } else {
        const component = getStatComponent(stat);
        if (component) {
          headerStats.push(
            React.cloneElement(component, { key: Math.random(), ...stat })
          );
        }
      }
    });

    return headerStats;
  }, [header.stats]);

  return useMemo(
    () => (
      <ZoHeader
        title={header.title}
        titleClassName={
          style.textSecondaryColor ? `text-${style.textSecondaryColor}` : ""
        }
        className={`border-${style.accentColor}`}
        titleOnClick={() => {
          history.push("/");
        }}
      >
        <div className="landscape:ml-4" />
        {getHeaderStats()}
      </ZoHeader>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getHeaderStats, header.title, style.accentColor]
  );
};

export default Header;
