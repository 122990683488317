function gameReducer(state, [type, payload]) {
  const prevPlayersState = { ...state.playersStates };
  switch (type) {
    case "room_status":
      return { ...state, roomStatus: payload.status };
    case "room_round":
      return { ...state, roomRound: payload.round };
    case "player_role":
      if (payload.id && prevPlayersState[payload.id] != null) {
        prevPlayersState[payload.id].role = payload.role;
        prevPlayersState[payload.id].role_card = payload.role_card;
        return { ...state, playersStates: prevPlayersState };
      }
      return state;
    case "player_reveal":
      if (payload.id && prevPlayersState[payload.id] != null) {
        prevPlayersState[payload.id].revealed = payload.revealed;
        return { ...state, playersStates: prevPlayersState };
      }
      return state;
    case "player_status":
      if (payload.id && prevPlayersState[payload.id] != null) {
        prevPlayersState[payload.id].status = payload.status;
        return { ...state, playersStates: prevPlayersState };
      }
      return state;
    case "player_vote":
      if (payload.id != null && prevPlayersState[payload.id] != null) {
        prevPlayersState[payload.id].vote = payload.vote;
        return { ...state, playersStates: prevPlayersState };
      }
      return state;
    case "player_avatar":
      if (payload.id && prevPlayersState[payload.id] != null) {
        prevPlayersState[payload.id].avatar = payload.avatar;
        return {
          ...state,
          playersStates: prevPlayersState,
        };
      }
      return state;
    case "player_time_bank":
      if (payload.id && prevPlayersState[payload.id] != null) {
        prevPlayersState[payload.id].timeBankUsed = true;
        return {
          ...state,
          playersStates: prevPlayersState,
        };
      }
      return state;
    default:
      return state;
  }
}

export default gameReducer;
