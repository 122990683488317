import { useContext } from "react";
import { AuthContext } from "./auth";
import { ProfileContext } from "./profile";
import { RoomContext } from "./room";
import { RTCContext } from "./rtc";
import { SeedContext } from "./seed";

export const useAuth = () => useContext(AuthContext);
export const useProfile = () => useContext(ProfileContext);
export const useSeed = () => useContext(SeedContext);
export const useRoom = () => useContext(RoomContext);
export const useRTC = () => useContext(RTCContext);
