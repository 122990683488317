import { createContext } from "react";

const GameContext = createContext({
  player: null,
  roomRound: "",
  roomStatus: "",
  playersStates: {},
  gameTime: 0,
  roundTime: 0,
  update: ([type, payload]) => {},
});

export default GameContext;
