import React, { useState, useContext, useEffect, useMemo } from "react";

import Modal from "./../../../../../../ui/Modal";
import PlayerCount from "./components/PlayerCount";
// import CharacterCount from "./components/CharacterCount";
import useApi from "./../../../../../../hooks/useApi";
import RoomContext from "../../../../contexts/room";
import GameContext from "../../../../contexts/game";
import { useResponseFlash } from "../../../../../../apps/Zo/components/hooks";
import roomsConfig from "./../../../../../../../configs/mafia/rooms.json";

const flattenRoomObject = (roomObject) => {
  const data = { ...roomObject.data };
  delete roomObject.data;
  return { ...roomObject, ...data };
};

const HostRoom = ({ close, sendSocketMessage }) => {
  const { room } = useContext(RoomContext);
  const { playersStates } = useContext(GameContext);
  const api = useApi("games", true);
  const { response, setResponse } = useResponseFlash();

  const [roomName, setRoomName] = useState("Mafia Room");
  const [players, setPlayers] = useState(8);
  const [mafiaCount, setMafiaCount] = useState(2);
  const [mafiaSemiCount, setMafiaSemiCount] = useState(0);
  const [mafiaRevealerCount, setMafiaRevealerCount] = useState(0);
  const [mafiaFlagbearerCount, setMafiaFlagbearerCount] = useState(0);
  const [detectiveCount, setDetectiveCount] = useState(1);
  const [healerCount, setHealerCount] = useState(1);
  const [villagerCount, setVillagerCount] = useState(4);
  const [villagerSaveCount, setVillagerSaveCount] = useState(0);
  const [villagerRevengeCount, setVillagerRevengeCount] = useState(0);
  const [type, setType] = useState("public");
  const [gameFlow, setgameFlow] = useState("initial_night");

  // const [sum, setSum] = useState(8);

  // useEffect(() => {
  //   setSum(
  //     +mafiaCount +
  //       +mafiaSemiCount +
  //       +mafiaRevealerCount +
  //       +mafiaFlagbearerCount +
  //       +detectiveCount +
  //       +healerCount +
  //       +villagerCount +
  //       +villagerSaveCount +
  //       +villagerRevengeCount
  //   );
  // }, [
  //   mafiaCount,
  //   detectiveCount,
  //   healerCount,
  //   villagerCount,
  //   mafiaSemiCount,
  //   villagerSaveCount,
  //   mafiaRevealerCount,
  //   villagerRevengeCount,
  //   mafiaFlagbearerCount,
  // ]);

  useEffect(() => {
    if (room) {
      setMafiaCount(room.mafia_count);
      setMafiaSemiCount(room.mafia_semi_count);
      setMafiaRevealerCount(room.mafia_revealer_count);
      setMafiaFlagbearerCount(room.mafia_flagbearer_count);
      setDetectiveCount(room.detective_count);
      setHealerCount(room.healer_count);
      setVillagerCount(room.villager_count);
      setVillagerSaveCount(room.villager_save_count);
      setVillagerRevengeCount(room.villager_revenge_count);
      setPlayers(room.players_count);
      setType(room.type);
      setRoomName(room.name);
      setgameFlow(room.game_flow);
    }
  }, [room]);

  useEffect(() => {
    if (roomsConfig[players]) {
      const counts = roomsConfig[players];
      setMafiaCount(counts.mafiaCount);
      setMafiaSemiCount(counts.mafiaSemiCount);
      setMafiaRevealerCount(counts.mafiaRevealerCount);
      setMafiaFlagbearerCount(counts.mafiaFlagbearerCount);
      setDetectiveCount(counts.detectiveCount);
      setHealerCount(counts.healerCount);
      setVillagerCount(counts.villagerCount);
      setVillagerSaveCount(counts.villagerSaveCount);
      setVillagerRevengeCount(counts.villagerRevengeCount);
      setgameFlow(counts.gameFlow);
    }
  }, [players]);

  const getDisabledPlayerCounts = () => {
    const disabledOptions = [];
    for (let index = 6; index <= 12; index++) {
      if (index < Object.keys(playersStates).length) {
        disabledOptions.push(index);
      }
    }

    return disabledOptions;
  };

  const configDisplay = useMemo(() => {
    const config = {};
    if (mafiaCount) {
      config["Mafia"] = mafiaCount;
    }
    if (mafiaSemiCount) {
      config["Mafia Semi Priest"] = mafiaSemiCount;
    }
    if (mafiaRevealerCount) {
      config["Mafia Revealer"] = mafiaRevealerCount;
    }
    if (mafiaFlagbearerCount) {
      config["Mafia Flagbearer"] = mafiaFlagbearerCount;
    }
    if (detectiveCount) {
      config["Detective"] = detectiveCount;
    }
    if (healerCount) {
      config["Healer"] = healerCount;
    }
    if (villagerCount) {
      config["Villager"] = villagerCount;
    }
    if (villagerSaveCount) {
      config["Villager protected by Mafia semi priest"] = villagerSaveCount;
    }
    if (villagerRevengeCount) {
      config["Vengeful Villager"] = villagerRevengeCount;
    }
    if (gameFlow) {
      config["Game Flow"] =
        gameFlow === "initial_day"
          ? "Starts with Day"
          : gameFlow === "initial_night"
          ? "Starts with Night"
          : gameFlow === "only_day"
          ? "Nightless Rounds"
          : "";
    }
    return Object.keys(config).map((c) => (
      <p key={c}>
        <strong>{c}: </strong> {config[c]}
      </p>
    ));
  }, [
    detectiveCount,
    gameFlow,
    healerCount,
    mafiaCount,
    mafiaFlagbearerCount,
    mafiaRevealerCount,
    mafiaSemiCount,
    villagerCount,
    villagerRevengeCount,
    villagerSaveCount,
  ]);

  const editGame = async () => {
    if (players < Object.keys(playersStates).length) {
      setResponse(`Cannot be less than the number of players joined.`);
      return;
    }
    // if (sum !== players) {
    //   setResponse(`The sum of characters should be equal to ${players}.`);
    //   return;
    // }
    try {
      const data = {
        data: {
          players_count: players,
          name: roomName,
          mafia_count: mafiaCount,
          mafia_semi_count: mafiaSemiCount,
          mafia_revealer_count: mafiaRevealerCount,
          mafia_flagbearer_count: mafiaFlagbearerCount,
          detective_count: detectiveCount,
          healer_count: healerCount,
          villager_count: villagerCount,
          villager_save_count: villagerSaveCount,
          villager_revenge_count: villagerRevengeCount,
          game_flow: gameFlow,
        },
        type,
      };
      const response = await api.put(
        `/games/api/v1/mafia/room/${room.code}/edit/`,
        JSON.stringify(data)
      );
      console.log(response);
      if (response.status === 200) {
        const roomData = response.data.room;
        sendSocketMessage("room_update", flattenRoomObject(roomData));
        close();
      }
    } catch (error) {
      setResponse("Error in updating the room");
      console.log(error);
    }
  };

  return (
    <Modal visible close={close}>
      <div
        className="p-4 border-t-4 border-mafia-secondary rounded-lg shadow-md bg-mafia-primary text-white w-144"
        style={{ height: "max-content" }}
      >
        <div className="font-bold mb-2 pb-2 text-center text-xl text-white uppercase tracking-normal border-b-2 border-mafia-background">
          Edit your Mafia Room
        </div>
        <div className="flex flex-col items-center px-4">
          <div className="flex items-center justify-between w-full mt-4">
            <div className="font-bold w-1/4 whitespace-no-wrap">Room Name</div>
            <div className="flex flex-grow w-3/4">
              <input
                className="px-4 py-2 bg-mafia-background rounded-lg w-full"
                type="text"
                placeholder="Enter Room Name"
                value={roomName}
                onChange={(e) => setRoomName(e.target.value)}
                maxLength="28"
              />
            </div>
          </div>
          <div className="flex items-center mt-4 justify-between w-full">
            <div className="font-bold w-1/4 whitespace-no-wrap">Players</div>
            <div className="w-3/4 flex">
              <PlayerCount
                value={players}
                options={[3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                disabledOptions={getDisabledPlayerCounts()}
                onChange={setPlayers}
              />
            </div>
          </div>
          <div className="flex items-center mt-4 justify-between w-full">
            <div className="font-bold w-1/4 whitespace-no-wrap">Characters</div>
            <div className="w-3/4">{configDisplay}</div>
          </div>
          {/* <div className="flex items-center mt-4 justify-between w-full">
            <div className="w-1/4 whitespace-no-wrap">Mafia</div>
            <CharacterCount
              sum={sum}
              players={players}
              value={mafiaCount}
              onChange={setMafiaCount}
            />
          </div>
          <div className="flex items-center mt-4 justify-between w-full">
            <div className="w-1/4 whitespace-no-wrap">Detective</div>
            <CharacterCount
              sum={sum}
              players={players}
              value={detectiveCount}
              onChange={setDetectiveCount}
            />
          </div>
          <div className="flex items-center mt-4 justify-between w-full">
            <div className="w-1/4 whitespace-no-wrap">Healer</div>
            <CharacterCount
              sum={sum}
              players={players}
              value={healerCount}
              onChange={setHealerCount}
            />
          </div>
          <div className="flex items-center mt-4 justify-between w-full">
            <div className="w-1/4 whitespace-no-wrap">Villagers</div>
            <CharacterCount
              sum={sum}
              players={players}
              value={villagerCount}
              onChange={setVillagerCount}
            />
          </div> */}
          <div className="flex items-center mt-4 justify-between w-full">
            <div className="w-1/4 whitespace-no-wrap">Room type</div>
            <div className="w-3/4 flex">
              <div
                className={`w-auto h-auto p-2 leading-none cursor-pointer flex items-center mr-2 justify-center rounded-lg ${
                  type === "public"
                    ? "bg-mafia-background text-white shadow-md"
                    : "text-white"
                }`}
                onClick={setType.bind(null, "public")}
              >
                Public
              </div>
              <div
                className={`w-auto h-auto p-2 leading-none cursor-pointer flex items-center justify-center rounded-lg ${
                  type === "private"
                    ? "bg-mafia-background text-white shadow-md"
                    : "text-white"
                }`}
                onClick={setType.bind(null, "private")}
              >
                Private
              </div>
            </div>
          </div>
          {/* <div className="flex items-center mt-4 justify-between w-full">
            <div className="w-1/4 whitespace-no-wrap">Initial Round</div>
            <div className="w-3/4 flex">
              <div
                className={`w-auto h-auto p-2 leading-none cursor-pointer whitespace-no-wrap flex items-center mr-2 justify-center rounded-lg ${
                  gameFlow === "initial_night"
                    ? "bg-mafia-background text-white shadow-md"
                    : "text-white"
                }`}
                onClick={setgameFlow.bind(null, "initial_night")}
              >
                Start with Night
              </div>
              <div
                className={`w-auto h-auto p-2 leading-none cursor-pointer whitespace-no-wrap flex items-center justify-center rounded-lg ${
                  gameFlow === "initial_day"
                    ? "bg-mafia-background text-white shadow-md"
                    : "text-white"
                }`}
                onClick={setgameFlow.bind(null, "initial_day")}
              >
                Start with Day
              </div>
              <div
                className={`w-auto h-auto p-2 leading-none cursor-pointer whitespace-no-wrap flex items-center mr-2 justify-center rounded-lg ${
                  gameFlow === "only_day"
                    ? "bg-mafia-background text-white shadow-md"
                    : "text-white"
                }`}
                onClick={setgameFlow.bind(null, "only_day")}
              >
                Day only
              </div>
            </div>
          </div> */}
          <div className="h-8 flex items-center justify-center font-semibold text-xs text-mafia-secondary">
            {response}
          </div>
          <button
            className="text-lg uppercase tracking-wide text-mafia-primary bg-mafia-secondary px-4 py-3 leading-none rounded-lg hover:shadow-lg font-semibold"
            onClick={editGame}
          >
            Update
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default HostRoom;
