import React from "react";
import PropTypes from "prop-types";
import { useSeed } from "../../apps/Zo/contexts/seed";

const ZoHeaderStat = ({
  className,
  titleClassName,
  value,
  icon,
  title,
  active,
  onClick,
  children,
  locked,
  onMouseOut,
  style,
}) => {
  const { style: seedStyle } = useSeed();

  const mainClasses = `flex items-center h-10 px-3 rounded-lg portrait:w-content landscape:overflow-hidden ${
    className ? className : ""
  } ${onClick ? "cursor-pointer shadow-md" : "cursor-default shadow-sm"} ${
    active ? "bg-gray-300 shadow-inner" : "bg-white"
  }`;
  const titleClasses = `absolute bottom-0 inline-block whitespace-no-wrap p-2 -mb-7 text-xs opacity-0 pointer-events-none group-hover:opacity-100 ${
    titleClassName ? titleClassName : ""
  }`;
  return (
    <div
      className="relative flex flex-col items-center mr-2 last:mr-0 group portrait:mr-4"
      onClick={onClick}
      onMouseOut={onMouseOut}
    >
      <div className={mainClasses} style={style}>
        {locked && (
          <div className="absolute rounded-lg top-0 left-0 w-full h-full z-10 bg-blur-sm flex items-center justify-center font-semibold">
            <svg
              className={`fill-current w-6 h-6 text-${
                seedStyle.accentColor || "orange"
              }`}
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM9 8V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9z" />
            </svg>
          </div>
        )}
        {icon && typeof icon === "string" ? (
          <img className="h-6" src={icon} alt="" />
        ) : (
          icon
        )}
        {value && typeof value === "string" ? (
          <span className="inline-block ml-2">{value}</span>
        ) : (
          value
        )}
        {children}
      </div>
      {title && <span className={titleClasses}>{title}</span>}
    </div>
  );
};

ZoHeaderStat.propTypes = {
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  value: PropTypes.any,
  icon: PropTypes.any,
  title: PropTypes.string,
  onClick: PropTypes.func,
  onMouseOut: PropTypes.func,
  style: PropTypes.object,
};

export default ZoHeaderStat;
