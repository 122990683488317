import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useProfile, useRoom } from "../../../contexts";
import useApi from "../../../hooks/useApi";
import Flex from "../../structure/Flex";
import Widget from "../../ui/Widget";
import Member from "./components/QueueMember";
import Timer from "./components/QueueTimer";

const MIN_PLAYER = 3;

const MafiaQueue = () => {
  const { profile } = useProfile();
  const { citizens, sendMessage, message, connecting } = useRoom();
  const history = useHistory();
  const location = useLocation();
  const api = useApi("PROFILE_LOBBY");

  const [eta, setEta] = useState<number | null>(null);

  const [queueMembers, setQueueMembers] = useState<string[]>([]);

  useEffect(() => {
    let mounted = true;

    if (api) {
      api
        .get("/mafia/queue/")
        .then((res) => {
          if (mounted) {
            setEta(res.data.eta.nextGameAt);
            setQueueMembers(res.data.members);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    return () => {
      mounted = false;
    };
  }, [api]);

  const joinQueue = () => {
    sendMessage({
      action: "joined_queue",
      payload: { code: profile?.code },
    });
  };

  const queueMembersFull = useMemo(
    () =>
      (citizens.length > 0 &&
        queueMembers.length > 0 &&
        queueMembers
          .map((qm) => citizens.find((m) => m.code === qm))
          .filter((qm) => qm != null)) ||
      [],
    [citizens, queueMembers]
  );

  const leaveQueue = () => {
    sendMessage({
      action: "left_queue",
      payload: { code: profile?.code },
    });
  };

  const addToQueue = (code: string) => {
    if (queueMembers.indexOf(code) === -1) {
      setQueueMembers((qMs) => [...qMs, code]);
    }
  };

  const removeFromQueue = (code: string) => {
    setQueueMembers((qMs) => qMs.filter((qM) => qM !== code));
  };

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    if (search.get("autojoin") && connecting === false) {
      setTimeout(joinQueue, 0);
      history.push(window.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, connecting]);

  useEffect(() => {
    if (message) {
      if (message.action === "joined_queue") {
        addToQueue(message.payload.code);
      } else if (
        message.action === "left_queue" ||
        message.action === "left" ||
        message.action === "kick"
      ) {
        removeFromQueue(message.payload.code);
      } else if (message.action === "queue_eta") {
        setEta(message.payload.nextGameAt);
      } else if (message.action === "new_match") {
        if (
          message.payload.players
            .map((p: any) => p.passport_id)
            .indexOf(profile?.code) !== -1
        ) {
          window.open(
            `https://${process.env.REACT_APP_ROOT_DOMAIN}/@mafia/r/${message.payload.code}`,
            "_self"
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <Widget title="Mafia Queue" id="mafia-joining-queue" hasToggle>
      {() => (
        <Flex col className="w-full rounded-b-lg h-96 scroll-hidden">
          <header className="bg-mafia-secondary font-semibold text-white p-2 flex items-center justify-between flex-shrink-0">
            {queueMembers.length < MIN_PLAYER ? (
              <span className="text-sm">
                Waiting for atleast {MIN_PLAYER - queueMembers.length} player
                {queueMembers.length === MIN_PLAYER - 1 ? "" : "s"} to join
              </span>
            ) : (
              <span className="text-sm">
                Next game starting in <Timer eta={eta} />
              </span>
            )}
            {queueMembers.find((m) => m === profile?.code) ? (
              <button
                className="bg-mafia-primary p-2 leading-none rounded-lg font-semibold uppercase tracking-wide cursor-pointer focus:outline-none text-white"
                onClick={leaveQueue}
              >
                Leave
              </button>
            ) : (
              <button
                className="bg-mafia-primary p-2 leading-none rounded-lg font-semibold uppercase tracking-wide cursor-pointer focus:outline-none text-white"
                onClick={joinQueue}
              >
                Join
              </button>
            )}
          </header>
          <div className="w-full flex-1 overflow-y-auto rounded-b-lg">
            {queueMembersFull && queueMembersFull.length > 0 ? (
              queueMembersFull
                .filter((m) => m != null)
                .map((m) => (
                  <Member
                    key={m?.code}
                    avatar={m?.avatar_url || ""}
                    nickname={m?.nickname || ""}
                  />
                ))
            ) : (
              <div className="w-full h-full flex items-center justify-center text-lg font-semibold rounded-b-lg text-gray-800">
                No active players.
              </div>
            )}
          </div>
        </Flex>
      )}
    </Widget>
  );
};

export default MafiaQueue;

// {/* <header className="w-full p-3 flex items-center justify-between">
// <span className="flex flex-col">
// <span className="font-bold">Mafia Game Queue</span>
// {queueMembers.length < MIN_PLAYER ? (
//   <span className="text-sm">
//   Waiting for atleast {MIN_PLAYER - queueMembers.length} player
//   {queueMembers.length === MIN_PLAYER - 1 ? "" : "s"} to join
//   </span>
//   ) : (
//     <span className="text-sm">
//     Next game starting in <Timer eta={eta} />
//     </span>
//     )}
//     </span>
//     {queueMembers.find((m) => m === profile?.code) ? (
//       <button
//       className="bg-mafia-secondary p-2 leading-none rounded-lg font-semibold uppercase tracking-wide cursor-pointer focus:outline-none text-mafia-primary"
//       onClick={leaveQueue}
//       >
//       Leave
//       </button>
//       ) : (
//         <button
//         className="bg-mafia-secondary p-2 leading-none rounded-lg font-semibold uppercase tracking-wide cursor-pointer focus:outline-none text-mafia-primary"
//         onClick={joinQueue}
//         >
//         Join
//         </button>
//         )}
//       </header> */}
