export const renderLinks = (text: string, anchorClass?: string) => {
  var urlRegex =
    /(https?:\/\/(www\.)?)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
  return text.replace(urlRegex, function (url) {
    const href = url.substring(0, 4) !== "http" ? "http://" + url : url;
    return `<a class="${
      anchorClass ||
      "text-orange font-semibold hover:underline pointer-events-auto"
    }" href="${href}" ${url.includes("zo.xyz") ? "" : 'target="_blank"'}>${url}</a>`;
  });
};
