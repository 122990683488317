import React, { useRef, useEffect, useState } from "react";
import Axios from "axios";

import zobu from "./../../../assets/zobu.svg";
import withMemo from "../../../utils/withMemo";

const Avatar = withMemo(
  ({ className, svg, full, style, onClick }) => {
    const svgRef = useRef();
    const [svgData, setSvgData] = useState("");

    const fetchSVG = async (url) => {
      if (sessionStorage.getItem(url)) {
        return sessionStorage.getItem(url);
      } else {
        try {
          const response = await Axios.get(url);
          const svgResponse = response.data.replace(/<\/?svg.*?>/g, "");
          try {
            sessionStorage.setItem(url, svgResponse);
          } catch (error) {
            sessionStorage.clear();
            sessionStorage.setItem(url, svgResponse);
          }
          return svgResponse;
        } catch (error) {
          console.log(error);
          return null;
        }
      }
    };

    useEffect(() => {
      let mounted = true;

      if (svg) {
        fetchSVG(svg)
          .then((data) => {
            if (data && mounted) {
              setSvgData(data);
            } else {
              fetchSVG(zobu)
                .then((data) => {
                  if (mounted) {
                    setSvgData(data);
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          })
          .catch((e) => {
            fetchSVG(zobu)
              .then((data) => {
                if (mounted) {
                  setSvgData(data);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          });
      } else {
        fetchSVG(zobu)
          .then((data) => {
            if (mounted) {
              setSvgData(data);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }

      return () => {
        mounted = false;
      };
    }, [svg]);
    return full ? (
      <svg
        className={className}
        ref={svgRef}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="154 0 200 512"
        dangerouslySetInnerHTML={{ __html: svgData }}
        style={style}
        onClick={onClick}
      />
    ) : (
      <div
        className={`overflow-hidden rounded-full ${className ? className : ""}`}
      >
        <svg
          className="w-full h-full"
          ref={svgRef}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="175 47 155 155"
          dangerouslySetInnerHTML={{ __html: svgData }}
          style={style}
          onClick={onClick}
        />
      </div>
    );
  },
  ["className", "svg", "full", "style", "onClick"]
);

export default Avatar;
