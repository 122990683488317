import React from "react";

import ZoHeaderStat from "./../../../../../ui/ZoHeaderStat";
import economy from "./../../../../../../assets/dollar.svg";

const Economy = (props) => {
  return (
    <ZoHeaderStat
      className="zo-head_economy"
      icon={economy}
      value={"270m"}
      title={"Economy"}
      {...props}
    />
  );
};

export default Economy;
