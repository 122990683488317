import React, { useContext, useMemo } from "react";
import withMemo from "../../../../../utils/withMemo";

import Avatar from "../../../../ui/Avatar";
import RoomContext from "../../contexts/room";

const AvatarsDisplay = withMemo(() => {
  const { members } = useContext(RoomContext);

  return useMemo(
    () =>
      members.length < 3 ? (
        <>
          <section
            className={`fixed flex items-center justify-center w-full mb-8 bottom-0 left-0 pointer-events-none ${
              members.length === 4
                ? "mr-20"
                : members.length === 5
                ? "ml-10"
                : ""
            }`}
          >
            <div className="flex items-center">
              {members.slice(2, 5).map((m) => (
                <Avatar
                  key={m.code}
                  svg={m.avatar_url}
                  full
                  style={{ height: window.innerWidth < 640 ? "40vh" : "50vh" }}
                />
              ))}
            </div>
          </section>
          <section
            className={`fixed flex items-center justify-center w-full bottom-0 left-0 pointer-events-none ${
              members.length === 5 ? "-ml-10" : ""
            }`}
          >
            <div className="flex items-center">
              {members.slice(0, 2).map((m) => (
                <Avatar
                  key={m.code}
                  svg={m.avatar_url}
                  full
                  style={{ height: window.innerWidth < 640 ? "40vh" : "50vh" }}
                />
              ))}
            </div>
          </section>
        </>
      ) : members.length < 6 ? (
        <>
          <section
            className={`fixed flex items-center justify-center w-full mb-8 bottom-0 left-0 pointer-events-none ${
              members.length === 4
                ? "-ml-18"
                : members.length === 6
                ? "ml-10"
                : ""
            }`}
          >
            <div className="flex items-center">
              {members.slice(3, 6).map((m) => (
                <Avatar
                  key={m.code}
                  svg={m.avatar_url}
                  full
                  style={{ height: window.innerWidth < 640 ? "40vh" : "50vh" }}
                />
              ))}
            </div>
          </section>
          <section
            className={`fixed flex items-center justify-center w-full bottom-0 left-0 pointer-events-none ${
              members.length === 5 ? "" : ""
            }`}
          >
            <div className="flex items-center">
              {members.slice(0, 3).map((m) => (
                <Avatar
                  key={m.code}
                  svg={m.avatar_url}
                  full
                  style={{ height: window.innerWidth < 640 ? "40vh" : "50vh" }}
                />
              ))}
            </div>
          </section>
        </>
      ) : members.length < 8 ? (
        <>
          <section
            className={`fixed flex items-center justify-center w-full mb-8 bottom-0 left-0 pointer-events-none ${
              members.length === 6 ? "-ml-18" : ""
            }`}
          >
            <div className="flex items-center">
              {members.slice(4, 8).map((m) => (
                <Avatar
                  key={m.code}
                  svg={m.avatar_url}
                  full
                  style={{ height: window.innerWidth < 640 ? "40vh" : "50vh" }}
                />
              ))}
            </div>
          </section>
          <section
            className={`fixed flex items-center justify-center w-full bottom-0 left-0 pointer-events-none ${
              members.length === 8 ? "-ml-10" : ""
            }`}
          >
            <div className="flex items-center">
              {members.slice(0, 4).map((m) => (
                <Avatar
                  key={m.code}
                  svg={m.avatar_url}
                  full
                  style={{ height: window.innerWidth < 640 ? "40vh" : "50vh" }}
                />
              ))}
            </div>
          </section>
        </>
      ) : (
        <>
          <section
            className={`fixed flex items-center justify-center w-full mb-8 bottom-0 left-0 pointer-events-none ${
              members.length === 8 ? "-ml-28" : ""
            }`}
          >
            <div className="flex items-center">
              {members.slice(5, 9).map((m) => (
                <Avatar
                  key={m.code}
                  svg={m.avatar_url}
                  full
                  style={{ height: window.innerWidth < 640 ? "40vh" : "50vh" }}
                />
              ))}
            </div>
          </section>
          <section
            className={`fixed flex items-center justify-center w-full bottom-0 left-0 pointer-events-none ${
              members.length === 8 ? "-ml-10" : ""
            }`}
          >
            <div className="flex items-center">
              {members.slice(0, 5).map((m) => (
                <Avatar
                  key={m.code}
                  svg={m.avatar_url}
                  full
                  style={{ height: window.innerWidth < 640 ? "40vh" : "50vh" }}
                />
              ))}
            </div>
          </section>
        </>
      ),
    [members]
  );
}, []);

export default AvatarsDisplay;
