import React, { useEffect } from "react";

import Timer from "../../../Header/components/RoundTimer";
import nightVillager from "./../../../../assets/night_villager.svg";
import Modal from "../../../../../../ui/Modal";
import VoteSelect from "../../../Footer/components/VoteSelect";

const VillageRevengeModal = ({ visible, close }) => {
  useEffect(() => {
    let mounted = true;
    if (visible) {
      setTimeout(() => {
        if (mounted) {
          close();
        }
      }, 25000);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal visible={visible} close={close}>
      <div
        className="bg-gray-200 relative z-40 rounded-lg overflow-hidden shadow-lg mb-10 bg-white bg-no-repeat bg-cover bg-center flex flex-col items-center"
        style={{
          width: "24rem",
          height: "30rem",
          backgroundImage: `url(${nightVillager})`,
        }}
      >
        <div className="bg-mafia-primary mt-4 w-3/4 mb-40 border-t-2 border-mafia-secondary p-2 rounded-lg shadow-lg flex flex-col items-center">
          <div className="font-bold uppercase tracking-normal text-mafia-secondary text-2xl">
            Take your revenge
          </div>
        </div>
        <Timer />
        <div className="text-white text-center p-4">
          You have the ability to kill someone else in place of you.
          <br />
          In case of no votes, a random player will be killed instead.
        </div>
        <div className="bg-white flex items-center rounded-lg shadow-lg mb-4">
          <div className="font-bold rounded-l-lg bg-mafia-primary text-lg uppercase tracking-normal text-white px-4 flex items-center h-16">
            Kill
          </div>
          <VoteSelect />
        </div>
      </div>
    </Modal>
  );
};

export default VillageRevengeModal;
