import React, { useContext, useMemo, useState } from "react";

import RTCControlButton from "./../RTCControlButton";
import RTCVolumeButton from "./../RTCVolumeButton";
import RoomContext from "../../contexts/room";
import ZoHeaderUser from "../../../../ui/ZoHeaderUser";
import withMemo from "../../../../../utils/withMemo";
import TitleModal from "../../modals/HostSettings/TitleModal";
import RTCHostControlButton from "../RTCHostControlButton";
import { useHistory } from "react-router-dom";

const Header = withMemo(
  ({ roomName, isHost, speakability, hasPremium, sendPartyMessage }) => {
    const { members } = useContext(RoomContext);
    const history = useHistory();

    const [titleVisible, setTitleVisible] = useState(false);

    return useMemo(
      () => (
        <header className="bg-orange h-16 w-full sm:rounded-lg shadow-md flex items-center px-4 flex-shrink-0 flex-grow-0 justify-between">
          <button className="flex items-center justify-between flex-1 focus:outline-none h-16">
            <div className="flex flex-col items-start">
              <div className="flex items-center">
                <span className="font-black text-white text-lg truncate flex items-center">
                  {roomName.length > 20
                    ? roomName.substr(0, 18).concat("...")
                    : roomName}
                </span>
                {isHost && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    className="w-4 h-4 fill-current text-white ml-2 cursor-pointer relative z-10"
                    onClick={
                      isHost ? setTitleVisible.bind(null, true) : () => {}
                    }
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                  </svg>
                )}
              </div>
              <span className="text-white text-xs leading-none">
                <strong>{members.length}</strong> zosteler
                {members.length === 1 ? "" : "s"} live
              </span>
            </div>
          </button>
          <div className="flex flex-shrink-0 items-center">
            <RTCVolumeButton />
            <button
              className="sm:hidden mr-4"
              onClick={() => {
                history.push("#events");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                className="w-6 h-6 text-white fill-current"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M19 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H8V2c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 16H6c-.55 0-1-.45-1-1V8h14v10c0 .55-.45 1-1 1zM8 10h3c.55 0 1 .45 1 1v3c0 .55-.45 1-1 1H8c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1z" />
              </svg>
            </button>
            {speakability === 1 ? (
              <RTCControlButton className="bg-white text-orange" />
            ) : speakability === 3 ? (
              isHost ? (
                <RTCControlButton className="bg-white text-orange" />
              ) : (
                <RTCHostControlButton />
              )
            ) : (
              <RTCControlButton className="bg-white text-orange" />
            )}
            <div className="sm:hidden ml-2">
              <ZoHeaderUser />
            </div>
          </div>
          {isHost && titleVisible && (
            <TitleModal
              close={setTitleVisible.bind(null, false)}
              title={roomName}
            />
          )}
        </header>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [hasPremium, isHost, members.length, roomName, speakability, titleVisible]
    );
  },
  ["roomName", "isHost", "speakability", "hasPremium"]
);

export default Header;
