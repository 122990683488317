import React, { useContext, useState } from "react";

import SubSectionRow from "../../../components/SubSectionRow";
import ProfileContext from "../../../../../../../contexts/profile";
import {
  useInput,
  useResponseFlash,
} from "../../../../../../Zo/components/hooks";
import useApi from "../../../../../../../hooks/useApi";

const LinkField = () => {
  const api = useApi("profile", true);
  const { profile, updateProfile } = useContext(ProfileContext);
  const { value: subdomain, bind: bindSubdomain } = useInput(
    profile.subdomain || ""
  );
  const { response, setResponse } = useResponseFlash();

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loading && api) {
      if (!/^[a-zA-Z0-9-]{6,32}$/.test(subdomain)) {
        setResponse(
          "Only [A-Z, a-z, 0-9 or -] and 6 to 32 characters in length allowed"
        );
        return;
      }
      setLoading(true);
      try {
        const data = {
          nickname: subdomain,
        };
        console.log(data);

        const response = await api.post(
          `/profile/api/v1/me/`,
          JSON.stringify(data)
        );
        if (response.status === 200) {
          updateProfile(data);
          setLoading(true);
          const res = await api.post(`/profile/api/v1/me/domain/`);
          console.log("domain response", res);
          if (res.status === 200) {
            setLoading(false);
            setExpanded(false);
            updateProfile({ subdomain });
          }
        } else {
          setResponse("Error in fields");
        }
      } catch (error) {
        console.log(error.response);
        if (error.response.status === 422) {
          setResponse(error.response.data.errors.nickname[0]);
          setLoading(false);
        } else {
          setResponse("Error in setting the nickname.");
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <SubSectionRow
      title="Link"
      visibleArea={
        profile.subdomain
          ? `https://${profile.subdomain}.${process.env.REACT_APP_ROOT_DOMAIN}`
          : null
      }
      editable={profile.status === "premium" && !profile.subdomain}
      editText="Personalise"
      placeholder={`Buy our Subscription to get https://[Your Nickname].${process.env.REACT_APP_ROOT_DOMAIN} as your link.`}
      isExpanded={expanded}
      setExpanded={setExpanded}
    >
      <form className="flex flex-col w-full" onSubmit={handleSubmit}>
        <div className="flex w-full items-center">
          <span>https://</span>
          <input
            className="w-1/3 portrait:w-full mr-2 portrait:mr-0 form-input"
            placeholder={`https://[Your Nickname].${process.env.REACT_APP_ROOT_DOMAIN}`}
            {...bindSubdomain}
          />
          <span>.{process.env.REACT_APP_ROOT_DOMAIN}</span>
        </div>
        <div className="bg-red-200 p-2 flex items-center rounded-lg my-2 text-gray-900 text-left text-xs">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            className="w-5 h-5 fill-current text-orange mr-4 flex-shrink-0"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
          </svg>
          You can only set this once. Choose Wisely! <br />
          P.S. This will become your nickname (which you can update from General
          Settings later).
        </div>

        <div className="w-full h-6 flex items-center justify-start font-semibold text-xs text-orange">
          {response}
        </div>
        <div className="flex mb-4 w-full">
          <button
            className="bg-orange text-white cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            type="submit"
          >
            {loading ? "Saving" : "Save"}
          </button>
          <button
            className="bg-white text-orange cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            onClick={setExpanded.bind(null, false)}
          >
            Cancel
          </button>
        </div>
      </form>
    </SubSectionRow>
  );
};

export default LinkField;
