import React, { useReducer } from "react";

import GameContext from ".";

function gameReducer(state, [type, payload]) {
  switch (type) {
    case "PLAYER":
      return {
        ...state,
        player: { ...payload },
      };
    case "PLAYERS_STATES":
      return {
        ...state,
        playersStates: payload,
      };
    case "PLAYER_ADD":
      const _pa_playersStates = { ...state.playersStates };
      _pa_playersStates[payload.id] = payload;
      return {
        ...state,
        playersStates: _pa_playersStates,
      };
    case "PLAYER_REMOVE":
      const _pr_playersStates = { ...state.playersStates };
      delete _pr_playersStates[payload.id];
      return {
        ...state,
        playersStates: { ..._pr_playersStates },
      };
    case "PLAYER_AVATAR":
      const _pav_playersStates = { ...state.playersStates };
      _pav_playersStates[payload.id].avatar = payload.avatar;
      return {
        ...state,
        playersStates: _pav_playersStates,
      };
    default:
      return state;
  }
}

const initialGameState = {
  player: null,
  roomRound: "",
  roomStatus: "",
  playersStates: {},
  gameTime: 0,
  roundTime: 0,
};

const GameProvider = ({ children }) => {
  const [state, dispatch] = useReducer(gameReducer, initialGameState);

  return (
    <GameContext.Provider value={{ update: dispatch, ...state }}>
      {children}
    </GameContext.Provider>
  );
};

export default GameProvider;
