import React from "react";

import Modal from "../../../../../ui/Modal";

const AssetModal = ({ close, assetOTD }) => {
  return (
    <Modal backgroundTheme="light" visible close={close}>
      <section className="w-108 portrait:w-full portrait:p-4 flex flex-col items-center bg-white py-6 border-t-4 border-orange rounded-lg overflow-hidden">
        <div className="font-bold text-2xl text-center">Asset of the Day</div>
        <div className="w-2/3 portrait:w-full portrait:p-4 text-gray-700 text-center mt-2">
          Zobu is your virtual avatar in the Zo World. And he needs cloths and
          assets just like you. Claim everyday free asset!
        </div>
        <div className="mt-6 w-full">
          <div className="flex flex-col items-center w-full mt-4">
            <img
              className="object-contain w-full h-32"
              src={assetOTD ? assetOTD.cropped_file : null}
              alt=""
            />
          </div>
          <div className="flex flex-col items-center w-full mt-4">
            <div className="flex flex-row">
              <button
                onClick={close}
                className="bg-white mt-8 px-4 py-4 mr-4 leading-none tracking-wide text-md font-semibold rounded-lg text-orange border border-orange"
              >
                Okay
              </button>
              <button
                onClick={() => {
                  window.open(
                    `https://me.${
                      process.env.REACT_APP_ROOT_DOMAIN
                    }/zobu/?asset_id=${assetOTD && assetOTD.id}&category_id=${
                      assetOTD && assetOTD.category
                    }`,
                    "_parent"
                  );
                }}
                className="bg-orange mt-8 px-4 py-4 ml-4 leading-none  tracking-wide text-md font-semibold rounded-lg text-white"
              >
                Get Equipped
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
    </Modal>
  );
};

export default AssetModal;
