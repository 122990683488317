import React from "react";

const SubSectionRow = ({
  visibleArea,
  placeholder,
  children,
  title,
  editable = true,
  isExpanded,
  setExpanded,
  editOnClick,
  editText = "Edit",
  forcePortrait,
}) => {
  return (
    <section
      className={`flex items-start p-2 w-full hover:bg-gray-200 rounded-lg ${
        forcePortrait ? "flex-col" : ""
      } portrait:flex-col`}
    >
      <div
        className={`font-lg font-medium pt-1 ${
          forcePortrait ? "w-full" : "w-1/5"
        } portrait:w-full`}
      >
        {title}
      </div>
      <div
        className={`flex items-start justify-between text-gray-700 pt-1 ${
          forcePortrait ? "w-full" : "w-4/5"
        } portrait:w-full`}
      >
        {isExpanded ? (
          children
        ) : (
          <>
            {visibleArea ? (
              <span className="break-all">{visibleArea}</span>
            ) : (
              <span className="text-gray-600 italic">{placeholder}</span>
            )}
            {editable && (
              <button
                className="hover:underline text-orange font-medium text-sm focus:outline-none"
                onClick={
                  editOnClick
                    ? editOnClick
                    : setExpanded
                    ? setExpanded.bind(null, true)
                    : null
                }
              >
                {editText}
              </button>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default SubSectionRow;
