import React, { useEffect, useRef, useState } from "react";
import withMemo from "../../../../../utils/withMemo";

import Chat from "../Chat";
import HostSettings from "../HostSettings";
import Widgets from "../Widgets";

const MobileTabs = withMemo(
  ({
    isHost,
    hasPremium,
    whiteboardText,
    roomDetails,
    background,
    sound,
    speakability,
    security,
  }) => {
    const [tabs, setTabs] = useState("home");
    const [unreadChatCount, setUnreadChatCount] = useState(0);
    const tabsContainerRef = useRef();

    const [keyboardFocused, setKeyboardFocused] = useState(false);

    const toggleKeyboardFocused = () => {
      setKeyboardFocused((v) => !v);
    };

    useEffect(() => {
      if (window.innerWidth < 640) {
        document
          .querySelector("#chat-input")
          .addEventListener("focus", toggleKeyboardFocused);
        document
          .querySelector("#chat-input")
          .addEventListener("blur", toggleKeyboardFocused);
      }

      return () => {
        document
          .querySelector("#chat-input")
          .removeEventListener("focus", toggleKeyboardFocused);
        document
          .querySelector("#chat-input")
          .removeEventListener("blur", toggleKeyboardFocused);
      };
    }, []);

    return (
      <>
        <div
          className="flex-grow w-screen flex relative overflow-auto"
          ref={tabsContainerRef}
        >
          <Chat
            active={tabs === "chat"}
            setUnreadChatCount={setUnreadChatCount}
          />

          <section
            className="flex-grow flex-shrink-0 flex flex-col w-full pb-2 relative"
            style={{
              transform:
                tabs === "home" ? "translateX(-100vw)" : "translateX(0)",
            }}
          >
            <header className="flex items-center justify-center flex-no-wrap overflow-x-auto overflow-y-hidden p-2 flex-shrink-0 flex-grow-0 w-screen">
              {isHost && (
                <HostSettings
                  hasPremium={hasPremium}
                  whiteboardText={whiteboardText}
                  background={background}
                  sound={sound}
                  security={security}
                  speakability={speakability}
                />
              )}
            </header>
            <section className="w-full flex-grow p-2 overflow-y-auto flex flex-col">
              <Widgets
                whiteboardText={whiteboardText}
                slug={roomDetails.slug}
              />
            </section>
          </section>
        </div>
        <footer
          className={`flex-shrink-0 flex-grow-0 w-full sm:hidden p-2 pt-0 shadow-sm ${
            keyboardFocused ? "hidden" : "flex"
          }`}
        >
          <div
            className="items-center justify-around flex bg-white rounded-full p-2"
            style={{ width: "calc(100% - 6rem)" }}
          >
            <button
              className={`flex relative items-center focus:outline-none flex-col ${
                tabs === "chat" ? "text-orange" : "text-gray-700"
              }`}
              onClick={setTabs.bind(null, "chat")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                className="fill-current w-6 h-6"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM7 9h10c.55 0 1 .45 1 1s-.45 1-1 1H7c-.55 0-1-.45-1-1s.45-1 1-1zm6 5H7c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm4-6H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1z" />
              </svg>
              <span className="text-xs">Chat</span>
              {unreadChatCount > 0 && (
                <span className="absolute top-0 right-0 text-xxs text-white font-bold bg-orange w-5 h-5 rounded-full shadow-md flex items-center justify-center -mt-2 -mr-2">
                  {unreadChatCount}
                </span>
              )}
            </button>
            <button
              className={`flex items-center focus:outline-none flex-col ${
                tabs === "home" ? "text-orange" : "text-gray-700"
              }`}
              onClick={setTabs.bind(null, "home")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                className="fill-current w-6 h-6"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M13 14v6c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-6c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1zm-9 7h6c.55 0 1-.45 1-1v-6c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1zM3 4v6c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1zm12.95-1.6L11.7 6.64c-.39.39-.39 1.02 0 1.41l4.25 4.25c.39.39 1.02.39 1.41 0l4.25-4.25c.39-.39.39-1.02 0-1.41L17.37 2.4c-.39-.39-1.03-.39-1.42 0z" />
              </svg>
              <span className="text-xs">Home</span>
            </button>
          </div>
        </footer>
      </>
    );
  },
  [
    "isHost",
    "hasPremium",
    "whiteboardText",
    "roomDetails",
    "background",
    "sound",
    "security",
    "speakability",
  ]
);

export default MobileTabs;
