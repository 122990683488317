import { createContext } from "react";

const TutorialContext = createContext({
  hasTutorial: false,
  steps: null,
  setSteps: (steps) => {},
  startTutorial: () => {},
});

export default TutorialContext;
