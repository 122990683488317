import React from "react";

import ZoHeaderStat from "./../../../../../ui/ZoHeaderStat";
import countriesIcon from "././../../../../../../assets/india.svg";

const Countries = () => {
  return (
    <ZoHeaderStat
      className="zo-head_countries"
      icon={countriesIcon}
      title="Countries"
      value={"1"}
      onClick={null}
    />
  );
};

export default Countries;
