import React from "react";
import withMemo from "../../../../utils/withMemo";

import MafiaLeaderboard from "../widgets/MafiaLeaderboard";
import MafiaPublicGames from "../widgets/MafiaPublicGames";
import MafiaQueue from "../widgets/MafiaQueue";
import RoomInfoWidget from "../widgets/RoomInfo";
import WhiteboardWidget from "../widgets/WhiteboardWidget";

const Widgets = withMemo(
  ({ whiteboardText, slug }) => {
    return (
      <>
        {whiteboardText && (
          <>
            <WhiteboardWidget description={whiteboardText} />
          </>
        )}
        <RoomInfoWidget />
        {slug === "mafia" && (
          <>
            <MafiaQueue />
            <MafiaLeaderboard />
            <MafiaPublicGames />
          </>
        )}
      </>
    );
  },
  ["whiteboardText", "slug"]
);

export default Widgets;
