import React from "react";
import { ArrowLeft, Calendar } from "../../../../assets/icons";
import Flex from "../../../../components/structure/Flex";
import IconButton from "../../../../components/ui/IconButton";
import UserNav from "../../../../components/ui/UserNav";

interface HeaderProps {
  view: "portal" | "events";
  changeView: React.Dispatch<React.SetStateAction<"portal" | "events">>;
}

const Header: React.FC<HeaderProps> = ({ changeView, view }) => {
  return (
    <header className="flex justify-between w-full p-4 items-center flex-shrink-0 max-w-lg md:mx-auto">
      <Flex items="center">
        {view === "events" && (
          <IconButton
            className="mr-1"
            onClick={changeView.bind(null, "portal")}
          >
            <ArrowLeft className="w-5 h-5" />
          </IconButton>
        )}
        <h1 className="text-2xl font-bold">
          {view === "portal"
            ? "Travel Portal"
            : view === "events"
            ? "Events Calendar"
            : null}
        </h1>
      </Flex>
      <Flex items="center">
        {view === "portal" && (
          <>
            <IconButton
              className="mr-1"
              onClick={changeView.bind(null, "events")}
            >
              <Calendar className="w-5 h-5" />
            </IconButton>
            {/* <IconButton className="mr-1">
              <Bell className="w-5 h-5" />
            </IconButton> */}
          </>
        )}
        <UserNav />
      </Flex>
    </header>
  );
};

export default Header;
