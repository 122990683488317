import React, { useState, useContext } from "react";

import ZoHeaderStat from "../../../../../ui/ZoHeaderStat";
import mafiaIcon from "./../../../../../../assets/cult-mafia.svg";
import ArticleModal from "../../widgets/Article/ArticleModal";
import { useSeed } from "../../../contexts/seed";
import AppContext from "../../../../../contexts/app";

const About = (props) => {
  const {
    seedData,
    style: { primaryColor, textSecondaryColor },
  } = useSeed();
  const { category, slug } = useContext(AppContext);

  const [isAboutVisible, setAboutVisibility] = useState(false);

  const getIcon = () => {
    if (category === "destination") {
      return seedData.thumbnail;
    } else if (category === "cult" && slug === "mafia") {
      return mafiaIcon;
    }
  };
  const getTitle = () => {
    if (category === "destination") {
      return `About${seedData ? " " + seedData.name : ""}`;
    } else if (category === "cult" && slug === "mafia") {
      return "About Mafia Cult";
    }
  };

  return (
    <>
      <ZoHeaderStat
        className={`zo-head_about whitespace-no-wrap w-auto bg-${
          primaryColor ? primaryColor : "white"
        }`}
        titleClassName={textSecondaryColor ? `text-${textSecondaryColor}` : ""}
        title={getTitle()}
        icon={getIcon()}
        onClick={() => {
          setAboutVisibility(true);
        }}
        value={`About`}
      />
      {isAboutVisible && (
        <ArticleModal close={setAboutVisibility.bind(null, false)} />
      )}
    </>
  );
};

export default About;
