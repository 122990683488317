import Axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReferralBlocker from "../../../../../../../../../components/apps/CommonRoom/components/ReferralBlocker";
import { useInput } from "../../../../../../../../../components/apps/Zo/components/hooks";
import { renderLinks } from "../../../../../../../../../utils";
import { useProfile } from "../../../../../../../contexts";
import useApi from "../../../../../../../hooks/useApi";
import InGameContext from "../../../../../contexts/inGame";
import RoomContext from "../../../../../contexts/room";
import SocketContext from "../../../../../contexts/socket";

const COLORS = [
  "text-red-300",
  "text-red-700",
  "text-yellow-300",
  "text-green-300",
  "text-teal-300",
  "text-blue-300",
  "text-indigo-300",
  "text-purple-300",
  "text-pink-300",
  "text-orange",
  "text-pink-600",
  "text-teal-800",
];

const Chat = () => {
  const [logs, setLogs] = useState([]);
  const { room } = useContext(RoomContext);
  const { message, sendSocketMessage } = useContext(SocketContext);
  const { playersStates, player, roomStatus } = useContext(InGameContext);
  const { profile } = useProfile();
  const api = useApi("GAMES_MAFIA_EVENTS");
  const logsRef = useRef();

  const {
    value: chatMessage,
    bind: bindChatMessage,
    reset: resetChatMessage,
  } = useInput("");
  const [referralBlockerVisibility, setReferralBlockerVisibility] =
    useState(false);

  const handleMessageSend = useCallback(
    (e) => {
      e.preventDefault();
      if (chatMessage && chatMessage.trim().length) {
        if (player.status === "spectate") {
          if (profile && profile.status !== "unfriendly") {
            sendSocketMessage("players_chat", {
              id: player.nickname,
              message: chatMessage.trim(),
              status: player.status,
            });
          } else {
            setReferralBlockerVisibility(true);
          }
        } else {
          sendSocketMessage("players_chat", {
            id: player.id,
            message: chatMessage.trim(),
            status: player.status,
          });
        }
        resetChatMessage();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatMessage, player.id, player.status]
  );

  const showForm = useCallback(() => {
    if (room.status !== "ended_mafia" && room.status !== "ended_village") {
      if (player.status === "spectate" && !profile) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }, [player.status, profile, room.status]);

  useEffect(() => {
    if (api) {
      api
        .get(`/${room.code}/`)
        .then((response) => {
          if (response.status === 200) {
            const _logs = response.data;
            _logs.forEach((_log) => {
              addSocketMessage(
                _log.data.action,
                _log.data.payload,
                _log.time_create
              );
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, room.status]);

  useEffect(() => {
    const anon_user_token = localStorage.getItem("anon_user_token");
    const anon_user = localStorage.getItem("anon_user");
    const anon_token_expiry = localStorage.getItem("anon_token_expiry");
    const anon_nickname = localStorage.getItem("anon_nickname");
    if (
      anon_nickname &&
      anon_token_expiry &&
      anon_user &&
      anon_user_token &&
      JSON.parse(anon_user) &&
      JSON.parse(anon_user).app_id &&
      JSON.parse(anon_user).user_id
    ) {
      Axios.get(
        `https://socket.${process.env.REACT_APP_API_DOMAIN}/games/api/v1/mafia/events/${room.code}/`,
        {
          headers: {
            Authorization: "Bearer " + anon_user_token,
            "Content-Type": "application/json",
            "Client-App-Id": JSON.parse(anon_user).app_id,
            "Client-User-Id": JSON.parse(anon_user).user_id,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            const _logs = response.data;
            _logs.forEach((_log) => {
              addSocketMessage(
                _log.data.action,
                _log.data.payload,
                _log.time_create
              );
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (message && message.action) {
      const time_create = new Date().toISOString();
      addSocketMessage(message.action, message.payload, time_create);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    setTimeout(() => {
      logsRef.current.scrollTo(0, logsRef.current.scrollHeight);
    }, 500);
  }, [logs]);

  function addSocketMessage(action, payload, time_create) {
    switch (action) {
      case "room_status":
        setLogs((l) => [
          ...l,
          { by: -1, log: formatRoomStatus(payload), time_create },
        ]);
        break;
      case "room_round":
        setLogs((l) => [
          ...l,
          { by: -1, log: formatRoomRound(payload), time_create },
        ]);
        break;
      case "player_status":
        setLogs((l) => [
          ...l,
          { by: -1, log: formatPlayerStatus(payload), time_create },
        ]);
        break;
      case "player_vote":
        setLogs((l) => [
          ...l,
          { by: -1, log: formatPlayerVote(payload), time_create },
        ]);
        break;
      case "players_chat":
        if (payload.id) {
          setLogs((l) => [
            ...l,
            { by: payload.id, log: payload.message, time_create },
          ]);
        }
        break;
      case "time_bank":
        if (payload.id) {
          setLogs((l) => [
            ...l,
            { by: -1, log: formatTimeBank(payload), time_create },
          ]);
        }
        break;
      case "time_extend":
        setLogs((l) => [
          ...l,
          {
            by: -1,
            log: "Time has been extended due to a change in majority votes in the last 15 seconds.",
            time_create,
          },
        ]);
        break;
      case "time_normalize":
        setLogs((l) => [
          ...l,
          {
            by: -1,
            log: "Time is now passing at normal speed.",
            time_create,
          },
        ]);
        break;
      case "time_expedite":
        if (payload.clockSpeed === 2) {
          setLogs((l) => [
            ...l,
            {
              by: -1,
              log: "The Village has now reached at a common decision. Time is now moving at 2x speed.",
              time_create,
            },
          ]);
        } else if (payload.clockSpeed === 4) {
          setLogs((l) => [
            ...l,
            {
              by: -1,
              log: "The player surrendered under the pressure of the Village. Time is now moving at 4x speed.",
              time_create,
            },
          ]);
        }
        break;
      case "detective_reveal":
        if (
          player.id &&
          player.id === payload.id &&
          playersStates[payload.vote]
        ) {
          setLogs((l) => [
            ...l,
            {
              by: -1,
              log: `You checked and ${
                playersStates[payload.vote].nickname
              } is ${
                playersStates[payload.vote].role &&
                playersStates[payload.vote].role.includes("mafia")
                  ? ""
                  : "not "
              } a mafia!`,
              time_create,
            },
          ]);
        }
        break;
      default:
        break;
    }
  }

  function formatRoomStatus(payload) {
    if (payload.status === "started") {
      return "The Godfather welcomes to the game of Zo Mafia! Brace for a wicked time.";
    } else if (payload.status === "ended_mafia") {
      return "The Mafia have established their supremacy by this flagrant win!";
    } else if (payload.status === "ended_village") {
      return "The Village has won! May judiciousness always thrive!";
    }
  }
  function formatRoomRound(payload) {
    if (payload.round === "identity") {
      return "And the identity round begins. Flip your card open to see what role you have been bequeathed.";
    } else if (payload.round === "night") {
      return "The night of blood and rage has risen!";
    } else if (payload.round === "day") {
      return "One of us is going to breathe their last today, it better be the Mafia!";
    } else if (payload.round === "break") {
      return "Get ready for another treacherous night!";
    }
  }
  function formatPlayerStatus(payload) {
    const _player = playersStates[payload.id];
    if (payload.status === "killed") {
      if (_player) {
        return `The Mafia spilled out their wrath on ${_player.nickname}. ${_player.nickname}, you are dead!`;
      } else {
        return `Villagers, rejoice! The healer has saved the night with their scrutiny!`;
      }
    } else if (payload.status === "lynched") {
      if (_player) {
        if (_player.role && _player.role.includes("mafia")) {
          return `The Village hanged ${_player.nickname}, who indeed was a Mafia!`;
        } else {
          return `This world is truly not made for the innocent. The villagers hanged ${_player.nickname} who was one of their own.`;
        }
      }
    } else if (payload.status === "revenged") {
      if (_player && _player.nickname) {
        if (_player.role && _player.role.includes("mafia")) {
          return `The vengeful villager took a revenge on ${_player.nickname}, who indeed was a Mafia!`;
        } else {
          return `The vengeful villager took a revenge on ${_player.nickname} who was one of their own.`;
        }
      }
    } else if (payload.status === "alive") {
      return `${_player.nickname} joined the table.`;
    }
  }
  function formatPlayerVote(payload) {
    if (!payload.hidden) {
      const voter = playersStates[payload.id];
      const votee = playersStates[payload.vote];
      if (payload.vote === -1) {
        return `${voter.nickname} abstained their vote.`;
      } else if (voter && votee) {
        return `${voter.nickname} voted ${votee.nickname}.`;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  function formatTimeBank(payload) {
    const _player = playersStates[payload.id];
    if (_player) {
      return `${_player.nickname} has requested for extra time.`;
    }
  }
  const filterLogs = useCallback(
    (l) => {
      if (l.log !== null && l.log !== undefined) {
        if (roomStatus !== "ended_mafia" && roomStatus !== "ended_village") {
          if (player.status === "alive" && l.by !== -1) {
            if (typeof l.by === "string") {
              return false;
            } else {
              if (playersStates[l.by].status !== "alive") {
                return false;
              } else {
                return true;
              }
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    [player.status, playersStates, roomStatus]
  );

  return useMemo(
    () => (
      <aside className="pointer-events-auto rounded-lg overflow-hidden w-1/3 flex flex-col items-center">
        <div className="bg-mafia-primary text-mafia-secondary border-mafia-secondary border-b-2 text-center py-2 font-semibold w-full flex-grow-0">
          Chat
        </div>
        <div
          className={`border border-mafia-primary border-4 text-white w-full flex-grow scroll-hidden text-xs overflow-hidden flex flex-col items-start`}
          style={{ backgroundColor: "rgba(39, 44, 81, 0.9)" }}
        >
          <ul
            className={`overflow-auto flex-grow flex w-full shadow-inner flex-col items-start p-2 h-56`}
            ref={logsRef}
            style={{ wordBreak: "break-word" }}
          >
            {logs.filter(filterLogs).map((_log, i) => {
              return (
                <li key={i} className="mt-3 text-xs">
                  <span className="inline-flex font-bold items-center">
                    {typeof _log.by === "string" ? (
                      <span className="mr-2 flex items-center">
                        <span className="text-white">{_log.by}</span>
                        <span
                          className={`ml-1 leading-none whitespace-no-wrap font-normal text-white`}
                        >
                          (spectator)
                        </span>
                      </span>
                    ) : _log.by !== -1 ? (
                      <span className="mr-2 flex items-center">
                        <span
                          className={`${
                            COLORS[
                              +Object.keys(playersStates).indexOf("" + _log.by)
                            ]
                          }`}
                        >
                          {playersStates[_log.by].nickname}
                        </span>
                        {playersStates[_log.by].status === "lynched" && (
                          <span
                            className={`ml-1 leading-none whitespace-no-wrap font-normal ${
                              COLORS[
                                +Object.keys(playersStates).indexOf(
                                  "" + _log.by
                                )
                              ]
                            }`}
                          >
                            (lynched by Village)
                          </span>
                        )}
                        {playersStates[_log.by].status === "revenged" && (
                          <span
                            className={`ml-1 leading-none whitespace-no-wrap font-normal ${
                              COLORS[
                                +Object.keys(playersStates).indexOf(
                                  "" + _log.by
                                )
                              ]
                            }`}
                          >
                            (revenged by Village)
                          </span>
                        )}
                        {playersStates[_log.by].status === "killed" && (
                          <span
                            className={`ml-1 leading-none whitespace-no-wrap font-normal ${
                              COLORS[
                                +Object.keys(playersStates).indexOf(
                                  "" + _log.by
                                )
                              ]
                            }`}
                          >
                            (killed by Mafia)
                          </span>
                        )}
                      </span>
                    ) : (
                      <span className="text-mafia-secondary font-bold mr-2">
                        Godfather
                      </span>
                    )}
                    {/* <span className="text-gray-200">
                        {new Date(_log.time_create).toLocaleTimeString()}
                      </span> */}
                  </span>
                  <span
                    dangerouslySetInnerHTML={{ __html: renderLinks(_log.log) }}
                  ></span>
                </li>
              );
            })}
          </ul>
          {showForm() && (
            <form
              className="w-full flex flex-grow-0"
              onSubmit={handleMessageSend}
            >
              <input
                type="text"
                className="flex-grow bg-mafia-primary text-white p-2 border border-mafia-secondary rounded-bl-lg focus:outline-none"
                placeholder="Enter your message ..."
                {...bindChatMessage}
              />
              <input
                type="submit"
                value="Send"
                className="flex-shrink-0 flex items-center justify-center p-2 cursor-pointer focus:outline-none bg-mafia-secondary text-mafia-primary"
              />
            </form>
          )}
        </div>
        {referralBlockerVisibility && (
          <ReferralBlocker
            customClose={setReferralBlockerVisibility.bind(null, false)}
          />
        )}
      </aside>
    ),
    [
      logs,
      filterLogs,
      showForm,
      handleMessageSend,
      bindChatMessage,
      referralBlockerVisibility,
      playersStates,
    ]
  );
};

export default Chat;
