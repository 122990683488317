import React, { useState, useEffect, useMemo } from "react";

import Portal from "../../../../ui/Portal";

const TravelModal = ({
  travelDetails,
  close,
  partyMembers,
  sendPartyMessage,
  customCancelHandler,
  partyCode,
  hideCancel,
  isPartyHost,
}) => {
  const [secondsLeft, setSecondsLeft] = useState(
    partyMembers && partyMembers.length <= 1 ? 3 : 5
  );

  useEffect(() => {
    let mounted = true;
    if (secondsLeft >= 1) {
      setTimeout(() => {
        if (mounted) {
          setSecondsLeft((s) => --s);
        }
      }, 1000);
    } else if (secondsLeft === 0) {
      window.open(travelDetails.link, "_parent");
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsLeft]);

  const hostName = useMemo(() => {
    if (partyMembers) {
      const host = partyMembers.find((m) => m.code === partyCode);
      if (host) {
        return host.nickname;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [partyCode, partyMembers]);

  const handleCancel = () => {
    if (customCancelHandler && typeof customCancelHandler === "function") {
      customCancelHandler();
    } else {
      sendPartyMessage({ action: "travel_cancel", payload: {} });
    }
  };

  return (
    <Portal>
      <section className="fixed top-0 left-0 w-screen h-full bg-blur z-90 flex flex-col items-center justify-center">
        <section className="relative bg-white border-t-4 border-orange w-108 portrait:w-full rounded-lg shadow-2xl overflow-hidden">
          {travelDetails.icon && (
            <img
              className="absolute bottom-0 left-0 w-56 h-56 object-contain object-left-bottom"
              src={travelDetails.icon}
              alt=""
            />
          )}
          {travelDetails.avatar_url && (
            <img
              className="absolute bottom-0 left-0 w-108 object-contain object-left-bottom -ml-32 -mb-40 pointer-events-none"
              src={travelDetails.avatar_url}
              alt=""
            />
          )}
          <div className="flex flex-col items-center flex-shrink-0 pl-36 pr-4 py-8">
            <div className="uppercase tracking-wide text-gray-800 font-semibold mb-1 text-sm">
              Visiting
            </div>
            <div className="font-bold text-2xl text-orange">
              {travelDetails.cult}
            </div>
            {partyMembers && partyMembers.length > 1 ? (
              isPartyHost ? (
                <div>
                  with {partyMembers.length - 1} other
                  {partyMembers.length === 2 ? "" : "s"}
                </div>
              ) : (
                <div>
                  with {hostName && `${hostName} `}
                  {partyMembers.length > 2 &&
                    `and ${partyMembers.length - 2} other${
                      partyMembers.length === 3 ? "" : "s"
                    }`}
                </div>
              )
            ) : (
              ""
            )}
            <div className="mt-8 text-lg">
              Redirecting in <strong>{secondsLeft}</strong>
            </div>
            {!hideCancel && (
              <button
                className="bg-orange font-semibold py-3 px-4 rounded-lg shadow-md text-white mt-4"
                onClick={handleCancel}
              >
                Cancel
              </button>
            )}
          </div>
        </section>
      </section>
    </Portal>
  );
};

export default TravelModal;
