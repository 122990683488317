import getRandomString from "../utils/getRandomString";

const RTC = (sessionId, token, publisherId, sendSocketMessage) => {
  const trickleCandidate = (candidate) => {
    const body = {
      janus: "trickle",
      session_id: sessionId,
      handle_id: publisherId,
      token: token,
      transaction: getRandomString(10),
    };
    if (Array.isArray(candidate)) {
      body["candidates"] = candidate;
    } else {
      body["candidate"] = candidate;
    }
    sendSocketMessage(body);
  };

  return {
    trickleCandidate,
  };
};

export default RTC;
