import React, { useEffect, useState } from "react";

const AutoExpandingTextArea = React.forwardRef(
  ({ onChange, ...props }, ref) => {
    const [height, setHeight] = useState("inherit");

    useEffect(() => {
      setHeight(`${ref.current.scrollHeight}px`);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (e) => {
      setHeight(`${e.target.scrollHeight}px`);
      onChange(e);
    };
    return (
      <textarea
        {...props}
        ref={ref}
        onChange={handleChange}
        style={{ height }}
      />
    );
  }
);

export default AutoExpandingTextArea;
