import React, { useContext, useMemo } from "react";

import "./index.css";
import withMemo from "../../../../../utils/withMemo";
import Players from "./components/Players";
import Cards from "./components/Cards";
import TableText from "./components/TableText";
import RoomContext from "../../contexts/room";
import tournamentBg from "./../../assets/tournament-bg.svg";
import InGameContext from "../../contexts/inGame";

const Table = withMemo(() => {
  const { room } = useContext(RoomContext);
  const { roomRound } = useContext(InGameContext);

  const tableClass = useMemo(() => {
    const classes = [];
    if (room.players_count <= 3) {
      classes.push("table-3");
    } else if (room.players_count <= 5) {
      classes.push("table-5");
    } else if (room.players_count <= 8) {
      classes.push("table-8");
    } else if (room.players_count > 8 && room.players_count <= 10) {
      classes.push("table-10");
    } else if (room.players_count > 10 && room.players_count <= 12) {
      classes.push("table-12");
    }
    if (roomRound === "night") {
      classes.push("night-shadow");
    }
    return classes.join(" ");
  }, [room.players_count, roomRound]);

  return useMemo(
    () =>
      room.status !== "ended_mafia" && room.status !== "ended_village" ? (
        <section
          id="game-table"
          className={`relative rounded-full mt-20 ${tableClass}`}
        >
          {room.tournament && (
            <div className="absolute top-0 w-full h-full flex left-0 justify-center items-center pointer-events-none">
              <img className="w-64 h-auto" src={tournamentBg} alt="" />
            </div>
          )}
          <Cards />
          <Players />
          <TableText />
        </section>
      ) : null,
    [tableClass, room.status, room.tournament]
  );
}, []);

export default Table;
