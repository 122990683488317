import React, { useState, useEffect, useCallback } from "react";

// import "./index.css";
import Widget from "../../ui/Widget";
import Dates from "./components/Dates";
import CurrentDay from "./components/CurrentDay";
import useApi from "../../../../../hooks/useApi";

const Timeline = (props) => {
  const api = useApi("zo world", false);

  const [selected, setSelected] = useState(null);
  const [timeLineData, setTimelineData] = useState([]);

  useEffect(() => {
    if (api) {
      api
        .get("/zoworld/api/v1/history/")
        .then((res) => {
          setTimelineData(res.data);
          setSelected(res.data[0].date_text);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [api]);

  const hasNext = useCallback(() => {
    if (
      timeLineData.findIndex((t) => t.date_text === selected) <
      timeLineData.length - 1
    ) {
      return true;
    } else {
      return false;
    }
  }, [selected, timeLineData]);

  const hasPrevious = useCallback(() => {
    if (timeLineData.findIndex((t) => t.date_text === selected) === 0) {
      return false;
    } else {
      return true;
    }
  }, [selected, timeLineData]);

  const nextDate = useCallback(() => {
    const index = timeLineData.findIndex((t) => t.date_text === selected);
    if (timeLineData[index + 1]) {
      setSelected(null);
      setTimeout(() => {
        setSelected(timeLineData[index + 1].date_text);
      }, 200);
    } else {
      return;
    }
  }, [selected, timeLineData]);

  const previousDate = useCallback(() => {
    const index = timeLineData.findIndex((t) => t.date_text === selected);
    if (timeLineData[index - 1]) {
      setSelected(null);
      setTimeout(() => {
        setSelected(timeLineData[index - 1].date_text);
      }, 200);
    } else {
      return;
    }
  }, [selected, timeLineData]);

  const changeDate = (e) => {
    if (e.key === "ArrowLeft") {
      previousDate();
    } else if (e.key === "ArrowRight") {
      nextDate();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", changeDate);

    return () => {
      document.removeEventListener("keydown", changeDate);
    };
  });

  return (
    <Widget className="shadow-none flex flex-col overflow-visible" {...props}>
      <Dates
        dates={timeLineData.map((t) => t.date_text)}
        selected={selected}
        setSelected={setSelected}
      />
      <CurrentDay
        date={selected}
        selectedData={timeLineData.find((t) => t.date_text === selected)}
        hasPrevious={hasPrevious}
        hasNext={hasNext}
        nextDate={nextDate}
        previousDate={previousDate}
      />
    </Widget>
  );
};

export default Timeline;
