import React, { useRef } from "react";

interface IconButtonProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  loading?: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({
  children,
  className,
  onClick,
  style,
}) => {
  const defaultClass = useRef<string>(
    "p-2 hover:bg-gray-300 rounded-full focus:outline-none focus:shadow-outline "
  ).current;
  return (
    <button
      className={defaultClass.concat(className || "")}
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  );
};

export default IconButton;
