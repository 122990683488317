import { createContext } from "react";

const LobbyContext = createContext({
  members: [],
  connecting: false,
  socketUrl: null,
  message: null,
  socket: null,
  token: null,
  setToken: (token) => {},
  setMembers: (members) => {},
  setSocketUrl: (subdomain) => {},
  sendLobbyMessage: (message) => {},
});

export default LobbyContext;
