import { useEffect, useMemo, useState } from "react";
import useApi from "../../../../../../../hooks/useApi";

const LocationDisplay = ({ countriesData, initialData }) => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);

  const api = useApi("PROFILE_LOCATIONS");

  useEffect(() => {
    let mounted = true;
    if (initialData) {
      if (mounted && api) {
        api.get("/states/" + initialData.country + "/").then((res) => {
          if (mounted) {
            setStates(res.data.states);
            api.get("/cities/" + initialData.country + "/").then((res) => {
              if (mounted) {
                setCities(res.data.cities);
                setLoading(false);
              }
            });
          }
        });
      }
    } else {
      setLoading(false);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData, api]);

  const city = useMemo(
    () =>
      initialData &&
      cities.length &&
      cities.find((c) => c.id === initialData.city) &&
      cities.find((c) => c.id === initialData.city).name,
    [cities, initialData]
  );

  const state = useMemo(
    () =>
      states.length &&
      cities.length &&
      initialData &&
      cities.find((c) => c.id === initialData.city) &&
      cities.find((c) => c.id === initialData.city).state &&
      states.find(
        (c) => c.id === cities.find((c) => c.id === initialData.city).state
      ) &&
      states.find(
        (c) => c.id === cities.find((c) => c.id === initialData.city).state
      ).name,
    [cities, initialData, states]
  );

  const country = useMemo(
    () =>
      initialData &&
      countriesData.find((c) => c.id === initialData.country) &&
      countriesData.find((c) => c.id === initialData.country).name,
    [countriesData, initialData]
  );

  return loading
    ? "Fetching location ..."
    : city && state && country
    ? `${city}, ${state}, ${country}`
    : null;
};

export default LocationDisplay;
