import React, { useContext } from "react";

import SubSectionRow from "../../../components/SubSectionRow";
import ProfileContext from "../../../../../../../contexts/profile";

const PassportField = () => {
  const { profile } = useContext(ProfileContext);

  return (
    <SubSectionRow
      title="Passport Number"
      visibleArea={<strong>{profile.code}</strong>}
      placeholder="What do people call you by?"
      editable={false}
    />
  );
};

export default PassportField;
