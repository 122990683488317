import React from "react";

import Modal from "../../../../ui/Modal";
import { useInput, useResponseFlash } from "../hooks";
import bg from "../../../../../assets/suggest_cult_bg.svg";
import cultIcon from "../../../../../assets/cult.svg";
import useApi from "../../../../hooks/useApi";

const CultSuggestModal = ({ close }) => {
  const { value: name, bind: bindName } = useInput("");
  const { value: description, bind: bindDescription } = useInput("");
  const { response, setResponse } = useResponseFlash();
  const api = useApi("zo world", true);

  const handleSubmit = async () => {
    if (!name.trim().length) {
      setResponse("We need a name for the cult.");
      return;
    } else if (!description.trim().length) {
      setResponse("We need a description also.");
      return;
    } else {
      const data = {
        name,
        description,
      };
      try {
        await api.post("/zoworld/api/v1/request/cult/", JSON.stringify(data));
        setResponse("Suggestion submitted.");
        setTimeout(close, 1000);
      } catch (error) {
        console.log(error);
        setResponse("Unable to submit. Try again later.");
      }
    }
  };

  return (
    <Modal backgroundTheme="light" visible close={close}>
      <section
        className="flex flex-col items-center bg-white p-8 portrait:p-4 portrait:w-full border-t-4 border-orange rounded-lg overflow-hidden"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "bottom right",
          backgroundSize: "10rem",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="font-bold text-2xl text-center flex">
          <img src={cultIcon} alt="" className="w-12 h-12 mr-4 -mt-1" />
          Pitch Your Cult
        </div>
        <div className="w-2/3 text-gray-700 text-center mt-2">
          Create and lead your own culture for others to follow &amp; engage
          with in Zo World!
        </div>
        <div className="mt-4 w-108 portrait:w-full">
          <div className="font-medium text-sm mb-1">Name</div>
          <input
            className="p-2 border outline-none w-96 rounded-lg"
            type="text"
            placeholder="What would you like to name this influential cult?"
            {...bindName}
          />
        </div>
        <div className="mt-4 w-108 portrait:w-full">
          <div className="font-medium text-sm mb-1">Description</div>
          <textarea
            className="p-2 border outline-none w-full rounded-lg resize-none"
            placeholder="Tell us in under 200 words your idea behind the cult. Also mention how do you plan to lead it and make it grow? And how do you see it helping Zostelers?"
            rows="5"
            {...bindDescription}
          />
        </div>
        <div className="h-6 flex items-center justify-center font-semibold text-xs text-orange">
          {response}
        </div>
        <button
          className="bg-orange text-white cursor-pointer mb-2 font-semibold px-6 py-4 rounded-lg shadow-lg leading-none hover:shadow-xl"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </section>
    </Modal>
  );
};

export default CultSuggestModal;
