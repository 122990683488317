import React from "react";

import bg from "./../../../../../../../assets/pr-bg.jpg";
import arrowLeft from "./../../../../../../../assets/arrow-left.svg";
import arrowRight from "./../../../../../../../assets/arrow-right.svg";
import { useSeed } from "../../../../contexts/seed";

const CurrentDay = ({
  selectedData,
  hasNext,
  hasPrevious,
  nextDate,
  previousDate,
}) => {
  const { style } = useSeed();

  return selectedData ? (
    <div className="bg-gray-100 fade-in-left w-full h-full flex-grow flex flex-col items-center rounded-lg landscape:overflow-hidden shadow-lg">
      <div
        className="w-full text-center font-bold text-white zo-text-shadow mb-4 bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${selectedData.cover_img || bg})` }}
      >
        <div
          className="w-full h-full py-16 opacity-0"
          style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
        >
          <div className="text-xl -mt-5 -mb-2">{selectedData.date_text}</div>
          <div className="text-5xl portrait:text-3xl">{selectedData.title}</div>
        </div>
      </div>
      <div className="w-full  flex justify-center">
        <div className="w-1/6 flex items-center h-full justify-center">
          {hasPrevious() && (
            <button onClick={previousDate}>
              <img className="w-12 h-12" src={arrowLeft} alt="" />
            </button>
          )}
        </div>
        <div className="w-4/6 landscape:h-56 overflow-y-auto portrait:overflow-y-visible px-4">
          {selectedData.content && (
            <div className="text-lg leading-relaxed">
              <span className="text-5xl font-bold leading-none">
                {selectedData.content.substr(0, 1)}
              </span>
              {selectedData.content.substr(1)}
            </div>
          )}
          <div className="mt-4">
            {selectedData.links.map((link) => (
              <a
                key={link.url}
                className={`m-4 rounded-md text-${
                  style.accentColor || "orange"
                } font-medium p-2 hover:underline`}
                href={link.url}
              >
                {link.title}
              </a>
            ))}
          </div>
        </div>
        <div className="w-1/6 flex items-center h-full justify-center">
          {hasNext() && (
            <button onClick={nextDate}>
              <img className="w-12 h-12" src={arrowRight} alt="" />
            </button>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default CurrentDay;
