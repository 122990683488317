import React from "react";
import withMemo from "../../../../../utils/withMemo";

import ChatInput from "./ChatInput";
import ChatMessages from "./ChatMessages";
import EmojiFlash from "./EmojiFlash";

const Chat = withMemo(
  ({ active, setUnreadChatCount }) => {
    return (
      <section
        className="h-full w-screen flex-shrink-0 sm:w-full flex flex-col items-center p-2 sm:p-0"
        style={{
          transform: active ? "translateX(0)" : "translateX(-100vw)",
        }}
      >
        <ChatMessages active={active} setUnreadChatCount={setUnreadChatCount} />
        <EmojiFlash />
        <ChatInput />
      </section>
    );
  },
  ["active", "setUnreadChatCount"]
);

export default Chat;
