import React from "react";

const PlayerCount = ({ value, onChange, disabled, options }) => {
  return options.map((option) => (
    <div
      key={option}
      className={`w-8 h-8 flex items-center mr-2 justify-center rounded-lg ${
        value === option
          ? "bg-mafia-background text-white shadow-md"
          : "text-white"
      } ${disabled ? "cursor-prevent border-gray-700" : "cursor-pointer"}`}
      onClick={disabled ? null : onChange.bind(null, option)}
    >
      {option}
    </div>
  ));
};

export default PlayerCount;
