import Axios from "axios";
import PropTypes from "prop-types";
import { useContext, useEffect, useMemo, useState } from "react";
import AuthContext from "../contexts/auth";

const BASE_URL = process.env.REACT_APP_API_DOMAIN;
const CLIENT_APP_ID = "Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU";

const useApi = (collection, isAuthenticated) => {
  const { token, sessionId } = useContext(AuthContext);
  console.log("token", token, sessionId);

  const [baseURL, setBaseURL] = useState(null);

  useEffect(() => {
    switch (collection) {
      default:
        setBaseURL(`https://${BASE_URL}`);
    }
  }, [collection]);

  const authApi = useMemo(() => {
    console.log("token", token, sessionId, baseURL);
    return token && sessionId && baseURL
      ? Axios.create({
          baseURL,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            "Client-App-Id": CLIENT_APP_ID,
            "Client-User-Id": sessionId,
          },
        })
      : null;
  }, [token, sessionId, baseURL]);

  const unAuthApi = useMemo(() => {
    return baseURL
      ? Axios.create({
          baseURL,
          headers: {
            "Content-Type": "application/json",
          },
        })
      : null;
  }, [baseURL]);

  if (isAuthenticated) {
    return authApi;
  } else {
    return unAuthApi;
  }
};

useApi.propTypes = {
  collection: PropTypes.oneOf([
    "webapp",
    "feedback",
    "booking",
    "comms",
    "places",
    "payments",
    "games",
    "pms",
    "auth",
    "profile",
    "supply",
    "zo world",
  ]).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default useApi;
