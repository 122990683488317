import React, { ReactNode } from "react";

interface ButtonProps {
  icon?: ReactNode;
  className?: string;
  textClassName?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  type: "primary" | "secondary";
}

const Button: React.FC<ButtonProps> = ({
  icon,
  type,
  children,
  className = "",
  textClassName = "",
  onClick,
  style,
}) => {
  return (
    <button
      className={`${className} flex items-center p-3 shadow-zo-sm rounded-lg focus:outline-none focus:shadow-outline ${
        type === "primary" ? "bg-orange text-white" : "bg-white"
      }`}
      onClick={onClick}
      style={style}
    >
      {icon}
      {children && (
        <span
          className={`${textClassName} ${
            icon ? "ml-2" : "ml-0"
          } font-bold text-sm whitespace-no-wrap`}
        >
          {children}
        </span>
      )}
    </button>
  );
};

export default Button;
