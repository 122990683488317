const PATH = "/api/v1/lobby";

const ENDPOINTS = {
  LOBBY_ROOMS_COMMON_ONLINE: "/rooms/common/online/",
  LOBBY_ROOMS_PRIVATE_ONLINE: "/rooms/private/online/",
  LOBBY_ROOM: "/room/",
  LOBBY_ROOM_MAFIA_INFO_QUEUE: "/room/mafia/info/queue/",
};

export type LobbyEndpoints = keyof typeof ENDPOINTS;
export const LobbyCollection = { PATH, ENDPOINTS };
