import React from "react";

import underConstruction from "../../../assets/under_construction_01.svg";

const Locked = () => {
  return (
    <div className="flex items-center flex-col justify-center bg-white w-screen h-screen">
      <div
        className=" bg-center bg-contain w-3/4 bg-no-repeat h-screen"
        style={{
          backgroundImage: `url(${underConstruction})`,
        }}
      ></div>
    </div>
  );
};

export default Locked;
