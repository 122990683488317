import React, { useContext } from "react";

import RoomContext from "../../../contexts/room";

const RoomName = () => {
  const { room } = useContext(RoomContext);
  return (
    <section className="bg-mafia-primary font-bold tracking-normal uppercase inline-block text-lg p-2 rounded-lg shadow-lg">
      {room.name}
    </section>
  );
};

export default RoomName;
