const PATH = "/api/v1/zoworld";

const ENDPOINTS = {
  ZOWORLD_ROOM_EVENT: "/room/event/",
  ZOWORLD_ROOM_EVENT_UPCOMING: "/room/event/upcoming/",
  ZOWORLD_REPORT_USER: "/report/user/",
};

export type ZoWorldEndpoints = keyof typeof ENDPOINTS;
export const ZoWorldCollection = { PATH, ENDPOINTS };
