import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";

import Avatar from "../../../../../../ui/Avatar";
import useApi from "../../../../../../hooks/useApi";

const EventCard = ({ data, dateFormat, sendMessage, handleEdit }) => {
  const api = useApi("profile", true);

  const [host, setHost] = useState({
    nickname: "Fetching host details ...",
    avatar_url: null,
  });

  useEffect(() => {
    if (api) {
      api
        .get(`/profile/api/v1/profile/lobby/${data.host}/`)
        .then((res) => {
          setHost(res.data.profile);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [data.host, api]);

  const startTravel = () => {
    sendMessage({
      action: "travel",
      payload: {
        cult: host.lobby_name,
        link: data.url,
        avatar_url: host.avatar_url,
      },
    });
  };

  const hasStarted = useMemo(() => moment(data.start_time).isBefore(moment()), [
    data.start_time,
  ]);

  const hasAlmostStarted = useMemo(
    () => moment(data.start_time).subtract(30, "minutes").isBefore(moment()),
    [data.start_time]
  );

  return (
    <li className="flex flex-col fadeInLeft animation-delay-300 bg-white-translucent-90 rounded-lg shadow-lg mt-4">
      <header className="p-4 pb-0 flex items-center justify-between">
        <span className="font-medium text-sm text-gray-700">
          {moment(data.start_time).format(dateFormat)}
        </span>
        <div className="flex items-center">
          {hasStarted && (
            <span className="text-green-500 font-medium">In Progress</span>
          )}
          {handleEdit != null && (
            <>
              <button className="ml-4 focus:outline-none" onClick={handleEdit}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  className="w-4 h-4"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path
                    fill="#f15824"
                    d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                  />
                </svg>
              </button>
            </>
          )}
        </div>
      </header>
      <section className="p-4 pt-2 flex flex-col">
        <span className="font-bold text-lg text-orange">{data.name}</span>
        <span className="text-gray-800 mt-1 text-sm">{data.description}</span>
      </section>
      <footer className="flex justify-between items-center p-4 bg-gray-200 rounded-b-lg">
        <span className="flex items-center">
          <Avatar
            svg={host.avatar_url}
            className="w-6 h-6 rounded-full bg-gray-300 border-2 border-orange mr-2"
          />
          <span className="text-sm font-semibold text-gray-800 capitalize">
            {host.nickname}
          </span>
        </span>
        {hasAlmostStarted && (
          <button
            className="border border-orange bg-white rounded-lg text-sm p-2 leading-none font-medium text-orange"
            onClick={startTravel}
          >
            Go to Room
          </button>
        )}
      </footer>
    </li>
  );
};

export default EventCard;
