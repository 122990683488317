import React, { useContext, useState, useEffect } from "react";
import humanize from "humanize-number";

import Modal from "./../../../../ui/Modal";
import passport from "./../../assets/passport.jpg";
import { useInput, useResponseFlash } from "../hooks";
import ProfileContext from "../../../../contexts/profile";
import useApi from "../../../../hooks/useApi";

const SorryModal = ({ close, visa }) => {
  const { value: referralCode, bind: bindReferral } = useInput("");
  const api = useApi("profile", true);
  const { updateProfile } = useContext(ProfileContext);
  const { response, setResponse } = useResponseFlash();

  const [waitCount, setWaitCount] = useState(null);

  useEffect(() => {
    if (api) {
      api
        .get("/profile/api/v1/referral/queue/")
        .then((res) => {
          setWaitCount(res.data.count);
        })
        .catch((e) => {
          setWaitCount(Math.floor(Math.random() * 10000));
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  const handleReferral = async () => {
    if (referralCode.length) {
      try {
        const data = JSON.stringify({
          referral_code: referralCode,
        });
        const response = await api.post("/profile/api/v1/referral/", data);
        console.log(response);
        if (response.status === 200) {
          updateProfile({ status: "freeloader" });
          close();
        } else {
          setResponse("Invalid referral code.");
        }
      } catch (error) {
        console.log(error);
        setResponse("Error validating referral code. Try again.");
      }
    } else {
      setResponse("Enter a referral code.");
    }
  };

  return (
    <Modal visible close={close}>
      <div className="flex rounded-lg overflow-hidden">
        <div className="w-108 min-h-108 bg-white portrait:hidden">
          <img src={passport} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="w-108 portrait:w-full portrait:border-t-4 portrait:border-orange portrait:p-4 bg-white px-2 pt-4 py-16 flex flex-col items-center">
          <div className="font-bold text-3xl text-orange">
            You are in the Q!
          </div>
          <div className="mt-4 text-center mx-8">
            {waitCount && `${humanize(waitCount)} are ahead of you.`}
            <br />
            Estimated wait for citizenship: 14 days
          </div>
          <div className="font-medium text-xl mt-10 -mb-8 text-center mx-8">
            Jump the Q?
          </div>
          <div className="flex w-full items-center px-4 no-label items-center mt-4">
            <input
              className="border rounded-lg short p-4 mt-8 flex-grow"
              placeholder="Enter your referral code"
              {...bindReferral}
            />
            <button
              className="flex-shrink-0 ml-2 mt-8 bg-white text-orange border border-orange font-semibold uppercase tracking-wide px-6 py-4 rounded-lg leading-none hover:shadow-md"
              onClick={handleReferral}
            >
              Enter
            </button>
          </div>
          <div className="h-6 flex items-center justify-center font-semibold text-xs text-orange">
            {response}
          </div>
          <div className="mb-6">OR</div>
          <div className="flex items-center">
            <button
              className="bg-orange text-white mr-2 font-semibold uppercase tracking-wide px-6 py-4 rounded-lg shadow-lg leading-none hover:shadow-xl"
              onClick={visa}
            >
              Get Visa
            </button>
            and skip the Q!
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SorryModal;
