import React, { useContext, useEffect, useState } from "react";

import Countries from "./components/Countries";
import Roles from "./components/Roles";
import Timeline from "./components/Timeline";
import humanize from "humanize-number";
// import logo from "./../../../assets/logo.svg";
import { SeedContext } from "../../../../../contexts/contexts";
import Widget from "../../ui/Widget";
import useApi from "../../../../../hooks/useApi";

const Population = (props) => {
  const { seedData } = useContext(SeedContext);
  const api = useApi("zo world", false);

  const [populationMetrics, setPopulationMetrics] = useState(null);
  const [countriesList, setCountriesList] = useState(null);

  useEffect(() => {
    if (api) {
      try {
        api.get(`/zoworld/api/v1/population/metrics/`).then((res) => {
          setPopulationMetrics(res.data);
          api.get("/profile/api/v1/locations/countries/").then((res) => {
            setCountriesList(res.data.countries);
          });
        });
      } catch (error) {
        console.log(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <Widget className="bg-gray-100" {...props}>
      <div className="portrait:block grid grid-cols-3 p-4 gap-4">
        <Countries
          populationMetrics={populationMetrics}
          countriesList={countriesList}
          humanize={humanize}
        />
        <Roles
          populationMetrics={populationMetrics}
          seedData={seedData}
          humanize={humanize}
        />
        <Timeline populationMetrics={populationMetrics} humanize={humanize} />
      </div>
    </Widget>
  );
};

export default Population;
