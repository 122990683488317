import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Lock } from "../../../assets/icons";
import { useProfile, useRoom } from "../../../contexts";
import { getChildrenProps } from "../../../utils/react";
import { getWidget } from "../../../utils/room";
import Flex from "../../structure/Flex";
import SwitchToggle from "../SwitchToggle";

type childType = {
  widget: RoomWidget;
  updateWidget: (key: string, value: any) => void;
  isHost: boolean;
};

interface WidgetProps {
  title: string;
  id: string;
  hostOnly?: boolean;
  hasToggle?: boolean;
  children: (value: childType) => ReactNode;
}

const Widget: React.FC<WidgetProps> = ({
  title,
  children,
  id,
  hostOnly,
  hasToggle = false,
}) => {
  const { widgets, sendMessage, room } = useRoom();
  const { profile } = useProfile();

  const isHost = useMemo(
    () => (profile && room ? profile.subdomain === room.subdomain : false),
    [profile, room]
  );

  const [expanded, setExpanded] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);
  const [installed, setInstalled] = useState<boolean>(false);
  const [customOptions, setCustomOptions] = useState<ReactNode | null>(null);
  const [customUserOptions, setCustomUserOptions] =
    useState<ReactNode | null>(null);

  const widget = useMemo(
    () => (id != null ? getWidget(widgets, id) : undefined),
    [id, widgets]
  );

  const updateWidget = useMemo(
    () => (key: string, value: any) => {
      if (widget) {
        const newData = { ...widget.data };
        newData[key] = value;
        sendMessage({
          action: "widget_update",
          payload: {
            widget: widget.widget,
            data: newData,
          },
        });
      }
    },
    [sendMessage, widget]
  );

  const handleVisible = useMemo(
    () => (v: boolean) => {
      updateWidget("visible", v);
      setVisible(v);
    },
    [updateWidget]
  );

  useEffect(() => {
    if (widget) {
      const _chilren = getChildrenProps(
        children({ widget, updateWidget, isHost })
      );
      const hostOptions = _chilren[0]["data-host-options"];
      setCustomOptions(hostOptions);
      const userOptions = _chilren[0]["data-user-options"];
      setCustomUserOptions(userOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  const toggleExpand = () => {
    setExpanded((v) => !v);
  };

  useEffect(() => {
    if (widget) {
      setInstalled(true);
      setVisible(widget.meta.hostOnly || widget.data.visible);
    } else {
      setInstalled(false);
    }
  }, [widget]);

  useEffect(() => {
    if (visible) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [visible]);

  const widgetVisible = useMemo(() => {
    if (installed) {
      if (isHost) {
        return true;
      } else {
        if (!hostOnly && visible) {
          return true;
        }
      }
    }
    return false;
  }, [hostOnly, installed, isHost, visible]);

  return useMemo(
    () =>
      widgetVisible ? (
        <Flex
          col
          className="rounded-lg shadow-zo-sm mt-4 first:mt-0 md:w-108 md:mt-0 md:mb-4 md:mr-4 w-full h-content flex-shrink-0"
        >
          <header
            className={`flex items-center justify-between bg-white-translucent-75 z-10 sticky top-0 left-0  p-2 w-full cursor-pointer ${
              expanded ? "rounded-t-lg" : "rounded-lg"
            }`}
            onClick={toggleExpand}
          >
            <Flex items="center" className="mr-2">
              <h3 className="font-bold text-sm truncate">
                {widget?.data.title || title}
              </h3>
            </Flex>
            <Flex items="center">
              {expanded && customUserOptions}
              {isHost && expanded && customOptions}
              {isHost ? (
                hostOnly ? (
                  <Flex
                    items="center"
                    justify="center"
                    className="px-2 py-1 ml-2 border text-gray-800 border-gray-800 rounded-full text-xxs font-semibold"
                  >
                    <Lock className="w-2 h-2 stroke-2 mr-1" />
                    Visible to you
                  </Flex>
                ) : (
                  hasToggle && (
                    <>
                      <span className="mr-2 text-gray-800 text-xxs font-semibold">
                        {visible ? "Visible" : "Hidden"}
                      </span>
                      <SwitchToggle
                        name={String(id)}
                        value={visible}
                        onChange={handleVisible}
                      />
                    </>
                  )
                )
              ) : null}
            </Flex>
          </header>
          {expanded && widget && (
            <section className="bg-white-translucent-95 rounded-b-lg relative">
              {children({ widget, updateWidget, isHost })}
            </section>
          )}
        </Flex>
      ) : null,
    [
      children,
      customOptions,
      customUserOptions,
      expanded,
      handleVisible,
      hasToggle,
      hostOnly,
      id,
      isHost,
      title,
      updateWidget,
      visible,
      widget,
      widgetVisible,
    ]
  );
};

export default Widget;
