import React, { useContext } from "react";

import VoteSelect from "./VoteSelect";
import InGameContext from "../../../contexts/inGame";

const VoteSection = ({ text }) => {
  return (
    <div className="bg-white flex items-center rounded-lg shadow-lg ml-4">
      <div className="font-bold rounded-l-lg bg-mafia-primary text-lg uppercase tracking-normal text-white px-4 flex items-center h-16">
        {text}
      </div>
      <VoteSelect />
    </div>
  );
};

const VotingArea = () => {
  const { roomStatus, player, roomRound } = useContext(InGameContext);

  if (roomStatus === "ended_mafia" || roomStatus === "ended_village") {
    return null;
  } else if (player.status === "alive") {
    if (roomRound === "night") {
      if (player.role && player.role.includes("mafia")) {
        return <VoteSection text="Assasinate" />;
      } else if (player.role === "detective") {
        return <VoteSection text="Guess Mafia" />;
      } else if (player.role === "healer") {
        return <VoteSection text="Save" />;
      } else {
        return null;
      }
    } else if (roomRound === "day") {
      return <VoteSection text="Lynch" />;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default VotingArea;
