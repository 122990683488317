import React, { useState, useEffect } from "react";

import useApi from "../../../../hooks/useApi";
import footer2 from "./../../assets/footer-2.svg";
import Stripe from "../../../Stripe";
import Portal from "../../../../ui/Portal";

const PaymentQuoteModal = ({
  visible,
  close,
  showPaymentForm,
  initialPriceId,
}) => {
  const api = useApi("payments", true);

  const [selected, setSelected] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [priceId, setPriceId] = useState();

  useEffect(() => {
    if (initialPriceId != null) {
      setPriceId(initialPriceId);
      setSelected(initialPriceId);
    }
  }, [initialPriceId]);

  useEffect(() => {
    if (api) {
      api
        .get("/subscription/api/v1/plans/")
        .then((res) => {
          setPaymentData(res.data);
          console.log(res.data);
          setSelected(res.data.plans[0].id);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [api]);

  const handleBuy = () => {
    setPriceId(selected);
  };

  return (
    <Portal>
      <section
        className={`w-screen h-screen fixed inset-0 bg-blur flex items-center justify-center ${
          visible
            ? " z-90 opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
      >
        <div className="relative border-orange border-t-4 bg-white p-6 pb-0 rounded-lg shadow-2xl h-120 w-144 flex flex-col items-center overflow-hidden">
          <header className="font-black text-3xl fadeInUp animation-delay-100">
            Become a <span className="text-orange">Zo World user</span>!
          </header>
          {paymentData ? (
            <>
              {paymentData.stripe_key && priceId && (
                <Stripe
                  stripeKey={paymentData.stripe_key}
                  priceId={priceId}
                  back={setPriceId.bind(null, null)}
                  showPaymentForm={showPaymentForm}
                />
              )}
              {!priceId && (
                <>
                  <div className="flex w-full mt-6">
                    {paymentData &&
                      paymentData.plans.map((plan) => (
                        <div
                          key={plan.id}
                          className={`px-2 pt-4 pb-10 relative overflow-hidden flex flex-col items-center justify-center w-1/3 text-center mr-2 border rounded-md cursor-pointer ${
                            selected === plan.id
                              ? "shadow-lg bg-orange"
                              : "bg-white"
                          }`}
                          onClick={setSelected.bind(null, plan.id)}
                        >
                          <div
                            className={`text-sm font-semibold mb-2 ${
                              selected === plan.id
                                ? "text-white"
                                : "text-orange"
                            }`}
                          >
                            {plan.name}
                          </div>
                          <div
                            className={selected === plan.id ? "text-white" : ""}
                          >
                            <span className="text-lg font-bold">
                              ₹{plan.price}
                            </span>
                            / {plan.short_text}
                          </div>
                          <div
                            className={`w-full p-1 text-xs font-medium bottom-0 left-0 text-center absolute ${
                              selected === plan.id
                                ? " bg-white text-orange"
                                : " bg-orange text-white"
                            }`}
                          >
                            {plan.discount_text}
                          </div>
                        </div>
                      ))}
                  </div>
                  <button
                    className="w-auto inline-block bg-orange text-white font-semibold px-6 py-4 mt-6 rounded-lg shadow-lg leading-none hover:shadow-xl"
                    onClick={handleBuy}
                  >
                    Buy{" "}
                    {paymentData.plans.find((p) => p.id === selected) &&
                      paymentData.plans.find((p) => p.id === selected).name}
                  </button>
                  <div
                    className="text-gray-700 font-semibold mt-1 text-sm cursor-pointer"
                    onClick={close}
                  >
                    No, Thanks
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="w-full py-16 text-orange font-bold flex items-center justify-center">
              Fetching plans ...
            </div>
          )}
          <footer
            className="bg-contain bg-bottom bg-no-repeat h-40 mt-6 flex-shrink-0 w-full fadeInUp flex-grow"
            style={{ backgroundImage: `url(${footer2})` }}
          ></footer>
        </div>
      </section>
    </Portal>
  );
};

export default PaymentQuoteModal;
