import React, { useEffect, useRef, useState } from "react";
import { Edit2, Save } from "../../../assets/icons";
import { useProfile, useRoom, useSeed } from "../../../contexts";
import useApi from "../../../hooks/useApi";
import useResponseFlash from "../../../hooks/useResponseFlash";
import { copyToClipboard } from "../../../utils/api/misc";
import { getBackgroundFromKey, randomString } from "../../../utils/room";
import BackgroundSheet from "../../bottomsheets/BackgroundSheet";
import Flex from "../../structure/Flex";
import IconButton from "../../ui/IconButton";
import TextToggle from "../../ui/TextToggle";
import Widget from "../../ui/Widget";
import privacy from "./../../../configs/privacy.json";
import speakabilityOptions from "./../../../configs/speakability.json";

interface RoomSettingsProps {}

const RoomSettings: React.FC<RoomSettingsProps> = () => {
  const { backgrounds } = useSeed();
  const { updateProfileKey } = useProfile();
  const { sendMessage, room } = useRoom();
  const api = useApi("PROFILE_ME_LOBBY");

  const inputRef = useRef<HTMLInputElement>(null);
  const [isBackgroundSheetVisible, setBackgroundSheetVisibility] =
    useState<boolean>(false);
  const [isEditingName, setEditingName] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordToken, setPasswordToken] = useState<string>("");

  const { response: copiedResponse, setResponse: setCopiedResponse } =
    useResponseFlash();
  const { response: newLinkResponse, setResponse: setNewLinkResponse } =
    useResponseFlash();

  const updateSetting = async (key: string, value: any) => {
    if (updateProfileKey) {
      const updated = await updateProfileKey(key, value);
      if (updated) {
        const data: GeneralObject = {};
        data[key] = value;
        sendMessage({
          action: `update_${key}`,
          payload: data,
        });
      }
    }
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
  };

  const toggleBackgroundSheet = () => {
    setBackgroundSheetVisibility((v) => !v);
  };

  const saveName: React.FormEventHandler = (e) => {
    e.preventDefault();
    updateSetting("lobby_name", value);
    setEditingName(false);
  };

  const getSharableLink = () => {
    if (!passwordToken) {
      generateNewLink();
    }
    const link =
      room?.security === 2 || room?.security === 3
        ? `https://${process.env.REACT_APP_ROOT_DOMAIN}/@${room?.subdomain}?t=${passwordToken}`
        : `https://${process.env.REACT_APP_ROOT_DOMAIN}/@${room?.subdomain}`;
    copyToClipboard(link);
    setCopiedResponse("Link copied successfully");
  };

  const generateNewLink = async () => {
    if (!loading) {
      setLoading(true);
      const password = randomString(12);
      try {
        if (api) {
          const data = {
            password: password,
          };
          const responsePassword = await api.post(
            "/password/",
            JSON.stringify(data)
          );
          if (responsePassword.status === 200) {
            setNewLinkResponse("Link Generated!");
            setPasswordToken(responsePassword.data.token);
          } else {
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isEditingName) {
      setValue(room?.lobby_name || "");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingName]);

  return (
    <>
      <Widget title="Room Settings" id="room-settings" hostOnly>
        {() => (
          <Flex col className="p-2">
            <Flex col className="mb-2 w-full">
              <span className="font-bold text-xs mb-1">Room Name</span>
              {isEditingName ? (
                <form
                  onSubmit={saveName}
                  className="w-full flex items-center justify-between"
                >
                  <input
                    ref={inputRef}
                    type="text"
                    value={value}
                    onChange={handleChange}
                    className="p-1 text-sm w-full flex-1 mr-2"
                    maxLength={28}
                  />
                  <IconButton>
                    <Save className="w-4 h-4" />
                  </IconButton>
                </form>
              ) : (
                <Flex
                  items="center"
                  justify="between"
                  className="w-full"
                  onClick={setEditingName.bind(null, true)}
                >
                  <span className="p-1 text-sm w-full truncate flex-1 mr-2">
                    {room?.lobby_name}
                  </span>
                  <IconButton>
                    <Edit2 className="w-4 h-4" />
                  </IconButton>
                </Flex>
              )}
            </Flex>
            <Flex className="w-full">
              <Flex col className="flex-1 pr-2">
                <div className="mb-2">
                  <span className="font-bold text-xs mb-1">Speakability</span>
                  <TextToggle
                    value={room?.speakability}
                    options={speakabilityOptions}
                    onChange={updateSetting.bind(null, "speakability")}
                  />
                </div>
                <div>
                  <span className="font-bold text-xs mb-1">Privacy</span>
                  <TextToggle
                    value={room?.security}
                    options={privacy}
                    onChange={updateSetting.bind(null, "security")}
                  />
                </div>
              </Flex>
              <Flex col>
                <span className="font-bold text-xs">Background</span>
                <img
                  src={getBackgroundFromKey(room?.background_key, backgrounds)}
                  alt="bg"
                  className="w-full h-24 rounded-lg object-cover border border-gray-300 cursor-pointer"
                  onClick={toggleBackgroundSheet}
                />
              </Flex>
            </Flex>
            {(room?.security === 2 || room?.security === 3) && (
              <Flex col className="mt-2">
                <span className="font-bold text-xs mb-1">Room invite link</span>
                <Flex items="center" className="w-full">
                  {copiedResponse ? (
                    <div className="rounded-l-lg py-1 px-2 flex-1 text-center whitespace-no-wrap border font-semibold text-xs">
                      Link Copied!
                    </div>
                  ) : (
                    <button
                      className="bg-orange flex-1 text-center focus:outline-none py-1 px-2 border border-orange whitespace-no-wrap font-semibold text-xs text-white rounded-l-lg"
                      onClick={getSharableLink}
                    >
                      Copy Secure Link
                    </button>
                  )}
                  {room?.security === 2 || room?.security === 3 ? (
                    newLinkResponse ? (
                      <div className="py-1 px-2 rounded-r-lg flex-1 text-center whitespace-no-wrap border font-semibold text-xs">
                        Link Generated!
                      </div>
                    ) : (
                      <button
                        className="py-1 flex-1 text-center focus:outline-none items-center px-2 w-full whitespace-no-wrap border border-orange text-orange font-semibold text-xs rounded-r-lg"
                        onClick={generateNewLink}
                      >
                        Generate New Link
                      </button>
                    )
                  ) : null}
                </Flex>
              </Flex>
            )}
          </Flex>
        )}
      </Widget>
      {room && (
        <BackgroundSheet
          open={isBackgroundSheetVisible}
          onDismiss={toggleBackgroundSheet}
          selected={String(room.background_key)}
          onChange={updateSetting.bind(null, "background_key")}
        />
      )}
    </>
  );
};

export default RoomSettings;
