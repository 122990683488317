import React, { useContext } from "react";

import mafiaIcon from "./../../../assets/icon-mafia.svg";
import detectiveIcon from "./../../../assets/icon-detective.svg";
import healerIcon from "./../../../assets/icon-healer.svg";
import villagerIcon from "./../../../assets/icon-villager-boy-2.svg";
import RoomContext from "../../../contexts/room";

const CharacterCounts = () => {
  const { room } = useContext(RoomContext);

  return (
    <section className="flex font-bold tracking-normal uppercase inline-block text-lg rounded-lg shadow-lg">
      <span title="Mafia(s)" className="flex items-center">
        <img className="w-5 h-5 mr-2" src={mafiaIcon} alt="M" />{" "}
        <strong>{room.mafia_count + room.mafia_semi_count}</strong>
      </span>
      <span title="Detective(s)" className="ml-4 flex items-center">
        <img className="w-5 h-5 mr-2" src={detectiveIcon} alt="D" />{" "}
        <strong>{room.detective_count}</strong>
      </span>
      <span title="Healer(s)" className="ml-4 flex items-center">
        <img className="w-5 h-5 mr-2" src={healerIcon} alt="H" />{" "}
        <strong>{room.healer_count}</strong>
      </span>
      <span title="Villager(s)" className="ml-4 flex items-center">
        <img className="w-5 h-5 mr-2" src={villagerIcon} alt="V" />{" "}
        <strong>{room.villager_count + room.villager_save_count}</strong>
      </span>
    </section>
  );
};

export default CharacterCounts;
