import React, { useMemo } from "react";

import Avatar from "../../../../../ui/Avatar";

const Member = ({ lastElement, avatar, nickname }) => {
  // const { profile } = useContext(ProfileContext);
  // const modalRef = useRef();
  // const [isOptionsVisible, setOptionsVisible] = useState(false);

  // useEffect(() => {
  //   if (isOptionsVisible) {
  //     document.addEventListener("click", checkClick);
  //   }
  //   return () => {
  //     document.removeEventListener("click", checkClick);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isOptionsVisible]);

  // const checkClick = (e) => {
  //   if (modalRef.current && !modalRef.current.contains(e.target)) {
  //     setOptionsVisible(false);
  //   }
  // };

  // const toggleOptionsVisibility = () => {
  //   setOptionsVisible((v) => !v);
  // };

  return useMemo(
    () => (
      <li className="inline-flex flex-col items-center w-1/5 h-18 my-3">
        <div
          className="flex flex-col items-center w-full h-full relative group w-16 mb-5 cursor-pointer"
          // onClick={toggleOptionsVisibility}
        >
          <div className="relative w-12 h-12 rounded-full bg-mafia-primary">
            <Avatar svg={avatar} className="w-full h-full" />
          </div>
          <div className="w-16 leading-none text-center text-gray-800 group-hover:w-auto group-hover:bg-white group-hover:z-10 group-hover:border rounded-lg p-1 absolute bottom-0 truncate text-xs font-medium mt-2 -mb-6">
            {nickname}
          </div>
        </div>
        {/* {isOptionsVisible && (
          <div
            className="fixed top-0 rounded-lg overflow-hidden h-auto bg-gray-900 shadow-lg mb-2 text-white z-50"
            ref={modalRef}
            style={{
              left:
                window.innerHeight < window.innerWidth
                  ? "24.5rem"
                  : "calc(50%)",
              minWidth: "10rem",
            }}
          >
            <div className="bg-white flex flex-col items-center">
              <Avatar className="h-56" svg={avatar} full />
            </div>
            <div className="p-2 border-b text-center text-sm border-gray-700 font-medium">
              {nickname}
            </div>
            {isHost && (
              <div className="flex items-center justify-around py-2 border-b border-gray-700">
                <button
                  className="bg-white focus:outline-none text-gray-900 text-sm cursor-pointer font-semibold p-2 rounded-lg shadow-lg leading-none hover:shadow-xl disabled:cursor-not-allowed"
                  onClick={handlePlayerKick}
                  disabled={isUser}
                >
                  Kick
                </button>
                <button
                  className={`text-sm focus:outline-none font-semibold p-2 rounded-lg shadow-lg leading-none hover:shadow-xl ${
                    muted
                      ? "text-white cursor-default"
                      : "bg-white text-gray-900"
                  }`}
                  onClick={handlePlayerMute}
                  disabled={muted}
                >
                  {muted ? "Muted" : "Mute"}
                </button>
              </div>
            )}
            {!isUser && !inSameParty && (
              <div className="flex items-center justify-around p-2 border-b border-gray-700">
                {!joinRequested ? (
                  <button
                    className="mr-2 bg-white focus:outline-none whitespace-no-wrap text-gray-900 text-xs cursor-pointer font-semibold p-1 rounded-lg shadow-lg leading-none hover:shadow-xl disabled:cursor-not-allowed"
                    onClick={handlePartyJoinRequest}
                  >
                    Join Party
                  </button>
                ) : (
                  <div className="p-1 leading-none text-white text-xs font-medium text-center whitespace-no-wrap mr-2">
                    Join Requested
                  </div>
                )}
                {!inviteRequested ? (
                  <button
                    className="bg-white focus:outline-none whitespace-no-wrap text-gray-900 text-xs cursor-pointer font-semibold p-1 rounded-lg shadow-lg leading-none hover:shadow-xl disabled:cursor-not-allowed"
                    onClick={handlePartyInviteRequest}
                  >
                    Invite to Party
                  </button>
                ) : (
                  <div className="p-1 leading-none text-white text-xs font-medium text-center whitespace-no-wrap">
                    Invitation sent
                  </div>
                )}
              </div>
            )}
            {isPremium && (
              <div
                className="flex justify-center py-2"
                onClick={window.open.bind(
                  null,
                  `https://${subdomain}.${process.env.REACT_APP_ROOT_DOMAIN}`,
                  "_parent",
                  "",
                  false
                )}
              >
                <button className="text-xs cursor-pointer font-semibold hover:underline">
                  View Lobby
                </button>
              </div>
            )}
          </div>
        )} */}
      </li>
    ),
    [avatar, nickname]
  );
};

export default Member;
