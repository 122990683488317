import { createContext } from "react";

const SocketContext = createContext({
  message: null,
  connecting: false,
  socket: null,
  setToken: (token) => {},
  startSocketConnection: (url) => {},
  closeSocketConnection: () => {},
  sendSocketMessage: (action, payload) => {},
});

export default SocketContext;
