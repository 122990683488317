import React, { useContext, useEffect, useState } from "react";
import Modal from "../../../../../../../components/ui/Modal";
import withMemo from "../../../../../../../utils/withMemo";
import { useRTC } from "../../../../../contexts";
import GameContext from "../../../contexts/game";

const RTCSettings = withMemo(
  ({ forceMute, forceSilent }) => {
    const { isActive, setIsSelfMute, isSelfMute } = useRTC();
    const { player } = useContext(GameContext);

    const [forceSilentDialogShown, hideForceSilentDialog] = useState(false);

    useEffect(() => {
      if (player.status === "spectate") {
        setIsSelfMute(true);
      }
      // if (!sessionStorage.getItem("forceSilent")) {
      //   sessionStorage.setItem("forceSilent", "false");
      // }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [player]);

    useEffect(() => {
      if (forceMute) {
        setIsSelfMute(true);
      } else {
        setIsSelfMute(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceMute]);

    useEffect(() => {
      if (forceSilent) {
        // setStreamMuted(true);
      } else {
        // setStreamMuted(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceSilent]);

    // const [streamAllowed, setStreamAllowed] = useState(false);
    // const { message } = useContext(SocketContext);
    // const audioMuteState = useRef(true);

    // useEffect(() => {
    //   if (message && message.action) {
    //     if (message.action === "player_stream") {
    //       setStreamAllowed(message.payload.audio);
    //     }
    //   }
    // }, [message]);

    // useEffect(() => {
    //   if (streamAllowed) {
    //     setIsSelfMute(audioMuteState.current);
    //   } else {
    //     setIsSelfMute(true);
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [streamAllowed]);

    // const handleVoice = () => {
    //   if (streamAllowed) {
    //     audioMuteState.current = !isSelfMute;
    //     setIsSelfMute((s) => !s);
    //   }
    // };

    return player.status !== "spectate" ? (
      <section>
        {isActive && (
          <button
            className={`bg-mafia-primary p-2 rounded-lg shadow-lg text-white ${
              forceMute
                ? "cursor-not-allowed bg-gray-800 mr-96"
                : "bg-mafia-primary"
            }`}
            onClick={() => {
              if (!forceMute) {
                setIsSelfMute((s) => !s);
              }
            }}
          >
            {forceMute ? (
              <span className="text-xs font-bold">You have been muted</span>
            ) : isSelfMute ? (
              <svg
                className="fill-current w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
              >
                <path d="M15 10.6V5c0-1.66-1.34-3-3-3-1.54 0-2.79 1.16-2.96 2.65L15 10.6zm3.08.4c-.41 0-.77.3-.83.71-.05.32-.12.64-.22.93l1.27 1.27c.3-.6.52-1.25.63-1.94.07-.51-.33-.97-.85-.97zM3.71 3.56c-.39.39-.39 1.02 0 1.41L9 10.27v.43c0 1.19.6 2.32 1.63 2.91.75.43 1.41.44 2.02.31l1.66 1.66c-.71.33-1.5.52-2.31.52-2.54 0-4.88-1.77-5.25-4.39-.06-.41-.42-.71-.83-.71-.52 0-.92.46-.85.97.46 2.96 2.96 5.3 5.93 5.75V20c0 .55.45 1 1 1s1-.45 1-1v-2.28c.91-.13 1.77-.45 2.55-.9l3.49 3.49c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L5.12 3.56c-.39-.39-1.02-.39-1.41 0z" />
              </svg>
            ) : (
              <svg
                className="fill-current w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
              >
                <path d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.91-3c-.49 0-.9.36-.98.85C16.52 14.2 14.47 16 12 16s-4.52-1.8-4.93-4.15c-.08-.49-.49-.85-.98-.85-.61 0-1.09.54-1 1.14.49 3 2.89 5.35 5.91 5.78V20c0 .55.45 1 1 1s1-.45 1-1v-2.08c3.02-.43 5.42-2.78 5.91-5.78.1-.6-.39-1.14-1-1.14z" />
              </svg>
            )}
          </button>
        )}
        {forceSilent && !forceSilentDialogShown && (
          <Modal visible close={() => {}} hideCloseButton disableCloseOutside>
            <div className="flex flex-col items-center font-semibold items-center justify-center border-t-4 border-mafia-secondary bg-mafia-background">
              <div className="border-b-2 w-full py-4 text-xl uppercase tracking-wide font-bold text-center text-mafia-secondary">
                NOTE
              </div>
              <div className="w-96 p-4 text-gray-400 text-center">
                Your audio has been disabled for the night.
                <br />
                Sleep tight.
              </div>
              <button
                className="text-lg uppercase tracking-wide text-mafia-primary mb-4 bg-mafia-secondary px-4 py-3 leading-none rounded-lg hover:shadow-lg font-semibold"
                onClick={() => {
                  hideForceSilentDialog(true);
                  sessionStorage.setItem("forceSilent", "true");
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        )}
      </section>
    ) : null;
  },
  ["forceMute", "forceSilent"]
);

export default RTCSettings;
