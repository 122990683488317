import { createHash } from "crypto";
import React, { useRef, useState } from "react";
import LogInButton from "../../../../../../../components/contexts/auth/components/LogInButton";
import useApi from "../../../../../hooks/useApi";

const getSHA1 = function () {
  return createHash("sha1")
    .update(
      JSON.stringify({
        timestamp: Date.now(),
        rand: Math.random() * 10000,
      })
    )
    .digest("hex")
    .substr(0, 10);
};

const createNewSession = () => {
  const sessionId = getSHA1();
  return sessionId;
};

const LogInOptions = () => {
  const api = useApi("AUTH_ANON_LOGIN");
  const [anonLogin, setAnonLogin] = useState(false);
  const [nickname, setNickname] = useState("");
  const anonId = useRef(createNewSession().substr(0, 4));

  const loginAnon = async () => {
    try {
      if (nickname.trim().length) {
        const _nickname = `${nickname.trim()}${anonId.current}`;
        const data = {
          nickname: _nickname.replace(/ /g, ""),
        };
        const response = await api.post("", JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            "Client-App-Id": "Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU",
            "Client-User-Id": createNewSession(),
          },
        });
        console.log(response);
        if (response.status === 200) {
          localStorage.setItem("anon_user", JSON.stringify(response.data.user));
          localStorage.setItem("anon_user_token", response.data.user_token);
          localStorage.setItem("anon_token_expiry", response.data.token_expiry);
          localStorage.setItem("anon_nickname", data.nickname);
          window.location.reload();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNickname = (e) => {
    setNickname(e.target.value);
  };

  return !anonLogin ? (
    <>
      <LogInButton className="bg-gray-100 text-gray-800 px-8 py-2 rounded-lg shadow-lg hover:shadow-xl" />
      <span
        className="block text-gray-100 font-medium text-xs pt-1 cursor-pointer hover:underline"
        onClick={setAnonLogin.bind(null, true)}
      >
        Login anonymously
      </span>
    </>
  ) : (
    <div className="w-2/3">
      <div className="rounded-lg overflow-hidden shadow-sm w-full h-full px-4 py-1 mb-4">
        <div className="flex items-center mt-4">
          <div className="relative mr-2 w-full">
            <input
              type="text"
              placeholder="Choose a Nickname"
              className="p-2 pr-16 border rounded-lg border bg-gray-300"
              value={nickname}
              onChange={handleNickname}
            />
            <span
              className="absolute top-0 right-0 block bg-gray-500 rounded-r-lg"
              style={{ padding: "0.56rem" }}
            >
              {anonId.current}
            </span>
          </div>
          <button
            className="bg-orange text-white py-2 px-4 rounded-lg"
            onClick={loginAnon}
          >
            Login
          </button>
        </div>
        <span className="text-xs text-white">
          Your anonymous username: {`${nickname}${anonId.current}`}
        </span>
      </div>
    </div>
  );
};

export default LogInOptions;
