import React, { useState } from "react";

import widgetsIcon from "./../../../../../assets/settings-widgets.svg";
import backgroundIcon from "./../../../../../assets/settings-background.svg";
import soundIcon from "./../../../../../assets/settings-sound.svg";
import speakabilityIcon from "./../../../../../assets/settings-speakability.svg";
import securityIcon from "./../../../../../assets/settings-security.svg";
import AddWidgetsModal from "../../modals/HostSettings/AddWidgetsModal";
import BackgroundsModal from "../../modals/HostSettings/BackgroundsModal";
import AmbientSoundModal from "../../modals/HostSettings/AmbientSoundModal";
import SecurityModal from "../../modals/HostSettings/SecurityModal";
import SpeakabilityModal from "../../modals/HostSettings/SpeakabilityModal";

const HostSettings = ({
  hasPremium,
  whiteboardText,
  background,
  sound,
  security,
  speakability,
}) => {
  const [addWidgetsVisible, setAddWidgetsVisible] = useState(false);
  const [backgroundsVisible, setBackgroundsVisible] = useState(false);
  const [ambientSoundVisible, setAmbientSoundVisible] = useState(false);
  const [speakabilityVisible, setSpeakabilityVisible] = useState(false);
  const [securityVisible, setSecurityVisible] = useState(false);

  return hasPremium ? (
    <>
      <button
        className="focus:outline-none relative group-a bg-white rounded-full shadow-md font-medium p-3 mx-2 text-orange flex items-center flex-col hover:bg-gray-300"
        onClick={setAddWidgetsVisible.bind(null, true)}
      >
        <img src={widgetsIcon} alt="" className="w-6 h-6" />
        <span className="absolute top-full mt-1 pointer-events-none z-40 opacity-0 bg-gray-700 text-white whitespace-no-wrap font-medium px-2 py-1 leading-none rounded-full text-xs group-a-hover:opacity-100">
          Widgets
        </span>
      </button>
      <button
        className="focus:outline-none relative group-a bg-white rounded-full shadow-md font-medium p-3 mx-2 text-orange flex items-center flex-col hover:bg-gray-300"
        onClick={setBackgroundsVisible.bind(null, true)}
      >
        <img src={backgroundIcon} alt="" className="w-6 h-6" />
        <span className="absolute top-full mt-1 pointer-events-none z-40 opacity-0 bg-gray-700 text-white whitespace-no-wrap font-medium px-2 py-1 leading-none rounded-full text-xs group-a-hover:opacity-100">
          Background Settings
        </span>
      </button>
      <button
        className="focus:outline-none relative group-a bg-white rounded-full shadow-md font-medium p-3 mx-2 text-orange flex items-center flex-col hover:bg-gray-300"
        onClick={setAmbientSoundVisible.bind(null, true)}
      >
        <img src={soundIcon} alt="" className="w-6 h-6" />
        <span className="absolute top-full mt-1 pointer-events-none z-40 opacity-0 bg-gray-700 text-white whitespace-no-wrap font-medium px-2 py-1 leading-none rounded-full text-xs group-a-hover:opacity-100">
          Ambient Sound Settings
        </span>
      </button>
      <button
        className="focus:outline-none relative group-a bg-white rounded-full shadow-md font-medium p-3 mx-2 text-orange flex items-center flex-col hover:bg-gray-300"
        onClick={setSpeakabilityVisible.bind(null, true)}
      >
        <img src={speakabilityIcon} alt="" className="w-6 h-6" />
        <span className="absolute top-full mt-1 pointer-events-none z-40 opacity-0 bg-gray-700 text-white whitespace-no-wrap font-medium px-2 py-1 leading-none rounded-full text-xs group-a-hover:opacity-100">
          Speakability Settings
        </span>
      </button>
      <button
        className="focus:outline-none relative group-a bg-white rounded-full shadow-md font-medium p-3 mx-2 text-orange flex items-center flex-col hover:bg-gray-300"
        onClick={setSecurityVisible.bind(null, true)}
      >
        <img src={securityIcon} alt="" className="w-6 h-6" />
        <span className="absolute top-full mt-1 pointer-events-none z-40 opacity-0 bg-gray-700 text-white whitespace-no-wrap font-medium px-2 py-1 leading-none rounded-full text-xs group-a-hover:opacity-100">
          Security Settings
        </span>
      </button>

      {addWidgetsVisible && (
        <AddWidgetsModal
          close={setAddWidgetsVisible.bind(null, false)}
          whiteboardText={whiteboardText}
        />
      )}
      {backgroundsVisible && (
        <BackgroundsModal
          close={setBackgroundsVisible.bind(null, false)}
          current={background}
        />
      )}
      {ambientSoundVisible && (
        <AmbientSoundModal
          close={setAmbientSoundVisible.bind(null, false)}
          sound={sound}
        />
      )}
      {securityVisible && (
        <SecurityModal
          close={setSecurityVisible.bind(null, false)}
          security={security}
        />
      )}
      {speakabilityVisible && (
        <SpeakabilityModal
          close={setSpeakabilityVisible.bind(null, false)}
          speakability={speakability}
        />
      )}
    </>
  ) : null;
};

export default HostSettings;
