// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    type: "members-list",
    rowSpan: 1,
    colSpan: 6,
    data: {
      title: "Cult Ninjas",
    },
    requiresLogin: [true],
  },
  {
    type: "portal",
    rowSpan: 1,
    colSpan: 2,
    requiresLogin: [true],
  },
  {
    type: "games-list",
    rowSpan: 3,
    colSpan: 5,
    requiresLogin: [true],
  },
  {
    type: "game-characters",
    rowSpan: 3,
    colSpan: 3,
    requiresLogin: [true],
  },
  {
    type: "mafia-actions",
    rowSpan: 1,
    colSpan: 8,
    requiresLogin: [true],
  },
  {
    type: "mafia-quick-join",
    rowSpan: 5,
    colSpan: 8,
    requiresLogin: [false],
  },
];
