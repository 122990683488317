import React, { useEffect, useState } from "react";
import { Layout, MessageCircle, TravelPortal } from "../../../../assets/icons";
import TP from "./../../../TravelPortal";
import RaiseHandButton from "./components/RaiseHandButton";
import RTCButton from "./components/RTCButton";

interface FooterProps {
  view: "chat" | "widget";
  toggleView: () => void;
  unreadChatCount: number;
  hasChat: boolean;
  showHand: boolean;
}

const Footer: React.FC<FooterProps> = ({
  view,
  toggleView,
  unreadChatCount,
  hasChat,
  showHand,
}) => {
  const [keyboardFocused, setKeyboardFocused] = useState<boolean>(false);
  const [travelVisible, setTravelVisible] = useState<boolean>(false);

  const openTravelPortal = () => {
    setTravelVisible(true);
  };

  const toggleKeyboardFocused = () => {
    setKeyboardFocused((v) => !v);
  };

  useEffect(() => {
    if (window.innerWidth < 640) {
      document
        .querySelector("#chat-input")
        ?.addEventListener("focus", toggleKeyboardFocused);
      document
        .querySelector("#chat-input")
        ?.addEventListener("blur", toggleKeyboardFocused);
    }

    return () => {
      document
        .querySelector("#chat-input")
        ?.removeEventListener("focus", toggleKeyboardFocused);
      document
        .querySelector("#chat-input")
        ?.removeEventListener("blur", toggleKeyboardFocused);
    };
  });

  return keyboardFocused ? null : (
    <footer className="flex-shrink-0 flex items-center justify-between mt-4 md:fixed md:z-10 md:left-0 md:right-0 md:bottom-0 md:p-4 md:pointer-events-none">
      {hasChat ? (
        <button
          className="bg-white flex items-center justify-center w-12 h-12 md:w-20 md:h-20 rounded-full shadow-zo-sm focus:outline-none relative"
          onClick={toggleView}
        >
          {view === "chat" ? (
            <Layout className="w-6 h-6" />
          ) : (
            <>
              <MessageCircle className="w-6 h-6" />
              {unreadChatCount > 0 && (
                <span className="absolute top-0 right-0 rounded-full bg-orange text-white w-5 h-5 text-xxs font-bold shadow-md flex items-center justify-center">
                  {unreadChatCount}
                </span>
              )}
            </>
          )}
        </button>
      ) : (
        <div className="w-12 h-12 md:w-20 md:h-20" />
      )}
      {showHand ? <RaiseHandButton /> : <RTCButton />}
      <button
        className="bg-white flex z-10 pointer-events-auto items-center justify-center w-12 h-12 md:w-20 md:h-20 rounded-full shadow-zo-sm focus:outline-none"
        onClick={openTravelPortal}
      >
        <TravelPortal className="h-6 md:h-8 md:w-16" />
      </button>
      {travelVisible && <TP close={setTravelVisible.bind(null, false)} />}
    </footer>
  );
};

export default Footer;
