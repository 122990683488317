import React, { useEffect, useState, useContext, useMemo, useRef } from "react";

import Portal from "../../../../../ui/Portal";
import useApi from "../../../../../hooks/useApi";
import ProfileContext from "../../../../../contexts/profile";
import PortalOption from "./PortalOption";

import AppContext from "../../../../../contexts/app";
import defaultIcon from "./../../../assets/bg-commonroom-small.svg";

const PortalOptions = ({
  isOpen,
  toggleOptionsVisible,
  sendPartyMessage,
  isPartyHost,
}) => {
  const commApi = useApi("comms", true);
  const { slug } = useContext(AppContext);
  const { profile } = useContext(ProfileContext);

  const [zoWorldRooms, setZoWorldRooms] = useState([]);
  const [personalRooms, setPersonalRooms] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const inputRef = useRef();

  const handleGo = (destination) => {
    toggleOptionsVisible();
    if (destination.slug !== "zo") {
      if (destination.avatar_url) {
        sendPartyMessage({
          action: "travel",
          payload: {
            cult: destination.name,
            link: destination.url,
            avatar_url: destination.avatar_url,
          },
        });
      } else {
        sendPartyMessage({
          action: "travel",
          payload: {
            cult: destination.name,
            link: destination.url,
            icon: destination.icon,
          },
        });
      }
    } else {
      sendPartyMessage({
        action: "travel",
        payload: {
          cult: destination.name,
          link: `https://${process.env.REACT_APP_ROOT_DOMAIN}/`,
          icon: destination.icon,
        },
      });
    }
  };

  const suggestDestination = (room) => {
    sendPartyMessage({
      action: "destination_suggest",
      payload: {
        room,
        from: { code: profile.code, nickname: profile.nickname },
      },
    });
  };

  const handleSelection = (room) => {
    toggleOptionsVisible();
    if (isPartyHost) {
      handleGo(room);
    } else {
      suggestDestination(room);
    }
  };

  const handleQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const resetQuery = () => {
    setSearchQuery("");
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (searchResults.length && searchResults[0]) {
        handleSelection(searchResults[0]);
      }
    }
  };

  const allRoomsSorted = useMemo(() => {
    const allRooms = [...zoWorldRooms, ...personalRooms];
    allRooms.sort((a, b) => b.members_count - a.members_count);
    return allRooms;
  }, [personalRooms, zoWorldRooms]);

  const searchResults = useMemo(() => {
    if (searchQuery) {
      return allRoomsSorted.filter(
        (r) =>
          r.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (r.slug &&
            r.slug.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (r.nickname &&
            r.nickname.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (r.type && r.type.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    } else {
      return allRoomsSorted;
    }
  }, [allRoomsSorted, searchQuery]);

  useEffect(() => {
    if (commApi && isOpen) {
      commApi.get("/lobby/api/v1/rooms/common/online/").then((res) => {
        if (res.status === 200) {
          setZoWorldRooms(
            res.data.rooms
              .filter((r) => r.slug !== slug)
              .map((r) => {
                return {
                  ...r,
                  type: r.type || "Common Room",
                  icon: r.icon || defaultIcon,
                };
              })
          );
        }
      });
      commApi.get("/lobby/api/v1/rooms/private/online/").then((res) => {
        if (res.status === 200) {
          const rooms = [...res.data.profiles];
          if (
            !rooms.find((f) => f.code === profile.code) &&
            profile.status === "premium"
          ) {
            rooms.unshift({
              code: profile.code,
              name: profile.lobby_name || "My Common Room",
              url: `https://${profile.subdomain}.${process.env.REACT_APP_ROOT_DOMAIN}`,
              active: true,
              members_count: 0,
              avatar_url: profile.avatar_url,
              nickname: profile.nickname,
              members_nicknames: [profile.nickname],
            });
          }
          setPersonalRooms(
            rooms
              .filter(
                (r) => !(profile.subdomain === slug && r.code === profile.code)
              )
              .map((r) => {
                return { ...r, type: "Personal Room" };
              })
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commApi, isOpen]);

  useEffect(() => {
    if (isOpen && window.innerWidth > 640) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isOpen]);

  return (
    <Portal>
      <section
        className={`portal bg-orange absolute z-70 top-0 left-0 scroll-hidden h-full w-full flex flex-col items-center pt-8 portrait:pb-20 portrait:px-4 ${
          isOpen ? "portal-open pointer-events-auto" : "pointer-events-none"
        }`}
      >
        <h1 className="text-2xl font-black text-white zo-text-shadow flex-grow-0">
          Where would you like to go?
        </h1>
        <form
          className="w-1/2 portrait:w-full m-4 relative"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <input
            type="text"
            placeholder="Quick Filter (e.g. Mafia, Common Room)"
            className="form-input p-3 rounded-lg w-full"
            ref={inputRef}
            value={searchQuery}
            onChange={handleQuery}
            onKeyDown={handleKeyDown}
          />
          {searchQuery && (
            <button
              className="focus:outline-none absolute top-0 right-0 m-3"
              onClick={resetQuery}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                className="w-6 h-6"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
              </svg>
            </button>
          )}
        </form>
        <div className="w-full mb-6 mt-2 overflow-y-auto h-auto">
          <div className="w-5/12 portrait:w-full mx-auto">
            {searchResults.length ? (
              searchResults.map((room) => (
                <PortalOption
                  key={room.code}
                  optionData={room}
                  handleSelection={handleSelection}
                  isUserPremium={profile.status === "premium"}
                />
              ))
            ) : searchQuery ? (
              <div className="my-4 text-white font-semibold text-lg text-center">
                No room found!
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </Portal>
  );
};

export default PortalOptions;
