import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid, GridCellRenderer } from "react-virtualized";
import { Save, Search, Trash } from "../../../assets/icons";
import Flex from "../../structure/Flex";
import BottomSheet from "../../ui/BottomSheet";
import IconButton from "../../ui/IconButton";
import allEmojis from "./../../../configs/emoji.json";

interface EmojiSheetProps {
  open: boolean;
  onDismiss: () => void;
  selected: number;
  emojis: string[];
  onChange: (key: string[]) => void;
}

const EmojiSheet: React.FC<EmojiSheetProps> = ({
  open,
  onDismiss,
  selected,
  emojis,
  onChange,
}) => {
  const gridRef = useRef<Grid>(null);
  const [emojisSelected, setEmojisSelected] = useState<string[]>([]);
  const [query, setQuery] = useState<string>("");

  useEffect(() => {
    if (open) {
      setQuery("");
      setEmojisSelected(emojis);
      if (gridRef) {
        if (emojis[selected] !== "") {
          const index = allEmojis.findIndex((v) => v.char === emojis[selected]);
          if (index !== -1) {
            const columnIndex = index % 6;
            const rowIndex = Math.floor(index / 6);
            setTimeout(() => {
              gridRef.current?.scrollToCell({ columnIndex, rowIndex });
            }, 200);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const queryResults = useMemo(
    () =>
      query === ""
        ? allEmojis
        : allEmojis.filter((e) =>
            e.name.toLowerCase().includes(query.toLowerCase())
          ),
    [query]
  );

  const saveEmojis = () => {
    onChange(emojisSelected);
    onDismiss();
  };

  const removeEmoji = () => {
    onChange([
      ...emojisSelected.slice(0, selected),
      "",
      ...emojisSelected.slice(selected + 1),
    ]);
    onDismiss();
  };

  const setEmoji = (e: string) => {
    setEmojisSelected((_e) => [
      ..._e.slice(0, selected),
      e,
      ..._e.slice(selected + 1),
    ]);
  };

  const handleQueryChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setQuery(e.target.value);
  };

  const cellRenderer: GridCellRenderer = ({
    columnIndex,
    key,
    rowIndex,
    style,
  }) => {
    const emoji = queryResults[rowIndex * 6 + columnIndex];
    const emojiCharacter = emoji?.char || null;
    return (
      <div style={style} className="p-1" key={key}>
        {emojiCharacter && (
          <span
            className={`text-3xl w-full h-full inline-flex items-center justify-center rounded-lg border-2 cursor-pointer ${
              emojisSelected[selected] === emojiCharacter ? "border-orange" : ""
            }`}
            onClick={setEmoji.bind(null, emojiCharacter)}
          >
            {emojiCharacter}
          </span>
        )}
      </div>
    );
  };

  return (
    <BottomSheet
      open={open}
      snapPoints={({ maxHeight }) => [maxHeight * 0.95]}
      onDismiss={onDismiss}
      title={<span className="font-bold text-sm ml-10">Change Emoji</span>}
      rightOptions={
        <Flex items="center">
          <IconButton className="mr-2" onClick={removeEmoji}>
            <Trash className="w-5 h-5" />
          </IconButton>
          <IconButton onClick={saveEmojis}>
            <Save className="w-5 h-5" />
          </IconButton>
        </Flex>
      }
    >
      <div className="flex flex-wrap pt-1 justify-center w-full w-10 scroll-hidden">
        <Flex items="center" className="p-4 w-full shadow">
          <Search className="w-5 h-5 mr-4 flex-shrink-0" />
          <input
            type="text"
            placeholder="Search for an emoji"
            className="p-0 m-0 focus:outline-none flex-grow"
            value={query}
            onChange={handleQueryChange}
          />
        </Flex>
        <Grid
          ref={gridRef}
          cellRenderer={cellRenderer}
          columnWidth={window.innerWidth < 768 ? window.innerWidth / 6 : 72}
          columnCount={6}
          width={window.innerWidth < 768 ? window.innerWidth : 432}
          rowHeight={window.innerWidth < 768 ? window.innerWidth / 6 : 72}
          rowCount={Math.max(Math.ceil(queryResults.length / 6), 1)}
          height={window.innerHeight * 0.95 - 76 - 56}
        />
      </div>
    </BottomSheet>
  );
};

export default EmojiSheet;
