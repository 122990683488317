import { createContext } from "react";
import { SocketMessage } from "../../hooks/useSocket";

type RoomContextInterface = {
  room: Room | null;
  citizens: Citizen[];
  message: SocketMessage | null;
  widgets: RoomWidget[];
  connecting: boolean | null;
  sendMessage: React.Dispatch<SocketMessage>;
};

const RoomContext = createContext<RoomContextInterface>({
  room: null,
  citizens: [],
  message: null,
  widgets: [],
  connecting: null,
  sendMessage: (message: SocketMessage) => {},
});

export default RoomContext;
