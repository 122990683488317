const PATH = "/api/v1/auth";

const ENDPOINTS = {
  AUTH_ANON_LOGIN: "/anon-login/",
  AUTH_CONTACT: "/contact/",
  AUTH_VERIFY_OTP: "/verify-otp/",
  AUTH_REQUEST_OTP: "/request-otp/",
  AUTH_ACTIVATE: "/activate/",
};

export type AuthEndpoints = keyof typeof ENDPOINTS;
export const AuthCollection = { PATH, ENDPOINTS };
