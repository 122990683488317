import React, { useState } from "react";
import useApi from "../../../../../../../hooks/useApi";
import SubSectionRow from "../../../components/SubSectionRow";

const EmailField = ({ loadingContact, emailFields, setEmailFields }) => {
  const api = useApi("AUTH_CONTACT");
  const verifyOTPApi = useApi("AUTH_VERIFY_OTP");
  const requestOTPApi = useApi("AUTH_REQUEST_OTP");

  const [expanded, setExpanded] = useState(false);

  function handleEmailChange(i, event) {
    const values = [...emailFields];
    values[i].email = event.target.value;
    setEmailFields(values);
  }

  function handleEmailAdd() {
    const values = [...emailFields];
    values.push({
      id: Math.random(),
      email: "",
      verified: false,
      primary: false,
    });
    setEmailFields(values);
  }

  async function handleEmailRemove(i) {
    const values = [...emailFields];
    if (values[i].id != null) {
      try {
        await api.delete(`/email/${values[i].id}/`);
      } catch (error) {
        console.log(error);
      }
    }
    values.splice(i, 1);
    if (!values.length) {
      values.push({ email: "", verified: false, primary: false });
    }
    setEmailFields(values);
  }

  async function handleEmailVerify(i) {
    const values = [...emailFields];
    if (values[i].email.length) {
      values[i].sendingOTP = true;
      setEmailFields(values);
      try {
        const data = {
          email: values[i].email,
        };
        const response = await requestOTPApi.post(
          "/email/",
          JSON.stringify(data)
        );
        console.log(response);
        if (response.status === 200) {
          values[i].sendingOTP = false;
          values[i].verifying = true;
          setEmailFields([...values]);
        }
      } catch (error) {
        console.log(error);
        values[i].verifying = false;
        setEmailFields([...values]);
      }
    }
  }

  function handleEmailOTPChange(i, event) {
    const values = [...emailFields];
    values[i].otp = event.target.value;
    setEmailFields(values);
  }

  async function handleEmailOTPSubmit(i, e) {
    e.preventDefault();
    const values = [...emailFields];
    if (values[i].otp != null && values[i].otp.length === 6) {
      values[i].checkingOTP = true;
      setEmailFields(values);
      try {
        const data = {
          email: values[i].email,
          otp: values[i].otp,
        };
        const response = await verifyOTPApi.post(
          "/email/",
          JSON.stringify(data)
        );
        console.log(response);
        if (response.status === 200) {
          values[i] = response.data;
          setEmailFields([...values]);
        }
      } catch (error) {
        console.log(error);
      }
      values[i].checkingOTP = false;
      values[i].verifying = false;
      setEmailFields([...values]);
    }
  }

  async function handleEmailPrimary(i) {
    const values = [...emailFields];
    if (!values[i].primary) {
      values[i].makingPrimary = true;
      setEmailFields(values);
      try {
        const data = {
          email: {
            id: values[i].id,
            primary: true,
          },
        };
        const response = await api.put("", JSON.stringify(data));
        if (response.status === 200) {
          const values = [...emailFields];
          values.forEach((v, _i) => {
            if (i === _i) {
              values[_i].primary = true;
            } else {
              values[_i].primary = false;
            }
          });
          values[i].makingPrimary = false;
          setEmailFields([...values]);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <SubSectionRow
      title="Email"
      visibleArea={
        loadingContact
          ? "Fetching email information..."
          : Array.isArray(emailFields) &&
            emailFields.length > 0 &&
            emailFields[0].email && (
              <ul>
                {emailFields.map((e) => (
                  <li key={e.id}>
                    {e.email}
                    {e.primary && <strong> (primary)</strong>}
                  </li>
                ))}
              </ul>
            )
      }
      placeholder="Add an email address"
      isExpanded={expanded}
      setExpanded={setExpanded}
    >
      <div className="w-full">
        <div className="flex flex-col w-full flex-col items-start">
          {emailFields.map((field, idx) => {
            return (
              <div
                key={`${field}-${idx}`}
                className="flex w-full flex-wrap items-center mb-2 portrait:my-2 group"
              >
                <input
                  className="form-input portrait:mb-2 portrait:w-full"
                  value={field.email}
                  placeholder="abc@zo.xyz"
                  onChange={(e) => handleEmailChange(idx, e)}
                  disabled={field.verified || field.verifying}
                />
                <div className="relative flex items-center">
                  {field.verifying ? (
                    <form
                      className="flex items-center absolute portrait:relative z-10"
                      onSubmit={(e) => {
                        if (!field.checkingOTP) {
                          handleEmailOTPSubmit(idx, e);
                        } else {
                          e.preventDefault();
                        }
                      }}
                    >
                      <input
                        type="number"
                        className="form-input mx-2 portrait:mx-0 portrait:mr-2 portrait:w-3/5"
                        placeholder="Enter OTP"
                        value={field.otp}
                        onChange={(e) => handleEmailOTPChange(idx, e)}
                      />
                      <button
                        type="submit"
                        className="bg-orange text-white px-4 py-3 portrait:px-0 portrait:w-2/5 portrait:flex portrait:justify-center text-sm ml-2 rounded-lg leading-none hover:shadow-sm font-medium"
                      >
                        {field.checkingOTP ? "Verifying" : "Submit"}
                      </button>
                    </form>
                  ) : (
                    <>
                      {field.verified ? (
                        <>
                          <div className="text-sm text-orange ml-2 text-gray-700 font-medium border-orange border p-2 leading-none rounded-lg">
                            Verified
                          </div>
                          {field.primary ? (
                            <div className="text-sm text-gray-800 ml-2 text-gray-700 font-medium border-gray-800 border p-2 leading-none rounded-lg">
                              Primary
                            </div>
                          ) : (
                            <button
                              className="text-sm font-medium ml-4 leading-none opacity-100 hover:underline"
                              onClick={() => {
                                if (!field.makingPrimary) {
                                  handleEmailPrimary(idx);
                                }
                              }}
                            >
                              {field.makingPrimary
                                ? "Making Primary"
                                : "Make primary"}
                            </button>
                          )}
                        </>
                      ) : (
                        <button
                          className="bg-orange text-white px-4 py-3 text-sm ml-2 rounded-lg leading-none hover:shadow-sm font-medium"
                          onClick={() => {
                            if (!field.sendingOTP) {
                              handleEmailVerify(idx);
                            }
                          }}
                        >
                          {field.sendingOTP ? "Sending OTP" : "Verify"}
                        </button>
                      )}
                      {!field.primary && (
                        <button
                          className="ml-4 opacity-100"
                          onClick={() => handleEmailRemove(idx)}
                        >
                          <svg
                            className="fill-current text-red-500"
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                          </svg>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <button
          className="text-sm text-gray-700 font-medium hover:underline my-2"
          onClick={handleEmailAdd}
        >
          + Add another email address
        </button>
        <button
          className="block bg-white text-orange cursor-pointer mt-2 mb-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
          onClick={setExpanded.bind(null, false)}
        >
          OK
        </button>
      </div>
    </SubSectionRow>
  );
};

export default EmailField;
