import { createHash } from "crypto";

export const createNewSession: () => string = () => {
  const sessionId = createHash("sha1")
    .update(
      JSON.stringify({
        timestamp: Date.now(),
        rand: Math.random() * 10000,
      })
    )
    .digest("hex")
    .substr(0, 10);
  localStorage.setItem("sessionId", sessionId);
  return sessionId;
};
