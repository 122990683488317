const getAsset = (user, id, fallback) => {
  if (
    user &&
    user.assets &&
    user.assets.find((a) => a.type === id) &&
    user.assets.find((a) => a.type === id).file
  ) {
    return user.assets.find((a) => a.type === id).file;
  } else {
    return fallback;
  }
};

const ifProbable = function (probability) {
  return !!probability && Math.random() <= probability;
};

const setCookie = (cname, cvalue, exp) => {
  const d = new Date(exp);
  var expires = "expires=" + d.toUTCString();
  document.cookie =
    cname +
    "=" +
    cvalue +
    ";" +
    expires +
    `;path=/;domain=.${process.env.REACT_APP_ROOT_DOMAIN}`;
};

const getCookie = (cname) => {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const deleteCookie = (cname) => {
  const d = new Date();
  var expires = "expires=" + d.toUTCString();
  document.cookie =
    cname +
    "=;" +
    expires +
    `;path=/;domain=.${process.env.REACT_APP_ROOT_DOMAIN}`;
};

const renderLinks = (text, anchorClass) => {
  var urlRegex = /(https?:\/\/(www\.)?)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
  return text.replace(urlRegex, function (url) {
    const href = url.substring(0, 4) !== "http" ? "http://" + url : url;
    return `<a class="${
      anchorClass ||
      "text-orange font-medium hover:underline pointer-events-auto"
    }" href="${href}" ${url.includes("zo.xyz") ? "" : 'target="_blank"'}>${url}</a>`;
  });
};

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) {
    return (c / 2) * t * t + b;
  }
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

Math.easeInCubic = function (t, b, c, d) {
  var tc = (t /= d) * t * t;
  return b + c * tc;
};

Math.inOutQuintic = function (t, b, c, d) {
  var ts = (t /= d) * t,
    tc = ts * t;
  return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc);
};

Math.linearTween = function (t, b, c, d) {
  return (c * t) / d + b;
};

// requestAnimationFrame for Smart Animating http://goo.gl/sx5sts
const requestAnimFrame = (function () {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();

function scrollTo(element, to, callback, duration) {
  // because it's so fucking difficult to detect the scrolling element, just move them all
  function move(amount) {
    element.scrollLeft = amount;
    // document.body.parentNode.scrollLeft = amount;
    // document.body.scrollLeft = amount;
  }
  function position() {
    return (
      element.scrollLeft ||
      document.body.parentNode.scrollLeft ||
      document.body.scrollLeft
    );
  }
  let start = position(),
    change = to - start,
    currentTime = 0,
    increment = 20;
  duration = typeof duration === "undefined" ? 500 : duration;
  var animateScroll = function () {
    // increment the time
    currentTime += increment;
    // find the value with the quadratic in-out easing function
    var val = Math.linearTween(currentTime, start, change, duration);
    // move the document.body
    move(val);
    // do the animation unless its over
    if (currentTime < duration) {
      requestAnimFrame(animateScroll);
    } else {
      if (callback && typeof callback === "function") {
        // the animation is done so lets callback
        callback();
      }
    }
  };
  animateScroll();
}

export {
  getAsset,
  ifProbable,
  getCookie,
  setCookie,
  deleteCookie,
  renderLinks,
  scrollTo,
};
