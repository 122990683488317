import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth, useProfile } from "..";
import useApi from "../../hooks/useApi";
import useSocket from "../../hooks/useSocket";
import {
  getBareMinimumWidgetIds,
  getFormattedWidgets,
  getNewWidget,
  getWidget,
} from "../../utils/room";
import LoadingEntry from "./components/LoadingEntry";
import RoomContext from "./RoomContext";

interface RoomProviderProps {}

const RoomProvider: React.FC<RoomProviderProps> = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const { profile } = useProfile();
  const params: { subdomain: string } = useParams();
  const myLobbyApi = useApi("PROFILE_ME_LOBBY");
  const profileLobbyApi = useApi("PROFILE_LOBBY");
  const {
    message,
    members,
    setMembers,
    setSocketUrl,
    sendSocketMessage,
    connecting,
  } = useSocket<Citizen>("Room");

  const [loading, setLoading] = useState<
    "entry" | "check" | "kicked" | "error" | ""
  >("entry");
  const [widgets, setWidgets] = useState<RoomWidget[]>([]);
  const [room, setRoom] = useState<Room | null>(null);

  const bareMinimumWidgetIds = useRef<string[]>(getBareMinimumWidgetIds());

  const subdomain = useMemo(() => profile?.subdomain, [profile]);

  const installWidget = (id: string) => {
    const widgetData = getNewWidget(id);
    if (widgetData) {
      sendSocketMessage({
        action: "widget_update",
        payload: widgetData,
      });
    }
  };

  const setupWidgets = (widgets: any[], shouldSetupWidgets = false) => {
    if (shouldSetupWidgets) {
      if (widgets != null) {
        const notInstalledBareWidgets = bareMinimumWidgetIds.current.filter(
          (bwi) => widgets.findIndex((w) => w.type === bwi) === -1
        );
        if (notInstalledBareWidgets.length) {
          setWidgets(getFormattedWidgets(widgets));
          notInstalledBareWidgets.forEach((w) => {
            installWidget(w);
          });
        } else {
          setWidgets(getFormattedWidgets(widgets));
        }
      }
    } else {
      setWidgets(getFormattedWidgets(widgets));
    }
  };

  const loginRoom = (data: GeneralObject, shouldSetupWidgets = false) => {
    document.title = data.profile.lobby_name;
    setMembers(data.members);
    setupWidgets(data.widgets, shouldSetupWidgets);
    setSocketUrl(data.socket.path + "?" + data.socket.token);
    setLoading("");
    setRoom((r) => {
      return { ...Object.assign(r, { rtcAllowed: true }) };
    });
  };

  const handleKick = () => {
    const kickTime = new Date();
    kickTime.setHours(kickTime.getHours() + 1);
    localStorage.setItem(`kicked_${room?.code}`, kickTime.toISOString());
    setLoading("kicked");
  };

  useEffect(() => {
    let mounted = true;
    if (isLoggedIn && subdomain && params.subdomain) {
      setLoading("entry");
      if (subdomain === params.subdomain || params.subdomain === "me") {
        if (myLobbyApi) {
          myLobbyApi
            .get("")
            .then((res) => {
              console.log("My Lobby", res);
              if (mounted) {
                setRoom({ ...res.data.profile });
                loginRoom(res.data, true);
                setLoading("");
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      } else {
        if (profileLobbyApi) {
          profileLobbyApi
            .get(`/${params.subdomain}/`)
            .then((res) => {
              console.log("Room Details:", res);
              if (mounted) {
                const isKicked = localStorage.getItem(
                  `kicked_${res.data.profile.code}`
                );
                try {
                  if (isKicked && +new Date(isKicked) < +new Date()) {
                    setLoading("kicked");
                  } else {
                    setRoom(res.data.profile);
                    setLoading("check");
                  }
                } catch (error) {
                  setLoading("kicked");
                }
              }
            })
            .catch((e) => {
              console.log(e);
              setLoading("error");
            });
        }
      }
    } else {
      setLoading("");
    }

    return () => {
      mounted = false;
      setRoom(null);
      setWidgets([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, subdomain, params.subdomain, myLobbyApi, profileLobbyApi]);

  useEffect(() => {
    sendSocketMessage({
      action: "member_update_avatar",
      payload: { code: profile?.code, avatar_url: profile?.avatar_url },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.avatar_url]);

  useEffect(() => {
    sendSocketMessage({
      action: "member_update_bio",
      payload: { code: profile?.code, bio: profile?.bio },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.bio]);

  useEffect(() => {
    if (message) {
      if (message.action === "update_security") {
        setRoom((r) => {
          return {
            ...Object.assign(r, { security: message.payload.security }),
          };
        });
      } else if (message.action === "update_background_key") {
        setRoom((r) => {
          return {
            ...Object.assign(r, {
              background_key: message.payload.background_key,
            }),
          };
        });
      } else if (message.action === "update_lobby_name") {
        setRoom((r) => {
          return {
            ...Object.assign(r, {
              lobby_name: message.payload.lobby_name,
            }),
          };
        });
      } else if (message.action === "update_speakability") {
        setRoom((r) => {
          return {
            ...Object.assign(r, { speakability: message.payload.speakability }),
          };
        });
      } else if (message.action === "member_kick") {
        if (message.payload.code === profile?.code) {
          handleKick();
        } else {
          setMembers((m) => m.filter((_m) => _m.code !== message.payload.code));
        }
      } else if (message.action === "raise_hand") {
        setMembers((m) => {
          const _m = m.find((__m) => __m.code === message.payload.code);
          if (_m) {
            _m.handRaised = true;
          }
          return [...m];
        });
      } else if (message.action === "unraise_hand") {
        setMembers((m) => {
          const _m = m.find((__m) => __m.code === message.payload.code);
          if (_m) {
            _m.handRaised = false;
          }
          return [...m];
        });
      } else if (message.action === "widget_update") {
        const _widget = getWidget(widgets, message.payload.widget);
        if (_widget) {
          setWidgets((w) => [
            ...w.filter((_w) => _w.widget !== message.payload.widget),
            {
              widget: message.payload.widget,
              meta: _widget.meta,
              data: message.payload.data,
            },
          ]);
        } else {
          const newWidget = getNewWidget(message.payload.widget);
          if (newWidget) {
            setWidgets((w) => [
              ...w.filter((_w) => _w.widget !== message.payload.widget),
              {
                widget: message.payload.widget,
                meta: newWidget.meta,
                data: message.payload.data,
              },
            ]);
          }
        }
      } else if (message.action === "member_update_avatar") {
        setMembers((m) => {
          const _member = m.find((m) => m.code === message.payload.code);
          if (_member) {
            _member.avatar_url = message.payload.avatar_url;
          }
          return [...m];
        });
      } else if (message.action === "member_update_bio") {
        setMembers((m) => {
          const _member = m.find((m) => m.code === message.payload.code);
          if (_member) {
            _member.bio = message.payload.bio;
          }
          return [...m];
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <RoomContext.Provider
      value={{
        widgets,
        room,
        citizens: members,
        message,
        connecting,
        sendMessage: sendSocketMessage,
      }}
    >
      {loading !== "" ? (
        <LoadingEntry phase={loading} close={loginRoom} room={room} />
      ) : room && !connecting ? (
        children
      ) : (
        <LoadingEntry phase="connecting" close={loginRoom} room={room} />
      )}
    </RoomContext.Provider>
  );
};

export default RoomProvider;
