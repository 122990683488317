import React, { useContext } from "react";

import Widget from "../../ui/Widget";
import SquareIcon from "../../ui/SquareIcon";
import logo from "./../../../../../../assets/logo-white.svg";
import plusIcon from "./../../../../../../assets/plus.svg";
import mafiaIcon from "./../../../assets/cult-mafia.svg";
import cameraIcon from "./../../../assets/cult-camera.svg";
import musicIcon from "./../../../assets/cult-music.svg";
import ModalContext from "../../../contexts/modals";
const cults = [
  {
    icon: logo,
    hoverText: "Travel",
    link: "https://www.zostel.com",
    backgroundColor: "#f15824",
  },
  {
    icon: mafiaIcon,
    hoverText: "Mafia",
    link: `https://mafia.${process.env.REACT_APP_ROOT_DOMAIN}`,
    backgroundColor: "#f8b41c",
  },
  {
    icon: cameraIcon,
    hoverText: "Camera",
    // backgroundColor: "#2ab3b5",
  },
  {
    icon: musicIcon,
    hoverText: "Music",
    // backgroundColor: "#4ba68e",
  },
  {
    icon: plusIcon,
    modal: "cult-suggest",
    hoverText: "Pitch your cult",
  },
];

const Cults = (props) => {
  const { setModal } = useContext(ModalContext);

  return (
    <Widget
      className="zo-subheader flex items-center shadow-none overflow-visible scroll-hidden"
      {...props}
    >
      <span className="flex-shrink-0 text-2xl font-bold zo-text-shadow">
        Our Cults
      </span>
      <ul className="flex items-center ml-10 portrait:overflow-x-auto portrait:overflow-y-visible portrait:flex-no-wrap">
        {cults.map((cult) => (
          <SquareIcon
            key={Math.random()}
            {...cult}
            onClick={
              cult.link
                ? () => {
                    window.open(cult.link, "_parent", "", false);
                  }
                : cult.modal
                ? () => {
                    setModal(cult.modal);
                  }
                : null
            }
          />
        ))}
      </ul>
    </Widget>
  );
};

export default Cults;
