import React from "react";

import Widget from "../../ui/Widget";
import { useSeed } from "../../../contexts/seed";

const Services = (props) => {
  const { style, seedData } = useSeed();

  return (
    <Widget
      className={`zo-destinations cursor-pointer text-white p-0 uppercase tracking-wide bg-${
        style.accentColor || "orange"
      } bg-cover bg-center`}
      {...props}
      style={{
        backgroundImage:
          seedData.images && `${seedData.images[0]}?w=200&h=200&c=80`
            ? `url(${seedData.images[0].image}?w=200&h=200&c=80)`
            : "",
      }}
      onClick={ ()=> window.open(`https://www.zostel.com/zostel/${seedData.slug}`, "_blank", "", false)}
    >
      <div className="flex flex-col items-center justify-center zo-bg-black-transparent w-full h-full pt-5 pb-4">
        <div className="font-semibold text-sm zo-text-shadow leading-none mb-2">
          Zostel {seedData.name}
        </div>
        <div className="font-bold text-xl zo-text-shadow leading-none">
          Book Now
        </div>
      </div>
    </Widget>
  );
};

export default Services;
