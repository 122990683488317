import React, { useCallback, useEffect, useMemo, useState } from "react";
import Portal from "../../../../../../../../components/ui/Portal";
import withMemo from "../../../../../../../../utils/withMemo";
import { useRoom } from "../../../../../../contexts";
import { getWidget } from "../../../../../../utils/room";

interface EmojiFlashProps {}
interface EmojiDisplayProps {
  list: Emoji[];
}
interface SEmojiProps {
  emoji: Emoji;
}

const getRandomMargin = () => {
  const margin = [
    "8",
    "16",
    "24",
    "32",
    "40",
    "48",
    "56",
    "64",
    "72",
    "80",
    "88",
  ];
  return margin[Math.floor(Math.random() * margin.length)];
};

const SEmoji: React.FC<SEmojiProps> = ({ emoji }) => {
  return (
    <div
      key={emoji.id}
      className={`emojiflash text-3xl absolute ml-${
        window.innerWidth < 640 ? +emoji.margin - 8 : emoji.margin
      } w-8 h-8 m-4 flex items-center justify-center rounded-full ${
        window.innerWidth < 640 ? "mb-16" : ""
      }`}
    >
      {emoji.emoji}
    </div>
  );
};

const EmojiDisplay: React.FC<EmojiDisplayProps> = ({ list }) => {
  return (
    <Portal>
      <aside className="fixed z-10 bottom-0 left-0 mb-32 ml-4 pointer-events-none">
        {list.map((emoji, i) => (
          <SEmoji key={emoji.id} emoji={emoji} />
        ))}
      </aside>
    </Portal>
  );
};

const EmojiFlash: React.FC<EmojiFlashProps> = withMemo(() => {
  const { sendMessage, message, widgets } = useRoom();

  const hasEmojiFlash = useMemo(
    () => getWidget(widgets, "chat")?.data.emojiVisible,
    [widgets]
  );
  const emojis = useMemo(
    () => getWidget(widgets, "chat")?.data.emojis,
    [widgets]
  );

  const [list, setList] = useState<Emoji[]>([]);

  const sendEmoji = useCallback(
    (emoji) => {
      sendMessage({
        action: "emoji_flash",
        payload: {
          emoji,
          id: +new Date(),
          margin: getRandomMargin(),
        },
      });
    },
    [sendMessage]
  );

  useEffect(() => {
    if (hasEmojiFlash) {
      setList([]);
    }
  }, [hasEmojiFlash]);

  useEffect(() => {
    if (message && message.action === "emoji_flash") {
      const time = +new Date();
      setList((l) => [
        ...l.filter((i) => time < i.id + 5000),
        {
          emoji: message.payload.emoji,
          id: message.payload.id,
          margin: message.payload.margin,
        },
      ]);
    }
  }, [message]);

  return useMemo(
    () =>
      hasEmojiFlash && emojis ? (
        <div className="focus:outline-none mb-2 flex items-center w-full flex-shrink-0 justify-center">
          {emojis
            .filter((e: string) => e !== "")
            .map((emoji: string, index: number) => (
              <button
                key={index}
                className="text-xl focus:outline-none ml-2 first:ml-0 w-10 h-10 flex items-center justify-center rounded-full bg-white shadow-md hover:bg-gray-300"
                onClick={sendEmoji.bind(null, emoji)}
                onKeyDown={(e) => e.preventDefault()}
                onKeyUp={sendEmoji.bind(null, emoji)}
              >
                {emoji}
              </button>
            ))}
          <EmojiDisplay list={list} />
        </div>
      ) : null,
    [emojis, hasEmojiFlash, list, sendEmoji]
  );
}, []);

export default EmojiFlash;
