import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";

import { useHistory, useLocation } from "react-router-dom";
import ProfileContext from "../../contexts/profile";
import AvatarEditor from "./components/AvatarEditor";
import useApi from "../../hooks/useApi";

const AvatarSection = ({ forceVisible }) => {
  const api = useApi("profile", true);
  const { profile, setProfile } = useContext(ProfileContext);
  const history = useHistory();
  const location = useLocation();

  const [zobuLayers, setZobuLayers] = useState([]);
  const [localBases, setLocalBases] = useState({});
  const [base, setBase] = useState(1);
  const [loadingAssets, setLoadingAssets] = useState(true);
  const zobuRef = useRef();

  const [loading, setLoading] = useState(false);
  const [avatarEditorVisible, setAvatarEditorVisible] = useState(false);

  const closeEditor = () => {
    history.push("/");
  };

  useEffect(() => {
    if (forceVisible) {
      setAvatarEditorVisible(true);
      setLoadingAssets(true);
    } else {
      const values = location.hash.split("/");
      if (values[0] === "#zobu") {
        setAvatarEditorVisible(true);
        setLoadingAssets(true);
      } else {
        setLoadingAssets(false);
        setAvatarEditorVisible(false);
      }
    }
  }, [location, setAvatarEditorVisible, forceVisible]);

  const hasAvatarChanged = useCallback(
    (newIdentifier) => {
      const avatarAsset = profile.assets.find((a) => a.type === 1);
      if (
        avatarAsset != null &&
        avatarAsset.identifier &&
        avatarAsset.identifier === newIdentifier
      ) {
        return false;
      } else {
        return true;
      }
    },
    [profile]
  );

  const handleSave = useCallback(async () => {
    setLoading(true);
    const layersEncoded = zobuLayers
      .map((l) => `${l.category}-${l.asset}`)
      .join(",");
    const identifier = `${base}@${layersEncoded}`;
    if (hasAvatarChanged(identifier)) {
      const svgString =
        "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>" +
        zobuRef.current.innerHTML +
        "</svg>";
      const svg = new Blob([svgString], { type: "image/svg+xml" });
      const formData = new FormData();
      formData.append("file", svg, "zobu-avatar.svg");
      formData.append("identifier", identifier);
      try {
        const response = await api.post(
          `/profile/api/v1/me/assets/1/upload/`,
          formData,
          { headers: { "content-type": "multipart/form-data" } }
        );
        if (response.status === 200) {
          console.log(response);
          setProfile((p) => {
            const profileCopy = { ...p };
            const avatarObj = profileCopy.assets.find((a) => a.type === 1);
            if (avatarObj) {
              avatarObj.identifier = identifier;
              avatarObj.file = response.data.file;
            } else {
              profileCopy.assets.push({
                type: 1,
                identifier,
                file: response.data.file,
              });
            }
            console.log(response.data.file);
            profileCopy.avatar_url = response.data.file;
            return { ...profileCopy };
          });
          closeEditor();
          setLoading(false);
        }
      } catch (error) {
        console.log(error.response);
      }
    } else {
      closeEditor();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [base, zobuLayers, api]);

  return avatarEditorVisible ? (
    <AvatarEditor
      localBases={localBases}
      zobuRef={zobuRef}
      zobuLayers={zobuLayers}
      base={base}
      loading={loading}
      close={closeEditor}
      handleSave={handleSave}
      loadingAssets={loadingAssets}
      setLocalBases={setLocalBases}
      setZobuLayers={setZobuLayers}
      setBase={setBase}
      setLoadingAssets={setLoadingAssets}
    />
  ) : null;
};

export default AvatarSection;
