import React, { createContext } from "react";

interface RTCContextInterface {
  peers: any[];
  isActive: boolean;
  isSelfMute: boolean;
  rtcSupported: boolean;
  roomCode: string | null;
  volume: number;
  inputDeviceId: string | undefined;
  setInputDeviceId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPeers: React.Dispatch<React.SetStateAction<any[]>>;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSelfMute: React.Dispatch<React.SetStateAction<boolean>>;
  setRoomCode: React.Dispatch<React.SetStateAction<string | null>>;
  setUserCode: React.Dispatch<React.SetStateAction<string | null>>;
  setDisplayName: React.Dispatch<React.SetStateAction<string | null>>;
  setVolume: React.Dispatch<React.SetStateAction<number>>;
  setRecordName: React.Dispatch<React.SetStateAction<string | false | null>>;
}

const RTCContext = createContext<RTCContextInterface>({
  peers: [],
  isActive: false,
  isSelfMute: true,
  rtcSupported: true,
  roomCode: null,
  volume: 1,
  inputDeviceId: undefined,
  setInputDeviceId: () => {},
  setPeers: () => {},
  setActive: () => {},
  setDisplayName: () => {},
  setIsSelfMute: () => {},
  setRoomCode: () => {},
  setUserCode: () => {},
  setVolume: () => {},
  setRecordName: () => {},
});

export default RTCContext;
