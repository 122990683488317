const PATH = "/api/v1/games";

const ENDPOINTS = {
  GAMES_MAFIA_LEADERBOARD: "/mafia/leaderboard/",
  GAMES_MAFIA_ROOMS_PUBLIC: "/mafia/rooms/public/",
  GAMES_MAFIA: "/mafia/",
  GAMES_MAFIA_ROOM: "/mafia/room/",
  GAMES_MAFIA_EVENTS: "/mafia/events/",
};

export type GamesEndpoints = keyof typeof ENDPOINTS;
export const GamesCollection = { PATH, ENDPOINTS };
