import React, { useState } from "react";

import godfather from "./../../../assets/mafia/role-godfather.svg";
import detective from "./../../../assets/mafia/role-detective.svg";
import healer from "./../../../assets/mafia/role-healer.svg";
import villager from "./../../../assets/mafia/role-villager-boy-1.svg";
import mafia from "./../../../assets/mafia/role-mafia.svg";
import CharacterModal from "./components/CharacterModal";
import { useSeed } from "../../../contexts/seed";
import Widget from "../../ui/Widget";

const GameCharacters = (props) => {
  const {
    style: { primaryColor, accentColor, backgroundColor },
  } = useSeed();

  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});

  const handleModalClick = (type) => {
    setModalData(type);
    setModalVisible(true);
  };

  return (
    <Widget
      className={`portrait:h-108 p-2 scroll-hidden flex flex-col items-center h-full bg-${
        primaryColor ? primaryColor : "white"
      } border-t-4 border-${accentColor}`}
      {...props}
    >
      <div
        className={`w-full font-bold flex-shrink-0 mb-2 pb-2 text-center text-xl uppercase tracking-normal border-b-2 border-${
          backgroundColor ? backgroundColor : "gray-900"
        }`}
      >
        Characters
      </div>
      <div className="overflow-y-auto flex-grow w-full">
        <div className="w-1/3 inline-block h-content">
          <img
            className="inline-block mx-1 mt-2 portrait:m-1 cursor-pointer hover:shadow-lg"
            src={mafia}
            onClick={handleModalClick.bind(null, "mafia")}
            alt=""
          />
        </div>
        <div className="w-1/3 inline-block h-content">
          <img
            className="inline-block mx-1 mt-2 portrait:m-1 cursor-pointer hover:shadow-lg"
            src={detective}
            onClick={handleModalClick.bind(null, "detective")}
            alt=""
          />
        </div>
        <div className="w-1/3 inline-block h-content">
          <img
            className="inline-block mx-1 mt-2 portrait:m-1 cursor-pointer hover:shadow-lg"
            src={healer}
            onClick={handleModalClick.bind(null, "healer")}
            alt=""
          />
        </div>
        <div className="w-1/3 inline-block h-content">
          <img
            className="inline-block mx-1 mt-2 portrait:m-1 cursor-pointer hover:shadow-lg"
            src={villager}
            onClick={handleModalClick.bind(null, "villager")}
            alt=""
          />
        </div>
        <div className="w-1/3 inline-block h-content">
          <img
            className="inline-block mx-1 mt-2 portrait:m-1 cursor-pointer hover:shadow-lg"
            src={godfather}
            onClick={handleModalClick.bind(null, "godfather")}
            alt=""
          />
        </div>
      </div>
      <CharacterModal
        visible={modalVisible}
        close={setModalVisible.bind(null, false)}
        character={modalData}
      />
    </Widget>
  );
};

export default GameCharacters;
