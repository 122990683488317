import React, { useContext, useEffect, useRef, useState } from "react";

import { renderLinks } from "../../../../utils";
import AppContext from "../../../contexts/app";
import ProfileContext from "../../../contexts/profile";
import useApi from "../../../hooks/useApi";
import useInput from "../../../hooks/useInput";
import AutoExpandingTextArea from "../../../ui/AutoExpandingTextArea";
import RoomContext from "../contexts/room";
import { checkHost } from "../utils";

const WhiteboardWidget = ({ description }) => {
  const { sendSocketMessage, roomDetails, message } = useContext(RoomContext);
  const { category, slug } = useContext(AppContext);
  const { profile } = useContext(ProfileContext);
  const api = useApi("profile", true);
  const commsApi = useApi("comms", true);

  const [loading, setLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const textareaRef = useRef();

  useEffect(() => {
    if (message && message.action === "new_whiteboard") {
      reset();
      setEditing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    if (isEditing) {
      textareaRef.current.focus();
    }
  }, [isEditing]);

  const { value: text, bind: bindText, reset } = useInput(description);

  const onSuccess = (text) => {
    sendSocketMessage({
      action: "update_whiteboard_text",
      payload: { text },
    });
    setLoading(false);
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (category === "profile") {
      if (!loading) {
        setLoading(true);
        try {
          const data = {
            description: text,
          };
          const response = await api.post(
            `/profile/api/v1/me/`,
            JSON.stringify(data)
          );
          if (response.status === 200) {
            console.log(response);
            onSuccess(data.description);
          }
        } catch (error) {
          console.log(error.response);
        }
      }
    } else {
      if (!loading) {
        setLoading(true);
        try {
          const data = {
            description: text,
          };
          const response = await commsApi.post(
            `/lobby/api/v1/room/${slug || "zo"}/`,
            JSON.stringify(data)
          );
          if (response.status === 200) {
            console.log(response);
            onSuccess(data.description);
          }
        } catch (error) {
          console.log(error.response);
        }
      }
    }
  };

  return (
    <section className="inline-flex flex-col w-108 sm:mx-2  portrait:w-full bg-contain bg-no-repeat shadow-md bg-white-translucent-90 rounded-lg shadow-md mb-4 flex-shrink-0">
      <header className="w-full p-3 flex items-center justify-between">
        <span className="font-bold">Notice Board</span>
        {checkHost(roomDetails, profile.code) ? (
          isEditing ? (
            <>
              <button
                className="flex items-center text-sm font-medium text-orange"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="flex items-center text-sm font-medium text-orange"
                onClick={handleSubmit}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  className="w-5 h-5 fill-current text-orange mr-2"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M17.59 3.59c-.38-.38-.89-.59-1.42-.59H5c-1.11 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V7.83c0-.53-.21-1.04-.59-1.41l-2.82-2.83zM12 19c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm1-10H7c-1.1 0-2-.9-2-2s.9-2 2-2h6c1.1 0 2 .9 2 2s-.9 2-2 2z" />
                </svg>
                Save
              </button>
            </>
          ) : (
            <button
              className="flex items-center text-sm font-medium text-orange"
              onClick={setEditing.bind(null, true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                className="w-5 h-5 fill-current text-orange mr-2"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
              </svg>
              Edit
            </button>
          )
        ) : null}
      </header>
      <section
        className="bg-white w-full rounded-lg p-4"
        style={{ boxShadow: "0 0 5px rgba(0,0,0,0.1)" }}
      >
        {isEditing ? (
          <AutoExpandingTextArea
            className="whiteboard-text leading-5 font-medium w-full p-0 form-textarea"
            placeholder="Add a whiteboard text"
            ref={textareaRef}
            {...bindText}
          />
        ) : (
          <p
            className="whiteboard-text whitespace-pre-line break-words leading-5 w-full font-medium"
            dangerouslySetInnerHTML={{ __html: renderLinks(description) }}
          />
        )}
      </section>
    </section>
  );
};

export default WhiteboardWidget;
