import React from "react";
import { LoadingDots, Mic, MicNone, MicOff } from "../../../../../assets/icons";

interface RTCAudioIconProps {
  muted: boolean;
  speaking: boolean;
  connected: boolean;
}

const RTCAudioIcon: React.FC<RTCAudioIconProps> = ({
  speaking,
  muted,
  connected,
}) => {
  return (
    <div className="absolute bottom-0 w-5 h-5 right-0 -mb-1">
      {connected ? (
        muted ? (
          <MicOff className="fill-current w-full h-full text-gray-800" />
        ) : speaking ? (
          <Mic className="fill-current w-full h-full text-orange" />
        ) : (
          <MicNone className="fill-current w-full h-full text-gray-800" />
        )
      ) : (
        <LoadingDots className="fill-current w-5 h-5 text-gray-800 loading-dots" />
      )}
    </div>
  );
};

export default RTCAudioIcon;
