import React, { useEffect, useState } from "react";

import "./index.css";
import backSide from "./../../../../../assets/role-back.svg";

const RoleCard = ({ reveal, card }) => {
  const [frontSide, setFrontside] = useState(null);

  useEffect(() => {
    if (reveal) {
      setFrontside(card);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reveal]);

  return (
    <div className={`flip-card ${reveal ? "revealed" : ""}`}>
      <div className="flip-card-inner">
        <img className="flip-card-front" src={backSide} alt="" />
        <img className="flip-card-back" src={frontSide} alt="" />
      </div>
    </div>
  );
};

export default RoleCard;
