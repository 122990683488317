import React from "react";
import Flex from "../../../../components/structure/Flex";
import CitizensList from "../../../../components/widgets/CitizensList";
import ChatInput from "./components/ChatInput";
import ChatMessages from "./components/ChatMessages";
import EmojiFlash from "./components/EmojiFlash";

interface ChatProps {
  active: boolean;
  setUnreadChatCount: React.Dispatch<React.SetStateAction<number>>;
}

const Chat: React.FC<ChatProps> = ({ active, setUnreadChatCount }) => {
  return (
    <Flex
      col
      className={`absolute md:relative md:flex-1 inset-0 overflow-hidden ${
        active
          ? "opacity-100 pointer-events-auto"
          : "opacity-0 pointer-events-none"
      }`}
    >
      <CitizensList />
      <Flex col items="center" className="w-full flex-1 overflow-hidden">
        <ChatMessages active={active} setUnreadChatCount={setUnreadChatCount} />
        <EmojiFlash />
        <ChatInput />
      </Flex>
    </Flex>
  );
};

export default Chat;
