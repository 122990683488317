import React, { useEffect, useState } from "react";
import humanize from "humanize-number";

import ZoHeaderStat from "./../../../../../ui/ZoHeaderStat";
// import AuthContext from "../../../../../contexts/auth";
import useApi from "../../../../../hooks/useApi";

const Live = () => {
  // const { publicApi } = useContext(AuthContext);
  const api = useApi("zo world", false);

  const [liveCount, setLiveCount] = useState(null);

  useEffect(() => {
    if (api) {
      api
        .get(`/zoworld/api/v1/live/`)
        .then((res) => {
          setLiveCount(res.data.live.count);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [api]);

  return (
    <ZoHeaderStat
      className="zo-head_live"
      icon={<span className="font-black text-red-500">LIVE</span>}
      value={liveCount ? humanize(liveCount) : "..."}
      title={"Live souls"}
    />
  );
};

export default Live;
