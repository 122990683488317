import React, { useContext } from "react";

import LiveResult from "./LiveResult";
import InGameContext from "../../../contexts/inGame";

const TableText = () => {
  const { roomRound, roomStatus } = useContext(InGameContext);

  return (
    <div className="absolute inset-0 w-full h-full flex flex-col items-center justify-center">
      {roomStatus === "ended_mafia" ? (
        <div className="text-3xl font-bold">Mafia Won!</div>
      ) : roomStatus === "ended_village" ? (
        <div className="text-3xl font-bold">Village Won!</div>
      ) : roomRound === "day" ? (
        <LiveResult />
      ) : (
        <></>
      )}
    </div>
  );
};

export default TableText;
