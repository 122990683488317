import React, { useState, useEffect, useContext } from "react";

import ProfileContext from ".";
import AuthContext from "../auth";
import useApi from "../../hooks/useApi";

const ProfileProvider = ({ children }) => {
  const { isLoggedIn } = useContext(AuthContext);
  const api = useApi("profile", true);

  const [profile, setProfile] = useState(null);
  const [myDestination, setMyDestination] = useState(null);
  const [activityHours, setActivityHours] = useState(null);

  useEffect(() => {
    if (
      process.env.REACT_APP_ROOT_DOMAIN === "zo.xyz" &&
      profile &&
      window.rudderanalytics
    ) {
      const data = {
        name: `${profile.first_name}${profile.last_name && profile.last_name}`,
        email: profile.email || "",
        mobile: profile.mobile || "",
      };
      window.rudderanalytics.identify(profile.code, data);
    }
  }, [profile]);

  useEffect(() => {
    if (isLoggedIn && api) {
      api
        .get("/profile/api/v1/me/")
        .then((res) => {
          console.log("Profile", res);
          if (res.status === 200) {
            setProfile(res.data.profile);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setProfile(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, api]);

  useEffect(() => {
    if (isLoggedIn && api) {
      api
        .get("/profile/api/v1/me/destination/")
        .then((res) => {
          console.log("My Destination", res);
          if (res.status === 200) {
            setMyDestination(res.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
      api
        .get("/profile/api/v1/me/activity/hours/")
        .then((res) => {
          console.log("Activity Hours", res);
          if (res.status === 200) {
            setActivityHours(res.data.hours);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setProfile(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, api]);

  const updateProfile = (data) => {
    setProfile((p) => {
      return { ...Object.assign(p, { ...data }) };
    });
  };

  return (
    <ProfileContext.Provider
      value={{
        profile,
        activityHours,
        myDestination,
        updateProfile,
        setProfile,
        setMyDestination,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
