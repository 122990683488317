import widgets from "./../configs/widgets.json";

export const getBackgroundFromKey = (
  key: string | undefined,
  backgrounds: GeneralObject
) => {
  return key != null ? backgrounds[key] || "" : "";
};

export const isRoomHost = (room: Room, nickname: string) => {
  if (room.nickname === nickname) {
    return true;
  } else {
    return false;
  }
};

export const getDetailedCitizens = (
  citizens: Citizen[],
  roomCode: string | undefined,
  userCode: string,
  userNickname: string,
  peers: any[],
  isActive: any,
  isSelfMute: any
) => {
  return citizens.map((member) => {
    const connected =
      userNickname === member.nickname
        ? peers.find((p) => p.display === member.nickname) || isActive
        : peers.find((p) => p.display === member.nickname);

    const speaking = connected
      ? connected.status === "talking"
        ? true
        : false
      : false;

    const muted: boolean =
      member.nickname === userNickname
        ? isSelfMute
        : connected
        ? connected.muted
        : false;

    const isHost: boolean = roomCode === member.code;

    const isHandRaised = member.handRaised;

    let priority;
    if (connected) {
      if (isHandRaised) {
        priority = 3;
      } else if (!muted) {
        if (isHost) {
          priority = 5;
        } else {
          priority = 4;
        }
      } else if (muted) {
        if (isHost) {
          priority = 2;
        } else {
          priority = 1;
        }
      } else {
        priority = 0;
      }
    } else {
      priority = 0;
    }

    const detailedCitizen: DetailedCitizen = {
      ...member,
      muted,
      isHost,
      connected,
      priority,
      speaking,
    };

    return detailedCitizen;
  });
};

export const getWidget = (widgets: RoomWidget[], widgetId: string) => {
  return widgets.find((w) => w.widget === widgetId);
};

export const getBareMinimumWidgetIds = () => {
  return widgets.map((w) => w.widget);
};

export const getNewWidget = (widgetId: string) => {
  return widgets.find((w) => w.widget === widgetId);
};

export const getFormattedWidgets = (widgets: any[]) => {
  return widgets.map((w) => {
    const _widget = getNewWidget(w.type);
    return {
      data: w.data,
      widget: w.type,
      meta: _widget?.meta || {},
    };
  });
};

export const randomString = (len: number) => {
  const charSet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";
  for (let i = 0; i < len; i++) {
    let randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }
  return randomString;
};

export const getRoomURL = (subdomain: string) => {
  return `https://${process.env.REACT_APP_ROOT_DOMAIN}/@${subdomain}/`;
};
