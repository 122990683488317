import React, { useEffect, useState } from "react";

import PortalOptions from "./components/PortalOptions";
import gun from "./../../../../../assets/portal-gun.svg";

const Portal = ({ isPartyHost, sendPartyMessage }) => {
  const [isOpen, setOpen] = useState(false);

  const [keyboardFocused, setKeyboardFocused] = useState(false);

  const toggleKeyboardFocused = () => {
    setKeyboardFocused((v) => !v);
  };

  useEffect(() => {
    const chatInput = document.querySelector("#chat-input");
    if (window.innerWidth < 640 && chatInput) {
      chatInput.addEventListener("focus", toggleKeyboardFocused);
      chatInput.addEventListener("blur", toggleKeyboardFocused);
    }

    return () => {
      if (chatInput) {
        chatInput.removeEventListener("focus", toggleKeyboardFocused);
        chatInput.removeEventListener("blur", toggleKeyboardFocused);
      }
    };
  }, []);

  const toggleOptionsVisible = () => {
    setOpen((v) => !v);
  };

  return (
    <div className={`items-center ${keyboardFocused ? "hidden" : "flex"}`}>
      <PortalOptions
        isOpen={isOpen}
        isPartyHost={isPartyHost}
        sendPartyMessage={sendPartyMessage}
        toggleOptionsVisible={toggleOptionsVisible}
      />
      <button
        className="relative focus:outline-none rounded-full bg-white overflow-hidden shadow-md p-2"
        onClick={toggleOptionsVisible}
      >
        {isOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            className="fill-current text-orange w-12 h-12"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
          </svg>
        ) : (
          <img src={gun} alt="" className="w-12 h-12" />
        )}
      </button>
    </div>
  );
};

export default Portal;
