import { AuthCollection, AuthEndpoints } from "./collections/auth";
import { GamesCollection, GamesEndpoints } from "./collections/games";
import { LobbyCollection, LobbyEndpoints } from "./collections/lobby";
import { ProfileCollection, ProfileEndpoints } from "./collections/profile";
import {
  SubscriptionCollection,
  SubscriptionEndpoints,
} from "./collections/subscription";
import { ZoWorldCollection, ZoWorldEndpoints } from "./collections/zoworld";

export type Endpoint =
  | AuthEndpoints
  | ProfileEndpoints
  | GamesEndpoints
  | LobbyEndpoints
  | SubscriptionEndpoints
  | ZoWorldEndpoints;

type EndpointCollection = { [key in Endpoint]?: string };

const include = (collection: { PATH: string; ENDPOINTS: EndpointCollection }) =>
  Object.fromEntries(
    Object.keys(collection.ENDPOINTS).map((k) => [
      k,
      collection.PATH + collection.ENDPOINTS[k as Endpoint],
    ])
  ) as EndpointCollection;

const ENDPOINTS: EndpointCollection = [
  AuthCollection,
  ProfileCollection,
  LobbyCollection,
  GamesCollection,
  SubscriptionCollection,
  ZoWorldCollection,
].reduce((z, c) => Object.assign(z, include(c)), {});

export const getApiEndpoint: (endpoint: Endpoint) => string = (
  endpoint: Endpoint
) => {
  if (endpoint in ENDPOINTS) {
    return `${process.env.REACT_APP_API_ZO}${ENDPOINTS[endpoint]}`;
  } else return "";
};
