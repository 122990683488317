import React, { useEffect, useRef, useState } from "react";
import { createNewSession } from "../../utils/auth";
import AuthContext from "./AuthContext";

interface AuthProviderProps {}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);
  const [tokenExpiry, setTokenExpiry] = useState<string | null>(null);
  const clientId = useRef<string>(
    process.env.REACT_APP_CLIENT_ID || ""
  ).current;

  const [isLoggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [sessionId, setSessionId] = useState<string>(createNewSession());
  const [user, setUser] = useState<any>();

  useEffect(() => {
    const localToken = localStorage.getItem("zo_token");
    if (token) {
      localStorage.setItem("zo_token", token);
    } else {
      if (localToken) {
        setToken(localToken);
      } else {
        setLoggedIn(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const localTokenExpiry = localStorage.getItem("zo_token_expiry");
    if (tokenExpiry) {
      localStorage.setItem("zo_token_expiry", tokenExpiry);
    } else {
      if (localTokenExpiry) {
        setTokenExpiry(localTokenExpiry);
      } else {
        setLoggedIn(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenExpiry]);

  useEffect(() => {
    const localStorageUser = localStorage.getItem("zo_user");
    const localUser = localStorageUser ? JSON.parse(localStorageUser) : null;
    if (user) {
      localStorage.setItem("zo_user", JSON.stringify(user));
    } else {
      if (localUser) {
        setUser(localUser);
        setSessionId(localUser.user_id);
      } else {
        setLoggedIn(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user && token && sessionId && tokenExpiry) {
      try {
        if (new Date(tokenExpiry).getTime() < new Date().getTime()) {
          logout();
        }
      } catch (error) {
        console.log(error);
      }
      setLoggedIn(true);
      sessionStorage.removeItem("zo_user");
      sessionStorage.removeItem("zo_token");
      sessionStorage.removeItem("zo_token_expiry");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token, sessionId, tokenExpiry]);

  const logout = () => {
    setLoggedIn(false);
    setUser(null);
    setToken(null);
    setTokenExpiry(null);
    localStorage.removeItem("zo_user");
    localStorage.removeItem("zo_token");
    localStorage.removeItem("zo_sessionId");
    localStorage.removeItem("zo_token_expiry");
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "development") {
      loginTest();
    }
  }, []);

  function loginTest() {
    if (
      process.env.REACT_APP_DEV_TOKEN &&
      process.env.REACT_APP_DEV_TOKEN_EXPIRY &&
      process.env.REACT_APP_DEV_USER &&
      JSON.parse(process.env.REACT_APP_DEV_USER) != null
    ) {
      setToken(process.env.REACT_APP_DEV_TOKEN);
      setTokenExpiry(process.env.REACT_APP_DEV_TOKEN_EXPIRY);
      setUser(JSON.parse(process.env.REACT_APP_DEV_USER));
    }
  }

  const login = (user: any, token: string, tokenExpiry: string) => {
    setUser(user);
    setToken(token);
    setTokenExpiry(tokenExpiry);
  };

  return (
    <AuthContext.Provider
      value={{
        clientId,
        token,
        isLoggedIn,
        sessionId,
        login,
        user,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
