import React from "react";
import { useHistory } from "react-router-dom";

import leaderboardIcon from "./../../../../../../assets/leaderboard.svg";
import ZoHeaderStat from "./../../../../../ui/ZoHeaderStat";
import useLocationActive from "../../hooks/useLocationActive";

const Leaderboard = () => {
  const history = useHistory();
  const isActive = useLocationActive("/leaderboard");

  return (
    <ZoHeaderStat
      className="zo-head_leaderboard"
      icon={leaderboardIcon}
      active={isActive}
      title={"Leaderboard"}
      onClick={() => {
        history.push("/leaderboard");
      }}
    />
  );
};

export default Leaderboard;
