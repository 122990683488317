import React from "react";

class WidgetErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: { message: "", stack: "" },
    info: { componentStack: "" },
  };

  static getDerivedStateFromError = (error) => {
    return { hasError: true };
  };

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    console.log(error, errorInfo);
    // You can also log error messages to an error reporting service here
  }

  render() {
    const { rowSpan, colSpan } = this.props;
    if (this.state.hasError) {
      // Error path
      return (
        <div
          className={`zo-widget w-full h-full bg-red-100 border-t-4 border-orange flex items-center justify-center overflow-hidden col-span-${colSpan} row-span-${rowSpan} rounded-lg`}
        >
          <h2 className="text-orange font-medium">Something went wrong.</h2>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default WidgetErrorBoundary;
