import React, { useContext, useMemo } from "react";

import ProfileContext from "../../../../../contexts/profile";
import RTCContext from "../../../context/rtcV2";
import Member from "./PartyMember";

const PartyMembers = ({ members, partyCode, isPartyHost, handleKick }) => {
  const { profile } = useContext(ProfileContext);
  const { peers, isActive, isSelfMute } = useContext(RTCContext);

  return useMemo(
    () => (
      <aside
        className="flex items-center border-l-4 bg-gray-200 rounded-r-lg overflow-x-auto overflow-y-visible relative w-content"
        style={{ maxWidth: "18rem" }}
      >
        {members != null &&
          members.map((member, index) => {
            const connected =
              profile.code === member.code
                ? peers.find((p) => p.display === member.code) || isActive
                : peers.find((p) => p.display === member.code);

            const speaking = connected
              ? connected.status === "talking"
                ? true
                : false
              : false;

            const muted =
              member.nickname === profile.nickname
                ? isSelfMute
                : connected
                ? connected.muted
                : false;

            return (
              <Member
                lastElement={index === members.length - 1}
                key={member.code}
                code={member.code}
                avatar={member.avatar_url}
                nickname={member.nickname}
                connected={
                  member.nickname === profile.nickname ? isActive : connected
                }
                speaking={speaking}
                muted={muted}
                isPartyHost={isPartyHost}
                partyCode={partyCode}
                isUser={member.code === profile.code}
                handleKick={handleKick}
              />
            );
          })}
      </aside>
    ),
    [
      handleKick,
      isActive,
      isPartyHost,
      isSelfMute,
      members,
      partyCode,
      peers,
      profile.code,
      profile.nickname,
    ]
  );
};

export default PartyMembers;
