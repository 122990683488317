import React from "react";

const GameInfo = () => {
  return (
    <aside className="pointer-events-auto rounded-lg flex-grow overflow-hidden w-1/3 flex flex-col items-center">
      <div className="bg-mafia-primary text-mafia-secondary border-mafia-secondary border-b-2 text-center py-2 font-semibold w-full flex-shrink-0">
        Game Stats
      </div>
      <div
        className={`border border-mafia-primary border-4 text-white w-full flex-grow scroll-hidden text-xs overflow-hidden flex flex-col items-start`}
        style={{ backgroundColor: "rgba(39, 44, 81, 0.9)" }}
      >
        <ul
          className={`overflow-auto flex w-full shadow-inner flex-col items-start p-2 h-56`}
        ></ul>
      </div>
    </aside>
  );
};

export default GameInfo;
