import React, { useContext, useEffect, useState } from "react";
import AppContext from ".";
import ZoWorld from "../../../apps/ZoWorld";
import useApi from "../../hooks/useApi";
import AuthContext from "../auth";
import { AuthProvider, ProfileProvider, SeedProvider } from "../providers";
import TutorialProvider from "../tutorial/provider";
// import Cult from "./../../apps/Cult";
import CommonRoom from "./../../apps/CommonRoomV2";
import Country from "./../../apps/Country";
import Zo from "./../../apps/Zo";
import ErrorBoundary from "./../../commons/ErrorBoundary";
import Locked from "./../../commons/Locked";
import NotFound from "./../../commons/NotFound";
import DeviceCheck from "./components/DeviceCheck";
import DeviceNotSupported from "./components/DeviceNotSupported";
import PermissionStatus from "./components/PermissionStatus";

const AppProvider = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const api = useApi("zo world", true);
  const [slug, setSlug] = useState(
    // For Dev change the last ""
    process.env.NODE_ENV === "production" ? "" : "jodhpur"
  );
  const [category, setCategory] = useState(
    // For Dev change the last ""
    process.env.NODE_ENV === "production" ? "" : "destination"
  );
  const [micPermissionStatus, setMicPermissionStatus] = useState("");

  const [isDeviceSupported, setDeviceSupported] = useState(null);

  useEffect(() => {
    if (window.sdData) {
      console.log(window.sdData.slug, window.sdData.category);
      if (window.sdData.slug) {
        setSlug(window.sdData.slug);
      }
      if (window.sdData.category) {
        setCategory(window.sdData.category);
      }
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      let asked = false;
      setTimeout(() => {
        if (!asked) {
          setMicPermissionStatus("asking");
        }
      }, 500);
      if (window && window.navigator && window.navigator.mediaDevices) {
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
          })
          .then(() => {
            asked = true;
            setMicPermissionStatus("success");
            setDeviceSupported(true);
          })
          .catch((e) => {
            asked = true;
            if (e.name === "NotAllowedError") {
              setMicPermissionStatus("denied");
              setDeviceSupported(true);
            } else if (e.name === "NotFoundError") {
              setMicPermissionStatus("not found");
              setDeviceSupported(false);
            }
          });
      } else {
        setDeviceSupported(false);
      }
    } else {
      if (window && window.navigator && window.navigator.mediaDevices) {
        setDeviceSupported(true);
      } else {
        setDeviceSupported(false);
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (
      process.env.REACT_APP_ROOT_DOMAIN === "zoworld.io" &&
      isLoggedIn &&
      slug != null &&
      slug !== "" &&
      api
    ) {
      api
        .post(
          "/zoworld/api/v1/tracker/visits/",
          JSON.stringify({ subdomain: slug })
        )
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, api]);

  function getApp() {
    if (window.location.host === `${process.env.REACT_APP_ROOT_DOMAIN}`) {
      return <ZoWorld />;
      // } else if (
      //   window.location.host === "localhost:3000" ||
      //   window.location.host.includes("192.168")
      // ) {
      // DEV PURPOSES
      // For Zo Component
      // return <Zo />;
      // For CommonRoom
      // return <Country slug={slug} />;
    } else if (category !== "" && slug !== "") {
      switch (category) {
        case "cult":
          if (slug && slug === "playmafia") {
            return (
              <ErrorBoundary>
                <AuthProvider>
                  <ProfileProvider>
                    <SeedProvider>
                      <TutorialProvider>
                        <Zo />
                      </TutorialProvider>
                    </SeedProvider>
                  </ProfileProvider>
                </AuthProvider>
              </ErrorBoundary>
            );
          } else {
            return (
              <ErrorBoundary>
                <AuthProvider>
                  <ProfileProvider>
                    <SeedProvider>
                      <TutorialProvider>
                        <Locked />
                      </TutorialProvider>
                    </SeedProvider>
                  </ProfileProvider>
                </AuthProvider>
              </ErrorBoundary>
            );
          }
        case "country":
          return (
            <ErrorBoundary>
              <AuthProvider>
                <ProfileProvider>
                  <SeedProvider>
                    <TutorialProvider>
                      <Country slug={slug} />
                    </TutorialProvider>
                  </SeedProvider>
                </ProfileProvider>
              </AuthProvider>
            </ErrorBoundary>
          );
        case "profile":
          return (
            <ErrorBoundary>
              <AuthProvider>
                <ProfileProvider>
                  <SeedProvider>
                    <TutorialProvider>
                      <CommonRoom />;
                    </TutorialProvider>
                  </SeedProvider>
                </ProfileProvider>
              </AuthProvider>
            </ErrorBoundary>
          );
        case "commonroom":
          return (
            <ErrorBoundary>
              <AuthProvider>
                <ProfileProvider>
                  <SeedProvider>
                    <TutorialProvider>
                      <CommonRoom />;
                    </TutorialProvider>
                  </SeedProvider>
                </ProfileProvider>
              </AuthProvider>
            </ErrorBoundary>
          );
        case "destination":
          return (
            <ErrorBoundary>
              <AuthProvider>
                <ProfileProvider>
                  <SeedProvider>
                    <TutorialProvider>
                      <Zo />
                    </TutorialProvider>
                  </SeedProvider>
                </ProfileProvider>
              </AuthProvider>
            </ErrorBoundary>
          );
        case "locked":
          if (slug && slug === "world") {
            return (
              <ErrorBoundary>
                <AuthProvider>
                  <ProfileProvider>
                    <SeedProvider>
                      <TutorialProvider>
                        <Zo />
                      </TutorialProvider>
                    </SeedProvider>
                  </ProfileProvider>
                </AuthProvider>
              </ErrorBoundary>
            );
          }
          return (
            <ErrorBoundary>
              <AuthProvider>
                <ProfileProvider>
                  <SeedProvider>
                    <TutorialProvider>
                      <Locked />
                    </TutorialProvider>
                  </SeedProvider>
                </ProfileProvider>
              </AuthProvider>
            </ErrorBoundary>
          );
        default:
          return (
            <ErrorBoundary>
              <AuthProvider>
                <ProfileProvider>
                  <SeedProvider>
                    <TutorialProvider>
                      <NotFound />
                    </TutorialProvider>
                  </SeedProvider>
                </ProfileProvider>
              </AuthProvider>
            </ErrorBoundary>
          );
      }
    } else {
      return (
        <ErrorBoundary>
          <AuthProvider>
            <ProfileProvider>
              <SeedProvider>
                <TutorialProvider>
                  <NotFound />
                </TutorialProvider>
              </SeedProvider>
            </ProfileProvider>
          </AuthProvider>
        </ErrorBoundary>
      );
    }
  }

  return (
    <AppContext.Provider value={{ slug, category }}>
      {isDeviceSupported != null ? (
        isDeviceSupported ? (
          getApp()
        ) : (
          <DeviceNotSupported />
        )
      ) : (
        <DeviceCheck />
      )}
      <PermissionStatus
        status={micPermissionStatus}
        close={setMicPermissionStatus.bind(null, "")}
      />
    </AppContext.Provider>
  );
};

export default AppProvider;
