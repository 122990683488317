import Axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { useRTC } from "../../../contexts";
import Footer from "../components/Footer";
import GameModals from "../components/GameModals";
import Header from "../components/Header";
import Table from "../components/Table";
import TravelPortalOptions from "../components/TravelPortalOptions";
import GameContext from "../contexts/game";
import InGameContext from "../contexts/inGame";
import ModalContext from "../contexts/modal";
import RoomContext from "../contexts/room";
import SocketContext from "../contexts/socket";
import TimeContext from "../contexts/time";
import gameReducer from "../reducer";
import gameFlow from "./../../../../../configs/mafia/gameFlow.json";
import zobu from "./../assets/zobu.svg";

// import useAudio from "../hooks/useAudio";
// import thrillerAudio from "./../assets/sounds/thriller.wav";
// import nightStartAudio from "./../assets/sounds/night-start.mp3";
// import nightBackgroundAudio from "./../assets/sounds/night-background.mp3";
// import nightSelectDetectiveAudio from "./../assets/sounds/night-detective-select.mp3";
// import nightSelectMafiaAudio from "./../assets/sounds/night-mafia-select.mp3";
// import nightSelectHealerAudio from "./../assets/sounds/night-healer-select.wav";
// import nightResultHealerAudio from "./../assets/sounds/night-result-healer.mp3";
// import nightResultMafiaAudio from "./../assets/sounds/night-result-mafia.mp3";
// import daySelectAudio from "./../assets/sounds/day-select.mp3";
// import dayResultMafiaAudio from "./../assets/sounds/day-result-mafia.mp3";
// import dayResultVillagerAudio from "./../assets/sounds/day-result-villager.mp3";

const initialGameState = {
  player: null,
  roomRound: "",
  roomStatus: "",
  playersStates: {},
};

const GameScene = () => {
  const { room } = useContext(RoomContext);
  const { playersStates: oldPlayersStates, player: oldPlayer } =
    useContext(GameContext);
  const { message, sendSocketMessage } = useContext(SocketContext);
  const {
    setRoundStartTime,
    setRoundTimeSpeed,
    setRoundTimeLeft,
    setGameStartTime,
    setRoundTime,
    setGameOver,
  } = useContext(TimeContext);
  const { setModal } = useContext(ModalContext);
  const { setDisplayName, setUserCode, setRoomCode, setRecordName } = useRTC();
  // const { toggle: toggleThrillerAudio } = useAudio(thrillerAudio, true, 0.1);
  // const { start: startNightAudio, stop: stopNightAudio } = useAudio(
  //   nightBackgroundAudio,
  //   true
  // );
  // const { start: playNightStartAudio } = useAudio(nightStartAudio, false);
  // const { start: playNightSelectHealer } = useAudio(
  //   nightSelectHealerAudio,
  //   false
  // );
  // const { start: playNightSelectMafia } = useAudio(
  //   nightSelectMafiaAudio,
  //   false
  // );
  // const { start: playNightSelectDetective } = useAudio(
  //   nightSelectDetectiveAudio,
  //   false
  // );
  // const { start: playNightResultHealer } = useAudio(
  //   nightResultHealerAudio,
  //   false
  // );
  // const { start: playNightResultMafia } = useAudio(
  //   nightResultMafiaAudio,
  //   false
  // );
  // const { start: playDayResultMafia } = useAudio(dayResultMafiaAudio, false);
  // const { start: playDayResultVillager } = useAudio(
  //   dayResultVillagerAudio,
  //   false
  // );
  // const { start: playDaySelect } = useAudio(daySelectAudio, false);

  const [state, dispatch] = useReducer(gameReducer, initialGameState, () => {
    if (room.players_count === Object.keys(oldPlayersStates).length) {
      return {
        ...initialGameState,
        playersStates: oldPlayersStates,
        player: oldPlayer,
        roomRound: room.round,
        roomStatus: room.status,
      };
    } else if (room.players_count === room.players.length) {
      const newPlayersStates = {};
      room.players.forEach((p) => {
        if (p.passport_id) {
          Axios.get(
            `https://profile.${process.env.REACT_APP_API_DOMAIN}/profile/api/v1/profile/lobby/${p.passport_id}/`
          )
            .then((res) => {
              if (res.status === 200) {
                if (res.data.profile && res.data.profile.avatar_url) {
                  dispatch([
                    "player_avatar",
                    {
                      id: p.id,
                      avatar: res.data.profile.avatar_url,
                    },
                  ]);
                }
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
        newPlayersStates[p.id] = { ...p, avatar: zobu };
      });
      return {
        ...initialGameState,
        playersStates: newPlayersStates,
        player: oldPlayer,
        roomRound: room.round,
        roomStatus: room.status,
      };
    }
  });

  const roundsInfo = useMemo(() => gameFlow[room.game_flow], [room.game_flow]);

  const getPlayersByKeyCheck = useCallback(
    (key, value) => {
      const _playersStates = { ...state.playersStates };
      const playersNeeded = [];
      Object.values(_playersStates).forEach((_player) => {
        if (_player[key] === value) {
          playersNeeded.push({ ..._player });
        }
      });
      return playersNeeded;
    },
    [state.playersStates]
  );

  const resetVotes = useCallback(() => {
    console.log("[DEBUG] Clearing votes");
    Object.keys(state.playersStates).forEach((id) => {
      dispatch(["player_vote", { id, vote: null }]);
    });
  }, [state.playersStates]);

  const handleRoomRound = useCallback(
    (round, timeStart, timeLeft, clockSpeed, isResuming) => {
      if (!isResuming) {
        sendSocketMessage("player_vote", { id: state.player.id, vote: null });
        resetVotes();
      }
      if (roundsInfo[round] && roundsInfo[round].time) {
        setRoundTime(roundsInfo[round].time);
      }
      if (timeStart && timeLeft && clockSpeed) {
        console.log(
          "Setting time",
          timeLeft,
          (+new Date() - timeStart) / 1000 > timeLeft
        );
        if (!((+new Date() - timeStart) / 1000 > timeLeft)) {
          setRoundStartTime(timeStart);
          setRoundTimeLeft(timeLeft);
          setRoundTimeSpeed(clockSpeed);
        }
      }
      switch (round) {
        case "identity":
          break;
        case "night":
          if (
            state.player &&
            state.playersStates[state.player.id] &&
            state.playersStates[state.player.id].status === "alive"
          ) {
            console.log(state.player);
            setModal(["night", state.playersStates[state.player.id].role]);
          }
          break;
        case "day":
          const villagerSave = state.playersStates
            ? Object.values(state.playersStates).find(
                (p) => p.role === "villager-save"
              )
            : null;

          console.log("DEBUG", villagerSave);
          if (villagerSave) {
            setModal([
              "day",
              state.playersStates[state.player.id].role,
              villagerSave,
            ]);
          }
          break;
        case "break":
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resetVotes, state.player]
  );

  const handlePlayerStatus = useCallback(
    (id, status) => {
      if (status === "killed") {
        if (id) {
          setModal(["night_result", state.playersStates[id]]);
        } else {
          setModal(["night_result", null]);
        }
      } else if (status === "lynched") {
        setModal(["public_hang", state.playersStates[id]]);
      } else if (status === "revenged") {
        setModal(["vengeful_kill", state.playersStates[id]]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.playersStates]
  );

  const handleTimeUpdate = useCallback(
    (timeStart, timeLeft, clockSpeed) => {
      if (timeStart && timeLeft && clockSpeed) {
        console.log("updating time");
        setRoundTimeSpeed(clockSpeed);
        setRoundStartTime(timeStart);
        setRoundTimeLeft(timeLeft);
      }
    },
    [setRoundStartTime, setRoundTimeLeft, setRoundTimeSpeed]
  );

  useEffect(() => {
    if (room.status === "ended_mafia" || room.status === "ended_village") {
      setModal(["end-screen", room.status]);
    } else {
      if (
        state.roomStatus === "ended_mafia" ||
        state.roomStatus === "ended_village"
      ) {
        setModal(["ended", state.roomStatus]);
        setGameOver(true);
      }
      if (state.roomRound) {
        if (
          state.roomStatus !== "ended_mafia" &&
          state.roomStatus !== "ended_village"
        ) {
          if (room.round_time) {
            const { timeStart, timeLeft, clockSpeed } = room.round_time;
            if (timeStart && timeLeft && clockSpeed) {
              handleRoomRound(
                state.roomRound,
                timeStart,
                timeLeft,
                clockSpeed,
                true
              );
            }
          } else {
            if (state.roomRound === "day") {
              const alivePlayers = getPlayersByKeyCheck(
                "status",
                "alive"
              ).length;
              console.log("Alive players", alivePlayers);
              const timeForRound = Math.min(alivePlayers / 2 + 1, 5) * 60;
              console.log(timeForRound);
              const timeStart = +new Date();
              const timeLeft = timeForRound;
              const clockSpeed = 1;
              handleRoomRound(
                state.roomRound,
                timeStart,
                timeLeft,
                clockSpeed,
                true
              );
            } else {
              const timeStart = +new Date();
              const timeLeft = roundsInfo[state.roomRound].time;
              const clockSpeed = 1;
              handleRoomRound(
                state.roomRound,
                timeStart,
                timeLeft,
                clockSpeed,
                true
              );
            }
          }
        }
        if (room.room_start_time) {
          setGameStartTime(room.room_start_time);
        } else {
          setGameStartTime(+new Date());
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (message) {
      if (message.action === "room_round") {
        handleRoomRound(
          message.payload.round,
          message.payload.round_time.timeStart,
          message.payload.round_time.timeLeft,
          message.payload.round_time.clockSpeed
        );
      } else if (message.action === "room_status") {
        if (
          message.payload.status === "ended_mafia" ||
          message.payload.status === "ended_village"
        ) {
          setModal(["ended", message.payload.status]);
          setGameOver(true);
        }
      } else if (message.action === "detective_reveal") {
        if (
          state.player.id &&
          state.player.id === message.payload.id &&
          state.roomStatus !== "ended_mafia" &&
          state.roomStatus !== "ended_village" &&
          state.playersStates[state.player.id] &&
          state.playersStates[state.player.id].status === "alive"
        ) {
          setModal(["detective", state.playersStates[message.payload.vote]]);
        }
      } else if (message.action === "player_status") {
        handlePlayerStatus(message.payload.id, message.payload.status);
      } else if (message.action === "time_bank") {
        dispatch([
          "player_time_bank",
          {
            id: message.payload.id,
            timeBankUsed: message.payload.timeBankUsed,
          },
        ]);
      } else if (message.action === "round_time") {
        handleTimeUpdate(
          message.payload.round_time.timeStart,
          message.payload.round_time.timeLeft,
          message.payload.round_time.clockSpeed
        );
      } else if (message.action === "room_start") {
        setGameStartTime(message.payload.room_start_time);
        dispatch([
          "room_round",
          {
            round: message.payload.round,
          },
        ]);
        handleRoomRound(
          message.payload.round,
          message.payload.round_time.timeStart,
          message.payload.round_time.timeLeft,
          message.payload.round_time.clockSpeed
        );
      } else if (message.action === "village-revenge") {
        if (
          state.player.id &&
          state.player.id === message.payload.id &&
          state.roomStatus !== "ended_mafia" &&
          state.roomStatus !== "ended_village" &&
          state.playersStates[state.player.id]
        ) {
          setModal(["village-revenge"]);
        }
      } else if (message.action === "mafia-reveal") {
        if (
          state.player.id &&
          state.player.id === message.payload.id &&
          state.roomStatus !== "ended_mafia" &&
          state.roomStatus !== "ended_village" &&
          state.playersStates[state.player.id]
        ) {
          setModal(["mafia-reveal"]);
        }
      } else if (message.action === "villager-reveal") {
        if (
          state.roomStatus !== "ended_mafia" &&
          state.roomStatus !== "ended_village" &&
          message.payload.id &&
          state.playersStates &&
          state.playersStates[message.payload.id]
        ) {
          setModal([
            "villager_reveal",
            state.playersStates[message.payload.id],
          ]);
        }
      }

      dispatch([message.action, message.payload]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    if (state.player && state.player.nickname && room && room.code) {
      setRoomCode(room.code);
      setUserCode(state.player.passport_id || state.player.auth_user_id);
      setDisplayName(state.player.nickname);
      setRecordName(
        process.env.NODE_ENV === "production" ? `mafia/${room.code}` : false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.player, room]);

  // Below useEffects are only for Audio purposes
  // useEffect(() => {
  //   toggleThrillerAudio();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   if (state.roomRound === "night") {
  //     playNightStartAudio();
  //     startNightAudio();
  //   } else if (state.roomRound === "day") {
  //     stopNightAudio();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state.roomRound]);

  // useEffect(() => {
  //   if (message) {
  //     if (message.action === "player_vote") {
  //       if (state.roomRound === "night") {
  //         if (state.player.id && state.player.id === message.payload.id) {
  //           if (message.payload.detective) {
  //             playNightSelectDetective();
  //           }
  //           if (state.player) {
  //             if (state.player.role === "mafia") {
  //               playNightSelectMafia();
  //             }
  //             if (state.player.role === "healer") {
  //               // playNightSelectHealer();
  //             }
  //           }
  //         }
  //       } else {
  //         playDaySelect();
  //       }
  //     } else if (message.action === "player_status") {
  //       if (message.payload.status === "killed") {
  //         if (message.payload.id) {
  //           playNightResultMafia();
  //         } else {
  //           playNightResultHealer();
  //         }
  //       } else if (message.payload.status === "lynched") {
  //         if (message.payload.id && state.playersStates[message.payload.id]) {
  //           if (state.playersStates[message.payload.id].role === "mafia") {
  //             playDayResultMafia();
  //           } else {
  //             playDayResultVillager();
  //           }
  //         }
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [message, state.roomRound, state.player, state.playersStates]);

  return useMemo(
    () => (
      <InGameContext.Provider
        value={{
          ...state,
          player: state.playersStates[state.player.id] || state.player,
          update: dispatch,
        }}
      >
        <div className="flex flex-col items-center bg-mafia-background">
          <Header />
          <Table />
          <Footer />
          <GameModals />
          <TravelPortalOptions
            visible={(() => {
              if (state.roomStatus !== "started") {
                return true;
              } else {
                if (
                  state.player != null &&
                  state.playersStates != null &&
                  state.player.id != null &&
                  state.playersStates[state.player.id] != null &&
                  state.playersStates[state.player.id].status === "alive"
                ) {
                  return false;
                } else {
                  return true;
                }
              }
            })()}
          />
        </div>
      </InGameContext.Provider>
    ),
    [state]
  );
};

export default GameScene;
