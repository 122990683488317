import { createContext } from "react";

const RTCContext = createContext({
  peers: [],
  isActive: false,
  isSelfMute: true,
  streamMuted: false,
  rtcSupported: true,
  roomCode: null,
  volume: 1,
  setIsSelfMute: (status) => {},
  setStreamMuted: (status) => {},
  setPeers: (peers) => {},
  setActive: (active) => {},
  setRoomCode: () => {},
  setUserCode: () => {},
  setDisplayName: () => {},
  setControlsVisible: () => {},
  setVolume: (volume) => {},
});

export default RTCContext;
