import React, { useRef, useState, useEffect, useMemo } from "react";

import RTCAudioIcon from "./../../../../ZoComm/components/Members/components/RTCAudioIcon";
import Avatar from "../../../../../ui/Avatar";

const PartyMember = ({
  code,
  lastElement,
  avatar,
  nickname,
  speaking,
  muted,
  connected,
  partyCode,
  handleKick,
  isPartyHost,
  isUser,
}) => {
  const modalRef = useRef();
  const [isOptionsVisible, setOptionsVisible] = useState(false);

  useEffect(() => {
    if (isOptionsVisible) {
      document.addEventListener("click", checkClick);
    }
    return () => {
      document.removeEventListener("click", checkClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOptionsVisible]);

  const checkClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setOptionsVisible(false);
    }
  };

  return useMemo(
    () => (
      <li className="inline-flex flex-col items-center w-auto h-18 m-3">
        <div className="flex flex-col items-center w-full h-full relative group w-16 mb-5 cursor-pointer">
          <div
            className={`relative w-12 h-12 rounded-full border-2 ${
              muted
                ? "bg-gray-400 border-gray-400"
                : speaking
                ? "border-orange bg-red-100"
                : "bg-white"
            } group-hover:bg-red-200`}
          >
            {
              <div>
                <Avatar svg={avatar} className="w-full h-full" />
              </div>
            }
            {connected && <RTCAudioIcon speaking={speaking} muted={muted} />}
            {partyCode === code && (
              <div className="absolute top-0 left-0 group-a w-4 h-4 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  className="fill-current text-green-500 bg-white rounded-full w-4 h-4"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm3.23 15.39L12 15.45l-3.22 1.94c-.38.23-.85-.11-.75-.54l.85-3.66-2.83-2.45c-.33-.29-.15-.84.29-.88l3.74-.32 1.46-3.45c.17-.41.75-.41.92 0l1.46 3.44 3.74.32c.44.04.62.59.28.88l-2.83 2.45.85 3.67c.1.43-.36.77-.74.54z" />
                </svg>
                <span
                  className={`absolute pointer-events-none z-40 opacity-0 bg-gray-700 text-white whitespace-no-wrap font-medium px-2 py-1 leading-none rounded-full text-xs group-a-hover:opacity-100 ${
                    lastElement ? "right-full" : ""
                  }`}
                >
                  Party Host
                </span>
              </div>
            )}
          </div>
          <div className="w-16 leading-none flex justify-center text-center group-hover:w-auto group-hover:z-10 group-hover:border rounded-lg absolute bottom-0 truncate text-xs font-medium text-gray-800 mt-2 -mb-6">
            {isPartyHost && !isUser && (
              <button
                className="bg-red-500 z-10 absolute p-1 top-0 rounded-full text-white font-semibold leading-none hidden group-hover:block"
                onClick={() => {
                  handleKick(code);
                }}
              >
                Kick
              </button>
            )}
            <span
              className={`whitespace-no-wrap p-1 block ${
                isPartyHost && !isUser
                  ? "group-hover:opacity-0"
                  : "group-hover:bg-white"
              }`}
            >
              {nickname}
            </span>
          </div>
        </div>
      </li>
    ),
    [
      avatar,
      code,
      connected,
      handleKick,
      isPartyHost,
      isUser,
      lastElement,
      muted,
      nickname,
      partyCode,
      speaking,
    ]
  );
};

export default PartyMember;
