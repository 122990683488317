import React, { useEffect, useState } from "react";

import Widget from "../../ui/Widget";
import PhotoModal from "./PhotoModal";
import { useSeed } from "../../../contexts/seed";
import useApi from "../../../../../hooks/useApi";

const PhotoOTD = (props) => {
  const api = useApi("zo world", false);
  const [photo, setPhoto] = useState(null);
  const [isVisible, setVisible] = useState(false);
  const { seedData } = useSeed();
  useEffect(() => {
    if (api) {
      seedData.code
        ? api
            .get(`/zoworld/api/v1/photo/${seedData.code}/today/`)
            .then((res) => {
              setPhoto(res.data.photos);
            })
            .catch((e) => {
              console.log(e);
            })
        : api
            .get(`/zoworld/api/v1/photo/today/`)
            .then((res) => {
              setPhoto(res.data.photos);
            })
            .catch((e) => {
              console.log(e);
            });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seedData.code, api]);

  return (
    <>
      <Widget
        className="zo-photo bg-white"
        {...props}
        onClick={setVisible.bind(null, true)}
      >
        <div
          className="flex flex-col justify-end w-full h-full portrait:h-108 bg-center bg-cover rounded-md"
          style={{ backgroundImage: photo && photo[0] ? `url(${photo[0].image}?w=1000&h=1000&c=80)` : null }}
        >
          <div className="px-2 pt-2 pb-2 font-bold text-white zo-gradient-black-bottom rounded-md">
            {props.data && props.data.title}
          </div>
        </div>
      </Widget>
      {isVisible && (
        <PhotoModal close={setVisible.bind(null, false)} photo={photo} />
      )}
    </>
  );
};

export default PhotoOTD;
