import React, { useContext, useEffect, useState, useRef } from "react";
import { createHash } from "crypto";

import Widget from "../../ui/Widget";
import { useInput, useResponseFlash } from "../../hooks";
import { useSeed } from "../../../contexts/seed";
import AuthContext from "../../../../../contexts/auth";
import useApi from "../../../../../hooks/useApi";
import { useHistory } from "react-router-dom";

const getSHA1 = function () {
  return createHash("sha1")
    .update(
      JSON.stringify({
        timestamp: Date.now(),
        rand: Math.random() * 10000,
      })
    )
    .digest("hex")
    .substr(0, 10);
};

const createNewSession = () => {
  const sessionId = getSHA1();
  return sessionId;
};

const MafiaQuickJoin = (props) => {
  const { showAuthModal } = useContext(AuthContext);
  const api = useApi("auth", false);
  const {
    style: { primaryColor, accentColor },
  } = useSeed();
  const {
    value: nickname,
    bind: bindNickname,
    setValue: setNickname,
  } = useInput("");
  const { value: roomCode, bind: bindRoomCode } = useInput("");
  const { response, setResponse } = useResponseFlash("");
  const history = useHistory();

  const [anonLoggedIn, setAnonLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const anonId = useRef(createNewSession().substr(0, 4));

  useEffect(() => {
    const anon_user_token = localStorage.getItem("anon_user_token");
    const anon_user = localStorage.getItem("anon_user");
    const anon_token_expiry = localStorage.getItem("anon_token_expiry");
    const anon_nickname = localStorage.getItem("anon_nickname");
    if (
      anon_nickname &&
      anon_token_expiry &&
      anon_user &&
      anon_user_token &&
      JSON.parse(anon_user) &&
      JSON.parse(anon_user).app_id &&
      JSON.parse(anon_user).user_id
    ) {
      setAnonLoggedIn(true);
      setNickname(anon_nickname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleJoin = async (e) => {
    e.preventDefault();
    if (!nickname) {
      setResponse("Enter a nickname.");
      return;
    } else if (!roomCode) {
      setResponse("Enter the room code.");
      return;
    } else {
      setLoading(true);
      if (!anonLoggedIn) {
        try {
          if (nickname.trim().length) {
            const _nickname = `${nickname.trim()}-${anonId.current}`;
            const data = {
              nickname: _nickname.replace(/ /g, ""),
            };
            const response = await api.post(
              "/auth/api/v1/anon-login/",
              JSON.stringify(data),
              {
                headers: {
                  "Content-Type": "application/json",
                  "Client-App-Id": "Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU",
                  "Client-User-Id": createNewSession(),
                },
              }
            );
            console.log(response);
            if (response.status === 200) {
              localStorage.setItem(
                "anon_user",
                JSON.stringify(response.data.user)
              );
              localStorage.setItem("anon_user_token", response.data.user_token);
              localStorage.setItem(
                "anon_token_expiry",
                response.data.token_expiry
              );
              localStorage.setItem("anon_nickname", data.nickname);
              history.push(`/r/${roomCode}`);
            }
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        history.push(`/r/${roomCode}`);
      }
    }
  };

  return (
    <Widget
      className={`portrait:h-144 p-2 scroll-hidden flex flex-col items-center justify-center h-full bg-${
        primaryColor ? primaryColor : "white"
      } border-t-4 border-${accentColor}`}
      {...props}
    >
      <span className="text-4xl tracking-wide uppercase font-bold text-white mb-8">
        Mafia Quick Join
      </span>
      <form className="flex-col flex items-center" onSubmit={handleJoin}>
        {!anonLoggedIn ? (
          <>
            <div className="flex items-center w-84">
              <input
                type="text"
                placeholder="Choose a Nickname"
                className="p-3 rounded-l-lg flex-grow text-mafia-background text-lg focus:outline-none"
                {...bindNickname}
              />
              <span className="bg-mafia-background text-white rounded-r-lg p-3 flex-shrink-0 text-lg">
                - {anonId.current}
              </span>
            </div>
            {nickname ? (
              <span className="text-xs text-white h-6 flex items-center">
                Your anonymous username: {`${nickname}-${anonId.current}`}
              </span>
            ) : (
              <span className="h-6" />
            )}
          </>
        ) : (
          <div className="text-lg mb-6">
            Your Nickname:{" "}
            <span className="text-mafia-secondary font-bold">
              {localStorage.getItem("anon_nickname")}
            </span>
          </div>
        )}
        <input
          type="text"
          placeholder="Room Code"
          className="w-84 p-3 rounded-lg flex-grow text-mafia-background text-lg focus:outline-none"
          {...bindRoomCode}
        />
        <div className="h-8 flex items-center justify-center font-semibold text-xs text-mafia-secondary">
          {response}
        </div>
        <button
          type="submit"
          disabled={loading}
          className="bg-mafia-secondary border-mafia-background font-semibold px-4 py-3 text-lg uppercase tracking-wide rounded-lg shadow-md text-mafia-primary"
        >
          {loading ? "Joining ..." : "Join Game"}
        </button>
      </form>
      <span className="my-6">Or</span>
      <span className="text-lg w-84 text-center">
        <button
          className="text-mafia-secondary font-bold hover:underline"
          onClick={showAuthModal}
        >
          Login
        </button>{" "}
        to view public rooms, get your custom nickname and avatar
      </span>
    </Widget>
  );
};

export default MafiaQuickJoin;
