import moment from "moment";
import React, { useContext, useState } from "react";
import { useResponseFlash } from "../../../../../../../../../components/apps/Zo/components/hooks";
import ProfileContext from "../../../../../../../contexts/profile/ProfileContext";
import useApi from "../../../../../../../hooks/useApi";
import SubSectionRow from "../../../components/SubSectionRow";
import DOBInput from "./DOBinput";

const DOBField = () => {
  const api = useApi("PROFILE_ME");
  const { profile, updateProfile } = useContext(ProfileContext);
  const [dob, setDob] = useState(profile.date_of_birth || "");
  const { response, setResponse } = useResponseFlash();

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loading && api) {
      setLoading(true);
      try {
        const data = {
          date_of_birth: dob,
        };
        console.log(data);

        const response = await api.post(``, JSON.stringify(data));
        if (response.status === 200) {
          updateProfile(data);
          setExpanded(false);
        } else {
          setResponse("Error in fields");
        }
      } catch (error) {
        console.log(error.response);
        if (error.response.status === 422) {
          setResponse(error.response.data.errors.date_of_birth[0]);
          setLoading(false);
        } else {
          setResponse("Error in setting the nickname.");
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <SubSectionRow
      title="Date of Birth"
      visibleArea={
        profile.date_of_birth &&
        moment(profile.date_of_birth).format("Do MMMM, YYYY")
      }
      placeholder="When were you born?"
      isExpanded={expanded}
      setExpanded={setExpanded}
    >
      <form className="flex flex-col w-full" onSubmit={handleSubmit}>
        <div className="flex w-full">
          <DOBInput
            onChange={(e) => setDob(e.target.value)}
            initialValue={profile.date_of_birth}
          />
        </div>
        <div className="w-full h-6 flex items-center justify-start font-semibold text-xs text-orange">
          {response}
        </div>
        <div className="flex mb-4 w-full">
          <button
            className="bg-orange text-white cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            type="submit"
          >
            {loading ? "Saving" : "Save"}
          </button>
          <button
            className="bg-white text-orange cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            onClick={setExpanded.bind(null, false)}
          >
            Cancel
          </button>
        </div>
      </form>
    </SubSectionRow>
  );
};

export default DOBField;
