import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";

import "./index.css";
import Portal from "../../ui/Portal";
import useApi from "../../hooks/useApi";
import TravelModal from "./components/modals/TravelModal";
import PortalMain from "./components/PortalV2";
import ProfileContext from "../../contexts/profile";
import { setCookie, deleteCookie } from "../../../utils";
import Requests from "./components/Requests";
import RTCProvider from "./context/rtcV2/provider";
import Party from "./components/Party";
import DestinationSuggestions from "./components/DestinationSuggestions";

const TravelPortal = ({
  isHost,
  message,
  partyMembers,
  partyMessage,
  partySocket,
  setPartyMembers,
  setPartySocketURL,
  setPartyToken,
  sendPartyMessage,
  setPartyCode,
  partyCode,
  sendSocketMessage,
  setSentMemberRequests,
  setSentPartyRequests,
  addNotification,
  partyTalking,
  setPartyTalking,
}) => {
  const commApi = useApi("comms", true);
  const { profile } = useContext(ProfileContext);

  const [travelDetails, setTravelDetails] = useState(null);
  const [partyRequests, setPartyRequests] = useState([]);
  const [memberRequests, setMemberRequests] = useState([]);
  const [destinationSuggestions, setDestinationSuggestions] = useState([]);

  const isPartyHost = useMemo(() => {
    if (partyCode && profile.code === partyCode) {
      return true;
    } else {
      return false;
    }
  }, [partyCode, profile.code]);

  useEffect(() => {
    if (partyMessage) {
      switch (partyMessage.action) {
        case "travel":
          const { cult, link } = partyMessage.payload;
          if (cult && link) {
            setTravelDetails(partyMessage.payload);
          }
          break;
        case "travel_cancel":
          setTravelDetails(null);
          break;
        case "destination_suggest":
          setDestinationSuggestions((ds) => [
            ...ds,
            {
              id: Math.random(),
              name: partyMessage.payload.room.name,
              from: partyMessage.payload.from.nickname,
            },
          ]);
          break;
        case "member_kick":
          if (partyMessage.payload.code) {
            if (partyMessage.payload.code === profile.code) {
              setPartyCode(profile.code);
            } else {
              setPartyMembers((m) =>
                m.filter((me) => me.code !== partyMessage.payload.code)
              );
            }
          }
          break;
        default:
          break;
      }
    }
  }, [partyCode, partyMessage, profile, setPartyCode, setPartyMembers]);

  useEffect(() => {
    if (partyCode && commApi) {
      console.log("Connecting to new party");
      commApi
        .get(`/lobby/api/v1/party/${partyCode}/info/`)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.socket && response.data.members) {
              connectToSocket(
                response.data.socket.path,
                response.data.socket.token,
                response.data.members
              );
              setCookie("partyCode", partyCode, +new Date() + 1000 * 60 * 60);
            }
          } else {
            // setTimeout(setPartyRequestDetails.bind(null, null), 1000);
          }
        })
        .catch((error) => {
          console.log(error);
          // setTimeout(setPartyRequestDetails.bind(null, null), 1000);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyCode, commApi]);

  useEffect(() => {
    if (message && profile) {
      switch (message.action) {
        case "party_request_response":
          if (
            message.payload.to &&
            message.payload.to.nickname === profile.nickname &&
            message.payload.from &&
            message.payload.from.nickname
          ) {
            if (message.payload.response === "accept") {
              addNotification(
                "New Member",
                `${message.payload.from.nickname} joined the party.`
              );
            } else if (message.payload.response === "decline") {
              addNotification(
                "Invitation Denied",
                `${message.payload.from.nickname} denied your party invitation.`
              );
            } else if (message.payload.response === "expired") {
              addNotification(
                "Invitation Expired",
                `${message.payload.from.nickname}'s party invitation expired.`
              );
            } else if (message.payload.response === "busy") {
              addNotification(
                "Already in a party",
                `${message.payload.from.nickname} is in another party.`
              );
            }
            setSentPartyRequests((p) =>
              p.filter((_p) => _p !== message.payload.from.nickname)
            );
          }
          break;
        case "member_request":
          if (
            message.payload.to &&
            message.payload.to.nickname === profile.nickname &&
            message.payload.from &&
            message.payload.from.nickname
          ) {
            const newRequest = {
              id: message.payload.id,
              from: message.payload.from.nickname,
            };
            setMemberRequests((r) => [...r, newRequest]);
          }
          if (
            message.payload.from &&
            message.payload.from.nickname === profile.nickname &&
            message.payload.to &&
            message.payload.to.nickname
          ) {
            setSentMemberRequests((p) => [...p, message.payload.to.nickname]);
          }
          break;
        case "member_request_response":
          if (
            message.payload.to &&
            message.payload.to.nickname === profile.nickname &&
            message.payload.from &&
            message.payload.from.nickname
          ) {
            if (
              message.payload.response === "accept" &&
              message.payload.from.partyCode
            ) {
              addNotification(
                "Welcome to the party",
                `${message.payload.from.nickname} accepted your request.`
              );
              setPartyMembers([]);
              setPartyCode(message.payload.from.partyCode);
            } else if (message.payload.response === "decline") {
              addNotification(
                "Join Request Denied",
                `${message.payload.from.nickname} denied your request to join their party.`
              );
            } else if (message.payload.response === "expired") {
              addNotification(
                "Join Request Expired",
                `Request to join ${message.payload.from.nickname}'s party expired.`
              );
            }
            setSentMemberRequests((p) =>
              p.filter((_p) => _p !== message.payload.from.nickname)
            );
          }
          break;
        default:
          break;
      }
    }
  }, [
    message,
    profile,
    setPartyCode,
    setSentMemberRequests,
    setSentPartyRequests,
    addNotification,
    setPartyMembers,
  ]);

  useEffect(() => {
    if (message && message.action === "new_premium") {
      if (message.payload.code !== profile.code) {
        addNotification(
          "New Premium Member",
          `${message.payload.nickname} just became a premium member.`
        );
      }
    }
  }, [addNotification, message, profile.code]);

  useEffect(() => {
    if (message && profile) {
      switch (message.action) {
        case "party_request":
          if (
            message.payload.to &&
            message.payload.to.nickname === profile.nickname &&
            message.payload.from &&
            message.payload.from.nickname &&
            message.payload.from.partyCode
          ) {
            console.log(partyMembers.length);
            if (partyMembers.length > 1) {
              sendSocketMessage({
                action: "party_request_response",
                payload: {
                  response: "busy",
                  to: { nickname: message.payload.from.nickname },
                  from: { nickname: profile.nickname },
                },
              });
            } else {
              const newRequest = {
                id: message.payload.id,
                from: message.payload.from.nickname,
                partyCode: message.payload.from.partyCode,
              };
              setPartyRequests((r) => [...r, newRequest]);
            }
          }
          if (
            message.payload.from &&
            message.payload.from.nickname === profile.nickname &&
            message.payload.to &&
            message.payload.to.nickname
          ) {
            setSentPartyRequests((p) => [...p, message.payload.to.nickname]);
          }
          break;
        default:
          break;
      }
    }
  }, [
    message,
    partyMembers,
    profile,
    sendSocketMessage,
    setPartyCode,
    setSentMemberRequests,
    setSentPartyRequests,
  ]);

  useEffect(() => {
    if (partySocket && partySocket.readyState === 1 && partyMembers.length) {
      const hostExists = !!partyMembers.find((m) => m.code === partyCode);
      console.log("PARTYMembers", hostExists, partyCode, partyMembers);
      if (!hostExists) {
        addNotification("Party Dissolved.", "Host left the party.");
        setPartyCode(profile.code);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyMembers, partyCode, setPartyCode, addNotification, partySocket]);

  const connectToSocket = (url, token, members) => {
    if (partySocket) {
      console.log("[PARTY_SOCKET] closing");
      partySocket.close();
    }
    setPartySocketURL(url);
    setPartyToken(token);
    setPartyMembers(members);
  };

  const leaveParty = useCallback(() => {
    deleteCookie("partyCode");
    setPartyCode(profile.code);
  }, [profile.code, setPartyCode]);

  const popMemberRequest = useCallback((code) => {
    setMemberRequests((m) => m.filter((_m) => _m.from !== code));
  }, []);
  const popPartyRequest = useCallback((code) => {
    setPartyRequests((m) => m.filter((_m) => _m.from !== code));
  }, []);

  const handleKick = useCallback(
    (code) => {
      sendPartyMessage({ action: "member_kick", payload: { code } });
    },
    [sendPartyMessage]
  );

  return useMemo(
    () => (
      <>
        <Portal>
          <RTCProvider>
            {window.innerWidth > 640 && (
              <Requests
                memberRequests={memberRequests}
                partyRequests={partyRequests}
                popMemberRequest={popMemberRequest}
                popPartyRequest={popPartyRequest}
                setPartyCode={setPartyCode}
                setPartyMembers={setPartyMembers}
                sendSocketMessage={sendSocketMessage}
                partyCode={partyCode}
              />
            )}
            <aside className="fixed bottom-0 right-0 mr-2 mb-1 sm:mr-4 sm:mb-4  w-content h-content z-70 items-center portrait:flex-col portrait:items-end flex">
              {window.innerWidth > 640 && (
                <Party
                  members={partyMembers}
                  isPartyHost={isPartyHost}
                  partyCode={partyCode}
                  leaveParty={leaveParty}
                  handleKick={handleKick}
                  partyMessage={partyMessage}
                  sendPartyMessage={sendPartyMessage}
                  partyTalking={partyTalking}
                  setPartyTalking={setPartyTalking}
                />
              )}
              <PortalMain
                isPartyHost={isPartyHost}
                sendPartyMessage={sendPartyMessage}
                membersCount={partyMembers ? partyMembers.length : 0}
              />
            </aside>
            {travelDetails && (
              <TravelModal
                travelDetails={travelDetails}
                partyMembers={partyMembers}
                partyCode={partyCode}
                close={setTravelDetails.bind(null, null)}
                sendPartyMessage={sendPartyMessage}
                isPartyHost={isPartyHost}
              />
            )}
            <DestinationSuggestions suggestionsList={destinationSuggestions} />
          </RTCProvider>
        </Portal>
      </>
    ),
    [
      destinationSuggestions,
      handleKick,
      isPartyHost,
      leaveParty,
      memberRequests,
      partyCode,
      partyMembers,
      partyMessage,
      partyRequests,
      partyTalking,
      popMemberRequest,
      popPartyRequest,
      sendPartyMessage,
      sendSocketMessage,
      setPartyCode,
      setPartyMembers,
      setPartyTalking,
      travelDetails,
    ]
  );
};

export default TravelPortal;
