import { useState, useEffect, useContext, useCallback } from "react";
// import { useResponseFlash } from "../apps/Zo/components/hooks";
import ProfileContext from "../contexts/profile";

const SOCKET_URL = `wss://socket.${process.env.REACT_APP_API_DOMAIN}/`;

const useSocket = (name) => {
  const { profile } = useContext(ProfileContext);
  const [socketUrl, setSocketUrl] = useState(null);
  const [socket, setSocket] = useState(null);
  const [token, setToken] = useState(null);
  const [timeoutSeconds, setTimeoutSeconds] = useState(0);
  const [connecting, setConnecting] = useState(null);
  const [members, setMembers] = useState([]);
  const [message, setMessage] = useState(null);

  const handleMessage = (_message) => {
    if (_message) {
      switch (_message.action) {
        case "joined":
          setMembers((_members) => {
            if (
              _members.findIndex(
                (_member) => _member.code === _message.payload.code
              ) === -1
            ) {
              const member = _message.payload;
              return [..._members, member];
            } else {
              return _members;
            }
          });
          break;
        case "left":
          setMembers((_members) =>
            _members.filter((_member) => _member.code !== _message.payload.code)
          );
          break;

        default:
          break;
      }
    }
    setMessage(_message);
  };

  useEffect(() => {
    if (socketUrl && token) {
      if (socket === null) {
        console.log(`[${name}] Attempting Connection in`, timeoutSeconds);
        setTimeout(() => {
          console.log(`[${name}] Creating Connection...`);
          setConnecting(true);
          const _socket = new WebSocket(SOCKET_URL + socketUrl + "?" + token);
          _socket.onmessage = function (e) {
            const data = JSON.parse(e.data);
            if (Array.isArray(data)) {
              data.forEach((d, i) => {
                setTimeout(() => {
                  handleMessage(d);
                }, i * 500);
                console.log(`[${name}] Message received.`, d);
              });
            } else {
              handleMessage(data);
              console.log(`[${name}] Message received.`, data);
            }
          };
          _socket.onclose = (e) => {
            console.log(`[${name}] Socket closed`);
            setSocket(null);
          };
          _socket.onerror = function (e) {
            console.log(`[${name}] Socket error`, e);
            setSocket(null);
          };
          _socket.onopen = (e) => {
            setConnecting(false);
            setTimeoutSeconds(0);
            console.log(`[${name}] Connection established.`);
          };
          setSocket(_socket);
        }, timeoutSeconds);
        setTimeoutSeconds((t) => Math.min(10000, t ? t * 2 : 2000));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketUrl, socket, token]);

  const sendSocketMessage = useCallback(
    (message) => {
      if (socket && profile) {
        socket.send(JSON.stringify({ ...message, sender: profile.code }));
      } else {
        console.log(`[${name}] Message not sent`, message, socket);
      }
    },
    [name, profile, socket]
  );

  return {
    setSocketUrl,
    socket,
    members,
    socketUrl,
    setMembers,
    connecting,
    sendSocketMessage,
    message,
    token,
    setToken,
  };
};

export default useSocket;
