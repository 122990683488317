import bg from "./../../../assets/bg.png";

const page = {
  layout: {
    header: {
      title: "",
      stats: [
        {
          type: "about",
        },
        {
          type: "calendar",
          disabled: true,
        },
        [
          {
            type: "rating",
          },
          {
            type: "economy",
            locked: true,
          },
          {
            type: "live",
          },
          {
            type: "leaderboard",
          },
        ],
      ],
    },
  },
  style: {
    backgroundPattern: bg,
    accentColor: "blue-500",
  },
};

export default page;
