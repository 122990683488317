import React, { useState } from "react";

const SubSection = ({ children, title, expand }) => {
  const [childrenVisible, setChildrenVisible] = useState(
    expand !== undefined ? expand : true
  );

  return (
    <section className="mb-4 last:mb-0 border border-gray-300 rounded-lg overflow-hidden w-full">
      <div
        className="p-4 font-lg font-medium cursor-pointer flex items-center justify-between"
        onClick={setChildrenVisible.bind(null, (v) => !v)}
      >
        {title}
        <button className="hover:underline font-medium text-gray-700 text-sm focus:outline-none">
          {childrenVisible ? "Collapse" : "Expand"}
        </button>
      </div>
      <div
        className={`bg-gray-100 ease-in-out transition-all duration-300 ${
          childrenVisible ? "h-auto border-t border-gray-300" : "h-0"
        }`}
      >
        <div className="p-4">{children}</div>
      </div>
    </section>
  );
};

export default SubSection;
