import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import ZoHeaderStat from "./../../../../../ui/ZoHeaderStat";
import calendarIcon from "./../../../../../../assets/calendar.svg";
import useLocationActive from "../../hooks/useLocationActive";

const Calendar = (props) => {
  const isActive = useLocationActive("/timeline");
  const history = useHistory();

  return (
    <ZoHeaderStat
      className="zo-head_timeline whitespace-no-wrap w-auto"
      active={isActive}
      icon={calendarIcon}
      title={"Today"}
      onClick={
        props.disabled
          ? null
          : () => {
              history.push("/timeline");
            }
      }
    >
    <span className='inline-block ml-2' dangerouslySetInnerHTML={{ __html: moment().format("dddd Do MMM").replace(/(\d)(st|nd|rd|th)/g, "$1<sup>$2</sup>" ) }}></span>

      
    </ZoHeaderStat>
  );
};

export default Calendar;
