import React, { useState } from "react";
import EmojiSheet from "../../bottomsheets/EmojiSheet";
// import { useRoom } from "../../../contexts";
import Flex from "../../structure/Flex";
import TextToggle from "../../ui/TextToggle";
import Widget from "../../ui/Widget";

interface ChatSettingsProps {}

const options = [
  { id: true, name: "Enable" },
  { id: false, name: "Disable" },
];

const ChatSettings: React.FC<ChatSettingsProps> = () => {
  const [isEmojiSheetVisible, setEmojiSheetVisibility] =
    useState<boolean>(false);

  const [selectedEmojiIndex, setSelectedEmojiIndex] = useState<number>(0);

  const toggleEmojiSheet = () => {
    setEmojiSheetVisibility((v) => !v);
  };

  const changeEmoji = (index: number) => {
    setSelectedEmojiIndex(index);
    toggleEmojiSheet();
  };

  return (
    <Widget title="Chat Settings" id="chat" hostOnly>
      {({ widget, updateWidget }) => (
        <Flex col className="p-2">
          <Flex items="start">
            <span className="font-bold pt-1 text-xs mb-1 flex-1">
              Chat visible
            </span>
            <TextToggle
              options={options}
              value={widget.data.chatVisible}
              onChange={updateWidget.bind(null, "chatVisible")}
            />
          </Flex>
          {widget.data.chatVisible && (
            <>
              <Flex items="start" className="mt-2">
                <span className="font-bold pt-1 text-xs mb-1 flex-1">
                  Notification sounds
                </span>
                <TextToggle
                  options={options}
                  value={widget.data.notificationsSounds}
                  onChange={updateWidget.bind(null, "notificationsSounds")}
                />
              </Flex>
              <Flex items="start" className="mt-2">
                <span className="font-bold pt-1 text-xs mb-1 flex-1">
                  Left/joined messages
                </span>
                <TextToggle
                  options={options}
                  value={widget.data.infoMessagesVisible}
                  onChange={updateWidget.bind(null, "infoMessagesVisible")}
                />
              </Flex>
              <Flex items="start" className="mt-2">
                <span className="font-bold pt-1 text-xs mb-1 flex-1">
                  Emojis
                </span>
                <TextToggle
                  options={options}
                  value={widget.data.emojiVisible}
                  onChange={updateWidget.bind(null, "emojiVisible")}
                />
              </Flex>
              {widget.data.emojiVisible && (
                <Flex className="mt-2" items="center" justify="between">
                  {Array.from({ length: 6 }).map((i, index) => (
                    <button
                      key={index}
                      className="text-xl focus:outline-none w-10 h-10 flex items-center justify-center rounded-full bg-white border border-gray-300 hover:bg-gray-300"
                      onClick={changeEmoji.bind(null, index)}
                    >
                      {widget.data.emojis[index] || "+"}
                    </button>
                  ))}
                </Flex>
              )}
            </>
          )}
          <EmojiSheet
            open={isEmojiSheetVisible}
            onDismiss={toggleEmojiSheet}
            emojis={widget.data.emojis}
            selected={selectedEmojiIndex}
            onChange={updateWidget.bind(null, "emojis")}
          />
        </Flex>
      )}
    </Widget>
  );
};

export default ChatSettings;
