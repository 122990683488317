import React, { useEffect, useState } from "react";
import { useRTC } from "../../../contexts";
import Flex from "../../structure/Flex";
import BottomSheet from "../../ui/BottomSheet";

interface RTCSettingsSheetProps {
  open: boolean;
  onDismiss: () => void;
}

const QUALITY_OPTIONS = [
  { id: 0, value: "Low" },
  { id: 1, value: "Medium" },
  { id: 2, value: "High" },
];

const RTCSettingsSheet: React.FC<RTCSettingsSheetProps> = ({
  open,
  onDismiss,
}) => {
  const [devices, setDevices] = useState<any[]>([]);
  const [outputDevice, setOutputDevice] = useState<string>("default");
  const { inputDeviceId, setInputDeviceId } = useRTC();

  useEffect(() => {
    if (navigator.mediaDevices && !!navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices
        .enumerateDevices()
        .then(function (devices) {
          console.log("DDSS", devices);
          setDevices(devices);
        })
        .catch(function (err) {
          console.log(err.name + ": " + err.message);
        });
      const localQuality = +(localStorage.getItem("zo_audio_quality") || "");
      if ([0, 1, 2].indexOf(localQuality) === -1) {
      }
    }
  }, [open]);

  useEffect(() => {
    if (outputDevice) {
      const rtcAudioElement = document.querySelector("audio#RTC_ROOM");
      if (rtcAudioElement) {
        const audio: any = rtcAudioElement;
        audio.setSinkId(outputDevice);
      }
    }
  }, [outputDevice]);

  const handleOutputChange: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    setOutputDevice(e.target.value);
  };

  const handleInputChange: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    setInputDeviceId(e.target.value);
  };

  return (
    <BottomSheet
      open={open}
      snapPoints={() => [336]}
      onDismiss={onDismiss}
      title="Zo Audio Settings"
    >
      <Flex col className="p-4 flex-1 h-full relative">
        <Flex col>
          <span className="font-semibold">Audio input device</span>
          <select
            className="bg-zo-off-white p-2 rounded-lg mt-1"
            value={inputDeviceId}
            onChange={handleInputChange}
          >
            {devices
              .filter((f) => f.kind === "audioinput")
              .map((d) => (
                <option key={d.deviceId} value={d.deviceId}>
                  {d.label}
                </option>
              ))}
          </select>
        </Flex>
        <Flex col className="mt-4">
          <span className="font-semibold">Audio output device</span>
          <select
            className="bg-zo-off-white p-2 rounded-lg mt-1"
            value={outputDevice}
            onChange={handleOutputChange}
          >
            {devices
              .filter((f) => f.kind === "audiooutput")
              .map((d) => (
                <option key={d.deviceId} value={d.deviceId}>
                  {d.label}
                </option>
              ))}
          </select>
        </Flex>
        <Flex col className="mt-4">
          <span className="font-semibold">Audio Quality</span>
          <select className="bg-zo-off-white p-2 rounded-lg mt-1">
            {QUALITY_OPTIONS.map((q) => (
              <option key={q.id} value={q.id}>
                {q.value}
              </option>
            ))}
          </select>
        </Flex>
      </Flex>
    </BottomSheet>
  );
};

export default RTCSettingsSheet;
