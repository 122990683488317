import React from "react";

import SubSection from "../../components/SubSection";
import Section from "../../components/Section";
import PassportField from "./components/PassportField";
import NameField from "./components/NameField";
import NicknameField from "./components/NicknameField";
import EmailField from "./components/EmailField";
import MobileField from "./components/MobileField";
import DOBField from "./components/DOBField";
import GenderField from "./components/GenderField";
import RelationshipField from "./components/RelationshipField";
import CurrentLocationField from "./components/CurrentLocationField";
import HomeTownField from "./components/HomeTownField";
import AddressField from "./components/AddressField";
import Essential from "./components/Essential_Id";
import BioField from "./components/BioField";

const GeneralSettings = ({
  countriesList,
  emailFields,
  setEmailFields,
  mobileFields,
  setMobileFields,
  loadingContact,
}) => {
  return (
    <Section title="General">
      <SubSection title="Account">
        <PassportField />
        <NameField />
        <NicknameField />
        <BioField />
        <EmailField
          loadingContact={loadingContact}
          emailFields={emailFields}
          setEmailFields={setEmailFields}
        />
        <MobileField
          loadingContact={loadingContact}
          mobileFields={mobileFields}
          setMobileFields={setMobileFields}
        />
        <DOBField />
        <GenderField />
        <RelationshipField />
      </SubSection>
      <SubSection title="Location">
        <CurrentLocationField countriesList={countriesList} />
        <HomeTownField countriesList={countriesList} />
        <AddressField />
      </SubSection>
      <SubSection title="KYC">
        <SubSection
          title="ID & Address Proof"
          visibleArea="Upload your documents"
          expand={false}
        >
          <Essential />
        </SubSection>
      </SubSection>
    </Section>
  );
};

export default GeneralSettings;
