import React, { useState, useContext } from "react";

import Widget from "../../ui/Widget";
import AuthContext from "../../../../../contexts/auth";
import JoinRoom from "./modals/JoinRoom";
import HostRoom from "./modals/HostRoom";
import ProfileContext from "../../../../../contexts/profile";
import ReferralBlocker from "../../../../CommonRoom/components/ReferralBlocker";

const MafiaActions = (props) => {
  const [joinRoomVisibility, setJoinRoomVisibility] = useState(false);
  const [hostRoomVisibility, setHostRoomVisibility] = useState(false);
  const [referralBlockerVisibility, setReferralBlockerVisibility] = useState(
    false
  );
  const { isLoggedIn, showAuthModal } = useContext(AuthContext);
  const { profile } = useContext(ProfileContext);

  const toggleJoinRoomVisibilty = () => {
    setJoinRoomVisibility((v) => !v);
  };
  const toggleHostRoomVisibilty = () => {
    if (isLoggedIn) {
      if (profile && profile.status !== "unfriendly") {
        setHostRoomVisibility((v) => !v);
      } else {
        setReferralBlockerVisibility(true);
      }
    } else {
      showAuthModal();
    }
  };

  return (
    <Widget
      className="flex portrait:flex-wrap items-center justify-evenly overflow-visible"
      {...props}
    >
      <button
        className="zo-join-room text-xl portrait:text-base py-3 px-6 portrait:mt-2 rounded-lg shadow-md font-semibold uppercase tracking-normal bg-mafia-primary text-white border-t-2 border-mafia-secondary hover:shadow-lg"
        onClick={toggleJoinRoomVisibilty}
      >
        Join via code
      </button>
      <button
        className="zo-host-room text-xl portrait:text-base py-3 px-6 portrait:mt-2 rounded-lg shadow-md font-semibold uppercase tracking-normal bg-mafia-primary text-white border-t-2 border-mafia-secondary hover:shadow-lg"
        onClick={toggleHostRoomVisibilty}
      >
        Host a room
      </button>
      <JoinRoom visible={joinRoomVisibility} close={toggleJoinRoomVisibilty} />
      <HostRoom visible={hostRoomVisibility} close={toggleHostRoomVisibilty} />
      {referralBlockerVisibility && (
        <ReferralBlocker
          customClose={setReferralBlockerVisibility.bind(null, false)}
        />
      )}
    </Widget>
  );
};

export default MafiaActions;
