import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import humanize from "humanize-number";

import ZoHeaderStat from "./../../../../../ui/ZoHeaderStat";
import useLocationActive from "../../hooks/useLocationActive";
// import AuthContext from "../../../../../contexts/auth";
import { useSeed } from "../../../contexts/seed";
import useApi from "../../../../../hooks/useApi";

const Population = () => {
  const history = useHistory();
  const isActive = useLocationActive("/population");
  const api = useApi("zo world", false);
  const { style } = useSeed();

  const [populationMetrics, setPopulationMetrics] = useState(null);

  useEffect(() => {
    if (api) {
      api
        .get(`/zoworld/api/v1/population/metrics/`)
        .then((res) => {
          setPopulationMetrics(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [api]);

  return (
    <ZoHeaderStat
      className="zo-head_population"
      active={isActive}
      icon={
        <svg
          className={`fill-current text-${style.accentColor || "orange"}`}
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V18c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05.02.01.03.03.04.04 1.14.83 1.93 1.94 1.93 3.41V18c0 .35-.07.69-.18 1H22c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5z" />
        </svg>
      }
      title="Population"
      onClick={() => {
        history.push("/population");
      }}
      value={
        populationMetrics ? humanize(populationMetrics.total_count) : "..."
      }
    />
  );
};

export default Population;
