import React, { useState, useRef } from "react";
import { createHash } from "crypto";

import footer3 from "./../../../../assets/footer-3.svg";
import Modal from "../../../ui/Modal";

const getSHA1 = function () {
  return createHash("sha1")
    .update(
      JSON.stringify({
        timestamp: Date.now(),
        rand: Math.random() * 10000,
      })
    )
    .digest("hex")
    .substr(0, 10);
};

function setCookie(cname, cvalue) {
  const d = new Date();
  d.setTime(d.getTime() + 20 * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie =
    cname +
    "=" +
    cvalue +
    ";" +
    expires +
    `;path=/;domain=.${process.env.REACT_APP_ROOT_DOMAIN}`;
}

const createNewSession = () => {
  const sessionId = getSHA1();
  setCookie("sessionId", sessionId);
  return sessionId;
};

const WelcomeModal = () => {
  const [redirecting, setRedirecting] = useState(false);

  const formRef = useRef();

  const redirectSignup = () => {
    setRedirecting(true);
    setTimeout(() => {
      formRef.current.submit();
    }, 3000);
  };

  return (
    <Modal
      close={() => {}}
      visible
      disableCloseOutside
      blurOuter
      hideCloseButton
    >
      <div className="relative border-orange border-t-4 bg-white p-6 pb-0 rounded-lg shadow-2xl h-full w-144 portrait:w-full flex flex-col items-center">
        <header className="font-black text-3xl fadeInUp text-center w-full portrait:px-6">
          Welcome to{" "}
          <span className="text-orange portrait:block portrait:mb-4">
            Zo World
          </span>
        </header>
        <section className="w-full mb-8 landscape:mt-4 flex-col flex items-center">
          <div className="font-medium text-xl mb-8 fadeInUp animation-delay-300 text-center">
            A Digital-First Nation built for socialising and more!
          </div>
          <div className="w-full flex items-center flex-col">
            <button
              className="bg-orange px-6 py-3 rounded-lg shadow-md hover:shadow-lg focus:outline-none font-medium text-white fadeInUp animation-delay-500"
              onClick={redirectSignup}
            >
              Check in
            </button>
          </div>
        </section>
        <footer
          className="bg-contain bg-bottom bg-no-repeat h-48 flex-shrink-0 w-full fadeInUp flex-grow"
          style={{ backgroundImage: `url(${footer3})` }}
        ></footer>
        {redirecting && (
          <section className="bg-blur flex items-center justify-center w-full h-full absolute cursor-wait">
            <div className="py-6 px-12 fade-in -mt-8 rounded-lg shadow-xl text-orange bg-white text-center font-medium">
              Hold on, we are taking you to
              <br />
              the right corner of the world!
            </div>
          </section>
        )}
        <form
          className="hidden"
          ref={formRef}
          action={`https://user.${process.env.REACT_APP_ROOT_DOMAIN}/sso`}
          method="POST"
        >
          <input
            type="text"
            value="Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU"
            name="client_app_id"
            onChange={() => {}}
            hidden
          />
          <input
            type="text"
            value={createNewSession()}
            onChange={() => {}}
            name="client_session_id"
            hidden
          />
          <input
            type="text"
            value={window.location.origin + "/auth/callback"}
            name="callback_url"
            onChange={() => {}}
            hidden
          />
          <input
            type="text"
            value={window.location.href}
            name="redirect_url"
            onChange={() => {}}
            hidden
          />
        </form>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
