import React from "react";
import Avatar from "../../../../../../../ui/Avatar";
import zobu from "./../../../../../../../../assets/zobu.svg";
import { bg, bn, levels } from "../../../../../../../../utils/experiences";
const Individuals = ({ leaderboardData, className, alt }) => {
  return (
    <div className={className || ""}>
      <div className="landscape:overflow-y-scroll h-full p-0">
        {leaderboardData ? (
          leaderboardData.map((profile, index) => {
            console.log(profile.level % 5);
            return (
              <>
                <div
                  className="p-4 py-2 m-2 bg-white rounded-lg shadow-md"
                  style={{
                    backgroundImage: `url(${bn[(profile.level % 5) + 1]})`,
                    backgroundRpeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  onClick={() => window.open(profile.lobby_url)}
                >
                  <div className="flex text-white items-center">
                    <div className="relative flex mt-4 justify-center mr-4">
                      <div className="py-2 px-4 text-m bg-yellow-500 text-black rounded-full">
                        {index + 1}
                      </div>
                    </div>

                    <div className="relative flex mt-4 justify-center">
                      <Avatar
                        className="h-16 -my-5 bg-white"
                        svg={profile.avatar_url || zobu}
                      />
                    </div>

                    <div className="ml-4 mt-3 md:mt-0 md:ml-6">
                      <div className="font-bold text-sm">
                        {profile.nickname || "Zobu"}
                      </div>
                      <p className="text-black font-bold bg-yellow-500 py-1 px-1 italic text-sm">
                        {levels[profile.level]
                          ? levels[profile.level]
                          : "Die Hard Zo V"}
                      </p>
                    </div>
                  </div>

                  <div className=" -mt-16 ml-8 items-center">
                    <div className="mr-6 relative flex justify-end">
                      <img
                        className="h-20 w-20"
                        src={bg[profile.level] ? bg[profile.level - 1] : bg[44]}
                        alt=""
                      />
                    </div>
                    <div className=" relative flex justify-end">
                      <p
                        className="text-white text-xs -mt-6"
                        style={{
                          transform: "rotate(270deg)",
                          transformOrigin: "top left",
                        }}
                      >
                        {profile.experience}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <h2 class="p-4  rounded-lg">{alt}</h2>
        )}
      </div>
    </div>
  );
};

export default Individuals;
