import React, { useContext, useState } from "react";

import ProfileContext from "../../../contexts/profile";
import useApi from "../../../hooks/useApi";
import { useInput, useResponseFlash } from "../../Zo/components/hooks";
import Modal from "../../../ui/Modal";

const NicknameModal = ({ next }) => {
  const { updateProfile, profile } = useContext(ProfileContext);
  const api = useApi("profile", true);
  const [loading, setLoading] = useState(false);
  const { value: nickname, bind: bindNickname } = useInput("");
  const { response, setResponse } = useResponseFlash();

  const handleSave = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (!/^[a-zA-Z0-9-]{6,32}$/.test(nickname)) {
        setResponse(
          "Only [A-Z, a-z, 0-9 or -] and 6 to 32 characters in length allowed"
        );
        return;
      }
      setLoading(true);
      try {
        const data = {
          nickname,
        };
        const response = await api.post(
          `/profile/api/v1/me/`,
          JSON.stringify(data)
        );
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          setResponse("Nickname updated!");
          updateProfile(data);
          if (next) {
            next();
          }
        } else {
          setResponse("Error in setting the nickname.");
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        console.log(error.response);
        if (error.response.status === 422) {
          setResponse(error.response.data.errors.nickname[0]);
          setLoading(false);
        } else {
          setResponse("Error in setting the nickname.");
          setLoading(false);
        }
      }
    }
  };

  return (
    <Modal
      close={() => {}}
      visible
      disableCloseOutside
      blurOuter
      hideCloseButton
    >
      <div className="relative border-orange border-t-4 bg-white rounded-lg shadow-2xl h-content w-content portrait:w-full">
        <form
          className="w-144 h-84 flex p-6 pb-0 flex-col items-center portrait:w-full portrait:h-content"
          onSubmit={handleSave}
        >
          <header className="font-black text-3xl fadeInUp animation-delay-100 text-center">
            Choose a cool <span className="text-orange">nickname</span>
          </header>
          <section className="mb-4 flex-col flex items-center">
            <div className="font-medium text text-center w-5/6 mt-4 mb-6 fadeInUp animation-delay-300">
              So, tell us{" "}
              <span className="text-orange">
                {profile && profile.first_name}
              </span>
              ! How do you want to be known in the <strong>Zo World</strong>?
            </div>
            <input
              type="text"
              placeholder="Enter your desired nickname"
              {...bindNickname}
              className="form-input w-72 fadeInUp animation-delay-500"
            />
            <div className="h-6 flex items-center justify-center font-semibold text-xs text-orange">
              {response}
            </div>
            <button className="bg-orange px-6 py-3 rounded-lg shadow-md hover:shadow-lg focus:outline-none font-medium text-white fadeInUp animation-delay-750">
              {loading ? "Setting" : "Choose"}
            </button>
          </section>
          <footer></footer>
        </form>
      </div>
    </Modal>
  );
};

export default NicknameModal;
