import React, { useEffect, useContext, useState } from "react";

import zoWidgets from "./data/zo/widgets";
import destinationWidgets from "./data/destination/widgets";
import zoSteps from "./data/zo/steps.json";
import mafiaWidgets from "./data/cult/mafia";
import Page from "../../components/ui/Page";
import AppContext from "./../../../../contexts/app";
import AuthContext from "../../../../contexts/auth";

const Home = () => {
  const { category, slug } = useContext(AppContext);
  const { isLoggedIn } = useContext(AuthContext);

  const [widgets, setWidgets] = useState([]);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (category === "destination") {
      setWidgets(destinationWidgets);
    } else if (category === "cult" && slug === "playmafia") {
      setWidgets(
        isLoggedIn != null
          ? mafiaWidgets.filter(
              (widget) => widget.requiresLogin.indexOf(isLoggedIn) !== -1
            )
          : null
      );
    } else {
      setWidgets(zoWidgets);
      setSteps(zoSteps);
    }
  }, [category, slug, isLoggedIn]);

  return (
    <>
      <Page widgets={widgets} steps={steps} />
    </>
  );
};

export default Home;
