import React, { useContext, useState } from "react";
import {
  useInput,
  useResponseFlash,
} from "../../../../../../../../../components/apps/Zo/components/hooks";
import ProfileContext from "../../../../../../../contexts/profile/ProfileContext";
import SeedContext from "../../../../../../../contexts/seed/SeedContext";
import useApi from "../../../../../../../hooks/useApi";
import SubSectionRow from "../../../components/SubSectionRow";

const RelationshipField = () => {
  const api = useApi("PROFILE_ME");
  const { seedData } = useContext(SeedContext);
  const { profile, updateProfile } = useContext(ProfileContext);
  const { value: relationshipStatus, bind: bindRelationshipStatus } = useInput(
    profile.relationship_status || 1
  );
  const { response, setResponse } = useResponseFlash();

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loading && api) {
      setLoading(true);
      try {
        const data = {
          relationship_status: +relationshipStatus,
        };
        console.log(data);

        const response = await api.post(``, JSON.stringify(data));
        if (response.status === 200) {
          updateProfile(data);
          setExpanded(false);
        } else {
          setResponse("Error in fields");
        }
      } catch (error) {
        console.log(error.response);
        setResponse("Error in setting the status.");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <SubSectionRow
      title="Relationship Status"
      visibleArea={
        profile.relationship_status != null &&
        seedData.relationship &&
        seedData.relationship.find(
          (r) => r[0] === profile.relationship_status
        ) &&
        seedData.relationship.find(
          (r) => r[0] === profile.relationship_status
        )[1]
      }
      placeholder="Single or double?"
      isExpanded={expanded}
      setExpanded={setExpanded}
    >
      <form className="flex flex-col w-full" onSubmit={handleSubmit}>
        <div className="flex w-full">
          <select
            className="w-1/3 form-select portrait:w-full"
            placeholder="Select your relationship status"
            {...bindRelationshipStatus}
          >
            {seedData.relationship &&
              seedData.relationship.map(([id, relation]) => (
                <option key={id} value={id}>
                  {relation}
                </option>
              ))}
          </select>
        </div>
        <div className="w-full h-6 flex items-center justify-start font-semibold text-xs text-orange">
          {response}
        </div>
        <div className="flex mb-4 w-full">
          <button
            className="bg-orange text-white cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            type="submit"
          >
            {loading ? "Saving" : "Save"}
          </button>
          <button
            className="bg-white text-orange cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            onClick={setExpanded.bind(null, false)}
          >
            Cancel
          </button>
        </div>
      </form>
    </SubSectionRow>
  );
};

export default RelationshipField;
