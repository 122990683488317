import React from "react";
import Avatar from "../../../../../../components/ui/Avatar";
import zobu from "./../../../../assets/zobu.svg";
import RTCAudioIcon from "./RTCAudioIcon";

const PlayerCard = ({
  className,
  nickname,
  avatar,
  ready,
  background,
  isHost,
  isUser,
  hideStatus,
  speaking,
  connected,
  muted,
}) => {
  return (
    <div
      className={`player-card inline-block px-2 pt-2 ${
        className ? className : ""
      }`}
    >
      <div
        className={`rounded-lg overflow-hidden bg-mafia-background shadow-sm w-full h-full bg-cover`}
        style={{
          backgroundImage: `url(${background})`,
        }}
      >
        <div className="flex items-center px-2 h-24">
          <div
            className={`relative w-20 h-20 rounded-full bg-gray-200 mr-4 border-4 ${
              speaking ? "mafia-player-speaking" : "border-mafia-primary"
            }`}
          >
            <Avatar svg={avatar || zobu} className="w-full h-full" />
            <RTCAudioIcon
              speaking={speaking}
              muted={muted}
              connected={connected}
            />
          </div>
          <div>
            <div className="font-semibold flex items-center text-lg text-mafia-secondary">
              {nickname}
              {isUser && (
                <div className="bg-mafia-primary text-white p-1 text-sm uppercase tracking-wide ml-2 rounded-lg">
                  You
                </div>
              )}
              {isHost && (
                <div className="bg-yellow-600 text-white p-1 text-sm uppercase tracking-wide ml-2 rounded-lg">
                  Host
                </div>
              )}
            </div>
            {hideStatus ? (
              <></>
            ) : ready ? (
              <div className="text-sm font-bold mt-1 inline-block">Ready</div>
            ) : (
              <div className="text-sm font-bold mt-1 inline-block">Waiting</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerCard;
