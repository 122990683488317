import React, { useEffect } from "react";

import Timer from "../../../Header/components/RoundTimer";
import nightDetective from "./../../../../assets/night_detective.svg";
import nightHealer from "./../../../../assets/night_healer.svg";
import nightMafia from "./../../../../assets/night_mafia.svg";
import nightVillager from "./../../../../assets/night_villager.svg";
import Modal from "../../../../../../ui/Modal";

const NightModal = ({ visible, close, role }) => {
  useEffect(() => {
    let mounted = true;
    if (visible) {
      setTimeout(() => {
        if (mounted) {
          close();
        }
      }, 5000);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal visible={visible} close={close}>
      {role === "detective" ? (
        <div
          className="bg-gray-200 relative z-40 rounded-lg overflow-hidden shadow-lg mb-10 bg-white bg-no-repeat bg-cover bg-center flex flex-col items-center"
          style={{
            width: "24rem",
            height: "27rem",
            backgroundImage: `url(${nightDetective})`,
          }}
        >
          <div className="bg-mafia-primary mt-4 w-3/4 mb-40 border-t-2 border-mafia-secondary p-2 rounded-lg shadow-lg flex flex-col items-center">
            <div className="font-bold uppercase tracking-normal text-mafia-secondary text-2xl">
              Night
            </div>
          </div>
          <Timer />
          <div className="text-white text-center mt-4 p-4">
            Time &amp; tide wait for none! The Village needs your scrutiny &amp;
            wisdom to survive. This is YOUR chance to take back what’s
            rightfully yours.
            <br />
            <span className="text-mafia-secondary font-bold text-2xl">
              Hunt down the Mafia!
            </span>
          </div>
        </div>
      ) : role === "healer" ? (
        <div
          className="bg-gray-200 relative z-40 rounded-lg overflow-hidden shadow-lg mb-10 bg-white bg-no-repeat bg-cover bg-center flex flex-col items-center"
          style={{
            width: "24rem",
            height: "27rem",
            backgroundImage: `url(${nightHealer})`,
          }}
        >
          <div className="bg-mafia-primary mt-4 w-3/4 mb-40 border-t-2 border-mafia-secondary p-2 rounded-lg shadow-lg flex flex-col items-center">
            <div className="font-bold uppercase tracking-normal text-mafia-secondary text-2xl">
              Night
            </div>
          </div>
          <Timer />
          <div className="text-white text-center mt-4 p-4">
            <span className="text-mafia-secondary font-bold text-2xl">
              The Sixth Sense
            </span>
            <br />
            In YOUR responsible hands lies the power to save an innocent life,
            only if your intuition nails it. So what’s it gonna be?
          </div>
        </div>
      ) : role.includes("mafia") ? (
        <div
          className="bg-gray-200 relative z-40 rounded-lg overflow-hidden shadow-lg mb-10 bg-white bg-no-repeat bg-cover bg-center flex flex-col items-center"
          style={{
            width: "24rem",
            height: "27rem",
            backgroundImage: `url(${nightMafia})`,
          }}
        >
          <div className="bg-mafia-primary mt-4 w-3/4 mb-40 border-t-2 border-mafia-secondary p-2 rounded-lg shadow-lg flex flex-col items-center">
            <div className="font-bold uppercase tracking-normal text-mafia-secondary text-2xl">
              Night
            </div>
          </div>
          <Timer />
          <div className="text-white text-center mt-4 p-4">
            <span className="text-mafia-secondary font-bold text-2xl">
              Mafia, blaze your guns!
            </span>
            <br />
            Ready to spread abomination? Try to achieve consensus, or a
            tie-stricken villager will be murdered!
          </div>
        </div>
      ) : role === "villager" ? (
        <div
          className="bg-gray-200 relative z-40 rounded-lg overflow-hidden shadow-lg mb-10 bg-white bg-no-repeat bg-cover bg-center flex flex-col items-center"
          style={{
            width: "24rem",
            height: "27rem",
            backgroundImage: `url(${nightVillager})`,
          }}
        >
          <div className="bg-mafia-primary mt-4 w-3/4 mb-40 border-t-2 border-mafia-secondary p-2 rounded-lg shadow-lg flex flex-col items-center">
            <div className="font-bold uppercase tracking-normal text-mafia-secondary text-2xl">
              Night
            </div>
          </div>
          <Timer />
          <div className="text-white text-center mt-4 p-4">
            Hola, Villager!
            <br />
            Remember, it’s with your wit &amp; fervour that the evil Mafias can
            be vanquished. So, are you ready to establish harmony once again?
          </div>
        </div>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default NightModal;
