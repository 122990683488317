import React from "react";

import Modal from "../../../ui/Modal";
import LogInButton from "./LogInButton";

const AuthModal = ({ visible, close }) => {
  return (
    <Modal
      visible={visible}
      close={close}
      modalClassName="bg-white border-t-4 border-orange -mt-24 p-2 flex-col items-center flex p-12"
    >
      <h1 className="font-bold text-2xl text-center">
        Sign in to <strong>Zo World</strong>
      </h1>
      <LogInButton className="bg-gray-200 px-8 py-4 rounded-lg shadow-lg mt-4 hover:shadow-xl" />
    </Modal>
  );
};

export default AuthModal;
