import React, { useState } from "react";
import { useProfile, useRoom } from "../../../../../../contexts";

interface ChatInputProps {}

const ChatInput: React.FC<ChatInputProps> = () => {
  const { sendMessage } = useRoom();
  const { profile } = useProfile();
  const [chatMessage, setChatMessage] = useState<string>("");

  const handleChatMessage: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setChatMessage(e.target.value);
  };

  const handleMessageSend: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (chatMessage && chatMessage.trim().length) {
      sendMessage({
        action: "member_chat",
        payload: {
          from: profile?.nickname,
          message: chatMessage.trim(),
          timestamp: +new Date(),
          avatar: profile?.avatar_url,
        },
      });
    }
    setChatMessage("");
  };

  return (
    <form className="w-full flex-shrink-0" onSubmit={handleMessageSend}>
      <input
        type="text"
        id="chat-input"
        className="w-full rounded-lg shadow-md p-4 focus:outline-none text-sm bg-white"
        placeholder="Enter your message here ..."
        autoComplete="off"
        value={chatMessage}
        onChange={handleChatMessage}
      />
    </form>
  );
};

export default ChatInput;
