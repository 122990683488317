import { useState } from "react";

const useInput = (initialValue, hasError) => {
  const [value, setValue] = useState(initialValue);

  const bind = {
    value,
    onChange: (event) => {
      setValue(event.target.value);
    },
  };

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind,
  };
};

export default useInput;
