import React, { useContext, useState } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";

import { AuthContext, ProfileContext } from "../../../../contexts/contexts";
import LogInOptions from "./components/LogInOptions";
import PlayerCard from "./components/PlayerCard";
import NicknameCard from "./components/NicknameCard";
import RoomContext from "../../contexts/room";
import GameContext from "../../contexts/game";
import allCharacters from "./../../assets/all-characters.svg";
import useApi from "../../../../hooks/useApi";
import ReferralBlocker from "../../../../apps/CommonRoom/components/ReferralBlocker";

const IntroScene = ({ join, spectate }) => {
  const history = useHistory();

  const { isLoggedIn } = useContext(AuthContext);
  const api = useApi("games", true);
  const { profile } = useContext(ProfileContext);
  const { room } = useContext(RoomContext);
  const { update } = useContext(GameContext);

  const [referralBlockerVisibility, setReferralBlockerVisibility] = useState(
    false
  );

  const handleJoin = async () => {
    const anon_user_token = localStorage.getItem("anon_user_token");
    const anon_user = localStorage.getItem("anon_user");
    const anon_token_expiry = localStorage.getItem("anon_token_expiry");
    const anon_nickname = localStorage.getItem("anon_nickname");
    if (isLoggedIn) {
      if (profile && profile.status !== "unfriendly") {
        try {
          const response = await api.post(
            `/games/api/v1/mafia/join/${room.code}/`,
            JSON.stringify({
              nickname: profile.nickname,
            })
          );
          console.log(response);
          if (response.status === 200) {
            const _player = { ...response.data.player };
            if (_player.id) {
              update(["PLAYER", _player]);
              join();
            } else {
              update(["PLAYER", null]);
            }
          }
        } catch (error) {
          console.log(error.response);
        }
      } else {
        setReferralBlockerVisibility(true);
      }
    } else if (
      anon_nickname &&
      anon_token_expiry &&
      anon_user &&
      anon_user_token &&
      JSON.parse(anon_user) &&
      JSON.parse(anon_user).app_id &&
      JSON.parse(anon_user).user_id
    ) {
      try {
        const response = await Axios.post(
          `https://socket.${process.env.REACT_APP_API_DOMAIN}/games/api/v1/mafia/join/${room.code}/`,
          JSON.stringify({ nickname: anon_nickname }),
          {
            headers: {
              Authorization: "Bearer " + anon_user_token,
              "Content-Type": "application/json",
              "Client-App-Id": JSON.parse(anon_user).app_id,
              "Client-User-Id": JSON.parse(anon_user).user_id,
            },
          }
        );
        console.log(response);
        if (response.status === 200) {
          const _player = { ...response.data.player };
          if (_player.id) {
            update(["PLAYER", _player]);
            join();
          } else {
            update(["PLAYER", null]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setReferralBlockerVisibility(true);
    }
  };

  const handleSpectate = () => {
    const anon_user_token = localStorage.getItem("anon_user_token");
    const anon_user = localStorage.getItem("anon_user");
    const anon_token_expiry = localStorage.getItem("anon_token_expiry");
    const anon_nickname = localStorage.getItem("anon_nickname");
    if (isLoggedIn) {
      update([
        "PLAYER",
        {
          auth_user_id: profile.code,
          status: "spectate",
          nickname: profile.nickname,
          role: "",
          roleCard: "",
          vote: null,
        },
      ]);
      if (room.status === "not_started") {
        join();
      } else {
        spectate();
      }
    } else if (
      anon_nickname &&
      anon_token_expiry &&
      anon_user &&
      anon_user_token &&
      JSON.parse(anon_user) &&
      JSON.parse(anon_user).app_id &&
      JSON.parse(anon_user).user_id
    ) {
      update([
        "PLAYER",
        {
          auth_user_id: JSON.parse(anon_user).id,
          status: "spectate",
          nickname: anon_nickname,
          role: "",
          roleCard: "",
          vote: null,
        },
      ]);
      if (room.status === "not_started") {
        join();
      } else {
        spectate();
      }
    }
  };

  const goBack = () => {
    history.push("/");
  };

  const getButton = () => {
    const anon_user_token = localStorage.getItem("anon_user_token");
    const anon_user = localStorage.getItem("anon_user");
    const anon_token_expiry = localStorage.getItem("anon_token_expiry");
    const anon_nickname = localStorage.getItem("anon_nickname");
    if (isLoggedIn) {
      if (profile) {
        if (profile.nickname) {
          return (
            <div>
              <button
                className="text-white bg-mafia-background border-t border-mafia-secondary px-4 py-3 leading-none rounded-lg font-semibold hover:shadow-lg"
                onClick={handleSpectate}
              >
                Spectate
              </button>
              {room.players.length < room.players_count && (
                <button
                  className="px-2 text-mafia-primary bg-mafia-secondary ml-4 px-4 py-3 leading-none rounded-lg hover:shadow-lg font-semibold ml-4"
                  onClick={handleJoin}
                >
                  Join the table
                </button>
              )}
            </div>
          );
        } else {
          return (
            <div className="bg-mafia-primary font-semibold text-white px-4 py-2 text-xl">
              Set Username to continue
            </div>
          );
        }
      } else {
        return (
          <div className="bg-gray-600 font-semibold text-white px-4 py-4 text-xl rounded-lg shadow-md cursor-not-allowed">
            Loading profile
          </div>
        );
      }
    } else if (
      anon_nickname &&
      anon_token_expiry &&
      anon_user &&
      anon_user_token
    ) {
      return (
        <div>
          <button
            className="text-white bg-mafia-background border-t border-mafia-secondary px-4 py-3 leading-none rounded-lg font-semibold hover:shadow-lg"
            onClick={handleSpectate}
          >
            Spectate
          </button>
          {room.players.length < room.players_count && (
            <button
              className="px-2 text-mafia-primary bg-mafia-secondary ml-4 px-4 py-3 leading-none rounded-lg hover:shadow-lg font-semibold ml-4"
              onClick={handleJoin}
            >
              Join the table
            </button>
          )}
        </div>
      );
    } else {
      return (
        <div className="bg-mafia-primary font-semibold text-white px-4 py-2 text-xl">
          Log in to join
        </div>
      );
    }
  };

  const checkLoginOptions = () => {
    const anon_user_token = localStorage.getItem("anon_user_token");
    const anon_user = localStorage.getItem("anon_user");
    const anon_token_expiry = localStorage.getItem("anon_token_expiry");
    const anon_nickname = localStorage.getItem("anon_nickname");
    if (isLoggedIn) {
      if (profile) {
        if (profile.nickname) {
          return (
            <PlayerCard
              className="w-3/4"
              nickname={profile.nickname}
              avatar={profile.avatar_url}
            />
          );
        } else {
          return <NicknameCard />;
        }
      } else {
        return (
          <div className="w-2/3 ">
            <div className="rounded-lg overflow-hidden shadow-sm w-full h-full bg-mafia-background flex items-center justify-center text-xl font-bold">
              Loading profile
            </div>
          </div>
        );
      }
    } else if (
      anon_nickname &&
      anon_token_expiry &&
      anon_user &&
      anon_user_token
    ) {
      return <PlayerCard className="w-1/3" nickname={anon_nickname} />;
    } else {
      return <LogInOptions />;
    }
  };

  return (
    <section className="w-screen h-screen fixed inset-0 z-60 flex items-center justify-center">
      <div
        className="border-t-4 border-mafia-secondary rounded-lg shadow-lg bg-mafia-primary overflow-hidden"
        style={{ width: "36rem", height: "max-content" }}
      >
        <div className="relative w-full font-bold m-4 mb-2 pb-2 text-center text-xl text-white uppercase tracking-normal border-b-2 border-mafia-background">
          <button
            className="absolute top-0 left-0 text-mafia-primary flex items-center rounded-lg shadow-lg bg-mafia-secondary"
            onClick={goBack}
          >
            <svg
              className="fill-current w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M14.71 6.71c-.39-.39-1.02-.39-1.41 0L8.71 11.3c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L10.83 12l3.88-3.88c.39-.39.38-1.03 0-1.41z" />
            </svg>
          </button>
          {room && room.name}
        </div>
        <div className="flex flex-col pt-4 pb-0 w-full items-center h-full justify-center flex-grow">
          <img className="h-56 rounded mb-6" src={allCharacters} alt="" />
          {checkLoginOptions()}
        </div>
        <div className="flex justify-between items-center h-16 pt-2 m-4 mt-2 border-t-2 border-mafia-background">
          <div className="flex items-center text-white text-lg px-1">
            <div>
              Players joined:{" "}
              <strong>
                {room && room.players.length}/{room && room.players_count}
              </strong>
            </div>
            <div className="ml-4">{/* Spectators: <strong>2</strong> */}</div>
          </div>
          {getButton()}
        </div>
      </div>
      {referralBlockerVisibility && (
        <ReferralBlocker
          customClose={setReferralBlockerVisibility.bind(null, false)}
        />
      )}
    </section>
  );
};

export default IntroScene;
