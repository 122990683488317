import React, { useState, useEffect } from "react";

import Portal from "../../../ui/Portal";

const DestinationSuggestions = ({ suggestionsList }) => {
  const [list, setList] = useState(suggestionsList);

  useEffect(() => {
    setList([...suggestionsList]);
  }, [suggestionsList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (suggestionsList.length && list.length) {
        deleteToast(suggestionsList[0].id);
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line
  }, [suggestionsList, list]);

  const deleteToast = (id) => {
    const listItemIndex = list.findIndex((e) => e.id === id);
    const suggestionsListItem = suggestionsList.findIndex((e) => e.id === id);
    list.splice(listItemIndex, 1);
    suggestionsList.splice(suggestionsListItem, 1);
    setList([...list]);
  };

  return (
    <Portal>
      <aside className="fixed z-70 bottom-0 right-0 mb-32 mr-4 fadeInRight animation-delay-4000">
        {list.map((suggestion, i) => (
          <div
            key={i}
            className="fadeInUp transition-all ease-in-out rounded-lg shadow-xl overflow-hidden mb-2 w-content bg-blur cursor-default p-2"
            onClick={() => deleteToast(suggestion.id)}
          >
            <p className="">
              <span className="font-semibold text-lg">{suggestion.from}</span>{" "}
              suggests{" "}
              <span className="text-xl font-bold uppercase tracking-wider text-orange">
                {suggestion.name}!
              </span>
            </p>
            {/* <p className="text-xs mt-1">{suggestion.from}</p> */}
          </div>
        ))}
      </aside>
    </Portal>
  );
};

export default DestinationSuggestions;
