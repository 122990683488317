import React from "react";
import moment from "moment";

import Avatar from "../../../../../../ui/Avatar";
import { renderLinks } from "../../../../../../../utils";
import withMemo from "../../../../../../../utils/withMemo";

const ChatMessage = withMemo(
  ({ type, nickname, timestamp, message, avatar, accentColor, collapsed }) => {
    return type === "received" ? (
      <li className="flex items-start my-1 w-full">
        {collapsed ? (
          <div className="w-8 h-8 mr-2 flex-shrink-0" />
        ) : (
          <Avatar
            className="w-8 h-8 bg-white rounded-full mr-2 flex-shrink-0"
            svg={avatar}
          />
        )}
        <div className="flex-grow overflow-hidden">
          <article
            className="w-content overflow-hidden bg-white rounded-lg p-2 shadow-md hover:bg-white"
            style={{ maxWidth: "80%" }}
          >
            {!collapsed && (
              <header className="text-sm">
                <span
                  className={`font-bold pr-4 ${accentColor || "text-gray-800"}`}
                >
                  {nickname}
                </span>
                <span className="font-medium text-gray-600 text-xxs">
                  {moment(timestamp).format("LT")}
                </span>
              </header>
            )}
            <section className="max-w-full break-words text-sm text-gray-800">
              <p
                dangerouslySetInnerHTML={{
                  __html: renderLinks(message, "text-orange hover:underline"),
                }}
              ></p>
            </section>
          </article>
        </div>
      </li>
    ) : type === "sent" ? (
      <li className="flex items-start my-1 w-full justify-end">
        <div className="flex-grow flex justify-end overflow-hidden">
          <article
            className="w-content overflow-hidden bg-white rounded-lg p-2 shadow-md hover:bg-white"
            style={{ maxWidth: "80%" }}
          >
            {!collapsed && (
              <header className="text-sm text-right">
                <span className="font-medium text-gray-600 text-xxs">
                  {moment(timestamp).format("LT")}
                </span>
                <span
                  className={`font-bold pl-4 ${accentColor || "text-gray-800"}`}
                >
                  Me
                </span>
              </header>
            )}
            <section className="max-w-full break-words text-sm text-gray-800">
              <p
                dangerouslySetInnerHTML={{
                  __html: renderLinks(message, "text-orange hover:underline"),
                }}
              ></p>
            </section>
          </article>
        </div>
        {collapsed ? (
          <div className="w-8 h-8 ml-2 flex-shrink-0" />
        ) : (
          <Avatar
            className="w-8 h-8 bg-white rounded-full ml-2 flex-shrink-0"
            svg={avatar}
          />
        )}
      </li>
    ) : type === "info" ? (
      <li className="flex items-start my-1 w-full justify-center">
        <article className="w-content max-w-full overflow-hidden zo-bg-black-transparent rounded-full p-1 shadow-md">
          <section className="max-w-full break-words text-sm text-white font-medium">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              className="w-6 h-6 fill-current inline pr-1 align-middle"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
            </svg>
            {message}
            <span className="font-medium text-xxs pl-2">
              {moment(timestamp).format("LT")}
            </span>
          </section>
        </article>
      </li>
    ) : null;
  },
  [
    "type",
    "nickname",
    "timestamp",
    "message",
    "avatar",
    "accentColor",
    "collapsed",
  ]
);

export default ChatMessage;
