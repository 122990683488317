import React, { useContext, useMemo } from "react";

import InGameContext from "../../../contexts/inGame";
import gameFlow from "./../../../../../../configs/mafia/gameFlow.json";
import RoomContext from "../../../contexts/room";

const RoundName = () => {
  const { room } = useContext(RoomContext);
  const { roomRound, roomStatus } = useContext(InGameContext);

  const roundsInfo = useMemo(() => gameFlow[room.game_flow], [room.game_flow]);

  return roomStatus === "ended_mafia" || roomStatus === "ended_village" ? (
    <section className="bg-mafia-primary font-bold tracking-wide uppercase inline-block text-xl p-4 border-t-4 border-mafia-secondary rounded-lg shadow-lg">
      Game Over
    </section>
  ) : roomRound ? (
    <section className="bg-mafia-primary font-bold tracking-wide uppercase inline-block text-xl p-4 border-t-4 border-mafia-secondary rounded-lg shadow-lg">
      {roundsInfo[roomRound].name}
    </section>
  ) : null;
};

export default RoundName;
