import React from "react";

import MemberRequest from "./components/MemberRequest";
import PartyRequest from "./components/PartyRequest";

const Requests = ({
  memberRequests,
  partyRequests,
  popPartyRequest,
  popMemberRequest,
  sendSocketMessage,
  setPartyCode,
  partyCode,
  setPartyMembers,
}) => {
  return (
    <aside className="fixed bottom-0 right-0 mr-4 mb-32 flex-col items-end justify-end flex">
      {memberRequests.length > 0 && (
        <div className="my-2">
          <div className="text-sm font-medium p-1 leading-none whitespace-no-wrap rounded-lg shadow-md zo-bg-white-transparent inline-block mb-1">
            Member Requests ({memberRequests.length})
          </div>
          {memberRequests[0] && (
            <MemberRequest
              from={memberRequests[0].from}
              pop={popMemberRequest}
              sendSocketMessage={sendSocketMessage}
              partyCode={partyCode}
            />
          )}
        </div>
      )}
      {partyRequests.length > 0 && (
        <div className="my-2">
          <div className="text-sm font-medium p-1 leading-none whitespace-no-wrap rounded-lg shadow-md zo-bg-white-transparent inline-block mb-1">
            Party Requests ({partyRequests.length})
          </div>
          {partyRequests[0] && (
            <PartyRequest
              from={partyRequests[0].from}
              partyCode={partyRequests[0].partyCode}
              pop={popPartyRequest}
              sendSocketMessage={sendSocketMessage}
              setPartyCode={setPartyCode}
              setPartyMembers={setPartyMembers}
            />
          )}
        </div>
      )}
    </aside>
  );
};

export default Requests;
