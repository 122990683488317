import React, { useContext, useState } from "react";
import ProfileContext from "../../../../contexts/profile";
import Modal from "../../../../ui/Modal";
import Toggle from "../../../../ui/Toggle";
import RoomContext from "../../contexts/room";

const AddWidgetsModal = ({ close, whiteboardText }) => {
  const { sendSocketMessage } = useContext(RoomContext);
  const { profile } = useContext(ProfileContext);

  const [chatSound, setChatSound] = useState(
    localStorage.getItem("disableChatSound") == null ? 1 : 0
  );
  const [chatLJMessages, setChatLJMessages] = useState(
    localStorage.getItem("disableChatLJMessages") == null ? 1 : 0
  );

  const onSoundChange = (value) => {
    setChatSound(value);
    if (value === 1) {
      localStorage.removeItem("disableChatSound");
    } else {
      localStorage.setItem("disableChatSound", "true");
    }
  };

  const onMessageChange = (value) => {
    setChatLJMessages(value);
    if (value === 1) {
      localStorage.removeItem("disableChatLJMessages");
    } else {
      localStorage.setItem("disableChatLJMessages", "true");
    }
  };

  const setWhiteboard = () => {
    sendSocketMessage({
      action: "new_whiteboard",
      payload: { from: profile.code },
      removable: true,
    });
    close();
  };
  return (
    <Modal close={close} backgroundTheme="light" visible>
      <div className="w-108 portrait:w-full rounded-lg bg-white border-t-4 border-orange flex flex-col items-center">
        <span className="font-bold text-xl p-4">Widgets</span>
        <div className="bg-gray-100 w-full flex flex-col p-4">
          <span className="uppercase font-semibold text-sm tracking-wide">
            Available Widgets
          </span>
          {whiteboardText ? (
            <span className="text-xs font-semibold mt-2 text-gray-700 p-4 w-full text-center">
              Stay tuned for more widgets
            </span>
          ) : (
            <button
              className="bg-white p-4 rounded-lg mt-2 shadow-md font-medium"
              onClick={setWhiteboard}
            >
              Notice Board
            </button>
          )}
        </div>
        <div className="w-full p-4 flex flex-col">
          <span className="uppercase font-semibold text-sm tracking-wide">
            Chat Widgets Settings
          </span>
          <div className="w-full flex items-center justify-between mt-2">
            <span className="text-sm">Notification sounds</span>
            <Toggle
              value={chatSound}
              onChange={onSoundChange}
              options={[
                { id: 1, value: "Enable" },
                { id: 0, value: "Disable" },
              ]}
            />
          </div>
          <div className="w-full flex items-center justify-between mt-2">
            <span className="text-sm">Members left/joined messages</span>
            <Toggle
              value={chatLJMessages}
              onChange={onMessageChange}
              options={[
                { id: 1, value: "Visible" },
                { id: 0, value: "Hidden" },
              ]}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddWidgetsModal;
