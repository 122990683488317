import React, { useEffect } from "react";
import Modal from "../../../../../../../../components/ui/Modal";
import vengefulKillMafia from "./../../../../assets/vengeful_kill_mafia.svg";
import vengefulKillVillager from "./../../../../assets/vengeful_kill_villager.svg";

const VengefulKillModal = ({ visible, close, player }) => {
  useEffect(() => {
    let mounted = true;
    if (visible) {
      setTimeout(() => {
        if (mounted) {
          close();
        }
      }, 5000);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal visible={visible} close={close}>
      <div
        className="bg-gray-200 relative z-40 rounded-lg overflow-hidden shadow-lg mb-10 bg-white bg-no-repeat bg-cover bg-center flex flex-col items-center justify-between"
        style={{
          width: "38.8rem",
          height: "34.1rem",
          backgroundImage: `url(${
            player && player.role.includes("mafia")
              ? vengefulKillMafia
              : vengefulKillVillager
          })`,
        }}
      >
        <div className="bg-mafia-primary mt-4 w-3/4 border-t-2 border-mafia-secondary p-4 rounded-lg shadow-lg flex flex-col items-center">
          <div className="font-bold uppercase tracking-normal text-mafia-secondary text-2xl">
            Vengeful Kill
          </div>
        </div>
        <div className="bg-mafia-primary mb-4 w-3/4 border-t-2 border-mafia-secondary p-4 rounded-lg shadow-lg flex flex-col items-center">
          {player && player.role.includes("mafia") ? (
            <div className="text-white text-lg text-center">
              The revenge took place against{" "}
              <span className="text-mafia-secondary font-bold text-2xl">
                {player.nickname}
              </span>
              , who{" "}
              <span className="text-mafia-secondary font-bold">
                was indeed a Mafia
              </span>
              .
            </div>
          ) : (
            <div className="text-white text-lg text-center">
              <span className="text-mafia-secondary font-bold text-2xl">
                {player.nickname}
              </span>{" "}
              soul curses from heaven who{" "}
              <span className="text-mafia-secondary font-bold">
                was not a Mafia
              </span>
              .
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default VengefulKillModal;
