import React, { useEffect, useState } from "react";

import audioAllow from "./../assets/audio-allow.svg";
import Portal from "../../../ui/Portal";

const PermissionStatus = ({ status, close }) => {
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    console.log("STATUS", status);
  }, [status]);

  useEffect(() => {
    if (closing) {
      setTimeout(close, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closing]);

  const handleClose = () => {
    setClosing(true);
  };

  return status === "asking" ? (
    <Portal>
      <section className="fixed top-0 left-0 z-100 bg-blur zo-bg-black-transparent w-screen h-screen flex flex-col items-center justify-center">
        <div className="w-120 portrait:w-full text-center h-content relative border-orange border-t-4 px-8 py-16 bg-white rounded-lg flex-col flex items-center shadow-xl">
          <div className="text-xl text-orange font-semibold">
            Grant us the permission to have a chat!
          </div>
          <span className="text-lg mt-2 text-gray-800 font-medium block text-center">
            Please help us with the microphone permission. It will enable us to
            have quick talk &amp; get you checked in. And don't worry, we won't
            spy on you.
          </span>
          <img
            className="absolute bottom-full left-0 h-56"
            style={{ marginBottom: "-4.2rem" }}
            src={audioAllow}
            alt=""
          />
        </div>
      </section>
    </Portal>
  ) : status === "denied" ? (
    <Portal>
      <section
        className={`${
          closing ? "fadeOutLeft" : "fadeInLeft animation-delay-1000"
        } fixed top-0 right-0 z-100 bg-blur w-content h-content rounded-lg m-4 flex flex-col shadow-2xl`}
      >
        <span className="text-orange font-semibold text-lg m-4 mb-1 block">
          You are missing on a lot of <strong>awesomeness!</strong>
        </span>
        <span className="text-gray-700 font-medium m-4 mt-0 block">
          Allow microphone permission for Zo World
        </span>
        <div className="flex items-center w-full rounded-b-lg overflow-hidden">
          <button
            className="text-white font-medium py-2 text-center bg-green-500 w-1/2"
            onClick={handleClose}
          >
            Ok, I will!
          </button>
          <button
            className="text-gray-700 font-medium py-2 text-center bg-gray-300 w-1/2"
            onClick={handleClose}
          >
            No, thanks!
          </button>
        </div>
      </section>
    </Portal>
  ) : null;
};

export default PermissionStatus;
