import React from "react";

import bg from "./assets/404-bg.svg";

const NotFound = () => {
  return (
    <div
      className="flex flex-col items-center justify-between w-screen h-screen bg-cover bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="text-center">
        <div className="font-bold text-6xl text-orange pt-4">4 (oh) 4</div>
        <div className="text-white font-medium text-xl">
          Oh, we don't think you should be on this page.
          <br />
          Go back, while you can.
        </div>
      </div>
      <button
        className="mb-32 portrait:mb-48 bg-orange text-white font-semibold rounded-lg shadow-xl text-lg portrait:text-2xl uppercase tracking-wide px-6 py-4"
        onClick={() => {
          window.history.back();
        }}
      >
        Go back NOW!
      </button>
    </div>
  );
};

export default NotFound;
