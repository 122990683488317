import React, { useEffect, useRef } from "react";

import closeIcon from "./../../../assets/close.svg";
import Portal from "../Portal";

const Modal = ({
  children,
  visible,
  close,
  backgroundTheme,
  modalClassName,
  modalWidth,
  modalHeight,
  hideCloseButton,
  disableCloseOutside = false,
  blurOuter,
}) => {
  const modalRef = useRef();

  useEffect(() => {
    if (visible && !disableCloseOutside) {
      document.addEventListener("click", checkClick);
    }
    return () => {
      document.removeEventListener("click", checkClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, disableCloseOutside]);

  const checkClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      close();
    }
  };

  return (
    <Portal>
      <section
        className={`w-screen h-screen fixed inset-0 z-60 flex items-center justify-center ${
          visible
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        } ${blurOuter && "bg-blur"}`}
        style={{
          backgroundColor:
            !blurOuter && backgroundTheme === "light"
              ? "rgba(255,255,255,0.8)"
              : "rgba(0,0,0,0.8)",
        }}
      >
        <div
          className={`relative z-40 rounded-lg overflow-hidden shadow-lg w-content portrait:w-full portrait:m-4 ${
            modalClassName ? modalClassName : ""
          }`}
          style={{ width: modalWidth, height: modalHeight || "max-content" }}
          ref={modalRef}
        >
          {children}
          {!hideCloseButton && (
            <button
              className="w-8 h-8 absolute top-0 right-0 m-4 portrait:m-6"
              onClick={close}
            >
              <img className="w-full h-full" src={closeIcon} alt="" />
            </button>
          )}
        </div>
      </section>
    </Portal>
  );
};

export default Modal;
