import { useMemo, useContext } from "react";
import axios, { AxiosInstance } from "axios";

import { Endpoint, getApiEndpoint } from "../utils/api";
import { AuthContext } from "../contexts/auth";

const useApi: (endpoint: Endpoint, route?: string) => AxiosInstance | null = (
  endpoint,
  route
) => {
  const { token, clientId, sessionId } = useContext(AuthContext);

  const axiosInstance = useMemo(() => {
    return token
      ? axios.create({
          baseURL: `${getApiEndpoint(endpoint)}${route || ""}`,
          headers: {
            "Content-Type": "application/json",
            "Client-App-Id": clientId,
            "Client-User-Id": sessionId,
            Authorization: `Bearer ${token}`,
          },
        })
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, endpoint, route]);

  return axiosInstance;
};

export default useApi;
