import React from "react";
import Portal from "../../../ui/Portal";

const DeviceCheck = () => {
  return (
    <Portal>
      <section className="fixed top-0 left-0 z-100 bg-blur zo-bg-black-transparent w-screen h-screen flex flex-col items-center justify-center"></section>
    </Portal>
  );
};

export default DeviceCheck;
