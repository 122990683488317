import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import ReportUserModal from "../../../../../commons/ReportAbuseModal";

import ProfileContext from "../../../../../contexts/profile";
import useResponseFlash from "../../../../../hooks/useResponseFlash";
import Avatar from "../../../../../ui/Avatar";
import RTCAudioIcon from "./RTCAudioIcon";

const Member = ({
  lastElement,
  checkHost,
  isHost,
  code,
  avatar,
  nickname,
  speaking,
  muted,
  bio,
  subdomain,
  isUser,
  connected,
  inSameParty,
  sendSocketMessage,
  partyCode,
  joinRequested,
  isPremium,
  inviteRequested,
}) => {
  const { profile } = useContext(ProfileContext);
  const modalRef = useRef();
  const { response: unmuteRequestSent, setResponse: sendUnmuteRequest } =
    useResponseFlash();

  const [isOptionsVisible, setOptionsVisible] = useState(false);

  const [reportAbuseVisible, setReportAbuseVisible] = useState(false);

  useEffect(() => {
    if (isOptionsVisible) {
      document.addEventListener("click", checkClick);
    }
    return () => {
      document.removeEventListener("click", checkClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOptionsVisible]);

  const checkClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setOptionsVisible(false);
    }
  };

  const toggleOptionsVisibility = () => {
    setOptionsVisible((v) => !v);
  };

  const handlePlayerMute = useCallback(() => {
    sendSocketMessage({ action: "member_mute", payload: { code, nickname } });
  }, [code, nickname, sendSocketMessage]);

  const handlePlayerKick = useCallback(() => {
    sendSocketMessage({ action: "member_kick", payload: { code, nickname } });
  }, [code, nickname, sendSocketMessage]);

  const handlePartyInviteRequest = useCallback(() => {
    sendSocketMessage({
      action: "party_request",
      payload: {
        id: +new Date() / 1000,
        from: { nickname: profile.nickname, partyCode },
        to: { nickname },
      },
    });
  }, [nickname, profile, sendSocketMessage, partyCode]);

  const handlePartyJoinRequest = useCallback(() => {
    sendSocketMessage({
      action: "member_request",
      payload: {
        id: +new Date() / 1000,
        from: { nickname: profile.nickname },
        to: { nickname },
      },
    });
  }, [nickname, profile, sendSocketMessage]);

  const requestPlayerUnmute = useCallback(() => {
    sendSocketMessage({
      action: "unmute_request",
      payload: {
        to: { nickname },
      },
    });
    sendUnmuteRequest("ss");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nickname, sendSocketMessage]);

  return useMemo(
    () => (
      <li
        className={`inline-flex flex-col items-center w-1/5 sm:w-1/6 h-18 my-3`}
      >
        <div
          className="flex flex-col items-center w-full h-full relative group w-16 mb-5 cursor-pointer"
          onClick={toggleOptionsVisibility}
        >
          <div
            className={`relative w-12 h-12 rounded-full border-2 
              ${
                muted
                  ? "bg-gray-300 border-gray-300"
                  : speaking
                  ? "border-orange bg-red-100 avatar-speaking"
                  : "bg-white"
              } group-hover:bg-red-200`}
          >
            <Avatar svg={avatar} className="w-full h-full" />
            {connected && <RTCAudioIcon speaking={speaking} muted={muted} />}
            {checkHost && (
              <div className="absolute top-0 left-0 group-a w-4 h-4 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  className="fill-current text-orange bg-white rounded-full w-4 h-4"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm3.23 15.39L12 15.45l-3.22 1.94c-.38.23-.85-.11-.75-.54l.85-3.66-2.83-2.45c-.33-.29-.15-.84.29-.88l3.74-.32 1.46-3.45c.17-.41.75-.41.92 0l1.46 3.44 3.74.32c.44.04.62.59.28.88l-2.83 2.45.85 3.67c.1.43-.36.77-.74.54z" />
                </svg>
                <span className="absolute pointer-events-none z-40 opacity-0 bg-gray-700 text-white whitespace-no-wrap font-medium px-2 py-1 leading-none rounded-full text-xs group-a-hover:opacity-100">
                  Host
                </span>
              </div>
            )}
            {inSameParty && (
              <div className="absolute top-0 right-0 group-a w-4 h-4 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  className="fill-current text-white bg-green-500 rounded-full w-4 h-4"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M10 9c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zM7 9.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm3 7c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm-3-3c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm3-6c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zM14 9c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-1.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm3 6c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-4c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm2-3.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-3.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z" />
                </svg>
                <span
                  className={`absolute pointer-events-none z-40 opacity-0 bg-gray-700 text-white whitespace-no-wrap font-medium px-2 py-1 leading-none rounded-full text-xs group-a-hover:opacity-100 ${
                    lastElement ? "right-full" : ""
                  }`}
                >
                  In the party
                </span>
              </div>
            )}
          </div>
          <div className="w-20 leading-none text-center group-hover:w-auto group-hover:bg-white group-hover:z-10 group-hover:border rounded-lg p-1 absolute bottom-0 truncate text-xs font-medium text-gray-800 mt-2 -mb-6">
            {nickname}
          </div>
        </div>
        {isOptionsVisible && (
          <div
            className="fixed top-0 rounded-lg overflow-hidden bg-gray-300 shadow-lg mb-2 z-50 pr-28 pl-4 pt-4 pb-4 h-content flex flex-col items-center justify-end text-gray-800"
            ref={modalRef}
            style={{
              minHeight: "18rem",
              left: window.innerHeight < window.innerWidth ? "24.5rem" : "5rem",
              top: window.innerHeight < window.innerWidth ? "1rem" : "5rem",
            }}
          >
            <Avatar
              className="h-84 -mr-6 -mb-16 absolute bottom-0 right-0"
              svg={avatar}
              full
            />
            <div className="font-bold text-gray-900">{nickname}</div>
            <div className="text-xs mb-4 font-semibold text-gray-700">
              {checkHost ? "Host" : isPremium ? "Member" : "Guest"}
            </div>
            {bio && (
              <div className="mb-4 text-sm font-medium italic text-center text-gray-800 w-36">
                {bio}
              </div>
            )}
            {isHost && (
              <div className="flex flex-col rounded-lg overflow-hidden border flex-shrink-0">
                {!checkHost && !isUser && (
                  <button
                    className="focus:outline-none bg-white border-b p-2 font-medium text-xs whitespace-no-wrap disabled:cursor-not-allowed hover:bg-orange hover:text-white"
                    onClick={handlePlayerKick}
                  >
                    Kick
                  </button>
                )}
                {muted ? (
                  unmuteRequestSent ? (
                    <div className="p-2 font-medium text-xs whitespace-no-wrap bg-gray-300 border-b">
                      Request Sent!
                    </div>
                  ) : (
                    <button
                      className="focus:outline-none bg-white p-2 font-medium text-xs whitespace-no-wrap disabled:cursor-not-allowed hover:bg-orange hover:text-white"
                      onClick={requestPlayerUnmute}
                    >
                      Request unmute
                    </button>
                  )
                ) : (
                  <button
                    className="focus:outline-none bg-white p-2 font-medium text-xs whitespace-no-wrap disabled:cursor-not-allowed hover:bg-orange hover:text-white"
                    onClick={handlePlayerMute}
                  >
                    Mute
                  </button>
                )}
              </div>
            )}
            {!isUser && !inSameParty && (
              <div className="hidden sm:flex flex-col rounded-lg overflow-hidden border flex-shrink-0 mt-4">
                {!joinRequested ? (
                  <button
                    className="focus:outline-none bg-white border-b p-2 font-medium text-xs whitespace-no-wrap disabled:cursor-not-allowed hover:bg-orange hover:text-white"
                    onClick={handlePartyJoinRequest}
                  >
                    Join Party
                  </button>
                ) : (
                  <div className="p-2 font-medium text-xs whitespace-no-wrap bg-gray-300 border-b">
                    Join Requested
                  </div>
                )}
                {!inviteRequested ? (
                  <button
                    className="focus:outline-none bg-white border-b p-2 font-medium text-xs whitespace-no-wrap disabled:cursor-not-allowed hover:bg-orange hover:text-white"
                    onClick={handlePartyInviteRequest}
                  >
                    Invite to Party
                  </button>
                ) : (
                  <div className="p-2 font-medium text-xs whitespace-no-wrap bg-gray-300">
                    Invitation sent
                  </div>
                )}
              </div>
            )}
            {isPremium && (
              <button
                className="rounded-lg flex-shrink-0 border mt-4 focus:outline-none bg-white border-b p-2 font-medium text-xs whitespace-no-wrap disabled:cursor-not-allowed hover:bg-orange hover:text-white"
                onClick={window.open.bind(
                  null,
                  `https://${subdomain}.${process.env.REACT_APP_ROOT_DOMAIN}`,
                  "_parent",
                  "",
                  false
                )}
              >
                Travel to room
              </button>
            )}
            <button
              className="focus:outline-none rounded-lg mt-4 text-orange font-medium text-xs whitespace-no-wrap disabled:cursor-not-allowed"
              onClick={setReportAbuseVisible.bind(null, true)}
            >
              Report User
            </button>
          </div>
        )}
        {reportAbuseVisible && (
          <ReportUserModal
            code={code}
            _nickname={nickname}
            close={setReportAbuseVisible.bind(null, false)}
          />
        )}
      </li>
    ),
    [
      avatar,
      checkHost,
      code,
      connected,
      bio,
      handlePartyInviteRequest,
      handlePartyJoinRequest,
      handlePlayerKick,
      handlePlayerMute,
      inSameParty,
      inviteRequested,
      isHost,
      isOptionsVisible,
      isPremium,
      isUser,
      joinRequested,
      lastElement,
      muted,
      nickname,
      reportAbuseVisible,
      requestPlayerUnmute,
      speaking,
      subdomain,
      unmuteRequestSent,
    ]
  );
};

export default Member;
