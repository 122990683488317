import React, { useContext, useState } from "react";

import SubSectionRow from "../../../components/SubSectionRow";
import ProfileContext from "../../../../../../../contexts/profile";
import {
  useInput,
  useResponseFlash,
} from "../../../../../../Zo/components/hooks";
import useApi from "../../../../../../../hooks/useApi";

const NameField = () => {
  const api = useApi("profile", true);
  const { profile, updateProfile } = useContext(ProfileContext);
  const { value: lobbyName, bind: bindLobbyName } = useInput(
    profile.lobby_name || ""
  );
  const { response, setResponse } = useResponseFlash();

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (lobbyName.length > 29) {
        setResponse("Should be <= 28 in length.");
        return;
      }
      setLoading(true);
      try {
        if (api) {
          const data = {
            lobby_name: lobbyName,
          };
          console.log(data);

          const response = await api.post(
            `/profile/api/v1/me/`,
            JSON.stringify(data)
          );
          if (response.status === 200) {
            updateProfile(data);
            setExpanded(false);
          } else {
            setResponse("Error in fields");
          }
        }
      } catch (error) {
        console.log(error.response);
        if (error.response.status === 422) {
          setResponse(error.response.data.errors.lobby_name[0]);
          setLoading(false);
        } else {
          setResponse("Error in setting the common room name.");
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <SubSectionRow
      title="Name"
      visibleArea={profile.lobby_name}
      placeholder="We are currently showing a default. Edit now!"
      isExpanded={expanded}
      setExpanded={setExpanded}
    >
      <form className="flex flex-col w-full" onSubmit={handleSubmit}>
        <div className="flex w-full">
          <input
            className="w-1/3 mr-2 form-input portrait:w-full portrait:mr-0"
            placeholder="Enter your Common Room Name"
            {...bindLobbyName}
          />
        </div>
        <div className="w-full h-6 flex items-center justify-start font-semibold text-xs text-orange">
          {response}
        </div>
        <div className="flex mb-4 w-full">
          <button
            className="bg-orange text-white cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            type="submit"
          >
            {loading ? "Saving" : "Save"}
          </button>
          <button
            className="bg-white text-orange cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            onClick={setExpanded.bind(null, false)}
          >
            Cancel
          </button>
        </div>
      </form>
    </SubSectionRow>
  );
};

export default NameField;
