import { createContext } from "react";

type ProfileContextInterface = {
  profile: UserProfile | null;
  activityHours: number;
  setProfile: (profile: UserProfile) => void;
  updateProfile: (updatedProfile: UserProfile) => void;
  updateProfileKey: ((key: string, value: any) => Promise<boolean>) | null;
};

const ProfileContext = createContext<ProfileContextInterface>({
  profile: null,
  activityHours: 0,
  setProfile: (profile: UserProfile) => {},
  updateProfile: (updatedProfile: UserProfile) => {},
  updateProfileKey: null,
});

export default ProfileContext;
