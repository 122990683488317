import React, { useContext, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import "./../index.css";
import ProfileContext from "../../../contexts/profile";
import useApi from "../../../hooks/useApi";
import { useResponseFlash } from "../../../apps/Zo/components/hooks";
import LobbyContext from "../../../apps/CommonRoom/contexts/lobby";

const CheckoutForm = ({ priceId, back, showPaymentForm }) => {
  const { profile } = useContext(ProfileContext);
  const { sendLobbyMessage } = useContext(LobbyContext);
  const api = useApi("payments", true);
  const stripe = useStripe();
  const elements = useElements();
  const { response, setResponse } = useResponseFlash();
  const [started, setStarted] = useState(false);

  function createPaymentMethod(cardElement, customerId, priceId) {
    return stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
      })
      .then((result) => {
        if (result.error) {
          console.log(result.error);
        } else {
          createSubscription({
            customerId: customerId,
            paymentMethodId: result.paymentMethod.id,
            priceId: priceId,
          });
        }
      });
  }

  function createSubscription({ customerId, paymentMethodId, priceId }) {
    return (
      api
        .post(
          "/subscription/api/v1/stripe/subscription/",
          JSON.stringify({
            customer_id: customerId,
            plan_id: priceId,
            payment_request_ref_id: paymentMethodId,
          })
        )
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the addional details we need.
        .then((result) => {
          console.log("Created subscription", {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscription: result.data.subscription,
          });
          return {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscription: result.data.subscription,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setResponse("Unable to process your payment.");
          console.log(error);
          setStarted(false);
        })
    );
  }

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    console.log("SUBSCRIPTION", "in handlePaymentThatRequiresCustomerAction");
    if (subscription && subscription.status === "active") {
      // Subscription is active, no customer actions required.
      console.log(
        "SUBSCRIPTION",
        "Subscription is active, no customer actions required."
      );
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          console.log("SUBSCRIPTION confirmCardPayment", result);
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else {
            console.log("SUBSCRIPTION result before succeeded check", result);
            if (result.paymentIntent.status === "succeeded") {
              // Show a success message to your customer.
              showSuccess();
              // There's a risk of the customer closing the window before the callback.
              // We recommend setting up webhook endpoints later in this guide.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setResponse("Unable to complete your payment");
        });
    } else {
      // No customer action needed.
      console.log("SUBSCRIPTION No customer action needed.");
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    console.log("SUBSCRIPTION in handleRequiresPaymentMethod");
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      console.log(
        "SUBSCRIPTION",
        "Subscription is active, no customer actions required."
      );
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        subscription.latest_invoice.payment_intent.status
      );
      console.log("Card declined");
      throw new Error("Your card was declined.");
    } else {
      console.log("SUBSCRIPTION in the last else");
      return { subscription, priceId, paymentMethodId };
    }
  }

  function onSubscriptionComplete(result) {
    // Payment was successful.
    console.log("SUBSCRIPTION COMPLETE", result, sendLobbyMessage);

    if (result.subscription.status === "active") {
      showSuccess();
    }
  }

  function showSuccess() {
    sendLobbyMessage({
      action: "new_premium",
      payload: { code: profile.code, nickname: profile.nickname },
    });
    showPaymentForm();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setStarted(true);
    const response = await api.post(
      "/subscription/api/v1/stripe/customer/",
      JSON.stringify({
        email: `${profile.code}@zo.xyz`,
        mobile_number: `${profile.mobile_country_code}${profile.mobile}`,
        name: `${profile.first_name}${
          profile.middle_name ? ` ${profile.middle_name}` : ""
        }${profile.last_name ? ` ${profile.last_name}` : ""}`,
      })
    );
    if (response.status === 200) {
      console.log(response.data);
      if (response.data.customer && response.data.customer.id) {
        const payload = await createPaymentMethod(
          elements.getElement(CardElement),
          response.data.customer.id,
          priceId
        );

        console.log(payload);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex items-center flex-col">
      <div className="font-medium mt-6 flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          className="fill-current mr-4 cursor-pointer"
          onClick={back}
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42-.39-.39-1.02-.39-1.41 0l-6.59 6.59c-.39.39-.39 1.02 0 1.41l6.59 6.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1z" />
        </svg>
        Enter your Card details to proceed
      </div>
      <CardElement
        onReady={() => {
          // console.log("CardElement [ready]");
        }}
        onChange={(event) => {
          // console.log("CardElement [change]", event);
        }}
        onBlur={() => {
          // console.log("CardElement [blur]");
        }}
        onFocus={() => {
          // console.log("CardElement [focus]");
        }}
      />
      <div className="h-6 flex items-center justify-center font-semibold text-xs text-orange">
        {response}
      </div>
      {started ? (
        <div className="font-medium px-3 py-2 mb-6 text-sm rounded-lg shadow-md text-orange">
          Processing ...
        </div>
      ) : (
        <button
          type="submit"
          className="bg-orange font-medium px-3 py-2 mb-6 text-sm rounded-lg shadow-md text-white"
          disabled={!stripe}
        >
          Pay
        </button>
      )}
    </form>
  );
};

export default CheckoutForm;
