import React, { useContext } from "react";
import useResponseFlash from "../../../hooks/useResponseFlash";

import Avatar from "../../../ui/Avatar";
import RoomContext from "../contexts/room";

const getSocialLink = (link, value) => {
  switch (link) {
    case 1:
      return value;
    case 2:
      return `https://www.twitter.com/${value}`;
    case 3:
      return `https://www.instagram.com/${value}`;
    case 4:
    case 5:
      return value;
    case 6:
      return `upi://pay?pa=${value}&tn=Gift from Zo`;
    default:
      return "";
  }
};

const copyReferralLink = (link) => {
  const textArea = document.createElement("textarea");
  textArea.value = link;
  textArea.style.position = "fixed";
  textArea.style.top = "-400vh";
  textArea.style.left = "0";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  textArea.setSelectionRange(0, 99999);
  document.execCommand("copy");
  textArea.remove();
};

const getSocialIcon = (link) => {
  switch (link) {
    case 1:
      return (
        <svg
          role="img"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="fill-current w-6 w-6"
        >
          <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
        </svg>
      );
    case 2:
      return (
        <svg
          role="img"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="fill-current w-6 w-6"
        >
          <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
        </svg>
      );
    case 3:
      return (
        <svg
          role="img"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="fill-current w-6 w-6"
        >
          <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
        </svg>
      );
    case 4:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          className="fill-current w-6 w-6"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M17 7h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c1.65 0 3 1.35 3 3s-1.35 3-3 3h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c2.76 0 5-2.24 5-5s-2.24-5-5-5zm-9 5c0 .55.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1H9c-.55 0-1 .45-1 1zm2 3H7c-1.65 0-3-1.35-3-3s1.35-3 3-3h3c.55 0 1-.45 1-1s-.45-1-1-1H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h3c.55 0 1-.45 1-1s-.45-1-1-1z" />
        </svg>
      );
    case 5:
      return (
        <svg
          role="img"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="fill-current w-6 w-6"
        >
          <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z" />
        </svg>
      );
    case 6:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          className="fill-current w-6 w-6"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M20 6h-2.18c.11-.31.18-.65.18-1 0-1.66-1.34-3-3-3-1.05 0-1.96.54-2.5 1.35l-.5.67-.5-.68C10.96 2.54 10.05 2 9 2 7.34 2 6 3.34 6 5c0 .35.07.69.18 1H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM9 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm10 15H5c-.55 0-1-.45-1-1v-1h16v1c0 .55-.45 1-1 1zm1-5H4V9c0-.55.45-1 1-1h4.08L7.6 10.02c-.33.45-.23 1.08.22 1.4.44.32 1.07.22 1.39-.22L12 7.4l2.79 3.8c.32.44.95.54 1.39.22.45-.32.55-.95.22-1.4L14.92 8H19c.55 0 1 .45 1 1v5z" />
        </svg>
      );
    default:
      return "";
  }
};

const RoomInfoWidget = () => {
  const { roomDetails } = useContext(RoomContext);

  const { response: copyResponse, setResponse: setCopyResponse } =
    useResponseFlash(1000);

  const copy = (link) => {
    try {
      copyReferralLink(link);
      setCopyResponse("UPI Id copied!");
    } catch (error) {
      console.log(error);
    }
  };

  return roomDetails.nickname ? (
    <section className="inline-flex flex-col w-108 sm:mx-2  portrait:w-full bg-contain bg-no-repeat shadow-md bg-white-translucent-90 rounded-lg shadow-md mb-4 flex-shrink-0">
      <header className="w-full p-3 flex items-center justify-between">
        <span className="font-bold">About me</span>
      </header>
      <section
        className="bg-blur w-full rounded-lg relative overflow-hidden"
        style={{ boxShadow: "0 0 5px rgba(0,0,0,0.1)", minHeight: "15rem" }}
      >
        <Avatar
          className="h-84 -mr-6 -mb-16 absolute z-20 bottom-0 right-0"
          svg={roomDetails.avatar_url}
          full
        />
        <div
          className="relative z-20 w-full p-4 bg-blur rounded-lg flex flex-col"
          style={{ maxWidth: "75%" }}
        >
          <div className="font-bold text-gray-900">
            {roomDetails.lobby_name}
          </div>
          <div className="font-medium text-gray-700 text-sm">
            {roomDetails.nickname}
          </div>
          {roomDetails.bio && (
            <div className="mt-3 text-sm italic font-medium text-gray-700">
              {roomDetails.bio}
            </div>
          )}
          {roomDetails.socials.length > 0 && (
            <>
              <div className="flex items-center mt-4">
                {roomDetails.socials
                  .filter((s) => s.value !== "" && s.link !== 6)
                  .map((s) => (
                    <a
                      key={s.link}
                      className="mr-4 sm:mr-2"
                      href={getSocialLink(s.link, s.value)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getSocialIcon(s.link, s.icon)}
                    </a>
                  ))}
              </div>
              {roomDetails.socials.find((f) => f.link === 6) &&
              roomDetails.socials.find((f) => f.link === 6).value !== "" ? (
                <button
                  className="flex items-center mt-4"
                  onClick={copy.bind(
                    null,
                    roomDetails.socials.find((f) => f.link === 6).value
                  )}
                >
                  {getSocialIcon(6)}{" "}
                  <span className="ml-1 truncate text-sm">
                    {copyResponse && copyResponse !== ""
                      ? copyResponse
                      : roomDetails.socials.find((f) => f.link === 6).value}
                  </span>
                </button>
              ) : null}
            </>
          )}
        </div>
      </section>
    </section>
  ) : null;
};

export default RoomInfoWidget;
