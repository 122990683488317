import axios, { AxiosInstance } from "axios";
import { useContext, useMemo } from "react";
import { AuthContext } from "../contexts/auth";
import { Endpoint, getApiEndpoint } from "../utils/api";

const useUnauthenticatedApi: (
  endpoint: Endpoint,
  route?: string
) => AxiosInstance = (endpoint, route) => {
  const { clientId, sessionId } = useContext(AuthContext);

  const axiosInstance = useMemo(() => {
    return axios.create({
      baseURL: `${getApiEndpoint(endpoint)}${route || ""}`,
      headers: {
        "Content-Type": "application/json",
        "Client-App-Id": clientId,
        "Client-User-Id": sessionId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, route]);

  return axiosInstance;
};

export default useUnauthenticatedApi;
