import React, { useState, useEffect } from "react";

import { useSeed } from "../../../contexts/seed/index";
import MemeOTD from "./components/MemeOTD";
import QuoteOTD from "./components/QuoteOTD";
import useApi from "../../../../../hooks/useApi";

const ContentOTD = (props) => {
  const api = useApi("zo world", false);
  const [content, setContent] = useState(null);
  const { seedData } = useSeed();

  useEffect(() => {
    if (api) {
      api
        .get(
          `/zoworld/api/v1/content/${
            seedData.code ? seedData.code + "/" : ""
          }today/`
        )
        .then((res) => {
          setContent(res.data.content);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  if (content) {
    switch (content.category) {
      case "QUOTE":
        return (
          <QuoteOTD props={props} content={content} accent={props.accent} />
        );
      case "MEME":
      case "CARTOON":
      case "COMIC":
        return <MemeOTD props={props} content={content} />;
      case "NEWS":
        // TODO: replaced with news widget when asset is available
        return (
          <QuoteOTD props={props} content={content} accent={props.accent} />
        );
      default:
        return null;
    }
  } else {
    return <QuoteOTD props={props} />;
  }
};

export default ContentOTD;
