import { createContext } from "react";

const AuthContext = createContext({
  authApi: null,
  token: null,
  sessionId: null,
  isLoggedIn: false,
  api: null,
  user: null,
  showAuthModal: () => {},
  logout: () => {},
  publicApi: null,
  profileApi: null,
  feedbackApi: null,
  zoworldApi: null,
  formApi: null,
  paymentApi: null,
  webAppApi: null,
  setCookie: () => {},
  getCookie: () => {},
  useAuthenticatedApi: (baseURL) => {},
  usePublicApi: (baseURL) => {},
});

export default AuthContext;
