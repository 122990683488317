import React, { ReactNode } from "react";

export const getChildrenProps = (children: ReactNode) => {
  const _children: GeneralObject[] = [];
  React.Children.forEach(children, (element) => {
    if (element == null) return;
    if (!React.isValidElement(element)) return;

    const props: any = element.props;
    const childData = {
      ...props,
      key: element.key,
      children: [],
    };
    _children.push(childData);
  });

  return _children;
};
