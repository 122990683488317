import React, { useContext, useEffect, useState } from "react";

import { useSeed } from "../../../contexts/seed";
import Widget from "../../ui/Widget";
import AuthContext from "../../../../../contexts/auth";
import GameCard from "./components/GameCard";
import useApi from "../../../../../hooks/useApi";

const GamesList = (props) => {
  const {
    style: { primaryColor, accentColor, backgroundColor },
  } = useSeed();
  const { isLoggedIn, showAuthModal } = useContext(AuthContext);
  const api = useApi("games", true);

  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    if (api) {
      api
        .get("/games/api/v1/mafia/rooms/public/")
        .then((res) => {
          console.log("Rooms List", res);
          setRooms(res.data.rooms);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <Widget
      className={`portrait:h-108 p-2 scroll-hidden flex flex-col items-center overflow-hidden bg-${
        primaryColor ? primaryColor : "white"
      } border-t-4 border-${accentColor}`}
      {...props}
    >
      <div
        className={`w-full font-bold flex-shrink-0 mb-2 pb-2 text-center text-xl uppercase tracking-normal border-b-2 border-${
          backgroundColor ? backgroundColor : "gray-900"
        }`}
      >
        Live Games
      </div>
      <div className="relative flex-grow w-full h-auto overflow-y-auto">
        {!isLoggedIn ? (
          <div className="absolute top-0 left-0 w-full h-full zo-bg-blur rounded-lg shadow-lg flex items-center justify-center">
            <button
              className="block bg-orange px-6 py-4 leading-none uppercase tracking-wide text-lg font-semibold rounded-lg text-white"
              onClick={showAuthModal}
            >
              Login to view
            </button>
          </div>
        ) : rooms.length > 0 ? (
          <ul className="h-auto">
            {rooms.map((room) => (
              <GameCard cardData={room} key={room.code} />
            ))}
          </ul>
        ) : (
          <div className="w-full h-full flex items-center justify-center text-lg font-semibold zo-text-shadow text-mafia-secondary bg-mafia-background rounded-lg">
            There are no active rooms right now.
          </div>
        )}
      </div>
    </Widget>
  );
};

export default GamesList;
