import React from "react";
import Widget from "../../../../ui/Widget";
const QuoteOTD = ({ props, content }) => {

  return (
    <Widget
      className={`zo-quote p-2 text-white bg-${props.accent || 'red-500' } border-t-4 border-${props.accent || 'red-500' }`}
      {...props}
    >
      <div className="font-bold">{content && content.category.charAt(0).toUpperCase() + content.category.slice(1).toLowerCase()} of the day</div>
      <blockquote className="p-1 text-xl leading-tight">
        {content
          ? content.caption.replace(/[“”'"]+/g, '')
          : 'The people, who think they are crazy enough to change the world, are the ones that do.'}

      </blockquote>
    </Widget>
  );
};

export default QuoteOTD;
