import React, { useState } from "react";
import moment from "moment";

import DOBInput from "../../../../Settings/components/ContentPane/panes/GeneralSettings/components/DOBinput";
import useResponseFlash from "../../../../../hooks/useResponseFlash";
import useInput from "../../../../../hooks/useInput";

const DOBRow = ({ profile, updateProfile }) => {
  const [editMode, setEditMode] = useState(false);
  const { response, setResponse } = useResponseFlash();

  const { value: dob, bind: bindDob } = useInput(profile.date_of_birth);

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateProfile({
      date_of_birth: dob,
    })
      .then((e) => {
        setEditMode(false);
      })
      .catch((e) => {
        setResponse("Error in updating");
      });
  };

  return (
    <div className="flex items-start w-full pt-2">
      <div className="w-1/4 mr-4 pt-3 text-sm">Birth date</div>
      <div className="w-3/4">
        {editMode ? (
          <form
            className="flex items-center flex-col bg-gray-300 p-2 rounded-lg"
            onSubmit={handleSubmit}
          >
            <div className="flex items-center justify-between w-full">
              <DOBInput {...bindDob} initialValue={dob} />
            </div>
            <div className="h-4 flex items-center justify-center font-semibold text-xs text-orange">
              {response}
            </div>
            <div className="flex items-center justify-around w-full">
              <button
                type="submit"
                className="bg-orange font-medium py-2 text-sm leading-none px-4 rounded-lg shadow-md text-white"
              >
                Update
              </button>
              <button
                type="button"
                onClick={setEditMode.bind(null, false)}
                className="bg-gray-100 font-medium py-2 text-sm leading-none px-4 rounded-lg shadow-md text-gray-800"
              >
                Cancel
              </button>
            </div>
          </form>
        ) : (
          <div className="w-full flex items-center justify-between pt-2">
            <span className="font-medium">
              {dob ? moment(dob).format("LL") : "Not set"}
            </span>
            <button
              className="text-orange text-sm font-semibold"
              onClick={setEditMode.bind(null, true)}
            >
              Edit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DOBRow;
