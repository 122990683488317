import React from "react";

import MenuItem from "./components/MenuItem";
import personalDetailsIcon from "./../../../../../assets/Personal_Details.svg";
import commonRoomIcon from "./../../../../../assets/Common_Room_02.svg";
import notificationsIcon from "./../../../../../assets/Notifications_02.svg";
import subscriptionIcon from "./../../../../../assets/Subscription.svg";
import socialLinksIcon from "./../../../../../assets/Social_Links.svg";

const MenuPane = ({ handleMenuClick, activePane }) => {
  return (
    <aside className="flex-grow">
      <ul className="px-4 flex-grow h-full overflow-y-auto">
        <MenuItem
          icon={personalDetailsIcon}
          name="General"
          onClick={handleMenuClick.bind(null, "general")}
          isActive={activePane === "general"}
        />
        <MenuItem
          icon={socialLinksIcon}
          name="Social Links"
          onClick={handleMenuClick.bind(null, "social")}
          isActive={activePane === "social"}
        />
        <MenuItem
          icon={commonRoomIcon}
          name="Common Room"
          onClick={handleMenuClick.bind(null, "common-room")}
          isActive={activePane === "common-room"}
        />
        <MenuItem
          icon={notificationsIcon}
          name="Notification"
          onClick={handleMenuClick.bind(null, "notification")}
          isActive={activePane === "notification"}
        />
        <MenuItem
          icon={subscriptionIcon}
          name="My Membership"
          onClick={handleMenuClick.bind(null, "visa")}
          isActive={activePane === "visa"}
        />
      </ul>
    </aside>
  );
};

export default MenuPane;
