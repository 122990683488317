import React, { useEffect, useState } from "react";

import RoleCard from "./components/RoleCard";
import Modal from "../../../../../../ui/Modal";
import identity from "./../../../../assets/identity.svg";
// import useAudio from "../../../../hooks/useAudio";
// import cardFlipAudio from "./../../../../assets/sounds/card-flip.wav";

const IdentityModal = ({ visible, close, card }) => {
  const [reveal, setReveal] = useState(false);
  // const { start } = useAudio(cardFlipAudio, false);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        // start();
        setReveal(true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    let mounted = true;
    if (visible) {
      setTimeout(() => {
        if (mounted) {
          close();
        }
      }, 5000);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal visible={visible} close={close}>
      <div
        className="relative z-40 rounded-lg overflow-hidden shadow-lg mb-10 bg-white"
        style={{
          width: "24rem",
          height: "27.2rem",
          backgroundImage: `url(${identity})`,
        }}
      >
        <div className="flex flex-col items-center pb-4">
          <div className="bg-mafia-primary my-4 w-3/4 border-t-2 border-mafia-secondary p-4 rounded-lg shadow-lg flex flex-col items-center">
            <div className="font-bold uppercase tracking-normal text-mafia-secondary text-2xl">
              Identity Reveal
            </div>
          </div>
          <RoleCard reveal={reveal} card={card} />
        </div>
      </div>
    </Modal>
  );
};

export default IdentityModal;
