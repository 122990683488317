import React, { useState, useEffect } from "react";

import useApi from "../../../../../../../hooks/useApi";

const LocationSelector = ({ countriesData, setLocation, initialData }) => {
  const [countrySelected, setCountrySelected] = useState(false);
  const [stateSelected, setStateSelected] = useState(false);
  const [currentCity, setCurrentCity] = useState();
  const [currentState, setCurrentState] = useState();
  const [currentCountry, setCurrentCountry] = useState();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const api = useApi("profile", true);

  useEffect(() => {
    let mounted = true;
    if (
      initialData &&
      initialData.city &&
      initialData.country &&
      mounted &&
      api
    ) {
      setCurrentCountry(initialData.country);
      setStateSelected(false);
      setCountrySelected(false);
      api
        .get("/profile/api/v1/locations/states/" + initialData.country + "/")
        .then((res) => {
          setStates(res.data.states);
          api
            .get(
              "/profile/api/v1/locations/cities/" + initialData.country + "/"
            )
            .then((res) => {
              if (mounted) {
                setCities(res.data.cities);
                setCurrentState(
                  res.data.cities.find((c) => c.id === initialData.city).state
                );
                setCurrentCity(initialData.city);
                setStateSelected(true);
                setCountrySelected(true);
              }
            });
        });
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData.country, initialData.city, api]);

  const handleCountry = async (e) => {
    const _country = e.target.value;
    setCurrentCountry(_country);
    setStateSelected(false);
    setCountrySelected(false);
    setCurrentCity("");
    setLocation({ country: _country, city: null });
    try {
      const responseStates = await api.get(
        "/profile/api/v1/locations/states/" + _country + "/"
      );
      const responseCities = await api.get(
        "/profile/api/v1/locations/cities/" + _country + "/"
      );
      console.log(responseCities);
      setStates(responseStates.data.states);
      setCities(responseCities.data.cities);
      setCountrySelected(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleState = (e) => {
    setCurrentState(e.target.value);
    const _city = cities.find(
      (c) => e.target.value && c.state === parseInt(e.target.value)
    );
    if (_city) {
      setCurrentCity(_city);
      setLocation({ country: parseInt(currentCountry), city: _city.id });
    }
    setStateSelected(true);
  };

  const handleCity = (e) => {
    const _city = e.target.value;
    setCurrentCity(_city);
    if (_city) {
      setLocation({ country: parseInt(currentCountry), city: +_city });
    }
  };

  return (
    <div className="flex portrait:flex-col portrait:w-full justify-between">
      <select
        size="large"
        placeholder="Country"
        className="w-1/3 form-select mr-2 portrait:w-full portrait:mb-2 portrait:mr-0"
        value={currentCountry}
        onChange={handleCountry}
      >
        {countriesData.map((c) => (
          <option key={c.id} value={c.id}>
            {c.name}
          </option>
        ))}
      </select>

      <select
        size="large"
        placeholder="State"
        className="w-1/3 form-select mr-2 portrait:w-full portrait:mb-2 portrait:mr-0"
        value={currentState}
        disabled={!countrySelected}
        onChange={handleState}
      >
        {states && states.length ? (
          states.map((c) => (
            <option key={c.id} value={c.id}>
              {c.name}
            </option>
          ))
        ) : (
          <option key={"state"} value="states">
            States
          </option>
        )}
      </select>

      <select
        size="large"
        placeholder="City"
        // style={{ width: 150 }}
        className="w-1/3 form-select portrait:w-full"
        value={currentCity}
        disabled={!stateSelected}
        onChange={handleCity}
      >
        {cities && cities.length ? (
          cities
            .filter((c) => currentState && c.state === parseInt(currentState))
            .map((c) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))
        ) : (
          <option key={"city"} value="city">
            City
          </option>
        )}
      </select>
    </div>
  );
};

export default LocationSelector;
