import React, { useState } from "react";

import ModalContext from ".";
// import zobu from "./../../../../../assets/zobu.svg";
// import card from "./../../assets/role-mafia.svg";

const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState([]);

  return (
    <ModalContext.Provider value={{ modal, setModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
