import React, { useMemo } from "react";
import Avatar from "../../../../components/ui/Avatar";
import { useRoom } from "../../../../contexts";

const AvatarsDisplay = () => {
  const { citizens } = useRoom();

  return useMemo(
    () =>
      citizens.length < 3 ? (
        <>
          <section
            className={`fixed flex items-center justify-center w-full mb-8 bottom-0 left-0 pointer-events-none ${
              citizens.length === 4
                ? "mr-20"
                : citizens.length === 5
                ? "ml-10"
                : ""
            }`}
          >
            <div className="flex items-center">
              {citizens.slice(2, 5).map((m) => (
                <Avatar
                  key={m.code}
                  svg={m.avatar_url}
                  full
                  style={{ height: window.innerWidth < 640 ? "40vh" : "50vh" }}
                />
              ))}
            </div>
          </section>
          <section
            className={`fixed flex items-center justify-center w-full bottom-0 left-0 pointer-events-none ${
              citizens.length === 5 ? "-ml-10" : ""
            }`}
          >
            <div className="flex items-center">
              {citizens.slice(0, 2).map((m) => (
                <Avatar
                  key={m.code}
                  svg={m.avatar_url}
                  full
                  style={{ height: window.innerWidth < 640 ? "40vh" : "50vh" }}
                />
              ))}
            </div>
          </section>
        </>
      ) : citizens.length < 6 ? (
        <>
          <section
            className={`fixed flex items-center justify-center w-full mb-8 bottom-0 left-0 pointer-events-none ${
              citizens.length === 4
                ? "-ml-18"
                : citizens.length === 6
                ? "ml-10"
                : ""
            }`}
          >
            <div className="flex items-center">
              {citizens.slice(3, 6).map((m) => (
                <Avatar
                  key={m.code}
                  svg={m.avatar_url}
                  full
                  style={{ height: window.innerWidth < 640 ? "40vh" : "50vh" }}
                />
              ))}
            </div>
          </section>
          <section
            className={`fixed flex items-center justify-center w-full bottom-0 left-0 pointer-events-none ${
              citizens.length === 5 ? "" : ""
            }`}
          >
            <div className="flex items-center">
              {citizens.slice(0, 3).map((m) => (
                <Avatar
                  key={m.code}
                  svg={m.avatar_url}
                  full
                  style={{ height: window.innerWidth < 640 ? "40vh" : "50vh" }}
                />
              ))}
            </div>
          </section>
        </>
      ) : citizens.length < 8 ? (
        <>
          <section
            className={`fixed flex items-center justify-center w-full mb-8 bottom-0 left-0 pointer-events-none ${
              citizens.length === 6 ? "-ml-18" : ""
            }`}
          >
            <div className="flex items-center">
              {citizens.slice(4, 8).map((m) => (
                <Avatar
                  key={m.code}
                  svg={m.avatar_url}
                  full
                  style={{ height: window.innerWidth < 640 ? "40vh" : "50vh" }}
                />
              ))}
            </div>
          </section>
          <section
            className={`fixed flex items-center justify-center w-full bottom-0 left-0 pointer-events-none ${
              citizens.length === 8 ? "-ml-10" : ""
            }`}
          >
            <div className="flex items-center">
              {citizens.slice(0, 4).map((m) => (
                <Avatar
                  key={m.code}
                  svg={m.avatar_url}
                  full
                  style={{ height: window.innerWidth < 640 ? "40vh" : "50vh" }}
                />
              ))}
            </div>
          </section>
        </>
      ) : (
        <>
          <section
            className={`fixed flex items-center justify-center w-full mb-8 bottom-0 left-0 pointer-events-none ${
              citizens.length === 8 ? "-ml-28" : ""
            }`}
          >
            <div className="flex items-center">
              {citizens.slice(5, 9).map((m) => (
                <Avatar
                  key={m.code}
                  svg={m.avatar_url}
                  full
                  style={{ height: window.innerWidth < 640 ? "40vh" : "50vh" }}
                />
              ))}
            </div>
          </section>
          <section
            className={`fixed flex items-center justify-center w-full bottom-0 left-0 pointer-events-none ${
              citizens.length === 8 ? "-ml-10" : ""
            }`}
          >
            <div className="flex items-center">
              {citizens.slice(0, 5).map((m) => (
                <Avatar
                  key={m.code}
                  svg={m.avatar_url}
                  full
                  style={{ height: window.innerWidth < 640 ? "40vh" : "50vh" }}
                />
              ))}
            </div>
          </section>
        </>
      ),
    [citizens]
  );
};

export default AvatarsDisplay;
