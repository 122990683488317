import React, { useContext, useEffect, useState } from "react";

import Widget from "../../ui/Widget";
import CircleIcon from "../../ui/CircleIcon";
import plusIcon from "./../../../../../../assets/plus.svg";
import zobu from "./../../../../../../assets/zobu.svg";
import ModalContext from "../../../contexts/modals";
import { useSeed } from "../../../contexts/seed";
import useApi from "../../../../../hooks/useApi";

const MembersList = (props) => {
  const { setModal } = useContext(ModalContext);
  const api = useApi("zo world", false);
  const { seedData } = useSeed();

  const [members, setMembers] = useState([]);

  useEffect(() => {
    if (seedData && seedData.code && api) {
      api
        .get(`/zoworld/api/v1/destination/${seedData.code}/hosts/`)
        .then((res) => {
          console.log(res.data);
          setMembers(res.data.hosts);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seedData]);

  return (
    <Widget
      className="flex items-center shadow-none overflow-visible scroll-hidden"
      {...props}
    >
      <span className="flex-shrink-0 text-2xl font-bold zo-text-shadow">
        {props.data.title}
      </span>
      <ul className="flex items-center ml-10 portrait:overflow-x-auto portrait:overflow-y-visible portrait:flex-no-wrap">
        {members.map((member) => (
          <CircleIcon
            key={Math.random()}
            avatar={member.profile.avatar_url || zobu}
            hoverText={member.profile.nickname}
            onClick={
              member.profile.subdomain
                ? () => {
                    window.open(
                      `https://${member.profile.subdomain}.${process.env.REACT_APP_ROOT_DOMAIN}`,
                      "_parent"
                    );
                  }
                : null
            }
          />
        ))}
        <CircleIcon
          icon={plusIcon}
          hoverText="Become a host"
          onClick={setModal.bind(null, "become-member")}
        />
      </ul>
    </Widget>
  );
};

export default MembersList;
