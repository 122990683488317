import { useState, useEffect } from "react";

const useResponseFlash = (delayTime = 2000) => {
  const [response, setResponse] = useState("");

  useEffect(() => {
    let mounted = true;
    if (response) {
      setTimeout(() => {
        if (mounted) {
          setResponse("");
        }
      }, delayTime);
    }

    return () => {
      mounted = false;
    };
  }, [response, delayTime]);

  return { response, setResponse };
};

export default useResponseFlash;
