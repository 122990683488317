import React, { useState, useContext, useEffect } from "react";
import humanize from "humanize-number";

import Widget from "../../ui/Widget";
import ZoHeaderStat from "../../../../../ui/ZoHeaderStat";
import genzIcon from "../../../../../../assets/populations/genZ.svg";
import genxIcon from "../../../../../../assets/populations/genX.svg";
import babyIcon from "../../../../../../assets/populations/Baby_boomers.svg";
import femaleIcon from "../../../../../../assets/populations/Female.svg";
import maleIcon from "../../../../../../assets/populations/Male.svg";
import millenialsIcon from "../../../../../../assets/populations/MIllennials.svg";
import transIcon from "../../../../../../assets/populations/Trans.svg";
import SeedContext from "../../../../../contexts/seed";
import useApi from "../../../../../hooks/useApi";

const PopulationMetrics = (props) => {
  const [zoGenders, setZoGenders] = useState(null);
  const [zoGenZ, setZoGenZ] = useState(null);
  const [zoGenX, setZoGenX] = useState(null);
  const [zoMillenials, setZoMillenials] = useState(null);
  const [zoBoomers, setZoBoomers] = useState(null);
  const [populationMetrics, setPopulationMetrics] = useState(null);

  const { seedData } = useContext(SeedContext);
  const api = useApi("zo world", false);

  useEffect(() => {
    if (api) {
      api
        .get(`/zoworld/api/v1/population/metrics/`)
        .then((res) => {
          setPopulationMetrics(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  useEffect(() => {
    if (populationMetrics && seedData) {
      const genders = [];
      seedData.gender &&
        seedData.gender.map(([id, sex]) => {
          populationMetrics.gender.map((item) => {
            if (item.gender === id || item.gender === id) {
              genders.push({
                sex: sex,
                citizen: item.count || 0,
              });
            }
            return genders;
          });
          return genders;
        });

      setZoGenders(
        genders.reduce((obj, item) => {
          obj[item.sex] = item;
          return obj;
        }, {})
      );

      // calculate generations wise count

      let genz = [];
      let genz_Count = 0;
      let millennials = [];
      let millennials_count = 0;
      let genx = [];
      let genx_Count = 0;
      let boomers = [];
      let boomers_count = 0;

      populationMetrics.age_year.map((item) => {
        if (item.year) {
          const [year] = item.year.split("-");
          if (Number(year) >= 2000) {
            // gen z
            genz.push(item);
            genz_Count = item.count + genz_Count;
          } else if (1999 >= Number(year) && Number(year) >= 1980) {
            //millenials
            millennials.push(item);
            millennials_count = item.count + millennials_count;
          } else if (1979 >= Number(year) && Number(year) >= 1965) {
            //genx
            genx.push(item);
            genx_Count = item.count + genx_Count;
          } else if (1964 >= Number(year)) {
            //boomers
            boomers.push(item);
            boomers_count = item.count + boomers_count;
          }
        }
        return item;
      });

      setZoGenZ({
        items: genz,
        citizens: genz_Count,
      });

      setZoMillenials({
        items: millennials,
        citizens: millennials_count,
      });

      setZoGenX({
        items: genx,
        citizens: genx_Count,
      });

      setZoBoomers({
        items: boomers,
        citizens: boomers_count,
      });
    }
  }, [populationMetrics, seedData]);

  return (
    <Widget
      className="flex items-center shadow-none overflow-visible portrait:-mt-4"
      {...props}
    >
      <ul className="flex items-center portrait:flex-wrap">
        <ZoHeaderStat
          className="portrait:mb-2"
          icon={maleIcon}
          title="Male"
          value={
            zoGenders && zoGenders["Male"]
              ? humanize(zoGenders["Male"].citizen)
              : "..."
          }
        />
        <ZoHeaderStat
          icon={femaleIcon}
          title="Female"
          value={
            zoGenders && zoGenders["Female"]
              ? humanize(zoGenders["Female"].citizen)
              : "..."
          }
        />
        <ZoHeaderStat
          title="Other"
          icon={transIcon}
          value={
            zoGenders && zoGenders["Other"]
              ? humanize(zoGenders["Other"].citizen)
              : "..."
          }
        />
        <ZoHeaderStat
          title="Gen Z"
          icon={genzIcon}
          value={zoGenZ ? humanize(zoGenZ.citizens) : "..."}
        />
        <ZoHeaderStat
          title="Millennials"
          icon={millenialsIcon}
          value={zoMillenials ? humanize(zoMillenials.citizens) : "..."}
        />
        <ZoHeaderStat
          title="Gen X"
          icon={genxIcon}
          value={zoGenX ? humanize(zoGenX.citizens) : "..."}
        />
        <ZoHeaderStat
          title="Baby Boomers"
          icon={babyIcon}
          value={zoBoomers ? humanize(zoBoomers.citizens) : "..."}
        />
      </ul>
    </Widget>
  );
};

export default PopulationMetrics;
