import React, { useContext, useState } from "react";
import {
  useInput,
  useResponseFlash,
} from "../../../../../../../../../components/apps/Zo/components/hooks";
import ProfileContext from "../../../../../../../contexts/profile/ProfileContext";
import useApi from "../../../../../../../hooks/useApi";
import SubSectionRow from "../../../components/SubSectionRow";

const NameField = () => {
  const api = useApi("PROFILE_ME");
  const { profile, updateProfile } = useContext(ProfileContext);
  const { value: firstName, bind: bindFirstName } = useInput(
    profile.first_name || ""
  );
  const { value: middleName, bind: bindMiddleName } = useInput(
    profile.middle_name || ""
  );
  const { value: lastName, bind: bindLastName } = useInput(
    profile.last_name || ""
  );
  const { response, setResponse } = useResponseFlash();

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const getName = () => {
    if (profile) {
      if (profile.first_name || profile.last_name || profile.middle_name) {
        return `${profile.first_name && profile.first_name}${
          profile.middle_name && ` ${profile.middle_name}`
        }${profile.last_name && ` ${profile.last_name}`}`;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loading && api) {
      if (!firstName && !middleName && !lastName) {
        setResponse("Enter at least your first name.");
        return;
      }
      setLoading(true);
      try {
        const data = {
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
        };
        console.log(data);

        const response = await api.post(``, JSON.stringify(data));
        if (response.status === 200) {
          updateProfile(data);
          setExpanded(false);
        } else {
          setResponse("Error in fields");
        }
      } catch (error) {
        console.log(error.response);
        setResponse("Error in setting the name.");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <SubSectionRow
      title="Name"
      visibleArea={getName()}
      placeholder="What do people call you by?"
      isExpanded={expanded}
      setExpanded={setExpanded}
    >
      <form className="flex flex-col w-full" onSubmit={handleSubmit}>
        <div className="flex portrait:flex-col w-full">
          <input
            className="w-1/3 portrait:w-full mr-2 portrait:mb-2 portrait:mr-0 form-input"
            placeholder="First Name"
            {...bindFirstName}
          />
          <input
            className="w-1/3 portrait:w-full mr-2 portrait:mb-2 portrait:mr-0 form-input"
            placeholder="Middle Name"
            {...bindMiddleName}
          />
          <input
            className="w-1/3 portrait:w-full form-input"
            placeholder="Last Name"
            {...bindLastName}
          />
        </div>
        <div className="h-6 flex items-center justify-start font-semibold text-xs text-orange w-full">
          {response}
        </div>
        <div className="flex mb-4 w-full">
          <button
            className="bg-orange text-white cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            type="submit"
          >
            {loading ? "Saving" : "Save"}
          </button>
          <button
            className="bg-white text-orange cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            onClick={setExpanded.bind(null, false)}
          >
            Cancel
          </button>
        </div>
      </form>
    </SubSectionRow>
  );
};

export default NameField;
