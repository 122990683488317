import React, { useState, useEffect } from "react";

import Modal from "../../../../../../ui/Modal";
import godfatherCard from "./../../../../assets/mafia/role-godfather.svg";
import mafiaCard from "./../../../../assets/mafia/role-mafia.svg";
import detectiveCard from "./../../../../assets/mafia/role-detective.svg";
import healerCard from "./../../../../assets/mafia/role-healer.svg";
import villagerCard from "./../../../../assets/mafia/role-villager-boy-1.svg";

const characterData = {
  godfather: {
    key: "godfather",
    card: godfatherCard,
    name: "Godfather",
    content:
      "The omnipresent entity of the Village of Rampur, Zo Godfather knows the good, the bad, and the ugly of this land. His role is not to give justice, but to keep the Mafia World spinning, by co-ordinating in harmony with the different characters of this game.",
  },
  mafia: {
    key: "mafia",
    card: mafiaCard,
    name: "Zo Mafia",
    content:
      "Pure evil geniuses, Zo Mafias play it dirty, aiming to establish their rule on the Village of Rampur. From killing the innocent during the night to parading as a villager in the daytime and misleading everyone, their job is as exciting as it is scandalising.",
  },
  detective: {
    key: "detective",
    card: detectiveCard,
    name: "Private Eye",
    content:
      "The Detective awakens each night after the Mafias have done their crime, investigating about the Zo Mafia with the Godfather. If they’re able to catch the Mafia with their guess, it becomes their duty to guide the Villagers to the right path under their guise.",
  },
  healer: {
    key: "healer",
    card: healerCard,
    name: "Healer",
    content:
      "A silent saviour with a whimsical superpower, the healer can save the World one night at a time. Playing against the mafia, the sixth sense of the healer proves to be a trump card for the game.",
  },
  villager: {
    key: "villager",
    card: villagerCard,
    name: "Villager",
    content:
      "Usually the innocent majority of the Village of Rampur, villagers either save their community or push it towards hell, until every one of them is found dead. Their judgement, sense of surroundings, and logic strength make the game tight and thrilling.",
  },
};

const CharacterModal = ({ visible, close, character }) => {
  const [currentCharacterData, setCurrentCharacterData] = useState({});

  const next = () => {
    const roles = Object.keys(characterData);
    const index = roles.indexOf(currentCharacterData.key);
    const nextIndex = (index + 1) % roles.length;
    setCurrentCharacterData(characterData[roles[nextIndex]]);
  };

  const previous = () => {
    const roles = Object.keys(characterData);
    const index = roles.indexOf(currentCharacterData.key);
    const nextIndex = index > 0 ? index - 1 : roles.length - 1;
    setCurrentCharacterData(characterData[roles[nextIndex]]);
  };

  useEffect(() => {
    if (character && characterData[character]) {
      setCurrentCharacterData(characterData[character]);
    }
  }, [character]);

  return (
    <Modal visible={visible} close={close}>
      <div
        className="p-4 border-t-4 border-mafia-secondary rounded-lg shadow-md bg-mafia-primary flex flex-col items-center"
        style={{ maxWidth: "40rem", minHeight: "21rem" }}
      >
        <div className="font-bold w-full flex-shrink-0 mb-2 pb-2 text-center text-xl text-white uppercase tracking-normal border-b-2 border-mafia-background">
          Characters
        </div>
        {currentCharacterData && (
          <div className="flex items-center h-full flex-grow">
            <button onClick={previous}>
              <svg
                className="w-10 h-10 fill-current text-mafia-secondary"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M14.71 6.71c-.39-.39-1.02-.39-1.41 0L8.71 11.3c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L10.83 12l3.88-3.88c.39-.39.38-1.03 0-1.41z" />
              </svg>
            </button>
            <div className="flex portrait:flex-col items-center">
              <img
                src={currentCharacterData.card}
                alt=""
                className="h-56 flex-shrink-0"
              />
              <div className="p-4 m-4 mr-0 text-white text-sm border-t-4 border-mafia-secondary rounded-lg shadow-md bg-mafia-background">
                <span className="block text-mafia-secondary text-lg font-bold uppercase tracking-normal mb-2">
                  {currentCharacterData.name}
                </span>
                {currentCharacterData.content}
              </div>
            </div>
            <button onClick={next}>
              <svg
                className="w-10 h-10 fill-current text-mafia-secondary"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
              </svg>
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CharacterModal;
