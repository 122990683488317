import React, { useContext, useEffect, useRef } from "react";

import PartyRTCContext from "../../../context/rtcV2";
import LobbyRTCContext from "../../../../../contexts/rtcV2";
import ProfileContext from "../../../../../contexts/profile";

const RTCButton = ({ sendPartyMessage, partyMessage, pressed, setPressed }) => {
  const { setIsSelfMute: setIsSelfMuteParty } = useContext(PartyRTCContext);
  const {
    setIsSelfMute: setIsSelfMuteLobby,
    isSelfMute: isSelfMuteLobby,
    setVolume,
  } = useContext(LobbyRTCContext);
  const { profile } = useContext(ProfileContext);

  const lastLobbyMicState = useRef(setIsSelfMuteLobby);
  const spacePressed = useRef(false);
  const mousePressed = useRef(false);

  const addPress = (code) => {
    if (code && pressed.indexOf(code) === -1) {
      setPressed((p) => [...p, code]);
    }
  };

  const removePress = (code) => {
    if (code && pressed.indexOf(code) !== -1) {
      setPressed((p) => p.filter((_code) => _code !== code));
    }
  };

  const sendMicOn = () => {
    sendPartyMessage({ action: "mic_press", payload: { code: profile.code } });
  };

  const sendMicOff = () => {
    sendPartyMessage({
      action: "mic_release",
      payload: { code: profile.code },
    });
  };

  const handleMouseDown = () => {
    mousePressed.current = true;
    if (!spacePressed.current) {
      sendMicOn();
    }
  };

  const handleMouseUp = () => {
    mousePressed.current = false;
    if (!spacePressed.current) {
      sendMicOff();
    }
  };

  useEffect(() => {
    if (partyMessage && partyMessage.action) {
      switch (partyMessage.action) {
        case "mic_press":
          addPress(partyMessage.payload.code);
          break;
        case "mic_release":
          removePress(partyMessage.payload.code);
          break;
        case "left":
          removePress(partyMessage.payload.code);
          break;
        default:
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyMessage]);

  useEffect(() => {
    if (pressed.length) {
      // Party Mute change according to user pressed
      if (pressed.indexOf(profile.code) !== -1) {
        setIsSelfMuteParty(false);
      } else {
        setIsSelfMuteParty(true);
      }
      // Lobby Volume 0.25
      setVolume(0.1);
      // Lobby Mute
      lastLobbyMicState.current = isSelfMuteLobby;
      setIsSelfMuteLobby(true);
    } else {
      // Party Mute
      setIsSelfMuteParty(true);
      // Lobby volume 1
      setVolume(1);
      // Lobby Mute to last state
      setIsSelfMuteLobby(lastLobbyMicState.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressed]);

  const handleShortcuts = (e) => {
    if (e.code === "Space") {
      if (e.type === "keydown" && !spacePressed.current) {
        spacePressed.current = true;
        if (!mousePressed.current) {
          sendMicOn();
        }
      } else if (e.type === "keyup" && spacePressed.current) {
        spacePressed.current = false;
        if (!mousePressed.current) {
          sendMicOff();
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleShortcuts);
    document.addEventListener("keyup", handleShortcuts);
    return () => {
      document.removeEventListener("keyup", handleShortcuts);
      document.removeEventListener("keydown", handleShortcuts);
    };
  });

  return (
    <button
      className={`group w-content h-content p-2 flex items-center justify-center rounded-full mx-2 focus:outline-none ${
        pressed.length ? "bg-red-700 shadow-inner" : "bg-orange shadow-2xl"
      }`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        className="fill-current text-white w-6 h-6"
      >
        <g>
          <rect fill="none" height="24" width="24" />
        </g>
        <path d="M18,12L18,12c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1h-2C18.45,11,18,11.45,18,12z" />
        <path d="M16.59,16.82c-0.33,0.44-0.24,1.05,0.2,1.37c0.53,0.39,1.09,0.81,1.62,1.21c0.44,0.33,1.06,0.24,1.38-0.2 c0-0.01,0.01-0.01,0.01-0.02c0.33-0.44,0.24-1.06-0.2-1.38c-0.53-0.4-1.09-0.82-1.61-1.21c-0.44-0.33-1.06-0.23-1.39,0.21 C16.6,16.81,16.59,16.82,16.59,16.82z" />
        <path d="M19.81,4.81c0-0.01-0.01-0.01-0.01-0.02c-0.33-0.44-0.95-0.53-1.38-0.2c-0.53,0.4-1.1,0.82-1.62,1.22 c-0.44,0.33-0.52,0.95-0.19,1.38c0,0.01,0.01,0.01,0.01,0.02c0.33,0.44,0.94,0.53,1.38,0.2c0.53-0.39,1.09-0.82,1.62-1.22 C20.05,5.87,20.13,5.25,19.81,4.81z" />
        <path d="M8,9H4c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h1v3c0,0.55,0.45,1,1,1h0c0.55,0,1-0.45,1-1v-3h1l5,3V6L8,9z" />
        <path d="M15.5,12c0-1.33-0.58-2.53-1.5-3.35v6.69C14.92,14.53,15.5,13.33,15.5,12z" />
      </svg>
      <span className="absolute top-full -mt-1 pointer-events-none opacity-0 bg-gray-700 text-white whitespace-no-wrap font-medium px-2 py-1 leading-none rounded-full text-xs group-hover:opacity-100">
        {pressed.indexOf(profile.code) !== -1
          ? "Speak now"
          : "Hold mouse click or hold SPACE to talk to the party"}
      </span>
    </button>
  );
};

export default RTCButton;
