import React, { useMemo, useEffect, useContext } from "react";
import SeedContext from "../../../../contexts/seed";

const ua = navigator.userAgent.toLowerCase();
let isSafari = false;
if (ua.indexOf("safari") !== -1) {
  if (ua.indexOf("chrome") > -1) {
    // alert("1") // Chrome
  } else {
    isSafari = true;
  }
}

const AmbientAudio = ({ sound }) => {
  const { musicFiles } = useContext(SeedContext);

  const audioFile = useMemo(() => musicFiles[sound] && musicFiles[sound].file, [
    musicFiles,
    sound,
  ]);

  const audio = useMemo(() => new Audio(audioFile), [audioFile]);

  // same as React.Component.forceUpdate
  const [, _forceUpdate] = React.useState(false);
  const forceUpdate = () => _forceUpdate((prevState) => !prevState);

  useEffect(() => {
    try {
      if (!isSafari) {
        audio.play();
      }
    } catch (error) {
      console.log(error);
    }
    audio.addEventListener("play", forceUpdate);
    audio.addEventListener("pause", forceUpdate);
    audio.addEventListener("ended", forceUpdate);
    audio.addEventListener("timeupdate", forceUpdate);
    audio.volume = 0.03;
    audio.loop = true;
    // audio.onloadedmetadata = () => {
    //   if (audioFile.start_time) {
    //     const currentTime =
    //       ((+new Date() - audioFile.start_time) / 1000) % audio.duration;
    //     console.log("audioFile", currentTime);
    //     audio.currentTime = currentTime;
    //   }
    // };

    return () => {
      audio.removeEventListener("play", forceUpdate);
      audio.removeEventListener("pause", forceUpdate);
      audio.removeEventListener("ended", forceUpdate);
      audio.addEventListener("timeupdate", forceUpdate);
      try {
        audio.pause();
        audio.remove();
      } catch (error) {
        console.log(error);
      }
    };

    /**
     * about useEffect's second argument
     *    give nothing        => same as componentDidUpdate
     *    give an empty array => same as componentWillMount
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioFile]);

  // useEffect(() => {
  //   try {
  //     if (mute) {
  //       if (audio) {
  //         audio.pause();
  //       }
  //     } else {
  //       if (audio) {
  //         if (!isSafari) {
  //           audio.play();
  //         }
  //       }
  //     }
  //   } catch (error) {}
  // }, [audio, mute]);

  // const play = () => {
  //   try {
  //     if (!audio.paused) {
  //       audio.currentTime = 0;
  //     }
  //     audio.play();
  //   } catch (error) {}
  // };
  // const pause = () => {
  //   try {
  //     audio.pause();
  //   } catch (error) {}
  // };

  return <></>;
};

export default AmbientAudio;
