import React, { useState } from "react";
import { LogIn, Mic, MicOff, UserX } from "../../../assets/icons";
import Avatar from "../Avatar";
import Button from "../Button";
import ReportUserModal from "./../../../../../components/commons/ReportAbuseModal";
import Portal from "./../../../../../components/ui/Portal";
import Flex from "./../../structure/Flex";

interface CitizenCardProps {
  data: DetailedCitizen;
  isUserHost: boolean;
  onDismiss: () => void;
  onMute: () => void;
  onKick: () => void;
  onRequestUnmute: () => void;
}

const CitizenCard: React.FC<CitizenCardProps> = ({
  data,
  isUserHost,
  onDismiss,
  onMute,
  onKick,
  onRequestUnmute,
}) => {
  const [reportUserVisible, setReportUserVisible] = useState<boolean>(false);

  return (
    <>
      <Portal>
        <Flex items="center" justify="center" className="fixed inset-0">
          <div
            className="fixed inset-0 bg-white-translucent-50"
            onClick={onDismiss}
          />
          <Flex
            col
            items="end"
            justify="end"
            className="bg-zo-off-white p-4 relative w-72 h-96 rounded-lg shadow-md overflow-hidden"
          >
            <Avatar
              className="h-108 -mb-12 -ml-8 absolute bottom-0 left-0"
              svg={data.avatar_url}
              full
            />
            <Flex col items="center" className="w-40 h-content">
              <span className="font-bold">{data.nickname}</span>
              <span className="text-sm text-center mt-2">{data.bio}</span>
              <Flex
                col
                items="center"
                className="w-full"
                style={{ minHeight: "12rem" }}
              >
                <Flex col items="center" className="w-full my-4">
                  <Button
                    type="secondary"
                    className="w-full"
                    textClassName="text-xs"
                    icon={<LogIn className="w-4 h-4" />}
                  >
                    Travel to room
                  </Button>
                </Flex>
                {isUserHost && (
                  <Flex col items="center" className="w-full">
                    {!data.isHost && (
                      <Button
                        type="secondary"
                        className="w-full rounded-b-none"
                        style={{ marginBottom: "1px" }}
                        textClassName="text-xs"
                        icon={<UserX className="w-4 h-4" />}
                        onClick={onKick}
                      >
                        Kick
                      </Button>
                    )}
                    {data.connected ? (
                      data.muted ? (
                        <Button
                          type="secondary"
                          className={`w-full ${
                            data.isHost ? "rounded-lg" : "rounded-t-none"
                          }`}
                          textClassName="text-xs"
                          icon={<Mic className="w-4 h-4" />}
                          onClick={onRequestUnmute}
                        >
                          Request unmute
                        </Button>
                      ) : (
                        <Button
                          type="secondary"
                          className={`w-full ${
                            data.isHost ? "rounded-lg" : "rounded-t-none"
                          }`}
                          textClassName="text-xs"
                          icon={<MicOff className="w-4 h-4" />}
                          onClick={onMute}
                        >
                          Mute
                        </Button>
                      )
                    ) : null}
                  </Flex>
                )}
              </Flex>
              <Flex col items="center" className="w-full mt-4">
                <button className="focus:outline-none text-sm font-bold text-orange">
                  Report User
                </button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Portal>
      {reportUserVisible && (
        <ReportUserModal
          close={setReportUserVisible.bind(null, false)}
          code={data.code}
        />
      )}
    </>
  );
};

export default CitizenCard;
