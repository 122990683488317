import React, { useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import { ArrowLeft, Key, Logout } from "../../../../assets/icons";
import Flex from "../../../../components/structure/Flex";
import Button from "../../../../components/ui/Button";
import { useProfile } from "../../../../contexts";

interface FooterProps {
  close: () => void;
}

const Footer: React.FC<FooterProps> = ({ close }) => {
  const history = useHistory();
  const location = useLocation();
  const { profile } = useProfile();

  const isSelfRoomOpen = useMemo(
    () =>
      profile ? location.pathname.includes(`@${profile.subdomain}`) : true,
    [location.pathname, profile]
  );

  const openRoom = () => {
    history.push(`/@${profile?.subdomain}/`);
    if (close) {
      close();
    }
  };

  const leaveRoom = () => {
    history.push("/@portal");
  };

  return (
    <footer className="flex items-center w-full justify-between p-4 flex-shrink-0 max-w-lg mx-auto">
      <Flex items="center">
        {close != null && (
          <>
            <Button
              type="primary"
              icon={<ArrowLeft className="w-4 h-4" />}
              className="mr-1"
              onClick={close}
            />
            <Button
              type="secondary"
              icon={<Logout className="w-4 h-4" />}
              onClick={leaveRoom}
            >
              Leave Room
            </Button>
          </>
        )}
      </Flex>
      {!isSelfRoomOpen ? (
        <Flex items="center">
          <Button
            type="primary"
            icon={<Key className="w-4 h-4 text-white" />}
            onClick={openRoom}
          >
            Open your room
          </Button>
        </Flex>
      ) : (
        <div />
      )}
    </footer>
  );
};

export default Footer;
