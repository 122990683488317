import {
  AUDIO_ACTIVE_PACKETS,
  AUDIO_LEVEL_AVERAGE,
  AUDIO_LEVEL_EVENTS,
  PREBUFFER,
  QUALITY,
  SAMPLE_RATE,
  VOLUME,
} from "../utils/constants";
import getRandomString from "../utils/getRandomString";
import JanusStore from "./JanusStore";

const AudioBridgePlugin = (
  sessionId,
  displayId,
  userId,
  token,
  publisherId,
  sendSocketMessage,
  record
) => {
  const baseRequest = {
    janus: "message",
    session_id: sessionId,
    token: token,
    handle_id: publisherId,
  };

  const janusStore = JanusStore();

  const routeMessage = (message) => {
    const { transaction } = message;
    const callback = janusStore.getCallback(transaction);
    if (callback) {
      callback(message);
    }
  };

  const sendPluginMessage = (transaction, body) => {
    sendSocketMessage({ ...baseRequest, transaction, body });
  };

  const sendPluginJSEPMessage = (transaction, body, jsep) => {
    sendSocketMessage({
      ...baseRequest,
      transaction,
      jsep,
      body,
    });
  };

  const checkForRoom = (roomId, callback) => {
    const body = {
      request: "exists",
      room: roomId,
    };
    const transaction = janusStore.storeCallback(callback);
    sendPluginMessage(transaction, body);
  };

  const createRoom = (roomId, callback) => {
    const body = {
      request: "create",
      room: roomId,
      description: `${roomId}'s Audio Room`,
      secret: getRandomString(10),
      pin: "1234",
      is_private: false,
      allowed: ["peerId"],
      sampling_rate: SAMPLE_RATE,
      audiolevel_ext: true,
      audiolevel_event: AUDIO_LEVEL_EVENTS,
      audio_active_packets: AUDIO_ACTIVE_PACKETS,
      audio_level_average: AUDIO_LEVEL_AVERAGE,
      default_prebuffering: PREBUFFER,
      record: record != null,
      record_file: `/var/recordings/${record}.wav`,
    };
    const transaction = janusStore.storeCallback(callback);
    sendPluginMessage(transaction, body);
  };

  const joinRoom = (roomId, muted, callback) => {
    const body = {
      request: "join",
      room: roomId,
      id: userId,
      pin: "1234",
      display: displayId,
      token: "peerId",
      muted,
      codec: "opus",
      quality: QUALITY,
      volume: VOLUME,
    };
    const transaction = janusStore.storeCallback(callback);
    sendPluginMessage(transaction, body);
  };

  const publishStream = (sdpOffer, muted, callback) => {
    // console.log("[configure] sdpOffer", sdpOffer);
    //console.log("sdpOffer1", getRTCPeerConnection(id).);
    // console.log("[PARTYRTC] publisher sdpOffer:", sdpOffer);
    const body = {
      request: "configure",
      muted,
      display: displayId,
      prebuffer: PREBUFFER,
      quality: QUALITY,
      volume: VOLUME,
      record: false,
      filename: "",
    };
    const jsep = {
      type: sdpOffer.type,
      sdp: sdpOffer.sdp,
    };
    const transaction = janusStore.storeCallback(callback);
    sendPluginJSEPMessage(transaction, body, jsep);
  };

  const renegotiate = (sdpOffer, muted, callback) => {
    const body = {
      audio: true,
    };
    const jsep = {
      type: sdpOffer.type,
      sdp: sdpOffer.sdp,
    };
    const transaction = janusStore.storeCallback(callback);
    sendPluginJSEPMessage(transaction, body, jsep);
  };

  const configureMute = (muted, callback) => {
    const body = {
      request: "configure",
      muted,
      display: displayId,
      prebuffer: PREBUFFER,
      quality: QUALITY,
      volume: VOLUME,
      record: false,
      filename: "",
    };
    const transaction = janusStore.storeCallback(callback);
    sendPluginMessage(transaction, body);
  };

  const changeRoom = (newRoomId, muted, callback) => {
    const body = {
      request: "changeroom",
      room: newRoomId,
      display: displayId,
      id: userId,
      token: "peerId",
      pin: "1234",
      muted,
    };
    const transaction = janusStore.storeCallback(callback);
    sendPluginMessage(transaction, body);
  };

  const leaveRoom = (roomId, callback) => {
    const body = {
      request: "leave",
      room: roomId,
    };
    const transaction = janusStore.storeCallback(callback);
    sendPluginMessage(transaction, body);
  };

  return {
    routeMessage,
    checkForRoom,
    createRoom,
    joinRoom,
    publishStream,
    configureMute,
    changeRoom,
    leaveRoom,
    renegotiate,
  };
};

export default AudioBridgePlugin;
