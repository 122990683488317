import React from "react";

import Widget from "../../ui/Widget";
import { useSeed } from "../../../contexts/seed";

const HotDeal = (props) => {
  const { style } = useSeed();

  return (
    <Widget
      className={`zo-hot-deal p-2 bg-white border-t-4 border-${
        style.accentColor || "orange"
      }`}
      {...props}
    >
      <div className="flex items-baseline justify-between">
        <div className="font-bold relative z-20">{props.data.title}</div>
        <div>
          Expiring in <span className="font-bold ">25:32</span>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="relative group">
          <img
            className="object-contain w-full h-32"
            src="https://d3gqasl9vmjfd8.cloudfront.net/bd146841-36ae-4b73-b425-f0c92a61909d.jpg"
            alt=""
          />
          <div
            className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
          >
            <button className="px-4 py-2 text-gray-800 border border-gray-800 rounded shadow-md hover:bg-white">
              View deal
            </button>
          </div>
        </div>
        <span className="mt-2 font-semibold">Walker Edison Folding Chair</span>
        <div className="flex items-baseline justify-between">
          <div className="price">
            <strong>₹599</strong>{" "}
            <span className="text-sm text-gray-700 line-through">999</span>
          </div>
          <div className="bought">
            <strong>43</strong>{" "}
            <span className="text-sm text-gray-700">/ 500 left to unlock</span>
          </div>
        </div>
      </div>
    </Widget>
  );
};

export default HotDeal;
