import React, { useEffect, useMemo, useRef, useState } from "react";
import { AutoSizer, List, ListRowRenderer } from "react-virtualized";
import { useProfile } from "../../../contexts";
import useApi from "../../../hooks/useApi";
import Flex from "../../structure/Flex";
import Widget from "../../ui/Widget";
import Avatar from "./../../ui/Avatar";

const MafiaLeaderboard = () => {
  const api = useApi("GAMES_MAFIA_LEADERBOARD");
  const { profile } = useProfile();

  const standingListRef = useRef<HTMLDivElement>(null);
  const lastScrollPostion = useRef<number>();

  const [standingList, setStandingList] = useState<any[]>([]);
  const [gainerList, setGainerList] = useState<any[]>([]);
  const [currentTab, setCurrentTab] = useState("standing");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let mounted = true;

    if (api) {
      api
        .get("?page=" + currentPage)
        .then((res) => {
          if (mounted) {
            console.log("Leaderboard", res);
            setStandingList((l) => [...l, ...res.data.standings]);
            setGainerList((l) => [...l, ...res.data.gainers]);
            setTotal(res.data.total);
            if (
              standingListRef &&
              standingListRef.current &&
              lastScrollPostion &&
              lastScrollPostion.current
            ) {
              standingListRef.current.scrollTop = lastScrollPostion.current;
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, currentPage]);

  const list = useMemo(
    () => (currentTab === "standing" ? standingList : gainerList),
    [currentTab, gainerList, standingList]
  );

  const openTab = (tab: string, e: any) => {
    e.stopPropagation();
    setCurrentTab(tab);
  };

  const handleLoadMore = () => {
    if (
      standingListRef &&
      standingListRef.current &&
      lastScrollPostion &&
      lastScrollPostion.current
    ) {
      lastScrollPostion.current = standingListRef.current.scrollTop;
    }
    setCurrentPage((p) => ++p);
  };

  const rowRenderer: ListRowRenderer = ({ index, key, style }) => {
    return (
      <div key={key} style={style}>
        <li
          className={`w-full flex items-center w-full p-2 rounded-lg pt-2 ${
            (currentTab === "standing" && index < 12) ||
            (currentTab === "gainer" && index < 5)
              ? "bg-gray-600"
              : ""
          } ${
            list[index].profile.code === profile?.code
              ? "bg-gray-800 text-white"
              : "bg-white"
          }`}
        >
          <span
            className={`w-6 h-6 flex justify-center font-semibold rounded-full items-center flex-shrink-0 text-xs mr-2 ${
              list[index].profile.code === profile?.code
                ? "text-gray-800 bg-white"
                : "text-white bg-gray-800"
            }`}
          >
            {index + 1}
          </span>
          <Avatar
            svg={list[index].profile.avatar_url}
            className="w-10 h-10 border-white rounded-full border-2 flex-shrink-0 bg-white"
          />
          <span
            className={`flex-grow font-semibold mx-2 flex items-center ${
              list[index].profile.code === profile?.code
                ? "text-white"
                : "text-gray-800"
            }`}
          >
            {list[index].profile.nickname}
          </span>
          <span
            className={`flex-shrink-0 w-24 text-center font-semibold ${
              list[index].profile.code === profile?.code
                ? "text-white"
                : "text-gray-800"
            }`}
          >
            {currentTab === "standing"
              ? list[index].score
              : list[index].season_score}
          </span>
        </li>
      </div>
    );
  };

  return (
    <Widget title="Leaderboard" hasToggle id="mafia-leaderboard">
      {() => (
        <div
          className="w-full flex flex-col"
          data-user-options={
            <Flex>
              <button
                className={`focus:outline-none text-sm py-1 px-2 font-semibold leading-none cursor-pointer ${
                  currentTab === "gainer"
                    ? "text-white bg-orange rounded-lg"
                    : "text-gray-700 hover:text-orange"
                }`}
                onClick={openTab.bind(null, "gainer")}
              >
                This Season
              </button>
              <button
                className={`focus:outline-none text-sm py-1 px-2 font-semibold leading-none cursor-pointer ${
                  currentTab === "standing"
                    ? "text-white bg-orange rounded-lg"
                    : "text-gray-700 hover:text-orange"
                }`}
                onClick={openTab.bind(null, "standing")}
              >
                All time
              </button>
            </Flex>
          }
        >
          <div
            className="flex items-center w-full py-2 px-4 bg-white-translucent-90 rounded-t-lg"
            style={{ boxShadow: "0 0 5px rgba(0,0,0,0.1)" }}
          >
            <span className="w-6 h-6 flex justify-cente font-semibold rounded-full items-center flex-shrink-0 text-xs mr-2">
              Rank
            </span>
            <span className="flex-grow font-semibold ml-4 mr-2 flex items-center">
              Nickname
            </span>
            <span className="flex-shrink-0 w-24 text-cente font-semibold">
              Score
            </span>
          </div>
          <div
            className="relative flex-grow w-full h-80 px-2 rounded-b-lg scroll-hidden"
            ref={standingListRef}
          >
            {list.length > 0 ? (
              <AutoSizer className="w-full">
                {({ width }) => (
                  <>
                    <List
                      rowHeight={60}
                      rowCount={list.length}
                      width={width}
                      rowRenderer={rowRenderer}
                      height={320}
                    />
                    {total > list.length && (
                      <button
                        className="bg-gray-800 text-white absolute rounded-lg font-semibold uppercase tracking-wide p-2 shadow-md my-2 focus:outline-none"
                        onClick={handleLoadMore}
                        style={{ top: 268 }}
                      >
                        Load More Ranks
                      </button>
                    )}
                  </>
                )}
              </AutoSizer>
            ) : (
              <div className="w-full h-full flex items-center justify-center text-lg font-semibold zo-text-shadow text-gray-800 bg-gray-400">
                No games played in this season.
              </div>
            )}
          </div>
        </div>
      )}
    </Widget>
  );
};

export default MafiaLeaderboard;
