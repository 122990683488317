import React, { useContext, useState, useEffect } from "react";

import SubSectionRow from "../../../components/SubSectionRow";
import ProfileContext from "../../../../../../../contexts/profile";
import { useResponseFlash } from "../../../../../../Zo/components/hooks";
import useApi from "../../../../../../../hooks/useApi";

const copyReferralLink = (link) => {
  const textArea = document.createElement("textarea");
  textArea.value = link;
  textArea.style.position = "fixed";
  textArea.style.top = "-400vh";
  textArea.style.left = "0";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  textArea.setSelectionRange(0, 99999);
  document.execCommand("copy");
  textArea.remove();
};

const randomString = (len) => {
  const charSet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";
  for (let i = 0; i < len; i++) {
    let randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }
  return randomString;
};

const SecurityField = () => {
  const { profile, updateProfile } = useContext(ProfileContext);
  const api = useApi("profile", true);
  const {
    response: copiedResponse,
    setResponse: setCopiedResponse,
  } = useResponseFlash();
  const {
    response: newLinkResponse,
    setResponse: setNewLinkResponse,
  } = useResponseFlash();

  const [loading, setLoading] = useState(false);
  const [passwordToken, setPasswordToken] = useState(null);

  useEffect(() => {
    if (api) {
      api
        .get("/profile/api/v1/me/lobby/")
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data.token);
            setPasswordToken(res.data.token);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [api]);

  const handleLevelUpdate = async (level) => {
    if (level > 0 && level < 4) {
      if (!loading && api) {
        setLoading(true);
        try {
          const data = {
            security: level,
          };
          const response = await api.post(
            `/profile/api/v1/me/`,
            JSON.stringify(data)
          );
          if (response.status === 200) {
            updateProfile(data);
          } else {
          }
        } catch (error) {
          console.log(error.response);
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const getSharableLink = () => {
    if (!passwordToken) {
      generateNewLink();
    }
    const link =
      profile.security === 2 || profile.security === 3
        ? `https://${profile.subdomain}.${process.env.REACT_APP_ROOT_DOMAIN}/?t=${passwordToken}`
        : `https://${profile.subdomain}.${process.env.REACT_APP_ROOT_DOMAIN}`;
    copyReferralLink(link);
    setCopiedResponse("Link copied successfully");
  };

  const generateNewLink = async () => {
    if (!loading) {
      setLoading(true);
      const password = randomString(12);
      try {
        if (api) {
          const data = {
            password: password,
          };
          const responsePassword = await api.post(
            "/profile/api/v1/me/lobby/password/",
            JSON.stringify(data)
          );
          if (responsePassword.status === 200) {
            setNewLinkResponse("asdasda");
            setPasswordToken(responsePassword.data.token);
          } else {
          }
        }
      } catch (error) {
        console.log(error.response);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <SubSectionRow
        title="Security Level"
        visibleArea={
          <div className="flex flex-col">
            <div className="flex items-center">
              <button
                className={`shadow-inner text-sm disabled:cursor-not-allowed focus:outline-none p-2 leading-none rounded-l-lg ${
                  profile.security === 3
                    ? "bg-orange shadow-none text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                disabled={profile.security === 3}
                onClick={handleLevelUpdate.bind(null, 3)}
              >
                Private
              </button>
              <button
                className={`shadow-inner text-sm disabled:cursor-not-allowed focus:outline-none p-2 leading-none ${
                  profile.security === 2
                    ? "bg-orange shadow-none text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                disabled={profile.security === 2}
                onClick={handleLevelUpdate.bind(null, 2)}
              >
                Friendly
              </button>
              <button
                className={`shadow-inner text-sm disabled:cursor-not-allowed focus:outline-none p-2 leading-none rounded-r-lg ${
                  profile.security === 1
                    ? "bg-orange shadow-none text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                disabled={profile.security === 1}
                onClick={handleLevelUpdate.bind(null, 1)}
              >
                Public
              </button>
            </div>
            <div className="bg-gray-200 p-2 flex items-center rounded-lg my-2 text-gray-600 text-left text-xs">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                className="w-5 h-5 fill-current mr-4 flex-shrink-0"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
              </svg>
              {profile.security === 3
                ? "Only people who have the Invite link can join."
                : profile.security === 2
                ? "Only people who the Invite link and verified Zo World users can join."
                : profile.security === 1
                ? "All Zo World users can join when you are online."
                : null}
            </div>
          </div>
        }
        editable={false}
      ></SubSectionRow>
      {(profile.security === 2 || profile.security === 3) && (
        <SubSectionRow
          title="Invite Link"
          visibleArea={
            <div className="flex items-center justify-between w-72 portrait:w-full">
              {copiedResponse ? (
                <div className="bg-white font-medium text-sm p-2 leading-none text-orange">
                  Link Copied!
                </div>
              ) : (
                <button
                  className="bg-orange focus:outline-none font-medium text-sm p-2 leading-none rounded-lg shadow-sm hover:shadow-md text-white mr-4"
                  onClick={getSharableLink}
                >
                  Copy Invite Link
                </button>
              )}
              {profile.security === 2 || profile.security === 3 ? (
                newLinkResponse ? (
                  <div className="bg-white font-medium text-sm p-2 leading-none text-orange">
                    Link Generated!
                  </div>
                ) : (
                  <button
                    className="bg-gray-200 focus:outline-none font-medium text-sm p-2 leading-none rounded-lg shadow-sm hover:shadow-md text-orange"
                    onClick={generateNewLink}
                  >
                    Generate New Link
                  </button>
                )
              ) : null}
            </div>
          }
          editable={false}
        ></SubSectionRow>
      )}
    </>
  );
};

export default SecurityField;
