import React, { useContext } from "react";

import ProfileContext from "../../../../../contexts/profile";

const MemberRequest = ({ from, pop, sendSocketMessage, partyCode }) => {
  const { profile } = useContext(ProfileContext);

  const handleAccept = () => {
    sendSocketMessage({
      action: "member_request_response",
      payload: {
        response: "accept",
        to: { nickname: from },
        from: {
          nickname: profile ? profile.nickname : null,
          partyCode: partyCode,
        },
      },
    });
    pop(from);
  };
  const handleDecline = () => {
    sendSocketMessage({
      action: "member_request_response",
      payload: {
        response: "decline",
        to: { nickname: from },
        from: { nickname: profile ? profile.nickname : null },
      },
    });
    pop(from);
  };

  return (
    <div className="fadeInUp bg-white border-t-4 border-orange rounded-lg shadow-lg">
      <div className="p-2">
        <strong>{from}</strong> wants to join your party
      </div>
      <div className="flex items-center w-full justify-around pb-2">
        <button
          className="bg-green-500 font-semibold px-3 py-2 leading-none text-sm rounded-lg shadow-md text-white hover:shadow-lg"
          onClick={handleAccept}
        >
          Accept
        </button>
        <button
          className="bg-red-500 font-semibold px-3 py-2 leading-none text-sm rounded-lg shadow-md text-white hover:shadow-lg"
          onClick={handleDecline}
        >
          Decline
        </button>
      </div>
    </div>
  );
};

export default MemberRequest;
