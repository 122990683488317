import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Modal from "./../../../../../../../ui/Modal";

const JoinRoom = ({ close, visible }) => {
  const [code, setCode] = useState("");

  const history = useHistory();
  const joinGame = () => {
    if (code && code.length === 4) {
      history.push("/r/" + code);
    }
  };

  return (
    <Modal visible={visible} close={close}>
      <div
        className="p-4 border-t-4 border-mafia-secondary rounded-lg shadow-md bg-mafia-primary text-white"
        style={{ maxWidth: "30rem", height: "max-content" }}
      >
        <div className="font-bold mb-2 pb-2 text-center text-xl text-white uppercase tracking-normal border-b-2 border-mafia-background">
          Join a Mafia Room
        </div>
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-between w-full px-4 mt-4">
            <div className="font-bold w-1/3 whitespace-no-wrap">Room Code</div>
            <div className="flex flex-grow w-2/3">
              <input
                className="px-4 py-2 bg-gray-300 text-gray-800 border rounded-lg w-full uppercase tracking-wide"
                type="text"
                placeholder="Enter Room Code, eg. ZXH2"
                maxLength="4"
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              />
            </div>
          </div>
          <button
            className="text-lg uppercase tracking-wide text-mafia-primary bg-mafia-secondary px-4 py-3 mt-6 leading-none rounded-lg hover:shadow-lg font-semibold"
            onClick={joinGame}
          >
            Join Room
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default JoinRoom;
