import React, { useState, useRef, useEffect, useContext } from "react";

import zobu from "./../../../assets/zobu.svg";
import commonRoomIcon from "./../../../assets/Common_Room_02.svg";
import avatarIcon from "./../../../assets/Avatars.svg";
import settingsIcon from "./../../../assets/settings.svg";
import Avatar from "../Avatar";
import { AuthContext, ProfileContext } from "../../contexts/contexts";
import LogInButton from "../../contexts/auth/components/LogInButton";
import ReportUserModal from "./../../commons/ReportAbuseModal";
import { useHistory } from "react-router-dom";

const ZoHeaderUser = ({ className }) => {
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const dropdownRef = useRef();
  const history = useHistory();

  const { isLoggedIn } = useContext(AuthContext);
  const { profile } = useContext(ProfileContext);

  const [reportAbuseVisible, setReportAbuseVisible] = useState(false);

  const toggleDropdownVisibility = (e) => {
    setTimeout(() => {
      setDropdownVisibility((v) => !v);
    }, [100]);
  };

  const hideDropdownVisibility = () => {
    setTimeout(() => {
      setDropdownVisibility(false);
    }, [100]);
  };

  const checkDropdownClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      hideDropdownVisibility();
    }
  };

  useEffect(() => {
    document.addEventListener("click", checkDropdownClick);
    return () => {
      document.removeEventListener("click", checkDropdownClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isInCommonRoom = () => {
    if (
      profile &&
      (window.location.host ===
        `${profile.subdomain}.${process.env.REACT_APP_ROOT_DOMAIN}` ||
        window.location.host === `me.${process.env.REACT_APP_ROOT_DOMAIN}`)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleCommonRoom = () => {
    if (profile) {
      if (
        window.location.host ===
          `${profile.subdomain}.${process.env.REACT_APP_ROOT_DOMAIN}` ||
        window.location.host === `me.${process.env.REACT_APP_ROOT_DOMAIN}`
      ) {
        return null;
      } else {
        window.open(
          `https://me.${process.env.REACT_APP_ROOT_DOMAIN}`,
          "_parent"
        );
      }
    } else {
      return null;
    }
  };

  const handleSettings = () => {
    if (profile) {
      history.push("#settings");
      hideDropdownVisibility();
    } else {
      return null;
    }
  };

  const handleZobuEditor = () => {
    history.push("#zobu");
    hideDropdownVisibility();
  };

  return (
    <>
      <section className="flex items-center flex-shrink-0 relative z-60">
        {/* <ZoHeaderStat
        icon={notificationIcon}
        title={"Notifications"}
        titleClassName={titleClassName}
        className="pl-1 pr-1"
      >
        <span className="absolute top-0 right-0 flex items-center justify-center inline-block w-6 h-6 -mt-1 -mr-1 text-sm text-white rounded-full shadow-md bg-orange">
          2
        </span>
      </ZoHeaderStat> */}
        <div
          className={`relative w-12 h-12 bg-white rounded-full shadow-md group ${
            className ? className : ""
          }`}
          ref={dropdownRef}
        >
          <Avatar
            className="cursor-pointer"
            svg={(profile && profile.avatar_url) || zobu}
            onClick={toggleDropdownVisibility}
          />
          {isLoggedIn && profile ? (
            <div
              className={`bg-white border-t-4 border-orange absolute rounded-lg shadow-xl z-40 right-0 mt-2 overflow-hidden ${
                dropdownVisibility
                  ? "opacity-100 pointer-events-auto"
                  : "opacity-0 pointer-events-none"
              }`}
              style={{ top: "100%", width: "max-content" }}
            >
              <div className="bg-gray-100 px-2 py-4 flex items-center justify-between cursor-default">
                <span className="text-orange font-medium leading-none">
                  {profile.first_name}
                  {profile.last_name && ` ${profile.last_name}`}
                </span>
              </div>
              <ul>
                <li
                  className={`px-2 text-gray-800 text-sm py-2 flex items-center justify-between ${
                    isInCommonRoom()
                      ? "opacity-50 cursor-default hover:white"
                      : "cursor-pointer hover:bg-gray-200"
                  }`}
                  onClick={handleCommonRoom}
                >
                  My Personal Room
                  <img src={commonRoomIcon} alt="" className="w-4 h-4 ml-8" />
                </li>
                <li
                  className="px-2 text-gray-800 text-sm py-2 flex items-center justify-between cursor-pointer hover:bg-gray-200"
                  onClick={handleSettings}
                >
                  My Settings
                  <img src={settingsIcon} alt="" className="w-4 h-4 ml-8" />
                </li>
                <li
                  className="px-2 text-gray-800 text-sm py-2 flex items-center justify-between cursor-pointer hover:bg-gray-200"
                  onClick={handleZobuEditor}
                >
                  Edit my Avatar
                  <img src={avatarIcon} alt="" className="w-4 h-4 ml-8" />
                </li>
                {/* <li
                className={`px-2 text-gray-800 text-sm py-2 flex items-center justify-between ${
                  isInDestination()
                    ? "opacity-50 cursor-default hover:white"
                    : "cursor-pointer hover:bg-gray-200"
                }`}
                onClick={handleDestination}
              >
                My Destination
                <img src={districtIcon} alt="" className="w-4 h-4 ml-8" />
              </li>
              <li
                className={`px-2 text-gray-800 text-sm py-2 flex items-center justify-between ${
                  isInZo()
                    ? "opacity-50 cursor-default hover:white"
                    : "cursor-pointer hover:bg-gray-200"
                }`}
                onClick={handleZoWorld}
              >
                Transport to Zo World
                <img src={logoIcon} alt="" className="w-4 h-4 ml-8" />
              </li> */}
              </ul>
              <div className="bg-gray-100 p-2 flex items-center">
                <button
                  className="text-gray-700 text-xs font-medium flex items-center cursor-pointer"
                  onClick={setReportAbuseVisible.bind(null, true)}
                >
                  Report User
                </button>
                <span className="mx-2 pt-1">&#186;</span>
                <a
                  className="text-gray-700 text-xs font-medium flex items-center cursor-pointer"
                  href="https://www.zostel.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy
                </a>
              </div>
            </div>
          ) : (
            <ul
              className={`absolute bg-white mt-2 rounded-lg shadow-xl z-40 right-0  overflow-hidden ${
                dropdownVisibility
                  ? "opacity-100 pointer-events-auto"
                  : "opacity-0 pointer-events-none"
              }`}
              style={{ top: "100%" }}
            >
              <li className="text-gray-800 px-4 py-2 rounded-lg cursor-pointer hover:bg-gray-200">
                <LogInButton />
              </li>
            </ul>
          )}
          <span className="hidden sm:block absolute top-0 left-0 -mt-3 -ml-5 block pointer-events-none text-red-600 font-black text-sm bg-red-100 rounded-md shadow-xs p-1 leading-none">
            BETA
          </span>
        </div>
      </section>
      {reportAbuseVisible && (
        <ReportUserModal close={setReportAbuseVisible.bind(null, false)} />
      )}
    </>
  );
};

export default ZoHeaderUser;
