import React, { useState } from "react";
import Avatar from "./../../../../../ui/Avatar";
import zobu from "./../../../../../../assets/zobu.svg";
import bg from "./../../../../../../assets/pr-bg.jpg";
import Widget from "../../ui/Widget";
import { useSeed } from "../../../contexts/seed";
import ZobuModal from "./ZobuModal";

const ZobuOTD = (props) => {
  const [isVisible, setVisible] = useState(false);
  const { seedData } = useSeed();

  return (
    <>
      <Widget
        className="zo-zobu bg-white bg-bottom bg-cover"
        {...props}
        onClick={setVisible.bind(null, true)}
      >
        <div
          className="w-full h-full portrait:h-56 bg-white bg-bottom bg-cover"
          style={{ backgroundImage: `url(${bg})` }}
        >
          <div className="px-2 pt-3 pb-2 font-bold zo-gradient-white-top whitespace-pre-wrap portrait:whitespace-no-wrap">
            {props.data.title}
          </div>
          <div className="flex flex-col">
            <div className="absolute bottom-0 flex flex-col items-center w-full">
              {seedData && seedData[props.data.key] ? (
                <img
                  className="absolute bottom-0 w-16 mb-6 h-32"
                  src={seedData && seedData[props.data.key]}
                  alt=""
                />
              ) : (
                <Avatar
                  className="absolute bottom-0 w-20 h-auto"
                  svg={seedData[props.data.key] || zobu}
                  full
                />
              )}
            </div>
          </div>
        </div>
      </Widget>
      {isVisible && props.data.title === "Humans of \nZo World" && (
        <ZobuModal close={setVisible.bind(null, false)} />
      )}
    </>
  );
};

export default ZobuOTD;
