import React, { useMemo } from "react";
import Avatar from "../../../../../ui/Avatar";

import populationIcon from "./../../../../../../assets/population-icon.svg";

const PortalOption = ({
  selectedDestination,
  handleSelection,
  optionData,
  isUserPremium,
}) => {
  const notAllowed = useMemo(
    () => !isUserPremium && optionData.security === 2,
    [isUserPremium, optionData.security]
  );

  const leftText = useMemo(() => {
    const leftCount =
      optionData.members_count - optionData.members_nicknames.length;
    if (leftCount > 0) {
      return ` and ${leftCount} other${leftCount > 1 ? "s" : ""}`;
    } else {
      return "";
    }
  }, [optionData]);

  return optionData ? (
    <div
      className={`mb-2 px-4 h-20 text-lg font-medium rounded-lg border-2 bg-no-repeat bg-left-bottom overflow-hidden ${
        notAllowed
          ? "bg-gray-200 border-gray-400 cursor-not-allowed"
          : selectedDestination && selectedDestination.code === optionData.code
          ? "bg-red-200 text-orange border-red-200 hover:bg-gray-200 cursor-pointer "
          : "bg-white border-white hover:bg-gray-200 cursor-pointer "
      }`}
      onClick={notAllowed ? () => {} : handleSelection.bind(null, optionData)}
      style={{
        backgroundImage: optionData.icon ? `url(${optionData.icon})` : ``,
      }}
    >
      <div className="flex items-center pl-24 h-full justify-between relative">
        {optionData.avatar_url && (
          <Avatar
            full
            className="absolute bottom-0 left-0 h-36"
            style={{ marginBottom: "-3.7rem" }}
            svg={optionData.avatar_url}
          />
        )}
        <div className="flex flex-col items-start">
          {notAllowed ? (
            <div className="text-gray-800 bg-gray-400 border border-gray-400 p-1 -mt-2 leading-none rounded-lg text-xxs uppercase tracking-wide inline-flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                className="w-3 h-3 mr-2 fill-current"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM9 8V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9z" />
              </svg>
              Members only
            </div>
          ) : (
            <div className="uppercase tracking-wide font-semibold text-gray-600 text-xxs">
              {optionData.type}
            </div>
          )}
          <div className="font-semibold">{optionData.name}</div>
          <div className="font-medium text-gray-700 text-xs">
            {optionData.members_nicknames != null
              ? `${optionData.members_nicknames.join(", ")}${leftText}`
              : optionData.nickname}
          </div>
        </div>
        <div className="m-2 bg-gray-300 p-2 rounded-lg text-sm text-gray-800 flex items-center">
          <img className="w-5 mr-2" src={populationIcon} alt="Zostelers" />
          {optionData.members_count}
        </div>
      </div>
    </div>
  ) : null;
};

export default PortalOption;
