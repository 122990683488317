// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    type: "cults",
    rowSpan: 1,
    colSpan: 6,
  },
  {
    type: "portal",
    rowSpan: 1,
    colSpan: 2,
  },
  {
    type: "challenges",
    rowSpan: 2,
    colSpan: 2,
  },
  {
    type: " BlogArticles",
    rowSpan: 2,
    colSpan: 2,
  },
  {
    type: "photo-otd-potrait",
    rowSpan: 4,
    colSpan: 2,
    data: {
      title: "Photo of the Day",
    },
  },
  {
    type: "destinations-unlocked",
    rowSpan: 2,
    colSpan: 2,
  },
  {
    type: "zobu-otd",
    rowSpan: 2,
    colSpan: 1,
    data: {
      title: "Humans of \nZo World",
    },
  },
  {
    type: "asset-otd",
    rowSpan: 2,
    colSpan: 1,
    data: {
      title: "Today's Asset",
    },
  },
  {
    type: "content-otd",
    rowSpan: 2,
    colSpan: 2,
  },
  {
    type: "hot-deal",
    locked: "Unlocking Soon",
    rowSpan: 2,
    colSpan: 2,
    data: {
      title: "Hot Deal",
    },
  },
];
