import React from "react";

// import liveVideo from "../../../../../../assets/weather/live.mp4";
import Widget from "../../ui/Widget";
import { useSeed } from "../../../contexts/seed";
// import launch from "./../../../../../assets/launch.svg";

const LifeAt = (props) => {
  const { seedData } = useSeed();
  return (
    <Widget
      className="relative zo-life-at bg-white bg-center bg-cover"
      {...props}
      locked={!seedData.intro_video ? 'Locked' : null}
      style={{ backgroundImage: !seedData.intro_video && seedData.images && `url(${seedData.images[0].image})` }}

    >
      <div
        className="relative flex flex-col justify-end"
      >
        <video className="w-full h-full" autoPlay loop muted playsInline src={seedData && seedData.intro_video} />
        <div className="absolute bottom-0 left-0 w-full px-2 pt-8 pb-4 zo-text-shadow font-bold text-white zo-gradient-black-bottom">
          Life at {seedData.name}
        </div>
      </div>
    </Widget>
  );
};

export default LifeAt;
