import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import withMemo from "../../../../../../utils/withMemo";
import SeedContext from "../../../../../contexts/seed";
import Portal from "../../../../../ui/Portal";
import RoomContext from "../../../contexts/room";

const EMOJIS = ["❤️", "🔥", "👏", "👍", "😆", "🎉", "🙏", "🙌"];
// const CHRISTMAS_EMOJIS = ["🎁", "🎅", "❄️", "🦌", "⛄️", "🍰", "🌟", "🎄"];

const getRandomMargin = () => {
  const margin = [
    "16",
    "24",
    "32",
    "40",
    "48",
    "56",
    "64",
    "72",
    "80",
    "88",
    "96",
  ];
  return margin[Math.floor(Math.random() * margin.length)];
};

const Emoji = withMemo(
  ({ emoji }) => {
    return (
      <div
        key={emoji.id}
        className={`emojiflash text-3xl absolute ml-${
          window.innerWidth < 640 ? +emoji.margin - 8 : emoji.margin
        } w-8 h-8 m-4 flex items-center justify-center rounded-full ${
          window.innerWidth < 640 ? "mb-16" : ""
        }`}
      >
        {emoji.emoji}
      </div>
    );
  },
  ["emoji"]
);

const EmojiDisplay = withMemo(
  ({ list }) => {
    return (
      <Portal>
        <aside className="fixed z-10 bottom-0 left-0 mb-32 mr-4 pointer-events-none">
          {list.map((emoji, i) => (
            <Emoji key={emoji.id} emoji={emoji} />
          ))}
        </aside>
      </Portal>
    );
  },
  ["list"]
);

const EmojiFlash = withMemo(() => {
  const { sendSocketMessage, message } = useContext(RoomContext);
  const { seedData } = useContext(SeedContext);

  const [list, setList] = useState([]);

  const sendEmoji = useCallback(
    (emoji) => {
      sendSocketMessage({
        action: "emoji_flash",
        payload: {
          emoji,
          id: +new Date(),
          margin: getRandomMargin(),
        },
      });
    },
    [sendSocketMessage]
  );

  useEffect(() => {
    if (message && message.action === "emoji_flash") {
      const time = +new Date();
      setList((l) => [...l.filter((i) => time < i.id + 5000), message.payload]);
    }
  }, [message]);

  return useMemo(
    () => (
      <div className="focus:outline-none flex items-center w-full flex-grow-0 my-2 flex-shrink-0 justify-center">
        {(seedData.reaction_emojis || EMOJIS).map((emoji, index) => (
          <button
            key={index}
            className="text-xl ml-2 w-10 h-10 flex items-center justify-center rounded-full bg-white shadow-md hover:bg-gray-300"
            onClick={sendEmoji.bind(null, emoji)}
            onKeyDown={(e) => e.preventDefault()}
            onKeyUp={sendEmoji.bind(null, emoji)}
          >
            {emoji}
          </button>
        ))}
        <EmojiDisplay list={list} />
      </div>
    ),
    [list, sendEmoji, seedData]
  );
}, []);

export default EmojiFlash;
