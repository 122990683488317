import React from "react";

const JoyrideTooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  skipProps,
  primaryProps,
  tooltipProps,
  isLastStep,
}) => (
  <div
    {...tooltipProps}
    className="bg-white rounded shadow-xl p-4 border-b-4 border-orange max-w-sm"
  >
    {step.title && <div className="font-bold text-lg mb-4">{step.title}</div>}
    <div className="whitespace-pre-wrap">{step.content}</div>
    <div className="flex items-center justify-between mt-4">
      {index > 0 && (
        <button
          className="py-2 px-4 bg-gray-300 rounded-lg shadow-md"
          {...backProps}
        >
          Previous
        </button>
      )}
      <button
        className="py-2 px-4 bg-gray-300 rounded-lg shadow-md"
        {...skipProps}
      >
        Skip
      </button>
      {continuous ? (
        isLastStep ? (
          <button
            className="py-2 px-4 bg-orange text-white outline-none rounded-lg shadow-md"
            {...primaryProps}
          >
            I'm ready!
          </button>
        ) : (
          <button
            className="py-2 px-4 bg-orange text-white outline-none rounded-lg shadow-md"
            {...primaryProps}
          >
            Next
          </button>
        )
      ) : (
        <></>
      )}
      {!continuous && (
        <button
          className="py-2 px-4 bg-orange text-white leading-none outline-none rounded-lg shadow-md"
          {...closeProps}
        >
          Close
        </button>
      )}
    </div>
  </div>
);

export default JoyrideTooltip;
