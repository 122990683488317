import React, { useContext, useMemo } from "react";
import withMemo from "../../../../utils/withMemo";

import RTCContext from "../../../contexts/rtcV2";
import RTCControlButton from "./RTCControlButton";

const RTCHostControlButton = withMemo(() => {
  const { isSelfMute } = useContext(RTCContext);

  return useMemo(
    () =>
      isSelfMute ? (
        <div className="relative flex flex-col group items-center text-xs bg-gray-300 text-gray-700 font-medium p-2 rounded-full">
          <svg
            className="fill-current w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M15 10.6V5c0-1.66-1.34-3-3-3-1.54 0-2.79 1.16-2.96 2.65L15 10.6zm3.08.4c-.41 0-.77.3-.83.71-.05.32-.12.64-.22.93l1.27 1.27c.3-.6.52-1.25.63-1.94.07-.51-.33-.97-.85-.97zM3.71 3.56c-.39.39-.39 1.02 0 1.41L9 10.27v.43c0 1.19.6 2.32 1.63 2.91.75.43 1.41.44 2.02.31l1.66 1.66c-.71.33-1.5.52-2.31.52-2.54 0-4.88-1.77-5.25-4.39-.06-.41-.42-.71-.83-.71-.52 0-.92.46-.85.97.46 2.96 2.96 5.3 5.93 5.75V20c0 .55.45 1 1 1s1-.45 1-1v-2.28c.91-.13 1.77-.45 2.55-.9l3.49 3.49c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L5.12 3.56c-.39-.39-1.02-.39-1.41 0z" />
          </svg>
          <span className="absolute top-full z-80 p-1 mt-1 rounded-lg shadow-md leading-none bg-gray-700 text-white text-xs whitespace-no-wrap opacity-0 pointer-events-none group-hover:opacity-100">
            Request host to speak
          </span>
        </div>
      ) : (
        <RTCControlButton className="text-orange bg-white" />
      ),
    [isSelfMute]
  );
}, []);

export default RTCHostControlButton;
