import React, { useContext, useEffect, useMemo, useState } from "react";
import withMemo from "../../../../../utils/withMemo";

import ProfileContext from "../../../../contexts/profile";
import RTCContext from "../../../../contexts/rtcV2";
import RoomContext from "../../contexts/room";
import { checkHost } from "../../utils";
import Member from "./components/Member";

const Members = withMemo(
  ({ sentMemberRequests, sentPartyRequests, partyMembers, partyCode }) => {
    const { members, sendSocketMessage, roomDetails } = useContext(RoomContext);
    const { profile } = useContext(ProfileContext);
    const { peers, isActive, isSelfMute } = useContext(RTCContext);

    const [keyboardFocused, setKeyboardFocused] = useState(false);

    const toggleKeyboardFocused = () => {
      setKeyboardFocused((v) => !v);
    };

    const membersWithData = useMemo(() => {
      return members.map((member) => {
        const connected =
          profile.code === member.code
            ? peers.find((p) => p.display === member.code) || isActive
            : peers.find((p) => p.display === member.code);

        const speaking = connected
          ? connected.status === "talking"
            ? true
            : false
          : false;

        const muted =
          member.nickname === profile.nickname
            ? isSelfMute
            : connected
            ? connected.muted
            : false;

        const isHost = checkHost(roomDetails, member.code);

        let priority;
        if (connected) {
          if (!muted) {
            if (isHost) {
              priority = 4;
            } else {
              priority = 3;
            }
          } else {
            if (isHost) {
              priority = 2;
            } else {
              priority = 1;
            }
          }
        } else {
          priority = 0;
        }

        return {
          ...member,
          muted,
          isHost,
          connected,
          speaking,
          priority,
        };
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, isSelfMute, members, peers]);

    useEffect(() => {
      if (window.innerWidth < 640) {
        document
          .querySelector("#chat-input")
          .addEventListener("focus", toggleKeyboardFocused);
        document
          .querySelector("#chat-input")
          .addEventListener("blur", toggleKeyboardFocused);
      }

      return () => {
        document
          .querySelector("#chat-input")
          .removeEventListener("focus", toggleKeyboardFocused);
        document
          .querySelector("#chat-input")
          .removeEventListener("blur", toggleKeyboardFocused);
      };
    }, []);

    return useMemo(
      () => (
        <section
          className={`w-full my-2 px-2 sm:px-0 flex-grow-0 flex-shrink-0 overflow-hidden rounded-lg scroll-visible ${
            keyboardFocused ? "h-0" : ""
          }`}
        >
          <div
            className="rounded-lg shadow-md bg-white-translucent-95 overflow-y-auto"
            style={{ maxHeight: window.innerWidth < 640 ? "12rem" : "18rem" }}
          >
            {membersWithData
              .sort((x, y) => y.priority - x.priority)
              .map((member, index) => {
                const inSameParty =
                  partyMembers.length > 1 &&
                  partyMembers.find((pm) => pm.code === member.code);

                const joinRequested = !!sentMemberRequests.find(
                  (req) => req === member.nickname
                );
                const inviteRequested = !!sentPartyRequests.find(
                  (req) => req === member.nickname
                );

                return (
                  <Member
                    lastElement={(index + 1) % 6 === 0}
                    checkHost={member.isHost}
                    isHost={checkHost(roomDetails, profile.code)}
                    key={member.code}
                    bio={member.bio}
                    code={member.code}
                    avatar={member.avatar_url}
                    nickname={member.nickname}
                    subdomain={member.subdomain}
                    isPremium={member.status === "premium"}
                    connected={
                      member.nickname === profile.nickname
                        ? isActive
                        : member.connected
                    }
                    isUser={member.nickname === profile.nickname}
                    speaking={member.speaking}
                    muted={member.muted}
                    sendSocketMessage={sendSocketMessage}
                    inSameParty={inSameParty}
                    partyCode={partyCode}
                    joinRequested={joinRequested}
                    inviteRequested={inviteRequested}
                  />
                );
              })}
          </div>
        </section>
      ),
      [
        isActive,
        keyboardFocused,
        membersWithData,
        partyCode,
        partyMembers,
        profile.code,
        profile.nickname,
        roomDetails,
        sendSocketMessage,
        sentMemberRequests,
        sentPartyRequests,
      ]
    );
  },
  ["sentMemberRequests", "sentPartyRequests", "partyMembers", "partyCode"]
);

export default Members;
