const PATH = "/api/v1/profile";

const ENDPOINTS = {
  PROFILE_ME: "/me/",
  PROFILE_ME_LOBBY: "/me/lobby/",
  PROFILE_ME_ACTIVITY_HOURS: "/me/activity/hours/",
  PROFILE_ASSETS: "/me/assets/",
  PROFILE_AVATAR_SEED: "/avatar/seed/",
  PROFILE_SEED: "/seed/",
  PROFILE_LOCATIONS: "/locations/",
  PROFILE_LOCATIONS_COUNTRIES: "/locations/countries/",
  PROFILE_LOCATIONS_COUNTRY: "/locations/country/",
  PROFILE_LOCATIONS_STATE: "/locations/state/",
  PROFILE_LOCATIONS_CITY: "/locations/city/",
  PROFILE_LOBBY: "/lobby/",
  PROFILE_LOBBY_ONLINE: "/lobby/online/",
};

export type ProfileEndpoints = keyof typeof ENDPOINTS;
export const ProfileCollection = { PATH, ENDPOINTS };
