import React, { useContext } from "react";

import Widget from "../../ui/Widget";
import { useSeed } from "../../../contexts/seed";
import ProfileContext from "../../../../../contexts/profile";
import talkToZobu from "../../../../../../assets/talk-to-zobu.svg";

const TalkToHost = (props) => {
  const { style, seedData } = useSeed();
  const { profile } = useContext(ProfileContext);

  const zobuNumber = 919599407770;
  const msgText = profile
    ? `Zo,\n
  This is ${
    profile.nickname || profile.first_name
  }, calling out to Zobu from destination ${seedData.name}.`
    : `Zo,\n
  I am calling out to Zobu from destination ${seedData.name}.`;

  return (
    <Widget
      className={`zo-destinations flex justify-between p-4 cursor-pointer items-center text-white bg-${
        style.accentColor || "orange"
      }`}
      {...props}
      onClick={() =>
        window.open(
          `https://api.whatsapp.com/send?phone=${zobuNumber}&text=${msgText}&source=&data=&app_absent=`
        )
      }
    >
      <div className="font-semibold text-lg">Talk to Host</div>
      <img
        src={talkToZobu}
        className="w-12 h-12 bg-white p-1 rounded-full"
        alt=""
      />
    </Widget>
  );
};

export default TalkToHost;
