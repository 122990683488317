import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Clock, Edit2 } from "../../../assets/icons";
import useApi from "../../../hooks/useApi";

interface EventCardProps {
  data: ZoEvent;
  onEdit: (() => void) | null;
  dateFormat: string;
}

const EventCard: React.FC<EventCardProps> = ({ data, onEdit, dateFormat }) => {
  const api = useApi("PROFILE_LOBBY");
  const [host, setHost] = useState({
    nickname: "Fetching host details ...",
    avatar_url: null,
    subdomain: null,
  });
  const history = useHistory();

  // const startTravel = () => {};

  useEffect(() => {
    if (api) {
      api
        .get(`/${data.host}/`)
        .then((res) => {
          setHost(res.data.profile);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [data.host, api]);

  const hasStarted = useMemo(
    () => moment(data.start_time).isBefore(moment()),
    [data.start_time]
  );

  const hasAlmostStarted = useMemo(
    () => moment(data.start_time).subtract(30, "minutes").isBefore(moment()),
    [data.start_time]
  );

  const handleVisit = () => {
    history.push(`/@${host.subdomain}/`);
  };

  return (
    <li
      className={`flex flex-col fadeInLeft overflow-hidden animation-delay-300 bg-white-translucent-90 rounded-lg shadow-lg mt-4 ${
        (hasAlmostStarted || hasStarted) && "cursor-pointer"
      }`}
      onClick={hasAlmostStarted || hasStarted ? handleVisit : undefined}
    >
      <header
        className="p-2 flex items-center justify-between"
        style={{ backgroundColor: "#E2DFDF" }}
      >
        <span className="font-bold text-sm text-zo-mid-grey flex items-center">
          <Clock className="w-4 h-4 mr-2" />
          {moment(data.start_time).format(dateFormat)}
        </span>
        <div className="flex items-center">
          {hasStarted ? (
            <span className="text-green-500 font-bold text-sm">
              In Progress
            </span>
          ) : hasAlmostStarted ? (
            <span className="text-green-500 font-bold text-sm">
              About to begin
            </span>
          ) : null}
          {onEdit != null && (
            <>
              <button className="ml-4 focus:outline-none" onClick={onEdit}>
                <Edit2 className="w-4 h-4" />
              </button>
            </>
          )}
        </div>
      </header>
      <section className="p-3 pb-4 flex flex-col">
        <span className="font-bold text-lg">{data.name}</span>
        <span className="text-gray-800 font-semibold mt-1 text-sm">
          {host.nickname}
        </span>
        <span className="text-gray-800 mt-1 text-sm">{data.description}</span>
      </section>
    </li>
  );
};

export default EventCard;
