import React, { useEffect, useState } from "react";
// import indiaFlag from "./../../../../../assets/india.svg";
// import heart from "./../../../../../assets/heart.svg";
// import ZoHeaderStat from "../../../../ui/ZoHeaderStat";
// import Avatar from "../../../../ui/Avatar";
// import zobu from "./../../../../../assets/zobu.svg";

// const zostelers = [
//   'Milan',
//   'Sync',
//   'Zoravar'
// ]

const Filters = ({ 
  seedData, 
  setSelectedDestination,
  selectedDestination,
  setSelectedRole,
  selectedRole 
}) => {

  const [ destinations, setDestinations ] = useState(null);
  const [ roles, setRoles ] = useState(null);
   
  useEffect(() => {
    if(seedData && seedData.destinations){
      seedData.destinations.push({
        id: 0,
        name: 'All'
      })
      setDestinations(seedData.destinations)
    }

    if(seedData && seedData.work_roles){
      seedData.work_roles.push([0, 'All']);
      setRoles(seedData.work_roles);
    }


  },[seedData])

  
  return (
    <>
    <div className="w-full p-6 relative mb-5">

      <p className='mb-2'> Destination</p>
      <select 
        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        onChange={(e) => { setSelectedDestination(Number(e.target.value)) }}
        value={selectedDestination}
      >
        { destinations &&
          destinations.map(destination => (
            <option key={destination.id} value={destination.id}>{destination.name}</option>
          ))
        }
        
      </select>

      <div className="pointer-events-none relative w-full items-center px-2  -mt-6 text-gray-700">
            <svg className="fill-current h-4 w-4 absolute right-0 mr-2 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div>

      <p className=' my-10 mb-2'> Role</p>
      <select 
        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        value={selectedRole}
        onChange={(e) => setSelectedRole(Number(e.target.value))}
      >
        {
          roles &&
          roles.map(([id, role]) => (
            <option key={id} value={id}> {role} </option>
          ))

        }
      </select>
      <div className="pointer-events-none relative w-full items-center px-2  -mt-6 text-gray-700">
            <svg className="fill-current h-4 w-4 absolute right-0 mr-2 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div>
     
  
      <div className="pointer-events-none relative w-full items-center px-2  -mt-6 text-gray-700">
            <svg className="fill-current h-4 w-4 absolute right-0 mr-2 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div>
    </div>


    {/* <table className="table-fixed">
      <thead>
        <tr>
          <th className="w-1/2 px-4 py-2 text-xl">By Destinations</th>
        </tr>
      </thead>
      <div className="overflow-scroll pb-48" style={{ height: '80vh'}} >
        {
          zostelers.map(destination => {
            return(
              <div className="  px-4 py-2  my-2 mx-2 flex items-center bg-white rounded-lg shadow-md p-2 justify-between">
                
                <Avatar className="h-10" svg={zobu} />

                <div className="text-sm my-5">
                  <p className="text-gray-900 text-md leading-none">{destination}</p>            
                </div>
                <div>
                <div className="relative flex justify-center">
                  <img className="h-6" src={heart} alt="" />
                  <span className="inline-block ml-2">{'12'}</span>
                </div>
                </div>
                
              </div>
            )
          })
        }
      </div>
    </table> */}
    </>
  );
};

export default Filters;
