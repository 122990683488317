import React from "react";
// import indiaFlag from "./../../../../../assets/india.svg";
// import populationIcon from "./../../../../../assets/people.svg";
// import ZoHeaderStat from "../../../../ui/ZoHeaderStat";
// import heart from "./../../../../../assets/heart.svg";
// import karma from "./../../../../../assets/karma.svg";
import Avatar from "./../../../../../../../ui/Avatar";
import zobu from "./../../../../../../../../assets/zobu.svg";
import bg from "../../../../../../../../assets/pr-bg.jpg";
import DelhiIcon from "../../../../../../../../assets/destinations/Delhi.svg";
import AgraIcon from "../../../../../../../../assets/destinations/Agra.svg";
import AlleppeyIcon from "../../../../../../../../assets/destinations/Alleppey.svg";
import BangaloreIcon from "../../../../../../../../assets/destinations/Bangalore.svg";
import GangtokIcon from "../../../../../../../../assets/destinations/Gangtok.svg";
// import happinessIcon from "./../../../../../assets/happiness.svg";

const zostelers = [
  { name: "Delhi", image: DelhiIcon },
  { name: "Agra", image: AgraIcon },
  { name: "Allepey", image: AlleppeyIcon },
  { name: "Banglore", image: BangaloreIcon },
  { name: "Gangtok", image: GangtokIcon },
  { name: "Allepesy", image: AlleppeyIcon },
  { name: "Banglosre", image: BangaloreIcon },
  { name: "Gangtsok", image: GangtokIcon },
];

const SidePanel = () => {
  return (
    <>
      {/* <div className="inline-block w-full relative mb-5">
      <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
        <option>Country</option>
        <option>Role</option>
        <option>Destination</option>
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
      </div>
    </div> */}

      <div className="text-center font-bold text-xl">Top 5 Destinations</div>
      <div
        className="overflow-y-auto overflow-x-hidden"
        style={{ height: "68vh" }}
      >
        {zostelers.map((destination, index) => {
          return (
            <div key={index}>
              <div
                key={index}
                className="px-4 py-2 my-2 mx-2 items-center bg-white object-contain rounded-lg shadow-md p-2"
                style={{ backgroundImage: `url(${bg})` }}
              >
                <div className="flex text-white items-center">
                  <div className="relative flex justify-center mr-4">
                    <div className="py-2 px-4 bg-yellow-200 text-black rounded-full">
                      {index + 1}
                    </div>
                  </div>

                  <div className="relative flex pb-4  justify-center">
                    <Avatar className="h-16 bg-white" svg={zobu} />
                  </div>

                  <div className="ml-4  md:mt-0 md:ml-6">
                    <div className="tracking-wide text-lg">
                      {destination.name}
                    </div>
                    {/* <p className="text-white-600 text-sm">Tropical thunder</p> */}
                  </div>

                  {/* <div className="text-sm -mt-4 right-0 relative flex justify-end">
                      <img className="h-6" src={'https://cdn3.iconfinder.com/data/icons/game-ui-flat-1/64/medal_award_prize_achievement_game_reward_badge_pentagonal-512.png'} alt="" />
                      <span className="inline-block ml-2">{'12'}</span>         
                    </div> */}
                </div>

                <div className=" -mt-12 items-center">
                  <div className="text-sm mb-2 relative flex justify-end">
                    {/* <img className="h-5" src={happinessIcon} alt="" /> */}
                    <span className=" text-white font-bold text-xl ml-2">
                      {"12 %"}
                    </span>
                  </div>

                  {/* <div className=" relative flex justify-end">
                    <p className="text-white text-xs">May, 2015</p>
                  </div> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* </table> */}
    </>
  );
};

export default SidePanel;
