import React, { useContext } from "react";
import ProfileContext from "../../../../../../../contexts/profile/ProfileContext";
import SubSectionRow from "../../../components/SubSectionRow";

const PassportField = () => {
  const { profile } = useContext(ProfileContext);

  return (
    <SubSectionRow
      title="Passport Number"
      visibleArea={<strong>{profile.code}</strong>}
      placeholder="What do people call you by?"
      editable={false}
    />
  );
};

export default PassportField;
