import React from "react";

const CharacterCount = ({ value, onChange, disabled, sum, players }) => {
  return (
    <div className="flex flex-col flex-grow w-2/3">
      <div className="flex items-center">
        <button
          className={`w-8 h-8 flex items-center justify-center text-white disabled:text-gray-800 disabled:cursor-prevent font-bold text-lg`}
          onClick={() => {
            onChange((v) => --v);
          }}
          disabled={value === 0 || disabled}
        >
          -
        </button>
        <div
          className={`w-8 h-8 flex items-center justify-center bg-mafia-background rounded-lg shadow-md text-white ${
            disabled && " cursor-prevent"
          }`}
        >
          {value}
        </div>
        <button
          className={`w-8 h-8 flex rounded-r-lg items-center justify-center text-white disabled:text-gray-800 disabled:cursor-prevent font-bold text-lg`}
          onClick={() => {
            onChange((v) => ++v);
          }}
          disabled={sum >= players || disabled}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default CharacterCount;
