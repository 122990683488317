import React, { useRef, useEffect } from "react";

import { useSeed } from "../../../../contexts/seed";

const Dates = ({ dates, selected, setSelected }) => {
  const datesRef = useRef();
  const { style } = useSeed();

  const handleLeftButton = () => {
    if (datesRef && datesRef.current) {
      let scrollAmount = 0;
      var slideTimer = setInterval(function () {
        datesRef.current.scrollLeft -= 21;
        scrollAmount += 10;
        if (scrollAmount >= 100) {
          window.clearInterval(slideTimer);
        }
      }, 25);
    }
  };
  const handleRightButton = () => {
    if (datesRef && datesRef.current) {
      let scrollAmount = 0;
      var slideTimer = setInterval(function () {
        datesRef.current.scrollLeft += 21;
        scrollAmount += 10;
        if (scrollAmount >= 100) {
          window.clearInterval(slideTimer);
        }
      }, 25);
    }
  };

  const setDate = (date) => {
    setSelected("");
    setTimeout(() => {
      setSelected(date);
    }, 200);
  };

  useEffect(() => {
    if (selected) {
      const element = datesRef.current.querySelector(
        `[data-date="${selected}"]`
      );
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [selected]);

  return (
    <div className="scroll-hidden flex items-center flex-shrink-0 -mt-4">
      <button
        className={`flex-shrink-0 flex w-8 h-8 m-2 bg-${
          style.accentColor || "orange"
        } text-white font-bold items-center justify-center rounded-full shadow-md outline-none hover:shadow-xl`}
        onClick={handleLeftButton}
      >
        <svg
          className="w-6 h-6 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M14.71 6.71c-.39-.39-1.02-.39-1.41 0L8.71 11.3c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L10.83 12l3.88-3.88c.39-.39.38-1.03 0-1.41z" />
        </svg>
      </button>
      <ul
        className="flex-grow flex items-center flex-no-wrap overflow-x-auto h-20"
        ref={datesRef}
      >
        {dates.map((date) => (
          <li
            key={date}
            className={`cursor-pointer w-auto whitespace-no-wrap mx-2 px-4 py-3 font-medium rounded-lg hover:shadow-lg ${
              selected === date
                ? `text-white bg-${style.accentColor || "orange"} shadow-lg`
                : "text-gray-800 bg-gray-100 shadow-md"
            }`}
            onClick={setDate.bind(null, date)}
            data-date={date}
          >
            {date}
          </li>
        ))}
      </ul>
      <button
        className={`flex-shrink-0 flex w-8 h-8 m-2 bg-${
          style.accentColor || "orange"
        } text-white font-bold items-center justify-center rounded-full outline-none shadow-md hover:shadow-xl`}
        onClick={handleRightButton}
      >
        <svg
          className="w-6 h-6 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
        </svg>
      </button>
    </div>
  );
};

export default Dates;
