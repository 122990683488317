import React from "react";

import pageData from "./page.json";
import Page from "../../components/ui/Page";

const Timeline = () => {
  const { subheader, widgets } = pageData;

  return <Page subheader={subheader} widgets={widgets} />;
};

export default Timeline;
