import React from "react";

import Modal from "../../../../../ui/Modal";
// import leftArrow from "../../../../../../assets/arrow-left.svg"
// import rightArrow from "../../../../../../assets/arrow-right.svg"

const PhotoModal = ({ close, photo }) => {
    // const [imageIndex, setImageIndex] = useState(0);
    return (
        <Modal backgroundTheme="light" visible close={close}>

            <section className="flex flex-col items-center bg-white  border-orange rounded-lg overflow-hidden">
                <div style={{ width: "60vw", height: "40vw" }}>
                    {/* {photo.length > 1 &&
                        <div
                            className=" arrow-container flex flex-row w-full absolute p-2 h-0"
                            style={{ justifyContent: 'space-between', zIndex: 1, marginTop: '18vw' }}
                        >
                            <span
                                className="rounded-full h-10 w-10 flex items-center justify-center bg-white bg-opacity-50 text-white cursor-pointer"
                                onClick={() => { setImageIndex(imageIndex >= 1 ? imageIndex - 1 : photo && photo.length - 1); }}
                            >
                                <img className="w-12 h-12 text-white" src={leftArrow} alt="" />
                            </span>
                            <span
                                className="rounded-full h-10 w-10 flex items-center justify-center bg-white bg-opacity-50 text-white cursor-pointer"
                                onClick={() => { setImageIndex(imageIndex < photo && photo.length - 1 ? imageIndex + 1 : 0); }}
                            >
                                <img className="w-12 h-12 text-white" src={rightArrow} alt="" />
                            </span>
                        </div>} */}
                    {photo &&
                        <div
                            className='absolute flex flex-col justify-end w-full h-full bg-center bg-cover rounded-md rounded-md-b'
                            style={{ backgroundImage: `url(${photo[0].image})`}}
                        >

                            <div className="p-2 zo-gradient-black-bottom rounded-md">
                                <div className="font-bold text-white">{photo[0].caption}</div>
                                {/* <span className="text-xs text-gray-100">
                                    {photo[0].destination}
                                </span> */}
                            </div>
                        </div>
                    }
                </div>
            </section>
        </Modal>
    );
};

export default PhotoModal;
