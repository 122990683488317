import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import Flex from "../../../../components/structure/Flex";
import RoomCard from "../../../../components/ui/RoomCard";
import useApi from "../../../../hooks/useApi";
import loadingZo from "./../../../../assets/lottie/loading-zo.json";

interface ActiveRoomsListProps {
  close?: () => void;
}

const ActiveRoomsList: React.FC<ActiveRoomsListProps> = ({ close }) => {
  const privateApi = useApi("PROFILE_LOBBY_ONLINE");
  const location = useLocation();
  const history = useHistory();

  const [rooms, setRooms] = useState<Room[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const isCurrentRoom = (subdomain: string) => {
    return location.pathname.includes(`@${subdomain}`);
  };

  useEffect(() => {
    if (privateApi) {
      setLoading(true);
      privateApi
        .get("")
        .then((res) => {
          setRooms(
            res.data.profiles.filter(
              (f: any) => f.members_count > 0 && !isCurrentRoom(f.subdomain)
            ) || []
          );
          setLoading(false);
        })
        .catch(console.log);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateApi]);

  const handleCardClick = (subdomain: string) => {
    history.push(`/@${subdomain}/`);
    if (close) {
      close();
    }
  };

  return (
    <section className="flex-1 px-4 overflow-y-auto overflow-x-hidden w-full max-w-lg mx-auto">
      {loading ? (
        <Flex col items="center" className="pt-12 px-4">
          <Lottie
            animationData={loadingZo}
            loop
            autoPlay
            className="w-16 h-16"
          />
          <span className="text-zo-mid-grey mt-4 font-bold">
            Looking for rooms
          </span>
        </Flex>
      ) : rooms.length > 0 ? (
        <>
          <h2 className="font-semibold">Active Rooms</h2>
          {rooms
            .sort((a, b) => b.members_count - a.members_count)
            .map((r) => (
              <RoomCard
                key={r.code}
                data={r}
                onClick={handleCardClick.bind(null, r.subdomain)}
              />
            ))}
        </>
      ) : (
        <div className="text-zo-mid-grey font-bold text-center py-12 w-full">
          No rooms active right now.
          <br />
          Start yours now!
        </div>
      )}
    </section>
  );
};

export default ActiveRoomsList;
