import React, { useEffect, useMemo, useState } from "react";
import Flex from "../../components/structure/Flex";
import CitizensList from "../../components/widgets/CitizensList";
import { useProfile, useRoom, useSeed } from "../../contexts";
import { getBackgroundFromKey, getWidget } from "../../utils/room";
import AvatarsDisplay from "./components/AvatarsDisplay";
import Chat from "./components/Chat";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Widgets from "./components/Widgets";

interface RoomProps {}

const Room: React.FC<RoomProps> = () => {
  const { backgrounds } = useSeed();
  const { widgets, room } = useRoom();
  const { profile } = useProfile();

  const [view, setView] = useState<"chat" | "widget">("widget");
  const [unreadChatCount, setUnreadChatCount] = useState<number>(0);

  const backgroundKey = useMemo(() => room?.background_key, [room]);
  const speakability = useMemo(() => room?.speakability, [room]);
  const isHost = useMemo(
    () => room?.nickname === profile?.nickname,
    [room, profile]
  );
  const hasChat = useMemo(
    () => !!getWidget(widgets, "chat")?.data.chatVisible,
    [widgets]
  );

  const toggleView = () => {
    setView((v) => (v === "widget" ? "chat" : "widget"));
  };

  useEffect(() => {
    if (!hasChat) {
      setView("widget");
    }
  }, [hasChat]);

  return useMemo(
    () =>
      window.innerWidth < 768 ? (
        <Flex
          col
          className="p-4 bg-zo-off-white h-full w-full bg-cover bg-center fade-in"
          style={{
            backgroundImage: `url(${getBackgroundFromKey(
              backgroundKey,
              backgrounds
            )})`,
          }}
        >
          <AvatarsDisplay />
          <Header />
          <div className="flex-1 overflow-hidden relative scroll-hidden">
            <Widgets active={view === "widget"} />
            {hasChat && (
              <Chat
                active={view === "chat"}
                setUnreadChatCount={setUnreadChatCount}
              />
            )}
          </div>
          <Footer
            unreadChatCount={unreadChatCount}
            view={view}
            hasChat={hasChat}
            toggleView={toggleView}
            showHand={!isHost && speakability === 3}
          />
        </Flex>
      ) : (
        <Flex
          className="p-4 bg-zo-off-white h-full w-full bg-cover bg-center fade-in"
          style={{
            backgroundImage: `url(${getBackgroundFromKey(
              backgroundKey,
              backgrounds
            )})`,
          }}
        >
          <AvatarsDisplay />
          <Flex col className="relative w-108 flex-shrink-0 h-full">
            <Header />
            {hasChat ? (
              <Chat active setUnreadChatCount={setUnreadChatCount} />
            ) : (
              <CitizensList expand />
            )}
          </Flex>
          <div className="flex-1 overflow-hidden relative scroll-hidden ml-2 flex flex-col">
            <Widgets active />
            <Footer
              unreadChatCount={unreadChatCount}
              view={view}
              hasChat={false}
              toggleView={toggleView}
              showHand={!isHost && speakability === 3}
            />
          </div>
        </Flex>
      ),
    [
      backgroundKey,
      backgrounds,
      view,
      hasChat,
      unreadChatCount,
      isHost,
      speakability,
    ]
  );
};

export default Room;
