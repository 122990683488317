import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReportUserModal from "../../../../components/commons/ReportAbuseModal";
import useApi from "../../hooks/useApi";
import mafiaScreen from "./assets/mafia-bg.jpg";
import GameProvider from "./contexts/game/provider";
import RoomContext from "./contexts/room";
import SocketProvider from "./contexts/socket/provider";
import Scenes from "./scenes";

function adjustHTMLFontSize() {
  if (window.innerHeight < window.innerWidth) {
    setTimeout(() => {
      document.getElementsByTagName("html")[0].style.fontSize =
        Math.round(window.innerHeight / (100 / 2.2)) + "px";
      window._orientation = "landscape";
    }, 200);
  } else {
    setTimeout(() => {
      document.getElementsByTagName("html")[0].style.fontSize = "12px";
      window._orientation = "portrait";
    }, 200);
  }
}

const fetchRoomDetails = async (api, roomCode) => {
  try {
    const response = await api.get(`/${roomCode.toUpperCase()}/`);
    console.log(response);
    if (response.status === 200) {
      return response.data.room;
    } else {
      console.log("Unable to join");
      throw new Error("");
    }
  } catch (error) {
    throw new Error(error);
  }
};

const flattenRoomObject = (roomObject) => {
  const data = { ...roomObject.data };
  delete roomObject.data;
  return { ...roomObject, ...data };
};

const Mafia = () => {
  const params = useParams();
  const api = useApi("GAMES_MAFIA_ROOM");

  const [room, setRoom] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);

  const [reportAbuseVisible, setReportAbuseVisible] = useState(false);

  useEffect(() => {
    if (params.id && api) {
      fetchRoomDetails(api, params.id)
        .then((room) => {
          setRoom(flattenRoomObject(room));
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setError(true);
          setLoading(false);
        });
    }
  }, [params.id, api]);

  useEffect(() => {
    adjustHTMLFontSize();
    window.addEventListener("orientationchange", adjustHTMLFontSize);

    return () => {
      window.removeEventListener("orientationchange", adjustHTMLFontSize);
    };
  }, []);

  return (
    <main
      className="flex justify-center w-full h-screen overflow-hidden text-white bg-cover bg-no-repeat bg-center"
      style={{ backgroundImage: `url(${mafiaScreen})` }}
    >
      <div
        className="flex justify-center w-full h-screen overflow-hidden text-white bg-blur-no-fallback"
        style={{ backgroundColor: "rgba(18,20,30, 0.9)" }}
      >
        <div className="landscape:hidden fixed top-0 w-full h-full flex items-center justify-center bg-mafia-background">
          <div className="bg-mafia-primary flex items-center border-t-4 p-2 text-center border-mafia-secondary justify-center w-84 h-56 text-white text-xl font-bold rounded-lg shadow-lg">
            <p>Rotate to play</p>
          </div>
        </div>
        <div className="portrait:hidden w-full h-full flex justify-center">
          {isLoading ? (
            <div className="fixed top-0 w-full h-full flex items-center justify-center bg-mafia-background">
              <div className="bg-mafia-primary flex items-center border-t-4 p-2 text-center border-mafia-secondary justify-center w-84 h-56 text-white text-xl font-bold rounded-lg shadow-lg">
                <p>Finding room</p>
              </div>
            </div>
          ) : hasError ? (
            <div className="fixed top-0 w-full h-full flex items-center justify-center bg-mafia-background">
              <div className="bg-mafia-primary flex items-center border-t-4 p-2 text-center border-mafia-secondary justify-center w-84 h-56 text-white text-xl font-bold rounded-lg shadow-lg">
                <p>Problem in finding the room</p>
              </div>
            </div>
          ) : room.status === "expired" ? (
            <div className="fixed top-0 w-full h-full flex items-center justify-center bg-mafia-background">
              <div className="bg-mafia-primary flex items-center border-t-4 p-2 text-center border-mafia-secondary justify-center w-84 h-56 text-white text-xl font-bold rounded-lg shadow-lg">
                <p>Room has expired due to inactivity.</p>
              </div>
            </div>
          ) : (
            <RoomContext.Provider value={{ room, setRoom }}>
              <GameProvider>
                <SocketProvider>
                  <Scenes />
                </SocketProvider>
              </GameProvider>
            </RoomContext.Provider>
          )}
        </div>
      </div>
      <button
        className="focus:outline-none text-white leading-none bg-mafia-primary fixed right-0 rounded-l-lg p-2 font-medium text-xs whitespace-no-wrap disabled:cursor-not-allowed"
        style={{ top: "50%" }}
        onClick={setReportAbuseVisible.bind(null, true)}
      >
        Report User
      </button>
      {reportAbuseVisible && (
        <ReportUserModal close={setReportAbuseVisible.bind(null, false)} />
      )}
    </main>
  );
};

export default Mafia;
