import React from "react";
import Article from "../../../../../ui/Article";
import Modal from "../../../../../ui/Modal";
import { useSeed } from "../../../contexts/seed";

const BlogArticlesModal = ({ close }) => {
  const { seedData } = useSeed();
  return (
    <Modal visible close={close} hideCloseButton>
      <section
        className="relative rounded-lg flex bg-white overflow-y-auto overflow-x-hidden"
        style={{ width: "calc(100vw - 2rem)", height: "calc(100vh - 2rem)" }}
      >
        <svg
          className="absolute cursor-pointer z-20 w-6 h-6 m-8 top-0 right-0 fill-current text-white"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          onClick={close}
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
        </svg>
        <Article
          coverImage={
            seedData.images
              ? `${seedData.images[0].image}?w=500&h=500&c=80`
              : "https://img.cdn.zostel.com/blog_photo/Kukargundha_to_Palachak_2_1.jpg?w=800&h=500&c=80"
          }
          title={`About ${seedData.name}`}
          graphic_left={seedData.graphic_left}
          graphic_right={seedData.graphic_right}
          background={seedData.background}
        >
          <div dangerouslySetInnerHTML={{ __html: seedData.description }}></div>
          <div className="flex justify-center my-6">
            <button
              className="bg-orange text-white px-5 py-3 text-lg uppercase tracking-wide font-semibold rounded-lg shadow-md hover:shadow-xl"
              onClick={() => {
                window.open(
                  `https://www.zostel.com/zostel/${seedData.slug}`,
                  "_blank",
                  "",
                  false
                );
              }}
            >
              Go to Page
            </button>
          </div>
        </Article>
      </section>
    </Modal>
  );
};

export default BlogArticlesModal;
