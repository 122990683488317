import React, { useEffect } from "react";
import Modal from "../../../../../../../../components/ui/Modal";
import endedMafia from "./../../../../assets/ended_mafia.svg";
import endedVillage from "./../../../../assets/ended_village.svg";

const EndedModal = ({ visible, close, type }) => {
  useEffect(() => {
    let mounted = true;
    if (visible) {
      setTimeout(() => {
        if (mounted) {
          close();
        }
      }, 5000);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal visible={visible} close={close}>
      <div
        className="relative z-40 rounded-lg overflow-hidden shadow-lg mb-10 bg-white"
        style={{
          width: "32rem",
          height: "23rem",
          backgroundImage: `url(${
            type === "ended_mafia" ? endedMafia : endedVillage
          })`,
        }}
      ></div>
    </Modal>
  );
};

export default EndedModal;
