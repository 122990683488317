import React, { useEffect, useState } from "react";
import {
  formattedSeconds,
  getMinutes,
  getSeconds,
} from "./../../../../../games/Mafia/utils";

const Timer = ({ eta }) => {
  const [secondsLeft, setSecondsLeft] = useState(0);
  useEffect(() => {
    let timer;
    if (eta) {
      timer = setInterval(() => {
        const timeElapsed = Math.round((eta - +new Date()) / 1000);
        setSecondsLeft(timeElapsed);
      }, 1000);
    } else {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eta]);

  return (
    <strong>
      {formattedSeconds(getMinutes(secondsLeft))} :{" "}
      {formattedSeconds(getSeconds(secondsLeft))}
    </strong>
  );
};

export default Timer;
