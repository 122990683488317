import React from "react";
import { useSeed } from "../../../contexts/seed";

const SquareIcon = ({
  icon,
  hoverText,
  onClick,
  backgroundColor,
  textInset = false,
  additionalClasses,
}) => {
  const { style } = useSeed();

  return (
    <li
      className={`relative flex flex-col flex-shrink-0 items-center ml-2 group w-16 h-16 rounded-lg ${
        onClick
          ? "cursor-pointer shadow-md hover:shadow-xl"
          : "shadow-sm bg-gray-100"
      } ${additionalClasses} bg-${
        style.primaryColor ? style.primaryColor : "white"
      }`}
      onClick={onClick}
      style={{ backgroundColor }}
    >
      <img
        className={`w-full h-full p-4 ${textInset === true ? "pt-1" : ""}`}
        src={icon}
        alt=""
      />
      {textInset === false ? (
        <span className="absolute bottom-0 inline-block p-1 -mb-6 text-xs whitespace-no-wrap opacity-0 pointer-events-none group-hover:opacity-100">
          {hoverText}
        </span>
      ) : (
        <span className="text-xs whitespace-no-wrap -mt-5">{hoverText}</span>
      )}
    </li>
  );
};

export default SquareIcon;
