import React, { useContext, useState } from "react";

import { ProfileContext } from "../../../../../contexts/contexts";
import useApi from "../../../../../hooks/useApi";
import { useResponseFlash } from "../../../../../apps/Zo/components/hooks";

const NicknameCard = () => {
  const api = useApi("profile", true);
  const { updateProfile } = useContext(ProfileContext);
  const { response, setResponse } = useResponseFlash();

  const [nickname, setNickname] = useState("");

  const updateNickname = async () => {
    if (nickname.trim().length && api) {
      if (!/^[a-zA-Z0-9-]{6,32}$/.test(nickname)) {
        setResponse(
          "Only [A-Z, a-z, 0-9 or -] and 6 to 32 characters in length allowed"
        );
        return;
      }
      try {
        const data = {
          nickname: nickname.trim(),
        };
        const response = await api.post(
          `/profile/api/v1/me/`,
          JSON.stringify(data)
        );
        console.log(response);
        if (response.status === 200) {
          updateProfile(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="w-2/3">
      <div className="rounded-lg overflow-hidden bg-mafia-background shadow-sm w-full h-full px-4 pt-2">
        {/* <div className="font-bold text-center">Login to join</div> */}
        <div className="flex items-center mt-4">
          <input
            type="text"
            placeholder="Choose a Nickname"
            className="p-2 border rounded-lg mr-2 flex-grow border text-white bg-mafia-primary"
            value={nickname}
            onChange={(e) => {
              setNickname(e.target.value);
            }}
          />
          <button
            className="bg-orange text-white py-2 px-4 rounded-lg flex-shrink-0"
            onClick={updateNickname}
          >
            Set
          </button>
        </div>
        <div className="h-6 flex items-center justify-center font-semibold text-xs text-orange">
          {response}
        </div>
      </div>
    </div>
  );
};

export default NicknameCard;
