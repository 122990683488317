// import bg from "./../../../assets/bg.png";
import liveIcon from "./../../../assets/mafia/mafia-live-players.svg";
import totalGamesIcon from "./../../../assets/mafia/mafia-total-games.svg";
import liveGamesIcon from "./../../../assets/mafia/mafia-live-games.svg";

const page = {
  layout: {
    header: {
      title: "ZO MAFIA",
      stats: [
        {
          type: "about",
        },
        {
          type: "count-stat",
          title: "Total Games",
          dataKey: "total_count",
          icon: totalGamesIcon,
        },
        {
          type: "count-stat",
          title: "Live Games",
          dataKey: "live_games",
          icon: liveGamesIcon,
        },
        {
          type: "count-stat",
          title: "Live Players",
          dataKey: "live_souls",
          icon: liveIcon,
        },
        {
          type: "rating",
        },
      ],
    },
  },
  style: {
    // backgroundPattern: bg,
    backgroundColor: "mafia-background",
    primaryColor: "mafia-primary",
    secondaryColor: "mafia-secondary",
    textPrimaryColor: "white",
    textSecondaryColor: "mafia-secondary",
    accentColor: "mafia-secondary",
  },
};

export default page;
