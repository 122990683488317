import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Portal from "../../ui/Portal";

const Notifications = ({ notificationList, autoDelete, dismissTime }) => {
  const [list, setList] = useState(notificationList);

  useEffect(() => {
    setList([...notificationList]);
  }, [notificationList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && notificationList.length && list.length) {
        deleteToast(notificationList[0].id);
      }
    }, dismissTime);

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line
  }, [notificationList, autoDelete, dismissTime, list]);

  const deleteToast = (id) => {
    const listItemIndex = list.findIndex((e) => e.id === id);
    const notificationListItem = notificationList.findIndex((e) => e.id === id);
    list.splice(listItemIndex, 1);
    notificationList.splice(notificationListItem, 1);
    setList([...list]);
  };

  return (
    <Portal>
      <aside className="fixed z-50 bottom-0 left-0 mb-24 ml-4 fadeInRight animation-delay-4000">
        {list.map((toast, i) => (
          <div
            key={i}
            className="fadeInRight transition-all duration-300 ease-in-out rounded-lg shadow-xl overflow-hidden mb-2 w-64 zo-bg-white-transparent bg-blur cursor-default border-orange border-l-8 p-2"
            onClick={() => deleteToast(toast.id)}
          >
            <p className="font-semibold text-sm">{toast.title}</p>
            <p className="text-xs mt-1">{toast.description}</p>
          </div>
        ))}
      </aside>
    </Portal>
  );
};

Notifications.propTypes = {
  notificationList: PropTypes.array.isRequired,
  autoDelete: PropTypes.bool,
  dismissTime: PropTypes.number,
};

export default Notifications;
