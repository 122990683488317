import React from "react";

interface SwitchToggleProps {
  name: string;
  value: boolean;
  onChange: (toggle: boolean) => void;
}

const SwitchToggle: React.FC<SwitchToggleProps> = ({
  name,
  value,
  onChange,
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e.target.checked);
  };

  return (
    <>
      <label className="toggle" htmlFor={`switch-toggle-${name}`}>
        <input
          className="toggle__input"
          checked={value}
          onChange={handleChange}
          name=""
          type="checkbox"
          id={`switch-toggle-${name}`}
        />
        <div className="toggle__fill"></div>
      </label>
    </>
  );
};

export default SwitchToggle;
