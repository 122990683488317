import React, { useContext } from "react";

import RoomTimer from "../../../../Header/components/RoomTimer";
import RoomContext from "../../../../../contexts/room";

const Header = () => {
  const { room } = useContext(RoomContext);

  return (
    <header className="w-full flex items-start flex-grow-0">
      <div className="w-1/6">
        <RoomTimer />
      </div>
      <div className="flex flex-col items-center justify-center w-4/6">
        <div className="text-white font-bold text-xl mt-2 uppercase tracking-wide">
          {room.name}
        </div>
      </div>
    </header>
  );
};

export default Header;
