import React, { useContext, useMemo } from "react";

import RTCContext from "../../../contexts/rtcV2";

const RTCControlButton = ({ isPartyTalking, className, large }) => {
  const { isActive, isSelfMute, setIsSelfMute } = useContext(RTCContext);

  const dimension = useMemo(() => {
    if (large) {
      return "w-10 h-10";
    } else {
      return "w-5 h-5";
    }
  }, [large]);

  return useMemo(
    () =>
      isActive ? (
        !isPartyTalking ? (
          <button
            className={`focus:outline-none relative flex flex-col items-center rounded-full p-2 w-content h-content group ${
              className ? className : "bg-orange text-white"
            }`}
            onClick={() => {
              setIsSelfMute((v) => !v);
            }}
          >
            {isSelfMute ? (
              <svg
                className={`fill-current ${dimension} `}
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
              >
                <path d="M15 10.6V5c0-1.66-1.34-3-3-3-1.54 0-2.79 1.16-2.96 2.65L15 10.6zm3.08.4c-.41 0-.77.3-.83.71-.05.32-.12.64-.22.93l1.27 1.27c.3-.6.52-1.25.63-1.94.07-.51-.33-.97-.85-.97zM3.71 3.56c-.39.39-.39 1.02 0 1.41L9 10.27v.43c0 1.19.6 2.32 1.63 2.91.75.43 1.41.44 2.02.31l1.66 1.66c-.71.33-1.5.52-2.31.52-2.54 0-4.88-1.77-5.25-4.39-.06-.41-.42-.71-.83-.71-.52 0-.92.46-.85.97.46 2.96 2.96 5.3 5.93 5.75V20c0 .55.45 1 1 1s1-.45 1-1v-2.28c.91-.13 1.77-.45 2.55-.9l3.49 3.49c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L5.12 3.56c-.39-.39-1.02-.39-1.41 0z" />
              </svg>
            ) : (
              <svg
                className={`fill-current ${dimension} `}
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
              >
                <path d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm-1-9c0-.55.45-1 1-1s1 .45 1 1v6c0 .55-.45 1-1 1s-1-.45-1-1V5zm6.91 6c-.49 0-.9.36-.98.85C16.52 14.2 14.47 16 12 16s-4.52-1.8-4.93-4.15c-.08-.49-.49-.85-.98-.85-.61 0-1.09.54-1 1.14.49 3 2.89 5.35 5.91 5.78V20c0 .55.45 1 1 1s1-.45 1-1v-2.08c3.02-.43 5.42-2.78 5.91-5.78.1-.6-.39-1.14-1-1.14z" />
              </svg>
            )}
            {!large && (
              <span className="absolute top-full z-80 p-1 mt-1 rounded-lg shadow-md leading-none bg-gray-700 text-white text-xs whitespace-no-wrap opacity-0 pointer-events-none group-hover:opacity-100">
                {isSelfMute ? "Unmute" : "Mute"} yourself
              </span>
            )}
          </button>
        ) : (
          <div className="rounded-full p-2 w-content h-content bg-orange">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enableBackground="new 0 0 24 24"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              className={`fill-current ${dimension} text-white`}
            >
              <g>
                <rect fill="none" height="24" width="24" />
              </g>
              <path d="M18,12L18,12c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1h-2C18.45,11,18,11.45,18,12z" />
              <path d="M16.59,16.82c-0.33,0.44-0.24,1.05,0.2,1.37c0.53,0.39,1.09,0.81,1.62,1.21c0.44,0.33,1.06,0.24,1.38-0.2 c0-0.01,0.01-0.01,0.01-0.02c0.33-0.44,0.24-1.06-0.2-1.38c-0.53-0.4-1.09-0.82-1.61-1.21c-0.44-0.33-1.06-0.23-1.39,0.21 C16.6,16.81,16.59,16.82,16.59,16.82z" />
              <path d="M19.81,4.81c0-0.01-0.01-0.01-0.01-0.02c-0.33-0.44-0.95-0.53-1.38-0.2c-0.53,0.4-1.1,0.82-1.62,1.22 c-0.44,0.33-0.52,0.95-0.19,1.38c0,0.01,0.01,0.01,0.01,0.02c0.33,0.44,0.94,0.53,1.38,0.2c0.53-0.39,1.09-0.82,1.62-1.22 C20.05,5.87,20.13,5.25,19.81,4.81z" />
              <path d="M8,9H4c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h1v3c0,0.55,0.45,1,1,1h0c0.55,0,1-0.45,1-1v-3h1l5,3V6L8,9z" />
              <path d="M15.5,12c0-1.33-0.58-2.53-1.5-3.35v6.69C14.92,14.53,15.5,13.33,15.5,12z" />
            </svg>
          </div>
        )
      ) : null,
    [
      className,
      dimension,
      isActive,
      isPartyTalking,
      isSelfMute,
      large,
      setIsSelfMute,
    ]
  );
};

export default RTCControlButton;
