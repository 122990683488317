import React, { useContext } from "react";

import footer2 from "./../../../../assets/footer-2.svg";
import ProfileContext from "./../../../contexts/profile";
import Portal from "./../../../ui/Portal";

const PaymentCompleteModal = ({ close }) => {
  const { profile, updateProfile } = useContext(ProfileContext);

  const handleClose = () => {
    updateProfile({ status: "premium" });
    close();
  };

  return (
    <Portal>
      <section className="w-screen h-screen fixed inset-0 z-90 bg-blur flex items-center justify-center opacity-100 pointer-events-auto">
        <div className="relative border-orange border-t-4 bg-white p-6 pb-0 rounded-lg shadow-2xl h-120 w-144 flex flex-col items-center overflow-hidden">
          <header className="font-black text-3xl fadeInUp animation-delay-100">
            To infinity and beyond,{" "}
            <span className="text-orange">{profile && profile.first_name}</span>
            !
          </header>
          <section className="mb-4 flex-col flex items-center">
            <div className="text text-center w-5/6 mt-4 mb-6 fadeInUp animation-delay-300">
              It’s time to cheer, as you are now one of the first one thousand
              members of Zo World! So, go on and have fun exploring.
              <br />
              <br />
              <span className="font-semibold">
                Your travel gun is now active. Use this to roam around in the Zo
                World.
              </span>
            </div>
            <button
              className="bg-orange px-6 py-3 rounded-lg shadow-md hover:shadow-lg focus:outline-none font-medium text-white fadeInUp animation-delay-500"
              onClick={handleClose}
            >
              Yay!
            </button>
          </section>

          <footer
            className="bg-contain bg-bottom bg-no-repeat h-48 flex-shrink-0 w-full fadeInUp flex-grow"
            style={{ backgroundImage: `url(${footer2})` }}
          ></footer>
        </div>
      </section>
    </Portal>
  );
};

export default PaymentCompleteModal;
