import React, { useEffect, useState } from "react";
import { FrontHand, Mic } from "../../../../../../assets/icons";
import Flex from "../../../../../../components/structure/Flex";
import { useProfile, useRoom, useRTC } from "../../../../../../contexts";
import UnmuteRequestModal from "../../../../../../modals/UnmuteRequest";

interface RTCButtonProps {
  className?: string;
}

const RTCButton: React.FC<RTCButtonProps> = ({ className }) => {
  const {
    setRoomCode,
    setUserCode,
    setRecordName,
    isActive,
    isSelfMute,
    setIsSelfMute,
    setDisplayName,
  } = useRTC();
  const { profile } = useProfile();
  const { room, message, sendMessage } = useRoom();

  const [unmuteModalVisible, setUnmuteModalVisibility] =
    useState<boolean>(false);

  useEffect(() => {
    setIsSelfMute(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleMute = () => {
    if (isSelfMute) {
      sendMessage({
        action: "raise_hand",
        payload: {
          code: profile?.code,
          nickname: profile?.nickname,
        },
      });
    } else {
      setIsSelfMute((m) => !m);
    }
  };

  const unraiseHand = () => {
    sendMessage({
      action: "unraise_hand",
      payload: {
        code: profile?.code,
        nickname: profile?.nickname,
      },
    });
  };

  const closeUnmute = () => {
    setUnmuteModalVisibility(false);
    unraiseHand();
  };

  useEffect(() => {
    if (room && profile) {
      if (room.rtcAllowed) {
        setRoomCode(room.code);
        setUserCode(profile?.code);
        setDisplayName(profile.nickname);
        setRecordName(
          process.env.NODE_ENV === "production" ? `room/${room.code}` : false
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, profile]);

  useEffect(() => {
    if (message) {
      if (
        message.action === "unmute_request" &&
        message.payload.code === profile?.code
      ) {
        console.log("ss");
        setUnmuteModalVisibility(true);
      } else if (
        message.action === "member_mute" &&
        message.payload.code === profile?.code
      ) {
        setIsSelfMute(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return isActive ? (
    <Flex
      items="center"
      className={`${className} relative w-12 h-12 md:w-20 md:h-20 md:pointer-events-auto`}
    >
      <button
        className="absolute flex p-3 md:p-6 focus:outline-none focus:shadow-outline items-center justify-center bg-orange text-white rounded-full shadow-2xl"
        onClick={toggleMute}
      >
        {isSelfMute ? (
          <FrontHand className="w-6 h-6 md:w-8 md:h-8 fill-current" />
        ) : (
          <Mic className="w-6 h-6 md:w-8 md:h-8 fill-current" />
        )}
      </button>
      {unmuteModalVisible && <UnmuteRequestModal close={closeUnmute} />}
    </Flex>
  ) : (
    <Flex
      items="center"
      className="md:text-lg text-xxs bg-orange relative z-10 text-white font-bold p-2 md:p-3 rounded-full"
    >
      Connecting audio ...
    </Flex>
  );
};

export default RTCButton;
