import React from "react";

import populationIcon from "./../../../../../assets/people.svg";
import dollarIcon from "./../../../../../assets/dollar.svg";
import calendarIcon from "./../../../../../assets/calendar.svg";
import ZoHeader from "../../../../ui/ZoHeader";
import ZoHeaderStat from "../../../../ui/ZoHeaderStat";
import ZoHeaderUser from "../../../../ui/ZoHeaderUser";

const Header = ({ populationMetrics, humanize }) => {
  return (
    <ZoHeader title="India" className="border-green-500">
      <div className="flex items-center ml-16">
        <ZoHeaderStat
          icon={populationIcon}
          title="Population"
          value={<span className='ml-2' >{populationMetrics && humanize(populationMetrics.country_citizen)}</span>}
          onClick={() => window.open("https://zo.xyz/population")}
        />
        {/* <ZoHeaderStat icon={indiaFlag} title="Countries" value="1" /> */}
      </div>
      <div className="flex items-center">
        <ZoHeaderStat
          value={"Thursday, 9th Apr"}
          icon={calendarIcon}
          title={"Today"}
          onClick={() => window.open("https://zo.xyz/timeline") }
        />
      </div>
      <div className="flex items-center">
        <ZoHeaderStat value={"270m"} icon={dollarIcon} title={"Economy"} />
        <ZoHeaderStat
          icon={<span className="font-black text-red-500">LIVE</span>}
          value={"242,213"}
          title={"Online Citizens"}
          onClick={() => window.open("https://zo.xyz/leaderboard")}
        />
        <ZoHeaderUser />
      </div>
    </ZoHeader>
  );
};

export default Header;
