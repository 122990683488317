import { useEffect, useRef, useState } from "react";
import { SOCKET_URL } from "../utils/constants";

const useJanusSocket = (name) => {
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState(null);
  const messageBuffer = useRef([]);

  useEffect(() => {
    if (socket === null) {
      console.log(`[${name}_SOCKET] Creating Connection...`);
      const _socket = new WebSocket(SOCKET_URL, "janus-protocol");
      _socket.onmessage = function (e) {
        const data = JSON.parse(e.data);
        setMessage(data);
        console.log(`[${name}_SOCKET] Message received.`, data);
      };
      _socket.onclose = (e) => {
        console.log(`[${name}_SOCKET] Socket closed`);
        setSocket(null);
      };
      _socket.onerror = function (e) {
        console.log(`[${name}_SOCKET] Socket error`, e);
        setSocket(null);
      };
      _socket.onopen = (e) => {
        console.log(`[${name}_SOCKET] Connection established.`);
        if (messageBuffer.current.length) {
          messageBuffer.current.forEach((m) => {
            _socket.send(JSON.stringify(m));
          });
          messageBuffer.current = [];
        }
      };
      setSocket(_socket);
    }
    return () => {
      if (socket) {
        console.log("roomleave");
        setTimeout(() => {
          socket.close();
        }, 1000);
      }
    };
  }, [socket, name]);

  const sendSocketMessage = (message) => {
    if (socket && socket.readyState === 1) {
      socket.send(JSON.stringify(message));
    } else {
      console.log(`[${name}_SOCKET] Message not sent`, message, socket);
      messageBuffer.current.push(message);
    }
  };

  return {
    janusSocket: socket,
    sendSocketMessage,
    janusMessage: message,
  };
};

export default useJanusSocket;
