import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import withMemo from "../../../utils/withMemo";
import CheckoutForm from "./Components/CheckoutForm";

const Stripe = withMemo(({ stripeKey, priceId, back, showPaymentForm }) => {
  const stripePromise = loadStripe(stripeKey);
  console.log("STRIPE", stripeKey);
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        priceId={priceId}
        back={back}
        showPaymentForm={showPaymentForm}
      />
    </Elements>
  );
}, []);

export default Stripe;
