import React, { useEffect } from "react";

import nightResultMafia from "./../../../../assets/night_results_mafia.svg";
import nightResultHealer from "./../../../../assets/night_results_healer.svg";
import Modal from "../../../../../../ui/Modal";

const NighResultModal = ({ visible, close, player }) => {
  useEffect(() => {
    let mounted = true;
    if (visible) {
      setTimeout(() => {
        if (mounted) {
          close();
        }
      }, 5000);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal visible={visible} close={close}>
      {player ? (
        <div
          className="bg-gray-200 relative z-40 rounded-lg overflow-hidden shadow-lg mb-10 bg-white bg-no-repeat bg-cover bg-center flex flex-col items-center justify-between"
          style={{
            width: "38.8rem",
            height: "35.2rem",
            backgroundImage: `url(${nightResultMafia})`,
          }}
        >
          <div className="bg-mafia-primary mt-4 w-3/4 border-t-2 border-mafia-secondary p-4 rounded-lg shadow-lg flex flex-col items-center">
            <div className="font-bold uppercase tracking-normal text-mafia-secondary text-2xl">
              Midnight Mischief!
            </div>
            <div className="text-white text-lg text-center">
              A blameless villager got sacrificed and the Mafia is to blame.
            </div>
          </div>
          <div className="bg-mafia-primary mb-4 w-3/4 border-t-2 border-mafia-secondary p-4 rounded-lg shadow-lg flex flex-col items-center">
            <div className="text-white text-lg text-center">
              <span className="text-mafia-secondary font-bold text-2xl">
                {player.nickname}
              </span>{" "}
              walks as a ghost amongst us.
            </div>
          </div>
        </div>
      ) : (
        <div
          className="bg-gray-200 relative z-40 rounded-lg overflow-hidden shadow-lg mb-10 bg-white bg-no-repeat bg-cover bg-center flex flex-col items-center justify-between"
          style={{
            width: "38.8rem",
            height: "35.2rem",
            backgroundImage: `url(${nightResultHealer})`,
          }}
        >
          <div className="bg-mafia-primary mt-4 w-3/4 border-t-2 border-mafia-secondary p-4 rounded-lg shadow-lg flex flex-col items-center">
            <div className="font-bold uppercase tracking-normal text-mafia-secondary text-2xl">
              Midnight Mischief!
            </div>
          </div>
          <div className="bg-mafia-primary mb-4 w-3/4 border-t-2 border-mafia-secondary p-4 rounded-lg shadow-lg flex flex-col items-center">
            <div className="text-white text-lg text-center">
              In this Village of make believe, God walks amongst us as the
              Healer. And tonight, he worked his magic!
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default NighResultModal;
