import React from "react";
import withMemo from "../../../utils/withMemo";

const StarFull = () => {
  return (
    <svg
      className="w-6 h-6 fill-current text-yellow-500"
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <path d="M12,17.27l4.15,2.51c0.76,0.46,1.69-0.22,1.49-1.08l-1.1-4.72l3.67-3.18c0.67-0.58,0.31-1.68-0.57-1.75l-4.83-0.41 l-1.89-4.46c-0.34-0.81-1.5-0.81-1.84,0L9.19,8.63L4.36,9.04c-0.88,0.07-1.24,1.17-0.57,1.75l3.67,3.18l-1.1,4.72 c-0.2,0.86,0.73,1.54,1.49,1.08L12,17.27z" />
      </g>
    </svg>
  );
};
const StarHalf = () => {
  return (
    <svg
      className="w-6 h-6 fill-current text-yellow-500"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19.65 9.04l-4.84-.42-1.89-4.45c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5 4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.73 3.67-3.18c.67-.58.32-1.68-.56-1.75zM12 15.4V6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z" />
    </svg>
  );
};
const StarEmpty = () => {
  return (
    <svg
      className="w-6 h-6 fill-current text-yellow-500"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19.65 9.04l-4.84-.42-1.89-4.45c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5 4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.73 3.67-3.18c.67-.58.32-1.68-.56-1.75zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z" />
    </svg>
  );
};

const StarRating = withMemo(
  ({ className, rating, outOf }) => {
    const full = Math.round(rating);
    const half = rating !== full && full - Math.floor(rating) === 0;
    const empty = half ? outOf - full - 1 : outOf - full;

    const getStars = () => {
      const stars = [];
      for (let index = 0; index < full; index++) {
        stars.push(<StarFull key={Math.random()} />);
      }
      if (half) {
        stars.push(<StarHalf key={Math.random()} />);
      }
      for (let index = 0; index < empty; index++) {
        stars.push(<StarEmpty key={Math.random()} />);
      }
      return stars;
    };

    return (
      <div className={`flex ${className ? className : ""}`}>{getStars()}</div>
    );
  },
  ["className", "rating", "outOf"]
);

export default StarRating;
