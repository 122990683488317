import { createContext } from "react";

interface AuthContextInterface {
  token: string | null;
  isLoggedIn: boolean | null;
  sessionId: string;
  clientId: string;
  user: any;
  login: (user: any, token: string, tokenExpiry: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextInterface>({
  token: null,
  isLoggedIn: null,
  sessionId: "",
  clientId: "",
  user: null,
  logout: () => {},
  login: () => {},
});

export default AuthContext;
