import React, { useEffect } from "react";
import { useSeed } from "../../../apps/ZoWorld/contexts";
import useApi from "../../../apps/ZoWorld/hooks/useApi";
import useInput from "../../hooks/useInput";
import useResponseFlash from "../../hooks/useResponseFlash";
import Modal from "../../ui/Modal";

const ReportUserModal = ({ code, _nickname = "", close }) => {
  const api = useApi("ZOWORLD_REPORT_USER");
  const { abuseTypes } = useSeed();

  const { value: nickname, bind: bindNickname } = useInput(_nickname);
  const { value: type, bind: bindType, setValue } = useInput("");
  const { value: notes, bind: bindNotes } = useInput("");
  const { response, setResponse } = useResponseFlash();

  useEffect(() => {
    if (abuseTypes) {
      setValue(abuseTypes[0][0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abuseTypes]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      url: window.location.href,
      nickname,
      type,
      notes,
    };
    if (code) {
      data.code = code;
    }
    const res = await api.post(
      "/zoworld/api/v1/report/user/",
      JSON.stringify(data)
    );
    if (res.status === 200) {
      setResponse("Reported successfully!");
      setTimeout(close, 2000);
    } else {
      setResponse("Error in reporting. Try again later.");
    }
    console.log(res);
  };

  return (
    <Modal close={close} visible disableCloseOutside blurOuter>
      <div className="relative border-orange border-t-4 bg-white rounded-lg shadow-2xl h-content w-content portrait:w-full">
        <form
          className="w-144 flex flex-col items-center portrait:w-full portrait:h-content"
          onSubmit={handleSubmit}
        >
          <header className="font-black w-full mb-4 p-4 bg-gray-300 text-2xl text-center">
            Report
          </header>
          {!code ? (
            <div className="flex w-full px-4 items-start portrait:flex-col my-2">
              <div className="w-1/4 mr-2 portrait:w-full portrait:mr-0 portrait:mb-2">
                <label
                  className="text-sm pt-3 font-medium text-gray-700"
                  htmlFor="ra-nickname"
                >
                  Nickname
                </label>
              </div>
              <input
                id="ra-nickname"
                name="ra-nickname"
                type="text"
                placeholder="Nickname of the offender"
                className="form-input flex-grow portrait:w-full"
                {...bindNickname}
              />
            </div>
          ) : (
            <div className="flex w-full px-4 items-start portrait:flex-col my-2">
              <div className="w-1/4 mr-2 portrait:w-full portrait:mr-0 portrait:mb-2">
                <label className="text-sm pt-3" htmlFor="ra-nickname">
                  Nickname
                </label>
              </div>
              <span className="font-bold">{nickname}</span>
            </div>
          )}
          <div className="flex w-full px-4 items-start portrait:flex-col my-2">
            <div className="w-1/4 mr-2 portrait:w-full portrait:mr-0 portrait:mb-2">
              <label className="text-sm pt-3" htmlFor="ra-type">
                Offense type
              </label>
            </div>
            <select
              className="form-select flex-grow portrait:w-full"
              {...bindType}
              id="ra-type"
            >
              {abuseTypes &&
                abuseTypes.map((at) => (
                  <option key={at[0]} value={+at[0]}>
                    {at[1]}
                  </option>
                ))}
            </select>
          </div>
          <div className="flex w-full px-4 items-start portrait:flex-col my-2">
            <div className="w-1/4 mr-2 portrait:w-full portrait:mr-0 portrait:mb-2">
              <label className="text-sm pt-3" htmlFor="ra-notes">
                Notes
              </label>
            </div>
            <textarea
              id="ra-notes"
              rows={4}
              placeholder="Briefly describe the offense..."
              className="form-textarea flex-grow portrait:w-full resize-none"
              {...bindNotes}
            />
          </div>
          <div className="flex w-full my-2">
            <div className="w-1/4 mr-2 portrait:w-full portrait:mr-0 portrait:mb-2">
              {/* <label htmlFor="nickname">Notes</label> */}
            </div>
            {response ? (
              <span className="whitespace-no-wrap text-orange font-medium py-3 px-4 mb-4">
                {response}
              </span>
            ) : (
              <button className="bg-orange font-medium py-3 px-4 text-white rounded-lg mb-4">
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ReportUserModal;
