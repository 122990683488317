import React, { useMemo } from "react";
import Avatar from "../../../../../components/ui/Avatar";
import { Users } from "../../../assets/icons";
import Flex from "../../structure/Flex";

interface RoomCardProps {
  data: Room;
  onClick?: () => void;
}

const RoomCard: React.FC<RoomCardProps> = ({ data, onClick }) => {
  const leftText = useMemo(() => {
    const leftCount = data.members_count - data.members_nicknames.length;
    if (leftCount > 0) {
      return ` and ${leftCount} other${leftCount > 1 ? "s" : ""}`;
    } else {
      return "";
    }
  }, [data]);

  return (
    <Flex
      items="end"
      justify="between"
      className="bg-white fadeInLeft rounded-lg shadow-zo-sm h-20 my-4 relative w-full overflow-hidden cursor-pointer"
      onClick={onClick}
    >
      <div className="w-16 h-20 flex-shrink-0">
        <Avatar
          full
          className="absolute bottom-0 left-0 ml-3 h-36"
          style={{ marginBottom: "-3.7rem" }}
          svg={data.avatar_url}
        />
      </div>
      <Flex
        col
        className="h-20 overflow-hidden flex-grow ml-4"
        justify="center"
      >
        <span className="uppercase font-bold text-zo-mid-grey tracking-wide text-xxs">
          {data.nickname}&apos;s Room
        </span>
        <span className="font-bold truncate">{data.name}</span>
        <span className="text-xs text-zo-mid-grey font-semibold">
          {data.members_nicknames != null
            ? `${data.members_nicknames.join(", ")}${leftText}`
            : data.nickname}
        </span>
      </Flex>
      <Flex className="h-20 p-4 flex-shrink-0" items="center" justify="center">
        <Users className="w-4 h-4 mr-2" />
        <span className="text-sm">{data.members_count}</span>
      </Flex>
    </Flex>
  );
};

export default RoomCard;
