import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import ChatMessage from "./components/ChatMessage";
import RoomContext from "./../../../contexts/room";
import ProfileContext from "../../../../../contexts/profile";
import { getColorCode } from "../../../utils";
import withMemo from "../../../../../../utils/withMemo";
import useAudio from "../../../../../hooks/useAudio";
import newMessageAudio from "./../../../../../../assets/new-message.mp3";

const getUptoMinutes = (timestamp) => Math.floor(timestamp / 60000);

const ChatMessages = withMemo(
  ({ active, setUnreadChatCount }) => {
    const { message, members } = useContext(RoomContext);
    const { profile } = useContext(ProfileContext);

    const membersColorRef = useRef({});
    const chatRef = useRef();
    const lastLength = useRef(0);
    const title = useRef(document.title);
    const [chatMessages, setChatMessages] = useState([]);
    const pageHidden = useRef(false);
    const newMessageSound = useAudio(newMessageAudio, false, 0.3);

    const [hasNewMessages, setNewMessageVisibility] = useState(false);

    const chatMessagesReceived = useMemo(
      () =>
        chatMessages.filter(
          (c) => c.type !== "info" && c.from !== profile.nickname
        ).length,
      [chatMessages, profile.nickname]
    );

    const scrollToBottom = useCallback(() => {
      if (chatRef.current) {
        chatRef.current.scrollTo(0, chatRef.current.scrollHeight);
      }
      setNewMessageVisibility(false);
    }, []);

    const toggleKeyboardFocused = () => {
      const _chatRef = chatRef.current;

      if (_chatRef) {
        setTimeout(() => {
          _chatRef.scrollTo(0, _chatRef.scrollHeight);
        }, 200);
      }
    };

    useEffect(() => {
      if (window.innerWidth < 640) {
        document
          .querySelector("#chat-input")
          .addEventListener("focus", toggleKeyboardFocused);
        document
          .querySelector("#chat-input")
          .addEventListener("blur", toggleKeyboardFocused);
      }

      return () => {
        document
          .querySelector("#chat-input")
          .removeEventListener("focus", toggleKeyboardFocused);
        document
          .querySelector("#chat-input")
          .removeEventListener("blur", toggleKeyboardFocused);
      };
    }, []);

    useEffect(() => {
      if (!active) {
        if (chatMessagesReceived !== lastLength.current) {
          console.log("Chat", chatMessagesReceived);
          setUnreadChatCount((c) => ++c);
          if (chatMessages) {
            const filteredMessages = chatMessages.filter((cm) => !!cm.from);
            const lastChatter =
              filteredMessages[filteredMessages.length - 1] &&
              filteredMessages[filteredMessages.length - 1].from;
            if (lastChatter) {
              document.title = `New message from ${lastChatter}`;
            }
          }
          if (
            newMessageSound &&
            localStorage.getItem("disableChatSound") == null
          ) {
            newMessageSound.play();
          }
        }
      } else {
        setUnreadChatCount(0);
        lastLength.current = chatMessagesReceived;
        document.title = title.current;
        if (pageHidden.current) {
          if (chatMessages) {
            const filteredMessages = chatMessages.filter((cm) => !!cm.from);
            const lastChatter =
              filteredMessages[filteredMessages.length - 1] &&
              filteredMessages[filteredMessages.length - 1].from;
            if (lastChatter) {
              document.title = `New message from ${lastChatter}`;
            }
          }
          if (
            newMessageSound &&
            localStorage.getItem("disableChatSound") == null
          ) {
            newMessageSound.play();
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatMessagesReceived, active]);

    useEffect(() => {
      function makeSound() {
        if (document.visibilityState === "hidden") {
          pageHidden.current = true;
        } else {
          pageHidden.current = false;
          document.title = title.current;
        }
      }
      document.addEventListener("visibilitychange", makeSound);

      return () => {
        document.removeEventListener("visibilitychange", makeSound);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const _chatRef = chatRef.current;

      if (_chatRef) {
        _chatRef.scrollTo(0, _chatRef.scrollHeight);
      }
    }, []);

    useEffect(() => {
      if (message && message.action) {
        if (message.action === "member_chat") {
          setChatMessages((c) => [...c, message.payload]);
          if (
            window._confetti_schoolPride != null
            // window._confetti_snow != null &&
            // window._confetti_fireworks != null
          ) {
            if (message.payload.message.match(/happy/i)) {
              window._confetti_schoolPride();
            }
            // if (message.payload.message.match(/new/i)) {
            //   window._confetti_fireworks();
            // }
            // if (message.payload.message.match(/year/i)) {
            //   window._confetti_snow();
            // }
            // if (message.payload.message.match(/2021/i)) {
            //   window._confetti_snow();
            //   window._confetti_fireworks();
            //   window._confetti_schoolPride();
            // }
          }
        } else if (
          message.action === "joined" &&
          localStorage.getItem("disableChatLJMessages") == null
        ) {
          setChatMessages((c) => [
            ...c,
            {
              type: "info",
              message: `${message.payload.nickname} has joined.`,
              timestamp: +new Date(),
            },
          ]);
        } else if (
          message.action === "left" &&
          localStorage.getItem("disableChatLJMessages") == null
        ) {
          setChatMessages((c) => [
            ...c,
            {
              type: "info",
              message: `${message.payload.nickname} has left.`,
              timestamp: +new Date(),
            },
          ]);
        } else if (message.action === "member_kick") {
          setChatMessages((c) => [
            ...c,
            {
              type: "info",
              message: `${message.payload.nickname} has left ...`,
              timestamp: +new Date(),
            },
          ]);
        } else if (message.action === "update_whiteboard_text") {
          if (message.payload.text) {
            setChatMessages((c) => [
              ...c,
              {
                type: "info",
                message: `Notice board has been updated.`,
                timestamp: +new Date(),
              },
            ]);
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    useEffect(() => {
      let mounted = true;

      if (chatRef.current) {
        if (chatRef.current.lastElementChild) {
          if (
            chatRef.current.scrollTop + chatRef.current.offsetHeight >=
            chatRef.current.scrollHeight -
              chatRef.current.lastElementChild.clientHeight -
              parseFloat(
                getComputedStyle(document.querySelector("html")).fontSize
              ) *
                0.75 -
              50
          ) {
            if (chatRef.current && mounted) {
              chatRef.current.scrollTo(0, chatRef.current.scrollHeight);
            }
          } else {
            setNewMessageVisibility(true);
          }
        }
      }

      return () => {
        mounted = false;
      };
    }, [chatMessages]);

    useEffect(() => {
      members.forEach((m) => {
        if (!membersColorRef.current[m.nickname]) {
          membersColorRef.current[m.nickname] = getColorCode();
        }
      });
    }, [members]);

    return useMemo(
      () => (
        <ul className="overflow-hidden w-full flex-grow flex flex-col justify-end">
          <div
            className="h-content max-h-full overflow-y-auto relative"
            ref={chatRef}
          >
            {hasNewMessages && (
              <div className="absolute bottom-0 h-content w-full mb-10">
                <div className="fixed w-full flex justify-center">
                  <button
                    className="bg-orange text-white font-medium tracking-wide p-3 leading-none rounded-lg shadow-md"
                    onClick={scrollToBottom}
                  >
                    New Messages &darr;
                  </button>
                </div>
              </div>
            )}
            {chatMessages.map((c, i) => {
              const type =
                c.type || (c.from === profile.nickname ? "sent" : "received");

              const collapsed =
                chatMessages[i - 1] &&
                c.from === chatMessages[i - 1].from &&
                getUptoMinutes(c.timestamp) ===
                  getUptoMinutes(chatMessages[i - 1].timestamp);

              return (
                <ChatMessage
                  type={type}
                  key={c.timestamp}
                  message={c.message}
                  nickname={c.from}
                  collapsed={collapsed}
                  avatar={c.avatar}
                  accentColor={membersColorRef.current[c.from]}
                />
              );
            })}
          </div>
        </ul>
      ),
      [chatMessages, hasNewMessages, profile.nickname, scrollToBottom]
    );
  },
  ["active", "setUnreadChatCount"]
);

export default ChatMessages;
