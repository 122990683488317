import React, { useEffect, useState } from "react";
import Portal from "../../../../components/ui/Portal";
import Flex from "../../components/structure/Flex";
import { useRTC } from "../../contexts";
import ActiveRoomsList from "./components/ActiveRoomsList";
import EventsList from "./components/EventsList";
import Footer from "./components/Footer";
import Header from "./components/Header";

interface TravelPortalProps {
  close: () => void;
}

const TravelPortal: React.FC<TravelPortalProps> = ({ close }) => {
  const [view, setView] = useState<"portal" | "events">("portal");
  const { setRoomCode } = useRTC();

  useEffect(() => {
    if (!close) {
      setRoomCode(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [close]);

  return (
    <Portal>
      <Flex col className="fixed inset-0 z-10 bg-zo-off-white fade-in">
        <Header view={view} changeView={setView} />
        {view === "portal" ? (
          <>
            <ActiveRoomsList close={close} />
            <Footer close={close} />
          </>
        ) : view === "events" ? (
          <EventsList />
        ) : null}
      </Flex>
    </Portal>
  );
};

export default TravelPortal;
