import React, { useCallback, useContext, useMemo } from "react";
import withMemo from "../../../../../../../utils/withMemo";
import InGameContext from "../../../contexts/inGame";
import SocketContext from "../../../contexts/socket";

const TimeExtension = withMemo(() => {
  const { roomRound, player, roomStatus } = useContext(InGameContext);
  const { sendSocketMessage } = useContext(SocketContext);

  const handleTimeBank = useCallback(() => {
    sendSocketMessage("request_time_bank", {
      id: player.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player.id]);

  return useMemo(
    () =>
      roomRound === "day" &&
      player.status === "alive" &&
      roomStatus !== "ended_mafia" &&
      roomStatus !== "ended_village" && (
        <button
          className={`relative bg-mafia-primary ml-4 p-2 rounded-lg shadow-lg text-white ${
            player.timeBankUsed && "cursor-not-allowed"
          }`}
          onClick={!player.timeBankUsed ? handleTimeBank : null}
          disabled={player.timeBankUsed}
        >
          Extra Time (+ 30s)
          <span className="absolute top-0 right-0 bg-mafia-secondary text-mafia-primary w-6 h-6 flex items-center justify-center text-sm font-bold -mt-2 -mr-2 rounded-full">
            {!player.timeBankUsed ? 1 : 0}
          </span>
        </button>
      ),
    [roomRound, player.status, handleTimeBank, player.timeBankUsed, roomStatus]
  );
}, []);

export default TimeExtension;
