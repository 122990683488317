import React from "react";

const MenuItem = ({ icon, name, onClick, isActive }) => {
  return (
    <li
      className={`py-1 my-1 pl-4 rounded-lg cursor-pointer flex items-center hover:bg-gray-200 ${
        isActive && "bg-gray-300"
      }`}
      onClick={onClick}
    >
      <img src={icon} alt="" className="mr-2 w-12 h-12 p-1" />
      {name}
    </li>
  );
};

export default MenuItem;
