import React from "react";

import Avatar from "../../../ui/Avatar";

const MembersStack = ({
  members,
  hostPassport,
  collapsed,
  destinationSuggestion,
  isPartyHost,
  handleKick,
}) => {
  return collapsed ? (
    <div className="flex items-center">
      {members.slice(0, 6).map((member) => (
        <div
          className="relative w-12 h-12 -mr-2 rounded-full border-2 bg-white group hover:z-10"
          key={member.code}
        >
          <Avatar svg={member.avatar_url} className="w-full h-full" />
          {hostPassport === member.code && (
            <div className="absolute top-0 left-0 group-a w-4 h-4 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                className="fill-current text-green-500 bg-white rounded-full w-4 h-4"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm3.23 15.39L12 15.45l-3.22 1.94c-.38.23-.85-.11-.75-.54l.85-3.66-2.83-2.45c-.33-.29-.15-.84.29-.88l3.74-.32 1.46-3.45c.17-.41.75-.41.92 0l1.46 3.44 3.74.32c.44.04.62.59.28.88l-2.83 2.45.85 3.67c.1.43-.36.77-.74.54z" />
              </svg>
              <span className="absolute pointer-events-none opacity-0 bg-gray-700 text-white whitespace-no-wrap font-medium px-2 py-1 leading-none rounded-full text-xs group-a-hover:opacity-100">
                Party host
              </span>
            </div>
          )}
          <div className="absolute pointer-events-none opacity-0 group-hover:opacity-100 bg-white px-2 py-1 mt-1 whitespace-no-wrap leading-none rounded-full -ml-2 text-xs">
            {member.nickname}
          </div>
        </div>
      ))}
      {members.length > 6 && (
        <div
          className="relative w-12 h-12 -mr-2 rounded-full border-2 bg-white group hover:z-10"
          key="left"
        >
          <div className="w-full h-full rounded-full border-2 bg-gray-300 text-gray-800 flex items-center justify-center font-medium">
            +{members.length - 6}
          </div>
          <div className="absolute opacity-0 group-hover:opacity-100 bg-white px-2 py-1 mt-1 whitespace-no-wrap leading-none rounded-full -ml-2 text-xs text-center">
            {members
              .slice(6)
              .map((m) => m.nickname)
              .join(<br />)}
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="flex items-center">
      {members.map((member) => (
        <div
          className="relative w-12 h-12 -mr-2 rounded-full border-2 bg-white group hover:z-40 flex flex-col items-center"
          key={member.code}
        >
          {destinationSuggestion &&
            destinationSuggestion.from.code === member.code && (
              <div className="fadeInUp absolute bottom-full mb-4 w-content z-40 text-lg h-content rounded-full uppercase tracking-wider shadow-2xl bg-white text-orange whitespace-no-wrap text-center leading-none font-bold p-2">
                {destinationSuggestion.room.name}!
              </div>
            )}
          <Avatar svg={member.avatar_url} className="w-full h-full" />
          {hostPassport === member.code && (
            <div className="absolute top-0 left-0 group-a w-4 h-4 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                className="fill-current text-green-500 bg-white rounded-full w-4 h-4"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm3.23 15.39L12 15.45l-3.22 1.94c-.38.23-.85-.11-.75-.54l.85-3.66-2.83-2.45c-.33-.29-.15-.84.29-.88l3.74-.32 1.46-3.45c.17-.41.75-.41.92 0l1.46 3.44 3.74.32c.44.04.62.59.28.88l-2.83 2.45.85 3.67c.1.43-.36.77-.74.54z" />
              </svg>
              <span className="absolute pointer-events-none opacity-0 bg-gray-700 text-white whitespace-no-wrap font-medium px-2 py-1 leading-none rounded-full text-xs group-a-hover:opacity-100">
                Party host
              </span>
            </div>
          )}
          <div className="absolute opacity-0 pointer-events-none group-hover:opacity-100 bg-white px-2 py-1 mt-1 whitespace-no-wrap leading-none rounded-full z-40 top-full text-xs">
            {member.nickname}{" "}
            {isPartyHost && handleKick != null && hostPassport !== member.code && (
              <button
                className="opacity-0 group-hover:opacity-100 pointer-events-auto w-content z-50 text-sm h-content rounded-lg shadow-2xl bg-orange text-white whitespace-no-wrap text-center leading-none font-semibold px-2 py-1"
                onClick={handleKick.bind(null, member.code)}
              >
                Kick
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MembersStack;
