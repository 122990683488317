import { createContext, useContext } from "react";

const SeedContext = createContext({
  layout: {},
  style: {},
  seedData: {},
});

const useSeed = () => {
  const seed = useContext(SeedContext);
  return seed;
};

export { useSeed, SeedContext };
