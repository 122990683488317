import React, { useContext, useState, useEffect } from "react";

// import Filters from "./components/Filters";
import SidePanel from "./components/SidePanel";
import Charts from "./components/Charts";
import { AuthContext } from "../../../../../contexts/contexts";
import Widget from "../../ui/Widget";

const Happiness = (props) => {
  const { publicApi } = useContext(AuthContext);

  const [happinessAggregate, setHappinessAggregate] = useState(null);

  useEffect(() => {
    if (publicApi && !happinessAggregate) {
      try {
        publicApi
          .get(`/zoworld/api/v1/happiness/aggregate/?period=daily`)
          .then((res) => {
            setHappinessAggregate(res.data.happiness); //set metrics
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, [publicApi, happinessAggregate]);

  return (
    <Widget className="flex flex-col bg-gray-100" {...props}>
      <div className="h-full grid grid-cols-3 p-4 gap-4 overflow-hidden">
        <div className="col-span-1">
          <SidePanel />
        </div>

        <div className="col-span-2">
          <Charts happinessAggregate={happinessAggregate} />
        </div>
      </div>
    </Widget>
  );
};

export default Happiness;
