import React from "react";

import Widget from "../../ui/Widget";
import { useSeed } from "../../../contexts/seed";

const Weather = (props) => {
  const { style, seedData } = useSeed();

  return (
    <Widget
      className={`zo-destinationtracking-wide bg-grey-100 border-t-4 border-${
        style.accentColor || "orange"
      }`}
      {...props}
      style={{ height: "7rem" }}
    >
      <a
        className="weatherwidget-io pointer-events-none cursor-default"
        href={seedData.weather_url + "/"}
        data-label_1={seedData.name}
        style={{ height: "7rem !important" }}
        data-icons="Climacons Animated"
        data-mode="Current"
        data-theme="original"
      >
        <span
          className={`flex items-center justify-center w-full h-full bg-${
            style.accentColor || "orange"
          } text-white font-medium text-lg`}
        >
          Loading Weather
        </span>
      </a>
      {
        !(function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          js = d.createElement(s);
          js.id = id;
          js.src = "https://weatherwidget.io/js/widget.min.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "weatherwidget-io-js")
      }
    </Widget>
  );
};

export default Weather;
