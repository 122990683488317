import React, { useContext, useEffect, useState } from "react";
import withMemo from "../../../../../../../utils/withMemo";
import InGameContext from "../../../contexts/inGame";

function getVotesSorted(votesArray) {
  const votesMap = {};
  votesArray.forEach((id) => {
    if (votesMap[id] === undefined) {
      votesMap[id] = 1;
    } else {
      votesMap[id]++;
    }
  });
  const votesEntries = Object.entries(votesMap);
  const sortedVotesEntries = votesEntries.sort((b, a) => a[1] - b[1]);
  return sortedVotesEntries;
}

const LiveResult = withMemo(() => {
  const [votes, setVotes] = useState([]);
  const { playersStates } = useContext(InGameContext);

  useEffect(() => {
    if (playersStates) {
      const votee = [];
      Object.keys(playersStates).forEach((id) => {
        const _player = playersStates[id];
        if (
          _player.status === "alive" &&
          _player.vote !== null &&
          _player.vote !== undefined &&
          _player.vote !== -1
        ) {
          votee.push(_player.vote);
        }
      });
      const _votes = getVotesSorted(votee)
        .slice(0, 3)
        .map((v) => [playersStates[v[0]], v[1]]);
      setVotes(_votes);
    }
  }, [playersStates]);

  return (
    <>
      <div className="">Currently Lynching:</div>
      <div className="text-xs">(Random in case of a tie)</div>
      {votes.length > 0 ? (
        <>
          {votes.map((v) => (
            <div key={v[0].id} className="text-lg flex items-center">
              <strong>{v[0].nickname}</strong>
              <div className="inline-block ml-2 text-base font-medium">
                {v[1]} vote(s)
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="text-2xl flex items-center">
          <strong>No Vote casted.</strong>
        </div>
      )}
    </>
  );
}, []);

export default LiveResult;
