import React from "react";

import "./index.css";
import Modal from "../../../../../../ui/Modal";
import Players from "./components/Players";
import Header from "./components/Header";
import GameScore from "./components/GameScore";
import Footer from "./components/Footer";
import GameInfo from "./components/GameInfo";
import Chat from "./components/Chat";

const EndScreenModal = () => {
  return (
    <Modal
      visible
      close={() => {}}
      disableCloseOutside
      hideCloseButton
      blurOuter
    >
      <section className="w-screen h-screen max-h-screen p-8 bg-mafia-background">
        <div className="w-full flex flex-col items-center p-4">
          <Header />
          <div className="w-full flex-grow items-center flex flex-col">
            <section className="my-4 bg-gray-800 shadow-inner rounded-lg p-2 w-full flex-grow-0">
              <Players />
            </section>
            <section className="flex flex-grow w-full">
              <Chat />
              <GameScore />
              <GameInfo />
            </section>
          </div>
          <Footer />
        </div>
      </section>
    </Modal>
  );
};

export default EndScreenModal;
