import React, { useContext } from "react";

import TimeContext from "../../../contexts/time";
import { formattedSeconds, getMinutes, getSeconds } from "../../../utils";

const RoundTimer = () => {
  const { roundTime, gameOver } = useContext(TimeContext);

  return !gameOver ? (
    <section className="bg-mafia-primary font-bold text-sm text-mafia-secondary tracking-normal border-t-2 border-mafia-secondary uppercase text-lg inline-block p-2 mt-2 rounded-lg shadow-lg">
      <span className="w-8 text-right pr-1">
        {formattedSeconds(getMinutes(roundTime))}
      </span>
      :
      <span className="w-8 text-left pl-1">
        {formattedSeconds(getSeconds(roundTime))}
      </span>
    </section>
  ) : null;
};

export default RoundTimer;
