import React from "react";
import { useHistory } from "react-router-dom";
import backIcon from "./../../../../../../../assets/back.svg";

const Section = ({ children, title }) => {
  const history = useHistory();

  const goBack = () => {
    if (window.location.pathname.includes("settings")) {
      history.push("/settings");
    } else {
      history.push("/#settings");
    }
  };

  return (
    <section className="fade-in p-4 w-full h-full flex flex-col">
      <h1 className="mb-4 text-2xl flex items-center font-bold py-2 leading-none flex-shrink-0">
        <button className="w-8 h-8 portrait:block hidden mr-4">
          <img
            className="w-full h-full"
            src={backIcon}
            onClick={goBack}
            alt=""
          />
        </button>
        {title}
      </h1>
      <div className="flex-no-wrap flex-grow overflow-y-auto my-2">
        {children}
      </div>
    </section>
  );
};

export default Section;
