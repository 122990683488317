import React, { useContext, useState } from "react";
import { useResponseFlash } from "../../../../../../../../../components/apps/Zo/components/hooks";
import ProfileContext from "../../../../../../../contexts/profile/ProfileContext";
import useApi from "../../../../../../../hooks/useApi";
import SubSectionRow from "../../../components/SubSectionRow";
import LocationDisplay from "./LocationDisplay";
import LocationSelector from "./LocationSelector";

const CurrentLocationField = ({ countriesList }) => {
  const api = useApi("PROFILE_ME");
  const { profile, updateProfile } = useContext(ProfileContext);
  const { response, setResponse } = useResponseFlash();

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!location.country || !location.city) {
      setResponse("Incomplete input");
      return;
    }
    if (!loading && api) {
      setLoading(true);
      try {
        const data = {
          country_residing: location.country,
          city: location.city,
        };
        console.log(data);

        const response = await api.post(``, JSON.stringify(data));
        if (response.status === 200) {
          updateProfile(data);
          setExpanded(false);
        } else {
          setResponse("Error in fields");
        }
      } catch (error) {
        console.log(error.response);
        setResponse("Error in setting the location.");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <SubSectionRow
      title="Current Location"
      visibleArea={
        profile.country_residing &&
        profile.city && (
          <LocationDisplay
            countriesData={countriesList}
            initialData={{
              country: profile.country_residing,
              city: profile.city,
            }}
          />
        )
      }
      placeholder="Where are you currenly residing?"
      isExpanded={expanded}
      setExpanded={setExpanded}
    >
      <form className="flex flex-col w-full" onSubmit={handleSubmit}>
        <div className="flex w-full">
          <LocationSelector
            countriesData={countriesList}
            setLocation={setLocation}
            initialData={{
              country: profile.country_residing,
              city: profile.city,
            }}
          />
        </div>
        <div className="w-full h-6 flex items-center justify-start font-semibold text-xs text-orange">
          {response}
        </div>
        <div className="flex mb-4 w-full">
          <button
            className="bg-orange text-white cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            type="submit"
          >
            {loading ? "Saving" : "Save"}
          </button>
          <button
            className="bg-white text-orange cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
            onClick={setExpanded.bind(null, false)}
          >
            Cancel
          </button>
        </div>
      </form>
    </SubSectionRow>
  );
};

export default CurrentLocationField;
