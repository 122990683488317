import React, { useState, useEffect } from "react";
// import indiaFlag from "./../../../../../assets/india.svg";
import populationIcon from "./../../../../../../../../assets/populations/Population.svg";
import countryIcon from "./../../../../../../../../assets/populations/Country.svg";
import countrybg from "./../../../../../../../../assets/populations/Countrybg.svg";

const flagEmojis = {
  AC: "🇦🇨",
  AD: "🇦🇩",
  AE: "🇦🇪",
  AF: "🇦🇫",
  AG: "🇦🇬",
  AI: "🇦🇮",
  AL: "🇦🇱",
  AM: "🇦🇲",
  AO: "🇦🇴",
  AQ: "🇦🇶",
  AR: "🇦🇷",
  AS: "🇦🇸",
  AT: "🇦🇹",
  AU: "🇦🇺",
  AW: "🇦🇼",
  AX: "🇦🇽",
  AZ: "🇦🇿",
  BA: "🇧🇦",
  BB: "🇧🇧",
  BD: "🇧🇩",
  BE: "🇧🇪",
  BF: "🇧🇫",
  BG: "🇧🇬",
  BH: "🇧🇭",
  BI: "🇧🇮",
  BJ: "🇧🇯",
  BL: "🇧🇱",
  BM: "🇧🇲",
  BN: "🇧🇳",
  BO: "🇧🇴",
  BQ: "🇧🇶",
  BR: "🇧🇷",
  BS: "🇧🇸",
  BT: "🇧🇹",
  BV: "🇧🇻",
  BW: "🇧🇼",
  BY: "🇧🇾",
  BZ: "🇧🇿",
  CA: "🇨🇦",
  CC: "🇨🇨",
  CD: "🇨🇩",
  CF: "🇨🇫",
  CG: "🇨🇬",
  CH: "🇨🇭",
  CI: "🇨🇮",
  CK: "🇨🇰",
  CL: "🇨🇱",
  CM: "🇨🇲",
  CN: "🇨🇳",
  CO: "🇨🇴",
  CP: "🇨🇵",
  CR: "🇨🇷",
  CU: "🇨🇺",
  CV: "🇨🇻",
  CW: "🇨🇼",
  CX: "🇨🇽",
  CY: "🇨🇾",
  CZ: "🇨🇿",
  DE: "🇩🇪",
  DG: "🇩🇬",
  DJ: "🇩🇯",
  DK: "🇩🇰",
  DM: "🇩🇲",
  DO: "🇩🇴",
  DZ: "🇩🇿",
  EA: "🇪🇦",
  EC: "🇪🇨",
  EE: "🇪🇪",
  EG: "🇪🇬",
  EH: "🇪🇭",
  ER: "🇪🇷",
  ES: "🇪🇸",
  ET: "🇪🇹",
  EU: "🇪🇺",
  FI: "🇫🇮",
  FJ: "🇫🇯",
  FK: "🇫🇰",
  FM: "🇫🇲",
  FO: "🇫🇴",
  FR: "🇫🇷",
  GA: "🇬🇦",
  GB: "🇬🇧",
  GD: "🇬🇩",
  GE: "🇬🇪",
  GF: "🇬🇫",
  GG: "🇬🇬",
  GH: "🇬🇭",
  GI: "🇬🇮",
  GL: "🇬🇱",
  GM: "🇬🇲",
  GN: "🇬🇳",
  GP: "🇬🇵",
  GQ: "🇬🇶",
  GR: "🇬🇷",
  GS: "🇬🇸",
  GT: "🇬🇹",
  GU: "🇬🇺",
  GW: "🇬🇼",
  GY: "🇬🇾",
  HK: "🇭🇰",
  HM: "🇭🇲",
  HN: "🇭🇳",
  HR: "🇭🇷",
  HT: "🇭🇹",
  HU: "🇭🇺",
  IC: "🇮🇨",
  ID: "🇮🇩",
  IE: "🇮🇪",
  IL: "🇮🇱",
  IM: "🇮🇲",
  IN: "🇮🇳",
  IO: "🇮🇴",
  IQ: "🇮🇶",
  IR: "🇮🇷",
  IS: "🇮🇸",
  IT: "🇮🇹",
  JE: "🇯🇪",
  JM: "🇯🇲",
  JO: "🇯🇴",
  JP: "🇯🇵",
  KE: "🇰🇪",
  KG: "🇰🇬",
  KH: "🇰🇭",
  KI: "🇰🇮",
  KM: "🇰🇲",
  KN: "🇰🇳",
  KP: "🇰🇵",
  KR: "🇰🇷",
  KW: "🇰🇼",
  KY: "🇰🇾",
  KZ: "🇰🇿",
  LA: "🇱🇦",
  LB: "🇱🇧",
  LC: "🇱🇨",
  LI: "🇱🇮",
  LK: "🇱🇰",
  LR: "🇱🇷",
  LS: "🇱🇸",
  LT: "🇱🇹",
  LU: "🇱🇺",
  LV: "🇱🇻",
  LY: "🇱🇾",
  MA: "🇲🇦",
  MC: "🇲🇨",
  MD: "🇲🇩",
  ME: "🇲🇪",
  MF: "🇲🇫",
  MG: "🇲🇬",
  MH: "🇲🇭",
  MK: "🇲🇰",
  ML: "🇲🇱",
  MM: "🇲🇲",
  MN: "🇲🇳",
  MO: "🇲🇴",
  MP: "🇲🇵",
  MQ: "🇲🇶",
  MR: "🇲🇷",
  MS: "🇲🇸",
  MT: "🇲🇹",
  MU: "🇲🇺",
  MV: "🇲🇻",
  MW: "🇲🇼",
  MX: "🇲🇽",
  MY: "🇲🇾",
  MZ: "🇲🇿",
  NA: "🇳🇦",
  NC: "🇳🇨",
  NE: "🇳🇪",
  NF: "🇳🇫",
  NG: "🇳🇬",
  NI: "🇳🇮",
  NL: "🇳🇱",
  NO: "🇳🇴",
  NP: "🇳🇵",
  NR: "🇳🇷",
  NU: "🇳🇺",
  NZ: "🇳🇿",
  OM: "🇴🇲",
  PA: "🇵🇦",
  PE: "🇵🇪",
  PF: "🇵🇫",
  PG: "🇵🇬",
  PH: "🇵🇭",
  PK: "🇵🇰",
  PL: "🇵🇱",
  PM: "🇵🇲",
  PN: "🇵🇳",
  PR: "🇵🇷",
  PS: "🇵🇸",
  PT: "🇵🇹",
  PW: "🇵🇼",
  PY: "🇵🇾",
  QA: "🇶🇦",
  RE: "🇷🇪",
  RO: "🇷🇴",
  RS: "🇷🇸",
  RU: "🇷🇺",
  RW: "🇷🇼",
  SA: "🇸🇦",
  SB: "🇸🇧",
  SC: "🇸🇨",
  SD: "🇸🇩",
  SE: "🇸🇪",
  SG: "🇸🇬",
  SH: "🇸🇭",
  SI: "🇸🇮",
  SJ: "🇸🇯",
  SK: "🇸🇰",
  SL: "🇸🇱",
  SM: "🇸🇲",
  SN: "🇸🇳",
  SO: "🇸🇴",
  SR: "🇸🇷",
  SS: "🇸🇸",
  ST: "🇸🇹",
  SV: "🇸🇻",
  SX: "🇸🇽",
  SY: "🇸🇾",
  SZ: "🇸🇿",
  TA: "🇹🇦",
  TC: "🇹🇨",
  TD: "🇹🇩",
  TF: "🇹🇫",
  TG: "🇹🇬",
  TH: "🇹🇭",
  TJ: "🇹🇯",
  TK: "🇹🇰",
  TL: "🇹🇱",
  TM: "🇹🇲",
  TN: "🇹🇳",
  TO: "🇹🇴",
  TR: "🇹🇷",
  TT: "🇹🇹",
  TV: "🇹🇻",
  TW: "🇹🇼",
  TZ: "🇹🇿",
  UA: "🇺🇦",
  UG: "🇺🇬",
  UM: "🇺🇲",
  UN: "🇺🇳",
  US: "🇺🇸",
  UY: "🇺🇾",
  UZ: "🇺🇿",
  VA: "🇻🇦",
  VC: "🇻🇨",
  VE: "🇻🇪",
  VG: "🇻🇬",
  VI: "🇻🇮",
  VN: "🇻🇳",
  VU: "🇻🇺",
  WF: "🇼🇫",
  WS: "🇼🇸",
  XK: "🇽🇰",
  YE: "🇾🇪",
  YT: "🇾🇹",
  ZA: "🇿🇦",
  ZM: "🇿🇲",
  ZW: "🇿🇼",
};

const Countries = ({ populationMetrics, countriesList, humanize }) => {
  const [zoCountries, setZoCountries] = useState(null);

  useEffect(() => {
    if (populationMetrics && countriesList) {
      countriesList.map((country) => {
        country.citizen = 0;
        populationMetrics.country_citizen.map((countryCitizen) => {
          if (country.id === countryCitizen.id) {
            country.citizen = countryCitizen.count;
            country.flag = flagEmojis[country.iso2_code]
              ? flagEmojis[country.iso2_code]
              : null;
          }
          return country;
        });
        return country;
      });

      setZoCountries(
        countriesList
          .sort((a, b) => b.citizen - a.citizen)
          .filter((x) => x.citizen)
      );
    }
  }, [populationMetrics, countriesList]);

  return (
    <>
      <div className="table-fixed">
        <div className="text-center font-bold  py-2 relative flex items-center justify-center">
          <img className="h-6" src={countryIcon} alt="" />
          <span className="ml-4 text-xl">By Countries</span>
        </div>
        <div
          className="overflow-y-scroll overflow-x-hidden"
          style={{ height: "62vh" }}
        >
          {zoCountries &&
            zoCountries.map((country) => {
              return (
                <div
                  key={country.name}
                  className={`px-4 py-2 my-2 mx-2 flex items-center bg-white rounded-lg  p-2 justify-between 
                  ${country.name === "India" && "shadow-md cursor-pointer"}`}
                  style={{
                    backgroundImage: `url(${countrybg})`,
                    backgroundSize: "cover",
                  }}
                  onClick={() =>
                    country.name === "India" &&
                    window.open(
                      `https://${country.name}.${process.env.REACT_APP_ROOT_DOMAIN}`,
                      "_parent"
                    )
                  }
                >
                  {/* <img className="w-10 h-10 rounded-full mr-4" src={indiaFlag} alt="flag" /> */}
                  <div className="text-5xl -my-2 mr-4"> {country.flag}</div>
                  <div className="text-sm">
                    <p className="text-gray-900 text-md leading-none">
                      {country.name}
                    </p>
                  </div>
                  <div>
                    <div className="relative flex justify-center">
                      <img className="h-6" src={populationIcon} alt="" />
                      <span className="inline-block ml-2">
                        {humanize(country.citizen)}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Countries;
