import React from "react";
import { createHash } from "crypto";

import logo from "./../../../../assets/logo.svg";

const getSHA1 = function () {
  return createHash("sha1")
    .update(
      JSON.stringify({
        timestamp: Date.now(),
        rand: Math.random() * 10000,
      })
    )
    .digest("hex")
    .substr(0, 10);
};

function setCookie(cname, cvalue) {
  const d = new Date();
  d.setTime(d.getTime() + 20 * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie =
    cname +
    "=" +
    cvalue +
    ";" +
    expires +
    `;path=/;domain=.${process.env.REACT_APP_ROOT_DOMAIN}`;
}

const createNewSession = () => {
  const sessionId = getSHA1();
  setCookie("sessionId", sessionId);
  return sessionId;
};

const LogInButton = ({ className }) => {
  return (
    <form id="auth-form" action="https://user.zo.xyz/sso" method="POST">
      <input
        type="text"
        value="Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU"
        name="client_app_id"
        onChange={() => {}}
        hidden
      />
      <input
        type="text"
        value={createNewSession()}
        onChange={() => {}}
        name="client_session_id"
        hidden
      />
      <input
        type="text"
        value={window.location.origin + "/auth/callback"}
        name="callback_url"
        onChange={() => {}}
        hidden
      />
      <input
        type="text"
        value={window.location.href}
        name="redirect_url"
        onChange={() => {}}
        hidden
      />
      <button
        type="submit"
        className={`flex items-center justify-center ${
          className ? className : ""
        }`}
      >
        <img className="w-10 h-8 mr-2 pl-2" src={logo} alt="" />
        <span className="whitespace-no-wrap text-sm pr-3">Login</span>
      </button>
    </form>
  );
};

export default LogInButton;
