import React, { useContext, useState } from "react";
import AppContext from "../../../../contexts/app";
import ProfileContext from "../../../../contexts/profile";
import SeedContext from "../../../../contexts/seed";
import useApi from "../../../../hooks/useApi";
import useResponseFlash from "../../../../hooks/useResponseFlash";
import Modal from "../../../../ui/Modal";
import RoomContext from "../../contexts/room";

const BackgroundsModal = ({ close, current }) => {
  const api = useApi("profile", true);
  const commsApi = useApi("comms", true);
  const { sendSocketMessage } = useContext(RoomContext);
  const { category, slug } = useContext(AppContext);
  const { backgrounds } = useContext(SeedContext);
  const { updateProfile } = useContext(ProfileContext);
  const { response, setResponse } = useResponseFlash();

  const [selected, setSelected] = useState(current);
  const [loading, setLoading] = useState(false);

  const onSuccess = (background) => {
    sendSocketMessage({
      action: "update_background",
      payload: { background },
    });
    setResponse("Background updated.");
    setLoading(false);
    close();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (category === "profile") {
      if (!loading) {
        setLoading(true);
        try {
          const data = {
            background_key: selected,
          };
          const response = await api.post(
            `/profile/api/v1/me/`,
            JSON.stringify(data)
          );
          if (response.status === 200) {
            console.log(response);
            updateProfile(data);
            onSuccess(data.background_key);
          }
        } catch (error) {
          console.log(error.response);
        }
      }
    } else {
      if (!loading) {
        setLoading(true);
        try {
          const data = {
            background_key: selected,
          };
          const response = await commsApi.post(
            `/lobby/api/v1/room/${slug || "zo"}/`,
            JSON.stringify(data)
          );
          if (response.status === 200) {
            console.log(response);
            onSuccess(data.background_key);
          }
        } catch (error) {
          console.log(error.response);
        }
      }
    }
  };

  return (
    <Modal close={close} backgroundTheme="light" visible>
      <div className="w-108 portrait:w-full rounded-lg bg-white border-t-4 border-orange flex flex-col items-center">
        <span className="font-bold text-xl p-4">Background Settings</span>
        <div className="bg-gray-100 w-full flex items-center justify-center p-4">
          <form className="flex flex-col w-full" onSubmit={handleSubmit}>
            <div
              className="flex flex-wrap w-full overflow-y-auto"
              style={{ maxHeight: "24rem" }}
            >
              {Object.keys(backgrounds).map((bg) => {
                const _bg = backgrounds[bg];
                return (
                  <img
                    className={`w-20 h-20 m-1 rounded-lg border-2 object-cover cursor-pointer ${
                      selected === bg ? "border-orange" : ""
                    }`}
                    key={_bg}
                    src={_bg}
                    onClick={setSelected.bind(null, bg)}
                    alt=""
                  />
                );
              })}
            </div>
            <div className="w-full h-6 flex items-center justify-start font-semibold text-xs text-orange">
              {response}
            </div>
            <div className="flex mb-4 w-full">
              <button
                className="bg-orange text-white cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
                type="submit"
              >
                {loading ? "Saving" : "Save"}
              </button>
              <button
                className="bg-white text-orange cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
                onClick={close}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default BackgroundsModal;
