import Lottie from "lottie-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Plus } from "../../../../assets/icons";
import EventEditSheet from "../../../../components/bottomsheets/EventEditSheet";
import Flex from "../../../../components/structure/Flex";
import Button from "../../../../components/ui/Button";
import EventCard from "../../../../components/ui/EventCard";
import { useProfile } from "../../../../contexts";
import useApi from "../../../../hooks/useApi";
import loadingZo from "./../../../../assets/lottie/loading-zo.json";

interface EventsListProps {}

const timeSorted = (a: ZoEvent, b: ZoEvent) =>
  +new Date(a.start_time) - +new Date(b.start_time);

const clearEvents = () => {
  const arr: Array<any> = [];
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i)?.substring(0, 3) === "events-") {
      arr.push(localStorage.key(i));
    }
  }
  for (let i = 0; i < arr.length; i++) {
    localStorage.removeItem(arr[i]);
  }
};

const EventsList: React.FC<EventsListProps> = () => {
  const api = useApi("ZOWORLD_ROOM_EVENT_UPCOMING");
  const { profile } = useProfile();

  const [loading, setLoading] = useState<boolean>(true);
  const [todayEvents, setTodayEvents] = useState<ZoEvent[]>([]);
  const [tomorrowEvents, setTomorrowEvents] = useState<ZoEvent[]>([]);
  const [laterEvents, setLaterEvents] = useState<ZoEvent[]>([]);
  const [editModalVisible, setEditModal] = useState<ZoEvent | null | "new">();

  const handleEdit = (event: ZoEvent) => {
    setEditModal(event);
  };

  const handleEvent = (updated: boolean) => {
    if (updated) {
      fetchEvents();
    }
    setEditModal(null);
  };

  const fetchEvents = () => {
    const today = moment();
    const tomorrow = moment().add(1, "day");
    setLoading(true);

    api
      ?.get("")
      .then((res) => {
        const _events = res.data.room_events;
        console.log("Events:", _events);
        clearEvents();

        const _todayEvents = _events
          .filter((e: ZoEvent) => today.isSame(moment(e.start_time), "day"))
          .sort(timeSorted);
        const _tomorrowEvents = _events
          .filter((e: ZoEvent) => tomorrow.isSame(moment(e.start_time), "day"))
          .sort(timeSorted);
        const _laterEvents = _events
          .filter((e: ZoEvent) => moment(e.start_time).isAfter(tomorrow, "day"))
          .sort(timeSorted);

        setTodayEvents(_todayEvents);
        setTomorrowEvents(_tomorrowEvents);
        setLaterEvents(_laterEvents);
        setLoading(false);
        localStorage.setItem(
          `events-${moment().format("YYYY-MM-DD")}`,
          JSON.stringify({
            today: _todayEvents,
            tomorrow: _tomorrowEvents,
            later: _laterEvents,
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    let localEvents = localStorage.getItem(
      `events-${moment().format("YYYY-MM-DD")}`
    );
    if (localEvents) {
      let localEventsJ = JSON.parse(localEvents);
      setTodayEvents(localEventsJ.today || []);
      setTomorrowEvents(localEventsJ.tomorrow || []);
      setLaterEvents(localEventsJ.later || []);
    }
  }, []);

  useEffect(() => {
    if (api) {
      fetchEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <section className="flex-1 px-4 overflow-y-auto overflow-x-hidden max-w-lg w-full mx-auto">
      {loading ? (
        <Flex col items="center" className="pt-12 px-4">
          <Lottie
            animationData={loadingZo}
            loop
            autoPlay
            className="w-16 h-16"
          />
          <span className="text-zo-mid-grey mt-4 font-bold">
            Looking for events
          </span>
        </Flex>
      ) : (
        <>
          <article>
            <h3 className="font-semibold uppercase tracking-wide text-gray-800 text-sm">
              Today
            </h3>
            <ul>
              {todayEvents.length > 0 ? (
                todayEvents.map((e) => (
                  <EventCard
                    onEdit={
                      profile?.code === e.host ? handleEdit.bind(null, e) : null
                    }
                    key={e.id}
                    data={e}
                    dateFormat="LT"
                  />
                ))
              ) : (
                <li className="w-full text-center font-medium text-gray-700 mt-2 text-sm">
                  No events scheduled for today.
                </li>
              )}
            </ul>
          </article>
          {tomorrowEvents.length > 0 && (
            <article className="pt-8">
              <h3 className="font-semibold uppercase tracking-wide text-gray-800 text-sm">
                Tomorrow
              </h3>
              <ul>
                {tomorrowEvents.map((e) => (
                  <EventCard
                    onEdit={
                      profile?.code === e.host ? handleEdit.bind(null, e) : null
                    }
                    key={e.id}
                    data={e}
                    dateFormat="LT"
                  />
                ))}
              </ul>
            </article>
          )}
          {laterEvents.length > 0 && (
            <article className="pt-8">
              <h3 className="font-semibold uppercase tracking-wide text-gray-800 text-sm">
                Later
              </h3>
              <ul>
                {laterEvents.map((e) => (
                  <EventCard
                    onEdit={
                      profile?.code === e.host ? handleEdit.bind(null, e) : null
                    }
                    key={e.id}
                    data={e}
                    dateFormat="LLLL"
                  />
                ))}
              </ul>
            </article>
          )}
          {todayEvents.length + tomorrowEvents.length + laterEvents.length >
            0 && (
            <article className="pt-4">
              <ul>
                <li className="w-full text-center font-medium text-gray-700 mt-2 text-sm">
                  And many more...
                  <br />
                  Stay Tuned!
                </li>
              </ul>
            </article>
          )}
          <Button
            type="primary"
            className="fixed bottom-0 right-0 m-4"
            icon={<Plus className="w-4 h-4" />}
            onClick={setEditModal.bind(null, "new")}
          >
            Add your event
          </Button>
          <EventEditSheet
            open={!!editModalVisible}
            data={editModalVisible || null}
            onDismiss={handleEvent}
          />
        </>
      )}
    </section>
  );
};

export default EventsList;
