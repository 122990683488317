// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    type: "members-list",
    rowSpan: 1,
    colSpan: 6,
    data: {
      title: "Destination Hosts",
    },
  },
  {
    type: "portal",
    rowSpan: 1,
    colSpan: 2,
  },
  {
    type: "weather",
    rowSpan: 1,
    colSpan: 2,
  },
  {
    type: "photo-otd-potrait",
    rowSpan: 4,
    colSpan: 2,
    data: {
      title: "Recent Visuals",
    },
  },
  {
    type: "hot-deal",
    locked: `Unlocks after 10K Local Holders`,
    rowSpan: 2,
    colSpan: 2,
    data: {
      title: "Local Shop",
    },
  },
  {
    type: "zobu-otd",
    rowSpan: 2,
    colSpan: 1,
    data: {
      title: "Local Attire (M)",
      key: "zobu_male",
    },
  },
  {
    type: "zobu-otd",
    rowSpan: 2,
    colSpan: 1,
    data: {
      title: "Local Attire (F)",
      key: "zobu_female",
    },
  },
  {
    type: "services",
    rowSpan: 1,
    colSpan: 2,
  },
  {
    type: "content-otd",
    accent: "blue-500",
    rowSpan: 2,
    colSpan: 2,
    data: {
      title: "Local News",
    },
  },
  {
    type: " BlogArticles",
    rowSpan: 2,
    colSpan: 2,
  },
  {
    type: "life-at",
    rowSpan: 1,
    colSpan: 2,
  },
  {
    type: "talk-to-host",
    rowSpan: 1,
    colSpan: 2,
  },
];
