import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

import Portal from "../../../../ui/Portal";
import useApi from "../../../../hooks/useApi";
import EventCard from "./components/EventCard";
import HostEventModal from "../../modals/HostEvent";
import ProfileContext from "../../../../contexts/profile";

const timeSorted = (a, b) => +new Date(a.start_time) - +new Date(b.start_time);

const clearEvents = () => {
  const arr = [];
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i).substring(0, 3) === "events-") {
      arr.push(localStorage.key(i));
    }
  }
  for (let i = 0; i < arr.length; i++) {
    localStorage.removeItem(arr[i]);
  }
};

const EventsDisplay = ({ close, sendMessage }) => {
  const api = useApi("zo world", true);
  const { profile } = useContext(ProfileContext);

  const [hostEventModalVisible, setHostEventModalVisible] = useState(false);
  const [editModal, setEditModal] = useState(null);

  const [todayEvents, setTodayEvents] = useState([]);
  const [tomorrowEvents, setTomorrowEvents] = useState([]);
  const [laterEvents, setLaterEvents] = useState([]);

  useEffect(() => {
    let localEvents = localStorage.getItem(
      `events-${moment().format("YYYY-MM-DD")}`
    );
    if (localEvents) {
      localEvents = JSON.parse(localEvents);
      setTodayEvents(localEvents.today || []);
      setTomorrowEvents(localEvents.tomorrow || []);
      setLaterEvents(localEvents.later || []);
    }
  }, []);

  const fetchEvents = () => {
    const today = moment();
    const tomorrow = moment().add(1, "day");

    api
      .get("/zoworld/api/v1/room/event/upcoming/")
      .then((res) => {
        const _events = res.data.room_events;
        console.log("Events:", _events);
        clearEvents();

        const _todayEvents = _events
          .filter((e) => today.isSame(moment(e.start_time), "day"))
          .sort(timeSorted);
        const _tomorrowEvents = _events
          .filter((e) => tomorrow.isSame(moment(e.start_time), "day"))
          .sort(timeSorted);
        const _laterEvents = _events
          .filter((e) => moment(e.start_time).isAfter(tomorrow, "day"))
          .sort(timeSorted);

        setTodayEvents(_todayEvents);
        setTomorrowEvents(_tomorrowEvents);
        setLaterEvents(_laterEvents);

        localStorage.setItem(
          `events-${moment().format("YYYY-MM-DD")}`,
          JSON.stringify({
            today: _todayEvents,
            tomorrow: _tomorrowEvents,
            later: _laterEvents,
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (api) {
      fetchEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  const handleEdit = (event) => {
    setEditModal(event);
    console.log(event);
  };

  return (
    <Portal>
      <section className="w-full h-full fixed top-0 left-0 z-70">
        <div
          className="w-full h-full absolute top-0 left-0"
          onClick={close}
        ></div>
        <aside className="fixed w-full h-full overflow-y-scroll shadow-xl top-0 right-0 bg-blur sm:w-108 fadeInLeft">
          <div className="flex justify-end"></div>
          <div className="p-4">
            <header className="flex justify-between items-center">
              <h2 className="font-bold text-xl sm:text-2xl">Zo World Events</h2>

              <button
                className="bg-orange p-2 text-sm sm:text-base rounded-lg shadow-md text-white font-medium flex items-center"
                onClick={setHostEventModalVisible.bind(null, true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current w-6 h-6 mr-2"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z" />
                </svg>
                Host an Event
              </button>
              <button className="" onClick={close}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
                </svg>
              </button>
            </header>
            <article className="pt-4">
              <h3 className="font-semibold uppercase tracking-wide text-gray-800 text-sm">
                Today
              </h3>
              <ul>
                {todayEvents.length > 0 ? (
                  todayEvents.map((e) => (
                    <EventCard
                      handleEdit={
                        profile.code === e.host
                          ? handleEdit.bind(null, e)
                          : null
                      }
                      key={e.id}
                      data={e}
                      dateFormat="LT"
                      sendMessage={sendMessage}
                    />
                  ))
                ) : (
                  <li className="w-full text-center font-medium text-gray-700 mt-2 text-sm">
                    No events scheduled for today.
                  </li>
                )}
              </ul>
            </article>
            {tomorrowEvents.length > 0 && (
              <article className="pt-8">
                <h3 className="font-semibold uppercase tracking-wide text-gray-800 text-sm">
                  Tomorrow
                </h3>
                <ul>
                  {tomorrowEvents.map((e) => (
                    <EventCard
                      handleEdit={
                        profile.code === e.host
                          ? handleEdit.bind(null, e)
                          : null
                      }
                      key={e.id}
                      data={e}
                      dateFormat="LT"
                    />
                  ))}
                </ul>
              </article>
            )}
            {laterEvents.length > 0 && (
              <article className="pt-8">
                <h3 className="font-semibold uppercase tracking-wide text-gray-800 text-sm">
                  Later
                </h3>
                <ul>
                  {laterEvents.map((e) => (
                    <EventCard
                      handleEdit={
                        profile.code === e.host
                          ? handleEdit.bind(null, e)
                          : null
                      }
                      key={e.id}
                      data={e}
                      dateFormat="LLLL"
                    />
                  ))}
                </ul>
              </article>
            )}
            {todayEvents.length + tomorrowEvents.length + laterEvents.length >
              0 && (
              <article className="pt-4">
                <ul>
                  <li className="w-full text-center font-medium text-gray-700 mt-2 text-sm">
                    And many more...
                    <br />
                    Stay Tuned!
                  </li>
                </ul>
              </article>
            )}
          </div>
        </aside>
        {(hostEventModalVisible || editModal != null) && (
          <HostEventModal
            data={editModal}
            close={() => {
              setEditModal(null);
              setHostEventModalVisible(false);
              fetchEvents();
            }}
          />
        )}
      </section>
    </Portal>
  );
};

export default EventsDisplay;
