import React, { useEffect, useState } from "react";

import SeedContext from ".";
import useApi from "../../hooks/useApi";

const SeedProvider = ({ children }) => {
  const api = useApi("profile", false);
  const [avatarTools, setAvatarTools] = useState({ categories: [], bases: [] });
  const [seedData, setSeedData] = useState({});
  const [backgrounds, setBackgrounds] = useState({});
  const [mobileBackgrounds, setMobileBackgrounds] = useState({});
  const [musicFiles, setMusicFiles] = useState({});
  const [abuseTypes, setAbuseTypes] = useState([]);
  console.log("SeedProvider", api);

  useEffect(() => {
    if (api) {
      api
        .get("/profile/api/v1/seed/")
        .then((res) => {
          console.log("Profile Seed", res);
          if (res.status === 200) {
            const data = res.data;
            setAvatarTools(data.avatar);
            setSeedData(data);
            if (data.background) {
              const _bgs = {};
              const _mbgs = {};
              data.background.forEach(({ key, file, file_vertical }) => {
                _bgs[key] = file;
                _mbgs[key] = file_vertical || file;
              });
              setBackgrounds(_bgs);
              setMobileBackgrounds(_mbgs);
            }
            if (data.music) {
              const _mscs = {};
              data.music.forEach((m) => {
                _mscs[m.key] = m;
              });
              setMusicFiles(_mscs);
            }
            if (data.report_user) {
              setAbuseTypes(data.report_user.types);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <SeedContext.Provider
      value={{
        avatarTools,
        seedData,
        backgrounds,
        musicFiles,
        mobileBackgrounds,
        abuseTypes,
      }}
    >
      {children}
    </SeedContext.Provider>
  );
};

export default SeedProvider;
