import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Modal from "./../../../../../../../ui/Modal";
import ProfileContext from "./../../../../../../../contexts/profile";
import PlayerCount from "./components/PlayerCount";
import CharacterCount from "./components/CharacterCount";
import useResponseFlash from "./../../../../../../Zo/components/hooks/useResponseFlash";
import useApi from "./../../../../../../../hooks/useApi";
import roomsConfig from "./../../../../../../../../configs/mafia/rooms.json";

const HostRoom = ({ close, visible }) => {
  const { profile } = useContext(ProfileContext);
  const api = useApi("games", true);
  const history = useHistory();
  const { response, setResponse } = useResponseFlash();

  const [roomName, setRoomName] = useState("Mafia Room");
  const [players, setPlayers] = useState(8);
  const [mafiaCount, setMafiaCount] = useState(2);
  const [mafiaSemiCount, setMafiaSemiCount] = useState(0);
  const [mafiaRevealerCount, setMafiaRevealerCount] = useState(0);
  const [mafiaFlagbearerCount, setMafiaFlagbearerCount] = useState(0);
  const [detectiveCount, setDetectiveCount] = useState(1);
  const [healerCount, setHealerCount] = useState(1);
  const [villagerCount, setVillagerCount] = useState(4);
  const [villagerSaveCount, setVillagerSaveCount] = useState(0);
  const [villagerRevengeCount, setVillagerRevengeCount] = useState(0);
  const [type, setType] = useState("public");
  const [gameFlow, setgameFlow] = useState("initial_night");

  const [sum, setSum] = useState(8);

  useEffect(() => {
    setSum(
      +mafiaCount +
        +mafiaSemiCount +
        +mafiaRevealerCount +
        +mafiaFlagbearerCount +
        +detectiveCount +
        +healerCount +
        +villagerCount +
        +villagerSaveCount +
        +villagerRevengeCount
    );
  }, [
    mafiaCount,
    detectiveCount,
    healerCount,
    villagerCount,
    mafiaSemiCount,
    villagerSaveCount,
    mafiaRevealerCount,
    villagerRevengeCount,
    mafiaFlagbearerCount,
  ]);

  useEffect(() => {
    if (roomsConfig[players]) {
      const counts = roomsConfig[players];
      setMafiaCount(counts.mafiaCount);
      setMafiaSemiCount(counts.mafiaSemiCount);
      setMafiaRevealerCount(counts.mafiaRevealerCount);
      setMafiaFlagbearerCount(counts.mafiaFlagbearerCount);
      setDetectiveCount(counts.detectiveCount);
      setHealerCount(counts.healerCount);
      setVillagerCount(counts.villagerCount);
      setVillagerSaveCount(counts.villagerSaveCount);
      setVillagerRevengeCount(counts.villagerRevengeCount);
      setgameFlow(counts.gameFlow);
    }
  }, [players]);

  useEffect(() => {
    if (profile && profile.nickname) {
      setRoomName(`${profile.nickname}'s Mafia Room`);
    }
  }, [profile]);

  const hostGame = async () => {
    if (sum !== players) {
      setResponse(`The sum of characters should be equal to ${players}.`);
      return;
    }
    try {
      const data = {
        data: {
          players_count: players,
          name: roomName,
          mafia_count: mafiaCount,
          mafia_semi_count: mafiaSemiCount,
          mafia_revealer_count: mafiaRevealerCount,
          mafia_flagbearer_count: mafiaFlagbearerCount,
          detective_count: detectiveCount,
          healer_count: healerCount,
          villager_count: villagerCount,
          villager_save_count: villagerSaveCount,
          villager_revenge_count: villagerRevengeCount,
          game_flow: gameFlow,
        },
        type,
      };
      const response = await api.post(
        "/games/api/v1/mafia/room/",
        JSON.stringify(data)
      );
      console.log(response);
      if (response.status === 200) {
        const roomData = response.data.room;
        history.push("/r/" + roomData.code);
      }
    } catch (error) {
      setResponse("Error in creating the room");
      console.log(error);
    }
  };

  return (
    <Modal visible={visible} close={close}>
      <div
        className="p-4 border-t-4 border-mafia-secondary rounded-lg shadow-md bg-mafia-primary text-white"
        style={{ maxWidth: "40rem", height: "max-content" }}
      >
        <div className="font-bold mb-2 pb-2 text-center text-xl text-white uppercase tracking-normal border-b-2 border-mafia-background">
          Host a Mafia Room
        </div>
        <div className="flex flex-col items-center px-4">
          <div className="flex items-center justify-between w-full mt-4">
            <div className="font-bold w-1/3 whitespace-no-wrap">Room Name</div>
            <div className="flex flex-grow w-2/3">
              <input
                className="px-4 py-2 bg-transparent border rounded-lg w-full"
                type="text"
                placeholder="Enter Room Name"
                value={roomName}
                onChange={(e) => setRoomName(e.target.value)}
              />
            </div>
          </div>
          <div className="flex items-center mt-4 justify-between w-full">
            <div className="font-bold w-1/3 whitespace-no-wrap">Players</div>
            <div className="w-2/3 flex">
              <PlayerCount
                value={players}
                options={[3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                onChange={setPlayers}
              />
            </div>
          </div>
          <div className="flex items-center mt-4 justify-between w-full">
            <div className="w-1/3 whitespace-no-wrap">Mafia</div>
            <CharacterCount
              sum={sum}
              players={players}
              value={mafiaCount}
              onChange={setMafiaCount}
            />
          </div>
          <div className="flex items-center mt-4 justify-between w-full">
            <div className="w-1/3 whitespace-no-wrap">Detective</div>
            <CharacterCount
              sum={sum}
              players={players}
              value={detectiveCount}
              onChange={setDetectiveCount}
            />
          </div>
          <div className="flex items-center mt-4 justify-between w-full">
            <div className="w-1/3 whitespace-no-wrap">Healer</div>
            <CharacterCount
              sum={sum}
              players={players}
              value={healerCount}
              onChange={setHealerCount}
            />
          </div>
          <div className="flex items-center mt-4 justify-between w-full">
            <div className="w-1/3 whitespace-no-wrap">Villagers</div>
            <CharacterCount
              sum={sum}
              players={players}
              value={villagerCount}
              onChange={setVillagerCount}
            />
          </div>
          <div className="flex items-center mt-4 justify-between w-full">
            <div className="w-1/3 whitespace-no-wrap">Room type</div>
            <div className="w-2/3 flex">
              <div
                className={`w-auto h-auto p-2 leading-none cursor-pointer flex items-center mr-2 justify-center rounded-lg ${
                  type === "public"
                    ? "bg-mafia-background text-white shadow-md"
                    : "text-white"
                }`}
                onClick={setType.bind(null, "public")}
              >
                Public
              </div>
              <div
                className={`w-auto h-auto p-2 leading-none cursor-pointer flex items-center justify-center rounded-lg ${
                  type === "private"
                    ? "bg-mafia-background text-white shadow-md"
                    : "text-white"
                }`}
                onClick={setType.bind(null, "private")}
              >
                Private
              </div>
            </div>
          </div>
          <div className="flex items-center mt-4 justify-between w-full">
            <div className="w-1/3 whitespace-no-wrap">Initial Round</div>
            <div className="w-2/3 flex">
              <div
                className={`w-auto h-auto p-2 leading-none cursor-pointer whitespace-no-wrap flex items-center mr-2 justify-center rounded-lg ${
                  gameFlow === "initial_night"
                    ? "bg-mafia-background text-white shadow-md"
                    : "text-white"
                }`}
                onClick={setgameFlow.bind(null, "initial_night")}
              >
                Start with Night
              </div>
              <div
                className={`w-auto h-auto p-2 leading-none cursor-pointer whitespace-no-wrap flex items-center justify-center rounded-lg ${
                  gameFlow === "initial_day"
                    ? "bg-mafia-background text-white shadow-md"
                    : "text-white"
                }`}
                onClick={setgameFlow.bind(null, "initial_day")}
              >
                Start with Day
              </div>
              <div
                className={`w-auto h-auto p-2 leading-none cursor-pointer whitespace-no-wrap flex items-center mr-2 justify-center rounded-lg ${
                  gameFlow === "only_day"
                    ? "bg-mafia-background text-white shadow-md"
                    : "text-white"
                }`}
                onClick={setgameFlow.bind(null, "only_day")}
              >
                Day only
              </div>
            </div>
          </div>
          <div className="h-8 flex items-center justify-center font-semibold text-xs text-mafia-secondary">
            {response}
          </div>
          <button
            className="text-lg uppercase tracking-wide text-mafia-primary bg-mafia-secondary px-4 py-3 leading-none rounded-lg hover:shadow-lg font-semibold"
            onClick={hostGame}
          >
            Host a room
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default HostRoom;
