import React, { useCallback, useContext, useMemo } from "react";
import { useRTC } from "../../../../../contexts";
import InGameContext from "../../../contexts/inGame";
import Player from "./Player";

const Players = () => {
  const { playersStates, roomRound, roomStatus, player } =
    useContext(InGameContext);
  const { peers, isSelfMute, isActive } = useRTC();

  const showVote = useCallback(
    (role, status) => {
      if (roomStatus === "ended_mafia" || roomStatus === "ended_village") {
        return false;
      }
      if (status === "alive") {
        if (roomRound === "night") {
          if (
            role === player.role &&
            player.role &&
            player.role.includes("mafia")
          ) {
            return true;
          } else {
            return false;
          }
        } else if (roomRound === "day") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    [player.role, roomRound, roomStatus]
  );

  return useMemo(
    () => (
      <div className="absolute inset-0 w-full h-full">
        {Object.values(playersStates).map((_player) => {
          const vote =
            _player.vote !== null &&
            _player.vote !== -1 &&
            showVote(_player.role, _player.status)
              ? playersStates[_player.vote]
                ? playersStates[_player.vote].nickname
                : null
              : null;

          const connected =
            _player.nickname === player.nickname
              ? peers.find((p) => p.display === _player.nickname) || isActive
              : peers.find((p) => p.display === _player.nickname);

          const speaking = connected
            ? connected.status === "talking"
              ? true
              : false
            : false;

          const muted =
            _player.nickname === player.nickname
              ? isSelfMute
              : connected
              ? connected.muted
              : false;

          return (
            <Player
              key={_player.id}
              avatar={_player.avatar}
              nickname={_player.nickname}
              isUser={_player.id === player.id}
              userRole={player.role}
              role={_player.role}
              vote={vote}
              isNight={roomRound === "night"}
              isInGame={
                roomRound !== "game_starting" && roomRound !== "identity"
              }
              connected={connected}
              status={_player.status}
              speaking={speaking}
              muted={muted}
            />
          );
        })}
      </div>
    ),
    [
      playersStates,
      showVote,
      player.nickname,
      player.id,
      player.role,
      isActive,
      peers,
      isSelfMute,
      roomRound,
    ]
  );
};

export default Players;
