import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import leftArrow from "../../../../../../assets/arrow-left.svg";
import rightArrow from "../../../../../../assets/arrow-right.svg";

import useApi from "../../../../../hooks/useApi";
import Widget from "../../ui/Widget";

const DestinationUnlocked = (props) => {
  const [index, setIndex] = useState(0);
  const destinationsRef = useRef();
  const api = useApi("webapp", false);
  const [destinations, setDestinations] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let mounted = true;
    if (api) {
      api
        .get("/api/v1/stay/destinations/?status=unlocked")
        .then((res) => {
          if (mounted) {
            console.log(res.data);
            setDestinations(res.data.destinations);
            setTotal(res.data.total);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  useEffect(() => {
    let mounted = true;
    const children = destinationsRef.current.children;
    setTimeout(() => {
      const _index = (index + 1) % (children.length - 1);
      if (mounted) {
        setIndex(_index);
      }
    }, 3000);

    return () => {
      mounted = false;
    };
  }, [index]);

  return (
    <Widget
      className="zo-destinations portrait:h-84 p-2 bg-white border-t-4 border-orange outerBox"
      {...props}
    >
      <div className="flex items-baseline justify-between">
        <div className="font-bold">Destinations Unlocked</div>
        {destinations.length > 0 && (
          <span className="text-l font-bold ">
            {" "}
            {destinations.length}/{total}
          </span>
        )}
      </div>
      <div
        className="arrow-container flex flex-row w-full absolute mt-10 p-2 left-0 h-0"
        style={{ justifyContent: "space-between", zIndex: "1" }}
      >
        <span
          className="prev rounded-full  h-10 w-10 flex items-center justify-center  bg-white bg-opacity-25 text-white cursor-pointer"
          onClick={() => {
            setIndex(index >= 1 ? index - 1 : destinations.length - 1);
          }}
        >
          {" "}
          <img className="w-12 h-12 text-white" src={leftArrow} alt="" />{" "}
        </span>
        <span
          className="next rounded-full h-10 w-10 flex items-center justify-center  bg-white bg-opacity-25 text-white cursor-pointer"
          onClick={() => {
            setIndex(index < destinations.length - 1 ? index + 1 : 0);
          }}
        >
          {" "}
          <img className="w-12 h-12 text-white" src={rightArrow} alt="" />{" "}
        </span>
      </div>
      <div
        className="relative flex flex-no-wrap items-center justify-center"
        ref={destinationsRef}
      >
        {destinations.map((d, i) => (
          <div
            key={d.id}
            className={`absolute flex flex-col cursor-pointer items-center justify-center top-0 left-0 w-full ${
              index === i
                ? "opacity-100 pointer-events-auto"
                : "opacity-0 pointer-events-none"
            }`}
            onClick={() => {
              window.open(
                `https://${d.slug}.${process.env.REACT_APP_ROOT_DOMAIN}`,
                "_parent"
              );
            }}
          >
            <img
              className="block object-contain object-bottom w-full portrait:h-56 h-32 my-2"
              src={d.thumbnail}
              alt=""
            />
            <div className="flex flex-col items-center">
              <div className="">{d.name}</div>
              <div
                className="text-sm text-gray-700"
                dangerouslySetInnerHTML={{
                  __html: moment(d.unlock_date)
                    .format("Do MMMM YYYY")
                    .replace(/(\d)(st|nd|rd|th)/g, "$1<sup>$2</sup>"),
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </Widget>
  );
};

export default DestinationUnlocked;
