import React, { useMemo } from "react";
import Flex from "../../../../components/structure/Flex";
import About from "../../../../components/widgets/About";
import ChatSettings from "../../../../components/widgets/ChatSettings";
import CitizensList from "../../../../components/widgets/CitizensList";
import MafiaActiveMatches from "../../../../components/widgets/MafiaActiveMatches";
import MafiaLeaderboard from "../../../../components/widgets/MafiaLeaderboard";
import MafiaQueue from "../../../../components/widgets/MafiaQueue/MafiaQueue";
import RoomSettings from "../../../../components/widgets/RoomSettings";
import Whiteboard from "../../../../components/widgets/Whiteboard";
import { useRoom } from "../../../../contexts";

interface WidgetsProps {
  active: boolean;
}

const Widgets: React.FC<WidgetsProps> = ({ active }) => {
  const { widgets } = useRoom();
  const widgetNames = widgets.map((w) => w.widget);
  return useMemo(
    () => (
      <Flex
        col
        items="start"
        justify="start"
        className={`absolute md:relative md:pl-2 md:flex-1 md:content-start overflow-y-auto overflow-x-hidden md:overflow-x-auto md:oveflow-y-hidden inset-0 ${
          active
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
        style={window.innerWidth >= 768 ? { flexFlow: "column wrap" } : {}}
      >
        {widgetNames.includes("audio-members") && window.innerWidth < 768 && (
          <CitizensList />
        )}
        {widgetNames.includes("room-settings") && <RoomSettings />}
        {widgetNames.includes("mafia-joining-queue") && <MafiaQueue />}
        {widgetNames.includes("mafia-active-matches") && <MafiaActiveMatches />}
        {widgetNames.includes("mafia-leaderboard") && <MafiaLeaderboard />}
        {widgetNames.includes("chat") && <ChatSettings />}
        {widgetNames.includes("about-me") && <About />}
        {widgetNames.includes("whiteboard") && <Whiteboard />}
      </Flex>
    ),
    [active, widgetNames]
  );
};

export default Widgets;
