import React from "react";

import withMemo from "../../../../../utils/withMemo";
import RoomName from "./components/RoomName";
import RoomTimer from "./components/RoomTimer";
import RoundTimer from "./components/RoundTimer";
import RoundName from "./components/RoundName";
import Population from "./components/Population";

const Header = withMemo(() => {
  return (
    <header
      className="w-screen flex items-start px-2 pt-2"
      style={{ height: "max-content", maxHeight: "7.5rem" }}
    >
      <div className="w-1/3 flex flex-col items-start">
        <RoomName />
        <RoomTimer />
      </div>
      <div className="w-1/3 flex flex-col items-center">
        <RoundName />
        <RoundTimer />
      </div>
      <div className="w-1/3">
        <Population />
      </div>
    </header>
  );
}, []);

export default Header;
