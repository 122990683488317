import React, { useEffect, useMemo, useState } from "react";
import { useProfile, useRoom, useRTC } from "../../../contexts";
import { getDetailedCitizens } from "../../../utils/room";
import Flex from "../../structure/Flex";
import Citizen from "../../ui/Citizen";
import CitizenCard from "../../ui/CitizenCard";
import Widget from "../../ui/Widget";

interface CitizensListProps {
  expand?: boolean;
}

const CitizensList: React.FC<CitizensListProps> = ({ expand }) => {
  const { citizens, room, sendMessage } = useRoom();
  const { profile } = useProfile();
  const { peers, isActive, isSelfMute } = useRTC();

  const [keyboardFocused, setKeyboardFocused] = useState<boolean>(false);
  const [focussedCitizen, setFocussedCitizen] =
    useState<DetailedCitizen | null>(null);

  const toggleKeyboardFocused = () => {
    setKeyboardFocused((v) => !v);
  };

  const roomCode = useMemo(() => room?.code, [room]);

  const isUserHost = useMemo(
    () => room?.nickname === profile?.nickname,
    [profile, room]
  );

  const citizensWithData = useMemo(
    () =>
      profile &&
      getDetailedCitizens(
        citizens,
        roomCode,
        profile.code,
        profile.nickname,
        peers,
        isActive,
        isSelfMute
      ),
    [profile, citizens, roomCode, peers, isActive, isSelfMute]
  );

  const kickCitizen = (code: string, nickname: string) => {
    sendMessage({
      action: "member_kick",
      payload: {
        code,
        nickname,
      },
    });
    setFocussedCitizen(null);
  };

  const requestUnmute = (code: string, nickname: string) => {
    sendMessage({
      action: "unmute_request",
      payload: {
        code,
        nickname,
      },
    });
    setFocussedCitizen(null);
  };

  const muteCitizen = (code: string, nickname: string) => {
    sendMessage({
      action: "member_mute",
      payload: {
        code,
        nickname,
      },
    });
    setFocussedCitizen(null);
  };

  useEffect(() => {
    if (window.innerWidth < 640) {
      document
        .querySelector("#chat-input")
        ?.addEventListener("focus", toggleKeyboardFocused);
      document
        .querySelector("#chat-input")
        ?.addEventListener("blur", toggleKeyboardFocused);
    }

    return () => {
      document
        .querySelector("#chat-input")
        ?.removeEventListener("focus", toggleKeyboardFocused);
      document
        .querySelector("#chat-input")
        ?.removeEventListener("blur", toggleKeyboardFocused);
    };
  }, []);

  return keyboardFocused ? null : (
    <>
      <Widget title="Zobus Live" id="audio-members">
        {() => (
          <div
            className="overflow-y-auto overflow-x-hidden flex items-center justify-start content-start flex-wrap"
            style={{ maxHeight: !expand ? "9.5rem" : "calc(100vh - 9rem)" }}
          >
            {citizensWithData && citizensWithData.length > 0 ? (
              citizensWithData
                .sort((x, y) => y.priority - x.priority)
                .map((c) => (
                  <Citizen
                    data={c}
                    key={c.code}
                    onClick={setFocussedCitizen.bind(null, c)}
                  />
                ))
            ) : (
              <Flex
                items="center"
                justify="center"
                className="text-sm text-zo-mid-grey font-bold py-2 pl-2"
              >
                Fetching zobus ...
              </Flex>
            )}
          </div>
        )}
      </Widget>
      {focussedCitizen != null && (
        <CitizenCard
          data={focussedCitizen}
          isUserHost={isUserHost}
          onDismiss={setFocussedCitizen.bind(null, null)}
          onKick={kickCitizen.bind(
            null,
            focussedCitizen.code,
            focussedCitizen.nickname
          )}
          onRequestUnmute={requestUnmute.bind(
            null,
            focussedCitizen.code,
            focussedCitizen.nickname
          )}
          onMute={muteCitizen.bind(
            null,
            focussedCitizen.code,
            focussedCitizen.nickname
          )}
        />
      )}
    </>
  );
};

export default CitizensList;
