import React, { useMemo } from "react";

const ua = navigator.userAgent.toLowerCase();
let isSafari = false;
if (ua.indexOf("safari") !== -1) {
  if (ua.indexOf("chrome") > -1 || ua.indexOf("edg") > -1) {
    // alert("1") // Chrome
  } else {
    isSafari = true;
  }
}

const useAudio = (url, loop, volume) => {
  // create audio only once
  const audio = useMemo(() => new Audio(url), [url]);

  // same as React.Component.forceUpdate
  const [, _forceUpdate] = React.useState(false);
  const forceUpdate = () => _forceUpdate((prevState) => !prevState);

  React.useEffect(() => {
    audio.addEventListener("play", forceUpdate);
    audio.addEventListener("pause", forceUpdate);
    audio.addEventListener("ended", forceUpdate);
    audio.addEventListener("timeupdate", forceUpdate);
    audio.volume = volume;
    audio.loop = loop;

    return () => {
      audio.removeEventListener("play", forceUpdate);
      audio.removeEventListener("pause", forceUpdate);
      audio.removeEventListener("ended", forceUpdate);
      audio.addEventListener("timeupdate", forceUpdate);
    };

    /**
     * about useEffect's second argument
     *    give nothing        => same as componentDidUpdate
     *    give an empty array => same as componentWillMount
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const play = () => {
    try {
      if (!audio.paused) {
        audio.currentTime = 0;
      }
      if (!isSafari) {
        audio.play();
      }
    } catch (error) {
      console.log("Audio error", error);
    }
  };
  const pause = () => {
    try {
      audio.pause();
    } catch (error) {}
  };

  return { playing: !audio.paused, play, pause };
};

export default useAudio;
