import React from "react";
import Widget from "../../ui/Widget";

const PageTitle = (props) => {
  return (
    <Widget className="flex items-center shadow-none h-16" {...props}>
      <span className="flex-shrink-0 text-2xl leading-none font-bold zo-text-shadow">
        {props.value}
      </span>
    </Widget>
  );
};

export default PageTitle;
