const normaliseRoomData = (type, data) => {
  if (type === "commonroom") {
    return {
      members: data.members,
      queue_members: data.queue_members,
      socket: data.socket,
      ...data.room,
      type: "commonroom",
    };
  } else if (type === "profile") {
    return {
      members: data.members,
      queue_members: data.queue_members,
      socket: data.socket,
      ...data.profile,
      name: data.profile.lobby_name,
      type: "profile",
    };
  }
};

const getColorCode = () => {
  const COLORS = [
    "text-red-500",
    "text-yellow-800",
    "text-green-500",
    "text-teal-500",
    "text-blue-500",
    "text-indigo-500",
    "text-purple-500",
    "text-pink-500",
    "text-orange",
    "text-teal-800",
  ];
  const index = Math.floor(Math.random() * COLORS.length);

  return COLORS[index];
};

const checkHost = (roomDetails, code) => {
  if (roomDetails.hosts) {
    return roomDetails.hosts.indexOf(code) !== -1;
  } else {
    return roomDetails.code === code;
  }
};

export { normaliseRoomData, getColorCode, checkHost };
