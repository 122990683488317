import React, { useContext, useState, useEffect } from "react";
import Individuals from "./components/Individuals";
import FilterByCult from "./components/Filters/FilterByCult.js";
import Filters from "./components/Filters";
import { SeedContext } from "../../../../../contexts/contexts";
import Widget from "../../ui/Widget";
import useApi from "../../../../../hooks/useApi";

const LeaderBoard = (props) => {
  const api = useApi("zo world", false);
  const { zoSeedData, seedData } = useContext(SeedContext);
  const [selectedDestination, setSelectedDestination] = useState(0);
  const [selectedRole, setSelectedRole] = useState(0);
  const [selectedCult, setSelectedCult] = useState(0);
  const [leaderboardData, setLeaderBoardData] = useState(null);

  //TODO api integration if needed
  useEffect(() => {
    setSelectedCult(selectedCult);
  }, [selectedCult]);

  useEffect(() => {
    if (api && !leaderboardData) {
      try {
        api.get(`/zoworld/api/v1/leaderboard/`).then((res) => {
          setLeaderBoardData(res.data.profiles); //set metrics
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [api, leaderboardData]);

  useEffect(() => {
    if (api) {
      if (selectedDestination || selectedRole) {
        try {
          api
            .get(
              `/zoworld/api/v1/leaderboard/?${
                selectedDestination
                  ? `destination_id=${selectedDestination}`
                  : ""
              }${selectedRole ? `&work_role=${selectedRole}` : ""}`
            )
            .then((res) => {
              setLeaderBoardData(res.data.profiles); //set metrics
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          api.get(`/zoworld/api/v1/leaderboard/`).then((res) => {
            setLeaderBoardData(res.data.profiles); //set metrics
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
  }, [api, selectedDestination, selectedRole]);

  return (
    <Widget
      className="p-0 flex flex-row portrait:flex-col"
      shadow={false}
      scroll={true}
      {...props}
    >
      <div class="sticky top-0 portrait:mb-4">
        <div class=" flex flex-col py-4 mr-4 portrait:mr-0 hover:pointer bg-orange bg-opacity-50 p-4 rounded-lg">
          <p class="mb-3 text-center"> Filter </p>
          <FilterByCult
            onFilterClicked={(cult) => {
              console.log(cult);
            }}
          />
          <Filters
            seedData={seedData}
            zoSeedData={zoSeedData}
            setSelectedDestination={setSelectedDestination}
            selectedDestination={selectedDestination}
            setSelectedRole={setSelectedRole}
            selectedRole={selectedRole}
          />
        </div>
      </div>
      <Individuals
        className="flex-1 bg-opacity-50  bg-gray-400  rounded-md p-2"
        leaderboardData={leaderboardData}
        alt={"Loading..."}
      />
    </Widget>
  );
};

export default LeaderBoard;
