import React, { useEffect } from "react";
import Modal from "../../../../../../../../components/ui/Modal";
import Timer from "../../../Header/components/RoundTimer";
import day_semipriest from "./../../../../assets/day_semipriest.svg";

const DayModal = ({ visible, close, role, extras }) => {
  useEffect(() => {
    let mounted = true;
    if (visible) {
      setTimeout(() => {
        if (mounted) {
          close();
        }
      }, 5000);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return role === "mafia-semi" ? (
    <Modal visible={visible} close={close}>
      <div
        className="bg-gray-200 relative z-40 rounded-lg overflow-hidden shadow-lg mb-10 bg-white bg-no-repeat bg-cover bg-center flex flex-col items-center"
        style={{
          width: "24rem",
          height: "28rem",
          backgroundImage: `url(${day_semipriest})`,
        }}
      >
        <div className="bg-mafia-primary mt-4 w-3/4 mb-40 border-t-2 border-mafia-secondary p-2 rounded-lg shadow-lg flex flex-col items-center">
          <div className="font-bold uppercase tracking-normal text-mafia-secondary text-2xl">
            Day
          </div>
        </div>
        <Timer />
        <div className="text-white text-center mt-4 p-4 text-sm">
          You are a Mafia semi priest.
          <br />
          <span className="text-mafia-secondary font-bold text-2xl">
            Prevent {extras.nickname} from getting lynched
          </span>{" "}
          in this round.
          <br />
          If either you or this player gets lynched, you lose.
        </div>
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default DayModal;
