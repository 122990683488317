import React, { useContext, useState } from "react";

import AppContext from "../../../../contexts/app";
import ProfileContext from "../../../../contexts/profile";
import useApi from "../../../../hooks/useApi";
import useInput from "../../../../hooks/useInput";
import useResponseFlash from "../../../../hooks/useResponseFlash";
import Modal from "../../../../ui/Modal";
import RoomContext from "../../contexts/room";

const TitleModal = ({ close, title }) => {
  const api = useApi("profile", true);
  const commsApi = useApi("comms", true);
  const { updateProfile } = useContext(ProfileContext);
  const { sendSocketMessage } = useContext(RoomContext);
  const { category, slug } = useContext(AppContext);
  const { value: lobbyName, bind: bindLobbyName } = useInput(title);
  const { response, setResponse } = useResponseFlash();

  const [loading, setLoading] = useState(false);

  const onSuccess = (name) => {
    sendSocketMessage({
      action: "update_room_name",
      payload: { name },
    });
    setLoading(false);
    close();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (lobbyName.length > 32) {
      setResponse("Should be <= 32 in length.");
      return;
    }
    if (category === "profile") {
      if (!loading) {
        setLoading(true);
        try {
          const data = {
            lobby_name: lobbyName,
          };
          const response = await api.post(
            `/profile/api/v1/me/`,
            JSON.stringify(data)
          );
          if (response.status === 200) {
            console.log(response);
            updateProfile(data);
            onSuccess(data.lobby_name);
          }
        } catch (error) {
          console.log(error.response);
        }
      }
    } else {
      if (!loading) {
        setLoading(true);
        try {
          const data = {
            name: lobbyName,
          };
          const response = await commsApi.post(
            `/lobby/api/v1/room/${slug || "zo"}/`,
            JSON.stringify(data)
          );
          if (response.status === 200) {
            console.log(response);
            onSuccess(data.name);
          }
        } catch (error) {
          console.log(error.response);
        }
      }
    }
  };

  return (
    <Modal close={close} backgroundTheme="light" visible>
      <div className="w-108 portrait:w-full rounded-lg bg-white border-t-4 border-orange flex flex-col items-center">
        <span className="font-bold text-xl p-4">Change your Room Name</span>
        <div className="bg-gray-100 w-full flex items-center justify-center p-4">
          <form className="flex flex-col w-full" onSubmit={handleSubmit}>
            <div className="flex w-full">
              <input
                className="w-full form-input"
                placeholder="Enter your Room Name"
                {...bindLobbyName}
              />
            </div>
            <div className="w-full h-6 flex items-center justify-start font-semibold text-xs text-orange">
              {response}
            </div>
            <div className="flex mb-4 w-full">
              <button
                className="bg-orange text-white cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
                type="submit"
              >
                {loading ? "Saving" : "Save"}
              </button>
              <button
                className="bg-white text-orange cursor-pointer mr-4 font-medium text-sm px-5 py-3 rounded-lg shadow-sm leading-none hover:shadow-md active:shadow-xs"
                onClick={close}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default TitleModal;
