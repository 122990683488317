export const SOCKET_URL = `wss://av.io.zo.xyz/socket`;

export const JANUS_TOKEN_URL = "https://zo.xyz/janus/auth/token/";

export const SAMPLE_RATE = 16000;
export const AUDIO_LEVEL_EVENTS = true;
export const AUDIO_ACTIVE_PACKETS = 45;
export const AUDIO_LEVEL_AVERAGE = 35;
export const PREBUFFER = 10;
export const QUALITY = 4;
export const VOLUME = 100;

export const KEEPALIVE_INTERVAL = 25000;
