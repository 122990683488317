import { createContext } from "react";

const RoomContext = createContext({
  roomDetails: null,
  members: [],
  connecting: false,
  message: null,
  setToken: (token) => {},
  setMembers: (members) => {},
  setSocketUrl: (subdomain) => {},
  sendSocketMessage: (message) => {},
});

export default RoomContext;
