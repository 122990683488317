import React, { useContext, useEffect, useMemo, useState } from "react";
import AppContext from "../../../../contexts/app";
import ProfileContext from "../../../../contexts/profile";
import useApi from "../../../../hooks/useApi";
import useResponseFlash from "../../../../hooks/useResponseFlash";
import Modal from "../../../../ui/Modal";
import RoomContext from "../../contexts/room";

const copyReferralLink = (link) => {
  const textArea = document.createElement("textarea");
  textArea.value = link;
  textArea.style.position = "fixed";
  textArea.style.top = "-400vh";
  textArea.style.left = "0";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  textArea.setSelectionRange(0, 99999);
  document.execCommand("copy");
  textArea.remove();
};

const randomString = (len) => {
  const charSet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";
  for (let i = 0; i < len; i++) {
    let randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }
  return randomString;
};

const SecurityModal = ({ close, security }) => {
  const api = useApi("profile", true);
  const commsApi = useApi("comms", true);
  const { sendSocketMessage, roomDetails } = useContext(RoomContext);
  const { updateProfile } = useContext(ProfileContext);
  const { category, slug } = useContext(AppContext);
  const {
    response: copiedResponse,
    setResponse: setCopiedResponse,
  } = useResponseFlash();
  const {
    response: newLinkResponse,
    setResponse: setNewLinkResponse,
  } = useResponseFlash();

  const [loading, setLoading] = useState(false);
  const [passwordToken, setPasswordToken] = useState(null);

  useEffect(() => {
    if (api) {
      api
        .get("/profile/api/v1/me/lobby/")
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data.token);
            setPasswordToken(res.data.token);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [api]);

  const onSuccess = (level) => {
    sendSocketMessage({
      action: "update_security",
      payload: { security: level },
    });
    setLoading(false);
  };

  const handleLevelUpdate = async (level) => {
    if (level > 0 && level < 4) {
      if (category === "profile") {
        if (!loading) {
          setLoading(true);
          try {
            const data = {
              security: level,
            };
            const response = await api.post(
              `/profile/api/v1/me/`,
              JSON.stringify(data)
            );
            if (response.status === 200) {
              console.log(response);
              updateProfile(data);
              onSuccess(level);
            }
          } catch (error) {
            console.log(error.response);
          }
        }
      } else {
        if (!loading) {
          setLoading(true);
          try {
            const data = {
              security: level,
            };
            const response = await commsApi.post(
              `/lobby/api/v1/room/${slug || "zo"}/`,
              JSON.stringify(data)
            );
            if (response.status === 200) {
              console.log(response);
              onSuccess(level);
            }
          } catch (error) {
            console.log(error.response);
          }
        }
      }
    }
  };

  const roomUrl = useMemo(
    () =>
      roomDetails.type === "profile"
        ? `https://${roomDetails.subdomain}.${process.env.REACT_APP_ROOT_DOMAIN}`
        : roomDetails.url,
    [roomDetails]
  );

  const getSharableLink = () => {
    if (!passwordToken) {
      generateNewLink();
    }
    const link =
      security === 2 || security === 3
        ? `${roomUrl}/?t=${passwordToken}`
        : `${roomUrl}`;
    copyReferralLink(link);
    setCopiedResponse("Link copied successfully");
  };

  const generateNewLink = async () => {
    if (!loading) {
      setLoading(true);
      const password = randomString(12);
      try {
        if (api) {
          const data = {
            password: password,
          };
          const responsePassword = await api.post(
            "/profile/api/v1/me/lobby/password/",
            JSON.stringify(data)
          );
          if (responsePassword.status === 200) {
            setNewLinkResponse("asdasda");
            setPasswordToken(responsePassword.data.token);
          } else {
          }
        }
      } catch (error) {
        console.log(error.response);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal close={close} backgroundTheme="light" visible>
      <div className="w-108 portrait:w-full rounded-lg bg-white border-t-4 border-orange flex flex-col items-center">
        <span className="font-bold text-xl p-4">Security Settings</span>
        <div className="bg-gray-100 w-full flex flex-col items-center justify-center p-4">
          <div className="flex flex-col items-center w-full">
            <div className="flex items-center">
              <button
                className={`shadow-inner text-sm disabled:cursor-not-allowed focus:outline-none p-2 leading-none rounded-l-lg ${
                  security === 3
                    ? "bg-orange shadow-none text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                disabled={security === 3}
                onClick={handleLevelUpdate.bind(null, 3)}
              >
                {roomDetails.type === "profile" ? "Private" : "Restrict Entry"}
              </button>
              <button
                className={`shadow-inner text-sm disabled:cursor-not-allowed focus:outline-none p-2 leading-none ${
                  security === 2
                    ? "bg-orange shadow-none text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                disabled={security === 2}
                onClick={handleLevelUpdate.bind(null, 2)}
              >
                Friendly
              </button>
              <button
                className={`shadow-inner text-sm disabled:cursor-not-allowed focus:outline-none p-2 leading-none rounded-r-lg ${
                  security === 1
                    ? "bg-orange shadow-none text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                disabled={security === 1}
                onClick={handleLevelUpdate.bind(null, 1)}
              >
                Public
              </button>
            </div>
            <div className="bg-gray-200 p-2 flex items-center rounded-lg my-2 text-gray-600 text-left text-xs">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                className="w-5 h-5 fill-current mr-4 flex-shrink-0"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
              </svg>
              {security === 3
                ? roomDetails.type === "commonroom"
                  ? "Deny room access to any user except for hosts."
                  : "Only people with the invite link can join in."
                : security === 2
                ? roomDetails.type === "commonroom"
                  ? "Only Zo World members can join this room."
                  : "People with the invite link and Zo World members can join."
                : security === 1
                ? roomDetails.type === "commonroom"
                  ? "Everyone is permitted to join this room."
                  : "Anyone on Zo World can join your room."
                : null}
            </div>
          </div>
          {roomDetails.type !== "commonroom" &&
            (security === 2 || security === 3) && (
              <div className="flex items-center justify-between w-72 portrait:w-full">
                {copiedResponse ? (
                  <div className="bg-white font-medium text-sm p-2 leading-none text-orange">
                    Link Copied!
                  </div>
                ) : (
                  <button
                    className="bg-orange focus:outline-none font-medium text-sm p-2 leading-none rounded-lg shadow-sm hover:shadow-md text-white mr-4"
                    onClick={getSharableLink}
                  >
                    Copy Invite Link
                  </button>
                )}
                {security === 2 || security === 3 ? (
                  newLinkResponse ? (
                    <div className="bg-white font-medium text-sm p-2 leading-none text-orange">
                      Link Generated!
                    </div>
                  ) : (
                    <button
                      className="bg-gray-200 focus:outline-none font-medium text-sm p-2 leading-none rounded-lg shadow-sm hover:shadow-md text-orange"
                      onClick={generateNewLink}
                    >
                      Generate New Link
                    </button>
                  )
                ) : null}
              </div>
            )}
        </div>
      </div>
    </Modal>
  );
};

export default SecurityModal;
