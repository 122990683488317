import React, { useEffect, useState } from "react";
import useApi from "../../../../hooks/useApi";
// import CommonRoomSettings from "./panes/CommonRoomSettings";
import GeneralSettings from "./panes/GeneralSettings";
import NotificationSettings from "./panes/NotificationSettings";
import SocialSettings from "./panes/SocialSettings";
import VisaSettings from "./panes/VisaSettings";

const ContentPane = ({ activePane }) => {
  const authContactApi = useApi("AUTH_CONTACT");
  const countriesApi = useApi("PROFILE_LOCATIONS_COUNTRIES");

  const [countriesList, setCountriesList] = useState([]);
  const [emailFields, setEmailFields] = useState([]);
  const [mobileFields, setMobileFields] = useState([]);
  const [loadingContact, setLoadingContact] = useState(true);

  useEffect(() => {
    setLoadingContact(true);
    if (authContactApi) {
      authContactApi
        .get("")
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            if (response.data.mobiles.length) {
              setMobileFields(response.data.mobiles);
            } else {
              setMobileFields([
                {
                  mobile: "",
                  mobile_country_code: "",
                  verified: false,
                  primary: false,
                },
              ]);
            }

            if (response.data.emails.length) {
              setEmailFields(response.data.emails);
            } else {
              setEmailFields([{ email: "", verified: false, primary: false }]);
            }
            setLoadingContact(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContactApi]);

  useEffect(() => {
    if (countriesApi) {
      countriesApi
        .get("")
        .then((res) => {
          setCountriesList(res.data.countries);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [countriesApi]);

  switch (activePane) {
    case "general":
      return (
        <GeneralSettings
          countriesList={countriesList}
          emailFields={emailFields}
          setEmailFields={setEmailFields}
          mobileFields={mobileFields}
          setMobileFields={setMobileFields}
          loadingContact={loadingContact}
        />
      );
    case "social":
      return <SocialSettings />;
    // case "common-room":
    //   return <CommonRoomSettings />;
    case "notification":
      return (
        <NotificationSettings
          emailFields={emailFields}
          setEmailFields={setEmailFields}
          mobileFields={mobileFields}
          setMobileFields={setMobileFields}
          loadingContact={loadingContact}
        />
      );
    case "visa":
      return <VisaSettings />;
    default:
      return null;
  }
};

export default ContentPane;
