import React, { useState, useContext, useEffect } from "react";

import Avatar from "../../../../../ui/Avatar";
import InGameContext from "../../../contexts/inGame";
import SocketContext from "../../../contexts/socket";

const isAlive = (_player) => _player.status === "alive";
const isMafia = (_player) => _player.role.includes("mafia");

const VoteSelect = () => {
  const { roomRound, playersStates, player } = useContext(InGameContext);
  const { sendSocketMessage } = useContext(SocketContext);

  const [dropdownVisible, setDropdownVisibility] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    if (roomRound === "night" && player.role && player.role.includes("mafia")) {
      const filtered = Object.keys(playersStates).filter(
        (id) => isAlive(playersStates[id]) && !isMafia(playersStates[id])
      );
      setPlayers(filtered.map((id) => playersStates[id]));
      setDisabled(false);
    } else if (roomRound === "night" && player.role === "healer") {
      const filtered = Object.keys(playersStates).filter((id) =>
        isAlive(playersStates[id])
      );
      setPlayers(filtered.map((id) => playersStates[id]));
      setDisabled(false);
    } else if (roomRound === "village-revenge") {
      const filtered = Object.keys(playersStates).filter(
        (id) => isAlive(playersStates[id]) && id !== player.id
      );
      setPlayers(filtered.map((id) => playersStates[id]));
      setDisabled(false);
    } else if (roomRound === "mafia-reveal") {
      const filtered = Object.keys(playersStates).filter(
        (id) => isAlive(playersStates[id]) && !isMafia(playersStates[id])
      );
      setPlayers(filtered.map((id) => playersStates[id]));
      setDisabled(false);
    } else {
      const filtered = Object.keys(playersStates).filter((id) =>
        isAlive(playersStates[id])
      );
      setPlayers(filtered.map((id) => playersStates[id]));
      setDisabled(false);
    }
  }, [roomRound, playersStates, player.role, player.id]);

  useEffect(() => {
    setDropdownVisibility(false);
  }, [roomRound]);

  const handleSelect = (id) => {
    if (roomRound === "night") {
      if (player.role === "detective") {
        sendSocketMessage("player_vote", {
          id: parseInt(player.id),
          vote: parseInt(id),
          hidden: true,
          detective: true,
        });
      } else {
        sendSocketMessage("player_vote", {
          id: parseInt(player.id),
          vote: parseInt(id),
          hidden: true,
        });
      }
    } else if (roomRound === "day") {
      sendSocketMessage("player_vote", {
        id: parseInt(player.id),
        vote: parseInt(id),
      });
    } else if (roomRound === "village-revenge") {
      sendSocketMessage("player_vote", {
        id: parseInt(player.id),
        vote: parseInt(id),
      });
    } else if (roomRound === "mafia-reveal") {
      sendSocketMessage("player_vote", {
        id: parseInt(player.id),
        vote: parseInt(id),
      });
    }
    setDropdownVisibility(false);
  };

  const toggleDropdownVisibility = () => {
    setDropdownVisibility((v) => !v);
  };

  return (
    <div className="relative text-gray-900">
      <div
        className={`flex items-center justify-between w-56 relative z-40 px-2 cursor-pointer bg-white hover:bg-gray-300 h-16 rounded-r-lg ${
          disabled ? "bg-gray-600 pointer-events-none cursor-not-allowed" : ""
        }`}
        onClick={toggleDropdownVisibility}
      >
        <div className="flex items-center">
          {player.vote === null ? (
            <div className="ml-12 text-gray-700">Select to vote</div>
          ) : player.vote !== -1 ? (
            <>
              <div className="bg-mafia-primary w-8 h-8 mr-2 border-3 rounded-full">
                <Avatar
                  svg={
                    players.find((u) => u.id === player.vote) &&
                    players.find((u) => u.id === player.vote).avatar
                  }
                />
              </div>
              <div className="w-32 truncate mr-2">
                {players.find((u) => u.id === player.vote) &&
                  players.find((u) => u.id === player.vote).nickname}
              </div>
            </>
          ) : (
            <div className="ml-12 w-32">Abstain vote</div>
          )}
        </div>
        <svg
          className="w-8 h-8 fill-current text-mafia-primary"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M12 5.83l2.46 2.46c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L12.7 3.7c-.39-.39-1.02-.39-1.41 0L8.12 6.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 5.83zm0 12.34l-2.46-2.46c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l3.17 3.18c.39.39 1.02.39 1.41 0l3.17-3.17c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L12 18.17z" />
        </svg>
      </div>
      <ul
        className={`absolute z-20 w-full rounded-t-lg overflow-hidden pb-8 bg-white ${
          dropdownVisible
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
        style={{ bottom: "calc(100% - 2rem)" }}
      >
        {player.vote !== -1 &&
          !(
            roomRound === "night" &&
            (player.role === "healer" || player.role === "detective")
          ) && (
            <li
              className="flex items-center px-2 cursor-pointer border-b bg-white hover:bg-gray-300 h-12"
              key={-1}
              onClick={handleSelect.bind(null, -1)}
            >
              <div onClick={handleSelect.bind(null, -1)} className="ml-12 w-32">
                Abstain vote
              </div>
            </li>
          )}
        {players
          .filter((u) => u.id !== player.vote)
          .map(({ id, nickname, avatar }) => (
            <li
              className="flex w-full items-center px-2 border-b cursor-pointer bg-white hover:bg-gray-300 h-12"
              key={id}
              onClick={handleSelect.bind(null, id)}
            >
              <div className="bg-mafia-primary w-8 h-8 mr-2 border-3 rounded-full">
                <Avatar svg={avatar} />
              </div>
              <div className="w-32 truncate">{nickname}</div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default VoteSelect;
