import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={21}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.726 5.45l3.5 6.061 5.725-3.305-3.5-6.062C4.424-.342 3.54 3.312 3.726 5.449z"
        fill="#F15824"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.489 5.215L7.51 10.45 11.89 7.92 8.897 2.74c-1.083-.509-1.884-.671-2.462-.652-.588.02-.97.228-1.238.51-.56.59-.76 1.662-.708 2.617zM6.383.533c.96-.032 2.09.259 3.413.913a.778.778 0 01.329.309l3.5 6.062a.778.778 0 01-.285 1.062l-5.725 3.306a.778.778 0 01-1.063-.285l-3.5-6.062a.778.778 0 01-.101-.321c-.1-1.143.06-2.875 1.118-3.99C4.627.94 5.4.567 6.383.533z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.887 5.382a.77.77 0 01-.278 1.07l-14.52 8.422-1.516 5.546a.802.802 0 01-.979.553.779.779 0 01-.567-.955l1.6-5.853a.783.783 0 01.364-.47l14.8-8.585a.811.811 0 011.096.272z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgComponent;
